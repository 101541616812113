// Your GeoJSON data goes here
const geojsonData = {
  type: "FeatureCollection",

  features: [
    {
      type: "Feature",
      id: "sm9a06d6bf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99135226, 40.64990534],
            [-73.99139847, 40.6503208],
            [-73.99160768, 40.65046325],
            [-73.9926705, 40.65039097],
            [-73.99261686, 40.65002874],
            [-73.9925203, 40.65003688],
            [-73.99249781, 40.6498327],
            [-73.99135226, 40.64990534],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL5",
      },
    },
    {
      type: "Feature",
      id: "smf7334d39",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99249781, 40.6498327],
            [-73.99243596, 40.64927124],
            [-73.99128981, 40.64934392],
            [-73.99135226, 40.64990534],
            [-73.99249781, 40.6498327],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL4",
      },
    },
    {
      type: "Feature",
      id: "sm6f4bdcdd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99243596, 40.64927124],
            [-73.99237407, 40.64870944],
            [-73.99122733, 40.64878216],
            [-73.99128981, 40.64934392],
            [-73.99243596, 40.64927124],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL3",
      },
    },
    {
      type: "Feature",
      id: "sm6a1aae34",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99237407, 40.64870944],
            [-73.99231325, 40.64815736],
            [-73.99114371, 40.64823153],
            [-73.99118389, 40.6483916],
            [-73.99122733, 40.64878216],
            [-73.99237407, 40.64870944],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL2",
      },
    },
    {
      type: "Feature",
      id: "sm96a27000",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99231325, 40.64815736],
            [-73.99225947, 40.64766913],
            [-73.99225947, 40.64766913],
            [-73.99212804, 40.6475857],
            [-73.99123754, 40.64769561],
            [-73.99105515, 40.64787877],
            [-73.99114371, 40.64823153],
            [-73.99231325, 40.64815736],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL1",
      },
    },
    {
      type: "Feature",
      id: "sm223f2fe8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99368898, 40.64800245],
            [-73.99373064, 40.64740755],
            [-73.99266716, 40.64752261],
            [-73.99253305, 40.64764064],
            [-73.99259539, 40.64807717],
            [-73.99259539, 40.64807717],
            [-73.99368898, 40.64800245],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL9",
      },
    },
    {
      type: "Feature",
      id: "sm1345f526",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99275079, 40.64938636],
            [-73.99282607, 40.6500206],
            [-73.99272682, 40.65002874],
            [-73.99278315, 40.65038283],
            [-73.99352612, 40.65032788],
            [-73.9935961, 40.6493286],
            [-73.99275079, 40.64938636],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL6",
      },
    },
    {
      type: "Feature",
      id: "sme4df2942",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9935961, 40.6493286],
            [-73.99364609, 40.64861487],
            [-73.99266715, 40.64868176],
            [-73.99275079, 40.64938636],
            [-73.9935961, 40.6493286],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL7",
      },
    },
    {
      type: "Feature",
      id: "sm40ee91f0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99364609, 40.64861487],
            [-73.99368898, 40.64800245],
            [-73.99259539, 40.64807717],
            [-73.99266715, 40.64868176],
            [-73.99364609, 40.64861487],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL8",
      },
    },
    {
      type: "Feature",
      id: "sm2e519994",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99458335, 40.64813607],
            [-73.9946359, 40.64747377],
            [-73.9946359, 40.64747377],
            [-73.99451253, 40.64737609],
            [-73.99391171, 40.64738422],
            [-73.99373064, 40.64740755],
            [-73.9936825, 40.64809492],
            [-73.99458335, 40.64813607],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL10",
      },
    },
    {
      type: "Feature",
      id: "sm79c2fffe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99358067, 40.64954892],
            [-73.99352612, 40.65032788],
            [-73.99426843, 40.65028417],
            [-73.99444546, 40.650158],
            [-73.99446757, 40.64959528],
            [-73.99446803, 40.64958945],
            [-73.99358067, 40.64954892],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL13",
      },
    },
    {
      type: "Feature",
      id: "sm3664a207",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99446803, 40.64958945],
            [-73.99452531, 40.6488676],
            [-73.99363125, 40.64882676],
            [-73.99358067, 40.64954892],
            [-73.99446803, 40.64958945],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL12",
      },
    },
    {
      type: "Feature",
      id: "sm68b368e6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99452531, 40.6488676],
            [-73.99458335, 40.64813607],
            [-73.9936825, 40.64809492],
            [-73.99363125, 40.64882676],
            [-73.99452531, 40.6488676],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL11",
      },
    },
    {
      type: "Feature",
      id: "sm010b4aae",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99577724, 40.64811492],
            [-73.99584313, 40.64736491],
            [-73.99507042, 40.64736792],
            [-73.99490747, 40.64748289],
            [-73.99490747, 40.64748289],
            [-73.99484539, 40.64806779],
            [-73.99577724, 40.64811492],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL17",
      },
    },
    {
      type: "Feature",
      id: "sm5649327c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99475256, 40.64956678],
            [-73.99472306, 40.65016304],
            [-73.99482097, 40.65026382],
            [-73.99558504, 40.65030246],
            [-73.99564714, 40.64959568],
            [-73.99475256, 40.64956678],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL14",
      },
    },
    {
      type: "Feature",
      id: "sm3d2a922b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99564714, 40.64959568],
            [-73.9957102, 40.648878],
            [-73.99481023, 40.64884736],
            [-73.99475256, 40.64956678],
            [-73.99564714, 40.64959568],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL15",
      },
    },
    {
      type: "Feature",
      id: "smfa00ab4a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99572755, 40.64884329],
            [-73.99577724, 40.64811495],
            [-73.99484539, 40.64806782],
            [-73.99480889, 40.64880564],
            [-73.99572755, 40.64884329],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL16",
      },
    },
    {
      type: "Feature",
      id: "sm622d4c93",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99574553, 40.64847581],
            [-73.99572755, 40.64884329],
            [-73.99660242, 40.6488788],
            [-73.99662189, 40.64850188],
            [-73.99574553, 40.64847581],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL21",
      },
    },
    {
      type: "Feature",
      id: "sm1d98a3a9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99662189, 40.64850188],
            [-73.9966411, 40.64813015],
            [-73.99577816, 40.64810449],
            [-73.99574553, 40.64847581],
            [-73.99662189, 40.64850188],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL20",
      },
    },
    {
      type: "Feature",
      id: "smf222aa43",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9966411, 40.64813015],
            [-73.99666044, 40.64775572],
            [-73.99581102, 40.64773046],
            [-73.99577816, 40.64810449],
            [-73.9966411, 40.64813015],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL19",
      },
    },
    {
      type: "Feature",
      id: "sm8cb8b31f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99666044, 40.64775572],
            [-73.99667438, 40.64748598],
            [-73.99667438, 40.64748598],
            [-73.99655636, 40.6473883],
            [-73.99611648, 40.64736388],
            [-73.99584313, 40.64736494],
            [-73.99581102, 40.64773046],
            [-73.99666044, 40.64775572],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL18",
      },
    },
    {
      type: "Feature",
      id: "smebebd96a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99780214, 40.6477958],
            [-73.99782508, 40.64743152],
            [-73.99708208, 40.64740458],
            [-73.99694796, 40.64749412],
            [-73.99693865, 40.64749384],
            [-73.99692469, 40.64776399],
            [-73.99780214, 40.6477958],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL26",
      },
    },
    {
      type: "Feature",
      id: "smb8a790d6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99692469, 40.64776399],
            [-73.99690452, 40.64815417],
            [-73.99777757, 40.64818582],
            [-73.99780214, 40.6477958],
            [-73.99692469, 40.64776399],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL25",
      },
    },
    {
      type: "Feature",
      id: "sm1448a838",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99690452, 40.64815417],
            [-73.99688506, 40.64853063],
            [-73.99775386, 40.64856214],
            [-73.99777757, 40.64818582],
            [-73.99690452, 40.64815417],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL24",
      },
    },
    {
      type: "Feature",
      id: "smf135c921",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99688506, 40.64853063],
            [-73.99686646, 40.64889052],
            [-73.99773076, 40.64892888],
            [-73.99775386, 40.64856214],
            [-73.99688506, 40.64853063],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL23",
      },
    },
    {
      type: "Feature",
      id: "smb7c0aaff",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99274491, 40.6455795],
            [-73.99277587, 40.64521217],
            [-73.99187196, 40.64517961],
            [-73.99184528, 40.6455413],
            [-73.99274491, 40.6455795],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL56",
      },
    },
    {
      type: "Feature",
      id: "smb4a1da63",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99173906, 40.6469815],
            [-73.99171517, 40.64730538],
            [-73.99172569, 40.64730891],
            [-73.99182761, 40.64739846],
            [-73.99248743, 40.64732926],
            [-73.99260587, 40.64722941],
            [-73.9926236, 40.64701906],
            [-73.99173906, 40.6469815],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL61",
      },
    },
    {
      type: "Feature",
      id: "sm6f1a80e1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9926236, 40.64701906],
            [-73.99265396, 40.64665878],
            [-73.99176564, 40.64662107],
            [-73.99173906, 40.6469815],
            [-73.9926236, 40.64701906],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL60",
      },
    },
    {
      type: "Feature",
      id: "smc405b39c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99265396, 40.64665878],
            [-73.9926839, 40.64630347],
            [-73.99179186, 40.6462656],
            [-73.99176564, 40.64662107],
            [-73.99265396, 40.64665878],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL59",
      },
    },
    {
      type: "Feature",
      id: "smecc08980",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9926839, 40.64630347],
            [-73.99271476, 40.64593732],
            [-73.99181888, 40.64589928],
            [-73.99179186, 40.6462656],
            [-73.9926839, 40.64630347],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL58",
      },
    },
    {
      type: "Feature",
      id: "sm8e3640cd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99271476, 40.64593732],
            [-73.99274491, 40.6455795],
            [-73.99184528, 40.6455413],
            [-73.99181888, 40.64589928],
            [-73.99271476, 40.64593732],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL57",
      },
    },
    {
      type: "Feature",
      id: "sma80f352f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99158072, 40.64575302],
            [-73.99160838, 40.64537184],
            [-73.99143672, 40.64530672],
            [-73.99065624, 40.64545325],
            [-73.99063784, 40.64571484],
            [-73.99158072, 40.64575302],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL39",
      },
    },
    {
      type: "Feature",
      id: "sm964469d6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99063784, 40.64571484],
            [-73.99061336, 40.64606292],
            [-73.99155547, 40.64610107],
            [-73.99158072, 40.64575302],
            [-73.99063784, 40.64571484],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL38",
      },
    },
    {
      type: "Feature",
      id: "sm6e5139c3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99061336, 40.64606292],
            [-73.99058855, 40.64641559],
            [-73.99152989, 40.64645371],
            [-73.99155547, 40.64610107],
            [-73.99061336, 40.64606292],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL37",
      },
    },
    {
      type: "Feature",
      id: "sme9028fd3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99058855, 40.64641559],
            [-73.99056379, 40.64676767],
            [-73.99150435, 40.64680575],
            [-73.99152989, 40.64645371],
            [-73.99058855, 40.64641559],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL36",
      },
    },
    {
      type: "Feature",
      id: "smd912cbd0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99056379, 40.64676767],
            [-73.99055432, 40.64690225],
            [-73.9907984, 40.64701622],
            [-73.99086813, 40.64722787],
            [-73.99112026, 40.64734184],
            [-73.9913187, 40.64731741],
            [-73.99147963, 40.64714647],
            [-73.99150435, 40.64680575],
            [-73.99056379, 40.64676767],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL35",
      },
    },
    {
      type: "Feature",
      id: "sm960825ea",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.98974121, 40.64628979],
            [-73.98970407, 40.64666825],
            [-73.98960483, 40.64666621],
            [-73.98959947, 40.64677204],
            [-73.99010639, 40.64668247],
            [-73.99055432, 40.64690225],
            [-73.99059497, 40.64632436],
            [-73.98974121, 40.64628979],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL42",
      },
    },
    {
      type: "Feature",
      id: "sm4328fb7e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99062247, 40.64593343],
            [-73.99065624, 40.64545325],
            [-73.98996691, 40.64556315],
            [-73.98977916, 40.64570154],
            [-73.98976643, 40.64589877],
            [-73.99062247, 40.64593343],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL40",
      },
    },
    {
      type: "Feature",
      id: "smcb2543c6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.98976643, 40.64589877],
            [-73.9897416, 40.64628359],
            [-73.98974121, 40.64628979],
            [-73.99059497, 40.64632436],
            [-73.99062247, 40.64593343],
            [-73.98976643, 40.64589877],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL41",
      },
    },
    {
      type: "Feature",
      id: "sm14032448",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.98851887, 40.64637621],
            [-73.98845948, 40.64694703],
            [-73.98949484, 40.64678829],
            [-73.98950559, 40.646654],
            [-73.98941438, 40.64665194],
            [-73.98943968, 40.64643929],
            [-73.98851887, 40.64637621],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL43",
      },
    },
    {
      type: "Feature",
      id: "sm695aa8c2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.98948231, 40.64608108],
            [-73.98952166, 40.64575038],
            [-73.98952166, 40.64575038],
            [-73.98939555, 40.64565071],
            [-73.989012, 40.6457199],
            [-73.98896372, 40.64558151],
            [-73.98859625, 40.64563239],
            [-73.98855535, 40.64602556],
            [-73.98948231, 40.64608108],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL45",
      },
    },
    {
      type: "Feature",
      id: "smdd583c1d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.98855535, 40.64602556],
            [-73.98851887, 40.64637621],
            [-73.98943968, 40.64643929],
            [-73.98948231, 40.64608108],
            [-73.98855535, 40.64602556],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL44",
      },
    },
    {
      type: "Feature",
      id: "sm0b20f24b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99160582, 40.64504351],
            [-73.99188197, 40.64496899],
            [-73.99193097, 40.64423123],
            [-73.99131031, 40.64439374],
            [-73.99160582, 40.64504351],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL52",
      },
    },
    {
      type: "Feature",
      id: "sma01dac08",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.98903131, 40.64478584],
            [-73.98869015, 40.64483671],
            [-73.98860434, 40.64555709],
            [-73.98860434, 40.64555709],
            [-73.98879746, 40.64553674],
            [-73.98920918, 40.64547254],
            [-73.98903131, 40.64478584],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL46",
      },
    },
    {
      type: "Feature",
      id: "sm6c595a1e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.98920918, 40.64547254],
            [-73.9896727, 40.64540026],
            [-73.98949562, 40.6447166],
            [-73.98903131, 40.64478584],
            [-73.98920918, 40.64547254],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL47",
      },
    },
    {
      type: "Feature",
      id: "smb60f2b21",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9896727, 40.64540026],
            [-73.99014265, 40.64532698],
            [-73.98996637, 40.6446464],
            [-73.98949562, 40.6447166],
            [-73.9896727, 40.64540026],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL48",
      },
    },
    {
      type: "Feature",
      id: "sm434ba4ee",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99014265, 40.64532698],
            [-73.99061666, 40.64525307],
            [-73.99044119, 40.6445756],
            [-73.98996637, 40.6446464],
            [-73.99014265, 40.64532698],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL49",
      },
    },
    {
      type: "Feature",
      id: "sm47adb301",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99061666, 40.64525307],
            [-73.99112021, 40.64517455],
            [-73.99115221, 40.64516592],
            [-73.99085522, 40.6445129],
            [-73.99084665, 40.64451514],
            [-73.99044119, 40.6445756],
            [-73.99061666, 40.64525307],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL50",
      },
    },
    {
      type: "Feature",
      id: "sm660e2189",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99115221, 40.64516592],
            [-73.99160582, 40.64504351],
            [-73.99131031, 40.64439374],
            [-73.99085522, 40.6445129],
            [-73.99115221, 40.64516592],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL51",
      },
    },
    {
      type: "Feature",
      id: "sm065efda5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99281858, 40.64449738],
            [-73.99283746, 40.64418143],
            [-73.99293402, 40.64418143],
            [-73.99293938, 40.64402879],
            [-73.99277711, 40.64402419],
            [-73.99193097, 40.64423123],
            [-73.99191642, 40.64446506],
            [-73.99281858, 40.64449738],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL53",
      },
    },
    {
      type: "Feature",
      id: "smb0f38077",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99189385, 40.64482778],
            [-73.99187196, 40.64517961],
            [-73.99277587, 40.64521217],
            [-73.9927969, 40.64486013],
            [-73.99189385, 40.64482778],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL55",
      },
    },
    {
      type: "Feature",
      id: "smd1e48059",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9927969, 40.64486013],
            [-73.99281858, 40.64449738],
            [-73.99191642, 40.64446506],
            [-73.99189385, 40.64482778],
            [-73.9927969, 40.64486013],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL54",
      },
    },
    {
      type: "Feature",
      id: "sm595e77a4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99402735, 40.64442415],
            [-73.99404988, 40.64408776],
            [-73.99304935, 40.64403286],
            [-73.99303862, 40.64418754],
            [-73.99312445, 40.64419161],
            [-73.99310962, 40.64438876],
            [-73.99402735, 40.64442415],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL70",
      },
    },
    {
      type: "Feature",
      id: "sme5e63513",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99310962, 40.64438876],
            [-73.99308332, 40.64473835],
            [-73.99400393, 40.64477385],
            [-73.99402735, 40.64442415],
            [-73.99310962, 40.64438876],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL69",
      },
    },
    {
      type: "Feature",
      id: "smb61f35c2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99308332, 40.64473835],
            [-73.99305631, 40.64509729],
            [-73.99397988, 40.6451329],
            [-73.99400393, 40.64477385],
            [-73.99308332, 40.64473835],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL68",
      },
    },
    {
      type: "Feature",
      id: "sm3be03cfb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99305631, 40.64509729],
            [-73.99303027, 40.64544336],
            [-73.99395669, 40.64547908],
            [-73.99397988, 40.6451329],
            [-73.99305631, 40.64509729],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL67",
      },
    },
    {
      type: "Feature",
      id: "sm1fbbf0d7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99303027, 40.64544336],
            [-73.99300366, 40.645797],
            [-73.993933, 40.64583283],
            [-73.99395669, 40.64547908],
            [-73.99303027, 40.64544336],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL66",
      },
    },
    {
      type: "Feature",
      id: "smec7dafd4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99300366, 40.645797],
            [-73.99297712, 40.64614974],
            [-73.99390937, 40.64618569],
            [-73.993933, 40.64583283],
            [-73.99300366, 40.645797],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL65",
      },
    },
    {
      type: "Feature",
      id: "smc4127e73",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99297712, 40.64614974],
            [-73.99295068, 40.64650117],
            [-73.99388582, 40.64653723],
            [-73.99390937, 40.64618569],
            [-73.99297712, 40.64614974],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL64",
      },
    },
    {
      type: "Feature",
      id: "sm9f818376",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99295068, 40.64650117],
            [-73.99292381, 40.64685832],
            [-73.99386189, 40.64689449],
            [-73.99388582, 40.64653723],
            [-73.99295068, 40.64650117],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL63",
      },
    },
    {
      type: "Feature",
      id: "sm126f065d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99292381, 40.64685832],
            [-73.99289962, 40.64717988],
            [-73.99291125, 40.64717869],
            [-73.99303463, 40.6472723],
            [-73.99384337, 40.647171],
            [-73.99386189, 40.64689449],
            [-73.99292381, 40.64685832],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL62",
      },
    },
    {
      type: "Feature",
      id: "sm111e9ee7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99498889, 40.64676381],
            [-73.9949658, 40.64705658],
            [-73.99510529, 40.64716241],
            [-73.99586171, 40.64714658],
            [-73.99588354, 40.64679587],
            [-73.99498889, 40.64676381],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL79",
      },
    },
    {
      type: "Feature",
      id: "sm4961da67",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99602279, 40.6445587],
            [-73.99604545, 40.64419462],
            [-73.99509319, 40.6441326],
            [-73.99509185, 40.64430457],
            [-73.99517634, 40.64430661],
            [-73.99515957, 40.64452778],
            [-73.99602279, 40.6445587],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL86",
      },
    },
    {
      type: "Feature",
      id: "sme92e483e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99515957, 40.64452778],
            [-73.99513137, 40.6448997],
            [-73.99599964, 40.64493081],
            [-73.99602279, 40.6445587],
            [-73.99515957, 40.64452778],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL85",
      },
    },
    {
      type: "Feature",
      id: "sm61ca831d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99513137, 40.6448997],
            [-73.99510388, 40.64526228],
            [-73.99597706, 40.64529357],
            [-73.99599964, 40.64493081],
            [-73.99513137, 40.6448997],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL84",
      },
    },
    {
      type: "Feature",
      id: "sm9be9c4a6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99510388, 40.64526228],
            [-73.99507502, 40.64564283],
            [-73.99595336, 40.64567431],
            [-73.99597706, 40.64529357],
            [-73.99510388, 40.64526228],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL83",
      },
    },
    {
      type: "Feature",
      id: "sm14d86577",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99507502, 40.64564283],
            [-73.99504675, 40.64601568],
            [-73.99593014, 40.64604733],
            [-73.99595336, 40.64567431],
            [-73.99507502, 40.64564283],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL82",
      },
    },
    {
      type: "Feature",
      id: "sm2d89444d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99504675, 40.64601568],
            [-73.9950185, 40.64638817],
            [-73.99590694, 40.64642],
            [-73.99593014, 40.64604733],
            [-73.99504675, 40.64601568],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL81",
      },
    },
    {
      type: "Feature",
      id: "smc41c29e0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9950185, 40.64638817],
            [-73.99501808, 40.64639364],
            [-73.99498889, 40.64676381],
            [-73.99588354, 40.64679587],
            [-73.99590694, 40.64642],
            [-73.9950185, 40.64638817],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL80",
      },
    },
    {
      type: "Feature",
      id: "sm67d84144",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99387096, 40.64675915],
            [-73.99384337, 40.647171],
            [-73.99384337, 40.647171],
            [-73.99458492, 40.64716239],
            [-73.99470831, 40.64705658],
            [-73.99470831, 40.64705658],
            [-73.99472616, 40.64679213],
            [-73.99387096, 40.64675915],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL78",
      },
    },
    {
      type: "Feature",
      id: "smf4405339",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99488073, 40.64450232],
            [-73.9948947, 40.64429542],
            [-73.99498054, 40.64430356],
            [-73.99499663, 40.64413668],
            [-73.99404988, 40.64408776],
            [-73.99402432, 40.6444693],
            [-73.99488073, 40.64450232],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL71",
      },
    },
    {
      type: "Feature",
      id: "sm3a54578c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99402432, 40.6444693],
            [-73.99399846, 40.64485539],
            [-73.99485467, 40.64488841],
            [-73.99488073, 40.64450232],
            [-73.99402432, 40.6444693],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL72",
      },
    },
    {
      type: "Feature",
      id: "sm83316a65",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99399846, 40.64485539],
            [-73.99397298, 40.64523585],
            [-73.99482899, 40.64526885],
            [-73.99485467, 40.64488841],
            [-73.99399846, 40.64485539],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL73",
      },
    },
    {
      type: "Feature",
      id: "sm46c0c30b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99397298, 40.64523585],
            [-73.99394798, 40.64560914],
            [-73.99480379, 40.64564214],
            [-73.99482899, 40.64526885],
            [-73.99397298, 40.64523585],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL74",
      },
    },
    {
      type: "Feature",
      id: "smc93b2001",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99394798, 40.64560914],
            [-73.99392188, 40.64599888],
            [-73.99477748, 40.64603187],
            [-73.99480379, 40.64564214],
            [-73.99394798, 40.64560914],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL75",
      },
    },
    {
      type: "Feature",
      id: "smbcdc58fc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99392188, 40.64599888],
            [-73.99389624, 40.64638173],
            [-73.99475164, 40.64641471],
            [-73.99477748, 40.64603187],
            [-73.99392188, 40.64599888],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL76",
      },
    },
    {
      type: "Feature",
      id: "sm268ae555",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99389624, 40.64638173],
            [-73.99387096, 40.64675915],
            [-73.99472616, 40.64679213],
            [-73.99475164, 40.64641471],
            [-73.99389624, 40.64638173],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL77",
      },
    },
    {
      type: "Feature",
      id: "smcd27a71f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99810378, 40.64466065],
            [-73.99812828, 40.64429745],
            [-73.9971337, 40.64424597],
            [-73.9971203, 40.64439555],
            [-73.99721015, 40.64439555],
            [-73.99719468, 40.64462544],
            [-73.99810378, 40.64466065],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL102",
      },
    },
    {
      type: "Feature",
      id: "smcda3f801",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99704802, 40.6468052],
            [-73.99703111, 40.64705658],
            [-73.99703111, 40.64705658],
            [-73.99714108, 40.64718275],
            [-73.99793101, 40.64722188],
            [-73.99795675, 40.64684039],
            [-73.99704802, 40.6468052],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL95",
      },
    },
    {
      type: "Feature",
      id: "sm6cae076c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99795675, 40.64684039],
            [-73.99798071, 40.64648522],
            [-73.99707191, 40.64645002],
            [-73.99704802, 40.6468052],
            [-73.99795675, 40.64684039],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL96",
      },
    },
    {
      type: "Feature",
      id: "sm87cc6404",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99798071, 40.64648522],
            [-73.99800576, 40.64611381],
            [-73.9970969, 40.64607861],
            [-73.99707191, 40.64645002],
            [-73.99798071, 40.64648522],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL97",
      },
    },
    {
      type: "Feature",
      id: "sm28c4ab5f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99800576, 40.64611381],
            [-73.99803016, 40.64575205],
            [-73.99712124, 40.64571685],
            [-73.9970969, 40.64607861],
            [-73.99800576, 40.64611381],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL98",
      },
    },
    {
      type: "Feature",
      id: "sm31b128b9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99803016, 40.64575205],
            [-73.99805462, 40.64538938],
            [-73.99714565, 40.64535408],
            [-73.99712124, 40.64571685],
            [-73.99803016, 40.64575205],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL99",
      },
    },
    {
      type: "Feature",
      id: "sm86f1d509",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99805462, 40.64538938],
            [-73.99807933, 40.6450231],
            [-73.99717029, 40.64498789],
            [-73.99714565, 40.64535408],
            [-73.99805462, 40.64538938],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL100",
      },
    },
    {
      type: "Feature",
      id: "sma10faacb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99807933, 40.6450231],
            [-73.99810378, 40.64466065],
            [-73.99719468, 40.64462544],
            [-73.99717029, 40.64498789],
            [-73.99807933, 40.6450231],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL101",
      },
    },
    {
      type: "Feature",
      id: "sm83930fdc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99588523, 40.64676843],
            [-73.99586169, 40.64714658],
            [-73.99586169, 40.64714658],
            [-73.99659657, 40.64715834],
            [-73.99673604, 40.64705251],
            [-73.9967595, 40.64699441],
            [-73.99677199, 40.64680021],
            [-73.99588523, 40.64676843],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL94",
      },
    },
    {
      type: "Feature",
      id: "smb321518b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99691395, 40.64459382],
            [-73.99692713, 40.64438904],
            [-73.99701766, 40.64439107],
            [-73.99702235, 40.6442308],
            [-73.99604545, 40.64419462],
            [-73.99602259, 40.64456187],
            [-73.99691395, 40.64459382],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL87",
      },
    },
    {
      type: "Feature",
      id: "sm1b73cf8e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99602259, 40.64456187],
            [-73.99599931, 40.64493589],
            [-73.99688989, 40.64496781],
            [-73.99691395, 40.64459382],
            [-73.99602259, 40.64456187],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL88",
      },
    },
    {
      type: "Feature",
      id: "sm01420d4b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99599931, 40.64493589],
            [-73.99597693, 40.64529546],
            [-73.99686676, 40.64532735],
            [-73.99688989, 40.64496781],
            [-73.99599931, 40.64493589],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL89",
      },
    },
    {
      type: "Feature",
      id: "sm89467857",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99597693, 40.64529546],
            [-73.99595382, 40.64566664],
            [-73.99684288, 40.6456985],
            [-73.99686676, 40.64532735],
            [-73.99597693, 40.64529546],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL90",
      },
    },
    {
      type: "Feature",
      id: "smbf63dd31",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99595382, 40.64566664],
            [-73.99593147, 40.64602566],
            [-73.99681978, 40.64605749],
            [-73.99684288, 40.6456985],
            [-73.99595382, 40.64566664],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL91",
      },
    },
    {
      type: "Feature",
      id: "sm8ad9ff43",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99593147, 40.64602566],
            [-73.99590826, 40.64639852],
            [-73.99679579, 40.64643032],
            [-73.99681978, 40.64605749],
            [-73.99593147, 40.64602566],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL92",
      },
    },
    {
      type: "Feature",
      id: "smdaa199ff",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99590826, 40.64639852],
            [-73.99588523, 40.64676843],
            [-73.99677199, 40.64680021],
            [-73.99679579, 40.64643032],
            [-73.99590826, 40.64639852],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL93",
      },
    },
    {
      type: "Feature",
      id: "sm2ac2d54c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.00008642, 40.64495224],
            [-74.00011027, 40.64464289],
            [-73.99920437, 40.64445259],
            [-73.99920571, 40.64452179],
            [-73.99927947, 40.64451772],
            [-73.99924995, 40.64491511],
            [-74.00008642, 40.64495224],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL117",
      },
    },
    {
      type: "Feature",
      id: "smf8526fd9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99922145, 40.64529884],
            [-73.99919195, 40.64569593],
            [-74.00002624, 40.64573296],
            [-74.00005685, 40.64533591],
            [-73.99922145, 40.64529884],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL116",
      },
    },
    {
      type: "Feature",
      id: "smcb3a3aef",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.00005685, 40.64533591],
            [-74.00008642, 40.64495224],
            [-73.99924995, 40.64491511],
            [-73.99922145, 40.64529884],
            [-74.00005685, 40.64533591],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL115",
      },
    },
    {
      type: "Feature",
      id: "sm6a9acd52",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99919195, 40.64569593],
            [-73.99916317, 40.64608334],
            [-73.99999648, 40.64611898],
            [-74.00002624, 40.64573296],
            [-73.99919195, 40.64569593],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL114",
      },
    },
    {
      type: "Feature",
      id: "smd0b6a39f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99916317, 40.64608334],
            [-73.99913444, 40.64647016],
            [-73.99996666, 40.64650575],
            [-73.99999648, 40.64611898],
            [-73.99916317, 40.64608334],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL113",
      },
    },
    {
      type: "Feature",
      id: "sm99247f83",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99913444, 40.64647016],
            [-73.99910587, 40.64685469],
            [-73.99993702, 40.64689023],
            [-73.99996666, 40.64650575],
            [-73.99913444, 40.64647016],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL112",
      },
    },
    {
      type: "Feature",
      id: "sm224142b2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99910587, 40.64685469],
            [-73.99908301, 40.6471624],
            [-73.99908301, 40.6471624],
            [-73.99918225, 40.64726416],
            [-73.9993861, 40.64726823],
            [-73.99938035, 40.64738596],
            [-73.99989768, 40.6474005],
            [-73.99993702, 40.64689023],
            [-73.99910587, 40.64685469],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL111",
      },
    },
    {
      type: "Feature",
      id: "sm2bf4ffb2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99795712, 40.6468348],
            [-73.99793101, 40.64722188],
            [-73.99865386, 40.64724381],
            [-73.99877724, 40.64715833],
            [-73.99877724, 40.64715833],
            [-73.99880223, 40.6468782],
            [-73.99795712, 40.6468348],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL110",
      },
    },
    {
      type: "Feature",
      id: "smcdd26db4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99899498, 40.64471725],
            [-73.99901396, 40.64450441],
            [-73.99911589, 40.64451459],
            [-73.99911857, 40.64443725],
            [-73.99843657, 40.64431319],
            [-73.99812828, 40.64429745],
            [-73.9981023, 40.64468259],
            [-73.99899498, 40.64471725],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL103",
      },
    },
    {
      type: "Feature",
      id: "sm7ea078e3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9980783, 40.64503842],
            [-73.99805465, 40.64538901],
            [-73.99893202, 40.64542308],
            [-73.99896327, 40.64507278],
            [-73.9980783, 40.64503842],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL105",
      },
    },
    {
      type: "Feature",
      id: "sm2a636d44",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99896327, 40.64507278],
            [-73.99899498, 40.64471725],
            [-73.9981023, 40.64468259],
            [-73.9980783, 40.64503842],
            [-73.99896327, 40.64507278],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL104",
      },
    },
    {
      type: "Feature",
      id: "sm0489f291",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99805465, 40.64538901],
            [-73.99803038, 40.64574881],
            [-73.99889996, 40.64578258],
            [-73.99893202, 40.64542308],
            [-73.99805465, 40.64538901],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL106",
      },
    },
    {
      type: "Feature",
      id: "sm4aace70f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99803038, 40.64574881],
            [-73.99800543, 40.64611865],
            [-73.998867, 40.64615211],
            [-73.99889996, 40.64578258],
            [-73.99803038, 40.64574881],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL107",
      },
    },
    {
      type: "Feature",
      id: "smb0962281",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99800543, 40.64611865],
            [-73.99798176, 40.64646947],
            [-73.99883478, 40.64651328],
            [-73.998867, 40.64615211],
            [-73.99800543, 40.64611865],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL108",
      },
    },
    {
      type: "Feature",
      id: "smf3847471",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99798176, 40.64646947],
            [-73.99795712, 40.6468348],
            [-73.99880223, 40.6468782],
            [-73.99883478, 40.64651328],
            [-73.99798176, 40.64646947],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL109",
      },
    },
    {
      type: "Feature",
      id: "smdff3e317",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9988837, 40.648635],
            [-73.99885719, 40.64897887],
            [-73.99977286, 40.64901951],
            [-73.99979937, 40.64867564],
            [-73.9988837, 40.648635],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL31",
      },
    },
    {
      type: "Feature",
      id: "smbb64f69b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99985447, 40.64796101],
            [-73.99989768, 40.6474005],
            [-73.99938035, 40.64738596],
            [-73.99937537, 40.64748802],
            [-73.99910179, 40.64747988],
            [-73.99896589, 40.64756901],
            [-73.9989388, 40.64792037],
            [-73.99985447, 40.64796101],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL34",
      },
    },
    {
      type: "Feature",
      id: "sm41b534f1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9989388, 40.64792037],
            [-73.99891104, 40.64828043],
            [-73.99982671, 40.64832107],
            [-73.99985447, 40.64796101],
            [-73.9989388, 40.64792037],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL33",
      },
    },
    {
      type: "Feature",
      id: "smdee84844",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99891104, 40.64828043],
            [-73.9988837, 40.648635],
            [-73.99979937, 40.64867564],
            [-73.99982671, 40.64832107],
            [-73.99891104, 40.64828043],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL32",
      },
    },
    {
      type: "Feature",
      id: "sm84bb87e1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99775377, 40.64856363],
            [-73.99773076, 40.64892888],
            [-73.99857709, 40.64896644],
            [-73.99860523, 40.64860142],
            [-73.99775377, 40.64856363],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL30",
      },
    },
    {
      type: "Feature",
      id: "sm3d58ed4a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99866413, 40.64783743],
            [-73.99868518, 40.64756445],
            [-73.99866994, 40.64756332],
            [-73.9985412, 40.64745749],
            [-73.99782508, 40.64743152],
            [-73.99780192, 40.64779916],
            [-73.99866413, 40.64783743],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL27",
      },
    },
    {
      type: "Feature",
      id: "smac56e8cf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99780192, 40.64779916],
            [-73.99777745, 40.64818772],
            [-73.99863473, 40.64821881],
            [-73.99866413, 40.64783743],
            [-73.99780192, 40.64779916],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL28",
      },
    },
    {
      type: "Feature",
      id: "sm75cfe574",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99777745, 40.64818772],
            [-73.99775377, 40.64856363],
            [-73.99860523, 40.64860142],
            [-73.99863473, 40.64821881],
            [-73.99777745, 40.64818772],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL29",
      },
    },
    {
      type: "Feature",
      id: "sm0f79411c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9957102, 40.648878],
            [-73.99558504, 40.65030246],
            [-73.99739588, 40.65039405],
            [-73.99965967, 40.65048763],
            [-73.99976906, 40.64906875],
            [-73.9957102, 40.648878],
          ],
        ],
      },
      properties: {
        Unit_Number: "JL22",
      },
    },
  ],
};

export default geojsonData;
