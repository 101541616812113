import { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { ChevronDown, ChevronUp } from "react-feather";
import Sidebar from "../components/sidebar/Sidebar";

// Mapping of questions to their full text
const questionMap = {
  understanding: "I understand what the product does.",
  knowHowToUse: "I know how to use the product.",
  usefulness: "How useful is our product to you?",
  experience: "Tell us about your experience using the product.",
  userFriendliness: "How would you rate the user-friendliness of the interface?",
  easeOfUse: "Overall, how easy to use do you find the product?",
  frustrations: "What do you find most frustrating about the product?",
  expectations: "To what extent does this product match your expectations?",
  helpfulness: "How do you think this product can help you?",
  importantElements: "What elements of this product are the most/least important to you?",
  desiredChanges: "What would you like to change about this product?",
  additionalFeatures: "Is there anything more you would like us to add to this product?",
  difficulties: "Were there any specific tasks that you found challenging?",
  usageFrequency: "How often do you use our product?",
  mostUsedFeatures: "What features do you use the most on the app?",
  navigationEase: "Did you find the navigation menu intuitive and easy to understand?",
  findability: "Were you able to quickly find what you were looking for?",
  taskChallenges: "Were there any specific tasks that you found challenging?",
  learningSpeed: "How quickly were you able to learn how to use the product?",
  informationClarity: "Were instructions and information provided clear and easy to understand?",
  screenGoal: "What are you trying to accomplish on this screen?",
  interfaceRating: "How would you rate the interface design of the website/app?",
  menuClarity: "Do the menu items make sense?",
  elementPosition: "Is the position of this element appropriate?",
  designSimplification: "What can we do to simplify this design?",
  removals: "Is there anything you would like us to remove?",
  layoutOrganization: "Does the screen layout look organized to you?",
  screenAdditions: "Is there anything you would like us to add to this screen?",
  navigationRating: "How would you like to rate the overall navigation experience?",
  pageLikeability: "Do you like how the page looks?",
  aestheticsRating: "How would you rate the overall aesthetics of the product?",
  recommendationLikelihood: "How likely are you to recommend our interface to your friends and colleagues?",
};

const SurveyReports = () => {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState({});
  const [expandedProgram, setExpandedProgram] = useState(null);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/survey"
        );
        const groupedPrograms = groupResponsesByProgram(response.data);
        setPrograms(groupedPrograms);
        calculateMetrics(groupedPrograms);
      } catch (error) {
        console.error("Error fetching survey responses:", error);
        setError("Failed to fetch survey responses");
      } finally {
        setLoading(false);
      }
    };

    fetchPrograms();
  }, []);

  const groupResponsesByProgram = (responses) => {
    const grouped = responses.reduce((acc, response) => {
      const { program } = response;
      if (!acc[program]) {
        acc[program] = [];
      }
      acc[program].push(response);
      return acc;
    }, {});

    return Object.entries(grouped).map(([program, responses]) => ({
      program,
      responses,
    }));
  };

  const calculateMetrics = (groupedPrograms) => {
    const metricsData = {};

    groupedPrograms.forEach(({ program, responses }) => {
      const questionCounts = {};

      responses.forEach((response) => {
        Object.entries(response).forEach(([question, answer]) => {
          if (question !== "program" && question !== "name" && question !== "id" && question !== "user_id") {
            if (!questionCounts[question]) {
              questionCounts[question] = {};
            }
            if (!questionCounts[question][answer]) {
              questionCounts[question][answer] = 0;
            }
            questionCounts[question][answer] += 1;
          }
        });
      });

      metricsData[program] = questionCounts;
    });

    setMetrics(metricsData);
  };

  const toggleExpand = (programName) => {
    setExpandedProgram(expandedProgram === programName ? null : programName);
  };

  return (
    <Sidebar>
    <div className="min-h-screen bg-gradient-to-br from-violet-600 to-indigo-600 p-8 w-full">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="w-full bg-white shadow-lg rounded-lg overflow-x-scroll max-w-4xl mx-auto">
          <h1 className="text-2xl font-bold text-center mb-6">Survey Reports</h1>
          {programs.map((program) => (
            <div key={program.program} className="mb-8">
              <div className="flex justify-between items-center p-4 bg-slate-100 rounded-lg">
                <h2 className="text-xl font-bold">{program.program}</h2>
                <button
                  className="hover:text-violet-600"
                  onClick={() => toggleExpand(program.program)}
                >
                  {expandedProgram === program.program ? <ChevronUp /> : <ChevronDown />}
                </button>
              </div>
              {expandedProgram === program.program && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  className="overflow-hidden transition-all duration-300"
                >
                  <ProgramMetrics program={program} metrics={metrics[program.program]} />
                </motion.div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
    </Sidebar>
  );
};

const ProgramMetrics = ({ program, metrics }) => {
  return (
    <div className="p-4">
      <table className="w-full mb-4">
        <thead>
          <tr className="border-b-[1px] border-slate-200 text-slate-400 text-sm uppercase">
            <th className="text-start p-4 font-medium">Question</th>
            <th className="text-start p-4 font-medium">Most Common Answer</th>
            <th className="text-start p-4 font-medium">Frequency</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(metrics).map(([question, answers]) => {
            const mostCommonAnswer = Object.entries(answers).reduce(
              (acc, [answer, count]) => {
                if (!acc || count > acc.count) {
                  return { answer, count };
                }
                return acc;
              },
              null
            );

            return (
              <tr key={question} className="text-sm">
                <td className="p-4">{questionMap[question] || question}</td>
                <td className="p-4">{mostCommonAnswer?.answer || "N/A"}</td>
                <td className="p-4">{mostCommonAnswer?.count || 0}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SurveyReports;
