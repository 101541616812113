import React, { useState } from "react";
import { ArrowRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useSelector } from "react-redux";

const FocusOfWeek = () => {
  const [step, setStep] = useState(1);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [expected_mib, setExpectedMib] = useState("");
  const [expected_sales, setExpectedSales] = useState("");
  const [customActivitiesEnabled, setCustomActivitiesEnabled] = useState(false);
  const [custom_activity, setCustomActivities] = useState("");

  const navigate = useNavigate();

  // Fetch the current user's ID from the Redux store
  const staff_id = useSelector((state) => state.user.user.user_id);

  // Calculate progress percentage based on step
  const progress = (step - 1) * 50;

  const handleSubmit = async (event) => {
    event.preventDefault();

    // If start date or end date is missing, prevent further action
    if (!start_date || !end_date) {
      toast.error("Please provide both start date and end date.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Check if end date is before start date
    if (new Date(end_date) < new Date(start_date)) {
      toast.error("End date cannot be before start date.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!title) {
      toast.error("Title is required.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!description) {
      toast.error("Description is required.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!expected_mib) {
      toast.error("Expected MIB is required.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!expected_sales) {
      toast.error("Expected sales is required.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const response = await fetch("https://workspace.optiven.co.ke/api/fow", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date,
          end_date,
          title,
          description,
          expected_mib,
          expected_sales,
          custom_activity: customActivitiesEnabled ? custom_activity : "",
          staff_id,
        }),
      });

      if (response.ok) {
        navigate("/view-fow");
        toast.success("Focus of the Week created successfully!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "An error occurred.");
      }
    } catch (error) {
      console.error("Error creating Focus of the Week:", error);
      toast.error(
        error.message || "An error occurred. Please try again later.",
        {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <Sidebar>
    <div className="flex justify-center items-center h-screen">
      <div>
        {/* Breadcrumbs */}
        <div className="text-sm breadcrumbs mb-4 flex justify-left">
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              <a href="/create-fow">Focus of the Week</a>
            </li>
          </ul>
        </div>

        <div className="card-body">
          <h2 className="card-title">Focus Of The Week</h2>
          <progress
            className="progress progress-success w-80"
            value={progress}
            max="100"
          ></progress>
          {step === 1 && (
            <form>
              <div>
                <label className="label font-bold text-sm">Start Date:</label>
                <input
                  type="date"
                  className="input input-bordered w-80 max-w-full mb-4"
                  value={start_date}
                  onChange={(e) => setStart_date(e.target.value)}
                />
              </div>
              <div>
                <label className="label font-bold text-sm">End Date:</label>
                <input
                  type="date"
                  className="input input-bordered w-80 max-w-full mb-4"
                  value={end_date}
                  onChange={(e) => setEnd_date(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary block mx-auto mb-4"
                onClick={() => {
                  // Check if both start date and end date are provided
                  if (!start_date || !end_date) {
                    toast.error(
                      "Please provide both start date and end date.",
                      {
                        position: "top-center",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                    return;
                  }

                  // Check if end date is before start date
                  if (new Date(end_date) < new Date(start_date)) {
                    toast.error("End date cannot be before start date.", {
                      position: "top-center",
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    return;
                  }

                  // If validation passes, proceed to the next step
                  setStep(step + 1);
                }}
              >
                <ArrowRight />
              </button>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleSubmit}>
              <label className="label font-bold text-sm">Title:</label>
              <input
                type="text"
                className="input input-bordered w-80 max-w-full mb-4"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
              />
              <label className="label font-bold text-sm">Description:</label>
              <textarea
                className="textarea textarea-success w-80 max-w-full mb-4"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              ></textarea>
              <label className="label font-bold text-sm">
                Expected MIB:
              </label>
              <textarea
                className="textarea textarea-success w-80 max-w-full mb-4"
                value={expected_mib}
                onChange={(e) => setExpectedMib(e.target.value)}
                placeholder="Expected MIB"
              ></textarea>
              <label className="label font-bold text-sm">
                Expected Sales:
              </label>
              <textarea
                className="textarea textarea-success w-80 max-w-full mb-4"
                value={expected_sales}
                onChange={(e) => setExpectedSales(e.target.value)}
                placeholder="Expected Sales"
              ></textarea>
               {/* Checkbox to enable custom activities */}
               <div className="form-control">
                  <label className="label cursor-pointer">
                    <span className="label-text">Custom Activities</span>
                    <input type="checkbox" className="checkbox" checked={customActivitiesEnabled} onChange={(e) => setCustomActivitiesEnabled(e.target.checked)} />
                  </label>
                </div>

                {/* Textarea for custom activities, shown only when checkbox is checked */}
                {customActivitiesEnabled && (
                  <div className="form-control">
                    
                    <textarea
                      className="textarea textarea-success w-80 max-w-full mb-4"
                      value={custom_activity}
                      onChange={(e) => setCustomActivities(e.target.value)}
                      placeholder="Describe custom activities"
                    ></textarea>
                  </div>
                )}
          
              
              <button
                type="submit"
                className="btn btn-outline btn-success block mx-auto"
              >
                Submit
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
    </Sidebar>
  );
};

export default FocusOfWeek;
