import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import formatDate from "../../utils/formatDate";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ValueAdditionFilterPayments = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [projects, setProjects] = useState([]);
  const[totalAmount,setTotalAmount] = useState(0)
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const valueAddition = accessRole.includes("valueAddition");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");

  const formatDateForAPI = (date) => {
    // Format the date for your API endpoint (assuming UTC timezone)
    const formattedDate = new Date(date)
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    return formattedDate;
  };

  const handleSingleDateFilter = async () => {
    try {
      const formattedDate = formatDateForAPI(selectedDate);

      const response = await axios.get(
        "https://workspace.optiven.co.ke/api/value_addition_payments/value-addition-filter",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            date: formattedDate,
          },
        }
      );
      console.log(response.data);
      setFilteredPayments(response.data);
    } catch (error) {
      console.error("Error filtering payments:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  const handleDateRangeFilter = async () => {
    try {
      const formattedStartDate = formatDateForAPI(startDate);
      const formattedEndDate = formatDateForAPI(endDate);

      const response = await axios.get(
        "https://workspace.optiven.co.ke/api/value_addition_payments/value-addition-filter-range",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
        }
      );

      setFilteredPayments(response.data);
    } catch (error) {
      console.error("Error filtering payments by date range:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/projects/all",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProjects();
  }, []); // Fetch projects only once when the component mounts

  const getProjectName = (projectData) => {
    const project = projects.find(
      (proj) => proj.id === parseInt(projectData, 10)
    );
    return project ? project.project_name : "Unknown Project";
  };

// download by excel
const handleDownloadExcel = () => {
    // Header row
    const headerRow = [
      "Project",
      "Name",
      "Contact",
      "ID Number",
      "Description",
      "Quantity",
      "Rate per Day",
      "Amount",
      
    ];
  
    // Data rows
    const data = filteredPayments.map((payment) => [
      getProjectName(payment.project_data),
      payment.name,
      payment.phone,
      payment.id_number,
      payment.work_description,
      payment.work_days,
      payment.rate_per_day,
      payment.total_amount,
      
    ]);
  
    // Add the total amount row
    const totalAmountRow = [
      "Total Amount", // Title for the total amount
      "",
      "",
      "",
      "",
      "",
      "",
      totalAmount, // Actual total amount value
     
    ];
  
    // Combine header row, data rows, and the total amount row
    const completeData = [headerRow, ...data, totalAmountRow];
  
    const ws = XLSX.utils.aoa_to_sheet(completeData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "FilteredPayments");
    XLSX.writeFile(wb, "filtered_payments.xlsx");
  };
   // handle download pdf
   const dataToPdfRows = (data) => {
    return data.map((payment) => [
      { text: getProjectName(payment.project_data), style: "tableCell" },
      { text: payment.name, style: "tableCell" },
      { text: payment.phone, style: "tableCell" },
      { text: payment.id_number, style: "tableCell" },
      { text: payment.work_description, style: "tableCell" },
      { text: payment.work_days, style: "tableCell" },
      { text: payment.rate_per_day, style: "tableCell" },
      { text: payment.total_amount, style: "tableCell" },
    ]);
  };
  const handleDownloadPDF = () => {
    const docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      content: [
        {
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
            body: [
              [
                { text: "Project Name", style: "tableHeader" },
                { text: " Name", style: "tableHeader" },
                { text: " Contact", style: "tableHeader" },
                { text: "Id Number", style: "tableHeader" },
                { text: "Work Description", style: "tableHeader" },
                { text: "Number of Work Days", style: "tableHeader" },
                { text: "Rate per day", style: "tableHeader" },
                { text: "Total Paid", style: "tableHeader" },
              ],
              ...dataToPdfRows(filteredPayments),
              [
                { text: "Total Payments", style: "tableCell", colSpan: 7 },
                {},
                {},
                {},
                {},
                {},
                {},
                { text: totalAmount.toFixed(2), style: "tableCell" }, // Display total amount with two decimal places
              ],
            ],
          },
        },
      ],
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 12,
          fillColor: "#CCCCCC",
        },
        tableCell: {
          fontSize: 10,
        },
      },
    };

    pdfMake.createPdf(docDefinition).open(); // Open PDF in a new tab
  };
  
  
  //reduce method
  useEffect(()=>{
    const total = filteredPayments.reduce((sum,filteredPayment) => sum +  filteredPayment.total_amount,0);
    setTotalAmount(total)
  },[filteredPayments])
  return (
    <>
      <Sidebar>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <h2 className="text-2xl font-bold mb-4 font-serif">Filter Payments</h2>
          {/* all the payments */}

          <div className="button-gap mb-4">
            <Link to="/view-value-addition-payments">
              <button className="btn btn-active mr-4 font-serif">All Payments</button>
            </Link>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Single Date Filter */}
            <div className="mb-4  ">
              <label htmlFor="singleDateFilter" className="text-gray-700 px-2  text-xl font-serif">
                Select Single Date:
              </label>
              <input
                type="date"
                id="singleDateFilter"
                className="border rounded py-2 px-3  text-xl font-serif"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
            <div>
              <button
                onClick={handleSingleDateFilter}
                className="bg-blue-500 text-white text-xl font-serif py-2 px-4 rounded ml-4 sm:ml-0 mt-2 sm:mt-0 mb-4"
              >
                Filter Payments by Single Date
              </button>
            </div>
          </div>

          {/* Date Range Filter */}

          <div className="mb-4">
            <label htmlFor="startDateFilter" className="text-gray-700 px-3  text-xl font-serif ">
              Select Start Date:
            </label>
            <input
              type="date"
              id="startDateFilter"
              className="border rounded py-2 px-3  text-xl font-serif"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          {/* End Date */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-2">
              <label htmlFor="endDateFilter" className="text-gray-700 px-3  text-xl font-serif">
                Select End Date:
              </label>
              <input
                type="date"
                id="endDateFilter"
                className="border rounded py-2 px-3  text-xl font-serif"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            <div>
              <button
                onClick={handleDateRangeFilter}
                className="bg-blue-500 text-white  text-xl font-serif py-2 px-4 rounded ml-4 sm:ml-0 mt-4 sm:mt-0"
              >
                Filter Payments by Date Range
              </button>
            </div>
          </div>

          <div>
          {!operations &&
            (valueAddition || valueAdmin || department === "ICT SYSTEMS") ? (
            <button
              onClick={handleDownloadExcel}
              className="bg-green-500 text-white py-2 px-4 rounded mt-4 sm:mt-2 mr-4 "
            >
              Download Excel
            </button>
            ) : null}
            <button
              onClick={handleDownloadPDF}
              className="bg-red-500 text-white py-2 px-4 rounded mt-4  sm:mt-2"
            >
              Download PDF
            </button>
          </div>

          {/* Display filtered payments */}
          <div className="mt-4">
            <h3 className="text-xl font-bold mb-2 font-serif">Filtered Payments</h3>
            <table className="table-auto w-full ">
              <thead>
                <tr className="font-serif">
                  <th>#</th>
                  <th className="px-4 py-2">Project</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Contact</th>
                  <th className="px-4 py-2">ID Number</th>
                  <th className="px-4 py-2">Description</th>
                  <th className="px-4 py-2">Quantity</th>
                  <th className="px-4 py-2">Rate per Day</th>
                  <th className="px-4 py-2">Total Amount</th>
                  <th className="px-4 py-2">Date</th>
                </tr>
              </thead>
              <tbody>
                {filteredPayments.map((payment,index) => (
                  <tr key={payment.id}>
                  <td className="border px-4 py-2">{index + 1}</td>
                    <td className="border px-4 py-2">
                      {getProjectName(payment.project_data)}
                    </td>
                    <td className="border px-4 py-2">{payment.name}</td>
                    <td className="border px-4 py-2">{payment.phone}</td>
                    <td className="border px-4 py-2">{payment.id_number}</td>

                    <td className="border px-4 py-2">{payment.work_description}</td>
                    <td className="border px-4 py-2">{payment.work_days}</td>
                    <td className="border px-4 py-2">{payment.rate_per_day}</td>

                    <td className="border px-4 py-2">{payment.total_amount}</td>
                    <td className="border px-4 py-2">
                      {formatDate(payment.date)}
                    </td>
                  </tr>
                ))}
                <tr>
                <td colSpan="7" className="border px-4 py-2">
                  {/* Leave this cell empty */}
                </td>
                <td className="text-right font-bold px-4 py-2 border font-serif">
                  Total Amount:
                </td>
                <td colSpan="1" className="border px-4 py-2 font-[Poppin] font-bold text-2xl">
                  {totalAmount}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default ValueAdditionFilterPayments;
