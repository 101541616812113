import axios from "axios";
import React, { useEffect, useMemo, useState, } from "react";
import { useNavigate, } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import _404cat from "../assets/svgs/404-cat.svg";
import { useSelector } from "react-redux";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  // SEARCHBAR: Initialize the search query to an empty string
  const [query, setQuery] = useState("");
  const isCaretaker = useSelector((state) => state.user.user.role === 'caretaker');
  const isSupervisor = useSelector((state) => state.user.user.role === 'supervisor');
  const userId = useSelector((state) => state.user.user.user_id);
  
  console.log(userId)

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        let url;
  
        // Determine the API endpoint based on the type of user
        if (isCaretaker) {
          url = `https://workspace.optiven.co.ke/api/projects?caretakerId=${userId}`;
        } else if (isSupervisor) {
          url = `https://workspace.optiven.co.ke/api/projects?supervisorId=${userId}`;
        } else {
          url = "https://workspace.optiven.co.ke/api/projects/all";
        }
  
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
  
        console.log(response.data);
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchProjects();
  }, [isCaretaker, isSupervisor, userId]);
  

  // SEARCHBAR: First filter the array of items(projects) to return an array that does not include the value of the query
  // SEARCHBAR: Memoize the filtered items, to only re-render when the value of the input or projects changes
  const filteredProjects = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      projects.filter((project) => {
        if (query === "") {
          return project;
        } else if (
          project.project_name.toLowerCase().includes(query.toLowerCase())
        ) {
          return project;
        }
      }),
    [query, projects]
  );

  const openProject = (id) => {
    navigate(`/projects/${id}`);
  };

  return (
    <>
      <Sidebar>
        <div className="flex justify-center items-center m-3">
          <input
            placeholder="Search Project by Name"
            className="input input-bordered input-success w-full max-w-xs"
            type="text"
            // SEARCHBAR: Create an onChange event which sets the query to whatever the value of the input is when it changes
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="container mx-auto mt-10">
          <div className="grid mx-5 gap-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {filteredProjects.length === 0 ? (
              <>
                <div></div>
                <div className="flex flex-col justify-center items-center w-full">
                  <img
                    src={_404cat}
                    alt="not-found"
                    className="max-w-lg lg:w-4/5"
                  />
                  <h1 className="text-black font-bold text-center">
                    Wow, such empty! Add some projects to get started
                  </h1>
                </div>
                <div></div>
              </>
            ) : (
              filteredProjects.map((project) => (
                <div
                  className="duration-300 transform bg-white shadow-lg rounded-lg cursor-pointer hover:-translate-y-2"
                  onClick={() => openProject(project.id)}
                  key={project.id}
                >
                  <div className="px-4 py-2 flex justify-between">
                    <div className="h-20">
                      <h2 className="font-bold text-xl mb-2">
                        {project.project_name}
                      </h2>
                      <p className="text-gray-700 text-base italic">
                        {project.location}
                      </p>
                    </div>
                    <div className="h-20 flex flex-col items-end">
                      <p className="text-gray-700 text-base italic">
                        {project.size_in_acres} acres
                      </p>
                      <h2
                        className="font-bold text-xl mt-2"
                        style={
                          project.level === "Gold"
                            ? { color: "#FFD700" } // gold
                            : project.level === "Silver"
                            ? { color: "#C0C0C0" } // silver
                            : project.level === "Sapphire"
                            ? { color: "#082567" } // sapphire
                            : project.level === "Platinum"
                            ? { color: "#E5E4E2" } // platinum
                            : project.level === "Bronze"
                            ? { color: "#CD7F32" } // bronze
                            : {}
                        }
                      >
                        {project.level}
                      </h2>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default Projects;
