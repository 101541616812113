import { useState, useEffect } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { motion } from "framer-motion";
import axios from "axios";
import Sidebar from "../components/sidebar/Sidebar";

const ViewSurvey = () => {
  const [programs, setPrograms] = useState([]);
  const [expandedProgram, setExpandedProgram] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/survey"
        );
        const groupedPrograms = groupResponsesByProgram(response.data);
        setPrograms(groupedPrograms);
      } catch (error) {
        console.error("Error fetching survey responses:", error);
        setError("Failed to fetch survey responses");
      } finally {
        setLoading(false);
      }
    };

    fetchPrograms();
  }, []);

  const groupResponsesByProgram = (responses) => {
    const grouped = responses.reduce((acc, response) => {
      const { program } = response;
      if (!acc[program]) {
        acc[program] = [];
      }
      acc[program].push(response);
      return acc;
    }, {});

    return Object.entries(grouped).map(([program, responses]) => ({
      program,
      responses,
    }));
  };

  const toggleExpand = (programName) => {
    setExpandedProgram(expandedProgram === programName ? null : programName);
  };

  return (
    <Sidebar>
    <div className="min-h-screen bg-gradient-to-br from-violet-600 to-indigo-600 p-8 w-full">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="w-full bg-white shadow-lg rounded-lg overflow-x-scroll max-w-4xl mx-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b-[1px] border-slate-200 text-slate-400 text-sm uppercase">
                <th className="pl-4 w-8"></th>
                <th className="text-start p-4 font-medium">Program</th>
                <th className="text-start p-4 font-medium">Responses</th>
              </tr>
            </thead>
            <tbody>
              {programs.map((program) => (
                <ProgramRow
                  key={program.program}
                  program={program}
                  isExpanded={expandedProgram === program.program}
                  toggleExpand={toggleExpand}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </Sidebar>
  );
};

const ProgramRow = ({ program, isExpanded, toggleExpand }) => {
  return (
    <>
      <tr className={`text-sm ${program.program % 2 ? "bg-slate-100" : "bg-white"}`}>
        <td className="pl-4 w-8">
          <button
            className="hover:text-violet-600"
            onClick={() => toggleExpand(program.program)}
          >
            {isExpanded ? <ChevronUp /> : <ChevronDown />}
          </button>
        </td>
        <td className="p-4">{program.program}</td>
        <td className="p-4">{program.responses.length} Responses</td>
      </tr>
      {isExpanded && (
        <tr className="bg-gray-100 text-sm">
          <td colSpan="3" className="p-4">
            <ProgramDetails program={program} />
          </td>
        </tr>
      )}
    </>
  );
};

const ProgramDetails = ({ program }) => {
  const questionMap = {
    program: "Program",
    understanding: "I understand what the product does.",
    knowHowToUse: "I know how to use the product.",
    usefulness: "How useful is our product to you?",
    experience: "Tell us about your experience using the product.",
    userFriendliness: "How would you rate the user-friendliness of the interface?",
    easeOfUse: "Overall, how easy to use do you find the product?",
    frustrations: "What do you find most frustrating about the product?",
    expectations: "To what extent does this product match your expectations?",
    helpfulness: "How do you think this product can help you?",
    importantElements: "What elements of this product are the most/least important to you?",
    desiredChanges: "What would you like to change about this product?",
    additionalFeatures: "Is there anything more you would like us to add to this product?",
    difficulties: "Were there any specific tasks that you found challenging?",
    usageFrequency: "How often do you use our product?",
    mostUsedFeatures: "What features do you use the most on the app?",
    navigationEase: "Did you find the navigation menu intuitive and easy to understand?",
    findability: "Were you able to quickly find what you were looking for?",
    taskChallenges: "Were there any specific tasks that you found challenging?",
    learningSpeed: "How quickly were you able to learn how to use the product?",
    informationClarity: "Were instructions and information provided clear and easy to understand?",
    screenGoal: "What are you trying to accomplish on this screen?",
    interfaceRating: "How would you rate the interface design of the website/app?",
    menuClarity: "Do the menu items make sense?",
    elementPosition: "Is the position of this element appropriate?",
    designSimplification: "What can we do to simplify this design?",
    removals: "Is there anything you would like us to remove?",
    layoutOrganization: "Does the screen layout look organized to you?",
    screenAdditions: "Is there anything you would like us to add to this screen?",
    navigationRating: "How would you like to rate the overall navigation experience?",
    pageLikeability: "Do you like how the page looks?",
    aestheticsRating: "How would you rate the overall aesthetics of the product?",
    recommendationLikelihood: "How likely are you to recommend our interface to your friends and colleagues?",
  };

  const headings = {
    general: "General Usability and Experience",
    design: "Design and Aesthetics",
    engagement: "Engagement and Usage Frequency",
    satisfaction: "User Satisfaction Survey",
  };

  const questionsByHeading = {
    general: [
      "understanding",
      "knowHowToUse",
      "usefulness",
      "experience",
      "userFriendliness",
      "easeOfUse",
      "frustrations",
      "expectations",
    ],
    design: [
      "helpfulness",
      "importantElements",
      "desiredChanges",
      "additionalFeatures",
      "difficulties",
    ],
    engagement: [
      "usageFrequency",
      "mostUsedFeatures",
      "navigationEase",
      "findability",
      "taskChallenges",
      "learningSpeed",
      "informationClarity",
      "screenGoal",
    ],
    satisfaction: [
      "interfaceRating",
      "menuClarity",
      "elementPosition",
      "designSimplification",
      "removals",
      "layoutOrganization",
      "screenAdditions",
      "navigationRating",
      "pageLikeability",
      "aestheticsRating",
      "recommendationLikelihood",
    ],
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {program.responses.map((response, index) => (
        <div key={index} className="bg-violet-100 p-4 rounded-lg mb-4">
          <h3 className="font-bold text-lg mb-2">
            Response from {response.name}
          </h3>
          {Object.entries(headings).map(([headingKey, headingValue]) => (
            <div key={headingKey} className="mb-4">
              <h4 className="font-semibold text-indigo-800 mb-2 underline text-center text-2xl">
                {headingValue}
              </h4>
              <div className="grid grid-cols-2 gap-4">
                {questionsByHeading[headingKey].map((questionKey) => (
                  <p key={questionKey}>
                    <strong>{questionMap[questionKey]}:</strong>{" "}
                    {response[questionKey]}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </motion.div>
  );
};

export default ViewSurvey;
