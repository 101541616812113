import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialClientState = {
  client_name: '',
  id_no: '',
  email: '',  // Added email to the initial state
  LR_no: '',
  plot_size: '',
  type: 'Mansion',
  cost: '480000'
};

const ClientModal = ({ isOpen, setIsOpen, addClient }) => {
  const [clientData, setClientData] = useState(initialClientState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'type') {
      const costs = { Mansion: '480000', Bungalow: '380000', Custom: '' };
      setClientData(prev => ({
        ...prev,
        [name]: value,
        cost: costs[value]
      }));
    } else {
      setClientData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = () => {
    if (
      !clientData.client_name ||
      !clientData.id_no ||
      !clientData.email ||
      !clientData.LR_no ||
      !clientData.plot_size ||
      !clientData.cost
    ) {
      toast.error('Please fill all the fields');
      return;
    }
    addClient({
      ...clientData,
      title: clientData.client_name,
      description: `ID: ${clientData.id_no}, Email: ${clientData.email}, Land Ref: ${clientData.LR_no}, Size: ${clientData.plot_size}, Type: ${clientData.type}, Cost: ${clientData.cost}`,
    });
    toast.success('Client added successfully!');
    setClientData(initialClientState);
    setIsOpen(false);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/30 backdrop-blur-sm flex justify-center items-center z-50"
          onClick={() => setIsOpen(false)}
        >
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.5 }}
            className="bg-white p-6 rounded-lg w-full max-w-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Add New Client</h2>
              <button onClick={() => setIsOpen(false)}><X /></button>
            </div>
            <form className="space-y-4 mt-4" onSubmit={(e) => e.preventDefault()}>
              <input type="text" name="client_name" placeholder="Client Name" className="w-full p-2 border rounded" value={clientData.client_name} onChange={handleInputChange} />
              <input type="text" name="id_no" placeholder="ID Number" className="w-full p-2 border rounded" value={clientData.id_no} onChange={handleInputChange} />
              <input type="email" name="email" placeholder="Email" className="w-full p-2 border rounded" value={clientData.email} onChange={handleInputChange} />
              <input type="text" name="LR_no" placeholder="Land Reference No" className="w-full p-2 border rounded" value={clientData.LR_no} onChange={handleInputChange} />
              <input type="text" name="plot_size" placeholder="Plot Size" className="w-full p-2 border rounded" value={clientData.plot_size} onChange={handleInputChange} />
              <select name="type" className="w-full p-2 border rounded" value={clientData.type} onChange={handleInputChange}>
                <option value="Mansion">Mansion</option>
                <option value="Bungalow">Bungalow</option>
                <option value="Custom">Custom</option>
              </select>
              {clientData.type === 'Custom' && (
                <input type="text" name="cost" placeholder="Enter custom cost" className="w-full p-2 border rounded" value={clientData.cost} onChange={handleInputChange} />
              )}
              <button type="button" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600" onClick={handleSubmit}>Add Client</button>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ClientModal;
