import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useMeasure from "react-use-measure";
import { Filter, X } from "react-feather";
import Modal from "react-modal";
import {
  useDragControls,
  useMotionValue,
  useAnimate,
  motion,
} from "framer-motion";
import { toast } from "react-toastify";

// Utility functions for formatting date and time
const formatDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date
    .toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-");
};

const formatTime = (timeString) => {
  if (!timeString) return null;
  const time = new Date(`1970-01-01T${timeString}`);
  return time.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

// DragCloseDrawer Component for the details drawer
const DragCloseDrawer = ({ open, setOpen, children }) => {
  const [scope, animate] = useAnimate();
  const [drawerRef, { height }] = useMeasure();
  const y = useMotionValue(0);
  const controls = useDragControls();

  const handleClose = async () => {
    await new Promise((resolve) => setTimeout(resolve, 50)); // 50ms delay

    if (!scope.current) {
      console.error("Element associated with the scope is not found.");
      return;
    }

    await animate(scope.current, {
      opacity: [1, 0],
    });

    const yStart = typeof y.get() === "number" ? y.get() : 0;

    await animate("#drawer", {
      y: [yStart, height],
    });

    setOpen(false);
  };

  return (
    <>
      {open && (
        <motion.div
          ref={scope}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={handleClose}
          className="fixed inset-0 z-50 bg-neutral-950/70"
        >
          <motion.div
            id="drawer"
            ref={drawerRef}
            onClick={(e) => e.stopPropagation()}
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{
              ease: "easeInOut",
            }}
            className="absolute bottom-0 h-[75vh] w-full overflow-hidden rounded-t-3xl bg-neutral-900"
            style={{ y }}
            drag="y"
            dragControls={controls}
            onDragEnd={() => {
              if (y.get() >= 100) {
                handleClose();
              }
            }}
            dragListener={false}
            dragConstraints={{
              top: 0,
              bottom: 0,
            }}
            dragElastic={{
              top: 0,
              bottom: 0.5,
            }}
          >
            <div className="absolute left-0 right-0 top-0 z-10 flex justify-center bg-neutral-900 p-4">
              <button
                onPointerDown={(e) => {
                  controls.start(e);
                }}
                className="h-2 w-14 cursor-grab touch-none rounded-full bg-neutral-700 active:cursor-grabbing"
              ></button>
            </div>
            <div className="relative z-0 h-full overflow-y-scroll p-4 pt-12">
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

// Modal for rating candidates
const RatingModal = ({ isOpen, onClose, onSubmit }) => {
  const [rating, setRating] = useState(5);

  const handleSubmit = () => {
    if (rating >= 1 && rating <= 10) {
      onSubmit(rating);
      onClose();
    } else {
      toast.error("Please select a rating between 1 and 10.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          <X size={24} />
        </button>
        <h3 className="text-lg font-bold">Rate the Candidate</h3>

        <input
          type="range"
          min={1}
          max={10}
          value={rating}
          onChange={(e) => setRating(Number(e.target.value))}
          className="range range-purple mt-4"
          step={1}
        />
        <div className="flex w-full justify-between px-2 text-xs text-purple-600">
          <span>1</span>
          <span>2</span>
          <span>3</span>
          <span>4</span>
          <span>5</span>
          <span>6</span>
          <span>7</span>
          <span>8</span>
          <span>9</span>
          <span>10</span>
        </div>

        <button onClick={handleSubmit} className="btn btn-primary mt-4">
          Submit Rating
        </button>
      </div>
    </Modal>
  );
};

// Modal for progressing candidates
const ProgressionModal = ({ isOpen, onClose, onProceed }) => {
  const [nextDate, setNextDate] = useState("");
  const [nextTime, setNextTime] = useState("");

  const handleProceed = () => {
    if (nextDate && nextTime) {
      onProceed(nextDate, nextTime);
      onClose();
    } else {
      toast.error("Please enter both date and time for the next interview.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          <X size={24} />
        </button>
        <h3 className="text-lg font-bold">Proceed to Next Level</h3>

        <input
          type="date"
          value={nextDate}
          onChange={(e) => setNextDate(e.target.value)}
          className="w-full mt-2 p-2 border"
          placeholder="Next Interview Date"
        />
        <input
          type="time"
          value={nextTime}
          onChange={(e) => setNextTime(e.target.value)}
          className="w-full mt-2 p-2 border"
          placeholder="Next Interview Time"
        />
        <button onClick={handleProceed} className="btn btn-primary mt-4">
          Proceed
        </button>
      </div>
    </Modal>
  );
};

// Modal for dropping candidates
const DropModal = ({ isOpen, onClose, onDrop }) => {
  const [dropReason, setDropReason] = useState("");

  const handleDrop = () => {
    if (dropReason) {
      onDrop(dropReason);
      onClose();
    } else {
      toast.error("Please enter a reason for dropping the candidate.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          <X size={24} />
        </button>
        <h3 className="text-lg font-bold">Drop Candidate</h3>
        <textarea
          value={dropReason}
          onChange={(e) => setDropReason(e.target.value)}
          className="w-full mt-2 p-2 border"
          placeholder="Reason for dropping"
        />
        <button onClick={handleDrop} className="btn btn-error mt-4">
          Drop
        </button>
      </div>
    </Modal>
  );
};

// Modal for recommending candidates
const RecommendModal = ({ isOpen, onClose, onRecommend }) => {
  const [comment, setComment] = useState("");
  const [recommend, setRecommend] = useState(false);

  const handleSubmit = () => {
    if (comment.trim() === "") {
      toast.error("Please enter a comment.");
      return;
    }
    onRecommend(comment, recommend);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          <X size={24} />
        </button>
        <h3 className="text-lg font-bold">Recommend Candidate</h3>

        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="w-full mt-2 p-2 border"
          placeholder="Enter your comment"
        />

        <div className="mt-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={recommend}
              onChange={() => setRecommend(true)}
              className="mr-2"
            />
            Recommend
          </label>
          <label className="flex items-center mt-2">
            <input
              type="checkbox"
              checked={!recommend}
              onChange={() => setRecommend(false)}
              className="mr-2"
            />
            Do Not Recommend
          </label>
        </div>

        <button onClick={handleSubmit} className="btn btn-info mt-4">
          Confirm Recommendation
        </button>
      </div>
    </Modal>
  );
};

// Main component
const ViewScheduledInterviews = () => {
  const [interviews, setInterviews] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [positionSearch, setPositionSearch] = useState("");
  const [levelSearch, setLevelSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [progressionModalOpen, setProgressionModalOpen] = useState(false);
  const [dropModalOpen, setDropModalOpen] = useState(false);
  const [recommendModalOpen, setRecommendModalOpen] = useState(false);
  const [hasRated, setHasRated] = useState(false); // New state to track if the candidate has been rated
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.user.user_id); // Correctly get user ID
  const navigate = useNavigate();

  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/interviews",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        if (Array.isArray(data)) {
          setInterviews(data);
        } else {
          console.error("Invalid response format. Expected an array.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchInterviews();
  }, [token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePositionSearch = (e) => {
    setPositionSearch(e.target.value);
  };

  const handleLevelSearch = (e) => {
    setLevelSearch(e.target.value);
  };

  const handleStatusSearch = (e) => {
    setStatusSearch(e.target.value);
  };

  const filteredInterviews = interviews.filter((interview) => {
    const matchesSearchTerm =
      (interview.name &&
        interview.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (interview.phone_number && interview.phone_number.includes(searchTerm));

    const matchesPosition =
      !positionSearch ||
      interview.position.toLowerCase().includes(positionSearch.toLowerCase());

    const matchesLevel =
      !levelSearch ||
      interview.current_level.toLowerCase().includes(levelSearch.toLowerCase());

    const matchesStatus =
      !statusSearch ||
      (interview.status &&
        interview.status.toLowerCase().includes(statusSearch.toLowerCase()));

    return (
      matchesSearchTerm && matchesPosition && matchesLevel && matchesStatus
    );
  });

  const handleAdmit = async (interviewId) => {
    try {
      const currentTime = new Date();
      const hours = currentTime.getHours().toString().padStart(2, "0");
      const minutes = currentTime.getMinutes().toString().padStart(2, "0");
      const seconds = currentTime.getSeconds().toString().padStart(2, "0");
      const currentTimeString = `${hours}:${minutes}:${seconds}`;

      const response = await fetch(
        `https://workspace.optiven.co.ke/api/interviews/admit/${interviewId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ report_time: currentTimeString }),
        }
      );

      if (response.ok) {
        toast.success("Candidate admitted successfully.");

        // Refresh the page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Failed to admit candidate.");
      }
    } catch (error) {
      console.error("Error admitting candidate:", error);
      toast.error("An error occurred while admitting the candidate.");
    }
  };

  const deleteInterview = (interviewId) => {
    fetch(`https://workspace.optiven.co.ke/api/interviews/${interviewId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setInterviews((prevInterviews) =>
          prevInterviews.filter((interview) => interview.id !== interviewId)
        );
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  // const handleDetailsClick = (interview) => {
  //   setSelectedInterview(interview);
  //   refreshInterviewDetails(interview.id); // Load interview details and rating status on details click
  //   setDrawerOpen(true);
  // };

  const handleRateCandidate = async (rating) => {
    try {
      const payload = {
        rating,
        userId: userId, // Correctly pass userId
      };
  
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/interviews/rate/${selectedInterview.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (response.ok) {
        setHasRated(true); // Ensure hasRated is set to true
        setRatingModalOpen(false);
        await refreshInterviewDetails(selectedInterview.id); // Fetch updated interview data
        toast.success("Rating submitted successfully.");
      } else {
        // Log the response status and text for debugging
        const errorData = await response.json();
        console.error("Failed to rate candidate:", errorData);
        toast.error("Failed to rate candidate.");
      }
    } catch (error) {
      toast.error("An error occurred while rating the candidate.");
      console.error("An error occurred while rating the candidate:", error);
    }
  };
  

  // Ensure that `hasRated` and decision logic is correctly checked to display the buttons
  const handleDetailsClick = (interview) => {
    setSelectedInterview(interview);
    refreshInterviewDetails(interview.id); // Load interview details and rating status on details click
    setDrawerOpen(true);
  };

  // Function to refresh interview details, including whether the current user has rated
  const refreshInterviewDetails = async (interviewId) => {
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/interviews/${interviewId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        console.error(
          `Failed to fetch interview details: ${response.statusText}`
        );
        return;
      }

      const updatedInterview = await response.json();

      // Log the response data for debugging
      console.log("Updated Interview Details:", updatedInterview);

      const ratedByUsers = updatedInterview.rated_by_users
        ? JSON.parse(JSON.stringify(updatedInterview.rated_by_users))
        : {};
      const currentLevel = updatedInterview.current_level.toLowerCase();
      const hasRated = ratedByUsers[currentLevel]?.includes(userId.toString());

      // Set the average ratings for all levels
      setSelectedInterview({
        ...updatedInterview,
        generalAverageRating: updatedInterview.generalAverageRating, // Store general average rating
        technicalAverageRating: updatedInterview.technicalAverageRating, // Store technical average rating
        executiveAverageRating: updatedInterview.executiveAverageRating, // Store executive average rating
      });

      setHasRated(hasRated);
    } catch (error) {
      console.error(
        "An error occurred while refreshing interview details:",
        error
      );
    }
  };

  const handleProceedToNextLevel = async (nextDate, nextTime) => {
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/interviews/proceed/${selectedInterview.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            next_interview_date: nextDate,
            next_interview_time: nextTime,
          }),
        }
      );

      if (response.ok) {
        setProgressionModalOpen(false);
        await refreshInterviewDetails(selectedInterview.id); // Fetch updated interview data
        toast.success("Proceeded to the next level successfully.");
      } else {
        toast.error("Failed to proceed candidate to next level.");
      }
    } catch (error) {
      toast.error(
        "An error occurred while proceeding the candidate to the next level."
      );
      console.error(
        "An error occurred while proceeding the candidate to the next level:",
        error
      );
    }
  };

  const handleDropCandidate = async (reason) => {
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/interviews/drop/${selectedInterview.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            drop_reason: reason,
            status: "Dropped",
          }),
        }
      );

      if (response.ok) {
        await refreshInterviewDetails(selectedInterview.id); // Refresh data
        setDropModalOpen(false);
        // setDropReason("");
        toast.success("Candidate dropped successfully.");
      } else {
        toast.error("Failed to drop candidate.");
      }
    } catch (error) {
      toast.error("An error occurred while dropping the candidate.");
      console.error("An error occurred while dropping the candidate:", error);
    }
  };

  const handleRecommendCandidate = async (comment, recommend) => {
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/interviews/recommend/${selectedInterview.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            comment,
            recommend,
            userId,
          }),
        }
      );

      if (response.ok) {
        await refreshInterviewDetails(selectedInterview.id); // Refresh data
        setRecommendModalOpen(false);
        toast.success("Candidate decision submitted successfully.");
      } else {
        toast.error("Failed to submit decision.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the decision.");
      console.error("An error occurred while submitting the decision:", error);
    }
  };

  // Function to calculate average rating
  function calculateAverageRating(ratings) {
    if (!ratings) return "Not yet rated";

    const ratingsArray = ratings
      .split(",")
      .map(Number)
      .filter((n) => !isNaN(n));
    if (ratingsArray.length === 0) return "Not yet rated";

    const total = ratingsArray.reduce((acc, curr) => acc + curr, 0);
    const average = total / ratingsArray.length;

    return average.toFixed(1);
  }

  // Calculate average ratings
  const generalAverageRating = selectedInterview
    ? calculateAverageRating(selectedInterview.general_ratings)
    : "Not yet rated";
  const technicalAverageRating = selectedInterview
    ? calculateAverageRating(selectedInterview.technical_ratings)
    : "Not yet rated";
  const executiveAverageRating = selectedInterview
    ? calculateAverageRating(selectedInterview.executive_ratings)
    : "Not yet rated";

  const StarRating = ({ rating }) => {
    const totalStars = 5; // 5 full stars
    const validRating = Math.max(0, Math.min(rating / 2, totalStars)); // Convert 10-scale to 5-scale
    const fullStars = Math.floor(validRating);
    const halfStar = validRating - fullStars >= 0.5;
    const emptyStars = totalStars - fullStars - (halfStar ? 1 : 0);

    return (
      <div className="rating rating-half" style={{ fontSize: "24px" }}>
        {Array.from({ length: fullStars }).map((_, i) => (
          <React.Fragment key={`full-${i}`}>
            <input
              type="radio"
              name={`rating-${i}`}
              className="rating-hidden"
            />
            <input
              type="radio"
              name={`rating-${i}`}
              className="mask mask-star-2 mask-half-1 bg-green-500"
              style={{ fontSize: "24px" }}
            />
            <input
              type="radio"
              name={`rating-${i}`}
              className="mask mask-star-2 mask-half-2 bg-green-500"
              style={{ fontSize: "24px" }}
            />
          </React.Fragment>
        ))}
        {halfStar && (
          <React.Fragment>
            <input type="radio" name="rating-half" className="rating-hidden" />
            <input
              type="radio"
              name="rating-half"
              className="mask mask-star-2 mask-half-1 bg-green-500"
              style={{ fontSize: "24px" }}
            />
            <input
              type="radio"
              name="rating-half"
              className="mask mask-star-2 mask-half-2 bg-gray-400"
              style={{ fontSize: "24px" }}
            />
          </React.Fragment>
        )}
        {Array.from({ length: emptyStars }).map((_, i) => (
          <React.Fragment key={`empty-${i}`}>
            <input type="radio" name={`empty-${i}`} className="rating-hidden" />
            <input
              type="radio"
              name={`empty-${i}`}
              className="mask mask-star-2 mask-half-1 bg-gray-400"
              style={{ fontSize: "24px" }}
            />
            <input
              type="radio"
              name={`empty-${i}`}
              className="mask mask-star-2 mask-half-2 bg-gray-400"
              style={{ fontSize: "24px" }}
            />
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <Sidebar>
      <div className="container px-4 py-2 mx-auto">
        <div className="flex justify-center mb-2 space-x-4">
          <div className="flex items-center border border-gray-300 rounded-md w-72 px-3 py-2">
            <Filter size={16} className="mr-2" />
            <input
              type="text"
              className="flex-1 focus:outline-none"
              placeholder="Search by Name or Phone"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="flex items-center border border-gray-300 rounded-md w-72 px-3 py-2">
            <Filter size={16} className="mr-2" />
            <input
              type="text"
              className="flex-1 focus:outline-none"
              placeholder="Search by Position"
              value={positionSearch}
              onChange={handlePositionSearch}
            />
          </div>
          <div className="flex items-center border border-gray-300 rounded-md w-72 px-3 py-2">
            <Filter size={16} className="mr-2" />
            <input
              type="text"
              className="flex-1 focus:outline-none"
              placeholder="Search by Level"
              value={levelSearch}
              onChange={handleLevelSearch}
            />
          </div>
          <div className="flex items-center border border-gray-300 rounded-md w-72 px-3 py-2">
            <Filter size={16} className="mr-2" />
            <input
              type="text"
              className="flex-1 focus:outline-none"
              placeholder="Search by Status"
              value={statusSearch}
              onChange={handleStatusSearch}
            />
          </div>
        </div>
        <div className="overflow-x-auto card bg-base-100 shadow-xl w-full">
          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Interview Date</th>
                <th>Interview Time</th>
                <th>Position</th>
                <th>Level</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredInterviews.map((interview) => (
                <tr key={interview.id}>
                  <td>{interview.name}</td>
                  <td>{interview.email}</td>
                  <td>{formatDate(interview.interview_date)}</td>
                  <td>{formatTime(interview.interview_time)}</td>
                  <td>{interview.position}</td>
                  <td>{interview.current_level}</td>
                  <td>{interview.status}</td>
                  <td>
                    <button
                      className="btn btn-outline btn-sm ml-1"
                      onClick={() => handleDetailsClick(interview)}
                    >
                      Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {selectedInterview && (
          <DragCloseDrawer open={drawerOpen} setOpen={setDrawerOpen}>
            <div className="mx-auto max-w-2xl space-y-4 text-neutral-400">
              <h2 className="text-2xl font-bold text-neutral-200 text-center">
                Interviewee Details
              </h2>
              <div className="flex justify-between space-x-4">
                <div className="w-1/2 space-y-2">
                  <p>
                    <strong>Name:</strong> {selectedInterview.name}
                  </p>
                  <p>
                    <strong>Email:</strong> {selectedInterview.email}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{" "}
                    {selectedInterview.phone_number}
                  </p>
                  <p>
                    <strong>Position:</strong> {selectedInterview.position}
                  </p>
                  <p>
                    <strong>Level:</strong> {selectedInterview.current_level}
                  </p>
                  <p>
                    <strong>Status:</strong> {selectedInterview.status}
                  </p>
                  <p>
                    <strong>Interview Date:</strong>{" "}
                    {formatDate(selectedInterview.interview_date)}
                  </p>
                  <p>
                    <strong>Interview Time:</strong>{" "}
                    {formatTime(selectedInterview.interview_time)}
                  </p>
                  <p>
                    <strong>Report Time:</strong>{" "}
                    {formatTime(selectedInterview.report_time)}
                  </p>
                </div>
                <div className="w-1/2 space-y-2">
                  <p>
                    <strong>General Average Rating:</strong>{" "}
                    {generalAverageRating}
                    <StarRating rating={generalAverageRating} />
                  </p>
                  <p>
                    <strong>Technical Average Rating:</strong>{" "}
                    {technicalAverageRating}
                    <StarRating rating={technicalAverageRating} />
                  </p>
                  <p>
                    <strong>Executive Average Rating:</strong>{" "}
                    {executiveAverageRating}
                    <StarRating rating={executiveAverageRating} />
                  </p>
                  {selectedInterview.drop_reason && (
                    <p>
                      <strong>Drop Reason:</strong>{" "}
                      {selectedInterview.drop_reason}
                    </p>
                  )}
                  {selectedInterview.decision && (
                    <p>
                      <strong>Decision:</strong> {selectedInterview.decision} by
                      User ID: {selectedInterview.decision_by}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-center mt-4">
                {!selectedInterview.report_time &&
                  selectedInterview.status !== "Completed" && (
                    <>
                      <button
                        className="btn btn-warning btn-sm text-white mx-2"
                        onClick={() =>
                          navigate(
                            `/edit-scheduled-interviews/${selectedInterview.id}`
                          )
                        }
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-sm text-white btn-error mx-2"
                        onClick={() => deleteInterview(selectedInterview.id)}
                      >
                        Delete
                      </button>
                      <button
                        className="btn btn-outline btn-info btn-sm mx-2"
                        onClick={() => handleAdmit(selectedInterview.id)}
                      >
                        Admit
                      </button>
                    </>
                  )}
                {selectedInterview.report_time && !hasRated && (
                  <button
                    className="btn btn-primary mx-2"
                    onClick={() => setRatingModalOpen(true)}
                  >
                    Rate
                  </button>
                )}
                {hasRated &&
                  selectedInterview.current_level !== "Executive" && (
                    <>
                      <button
                        className="btn btn-success mx-2"
                        onClick={() => setProgressionModalOpen(true)}
                      >
                        Proceed to Next Level
                      </button>
                      <button
                        className="btn btn-error mx-2"
                        onClick={() => setDropModalOpen(true)}
                      >
                        Drop Candidate
                      </button>
                    </>
                  )}
                {selectedInterview.current_level === "Executive" &&
                  hasRated &&
                  !selectedInterview.decision && (
                    <>
                      <button
                        className="btn btn-info mx-2"
                        onClick={() => setRecommendModalOpen(true)}
                      >
                        Recommend / Decision
                      </button>
                      <button
                        className="btn btn-error mx-2"
                        onClick={() => setDropModalOpen(true)}
                      >
                        Drop Candidate
                      </button>
                    </>
                  )}
              </div>
            </div>
          </DragCloseDrawer>
        )}

        {selectedInterview && (
          <RatingModal
            isOpen={ratingModalOpen}
            onClose={() => setRatingModalOpen(false)}
            onSubmit={handleRateCandidate}
          />
        )}

        {selectedInterview && (
          <ProgressionModal
            isOpen={progressionModalOpen}
            onClose={() => setProgressionModalOpen(false)}
            onProceed={handleProceedToNextLevel}
          />
        )}

        {selectedInterview && (
          <DropModal
            isOpen={dropModalOpen}
            onClose={() => setDropModalOpen(false)}
            onDrop={handleDropCandidate}
          />
        )}

        {selectedInterview && (
          <RecommendModal
            isOpen={recommendModalOpen}
            onClose={() => setRecommendModalOpen(false)}
            onRecommend={handleRecommendCandidate}
          />
        )}
      </div>
    </Sidebar>
  );
};

export default ViewScheduledInterviews;
