import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const RegionReports = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("all");
  const [regionTeams, setRegionTeams] = useState([]);

  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.user.user_id);

  useEffect(() => {
    // Fetch teams in the RM's region
    const fetchTeams = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/teams`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("all teams", response.data);
        // teams in the RM's region
        const teamsInRMRegion = response.data.filter(
          (team) => team.regional_manager === userId
        );
        setRegionTeams(teamsInRMRegion);

        console.log("teams in regions", regionTeams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userId]);

  const handleDownload = async () => {
    // Form validation
    if (!startDate || !endDate) {
      toast.error("Both dates must be chosen before submitting the form.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (endDate < startDate) {
      toast.error("End date cannot be before the start date.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      let url = "https://workspace.optiven.co.ke/api/workplan-reports/regional";

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          user_id: userId,
        },
        responseType: "blob",
      });

      // Create a blob from the PDF stream
      const file = new Blob([response.data], {
        type: "application/pdf",
      });

      // Create a link and click it to trigger the download
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "regional_reports.pdf";
      link.click();

      toast.success("PDF downloaded successfully.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error(
        "An error occurred while downloading the PDF. Please try again.",
        {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const renderTeamDropdown = () => {
    return (
      <div>
        <label className="label">
          <span className="label-text font-bold">Select Team</span>
        </label>
        <select
          value={selectedTeam}
          onChange={(e) => setSelectedTeam(e.target.value)}
          className="select select-bordered w-full max-w-xs mb-4"
        >
          <option value="all">All</option>
          {regionTeams.map((team) => (
            <option key={team.id} value={team.id}>
              {team.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <div className="form-control w-full max-w-xs">
          <div className="flex flex-col justify-center">
            <h1 className="font-bold text-lg">REGIONAL MARKETERS REPORTS</h1>
            {renderTeamDropdown()}
            <label className="label">
              <span className="label-text font-bold">Start Date</span>
            </label>
            <input
              type="date"
              value={startDate}
              className="input input-bordered w-full max-w-xs mb-4"
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className="label">
              <span className="label-text font-bold">End Date</span>
            </label>
            <input
              type="date"
              className="input input-bordered w-full max-w-xs mb-4"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button className="btn btn-outline" onClick={handleDownload}>
              Download PDF
            </button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default RegionReports;
