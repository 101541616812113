import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

function formatDate(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function formatDateToTheDatabase(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const timezoneOffset = -date.getTimezoneOffset();
  const offsetHours = Math.floor(timezoneOffset / 60);
  const offsetMinutes = timezoneOffset % 60;

  const formattedOffset = `${offsetHours >= 0 ? "+" : ""}${offsetHours
    .toString()
    .padStart(2, "0")}:${offsetMinutes.toString().padStart(2, "0")}`;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000${formattedOffset}`;
}

const EditValueAddition = () => {
  // finding the valueAdditionId
  const loc = useLocation();
  const url = loc.pathname;
  const valueAdditionId = url.split("/")[2];

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [construction_manager, setConstructionManager] = useState("");
  const [service_provider, setServiceProvider] = useState("");
  const [budget, setBudget] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [details, setDetails] = useState("");
  const [plot, setPlot] = useState("");
  const [constructionManagers, setConstructionManagers] = useState([]);
  const [constructionManagerOptions, setConstructionManagersOptions] = useState([]);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [serviceProviderOptions, setServiceProvidersOptions] = useState(
    []
  );

  useEffect(() => {
    // Fetch the value-addition data from the backend API
    axios
      .get(`https://workspace.optiven.co.ke/api/value-additions/${valueAdditionId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
        console.log("API Response:", res.data);

        setTitle(res.data.title);
        setConstructionManager(res.data.construction_manager);
        setServiceProvider(res.data.service_provider);
        setStartDate(res.data.startDate);
        setEndDate(res.data.endDate);
        setBudget(res.data.budget);
        setPlot(res.data.plot);
        setDetails(res.data.details);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [valueAdditionId]);
  // get construction managers
  useEffect(() => {
    const fetchConstructionMangers = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/construction-managers",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setConstructionManagers(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchServiceProviders = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/suppliers",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setServiceProviders(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchConstructionMangers();
    fetchServiceProviders();
  }, []);

  useEffect(() => {
    const options = constructionManagers.map((c) => ({
      value: c.id,
      label: c.constructor_name,
    }));
    setConstructionManagersOptions([{ value: "", label: "" }, ...options]);
  }, [constructionManagers]);

  // get service Providers
useEffect(() => {
  const options = serviceProviders.map((s) => ({
    value: s.id,
    label: s.name,
  }));
  setServiceProvidersOptions([{ value: "", label: "" }, ...options]);
}, [serviceProviders]);


  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const valAddition = {
      title,
      construction_manager,
      service_provider,
      budget,
      startDate: formatDateToTheDatabase(startDate),
      endDate: formatDateToTheDatabase(endDate),
      details,
      plot,
    };

    // Send a PATCH request to update the value addition data in the backend API
    axios
      .patch(
        `https://workspace.optiven.co.ke/api/value-additions/${valueAdditionId}`,
        valAddition,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }
      )
      .then((res) => {
        setLoading(false);
        toast.success("Value addition updated!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(res.data);
        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };
  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <form onSubmit={handleSubmit} className="form-control w-full max-w-xs">
          <label htmlFor="title" className="label">
            <span className="label-text font-bold">Title</span>
          </label>
          <input
            id="title"
            as="select"
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            value={title}
            className="input input-bordered w-full max-w-xs"
          />
           

          <label htmlFor="construction_manager" className="label">
            <span className="label-text font-bold">Construction Manager</span>
          </label>
          <select
            id="construction_manager"
            as="select"
            onChange={(event) => {
              setConstructionManager(event.target.value);
            }}
            value={construction_manager}
            className="select select-bordered"
          >
            <option value="">Select a Construction Manager</option>
            {constructionManagerOptions.slice(1).map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <label htmlFor="service_provider" className="label">
            <span className="label-text font-bold">Service Provider</span>
          </label>
          <select
            id="service_provider"
            as="select"
            onChange={(event) => {
              setServiceProvider(event.target.value);
            }}
            value={service_provider}
            className="select select-bordered"
          >
            <option value="">Select a Service Provider</option>
            {serviceProviderOptions.slice(1).map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <div className="lg:flex justify-between">
            <div className="w-36">
              <label className="label" htmlFor="startDate">
                <span className="label-text font-bold">Start Date: </span>
              </label>
              <input
                type="date"
                className="input input-bordered w-full max-w-xs"
                id="startDate"
                value={formatDate(startDate)}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </div>
            <div className="w-36">
              <label className="label" htmlFor="endDate">
                <span className="label-text font-bold">
                  Expected End Date:{" "}
                </span>
              </label>
              <input
                type="date"
                className="input input-bordered w-full max-w-xs"
                id="endDate"
                value={formatDate(endDate)}
                onChange={(event) => setEndDate(event.target.value)}
              />
            </div>
          </div>
          <label htmlFor="plot" className="label">
            <span className="label-text font-bold">Plot Allocation</span>
          </label>
          <input
            type="text"
            id="plot"
            value={plot}
            onChange={(event) => setPlot(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="budget" className="label">
            <span className="label-text font-bold">Budget (KES)</span>
          </label>
          <input
            type="text"
            id="budget"
            value={budget}
            onChange={(event) => setBudget(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="details" className="label">
            <span className="label-text font-bold">Details</span>
          </label>
          <textarea
            id="details"
            value={details}
            onChange={(event) => setDetails(event.target.value)}
            className="textarea textarea-bordered w-full max-w-sm h-24"
          />

          <button
            type="submit"
            disabled={loading}
            id="submit"
            className="btn btn-primary w-full max-w-xs mt-4 text-white"
          >
            {loading ? "Saving..." : "Update Value Addition"}
          </button>
        </form>
      </div>
    </Sidebar>
  );
};

export default EditValueAddition;
