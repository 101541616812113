const geojsonData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "smde3204b4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.89286817, 60.232152055],
            [17.976396375, 60.152957187],
            [18.34205524, 60.247760993],
            [18.25727601, 60.326870591],
            [17.89286817, 60.232152055],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC25",
      },
    },
    {
      type: "Feature",
      id: "sm91db9710",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.433053491, 61.086053147],
            [17.289750869, 61.216224518],
            [16.968400769, 61.095979151],
            [17.077028438, 60.995675672],
            [17.433053491, 61.086053147],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC35",
      },
    },
    {
      type: "Feature",
      id: "sm19faa28a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.077028438, 60.995675672],
            [17.168914656, 60.910582719],
            [17.525883844, 61.001442274],
            [17.433053491, 61.086053147],
            [17.077028438, 60.995675672],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC34",
      },
    },
    {
      type: "Feature",
      id: "sm6049ce5f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.168914656, 60.910582719],
            [17.259513294, 60.826459161],
            [17.616392192, 60.918730193],
            [17.525883844, 61.001442274],
            [17.168914656, 60.910582719],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC33",
      },
    },
    {
      type: "Feature",
      id: "sm5b839979",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.259513294, 60.826459161],
            [17.353242821, 60.739195018],
            [17.711082046, 60.831966394],
            [17.616392192, 60.918730193],
            [17.259513294, 60.826459161],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC32",
      },
    },
    {
      type: "Feature",
      id: "smec2cab11",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.353242821, 60.739195018],
            [17.443767535, 60.654688824],
            [17.802534251, 60.747945168],
            [17.711082046, 60.831966394],
            [17.353242821, 60.739195018],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC31",
      },
    },
    {
      type: "Feature",
      id: "sm6acb67fe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.443767535, 60.654688824],
            [17.530458213, 60.573553412],
            [17.890113138, 60.667275783],
            [17.802534251, 60.747945168],
            [17.443767535, 60.654688824],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC30",
      },
    },
    {
      type: "Feature",
      id: "smcb179079",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.530458213, 60.573553412],
            [17.621744527, 60.487896131],
            [17.983366561, 60.581156793],
            [17.890113138, 60.667275783],
            [17.530458213, 60.573553412],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC29",
      },
    },
    {
      type: "Feature",
      id: "smbbc2f59c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.621744527, 60.487896131],
            [17.712117192, 60.402872726],
            [18.074667808, 60.496617646],
            [17.983366561, 60.581156793],
            [17.621744527, 60.487896131],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC28",
      },
    },
    {
      type: "Feature",
      id: "sm7ab7d450",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.712117192, 60.402872726],
            [17.802380132, 60.317730251],
            [18.165858204, 60.411960526],
            [18.074667808, 60.496617646],
            [17.712117192, 60.402872726],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC27",
      },
    },
    {
      type: "Feature",
      id: "smcf24b0f2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.802380132, 60.317730251],
            [17.89286817, 60.232152055],
            [18.25727601, 60.326870591],
            [18.165858204, 60.411960526],
            [17.802380132, 60.317730251],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC26",
      },
    },
    {
      type: "Feature",
      id: "sm67e3d50f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.171461831, 61.423898733],
            [20.010240374, 61.383569349],
            [20.003373918, 61.363173101],
            [20.165617024, 61.214893015],
            [20.346793236, 61.259476893],
            [20.171461831, 61.423898733],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC103",
      },
    },
    {
      type: "Feature",
      id: "sm31dd13ec",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.346793236, 61.259476893],
            [20.514932306, 61.300796072],
            [20.338839221, 61.465712948],
            [20.171461831, 61.423898733],
            [20.346793236, 61.259476893],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC102",
      },
    },
    {
      type: "Feature",
      id: "sm4072a9ec",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.514932306, 61.300796072],
            [20.691163263, 61.344045441],
            [20.514271842, 61.509479363],
            [20.338839221, 61.465712948],
            [20.514932306, 61.300796072],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC101",
      },
    },
    {
      type: "Feature",
      id: "sm5819fed1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.691163263, 61.344045441],
            [20.860564732, 61.385562531],
            [20.682905912, 61.551491713],
            [20.514271842, 61.509479363],
            [20.691163263, 61.344045441],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC100",
      },
    },
    {
      type: "Feature",
      id: "sm34d87120",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.860564732, 61.385562531],
            [21.035483629, 61.428374048],
            [20.857032417, 61.594812825],
            [20.682905912, 61.551491713],
            [20.860564732, 61.385562531],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC99",
      },
    },
    {
      type: "Feature",
      id: "sma5d58cde",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.035483629, 61.428374048],
            [21.209587072, 61.47092773],
            [21.030347161, 61.637871919],
            [20.857032417, 61.594812825],
            [21.035483629, 61.428374048],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC98",
      },
    },
    {
      type: "Feature",
      id: "sme20cc759",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.209587072, 61.47092773],
            [21.38319391, 61.513302223],
            [21.20316755, 61.68074859],
            [21.030347161, 61.637871919],
            [21.209587072, 61.47092773],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC97",
      },
    },
    {
      type: "Feature",
      id: "smc693d5bc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.38319391, 61.513302223],
            [21.553957099, 61.554926366],
            [21.373157172, 61.722864941],
            [21.20316755, 61.68074859],
            [21.38319391, 61.513302223],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC96",
      },
    },
    {
      type: "Feature",
      id: "sm6040df3b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.553957099, 61.554926366],
            [21.735709647, 61.599167963],
            [21.585015561, 61.739005768],
            [21.560342602, 61.751358591],
            [21.513605551, 61.757618841],
            [21.373157172, 61.722864941],
            [21.553957099, 61.554926366],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC95",
      },
    },
    {
      type: "Feature",
      id: "smb6ba7c28",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.74505187, 61.387475039],
            [21.852017641, 61.413740889],
            [21.879632764, 61.465021408],
            [21.735709647, 61.599167963],
            [21.563303497, 61.557202975],
            [21.74505187, 61.387475039],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC136",
      },
    },
    {
      type: "Feature",
      id: "sm2f84114c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.563303497, 61.557202975],
            [21.38833494, 61.514556182],
            [21.570194854, 61.344490658],
            [21.74505187, 61.387475039],
            [21.563303497, 61.557202975],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC135",
      },
    },
    {
      type: "Feature",
      id: "sm8c95d480",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.38833494, 61.514556182],
            [21.214380478, 61.472098494],
            [21.396351288, 61.30169683],
            [21.570194854, 61.344490658],
            [21.38833494, 61.514556182],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC134",
      },
    },
    {
      type: "Feature",
      id: "sm33abab15",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.214380478, 61.472098494],
            [21.038348163, 61.429074656],
            [21.220431192, 61.258332322],
            [21.396351288, 61.30169683],
            [21.214380478, 61.472098494],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC133",
      },
    },
    {
      type: "Feature",
      id: "sm04ade421",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.038348163, 61.429074656],
            [20.861202431, 61.385718715],
            [21.043398389, 61.214633033],
            [21.220431192, 61.258332322],
            [21.038348163, 61.429074656],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC132",
      },
    },
    {
      type: "Feature",
      id: "smf3282446",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.861202431, 61.385718715],
            [20.691502998, 61.344128759],
            [20.873807139, 61.172713669],
            [21.043398389, 61.214633033],
            [20.861202431, 61.385718715],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC131",
      },
    },
    {
      type: "Feature",
      id: "sm81472fd5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.691502998, 61.344128759],
            [20.515470684, 61.300928288],
            [20.697887044, 61.129170986],
            [20.873807139, 61.172713669],
            [20.691502998, 61.344128759],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC130",
      },
    },
    {
      type: "Feature",
      id: "sm621103ad",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.515470684, 61.300928288],
            [20.344707494, 61.258963993],
            [20.527232715, 61.086874227],
            [20.697887044, 61.129170986],
            [20.515470684, 61.300928288],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC129",
      },
    },
    {
      type: "Feature",
      id: "sme14def92",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.344707494, 61.258963993],
            [20.165617024, 61.214893015],
            [20.334337053, 61.059949046],
            [20.389268693, 61.052638399],
            [20.527232715, 61.086874227],
            [20.344707494, 61.258963993],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC128",
      },
    },
    {
      type: "Feature",
      id: "smd3ed0d7c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.11025485, 60.982789198],
            [20.261552628, 61.021382994],
            [20.260179337, 61.047985292],
            [20.104033665, 61.190870466],
            [19.931664455, 61.146998336],
            [20.11025485, 60.982789198],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC127",
      },
    },
    {
      type: "Feature",
      id: "smcd951385",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.931664455, 61.146998336],
            [19.758008817, 61.102737036],
            [19.936483395, 60.938404806],
            [20.11025485, 60.982789198],
            [19.931664455, 61.146998336],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC126",
      },
    },
    {
      type: "Feature",
      id: "smc60008e8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.758008817, 61.102737036],
            [19.584240584, 61.058384945],
            [19.762599271, 60.893929619],
            [19.936483395, 60.938404806],
            [19.758008817, 61.102737036],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC125",
      },
    },
    {
      type: "Feature",
      id: "sm98b41f76",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.584240584, 61.058384945],
            [19.405042094, 61.012581732],
            [19.583281268, 60.847999543],
            [19.762599271, 60.893929619],
            [19.584240584, 61.058384945],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC124",
      },
    },
    {
      type: "Feature",
      id: "smbb9de03b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.405042094, 61.012581732],
            [19.238966296, 60.970073593],
            [19.41709471, 60.805373904],
            [19.583281268, 60.847999543],
            [19.405042094, 61.012581732],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC123",
      },
    },
    {
      type: "Feature",
      id: "sm7e12eebc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.238966296, 60.970073593],
            [19.064066975, 60.925245501],
            [19.242078743, 60.760422146],
            [19.41709471, 60.805373904],
            [19.238966296, 60.970073593],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC122",
      },
    },
    {
      type: "Feature",
      id: "smffeb6901",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.064066975, 60.925245501],
            [18.895273473, 60.881922481],
            [19.073172668, 60.716979853],
            [19.242078743, 60.760422146],
            [19.064066975, 60.925245501],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC121",
      },
    },
    {
      type: "Feature",
      id: "sm25aec2df",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.895273473, 60.881922481],
            [18.724364521, 60.837996501],
            [18.90257769, 60.673043476],
            [19.073172668, 60.716979853],
            [18.895273473, 60.881922481],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC120",
      },
    },
    {
      type: "Feature",
      id: "sm3036b63c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.724364521, 60.837996501],
            [18.55100738, 60.793379564],
            [18.728676972, 60.628193902],
            [18.90257769, 60.673043476],
            [18.724364521, 60.837996501],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC119",
      },
    },
    {
      type: "Feature",
      id: "sm4e31da20",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.55100738, 60.793379564],
            [18.374639188, 60.747923805],
            [18.552191155, 60.582613758],
            [18.728676972, 60.628193902],
            [18.55100738, 60.793379564],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC118",
      },
    },
    {
      type: "Feature",
      id: "smd99d12f8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.374639188, 60.747923805],
            [18.200328845, 60.702935065],
            [18.378191677, 60.537612707],
            [18.552191155, 60.582613758],
            [18.374639188, 60.747923805],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC117",
      },
    },
    {
      type: "Feature",
      id: "sm68910c73",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.200328845, 60.702935065],
            [18.023881631, 60.657330598],
            [18.166597186, 60.523511425],
            [18.212602435, 60.5076274],
            [18.255861102, 60.50593715],
            [18.378191677, 60.537612707],
            [18.200328845, 60.702935065],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC116",
      },
    },
    {
      type: "Feature",
      id: "smee6d13b2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.014120505, 60.877083071],
            [17.8816393, 60.843240275],
            [17.867219745, 60.825505044],
            [17.870652972, 60.800391207],
            [18.023881631, 60.657330598],
            [18.202003792, 60.703367661],
            [18.014120505, 60.877083071],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC115",
      },
    },
    {
      type: "Feature",
      id: "sm6b9e6fbb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.202003792, 60.703367661],
            [18.372365752, 60.747337446],
            [18.184707885, 60.920607438],
            [18.014120505, 60.877083071],
            [18.202003792, 60.703367661],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC114",
      },
    },
    {
      type: "Feature",
      id: "sm7098b533",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.372365752, 60.747337446],
            [18.549530571, 60.79299921],
            [18.362107126, 60.965806838],
            [18.184707885, 60.920607438],
            [18.372365752, 60.747337446],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC113",
      },
    },
    {
      type: "Feature",
      id: "smb887d629",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.549530571, 60.79299921],
            [18.714284387, 60.835403879],
            [18.527078942, 61.007782301],
            [18.362107126, 60.965806838],
            [18.549530571, 60.79299921],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC112",
      },
    },
    {
      type: "Feature",
      id: "sm0e51aef5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.714284387, 60.835403879],
            [18.890195925, 60.880618351],
            [18.703223243, 61.052539316],
            [18.527078942, 61.007782301],
            [18.714284387, 60.835403879],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC111",
      },
    },
    {
      type: "Feature",
      id: "smeaae8374",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.890195925, 60.880618351],
            [19.064854182, 60.92544741],
            [18.878112605, 61.096915012],
            [18.703223243, 61.052539316],
            [18.890195925, 60.880618351],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC110",
      },
    },
    {
      type: "Feature",
      id: "sm9b9f52bd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.064854182, 60.92544741],
            [19.239512438, 60.970213474],
            [19.053001967, 61.141228543],
            [18.878112605, 61.096915012],
            [19.064854182, 60.92544741],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC109",
      },
    },
    {
      type: "Feature",
      id: "smc5254403",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.239512438, 60.970213474],
            [19.411008209, 61.014107738],
            [19.224724658, 61.184679274],
            [19.053001967, 61.141228543],
            [19.239512438, 60.970213474],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC108",
      },
    },
    {
      type: "Feature",
      id: "smce6a3bf1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.411008209, 61.014107738],
            [19.583787713, 61.058269274],
            [19.397732782, 61.228394766],
            [19.224724658, 61.184679274],
            [19.411008209, 61.014107738],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC107",
      },
    },
    {
      type: "Feature",
      id: "sm6c78aee3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.583787713, 61.058269274],
            [19.7579388, 61.102719177],
            [19.572114304, 61.272395904],
            [19.397732782, 61.228394766],
            [19.583787713, 61.058269274],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC106",
      },
    },
    {
      type: "Feature",
      id: "sme8bfa4ee",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.7579388, 61.102719177],
            [19.930269701, 61.146643088],
            [19.74467323, 61.31587655],
            [19.572114304, 61.272395904],
            [19.7579388, 61.102719177],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC105",
      },
    },
    {
      type: "Feature",
      id: "smbc8f0ec2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.930269701, 61.146643088],
            [20.104033665, 61.190870466],
            [19.927842912, 61.351324013],
            [19.890764055, 61.3526408],
            [19.74467323, 61.31587655],
            [19.930269701, 61.146643088],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC104",
      },
    },
    {
      type: "Feature",
      id: "sm180f2cf2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.481851935, 61.288385996],
            [17.348802382, 61.238038111],
            [17.52183705, 61.092992039],
            [17.590501601, 61.091000474],
            [17.722514271, 61.141210055],
            [17.481851935, 61.288385996],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC36",
      },
    },
    {
      type: "Feature",
      id: "sm7ade4e1d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.79236568, 62.286500316],
            [20.907342721, 62.328572878],
            [20.915582467, 62.358213945],
            [20.70335669, 62.48305032],
            [20.552178794, 62.428025818],
            [20.79236568, 62.286500316],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC57",
      },
    },
    {
      type: "Feature",
      id: "smf0aa1184",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.552178794, 62.428025818],
            [20.391942705, 62.369593704],
            [20.632154404, 62.22777736],
            [20.79236568, 62.286500316],
            [20.552178794, 62.428025818],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC56",
      },
    },
    {
      type: "Feature",
      id: "sm7d956759",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.391942705, 62.369593704],
            [20.24656753, 62.31648213],
            [20.48680174, 62.17440153],
            [20.632154404, 62.22777736],
            [20.391942705, 62.369593704],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC55",
      },
    },
    {
      type: "Feature",
      id: "sm84338b24",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.24656753, 62.31648213],
            [20.094449409, 62.260806428],
            [20.334707175, 62.118448922],
            [20.48680174, 62.17440153],
            [20.24656753, 62.31648213],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC54",
      },
    },
    {
      type: "Feature",
      id: "smd55c1acf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.094449409, 62.260806428],
            [19.939407303, 62.20395448],
            [20.179689077, 62.06131433],
            [20.334707175, 62.118448922],
            [20.094449409, 62.260806428],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC53",
      },
    },
    {
      type: "Feature",
      id: "smf44e5382",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.939407303, 62.20395448],
            [19.7826695, 62.146371761],
            [20.022975546, 62.003445452],
            [20.179689077, 62.06131433],
            [19.939407303, 62.20395448],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC52",
      },
    },
    {
      type: "Feature",
      id: "sm242fbf49",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.7826695, 62.146371761],
            [19.626038589, 62.088718703],
            [19.866368889, 61.945506002],
            [20.022975546, 62.003445452],
            [19.7826695, 62.146371761],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC51",
      },
    },
    {
      type: "Feature",
      id: "sm00cd8317",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.626038589, 62.088718703],
            [19.476083574, 62.033420118],
            [19.716437096, 61.889932833],
            [19.866368889, 61.945506002],
            [19.626038589, 62.088718703],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC50",
      },
    },
    {
      type: "Feature",
      id: "sma291a679",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.476083574, 62.033420118],
            [19.322016129, 61.976500119],
            [19.562393509, 61.832730313],
            [19.716437096, 61.889932833],
            [19.476083574, 62.033420118],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC49",
      },
    },
    {
      type: "Feature",
      id: "sm8a42005f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.322016129, 61.976500119],
            [19.166867132, 61.919072976],
            [19.407268537, 61.775018249],
            [19.562393509, 61.832730313],
            [19.322016129, 61.976500119],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC48",
      },
    },
    {
      type: "Feature",
      id: "smd26fa561",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.166867132, 61.919072976],
            [19.012546061, 61.861845059],
            [19.252971364, 61.717506519],
            [19.407268537, 61.775018249],
            [19.166867132, 61.919072976],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC47",
      },
    },
    {
      type: "Feature",
      id: "sma8ebce48",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.012546061, 61.861845059],
            [18.854473079, 61.803114778],
            [19.094922859, 61.658485096],
            [19.252971364, 61.717506519],
            [19.012546061, 61.861845059],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC46",
      },
    },
    {
      type: "Feature",
      id: "sme72ff39e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.854473079, 61.803114778],
            [18.706467387, 61.748022907],
            [18.946940087, 61.603120234],
            [19.094922859, 61.658485096],
            [18.854473079, 61.803114778],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC45",
      },
    },
    {
      type: "Feature",
      id: "sm0ccba469",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.706467387, 61.748022907],
            [18.55918273, 61.693101391],
            [18.799678239, 61.54792668],
            [18.946940087, 61.603120234],
            [18.706467387, 61.748022907],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC44",
      },
    },
    {
      type: "Feature",
      id: "sm45bee133",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.55918273, 61.693101391],
            [18.402304506, 61.634494797],
            [18.642824308, 61.489029916],
            [18.799678239, 61.54792668],
            [18.55918273, 61.693101391],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC43",
      },
    },
    {
      type: "Feature",
      id: "sma9afd2f6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.402304506, 61.634494797],
            [18.248524212, 61.576937561],
            [18.489067828, 61.431187829],
            [18.642824308, 61.489029916],
            [18.402304506, 61.634494797],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC42",
      },
    },
    {
      type: "Feature",
      id: "sm713ec996",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.248524212, 61.576937561],
            [18.096552826, 61.519952218],
            [18.337119974, 61.373920583],
            [18.489067828, 61.431187829],
            [18.248524212, 61.576937561],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC41",
      },
    },
    {
      type: "Feature",
      id: "smb271ebfb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.096552826, 61.519952218],
            [17.940749844, 61.461421468],
            [18.181341118, 61.315100411],
            [18.337119974, 61.373920583],
            [18.096552826, 61.519952218],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC40",
      },
    },
    {
      type: "Feature",
      id: "sm06caf4eb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.940749844, 61.461421468],
            [17.787984055, 61.403924752],
            [18.028598985, 61.257319509],
            [18.181341118, 61.315100411],
            [17.940749844, 61.461421468],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC39",
      },
    },
    {
      type: "Feature",
      id: "smdf9eb0aa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.787984055, 61.403924752],
            [17.635432049, 61.346402682],
            [17.876070602, 61.199513249],
            [18.028598985, 61.257319509],
            [17.787984055, 61.403924752],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC38",
      },
    },
    {
      type: "Feature",
      id: "smfb418208",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.635432049, 61.346402682],
            [17.481851935, 61.288385996],
            [17.722514271, 61.141210055],
            [17.876070602, 61.199513249],
            [17.635432049, 61.346402682],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC37",
      },
    },
    {
      type: "Feature",
      id: "sm4dbae79d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.611047509, 62.149054525],
            [20.388238714, 62.066273944],
            [20.388528385, 62.040812555],
            [20.465215681, 61.96940202],
            [20.73651233, 62.034941853],
            [20.611047509, 62.149054525],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC60",
      },
    },
    {
      type: "Feature",
      id: "sm7b49bf63",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.73651233, 62.034941853],
            [20.961415066, 62.089167018],
            [20.812980367, 62.223884014],
            [20.611047509, 62.149054525],
            [20.73651233, 62.034941853],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC59",
      },
    },
    {
      type: "Feature",
      id: "sm3959cd4d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.961415066, 62.089167018],
            [21.153863388, 62.135490476],
            [21.015146061, 62.261214137],
            [20.982873722, 62.268883397],
            [20.932061956, 62.267924846],
            [20.812980367, 62.223884014],
            [20.961415066, 62.089167018],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC58",
      },
    },
    {
      type: "Feature",
      id: "sm2aaeb82d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.240314544, 62.056872268],
            [21.153863388, 62.135490476],
            [20.805918423, 62.051686331],
            [20.892369579, 61.972850779],
            [21.240314544, 62.056872268],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC91",
      },
    },
    {
      type: "Feature",
      id: "sm72e1d53e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.068253001, 61.811830129],
            [21.156385132, 61.730826447],
            [21.460778996, 61.809476587],
            [21.474511906, 61.842869018],
            [21.416197966, 61.896295046],
            [21.068253001, 61.811830129],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC94",
      },
    },
    {
      type: "Feature",
      id: "smef15b522",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.416197966, 61.896295046],
            [21.331288521, 61.973920845],
            [20.983343555, 61.889670206],
            [21.068253001, 61.811830129],
            [21.416197966, 61.896295046],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC93",
      },
    },
    {
      type: "Feature",
      id: "sm14c9d366",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.331288521, 61.973920845],
            [21.240314544, 62.056872268],
            [20.892369579, 61.972850779],
            [20.983343555, 61.889670206],
            [21.331288521, 61.973920845],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC92",
      },
    },
    {
      type: "Feature",
      id: "sm41415b4a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.725574011, 61.725706791],
            [20.782373236, 61.672285031],
            [20.824258612, 61.658271466],
            [20.891214528, 61.662146888],
            [21.156385132, 61.730826447],
            [21.070579959, 61.809694125],
            [20.725574011, 61.725706791],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC87",
      },
    },
    {
      type: "Feature",
      id: "smcaacbd6a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.89201714, 61.973172586],
            [20.805918423, 62.051686331],
            [20.465215681, 61.96940202],
            [20.549914495, 61.89033648],
            [20.89201714, 61.973172586],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC90",
      },
    },
    {
      type: "Feature",
      id: "sme654e3ee",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.549914495, 61.89033648],
            [20.635609881, 61.810132235],
            [20.9791289, 61.893528812],
            [20.89201714, 61.973172586],
            [20.549914495, 61.89033648],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC89",
      },
    },
    {
      type: "Feature",
      id: "sm13534dd3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.635609881, 61.810132235],
            [20.725574011, 61.725706791],
            [21.070579959, 61.809694125],
            [20.9791289, 61.893528812],
            [20.635609881, 61.810132235],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC88",
      },
    },
    {
      type: "Feature",
      id: "sme9893cdf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.520004162, 61.573755448],
            [20.671136663, 61.612928303],
            [20.681436345, 61.640337873],
            [20.545498765, 61.766922114],
            [20.353050206, 61.719280196],
            [20.520004162, 61.573755448],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC85",
      },
    },
    {
      type: "Feature",
      id: "sm87505f36",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.353050206, 61.719280196],
            [20.153900343, 61.669901683],
            [20.322689677, 61.522537866],
            [20.520004162, 61.573755448],
            [20.353050206, 61.719280196],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC84",
      },
    },
    {
      type: "Feature",
      id: "smf9131d91",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.153900343, 61.669901683],
            [19.961958881, 61.622235648],
            [20.13251716, 61.473094141],
            [20.322689677, 61.522537866],
            [20.153900343, 61.669901683],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC83",
      },
    },
    {
      type: "Feature",
      id: "sm9b455dae",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.961958881, 61.622235648],
            [19.767681558, 61.573914665],
            [19.908273502, 61.445662964],
            [19.983817762, 61.434378413],
            [20.13251716, 61.473094141],
            [19.961958881, 61.622235648],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC82",
      },
    },
    {
      type: "Feature",
      id: "sm25e86659",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.076669365, 61.745582516],
            [19.683295094, 61.65064048],
            [19.767681558, 61.573914665],
            [20.158819111, 61.671122226],
            [20.076669365, 61.745582516],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC81",
      },
    },
    {
      type: "Feature",
      id: "sm24288587",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.158819111, 61.671122226],
            [20.545498765, 61.766922114],
            [20.467241434, 61.839559501],
            [20.076669365, 61.745582516],
            [20.158819111, 61.671122226],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC86",
      },
    },
    {
      type: "Feature",
      id: "smdbb95b59",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.776706608, 61.839022877],
            [19.585550114, 61.767603724],
            [19.586923405, 61.738031177],
            [19.683295094, 61.65064048],
            [19.918363916, 61.707410157],
            [19.776706608, 61.839022877],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC64",
      },
    },
    {
      type: "Feature",
      id: "sm037ab168",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.918363916, 61.707410157],
            [20.119736511, 61.755959162],
            [19.957492351, 61.906414689],
            [19.776706608, 61.839022877],
            [19.918363916, 61.707410157],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC63",
      },
    },
    {
      type: "Feature",
      id: "smc8f86e59",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.119736511, 61.755959162],
            [20.302508647, 61.79995758],
            [20.121579209, 61.967453353],
            [19.957492351, 61.906414689],
            [20.119736511, 61.755959162],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC62",
      },
    },
    {
      type: "Feature",
      id: "smb39948f0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.302508647, 61.79995758],
            [20.467241434, 61.839559501],
            [20.295541571, 61.998328032],
            [20.222070502, 62.004774914],
            [20.121579209, 61.967453353],
            [20.302508647, 61.79995758],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC61",
      },
    },
    {
      type: "Feature",
      id: "sma3a36ebd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.782899298, 61.095645803],
            [17.655732949, 61.062939274],
            [17.636850198, 61.048650057],
            [17.628051022, 61.029876087],
            [17.752549938, 60.912413873],
            [17.827394298, 60.900394448],
            [17.957314473, 60.933264824],
            [17.782899298, 61.095645803],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC69",
      },
    },
    {
      type: "Feature",
      id: "sm0a670074",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.689767037, 61.368353249],
            [19.828622625, 61.402966324],
            [19.829995916, 61.44106767],
            [19.681601581, 61.573871332],
            [19.512096216, 61.531491097],
            [19.689767037, 61.368353249],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC80",
      },
    },
    {
      type: "Feature",
      id: "sm4bc29aa6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.512096216, 61.531491097],
            [19.340688288, 61.48857631],
            [19.518052269, 61.325496207],
            [19.689767037, 61.368353249],
            [19.512096216, 61.531491097],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC79",
      },
    },
    {
      type: "Feature",
      id: "sm32a914ab",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.340688288, 61.48857631],
            [19.170230003, 61.445840519],
            [19.347288843, 61.282818395],
            [19.518052269, 61.325496207],
            [19.340688288, 61.48857631],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC78",
      },
    },
    {
      type: "Feature",
      id: "sme898d572",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.170230003, 61.445840519],
            [18.993221373, 61.401400402],
            [19.169963346, 61.238439072],
            [19.347288843, 61.282818395],
            [19.170230003, 61.445840519],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC77",
      },
    },
    {
      type: "Feature",
      id: "smb02528de",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.993221373, 61.401400402],
            [18.820557929, 61.357990194],
            [18.996990814, 61.195088743],
            [19.169963346, 61.238439072],
            [18.993221373, 61.401400402],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC76",
      },
    },
    {
      type: "Feature",
      id: "sm38c16baa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.820557929, 61.357990194],
            [18.647598414, 61.314445091],
            [18.823721681, 61.151604194],
            [18.996990814, 61.195088743],
            [18.820557929, 61.357990194],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC75",
      },
    },
    {
      type: "Feature",
      id: "sm5677ad3f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.647598414, 61.314445091],
            [18.47567329, 61.271100385],
            [18.651488791, 61.108320249],
            [18.823721681, 61.151604194],
            [18.647598414, 61.314445091],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC74",
      },
    },
    {
      type: "Feature",
      id: "smd8e08c28",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.47567329, 61.271100385],
            [18.302758001, 61.227445622],
            [18.478263963, 61.064727171],
            [18.651488791, 61.108320249],
            [18.47567329, 61.271100385],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC73",
      },
    },
    {
      type: "Feature",
      id: "sm1ed6caa6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.302758001, 61.227445622],
            [18.129842711, 61.183730204],
            [18.305039135, 61.021074015],
            [18.478263963, 61.064727171],
            [18.302758001, 61.227445622],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC72",
      },
    },
    {
      type: "Feature",
      id: "sm270284ef",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.129842711, 61.183730204],
            [17.954947089, 61.139452368],
            [18.129830429, 60.976859743],
            [18.305039135, 61.021074015],
            [18.129842711, 61.183730204],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC71",
      },
    },
    {
      type: "Feature",
      id: "sme3c84c3e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.954947089, 61.139452368],
            [17.839067272, 61.110081159],
            [17.782899298, 61.095645803],
            [17.957314473, 60.933264824],
            [18.129830429, 60.976859743],
            [17.954947089, 61.139452368],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC70",
      },
    },
    {
      type: "Feature",
      id: "sm427d8b98",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.663836786, 61.423583803],
            [17.839067272, 61.110081159],
            [17.959230264, 61.139923479],
            [18.14325126, 61.188274428],
            [18.753255518, 61.34174493],
            [18.663836786, 61.423583803],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC149",
      },
    },
    {
      type: "Feature",
      id: "smc697510e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.753255518, 61.34174493],
            [19.050781793, 61.416327612],
            [18.931916419, 61.524810896],
            [18.663836786, 61.423583803],
            [18.753255518, 61.34174493],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC68",
      },
    },
    {
      type: "Feature",
      id: "sm309944a3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.050781793, 61.416327612],
            [19.286881277, 61.475385559],
            [19.144648766, 61.604904891],
            [18.931916419, 61.524810896],
            [19.050781793, 61.416327612],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC67",
      },
    },
    {
      type: "Feature",
      id: "sm74f358f0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.286881277, 61.475385559],
            [19.494811671, 61.527304704],
            [19.331999964, 61.675271759],
            [19.144648766, 61.604904891],
            [19.286881277, 61.475385559],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC66",
      },
    },
    {
      type: "Feature",
      id: "sm7794cd05",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.494811671, 61.527304704],
            [19.681601581, 61.573871332],
            [19.518258854, 61.7142876],
            [19.448221013, 61.718842581],
            [19.331999964, 61.675271759],
            [19.494811671, 61.527304704],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC65",
      },
    },
    {
      type: "Feature",
      id: "sm15e09d9c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.275041418, 62.534971786],
            [21.162431531, 62.650653446],
            [20.837548806, 62.533031965],
            [20.923604902, 62.45506124],
            [21.275041418, 62.534971786],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC148",
      },
    },
    {
      type: "Feature",
      id: "sm2e281044",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.923604902, 62.45506124],
            [21.015832706, 62.37127222],
            [21.098230167, 62.365540019],
            [21.33649616, 62.425355674],
            [21.360528752, 62.457758896],
            [21.275041418, 62.534971786],
            [20.923604902, 62.45506124],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC147",
      },
    },
    {
      type: "Feature",
      id: "smb7fa84d6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.911597309, 61.546412152],
            [21.981942944, 61.481089618],
            [22.060220532, 61.473876345],
            [22.290246777, 61.533172251],
            [22.316682629, 61.548551049],
            [22.319085889, 61.572749046],
            [22.257658752, 61.630176949],
            [21.911597309, 61.546412152],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC137",
      },
    },
    {
      type: "Feature",
      id: "sm778c3066",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.524465746, 62.30746097],
            [21.458032414, 62.368087797],
            [21.389367864, 62.378276755],
            [21.14217548, 62.319642952],
            [21.112649724, 62.302732614],
            [21.11470966, 62.276870133],
            [21.1728037, 62.224207159],
            [21.524465746, 62.30746097],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC146",
      },
    },
    {
      type: "Feature",
      id: "sm22ef2eb1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.1728037, 62.224207159],
            [21.261926428, 62.143237299],
            [21.612912858, 62.226554335],
            [21.524465746, 62.30746097],
            [21.1728037, 62.224207159],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC145",
      },
    },
    {
      type: "Feature",
      id: "sm86dfc9c3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.261926428, 62.143237299],
            [21.35085246, 62.062229772],
            [21.701164764, 62.145609467],
            [21.612912858, 62.226554335],
            [21.261926428, 62.143237299],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC144",
      },
    },
    {
      type: "Feature",
      id: "sm7b7b3af2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.35085246, 62.062229772],
            [21.44022066, 61.980601285],
            [21.789855487, 62.064043518],
            [21.701164764, 62.145609467],
            [21.35085246, 62.062229772],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC143",
      },
    },
    {
      type: "Feature",
      id: "smc892e8bc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.44022066, 61.980601285],
            [21.528733792, 61.899537823],
            [21.877697624, 61.983041565],
            [21.789855487, 62.064043518],
            [21.44022066, 61.980601285],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC142",
      },
    },
    {
      type: "Feature",
      id: "smb27520ba",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.528733792, 61.899537823],
            [21.619743163, 61.815963653],
            [21.968017077, 61.89953019],
            [21.877697624, 61.983041565],
            [21.528733792, 61.899537823],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC141",
      },
    },
    {
      type: "Feature",
      id: "smf6c1c3f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.643835507, 61.793801413],
            [21.734579213, 61.71018379],
            [22.081982585, 61.793828906],
            [21.991238653, 61.878022082],
            [21.643835507, 61.793801413],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC140",
      },
    },
    {
      type: "Feature",
      id: "sm5ac21c25",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.734579213, 61.71018379],
            [21.823191486, 61.628310819],
            [22.169923111, 61.712016066],
            [22.081982585, 61.793828906],
            [21.734579213, 61.71018379],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC139",
      },
    },
    {
      type: "Feature",
      id: "sma91568f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.823191486, 61.628310819],
            [21.911597309, 61.546412152],
            [22.257658752, 61.630176949],
            [22.169923111, 61.712016066],
            [21.823191486, 61.628310819],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC138",
      },
    },
    {
      type: "Feature",
      id: "sm21bd3dcf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.397126432, 61.280772576],
            [22.596147354, 61.330742011],
            [22.430092517, 61.467265682],
            [22.389810381, 61.483548308],
            [22.33110219, 61.481581372],
            [22.21091678, 61.451414573],
            [22.397126432, 61.280772576],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC1",
      },
    },
    {
      type: "Feature",
      id: "smdc5f9ff3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.414536985, 60.483370771],
            [18.259501937, 60.44320951],
            [18.253577097, 60.403006734],
            [18.403489879, 60.261108533],
            [18.597480191, 60.31139049],
            [18.414536985, 60.483370771],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC24",
      },
    },
    {
      type: "Feature",
      id: "sm419f11f2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.597480191, 60.31139049],
            [18.77333245, 60.356904314],
            [18.590899893, 60.528996559],
            [18.414536985, 60.483370771],
            [18.597480191, 60.31139049],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC23",
      },
    },
    {
      type: "Feature",
      id: "sm528e891f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.77333245, 60.356904314],
            [18.942511754, 60.400631174],
            [18.759902861, 60.57265806],
            [18.590899893, 60.528996559],
            [18.77333245, 60.356904314],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC22",
      },
    },
    {
      type: "Feature",
      id: "sm67acd9f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.942511754, 60.400631174],
            [19.112423295, 60.444488243],
            [18.929637302, 60.61644925],
            [18.759902861, 60.57265806],
            [18.942511754, 60.400631174],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC21",
      },
    },
    {
      type: "Feature",
      id: "sm9954cf29",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.112423295, 60.444488243],
            [19.286747334, 60.489422809],
            [19.103779642, 60.661315981],
            [18.929637302, 60.61644925],
            [19.112423295, 60.444488243],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC20",
      },
    },
    {
      type: "Feature",
      id: "sme01f59ee",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.286747334, 60.489422809],
            [19.464791548, 60.535252127],
            [19.281638279, 60.707075759],
            [19.103779642, 60.661315981],
            [19.286747334, 60.489422809],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC19",
      },
    },
    {
      type: "Feature",
      id: "smd42ba83a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.464791548, 60.535252127],
            [19.631220635, 60.578033071],
            [19.447893897, 60.749791467],
            [19.281638279, 60.707075759],
            [19.464791548, 60.535252127],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC18",
      },
    },
    {
      type: "Feature",
      id: "sm45852cba",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.631220635, 60.578033071],
            [19.811718356, 60.624366449],
            [19.628203484, 60.796053841],
            [19.447893897, 60.749791467],
            [19.631220635, 60.578033071],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC17",
      },
    },
    {
      type: "Feature",
      id: "sm27923fba",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.811718356, 60.624366449],
            [19.982757747, 60.66821056],
            [19.799064601, 60.839830426],
            [19.628203484, 60.796053841],
            [19.811718356, 60.624366449],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC16",
      },
    },
    {
      type: "Feature",
      id: "sm8b82c528",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.982757747, 60.66821056],
            [20.152787265, 60.7117367],
            [19.968916896, 60.883289206],
            [19.799064601, 60.839830426],
            [19.982757747, 60.66821056],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC15",
      },
    },
    {
      type: "Feature",
      id: "sm7a40f901",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.152787265, 60.7117367],
            [20.32447041, 60.755626423],
            [20.140421095, 60.927110678],
            [19.968916896, 60.883289206],
            [20.152787265, 60.7117367],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC14",
      },
    },
    {
      type: "Feature",
      id: "smcc5e06a1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.32447041, 60.755626423],
            [20.497884363, 60.799897741],
            [20.313654298, 60.971312819],
            [20.140421095, 60.927110678],
            [20.32447041, 60.755626423],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC13",
      },
    },
    {
      type: "Feature",
      id: "smccddf96e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.497884363, 60.799897741],
            [20.673098531, 60.844566563],
            [20.488685839, 61.015911503],
            [20.313654298, 60.971312819],
            [20.497884363, 60.799897741],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC12",
      },
    },
    {
      type: "Feature",
      id: "sm43959770",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.673098531, 60.844566563],
            [20.844523998, 60.888209169],
            [20.659932629, 61.059485252],
            [20.488685839, 61.015911503],
            [20.673098531, 60.844566563],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC11",
      },
    },
    {
      type: "Feature",
      id: "sm153582d3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.844523998, 60.888209169],
            [21.013921066, 60.93127681],
            [20.829153134, 61.102484623],
            [20.659932629, 61.059485252],
            [20.844523998, 60.888209169],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC10",
      },
    },
    {
      type: "Feature",
      id: "sm695154e3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.013921066, 60.93127681],
            [21.188413416, 60.975579071],
            [21.00346361, 61.146716325],
            [20.829153134, 61.102484623],
            [21.013921066, 60.93127681],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC9",
      },
    },
    {
      type: "Feature",
      id: "sm9c9b1f7a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.188413416, 60.975579071],
            [21.367466619, 61.02097521],
            [21.182330186, 61.192039813],
            [21.00346361, 61.146716325],
            [21.188413416, 60.975579071],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC8",
      },
    },
    {
      type: "Feature",
      id: "sm57388f90",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.367466619, 61.02097521],
            [21.538941538, 61.064389201],
            [21.353626375, 61.235383993],
            [21.182330186, 61.192039813],
            [21.367466619, 61.02097521],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC7",
      },
    },
    {
      type: "Feature",
      id: "sm2674528e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.538941538, 61.064389201],
            [21.708951982, 61.107373754],
            [21.523459617, 61.278299106],
            [21.353626375, 61.235383993],
            [21.538941538, 61.064389201],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC6",
      },
    },
    {
      type: "Feature",
      id: "sm84c6a44e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.708951982, 61.107373754],
            [21.878962427, 61.150299951],
            [21.693292859, 61.32115564],
            [21.523459617, 61.278299106],
            [21.708951982, 61.107373754],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC5",
      },
    },
    {
      type: "Feature",
      id: "sm801bd202",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.878962427, 61.150299951],
            [22.054117606, 61.194464177],
            [21.868265474, 61.365247862],
            [21.693292859, 61.32115564],
            [21.878962427, 61.150299951],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC4",
      },
    },
    {
      type: "Feature",
      id: "sm9f82f215",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.054117606, 61.194464177],
            [22.225988138, 61.237740107],
            [22.039956864, 61.408452909],
            [21.868265474, 61.365247862],
            [22.054117606, 61.194464177],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC3",
      },
    },
    {
      type: "Feature",
      id: "sm62fe3655",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.225988138, 61.237740107],
            [22.397126432, 61.280772576],
            [22.21091678, 61.451414573],
            [22.039956864, 61.408452909],
            [22.225988138, 61.237740107],
          ],
        ],
      },
      properties: {
        Unit_Number: "VC2",
      },
    },
  ],
};

export default geojsonData;
