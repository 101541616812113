import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Insights from "../../components/AdminInsights";
import axios from "axios";
import UpdateTaskModal from "./UpdateTask";
import TargetCompletionCard from "../../components/AdminTaskCompletion";
import DonutChart from "../../components/DonutChart";
//import RadarChart from "../../components/RadarChart";
import { Home, BarChart } from "react-feather";
//import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HosInsights from "../../components/HosInsights";
import GmInsights from "../../components/GmInsights";
import ViewTasks from "./ViewAssignedTasks";
import TeamInsights from "../../components/TeamsMIBInsights";

const AdminDashboard = () => {
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");

  // Define access role checks
  const isIvoryAdmin = accessRoles.includes("isIvoryAdmin");
  const isIvoryDirector = accessRoles.includes("isIvoryDirector");
  const isIvoryTeam = accessRoles.includes("isIvoryTeam");
  const isIvoryHos = accessRoles.includes("isIvoryHos");
  const isIvoryGm = accessRoles.includes("isIvoryGm");

  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTaskForUpdate, setSelectedTaskForUpdate] = useState(null);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the current month's start and end dates
        const currentDate = new Date();
        const startOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const endOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        );

        // Format dates to ISO strings
        const startDateString = startOfMonth.toISOString().split("T")[0];
        const endDateString = endOfMonth.toISOString().split("T")[0];

        // Fetch user tasks for the current month
        const tasksResponse = await axios.get(
          `https://workspace.optiven.co.ke/api/perf/current-month/${startDateString}/${endDateString}`
        );

        setTasks(tasksResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // const openUpdateModal = (taskId) => {
  //   console.log("Clicked Details Button for Task ID:", taskId);
  //   setSelectedTaskForUpdate(taskId);
  //   setIsModalOpen(true);
  // };

  const closeUpdateModal = () => {
    setSelectedTaskForUpdate(null);
    setIsModalOpen(false);
  };

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const firstName = user.fullnames
    ? user.fullnames.trim().split(" ")[0]
    : "user";

  const now = new Date();
  const hour = now.getHours();
  let greeting;

  if (hour < 12) {
    greeting = "Good morning,";
  } else if (hour >= 12 && hour < 17) {
    greeting = "Good afternoon,";
  } else {
    greeting = "Good evening,";
  }

 

  const renderInsightsComponent = () => {
    if (
      isIvoryAdmin ||
      isIvoryDirector ||
      isIvoryTeam ||
      department === "ICT SYSTEMS"
    ) {
      return <Insights />;
    } else if (isIvoryHos) {
      return <HosInsights />;
    } else if (isIvoryGm) {
      return <GmInsights />;
    } else {
      // Add a default component or handle the case where no role matches
      return <div>No matching role found</div>;
    }
  };

  return (
    <Sidebar>
      {/* Main Dashboard Content */}
      <div className="h-full w-full flex overflow-hidden antialiased text-gray-800 bg-white">
        <div className="flex-1 flex flex-col overflow-y-auto">
          <main className="flex-grow flex flex-col min-h-0 border-t">
            <section className="flex min-h-0 flex-col flex-auto border-l px-4 md:px-8 lg:px-16 xl:px-24">
              <div className="flex flex-col items-center">
                <h2 className="mt-6 text-4xl sm:text-5xl lg:text-7xl brave tracking-wider font-thick text-center">
                  {greeting}{" "}
                  <span className="brave tracking-wider font-thick">
                    {firstName}.
                  </span>
                </h2>
              </div>

              {renderInsightsComponent()}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div>
                  <DonutChart tasks={tasks} />
                </div>
                <div>
                  <TargetCompletionCard />
                  {/* Uncomment the following line if you want to add the RadarChart */}
                  {/* <RadarChart /> */}
                </div>
              </div>

              {/*<TeamInsights />*/}
            </section>
          </main>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        {/* Adjusted marginBottom value */}
        <h2 style={{ textDecoration: "underline", fontSize: "24px" }}>
          <strong>Tasks Requiring Your Attention.</strong>
        </h2>
      </div>
      <ViewTasks />

      {/* Green footer */}
      <div
        style={{
          backgroundColor: "#89bf70",
          color: "white",
          padding: "10px",
          textAlign: "center",
        }}
      >
        Copyright © 2023. All rights reserved.
      </div>

      <UpdateTaskModal
        isOpen={isModalOpen}
        onClose={closeUpdateModal}
        task={selectedTaskForUpdate}
      />
    </Sidebar>
  );
};

export default AdminDashboard;
