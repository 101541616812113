const geojsonData = {
  type: "FeatureCollection",

  features: [
    {
      type: "Feature",
      id: "sm68949954",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716044334, 61.696654404],
            [18.736300039, 61.696683871],
            [18.736257141, 61.703312371],
            [18.718404358, 61.702661372],
            [18.716001098, 61.701277956],
            [18.716044334, 61.696654404],
          ],
        ],
      },
      properties: {
        Unit_Number: "V119",
      },
    },
    {
      type: "Feature",
      id: "sma78d18ed",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.667249265, 61.532782924],
            [18.687128786, 61.53282639],
            [18.687062811, 61.539681186],
            [18.669995847, 61.539643878],
            [18.667249265, 61.53858039],
            [18.667249265, 61.532782924],
          ],
        ],
      },
      properties: {
        Unit_Number: "V146",
      },
    },
    {
      type: "Feature",
      id: "smc5557249",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.722996309, 61.715922752],
            [18.722824647, 61.705936573],
            [18.732823912, 61.706567155],
            [18.735570494, 61.708194418],
            [18.735742155, 61.713319736],
            [18.727845732, 61.713238389],
            [18.727845732, 61.716654803],
            [18.722996309, 61.715922752],
          ],
        ],
      },
      properties: {
        Unit_Number: "V180",
      },
    },
    {
      type: "Feature",
      id: "smd5e75a9c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.722824647, 61.705936573],
            [18.722996309, 61.715922752],
            [18.710121707, 61.715109337],
            [18.710121707, 61.705387343],
            [18.722824647, 61.705936573],
          ],
        ],
      },
      properties: {
        Unit_Number: "V179",
      },
    },
    {
      type: "Feature",
      id: "sm5b8597dc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.710121707, 61.705387343],
            [18.710121707, 61.715109337],
            [18.697847919, 61.714295899],
            [18.697762088, 61.704695704],
            [18.710121707, 61.705387343],
          ],
        ],
      },
      properties: {
        Unit_Number: "V178",
      },
    },
    {
      type: "Feature",
      id: "sm693e4349",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.697762088, 61.704695704],
            [18.697847919, 61.714295899],
            [18.685359553, 61.713604458],
            [18.68501623, 61.70408542],
            [18.697762088, 61.704695704],
          ],
        ],
      },
      properties: {
        Unit_Number: "V177",
      },
    },
    {
      type: "Feature",
      id: "sm1290e629",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.68501623, 61.70408542],
            [18.685359553, 61.713604458],
            [18.671969964, 61.712750304],
            [18.672141625, 61.703271689],
            [18.68501623, 61.70408542],
          ],
        ],
      },
      properties: {
        Unit_Number: "V176",
      },
    },
    {
      type: "Feature",
      id: "sm03cbdc5e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.672141625, 61.703271689],
            [18.671969964, 61.712750304],
            [18.659181191, 61.711977479],
            [18.659095361, 61.702498627],
            [18.672141625, 61.703271689],
          ],
        ],
      },
      properties: {
        Unit_Number: "V175",
      },
    },
    {
      type: "Feature",
      id: "smb3bb0821",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.659095361, 61.702498627],
            [18.659181191, 61.711977479],
            [18.646564079, 61.711163958],
            [18.646306578, 61.701766228],
            [18.659095361, 61.702498627],
          ],
        ],
      },
      properties: {
        Unit_Number: "V174",
      },
    },
    {
      type: "Feature",
      id: "sm055c7dd9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.646220758, 61.703068254],
            [18.646564079, 61.711163958],
            [18.634805267, 61.710472444],
            [18.635128147, 61.698523887],
            [18.646338315, 61.698591966],
            [18.646306578, 61.698592324],
            [18.646306578, 61.701766228],
            [18.646220758, 61.703068254],
          ],
        ],
      },
      properties: {
        Unit_Number: "V173",
      },
    },
    {
      type: "Feature",
      id: "sm8d9b3750",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.646338315, 61.698591966],
            [18.635128147, 61.698523887],
            [18.635328025, 61.691124858],
            [18.656075396, 61.691241871],
            [18.655919615, 61.697452894],
            [18.653516356, 61.698510937],
            [18.646338315, 61.698591966],
          ],
        ],
      },
      properties: {
        Unit_Number: "V172",
      },
    },
    {
      type: "Feature",
      id: "sm9751c6a5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.656075396, 61.691241871],
            [18.635328025, 61.691124858],
            [18.635503435, 61.684630106],
            [18.656238261, 61.684747074],
            [18.656075396, 61.691241871],
          ],
        ],
      },
      properties: {
        Unit_Number: "V171",
      },
    },
    {
      type: "Feature",
      id: "smbbe5d875",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.656238261, 61.684747074],
            [18.635503435, 61.684630106],
            [18.635692245, 61.67763767],
            [18.656413568, 61.677754588],
            [18.656238261, 61.684747074],
          ],
        ],
      },
      properties: {
        Unit_Number: "V170",
      },
    },
    {
      type: "Feature",
      id: "smc373ec16",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.656413568, 61.677754588],
            [18.635692245, 61.67763767],
            [18.635881578, 61.670624277],
            [18.65658936, 61.670741145],
            [18.656413568, 61.677754588],
          ],
        ],
      },
      properties: {
        Unit_Number: "V169",
      },
    },
    {
      type: "Feature",
      id: "sm6802bd1f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.65658936, 61.670741145],
            [18.635881578, 61.670624277],
            [18.636070754, 61.663615104],
            [18.656765007, 61.663731922],
            [18.65658936, 61.670741145],
          ],
        ],
      },
      properties: {
        Unit_Number: "V168",
      },
    },
    {
      type: "Feature",
      id: "sm1804a507",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.656765007, 61.663731922],
            [18.636070754, 61.663615104],
            [18.636254958, 61.65678863],
            [18.656936037, 61.656905399],
            [18.656765007, 61.663731922],
          ],
        ],
      },
      properties: {
        Unit_Number: "V167",
      },
    },
    {
      type: "Feature",
      id: "sm73416c1c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.656936037, 61.656905399],
            [18.636254958, 61.65678863],
            [18.636440103, 61.649925753],
            [18.657107941, 61.650042473],
            [18.656936037, 61.656905399],
          ],
        ],
      },
      properties: {
        Unit_Number: "V166",
      },
    },
    {
      type: "Feature",
      id: "sm8c61aac0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.657107941, 61.650042473],
            [18.636440103, 61.649925753],
            [18.636633938, 61.642739147],
            [18.657287913, 61.642855816],
            [18.657107941, 61.650042473],
          ],
        ],
      },
      properties: {
        Unit_Number: "V165",
      },
    },
    {
      type: "Feature",
      id: "sma4e432c8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.657287913, 61.642855816],
            [18.636633938, 61.642739147],
            [18.636818456, 61.635896424],
            [18.657459234, 61.636013044],
            [18.657287913, 61.642855816],
          ],
        ],
      },
      properties: {
        Unit_Number: "V164",
      },
    },
    {
      type: "Feature",
      id: "sma3a52077",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.657459234, 61.636013044],
            [18.636818456, 61.635896424],
            [18.637003549, 61.629030848],
            [18.657631089, 61.629147419],
            [18.657459234, 61.636013044],
          ],
        ],
      },
      properties: {
        Unit_Number: "V163",
      },
    },
    {
      type: "Feature",
      id: "smd40ce41c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.657631089, 61.629147419],
            [18.637003549, 61.629030848],
            [18.637186051, 61.622259876],
            [18.657800539, 61.622376399],
            [18.657631089, 61.629147419],
          ],
        ],
      },
      properties: {
        Unit_Number: "V162",
      },
    },
    {
      type: "Feature",
      id: "sm2fb432fd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.657800539, 61.622376399],
            [18.637186051, 61.622259876],
            [18.637370778, 61.615404859],
            [18.657972054, 61.615521334],
            [18.657800539, 61.622376399],
          ],
        ],
      },
      properties: {
        Unit_Number: "V161",
      },
    },
    {
      type: "Feature",
      id: "sm0aaa40eb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.657972054, 61.615521334],
            [18.637370778, 61.615404859],
            [18.637559823, 61.608388013],
            [18.658147579, 61.608504437],
            [18.657972054, 61.615521334],
          ],
        ],
      },
      properties: {
        Unit_Number: "V160",
      },
    },
    {
      type: "Feature",
      id: "smdfd9adad",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.658147579, 61.608504437],
            [18.637559823, 61.608388013],
            [18.637742038, 61.601623204],
            [18.658316762, 61.60173958],
            [18.658147579, 61.608504437],
          ],
        ],
      },
      properties: {
        Unit_Number: "V159",
      },
    },
    {
      type: "Feature",
      id: "sma0e4c54b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.658316762, 61.60173958],
            [18.637742038, 61.601623204],
            [18.637933805, 61.594502165],
            [18.658494814, 61.59461849],
            [18.658316762, 61.60173958],
          ],
        ],
      },
      properties: {
        Unit_Number: "V158",
      },
    },
    {
      type: "Feature",
      id: "sm048b5cd4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.658494814, 61.59461849],
            [18.637933805, 61.594502165],
            [18.638116177, 61.587728495],
            [18.658664143, 61.587844772],
            [18.658494814, 61.59461849],
          ],
        ],
      },
      properties: {
        Unit_Number: "V157",
      },
    },
    {
      type: "Feature",
      id: "sm0d0fc323",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.658664143, 61.587844772],
            [18.638116177, 61.587728495],
            [18.638307238, 61.580630492],
            [18.65884154, 61.580746719],
            [18.658664143, 61.587844772],
          ],
        ],
      },
      properties: {
        Unit_Number: "V156",
      },
    },
    {
      type: "Feature",
      id: "sm5ecd9035",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.65884154, 61.580746719],
            [18.638307238, 61.580630492],
            [18.638498822, 61.573511431],
            [18.659019422, 61.573627606],
            [18.65884154, 61.580746719],
          ],
        ],
      },
      properties: {
        Unit_Number: "V155",
      },
    },
    {
      type: "Feature",
      id: "sm04b261f6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.659019422, 61.573627606],
            [18.638498822, 61.573511431],
            [18.638678943, 61.566816838],
            [18.659186661, 61.566932966],
            [18.659019422, 61.573627606],
          ],
        ],
      },
      properties: {
        Unit_Number: "V154",
      },
    },
    {
      type: "Feature",
      id: "sm2d7ba22d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.659186661, 61.566932966],
            [18.638678943, 61.566816838],
            [18.638863435, 61.559958295],
            [18.659357958, 61.560074373],
            [18.659186661, 61.566932966],
          ],
        ],
      },
      properties: {
        Unit_Number: "V153",
      },
    },
    {
      type: "Feature",
      id: "smf132a09e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.659357958, 61.560074373],
            [18.638863435, 61.559958295],
            [18.639048345, 61.553082678],
            [18.659529643, 61.553198707],
            [18.659357958, 61.560074373],
          ],
        ],
      },
      properties: {
        Unit_Number: "V152",
      },
    },
    {
      type: "Feature",
      id: "sm2453e1a4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.659529643, 61.553198707],
            [18.639048345, 61.553082678],
            [18.639241761, 61.545889132],
            [18.659709227, 61.546005109],
            [18.659529643, 61.553198707],
          ],
        ],
      },
      properties: {
        Unit_Number: "V151",
      },
    },
    {
      type: "Feature",
      id: "smdae5117d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.659709227, 61.546005109],
            [18.639241761, 61.545889132],
            [18.639424551, 61.539089274],
            [18.659878944, 61.539205203],
            [18.659709227, 61.546005109],
          ],
        ],
      },
      properties: {
        Unit_Number: "V150",
      },
    },
    {
      type: "Feature",
      id: "smec38b0d2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.659878944, 61.539205203],
            [18.639424551, 61.539089274],
            [18.639597461, 61.532655587],
            [18.660039488, 61.53277147],
            [18.659878944, 61.539205203],
          ],
        ],
      },
      properties: {
        Unit_Number: "V149",
      },
    },
    {
      type: "Feature",
      id: "sm90bf8160",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.660039488, 61.53277147],
            [18.639597461, 61.532655587],
            [18.639783447, 61.525733858],
            [18.6642452, 61.526913419],
            [18.6642452, 61.53277147],
            [18.660039488, 61.53277147],
            [18.660039488, 61.53277147],
          ],
        ],
      },
      properties: {
        Unit_Number: "V148",
      },
    },
    {
      type: "Feature",
      id: "sm0991cfee",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.6642452, 61.53277147],
            [18.6642452, 61.526913419],
            [18.687175046, 61.52801907],
            [18.687128786, 61.53282639],
            [18.667249265, 61.532782924],
            [18.667249265, 61.53277147],
            [18.6642452, 61.53277147],
          ],
        ],
      },
      properties: {
        Unit_Number: "V147",
      },
    },
    {
      type: "Feature",
      id: "sme036b33b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687062811, 61.539681186],
            [18.687175046, 61.52801907],
            [18.700214474, 61.528647799],
            [18.700108014, 61.539709703],
            [18.687062811, 61.539681186],
          ],
        ],
      },
      properties: {
        Unit_Number: "V145",
      },
    },
    {
      type: "Feature",
      id: "smc35f1ca2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.700108014, 61.539709703],
            [18.700214474, 61.528647799],
            [18.717116904, 61.529621114],
            [18.717493561, 61.535636378],
            [18.710507932, 61.535798789],
            [18.71016461, 61.538416773],
            [18.707418028, 61.539725683],
            [18.700108014, 61.539709703],
          ],
        ],
      },
      properties: {
        Unit_Number: "V144",
      },
    },
    {
      type: "Feature",
      id: "sm7c5bface",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717493561, 61.535636378],
            [18.717116904, 61.529621114],
            [18.737372941, 61.530439418],
            [18.737327616, 61.537480419],
            [18.717529153, 61.537451468],
            [18.717546049, 61.535635158],
            [18.717493561, 61.535636378],
          ],
        ],
      },
      properties: {
        Unit_Number: "V143",
      },
    },
    {
      type: "Feature",
      id: "sm3fa2b16e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717529153, 61.537451468],
            [18.737327616, 61.537480419],
            [18.737283896, 61.544270681],
            [18.717465979, 61.544241708],
            [18.717529153, 61.537451468],
          ],
        ],
      },
      properties: {
        Unit_Number: "V142",
      },
    },
    {
      type: "Feature",
      id: "sm8c0be1ca",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717465979, 61.544241708],
            [18.737283896, 61.544270681],
            [18.737236499, 61.551630369],
            [18.717397492, 61.551601372],
            [18.717465979, 61.544241708],
          ],
        ],
      },
      properties: {
        Unit_Number: "V141",
      },
    },
    {
      type: "Feature",
      id: "sm112c839d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717397492, 61.551601372],
            [18.737236499, 61.551630369],
            [18.737194891, 61.558089763],
            [18.717337369, 61.558060746],
            [18.717397492, 61.551601372],
          ],
        ],
      },
      properties: {
        Unit_Number: "V140",
      },
    },
    {
      type: "Feature",
      id: "sm7625f62b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717337369, 61.558060746],
            [18.737194891, 61.558089763],
            [18.737147502, 61.565444922],
            [18.717268893, 61.56541588],
            [18.717337369, 61.558060746],
          ],
        ],
      },
      properties: {
        Unit_Number: "V139",
      },
    },
    {
      type: "Feature",
      id: "sm09785bbf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717268893, 61.56541588],
            [18.737147502, 61.565444922],
            [18.737104307, 61.572147601],
            [18.717206478, 61.572118538],
            [18.717268893, 61.56541588],
          ],
        ],
      },
      properties: {
        Unit_Number: "V138",
      },
    },
    {
      type: "Feature",
      id: "sme2a4ee58",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717206478, 61.572118538],
            [18.737104307, 61.572147601],
            [18.737057941, 61.579340789],
            [18.71713948, 61.579311702],
            [18.717206478, 61.572118538],
          ],
        ],
      },
      properties: {
        Unit_Number: "V137",
      },
    },
    {
      type: "Feature",
      id: "sme54ece4d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.71713948, 61.579311702],
            [18.737057941, 61.579340789],
            [18.737015799, 61.585877101],
            [18.717078587, 61.585847993],
            [18.71713948, 61.579311702],
          ],
        ],
      },
      properties: {
        Unit_Number: "V136",
      },
    },
    {
      type: "Feature",
      id: "sm5ac45a85",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717078587, 61.585847993],
            [18.737015799, 61.585877101],
            [18.736970517, 61.592899018],
            [18.717013155, 61.592869887],
            [18.717078587, 61.585847993],
          ],
        ],
      },
      properties: {
        Unit_Number: "V135",
      },
    },
    {
      type: "Feature",
      id: "sm8536ec7b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.717013155, 61.592869887],
            [18.736970517, 61.592899018],
            [18.736925199, 61.599924855],
            [18.716947672, 61.599895701],
            [18.717013155, 61.592869887],
          ],
        ],
      },
      properties: {
        Unit_Number: "V134",
      },
    },
    {
      type: "Feature",
      id: "sm66295713",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716947672, 61.599895701],
            [18.736925199, 61.599924855],
            [18.736880989, 61.606777328],
            [18.716883791, 61.606748152],
            [18.716947672, 61.599895701],
          ],
        ],
      },
      properties: {
        Unit_Number: "V133",
      },
    },
    {
      type: "Feature",
      id: "smcf0b4aec",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716883791, 61.606748152],
            [18.736880989, 61.606777328],
            [18.736835691, 61.613797014],
            [18.716818336, 61.613767815],
            [18.716883791, 61.606748152],
          ],
        ],
      },
      properties: {
        Unit_Number: "V132",
      },
    },
    {
      type: "Feature",
      id: "sm2e40d2c7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716818336, 61.613767815],
            [18.736835691, 61.613797014],
            [18.736789336, 61.62097879],
            [18.716751354, 61.620949568],
            [18.716818336, 61.613767815],
          ],
        ],
      },
      properties: {
        Unit_Number: "V131",
      },
    },
    {
      type: "Feature",
      id: "sm085e7e0b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716751354, 61.620949568],
            [18.736789336, 61.62097879],
            [18.736747159, 61.627511828],
            [18.716690409, 61.627482585],
            [18.716751354, 61.620949568],
          ],
        ],
      },
      properties: {
        Unit_Number: "V130",
      },
    },
    {
      type: "Feature",
      id: "sme1375dd3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716690409, 61.627482585],
            [18.736747159, 61.627511828],
            [18.736700862, 61.634681414],
            [18.716623511, 61.634652148],
            [18.716690409, 61.627482585],
          ],
        ],
      },
      properties: {
        Unit_Number: "V129",
      },
    },
    {
      type: "Feature",
      id: "sma1a4c059",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716623511, 61.634652148],
            [18.736700862, 61.634681414],
            [18.736657661, 61.641370131],
            [18.716561086, 61.641340843],
            [18.716623511, 61.634652148],
          ],
        ],
      },
      properties: {
        Unit_Number: "V128",
      },
    },
    {
      type: "Feature",
      id: "sm3e811ebe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716561086, 61.641340843],
            [18.736657661, 61.641370131],
            [18.73661234, 61.648385476],
            [18.716495598, 61.648356165],
            [18.716561086, 61.641340843],
          ],
        ],
      },
      properties: {
        Unit_Number: "V127",
      },
    },
    {
      type: "Feature",
      id: "smcec3f500",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716495598, 61.648356165],
            [18.73661234, 61.648385476],
            [18.736568069, 61.65523673],
            [18.716431628, 61.655207397],
            [18.716495598, 61.648356165],
          ],
        ],
      },
      properties: {
        Unit_Number: "V126",
      },
    },
    {
      type: "Feature",
      id: "sm44f5684b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716431628, 61.655207397],
            [18.736568069, 61.65523673],
            [18.736523362, 61.662153946],
            [18.716367028, 61.66212459],
            [18.716431628, 61.655207397],
          ],
        ],
      },
      properties: {
        Unit_Number: "V125",
      },
    },
    {
      type: "Feature",
      id: "sm9114cafb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716367028, 61.66212459],
            [18.736523362, 61.662153946],
            [18.736479624, 61.668919685],
            [18.716303828, 61.668890308],
            [18.716367028, 61.66212459],
          ],
        ],
      },
      properties: {
        Unit_Number: "V124",
      },
    },
    {
      type: "Feature",
      id: "sm83e2b1a3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716303828, 61.668890308],
            [18.736479624, 61.668919685],
            [18.736434904, 61.675835839],
            [18.716239209, 61.67580644],
            [18.716303828, 61.668890308],
          ],
        ],
      },
      properties: {
        Unit_Number: "V123",
      },
    },
    {
      type: "Feature",
      id: "sm0e56821e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716239209, 61.67580644],
            [18.736434904, 61.675835839],
            [18.736388543, 61.68300422],
            [18.716172218, 61.682974797],
            [18.716239209, 61.67580644],
          ],
        ],
      },
      properties: {
        Unit_Number: "V122",
      },
    },
    {
      type: "Feature",
      id: "sm075322ff",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716172218, 61.682974797],
            [18.736388543, 61.68300422],
            [18.736344291, 61.689844803],
            [18.716108276, 61.689815358],
            [18.716172218, 61.682974797],
          ],
        ],
      },
      properties: {
        Unit_Number: "V121",
      },
    },
    {
      type: "Feature",
      id: "sme1e79c80",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.716108276, 61.689815358],
            [18.736344291, 61.689844803],
            [18.736300039, 61.696683871],
            [18.716044334, 61.696654404],
            [18.716108276, 61.689815358],
          ],
        ],
      },
      properties: {
        Unit_Number: "V120",
      },
    },
    {
      type: "Feature",
      id: "sma92e9159",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686543164, 61.651596704],
            [18.706044739, 61.651637407],
            [18.709048813, 61.653104555],
            [18.709022577, 61.65796656],
            [18.686484445, 61.657939148],
            [18.686543164, 61.651596704],
          ],
        ],
      },
      properties: {
        Unit_Number: "V196",
      },
    },
    {
      type: "Feature",
      id: "sm6052ab8a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686484445, 61.657939148],
            [18.664186461, 61.657912028],
            [18.664331025, 61.652819282],
            [18.666991776, 61.651555897],
            [18.686543164, 61.651596704],
            [18.686484445, 61.657939148],
          ],
        ],
      },
      properties: {
        Unit_Number: "V195",
      },
    },
    {
      type: "Feature",
      id: "sm7f142af3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686492441, 61.66371631],
            [18.664022445, 61.663688986],
            [18.664186461, 61.657912028],
            [18.686523621, 61.657939196],
            [18.686492441, 61.66371631],
          ],
        ],
      },
      properties: {
        Unit_Number: "V193",
      },
    },
    {
      type: "Feature",
      id: "sm2b820e8b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686523621, 61.657939196],
            [18.709022577, 61.65796656],
            [18.708991397, 61.663743669],
            [18.686492441, 61.66371631],
            [18.686523621, 61.657939196],
          ],
        ],
      },
      properties: {
        Unit_Number: "V194",
      },
    },
    {
      type: "Feature",
      id: "smabcf1774",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686830482, 61.669990333],
            [18.6638443, 61.669962388],
            [18.664022445, 61.663688986],
            [18.686864348, 61.663716762],
            [18.686830482, 61.669990333],
          ],
        ],
      },
      properties: {
        Unit_Number: "V191",
      },
    },
    {
      type: "Feature",
      id: "sm4827a378",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686864348, 61.663716762],
            [18.708991397, 61.663743669],
            [18.708957532, 61.670017234],
            [18.686830482, 61.669990333],
            [18.686864348, 61.663716762],
          ],
        ],
      },
      properties: {
        Unit_Number: "V192",
      },
    },
    {
      type: "Feature",
      id: "sm5e95e94a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686484963, 61.676098943],
            [18.663670794, 61.676071212],
            [18.6638443, 61.669962388],
            [18.686517946, 61.669989953],
            [18.686484963, 61.676098943],
          ],
        ],
      },
      properties: {
        Unit_Number: "V189",
      },
    },
    {
      type: "Feature",
      id: "sma87743f5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686517946, 61.669989953],
            [18.708957532, 61.670017234],
            [18.708924549, 61.676126219],
            [18.686484963, 61.676098943],
            [18.686517946, 61.669989953],
          ],
        ],
      },
      properties: {
        Unit_Number: "V190",
      },
    },
    {
      type: "Feature",
      id: "smcd168b3b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.685969544, 61.682531438],
            [18.663488046, 61.682504117],
            [18.663670794, 61.676071212],
            [18.686004285, 61.676098359],
            [18.685969544, 61.682531438],
          ],
        ],
      },
      properties: {
        Unit_Number: "V187",
      },
    },
    {
      type: "Feature",
      id: "sm56a38847",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686004285, 61.676098359],
            [18.708924549, 61.676126219],
            [18.708889809, 61.682559292],
            [18.685969544, 61.682531438],
            [18.686004285, 61.676098359],
          ],
        ],
      },
      properties: {
        Unit_Number: "V188",
      },
    },
    {
      type: "Feature",
      id: "sm410e447f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.68580359, 61.688477045],
            [18.663319107, 61.688449726],
            [18.663488046, 61.682504117],
            [18.685835705, 61.682531275],
            [18.68580359, 61.688477045],
          ],
        ],
      },
      properties: {
        Unit_Number: "V185",
      },
    },
    {
      type: "Feature",
      id: "sm7f1e32a5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.685835705, 61.682531275],
            [18.708889809, 61.682559292],
            [18.708857694, 61.688505057],
            [18.68580359, 61.688477045],
            [18.685835705, 61.682531275],
          ],
        ],
      },
      properties: {
        Unit_Number: "V186",
      },
    },
    {
      type: "Feature",
      id: "sma43b61fd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.685973019, 61.694670068],
            [18.663143115, 61.694642335],
            [18.663319107, 61.688449726],
            [18.686006475, 61.688477292],
            [18.685973019, 61.694670068],
          ],
        ],
      },
      properties: {
        Unit_Number: "V183",
      },
    },
    {
      type: "Feature",
      id: "sm7122107d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686006475, 61.688477292],
            [18.708857694, 61.688505057],
            [18.708824238, 61.694697827],
            [18.685973019, 61.694670068],
            [18.686006475, 61.688477292],
          ],
        ],
      },
      properties: {
        Unit_Number: "V184",
      },
    },
    {
      type: "Feature",
      id: "sm128ac377",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688379663, 61.700799227],
            [18.666390961, 61.699772404],
            [18.663043564, 61.698144696],
            [18.663143115, 61.694642335],
            [18.688412765, 61.694673032],
            [18.688379663, 61.700799227],
          ],
        ],
      },
      properties: {
        Unit_Number: "V181",
      },
    },
    {
      type: "Feature",
      id: "sm6c076f58",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688412765, 61.694673032],
            [18.708824238, 61.694697827],
            [18.708791321, 61.700789678],
            [18.706473892, 61.701644162],
            [18.688379663, 61.700799227],
            [18.688412765, 61.694673032],
          ],
        ],
      },
      properties: {
        Unit_Number: "V182",
      },
    },
    {
      type: "Feature",
      id: "sm41469ea7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.68762579, 61.598120465],
            [18.687507913, 61.604634925],
            [18.664756116, 61.604541811],
            [18.664931839, 61.599100264],
            [18.664931839, 61.599100264],
            [18.668021744, 61.598120465],
            [18.68762579, 61.598120465],
          ],
        ],
      },
      properties: {
        Unit_Number: "V211",
      },
    },
    {
      type: "Feature",
      id: "sma52dac5d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687507913, 61.604634925],
            [18.68762579, 61.598120465],
            [18.7073322, 61.598120465],
            [18.709392136, 61.5996718],
            [18.709300714, 61.604724113],
            [18.687507913, 61.604634925],
          ],
        ],
      },
      properties: {
        Unit_Number: "V212",
      },
    },
    {
      type: "Feature",
      id: "smdbc46993",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687443069, 61.6108298],
            [18.687555183, 61.604635118],
            [18.709300714, 61.604724113],
            [18.7091886, 61.610918777],
            [18.687443069, 61.6108298],
          ],
        ],
      },
      properties: {
        Unit_Number: "V210",
      },
    },
    {
      type: "Feature",
      id: "sm979a8f72",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687555183, 61.604635118],
            [18.687443069, 61.6108298],
            [18.664556045, 61.610736152],
            [18.664756116, 61.604541811],
            [18.687555183, 61.604635118],
          ],
        ],
      },
      properties: {
        Unit_Number: "V209",
      },
    },
    {
      type: "Feature",
      id: "sm4e268fd0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686928085, 61.616950526],
            [18.687038912, 61.610828146],
            [18.7091886, 61.610918777],
            [18.709077773, 61.617041139],
            [18.686928085, 61.616950526],
          ],
        ],
      },
      properties: {
        Unit_Number: "V208",
      },
    },
    {
      type: "Feature",
      id: "sm12070d6c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687038912, 61.610828146],
            [18.686928085, 61.616950526],
            [18.66435827, 61.616858195],
            [18.664556045, 61.610736152],
            [18.687038912, 61.610828146],
          ],
        ],
      },
      properties: {
        Unit_Number: "V207",
      },
    },
    {
      type: "Feature",
      id: "smf4db15e9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686938401, 61.623316245],
            [18.687053647, 61.61695104],
            [18.709077773, 61.617041139],
            [18.708962527, 61.623406325],
            [18.686938401, 61.623316245],
          ],
        ],
      },
      properties: {
        Unit_Number: "V206",
      },
    },
    {
      type: "Feature",
      id: "sm64b43ad8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687053647, 61.61695104],
            [18.686938401, 61.623316245],
            [18.66415261, 61.623223049],
            [18.66435827, 61.616858195],
            [18.687053647, 61.61695104],
          ],
        ],
      },
      properties: {
        Unit_Number: "V205",
      },
    },
    {
      type: "Feature",
      id: "sme0b1f80d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686934072, 61.62942887],
            [18.687044759, 61.62331668],
            [18.708962527, 61.623406325],
            [18.70885184, 61.629518498],
            [18.686934072, 61.62942887],
          ],
        ],
      },
      properties: {
        Unit_Number: "V204",
      },
    },
    {
      type: "Feature",
      id: "sm029b2388",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687044759, 61.62331668],
            [18.686934072, 61.62942887],
            [18.663955085, 61.629334903],
            [18.66415261, 61.623223049],
            [18.687044759, 61.62331668],
          ],
        ],
      },
      properties: {
        Unit_Number: "V203",
      },
    },
    {
      type: "Feature",
      id: "smc02f13c6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687110088, 61.63555015],
            [18.687220941, 61.629430044],
            [18.70885184, 61.629518498],
            [18.708740988, 61.635638587],
            [18.687110088, 61.63555015],
          ],
        ],
      },
      properties: {
        Unit_Number: "V202",
      },
    },
    {
      type: "Feature",
      id: "sm909e446f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687220941, 61.629430044],
            [18.687110088, 61.63555015],
            [18.663757265, 61.635454673],
            [18.663955085, 61.629334903],
            [18.687220941, 61.629430044],
          ],
        ],
      },
      properties: {
        Unit_Number: "V201",
      },
    },
    {
      type: "Feature",
      id: "sm648f7338",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.686923706, 61.641989267],
            [18.687040365, 61.635549865],
            [18.708740988, 61.635638587],
            [18.708624329, 61.642077971],
            [18.686923706, 61.641989267],
          ],
        ],
      },
      properties: {
        Unit_Number: "V200",
      },
    },
    {
      type: "Feature",
      id: "smc68ecec3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687040365, 61.635549865],
            [18.686923706, 61.641989267],
            [18.663549082, 61.641893721],
            [18.663757265, 61.635454673],
            [18.687040365, 61.635549865],
          ],
        ],
      },
      properties: {
        Unit_Number: "V199",
      },
    },
    {
      type: "Feature",
      id: "smc232aced",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687110746, 61.648233468],
            [18.68722387, 61.641990494],
            [18.708624329, 61.642077971],
            [18.708533829, 61.6470725],
            [18.70613057, 61.64821379],
            [18.687110746, 61.648233468],
          ],
        ],
      },
      properties: {
        Unit_Number: "V198",
      },
    },
    {
      type: "Feature",
      id: "smb25caa8c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.68722387, 61.641990494],
            [18.687110746, 61.648233468],
            [18.666734284, 61.648254549],
            [18.663386887, 61.646909455],
            [18.663549082, 61.641893721],
            [18.68722387, 61.641990494],
          ],
        ],
      },
      properties: {
        Unit_Number: "V197",
      },
    },
    {
      type: "Feature",
      id: "sm0ad79382",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688774665, 61.543034538],
            [18.688518608, 61.54940892],
            [18.666987927, 61.549340195],
            [18.667163438, 61.544756292],
            [18.667163438, 61.544756292],
            [18.671283311, 61.542875023],
            [18.688774665, 61.543034538],
          ],
        ],
      },
      properties: {
        Unit_Number: "V227",
      },
    },
    {
      type: "Feature",
      id: "sm4d31cc6f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688518608, 61.54940892],
            [18.688774665, 61.543034538],
            [18.707160539, 61.54320221],
            [18.710765428, 61.544674502],
            [18.710697852, 61.549479715],
            [18.688518608, 61.54940892],
          ],
        ],
      },
      properties: {
        Unit_Number: "V228",
      },
    },
    {
      type: "Feature",
      id: "sm7526bb2c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688319963, 61.556350009],
            [18.688417598, 61.549408598],
            [18.710697852, 61.549479715],
            [18.710600216, 61.556421111],
            [18.688319963, 61.556350009],
          ],
        ],
      },
      properties: {
        Unit_Number: "V226",
      },
    },
    {
      type: "Feature",
      id: "sma17bc4b0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688417598, 61.549408598],
            [18.688319963, 61.556350009],
            [18.66672212, 61.556281085],
            [18.666987927, 61.549340195],
            [18.688417598, 61.549408598],
          ],
        ],
      },
      properties: {
        Unit_Number: "V225",
      },
    },
    {
      type: "Feature",
      id: "sm58aac089",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688144643, 61.562741514],
            [18.688234567, 61.556349737],
            [18.710600216, 61.556421111],
            [18.710510292, 61.562812873],
            [18.688144643, 61.562741514],
          ],
        ],
      },
      properties: {
        Unit_Number: "V224",
      },
    },
    {
      type: "Feature",
      id: "sm4c36ca02",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688234567, 61.556349737],
            [18.688144643, 61.562741514],
            [18.666477308, 61.562672382],
            [18.66672212, 61.556281085],
            [18.688234567, 61.556349737],
          ],
        ],
      },
      properties: {
        Unit_Number: "V223",
      },
    },
    {
      type: "Feature",
      id: "sm74e6b969",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688268864, 61.56274191],
            [18.688175867, 61.569350711],
            [18.666224131, 61.569280686],
            [18.666477308, 61.562672382],
            [18.688268864, 61.56274191],
          ],
        ],
      },
      properties: {
        Unit_Number: "V221",
      },
    },
    {
      type: "Feature",
      id: "sm7ee737e4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688175867, 61.569350711],
            [18.688268864, 61.56274191],
            [18.710510292, 61.562812873],
            [18.710417295, 61.569421659],
            [18.688175867, 61.569350711],
          ],
        ],
      },
      properties: {
        Unit_Number: "V222",
      },
    },
    {
      type: "Feature",
      id: "sm9590dbdb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688340495, 61.575570085],
            [18.688428019, 61.569351515],
            [18.710417295, 61.569421659],
            [18.710329771, 61.575640215],
            [18.688340495, 61.575570085],
          ],
        ],
      },
      properties: {
        Unit_Number: "V220",
      },
    },
    {
      type: "Feature",
      id: "sm422680d6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688428019, 61.569351515],
            [18.688340495, 61.575570085],
            [18.665985854, 61.57549879],
            [18.666224131, 61.569280686],
            [18.688428019, 61.569351515],
          ],
        ],
      },
      properties: {
        Unit_Number: "V219",
      },
    },
    {
      type: "Feature",
      id: "sm0bde7186",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687968357, 61.582110538],
            [18.688060443, 61.575569192],
            [18.710329771, 61.575640215],
            [18.710237685, 61.582181546],
            [18.687968357, 61.582110538],
          ],
        ],
      },
      properties: {
        Unit_Number: "V218",
      },
    },
    {
      type: "Feature",
      id: "sma1341fca",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.688060443, 61.575569192],
            [18.687968357, 61.582110538],
            [18.665735157, 61.582039645],
            [18.665985854, 61.57549879],
            [18.688060443, 61.575569192],
          ],
        ],
      },
      properties: {
        Unit_Number: "V217",
      },
    },
    {
      type: "Feature",
      id: "sm4ce3f508",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687633054, 61.588565863],
            [18.687723959, 61.582109759],
            [18.710237685, 61.582181546],
            [18.71014678, 61.588637636],
            [18.687633054, 61.588565863],
          ],
        ],
      },
      properties: {
        Unit_Number: "V216",
      },
    },
    {
      type: "Feature",
      id: "sm44b413d5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687723959, 61.582109759],
            [18.687633054, 61.588565863],
            [18.665487675, 61.588495265],
            [18.665735157, 61.582039645],
            [18.687723959, 61.582109759],
          ],
        ],
      },
      properties: {
        Unit_Number: "V215",
      },
    },
    {
      type: "Feature",
      id: "smb8b68552",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.6878001, 61.595182022],
            [18.687893266, 61.588566693],
            [18.71014678, 61.588637636],
            [18.710078782, 61.593465993],
            [18.707160538, 61.595017562],
            [18.6878001, 61.595182022],
          ],
        ],
      },
      properties: {
        Unit_Number: "V214",
      },
    },
    {
      type: "Feature",
      id: "sm3c6c32f4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.687893266, 61.588566693],
            [18.6878001, 61.595182022],
            [18.66870839, 61.595344198],
            [18.665275162, 61.594037633],
            [18.665487675, 61.588495265],
            [18.687893266, 61.588566693],
          ],
        ],
      },
      properties: {
        Unit_Number: "V213",
      },
    },
  ],
};

export default geojsonData;
