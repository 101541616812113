import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";

const CreateLabour = () => {
  const navigate = useNavigate();

  const labourTypeOptions = [
    { value: "Supervisor", label: "Supervisor" },
    { value: "Skilled", label: "Skilled" },
    { value: "Unskilled", label: "Unskilled" },
  ];
  const wageTypeOptions = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Contract", label: "Contract" },
  ];

  const initialValues = {
    rate_per_day: 0,
    wage_type: "",
    labour_type: "",
  };

  const validationSchema = Yup.object({
    rate_per_day: Yup.number().required("Rate is required"),
    wage_type: Yup.string().required("Wage type is required"),
    labour_type: Yup.string()
      .oneOf(labourTypeOptions.map((option) => option.value))
      .required("Labour type is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        "https://workspace.optiven.co.ke/api/labour",
        {
          rate_per_day: values.rate_per_day,
          wage_type: values.wage_type,
          labour_type: values.labour_type,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }
      );
      console.log(response.data);
      toast.success("Labour created successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // redirect to home page
      navigate("/view-labour");
    } catch (error) {
      console.error(error.response.data);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setSubmitting(false);
  };
  return (
    <>
      <Sidebar>
        <div className="hero min-h-screen">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
          
            {(formik) => (
              <Form className="form-control w-full max-w-xs">
               <h1 className="font-bold flex justify-center mb-4 text-2xl">Labour</h1>
                <label htmlFor="labour_type" className="label">
                  <span className="label-text font-bold">Labour Type</span>
                </label>
                <Field
                  as="select"
                  id="labour_type"
                  name="labour_type"
                  className="select select-bordered"
                >
                  <option value="">Select a Labour Type</option>
                  {labourTypeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="labour_type"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="wage_type" className="label">
                  <span className="label-text font-bold">Wage Type</span>
                </label>
                <Field
                  as ="select"
                  id="wage_type"
                  name="wage_type"
                  placeholder="Daily"
                  className="select select-bordered"
                >
                  <option value=""> Select Wage Type</option>
                  {wageTypeOptions.map((options) => (
                    <option key={options.value} value={options.value}>
                      {options.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="wage_type"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="rate_per_day" className="label">
                  <span className="label-text font-bold">Rate Per Day</span>
                </label>
                <Field
                  type="text"
                  id="rate_per_day"
                  name="rate_per_day"
                  placeholder="100"
                  className="input input-bordered w-full max-w-xs"
                />
                <ErrorMessage
                  name="rate_per_day"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <button
                  type="submit"
                  id="submit"
                  className="btn btn-primary w-full max-w-xs mt-4 text-white"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Submitting..." : "Create Labour"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateLabour;
