import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-search/dist/leaflet-search.min.css";
import "leaflet-search";
import pin from "../OG/images/pin.png";

const MapComponent = ({ geojsonData }) => {
  const mapRef = useRef(null);
  const [plotsUnitsData, setPlotsUnitsData] = useState([]);
  const searchControlRef = useRef(null);
  const [setSearchResults] = useState([]);
  const selectedPlotLayerRef = useRef(null);

  const dataFetched = plotsUnitsData.length > 0;
  // Define a custom golden pin icon
  const goldenPinIcon = L.icon({
    iconUrl: pin,
    iconSize: [100, 80], // Adjust the size of the golden pin
    iconAnchor: [16, 48], // Adjust the anchor point
  });
  useEffect(() => {
    let geoJsonLayer;

    if (!mapRef.current && geojsonData.features.length > 0) {
      const firstFeature = geojsonData.features[0];
      const coordinates = firstFeature.geometry.coordinates[0];

      if (coordinates.length >= 3) {
        const centerLng =
          coordinates.reduce((sum, coord) => sum + coord[0], 0) /
          coordinates.length;
        const centerLat =
          coordinates.reduce((sum, coord) => sum + coord[1], 0) /
          coordinates.length;

        mapRef.current = L.map("map").setView([centerLat, centerLng], 15);

        L.tileLayer(
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzklEQVRIDb3BAQEAAAABIP5mz6iDBTAYzFRMXAuN7ABTGYMKEzzLMvBDFRgwoTPsDAUxiwwTM82zIAxUYMKRM+wMBTGLDNEzPMDEUYMKRM+wMBTGJ6m8F9B5K3RgEoYMKRM+wMBTGYsM0TMsxMVGAwTKhM+wMBTGJ6SBDHAk4MwBaxjAzRM9bAtwRWWoMKEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwF9AAAAABJRU5ErkJggg=="
        ).addTo(mapRef.current);
        //addImageOverlays();
      } else {
        console.error("Invalid coordinates.");
      }
    }

    if (!geoJsonLayer) {
      geoJsonLayer = L.geoJSON(geojsonData, {
        style: styleFunction,
        onEachFeature: onEachFeature,
      });
      geoJsonLayer.addTo(mapRef.current);
    }

    if (!dataFetched) {
      fetch("https://workspace.optiven.co.ke/api/plots")
        .then((response) => response.json())
        .then((data) => {
          setPlotsUnitsData(data);
        })
        .catch((error) => {
          console.error("Error fetching plot units data:", error);
        });
    }

    // Add the Leaflet-Search control to the map with styling and animations
    if (mapRef.current && !searchControlRef.current) {
      searchControlRef.current = new L.Control.Search({
        layer: geoJsonLayer,
        propertyName: "Unit_Number",
        marker: false,
        autoCollapse: true,
        position: "topright",
        initial: false,
        style:
          "background-color: #f0feff; border: 2px solid #0078d4; border-radius: 4px; padding: 5px;",
        textPlaceholder: "Enter Plot Number...", // Placeholder text
        textErr: "Plot not found!", // Error message
        textCancel: "Cancel", // Cancel button text
        searchMove: false, // Disable automatic map movement
      });

      // Customize the zoom level and animation duration
      searchControlRef.current._moveToLocation = function (latlng, title, map) {
        const options = {
          animate: true,
          duration: 0.5, // Adjust animation duration (in seconds)
        };

        map.setView(latlng, map.getZoom(), options);
      };
      // Customize the search function to handle numbers without prefixes
      searchControlRef.current._searchText = (text) => {
        if (!isNaN(text)) {
          text = "og" + text;
        }
        L.Control.Search.prototype._searchText.call(
          searchControlRef.current,
          text
        );
      };

      // Handle search result selection
      searchControlRef.current.on("search:locationfound", (e) => {
        if (selectedPlotLayerRef.current) {
          mapRef.current.removeLayer(selectedPlotLayerRef.current);
        }

        const selectedPlot = e.layer;
        selectedPlotLayerRef.current = L.marker(
          selectedPlot.getBounds().getCenter(),
          { icon: goldenPinIcon }
        ).addTo(mapRef.current);

        mapRef.current.fitBounds(e.layer.getBounds());

        setTimeout(() => {
          if (selectedPlotLayerRef.current) {
            mapRef.current.removeLayer(selectedPlotLayerRef.current);
          }
        }, 3000);
      });

      searchControlRef.current.on("search:searchcomplete", (e) => {
        const sortedResults = e.target._recordsList.slice().sort((a, b) => {
          return a.elem.properties.Unit_Number.localeCompare(
            b.elem.properties.Unit_Number
          );
        });
        setSearchResults(sortedResults);
      });

      searchControlRef.current.addTo(mapRef.current);

      const searchInput = document.querySelector(".search-input");
      searchInput.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          searchControlRef.current._searchText(searchInput.value);
        }
      });
    }
  }, [geojsonData.features.length, plotsUnitsData, dataFetched]);

  // Define a function to style the GeoJSON features based on Unit_Status
  const styleFunction = (feature) => {
    const Unit_Number = feature.properties.Unit_Number;
    let fillColor = "#f0fdff";

    if (plotsUnitsData) {
      const plotsInfo = plotsUnitsData.find(
        (plots) => plots.Unit_Number === Unit_Number
      );

      if (plotsInfo) {
        const Unit_Status = plotsInfo.Unit_Status;

        if (Unit_Status === "Open") {
          fillColor = "#49e183";
        } else if (Unit_Status === "Reserved") {
          fillColor = "#ffd700";
        } else if (Unit_Status === "Sold") {
          fillColor = "#db1515";
        }
      }
    }

    return {
      fillColor: fillColor,
      fillOpacity: 0.9,
      color: "black",
      weight: 2,
    };
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const onEachFeature = (feature, layer) => {
    let popupContent = "<b>Plot Information</b><br />";

    if (feature.properties.project) {
      popupContent += `Plots Name: ${feature.properties.project}<br />`;
    }

    const Unit_Number = feature.properties.Unit_Number;

    if (plotsUnitsData) {
      const plotsInfo = plotsUnitsData.find(
        (plots) => plots.Unit_Number === Unit_Number
      );

      if (plotsInfo) {
        const formattedPrice = `Ksh. ${formatPriceWithCommas(
          plotsInfo.Cash_Price
        )}`;

        const plotIdLabel = L.divIcon({
          className: "plot-id-label",
          html: `<strong><span style="font-size: 8px; font-weight: bold;">${plotsInfo.Unit_Number}</span></strong>`,
          iconSize: [20, 10],
        });

        L.marker(layer.getBounds().getCenter(), { icon: plotIdLabel }).addTo(
          mapRef.current
        );

        if (isMobile) {
          // For mobile, open the tooltip on click
          layer.on("click", function (e) {
            this.openTooltip();
          });
        } else {
          // For desktop, open the tooltip on hover
          layer.on("mouseover", function (e) {
            this.openTooltip();
          });

          layer.on("mouseout", function (e) {
            this.closeTooltip();
          });
        }

        layer.bindTooltip(
          `
        <b>Plots Information</b><br />
        <b>Project Name:</b> ${plotsInfo.Name}<br />
        <b>Status:</b> ${plotsInfo.Unit_Status}<br />
        <b>Price:</b> ${formattedPrice}<br />
        <b>Plot Size:</b> ${plotsInfo.Unit_Type} HA<br />
        
      `,
          {
            className: "custom-tooltip",
          }
        );
      }
    }
  };

  

  const formatPriceWithCommas = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div id="map" style={{ height: "450px" }}>
      {/* Custom CSS for styling the tooltip */}
      <style>
        {`
          /* Apply the custom font to the tooltip */
          .leaflet-tooltip {
            font-family: 'Lausanne-Regular', sans-serif;
            background-color: #86bc39; /* Tooltip background color */
            color: black; /* Default text color */
          }

          /* Adjust text color for specific status background colors */
          .leaflet-tooltip.leaflet-tooltip-reserved {
            color: black; /* Text color for Reserved status */
          }
        `}
      </style>
    </div>
  );
};

export default MapComponent;
