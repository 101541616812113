import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { CloudLightning } from "react-feather";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar";
import { AlertOctagon } from "react-feather";

const ClientDetails = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [downloaded, setDownloaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showContinueConstructionModal, setShowContinueConstructionModal] =
    useState(false);
  const [actual_cost, setActualCost] = useState("");

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/homes-clients/${clientId}`
        );
        setClient(response.data);
        setTimeout(() => setIsLoading(false), 3000);
      } catch (error) {
        console.error("Failed to fetch client details", error);
        setIsLoading(false);
      }
    };

    fetchClientDetails();
  }, [clientId]);

  const downloadContract = async () => {
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/homes-clients/${clientId}/contract`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Contract_${clientId}.pdf`);
      document.body.appendChild(link);
      link.click();
      setDownloaded(true);
    } catch (error) {
      console.error("Failed to download contract", error);
    }
  };

  const handleContinueConstruction = () => {
    setShowContinueConstructionModal(true);
  };
  const confirmContinueConstruction = async () => {
    try {
      const response = await axios.patch(
        `https://workspace.optiven.co.ke/api/homes-clients/${clientId}/change-status/construction`
      );
      toast.success("Construction phase initiated!");
      navigate(`/const-phase/${clientId}`);
    } catch (error) {
      console.error("Failed to initiate construction phase", error);
      toast.error("Failed to initiate construction phase.");
    } finally {
      setShowContinueConstructionModal(false);
    }
  };

  const handleActualCostChange = (event) => {
    setActualCost(event.target.value);
  };

  const handleTerminateContract = () => {
    setShowConfirmationModal(true);
  };

  const confirmTerminateContract = () => {
    axios
      .patch(
        `https://workspace.optiven.co.ke/api/homes-clients/${clientId}/change-status/terminated`
      )
      .then(() => {
        toast.success("Contract terminated successfully!");
        navigate(`/client/${clientId}`, { replace: true });
        window.setTimeout(() => {
          window.location.reload();
        }, 500); // waits 500ms before reloading the page
      })
      .catch((error) => {
        console.error("Failed to terminate contract", error);
        toast.error("Failed to terminate contract.");
      })
      .finally(() => {
        setShowConfirmationModal(false);
      });
  };

  const handleActualCost = () => {
    axios
      .patch(
        `https://workspace.optiven.co.ke/api/homes-clients/${clientId}/actual_cost`,
        {
          actual_cost: actual_cost,
        }
      )
      .then(() => {
        toast.success("Actual Cost Updated Successfully!");
        window.location.reload();
        console.log(actual_cost);
      })
      .catch((error) => {
        console.error("Failed to update actual cost", error);
        toast.error("Failed to update the actual cost");
      });
  };

  const BarLoader = () => {
    const variants = {
      initial: {
        scaleY: 0.5,
        opacity: 0,
      },
      animate: {
        scaleY: 1,
        opacity: 1,
        transition: {
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1,
          ease: "circIn",
        },
      },
    };

    return (
      <motion.div
        transition={{ staggerChildren: 0.25 }}
        initial="initial"
        animate="animate"
        className="flex gap-1"
      >
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
      </motion.div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <BarLoader />
        <h1>Loading Client Details</h1>
      </div>
    );
  }

  const getTypeBadge = (type) => {
    const typeLower = type.toLowerCase();
    switch (typeLower) {
      case "mansion":
        return "badge badge-success badge-outline";
      case "bungalow":
        return "badge badge-warning badge-outline";
      case "custom":
        return "badge badge-info badge-outline";
      default:
        return "badge badge-outline";
    }
  };

  // calculate variance
  const getVarianceBadge = (variance) => {
    return variance >= 0 ? "badge badge-success" : "badge badge-error";
  };

  // Check if client status is terminated
  const isTerminated =
    client && client.length > 0 && client[0].status === "terminated";
  return (
    <Sidebar>
      <div className="flex justify-center items-center h-screen bg-slate-950">
        <ToastContainer />
        <div className="group relative w-full max-w-4xl mx-auto overflow-hidden rounded-lg bg-slate-800 p-0.5 transition-all duration-500 hover:scale-[1.01] hover:bg-slate-800/50">
          <div className="relative z-10 flex flex-col overflow-hidden rounded-[7px] bg-slate-900 p-8 transition-colors duration-500 group-hover:bg-slate-800 ">
            <div className="flex items-center justify-center">
              <CloudLightning className="text-lg" />
            </div>
            <div className="flex items-center justify-center">
              <h1 className="mb-4 text-3xl font-bold text-slate-50">
                Client Details
              </h1>
            </div>
            {client.map((client, index) => (
              <div key={index} className="text-slate-400">
                <div className="grid grid-cols-1 sm:grid-cols-3 mb-2 ">
                  <p>
                    <strong>Name:</strong> {client.client_name}
                  </p>
                  <p>
                    <strong>ID Number:</strong> {client.id_no}
                  </p>
                  <p>
                    <strong>Email:</strong> {client.email}
                  </p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 ">
                  <p>
                    <strong>Land Registration No:</strong> {client.LR_no}
                  </p>
                  <p>
                    <strong>Plot Size:</strong> {client.plot_size}HA
                  </p>
                  <div className="mt-1">
                    <strong>Type:</strong>
                    <p
                      className={`text-sm font-light ${getTypeBadge(
                        client.type
                      )}`}
                    >
                      {client.type}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-3 mt-2">
                  <p className=" ">
                  <strong>Cost:</strong> Ksh. {client.cost.toLocaleString()}
                  </p>
                  <div>
                    <strong>Actual Cost:</strong>
                    {client.actual_cost === null ? (
                      <input
                        type="text"
                        id="actual_cost"
                        name="actual_cost"
                        value={client.actual_cost}
                        onChange={handleActualCostChange}
                        className="w-1/2 rounded-lg ml-2 px-2 font-bold text-black"
                      />
                    ) : (
                      <strong>Ksh. {client.actual_cost.toLocaleString()}</strong>
                    )}
                  </div>
                  <div>
                    {client.actual_cost === null ? (
                      <button
                        className="mb-4 bg-blue-500 text-white px-2 rounded"
                        onClick={() => handleActualCost(clientId)}
                      >
                        Update Cost
                      </button>
                    ) : (
                      <div>
                        <strong>
                          Variance:
                          <span
                            className={`ml-2 text-sm font-light ${getVarianceBadge(
                              client.cost - client.actual_cost
                            )}`}
                          >
                            {client.cost - client.actual_cost}
                          </span>
                        </strong>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {!isTerminated && (
              <div>
                <div className="flex justify-center mt-3 ">
                  <button
                    className="mb-4 bg-blue-500 text-white py-2 px-4 rounded w-1/2 sm:w-1/4"
                    onClick={downloadContract}
                  >
                    Download Contract
                  </button>
                </div>
              </div>
            )}
            {!isTerminated && client.every((c) => c.actual_cost !== null) && (
              <div className="mt-4 grid grid-cols-2 w-full gap-8">
                <button
                  className="bg-green-500 text-white py-2 px-4 rounded"
                  onClick={handleContinueConstruction}
                >
                  Continue with Construction
                </button>
                <button
                  className="bg-red-500 text-white py-2 px-4 rounded"
                  onClick={handleTerminateContract}
                >
                  Terminate Contract
                </button>
              </div>
            )}
            {isTerminated && (
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-10">
                <div className="text-red-500 text-4xl font-bold text-center transform -rotate-45">
                  <div>Contract</div>
                  <div>Terminated</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <div className="flex items-center justify-center text-red-500">
              <AlertOctagon className="text-lg" />
            </div>
            <h2 className="text-lg font-semibold mb-4 text-center text-red-500">
              {" "}
              Terminate Contract
            </h2>
            <p className="text-sm mb-4">
              Are you sure you want to terminate the contract?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white py-2 px-4 rounded"
                onClick={confirmTerminateContract}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {showContinueConstructionModal && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <div className="flex items-center justify-center text-green-500">
              <AlertOctagon className="text-lg" />
            </div>
            <h2 className="text-lg font-semibold mb-4 text-center text-green-500">
              Continue Construction
            </h2>
            <p className="text-sm mb-4">
              Are you sure you want to continue with the construction phase?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                onClick={() => setShowContinueConstructionModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white py-2 px-4 rounded"
                onClick={confirmContinueConstruction}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default ClientDetails;
