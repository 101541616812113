import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../components/sidebar/Sidebar";

function GMCConferenceRooms() {
  const [rooms, setRooms] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.user.user_id);
  const { date, numberOfPeople, purpose } = location.state || {};

  useEffect(() => {
    if (!date || !numberOfPeople || !purpose) {
      navigate("/");
      return;
    }

    axios
      .get("http://localhost:8080/api/gmc-rooms") // Changed endpoint
      .then((response) => {
        const filteredRooms = response.data.filter(
          (room) => room.capacity >= numberOfPeople
        );
        setRooms(filteredRooms);
      })
      .catch((error) => {
        console.error("There was an error fetching the rooms!", error);
      });
  }, [date, numberOfPeople, purpose, navigate]);

  const handleRoomClick = (room) => {
    navigate(`/gmc-rooms/${room.id}/booking`, {
      state: { room, date, numberOfPeople, purpose, user_id: userId },
    });
  };

  return (
    <Sidebar>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
        {rooms.map((room) => (
          <div
            key={room.id}
            className="group relative block bg-black cursor-pointer"
            onClick={() => handleRoomClick(room)}
          >
            <img
              alt={room.name}
              src="https://images.unsplash.com/photo-1517048676732-d65bc937f952?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
            />
            <div className="relative p-4 sm:p-6 lg:p-8">
              <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
                {room.capacity} People
              </p>
              <p className="text-xl font-bold text-white sm:text-2xl">
                {room.name}
              </p>
              <div className="mt-32 sm:mt-48 lg:mt-64">
                <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                  <p className="text-sm text-white">{room.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Sidebar>
  );
}

export default GMCConferenceRooms;
