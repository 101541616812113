import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ArrowUpRight, User } from "react-feather";
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import noClientsImage from '../../../assets/app-illustrations/Shrug-pana.png';

const ConstClients = () => {
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 8;

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("https://workspace.optiven.co.ke/api/homes-clients");
        const constructionClients = response.data.filter(client => client.status.toLowerCase() === "construction");
        setClients(constructionClients);
      } catch (error) {
        console.error("Failed to fetch construction clients", error);
      }
    };

    fetchClients();
  }, []);

  const getTypeBadge = (type) => {
    const typeLower = type.toLowerCase();
    switch (typeLower) {
      case "mansion":
        return "badge badge-success badge-outline";
      case "bungalow":
        return "badge badge-warning badge-outline";
      case "custom":
        return "badge badge-info badge-outline";
      default:
        return "badge badge-outline";
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredClients = clients.filter((client) => {
    const clientName = client.client_name ? client.client_name.toLowerCase() : "";
    const clientId = client.id_no ? client.id_no.toString().toLowerCase() : "";
    const clientType = client.type ? client.type.toLowerCase() : "";

    return (
      clientName.includes(searchTerm.toLowerCase()) ||
      clientId.includes(searchTerm.toLowerCase()) ||
      clientType.includes(searchTerm.toLowerCase())
    );
  });

  // Pagination logic
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
  const totalPages = Math.ceil(filteredClients.length / clientsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Sidebar>
      <section className="p-8">
        <div className="mb-4 sm:text-center sm:text-4xl text-2xl font-bold text-gray-700">
          Construction Clients
        </div>
        <div className="mb-8 flex justify-center items-center">
          <input
            type="text"
            placeholder="Search by name, ID number, or type"
            className="border border-gray-300 rounded-md px-3 py-2 mr-4 sm:w-72 w-60"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        {currentClients.length === 0 ? (
          <div className="text-center">
            <img src={noClientsImage} alt="No Construction Clients" className="mx-auto" style={{ maxWidth: '600px', height: 'auto' }} />
            <p>No construction clients found.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {currentClients.map((client) => (
              <Link to={`/const-phase/${client.id}`} key={client.id} className="block">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="w-full h-[250px] relative overflow-hidden rounded-lg shadow-lg transition-transform duration-200 ease-in-out"
                >
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center p-4">
                    <div className="absolute top-10 left-1/2 transform -translate-x-1/2">
                      <User className="text-white" size={24} />
                    </div>
                    <h3 className="text-lg mb-2 font-semibold text-white">
                      {client.client_name}
                    </h3>
                    <p className="text-sm font-semibold text-gray-300">
                      <strong>ID:</strong> {client.id_no}
                    </p>
                    <p
                      className={`text-xs font-light ${getTypeBadge(
                        client.type
                      )}`}
                    >
                      {client.type}
                    </p>
                  </div>
                  <Link
                    to={`/client/${client.id}`}
                    className="absolute bottom-0 right-0 p-4 text-white hover:text-blue-500 transition-colors"
                  >
                    <ArrowUpRight className="text-lg" />
                  </Link>
                </motion.div>
              </Link>
            ))}
          </div>
        )}
        {filteredClients.length > clientsPerPage && (
          <div className="flex justify-center mt-8">
            <nav>
              <ul className="inline-flex -space-x-px">
                {[...Array(totalPages)].map((_, i) => (
                  <li key={i}>
                    <button
                      onClick={() => paginate(i + 1)}
                      className={`px-3 py-2 leading-tight ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700'}`}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        )}
      </section>
    </Sidebar>
  );
};

export default ConstClients;
