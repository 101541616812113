import React, { useState, useCallback, useEffect, useMemo } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../../foundation/components/Sidebar";
import { useSelector } from "react-redux";

const Health = () => {
  const [healthName, setHealthName] = useState("");
  const [healthAge, setHealthAge] = useState("");
  const [healthGender, setHealthGender] = useState("");
  const [healthContact, setHealthContact] = useState("");
  const [healthComplication, setHealthComplication] = useState("");
  const [healthAmount, setHealthAmount] = useState("");
  const [healthInitiative, setHealthInitiative] = useState("");
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payConfirmation, setPayConfirmation] = useState(0);
  const [projectCase, setProjectCase] = useState(0);
  const [initiatives, setInitiatives] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery4, setSearchQuery4] = useState("");

  const [loading, setLoading] = useState(false);
  const [health, setHealth] = useState([]);
  const token = useSelector((state) => state.user.token);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const health = {
      health_name: healthName,
      health_age: healthAge,
      health_gender: healthGender,
      health_contact: healthContact,
      health_complication: healthComplication,
      health_amount: healthAmount,
      pay_confirmation: payConfirmation,
      project_case: projectCase,
      initiative_pillar: healthInitiative,
    };

    try {
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/health",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(health),
        }
      );

      if (!response.ok) {
        toast.error("Error adding Health Project ", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // Display success notification
      else {
        toast.success("Health Project added successfully!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setLoading(false);
      closeModal();

      setHealthName("");
      setHealthAge("");
      setHealthGender("");
      setHealthContact("");
      setHealthComplication("");
      setHealthAmount("");
      setPayConfirmation("");
      setProjectCase("");
      setHealthInitiative("");
    } catch (error) {
      // Display error notification
      toast.error(error, {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchHealth = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/health",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setHealth(data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchEvents = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/events/pillar-count?pillar=Health",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setEvents(data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchInitiatives = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/initiatives/health",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setInitiatives(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchHealth();
    fetchEvents();
    fetchInitiatives();
  }, []);

  const filteredHealth = useMemo(() => {
    return health.filter((item) => {
      if (searchQuery === "") {
        return true; // Include all items when the search query is empty
      } else if (
        item.health_name &&
        item.health_name.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        return true; // Include the item if it matches the search query
      } else {
        return false; // Exclude the item if it doesn't match the search query
      }
    });
  }, [searchQuery, health]);

  const filteredInitiatives = useMemo(() => {
    return initiatives.filter((item) => {
      if (searchQuery4 === "") {
        return true; // Include all items when the search query is empty
      } else if (
        item.initiative_name &&
        item.initiative_name.toLowerCase().includes(searchQuery4.toLowerCase())
      ) {
        return true; // Include the item if it matches the search query
      } else {
        return false; // Exclude the item if it doesn't match the search query
      }
    });
  }, [searchQuery4, initiatives]);

  // Calculate the total sum of educ_amount values
  const totalAmount = health.reduce((sum, item) => {
    // Parse the educ_amount string to a number and add it to the sum
    return sum + item.health_amount;
  }, 0);
  return (
    <Sidebar>
      <section className="text-center overflow-x-hidden">
        <div className="container mx-auto text-center mt-4">
          <section className="text-gray-600 body-font">
            <div className="sm:flex sm:items-center sm:justify-between mx-8">
              <div>
                <div className="flex items-center gap-x-3">
                  <h2 className="text-lg font-medium text-gray-800 dark:text-white">
                    Health
                  </h2>
                  <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">
                    Welcome 😊
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 text-start">
                  These are all the Health Projects that have been Registered
                  under this Pillar.<br></br>
                </p>
              </div>
              <div className="flex items-center mt-4 gap-x-3">
                <button
                  className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-primary rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                  onClick={() => setIsModalOpen(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>Add a Project</span>
                </button>
                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  className="modal-box container mx-auto"
                >
                  {" "}
                  <button
                    onClick={closeModal}
                    className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                  >
                    ✕
                  </button>
                  {/* Add your form fields or other content here */}
                  <div>
                    <form onSubmit={handleSubmit}>
                      <label className="label font-bold text-xs">
                        Add a Health Project
                      </label>
                      <label className="label font-bold text-xs">
                        Name of the one assisted by Health Project
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="healthName"
                        value={healthName}
                        onChange={(e) => setHealthName(e.target.value)}
                        spellCheck
                        required
                      />
                      <label className="label font-bold text-xs">
                        Age of the assisted
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="healthAge"
                        value={healthAge}
                        onChange={(e) => setHealthAge(e.target.value)}
                        spellCheck
                        required
                      />
                      <label className="label font-bold text-xs">
                        Gender of the assisted
                      </label>
                      <select
                        className="input input-bordered w-full"
                        name="healthGender"
                        value={healthGender}
                        onChange={(e) => setHealthGender(e.target.value)}
                        required
                      >
                        <option value="None Selected">
                          Please select Gender
                        </option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                      </select>
                      <label className="label font-bold text-xs">
                        Phone Contact of the assisted or Guardian
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="healthContact"
                        value={healthContact}
                        onChange={(e) => setHealthContact(e.target.value)}
                        spellCheck
                        required
                      />
                      <label className="label font-bold text-xs">
                        Initiative Under
                      </label>
                      <select
                        className="input input-bordered w-full"
                        name="healthInitiative"
                        value={healthInitiative}
                        onChange={(e) => setHealthInitiative(e.target.value)}
                        required
                      >
                        <option value="NONE">
                          Please select an Initiative
                        </option>
                        <option value="Patnerships">Patnerships</option>
                        {filteredInitiatives.map((initiatives) => (
                          <option
                            key={initiatives.initiative_id}
                            value={initiatives.initiative_name}
                          >
                            {initiatives.initiative_name}
                          </option>
                        ))}
                      </select>
                      <label className="label font-bold text-xs">
                        Health Complication of the assisted
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="healthComplication"
                        value={healthComplication}
                        onChange={(e) => setHealthComplication(e.target.value)}
                        type="text"
                        spellCheck
                        required
                      />
                      <label className="label font-bold text-xs">
                        Health History of the Patient (else NA)
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="healthComplication"
                        value={projectCase}
                        onChange={(e) => setProjectCase(e.target.value)}
                        spellCheck
                        required
                        type="text"
                      />
                      <label className="label font-bold text-xs">Amount</label>
                      <input
                        className="input input-bordered w-full"
                        name="healthAmount"
                        value={healthAmount}
                        onChange={(e) => setHealthAmount(e.target.value)}
                        spellCheck
                        required
                        type="number"
                      />{" "}
                      <label className="label font-bold text-xs">
                        Confirmation of Pay
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="payConfirmation"
                        value={payConfirmation}
                        onChange={(e) => setPayConfirmation(e.target.value)}
                        spellCheck
                        required
                        type="text"
                      />
                      <button
                        type="submit"
                        className="btn btn-outline my-4 w-full bg-green"
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button>
                    </form>{" "}
                  </div>
                </Modal>
              </div>
            </div>
            {/*BOXES */}
            <div className="container px-0 py-4 mx-auto">
              <div className="flex flex-wrap m-4 text-center">
                <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
                  <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      className="text-indigo-500 w-12 h-12 mb-3 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                      <circle cx={9} cy={7} r={4} />
                      <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75" />
                    </svg>
                    <h2 className="title-font font-medium text-3xl text-gray-900">
                      {health.length}
                    </h2>
                    <p className="leading-relaxed">Outreached</p>
                  </div>
                </div>
                <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
                  <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      className="text-indigo-500 w-12 h-12 mb-3 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 18v-6a9 9 0 0118 0v6" />
                      <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z" />
                    </svg>
                    <h2 className="title-font font-medium text-3xl text-gray-900">
                      {events.length}
                    </h2>
                    <p className="leading-relaxed">Events</p>
                  </div>
                </div>
                <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
                  <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      className="text-indigo-500 w-12 h-12 mb-3 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
                    </svg>
                    <h2 className="title-font font-medium text-3xl text-gray-900">
                      {totalAmount.toLocaleString()}
                    </h2>
                    <p className="leading-relaxed">Total Money used</p>
                  </div>
                </div>
              </div>
              <div className="mt-6 md:flex md:items-center md:justify-between">
                <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg dark:bg-gray-900 rtl:flex-row-reverse dark:border-gray-700 dark:divide-gray-700"></div>
                <div className="relative flex items-center mt-4 md:mt-0">
                  <input
                    type="text"
                    className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Search Health Project by name..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                          <tr>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Index
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Initiative Under
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              <div className="text-center">
                                <div className="flex items-center gap-x-3 focus:outline-none">
                                  <span>Name of the one assisted</span>
                                  <svg
                                    className="h-3"
                                    viewBox="0 0 10 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.1"
                                    />
                                    <path
                                      d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.1"
                                    />
                                    <path
                                      d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.3"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Gender
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Contact
                            </th>{" "}
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Complication
                            </th>{" "}
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Case History
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Amount Disbursed
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Confirmation of Pay
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {filteredHealth.map((health, index) => (
                            <tr key={index}>
                              <td className="border border-secondary-content px-2 py-2 font-bold">
                                {index + 1}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {health.initiative_pillar}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-start">
                                <div className="flex flex-col justify-center items-start">
                                  <div>{health.health_name}</div>
                                  <div className="px-3 mt-2 py-1 text-sm font-normal rounded-full text-blue-500 gap-x-2 bg-blue-200 dark:bg-gray-800">
                                    {health.health_age} years old
                                  </div>
                                </div>
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {health.health_gender}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {health.health_contact}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {health.health_complication}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {health.project_case}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {health.health_amount}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {health.pay_confirmation}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Sidebar>
  );
};

export default Health;
