import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";

const CreateTask = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { taskTitle } = location.state || {};
  const valueAdditionId = id;
  const token = useSelector((state) => state.user.token);
  const [title, setTitle] = useState("");
  

  // get the name of the task

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/tasks/value-addition-subtasks/${taskTitle}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const tasks = response.data;
        console.log(tasks);
        const taskId = parseInt(taskTitle);
        const task = tasks.find((task) => task.id === taskId);

        if (task) {
          setTitle(task.title.toString());
          console.log(task.title);
        } else {
          console.error("Title not available");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [taskTitle]);
  console.log(title);

  const initialValues = {
    title: "",
    cost: "",
    status: "In Progress",
    startDate: "",
    expectedDateOfCompletion: "",
  };

  const validationSchema = Yup.object({
    cost: Yup.number().min(1, "Cannot be 0 KES").required("Field is required"),
    startDate: Yup.date().required("Start date is required"),
    expectedDateOfCompletion: Yup.date()
      .required("Expected end date is required")
      .min(Yup.ref("startDate"), "Expected end date must be after start date"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch("https://workspace.optiven.co.ke/api/tasks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          title: title || values.title,
          cost: values.cost,
          status: "In Progress",
          startDate: values.startDate,
          expectedDateOfCompletion: values.expectedDateOfCompletion,
          valueAdditionId: valueAdditionId,
        }),
      });
      console.log(response.data);
      toast.success("Task added successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Redirect to home page
      navigate(`/value-additions/${valueAdditionId}`);
    } catch (error) {
      console.error(error.response.data);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setSubmitting(false);
  };

  return (
    <>
      <Sidebar>
        <div className="hero min-h-screen">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form className="form-control w-full max-w-sm mt-16">
                <label htmlFor="title" className="label">
                  <span className="label-text font-bold">Title</span>
                </label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  value={formik.values.title || title}
                  placeholder="Build an embankment"
                  className="input input-bordered w-full max-w-sm"
                />

                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />
                <div className="lg:flex justify-between">
                  <div className="w-44">
                    <label className="label" htmlFor="startDate">
                      <span className="label-text font-bold">Start Date: </span>
                    </label>
                    <Field
                      type="date"
                      className="input input-bordered w-full max-w-sm"
                      id="startDate"
                      name="startDate"
                    />
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="text-red-500 font-bold text-sm mt-2"
                    />
                  </div>
                  <div className="w-44">
                    <label className="label" htmlFor="expectedDateOfCompletion">
                      <span className="label-text font-bold">
                        Expected End Date:{" "}
                      </span>
                    </label>
                    <Field
                      type="date"
                      className="input input-bordered w-full max-w-sm"
                      id="expectedDateOfCompletion"
                      name="expectedDateOfCompletion"
                    />
                    <ErrorMessage
                      name="expectedDateOfCompletion"
                      component="div"
                      className="text-red-500 font-bold text-sm mt-2"
                    />
                  </div>
                </div>
                <label htmlFor="cost" className="label">
                  <span className="label-text font-bold">Cost (KES)</span>
                </label>
                <Field
                  type="text"
                  id="cost"
                  name="cost"
                  placeholder="100000"
                  className="input input-bordered w-full max-w-sm"
                />
                <ErrorMessage
                  name="cost"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <button
                  type="submit"
                  id="submit"
                  className="btn btn-primary w-full max-w-sm mt-4 text-white"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Submitting..." : "Add Task"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateTask;
