import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ children }) => {
  const accessRole = useSelector((state) => state.user.accessRole).trim();

  const department = useSelector((state) => state.user.user.department);

  const accessRoles = accessRole.split("#");

  const isConsult = accessRoles.includes("ui-uxConsult");


  return (
    <>
      <div className="drawer">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content overflow-visible">{children}</div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 bg-base-100 text-base-content">
            {/* Home */}
            <li>
              <Link to="/" className="font-bold my-1">
                Home
              </Link>
            </li>

            {/* Survey */}
            {(isConsult || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Survey</div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/survey"
                  >
                    Start Survey
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/view-survey"
                  >
                    View Responses
                  </Link>
                </div>
              </div>
            )}

            {/* Reports */}
            {(isConsult ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Reports</div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/survey-reports"
                  >
                    Survey Reports
                  </Link>
                </div>
              </div>
            )}

           
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
