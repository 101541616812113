import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function formatDate(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const ViewValueAdditionPayments = () => {
  const [payments, setPayments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const valueAddition = accessRole.includes("valueAddition");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/value_addition_payments/value-addition-payments",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setPayments(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/projects/all",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPayments();
    fetchProjects();
  }, []);

  // map the id from the payment table to the table with projects
  const getProjectName = (projectData) => {
    const project = projects.find(
      (proj) => proj.id === parseInt(projectData, 10)
    );
    return project ? project.project_name : "Unknown Project";
  };

  // download the data by excel
  const handleDownloadExcel = () => {
    // Header row
    const headerRow = [
      "Project",
      "Name",
      "Contact",
      "ID Number",
      "Description",
      "Quantity",
      "Rate per Day",
      "Amount",
      "Date",
    ];

    // Data rows
    const data = payments.map((payment) => [
      getProjectName(payment.project_data),
      payment.name,
      payment.phone,
      payment.id_number,
      payment.work_description,
      payment.work_days,
      payment.rate_per_day,
      payment.total_amount,
      formatDate(payment.date),
    ]);

    // Add the total amount row
    const totalAmountRow = [
      "Total Amount", // Title for the total amount
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      totalAmount, // Actual total amount value
      "",
    ];

    // Combine header row, data rows, and the total amount row
    const completeData = [headerRow, ...data, totalAmountRow];

    const ws = XLSX.utils.aoa_to_sheet(completeData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "FilteredPayments");
    XLSX.writeFile(wb, "filtered_payments.xlsx");
  };
    // handle download Pdf
    const dataToPdfRows = (data) => {
      return data.map((payment) => [
        { text: getProjectName(payment.project_data), style: "tableCell" },
        { text: payment.name, style: "tableCell" },
        { text: payment.phone, style: "tableCell" },
        { text: payment.id_number, style: "tableCell" },
        { text: payment.work_description, style: "tableCell" },
        { text: payment.work_days, style: "tableCell" },
        { text: payment.rate_per_day, style: "tableCell" },
        { text: payment.total_amount, style: "tableCell" },
      ]);
    };
    const handleDownloadPDF = () => {
      const docDefinition = {
        pageSize: "A4",
        pageOrientation: "landscape",
        content: [
          {
            table: {
              headerRows: 1,
              widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
              body: [
                [
                  { text: "Project Name", style: "tableHeader" },
                  { text: " Name", style: "tableHeader" },
                  { text: " Contact", style: "tableHeader" },
                  { text: "Id Number", style: "tableHeader" },
                  { text: "Work Description", style: "tableHeader" },
                  { text: "Number of Work Days", style: "tableHeader" },
                  { text: "Rate per day", style: "tableHeader" },
                  { text: "Total Paid", style: "tableHeader" },
                ],
                ...dataToPdfRows(payments),
                [
                  { text: "Total Payments", style: "tableCell", colSpan: 7 },
                  {},
                  {},
                  {},
                  {},
                  {},
                  {},
                  { text: totalAmount.toFixed(2), style: "tableCell" }, // Display total amount with two decimal places
                ],
              ],
            },
          },
        ],
        styles: {
          tableHeader: {
            bold: true,
            fontSize: 12,
            fillColor: "#CCCCCC",
          },
          tableCell: {
            fontSize: 10,
          },
        },
      };
  
      pdfMake.createPdf(docDefinition).open(); // Open PDF in a new tab
    };
  // use the reduce method to calculate the total
  useEffect(() => {
    const total = payments.reduce(
      (sum, payment) => sum + payment.total_amount,
      0
    );
    setTotalAmount(total);
  }, [payments]);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = payments.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Sidebar>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <h2 className="text-2xl font-bold mb-4 font-serif">
            Employee Payment
          </h2>
          <div className="button-gap">
            <Link to="/value-addition-filter-payments">
              <button className="btn btn-active mr-4 font-serif">
                Filter Payments
              </button>
            </Link>

            {!operations &&
            (valueAddition || valueAdmin || department === "ICT SYSTEMS") ? (
              <button
                onClick={handleDownloadExcel}
                className="bg-green-500 text-white py-3 px-4 rounded mb-4 mr-4"
              >
                Export to Excel
              </button>
            ) : null}

            <button
              onClick={handleDownloadPDF}
              className="bg-red-500 text-white py-3 px-4 rounded mb-4"
            >
              Download Pdf
            </button>
          </div>

          <table className="table-auto w-full">
            <thead>
              <tr className="font-serif">
                <th>#</th>
                <th className="px-4 py-2">Project</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Contact</th>
                <th className="px-4 py-2">ID Number</th>
                <th className="px-4 py-2">Description</th>
                <th className="px-4 py-2">Start Date</th>
                <th className="px-4 py-2">End Date</th>
                <th className="px-4 py-2">D.Diff</th>
                <th className="px-4 py-2">Q(workdays)</th>
                <th className="px-4 py-2">Rate per Day</th>
                <th className="px-4 py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((payment, index) => {
                const startDate = new Date(payment.start_date);
                const endDate = new Date(payment.end_date);

                // Convert milliseconds to days
                const dateDifference = endDate.getTime() - startDate.getTime();
                const daysDifference = Math.floor(dateDifference / (1000 * 60 * 60 * 24));
                const daysDifferenceWithOneDayAdded = daysDifference + 1;

                return (
                  <tr key={payment.id}>
                    <td className="border px-4 py-2">{index + 1}</td>
                    <td className="border px-4 py-2">
                      {getProjectName(payment.project_data)}
                    </td>
                    <td className="border px-4 py-2">{payment.name}</td>
                    <td className="border px-4 py-2">{payment.phone}</td>
                    <td className="border px-4 py-2">{payment.id_number}</td>
                    <td className="border px-4 py-2">
                      {payment.work_description}
                    </td>
                    <td className="border px-4 py-2">
                      {formatDate(payment.start_date)}
                    </td>
                    <td className="border px-4 py-2">
                      {formatDate(payment.end_date)}
                    </td>
                    <td className="border px-4 py-2">{daysDifferenceWithOneDayAdded}</td>
                    <td className="border px-4 py-2">{payment.work_days}</td>
                    <td className="border px-4 py-2">{payment.rate_per_day}</td>
                    <td className="border px-4 py-2">{payment.total_amount}</td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan="10" className="border px-4 py-2">
                  {/* Leave this cell empty */}
                </td>
                <td className="text-right font-bold px-4 py-2 border font-serif">
                  Total Amount:
                </td>
                <td
                  colSpan="1"
                  className="border px-4 py-2 font-[Poppin] font-bold text-2xl"
                >
                  {totalAmount}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="join flex justify-center mt-4">
            <button
              className="join-item btn m-1"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button className="join-item btn m-1">{currentPage}</button>
            <button
              className="join-item btn m-1"
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastItem >= payments.length}
            >
              Next
            </button>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default ViewValueAdditionPayments;
