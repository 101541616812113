import Sidebar from "../components/Sidebar";

const Home = () => {
  return (
    <Sidebar>
      <section className="relative  lg:flex lg:pt-8 lg:flex-col lg:pb-0 h-screen">
        <div className="relative w-full h-full">

          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
            <div className="w-full lg:max-w-lg lg:pr-5 text-white">
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400"></p>
              <h2 className="mb-5 font-serif text-4xl tracking-tight sm:text-4xl sm:leading-none lg:text-6xl lg:leading-tight text-center animated-heading">
                <span className="sometimes-italic tracking-wider font-bold ">
                  Value Additions
                </span>{" "}
                <br />
              </h2>
              <p className="pr-5 mb-5 text-base text-white md:text-lg text-center font-serif">
                Access the menu from the <u>sidebar</u> on the <u>top left</u> to get
                started.
              </p>
              <div className="text-center">
                <a
                  href="/"
                  className="font-serif text-center inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  Back to Dashboard
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};

export default Home;
