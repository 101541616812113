import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { PlusCircle, MinusCircle } from "react-feather";
import { useNavigate } from "react-router-dom";

const RegionalEvent = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  const [eventForms, setEventForms] = useState([
    {
      region: "",
      event: "",
      dataCollected: "",
      siteVisitDone: "",
      scheduledVisits: "",
      sales: "",
      referrals: "",
      cost: "",
      startDate: "", // Add startDate property
      endDate: "",
    },
  ]);
  const [regionOptions, setRegionOptions] = useState([]);

  useEffect(() => {
    const fetchRegionOptions = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/category"
        );
        if (!response.ok) {
          throw new Error("An error occurred while fetching region options");
        }
        const data = await response.json();

        const categories = data.map((item) => item.category);
        setRegionOptions(categories);
      } catch (error) {
        console.error("Error fetching region options:", error);
      }
    };

    fetchRegionOptions();
  }, []);
  useEffect(() => {
    // Update the start and end dates whenever the currentMonth, startDate, or endDate changes
    setEventForms((prevForms) => {
      return prevForms.map((form) => {
        return {
          ...form,
          startDate,
          endDate,
        };
      });
    });
  }, [startDate, endDate]);

  const addEventForm = () => {
    setEventForms([
      ...eventForms,
      {
        region: "",
        event: "",
        dataCollected: "",
        siteVisitDone: "",
        scheduledVisits: "",
        sales: "",
        referrals: "",
        cost: "",
        startDate: "", // Add startDate property
        endDate: "",
      },
    ]);
  };
  const removeTask = (index) => {
    if (eventForms.length > 1) {
      const updatedEventForms = [...eventForms];
      updatedEventForms.splice(index, 1);
      setEventForms(updatedEventForms);
    }
  };

  const handleEventChange = (index, field, value) => {
    const updatedEventForms = eventForms.map((form, i) => {
      if (i === index) {
        return { ...form, [field]: value };
      }
      return form;
    });
    setEventForms(updatedEventForms);
  };

  const handleSubmitEvent = async () => {
    if (
      !eventForms.every(
        (form) =>
          form.region &&
          form.event &&
          form.dataCollected &&
          form.siteVisitDone &&
          form.scheduledVisits &&
          form.sales &&
          form.referrals &&
          form.cost
      )
    ) {
      // Display error message for missing required fields
      toast.error("Please fill in all required fields.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent form submission if there are empty fields
    }

    try {
      // Proceed with form submission
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/event",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(eventForms),
        }
      );

      if (!response.ok) {
        throw new Error("An error occurred while submitting event forms");
      }

      toast.success("Event forms submitted successfully!", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setEventForms([
        {
          region: "",
          event: "",
          dataCollected: "",
          siteVisitDone: "",
          scheduledVisits: "",
          sales: "",
          referrals: "",
          cost: "", // Added Cost field
        },
      ]);
      navigate("/dash");
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.error(error);
    }
  };

  return (
    <Sidebar>
      <div className="flex justify-center items-center mx-10 my-10 flex flex-wrap gap-8">
        {eventForms.map((form, index) => (
          <div key={index} className="card w-96 bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title text-2xl font-semibold mb-4">
                Event Form
              </h2>
              <form action="#" className="space-y-4">
                {/* Start Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                {/* End Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                {/* Select Region */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Select Region
                  </label>
                  {regionOptions.length > 0 ? (
                    <select
                      className="select select-bordered select-primary w-full max-w-xs"
                      value={form.region}
                      onChange={(e) =>
                        handleEventChange(index, "region", e.target.value)
                      }
                    >
                      <option value="">Select Region</option>
                      {regionOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p>Loading region options...</p>
                  )}
                </div>

                {/* Event */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Event
                  </label>
                  <input
                    type="text"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.event}
                    onChange={(e) =>
                      handleEventChange(index, "event", e.target.value)
                    }
                  />
                </div>

                {/* Data Collected */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Data Collected
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.dataCollected}
                    onChange={(e) =>
                      handleEventChange(index, "dataCollected", e.target.value)
                    }
                  />
                </div>

                {/* Site Visit Done */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Site Visit Done
                  </label>
                  <input
                    type="text"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.siteVisitDone}
                    onChange={(e) =>
                      handleEventChange(index, "siteVisitDone", e.target.value)
                    }
                  />
                </div>

                {/* Scheduled Visits */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Scheduled Visits
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.scheduledVisits}
                    onChange={(e) =>
                      handleEventChange(
                        index,
                        "scheduledVisits",
                        e.target.value
                      )
                    }
                  />
                </div>

                {/* Sales */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Sales
                  </label>
                  <input
                    type="text"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.sales}
                    onChange={(e) =>
                      handleEventChange(index, "sales", e.target.value)
                    }
                  />
                </div>

                {/* Referrals */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Referrals
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.referrals}
                    onChange={(e) =>
                      handleEventChange(index, "referrals", e.target.value)
                    }
                  />
                </div>
                {/* Cost */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Cost
                  </label>
                  <input
                    type="text"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.cost}
                    onChange={(e) =>
                      handleEventChange(index, "cost", e.target.value)
                    }
                  />
                </div>
                <div className="w-full flex justify-center mt-4 space-x-4">
                  {index !== 0 && (
                    <button
                      onClick={() => removeTask(index)}
                      className="btn btn-danger btn-circle text-white"
                      style={{ backgroundColor: "#df4747" }}
                    >
                      <MinusCircle size={30} />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center space-x-4">
        <button
          className="btn btn-primary"
          type="button"
          onClick={addEventForm}
        >
          <PlusCircle size={30} />
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSubmitEvent}
        >
          Submit Event Forms
        </button>
      </div>
    </Sidebar>
  );
};

export default RegionalEvent;
