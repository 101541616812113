import React from 'react';

function OverwriteModal({ show, handleClose, handleConfirm }) {
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
       
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Booking Conflict</h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Booking conflict detected. Do you want to overwrite the existing booking?
            </p>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
            onClick={handleConfirm}
          >
            Overwrite
          </button>
        </div>
        </div>
      </div>
    </div>
  );
}

export default OverwriteModal;
