import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import MonthlySalesChart from "../dash/Charts/MonthlyBarChart"; // Import the new component

const ViewMonthlySales = () => {
  const [monthlySalesData, setMonthlySalesData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [filteredMonthlySalesData, setFilteredMonthlySalesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const accessRoles = accessRole.split("#");

  const isIvoryAdmin = accessRoles.includes("isIvoryAdmin");
  const isIvoryHos = accessRoles.includes("isIvoryHos");
  const isIvoryGm = accessRoles.includes("isIvoryGm");

  const baseUrl = "https://workspace.optiven.co.ke/api/monthly-sales";

  const getTeamSpecificPath = () => {
    if (isIvoryHos) {
      return "/team1";
    } else if (isIvoryGm) {
      return "/team2";
    } else {
      return "";
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const teamSpecificPath = getTeamSpecificPath();
      const url = baseUrl + teamSpecificPath;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setMonthlySalesData(data);
      setFilteredMonthlySalesData(data);
    } catch (error) {
      setError("Error fetching data: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [accessRole]);

  const mapMetricName = (key) => {
    switch (key) {
      case "OceanViewRidge":
        return "Ocean View Ridge";
      case "Malindi":
        return "Malindi";
        case "GreatOasis":
        return "Great Oasis";
      case "Ushindi":
        return "Ushindi";
        case "AchieversCommercial":
        return "Achievers Commercial";
      case "Wema":
        return "Wema";
        case "JoyLovers":
        return "Joy Lovers";
      case "kithimani":
        return "Kithimani";
      case "totalSales":
        return "Total Sales";
      default:
        return key; // Return the key itself if not matched
    }
  };

  const chartData = filteredMonthlySalesData.map((salesRecord) => ({
    team: salesRecord.team,
    OceanViewRidge: salesRecord.OceanViewRidge,
        Malindi: salesRecord.Malindi,
        GreatOasis: salesRecord.GreatOasis,
        Ushindi: salesRecord.Ushindi,
        AchieversCommercial: salesRecord.AchieversCommercial,
        Wema: salesRecord.Wema,
        JoyLovers: salesRecord.JoyLovers,
        kithimani: salesRecord.kithimani,
    totalSales: salesRecord.total_sales,
  }));

  const chartDatasets = ["OceanViewRidge", "Malindi","GreatOasis","Ushindi", "AchieversCommercial","Wema", "JoyLovers", "kithimani", "totalSales"].map(
    (key, index) => ({
      label: mapMetricName(key),
      dataKey: key,
      fill: `#${index === 0 ? "353e2d" : index === 1 ? "9d92f0" : "ca9d52"}`,
    })
  );

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
    if (selectedMonth === "") {
      setFilteredMonthlySalesData(monthlySalesData);
    } else {
      const filteredData = monthlySalesData.filter((record) => {
        const endDate = new Date(record.endDate);
        return endDate.getMonth() + 1 === parseInt(selectedMonth);
      });
      setFilteredMonthlySalesData(filteredData);
    }
  };

  const Modal = ({ isOpen, setIsOpen, rowData }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(null)}
            className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
            style={{ zIndex: 9999 }}
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-lg p-6 max-w-3xl w-full overflow-y-auto"
              style={{
                maxWidth: "95%",
                maxHeight: "95%",
                width: "95%",
                zIndex: 9999,
              }}
            >
              <h2 className="text-2xl font-bold mb-4">Referal Details</h2>
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-2">
                <p className="font-medium">Team</p>
                  <p className="font-medium">Ocean View Ridge</p>
                  <p>{rowData.OceanViewRidge}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Malindi</p>
                  <p>{rowData.Malindi}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Great Oasis</p>
                  <p>{rowData.GreatOasis}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Ushindi</p>
                  <p>{rowData.Ushindi}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Achievers Commercial</p>
                  <p>{rowData.AchieversCommercial}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Wema</p>
                  <p>{rowData.Wema}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Joy Lovers</p>
                  <p>{rowData.JoyLovers}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Affordable Plots</p>
                  <p>{rowData.affordable_plots}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Sales Achieved</p>
                  <p>{rowData.sales_achieved}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Site Visits</p>
                  <p>{rowData.site_visits}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Observation</p>
                  <p>{rowData.observation}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Recommendation</p>
                  <p>{rowData.recommendation}</p>
                </div>
              </div>
              <button
                onClick={() => setIsOpen(null)}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mt-4"
                style={{ zIndex: 9999 }}
              >
                Close
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <Sidebar>
      <div className="container px-4 py-6 mx-auto">
        <div className="overflow-x-auto card bg-base-100 shadow-xl">
          {/* Filter by Month UI */}
          <div className="flex justify-end mb-4">
            <label className="mr-2">Filter by Month:</label>
            <select
              className="border p-1"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              <option value="">All Months</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <table className="table table-compact">
            <thead>
              <tr>
                <th>ID</th>
                <th className="text-center">Team</th>
                <th className="text-center">Ocean View Ridge</th>
                <th className="text-center">Malindi</th>
                <th className="text-center">Great Oasis</th>
                <th className="text-center">Ushindi</th>
                <th className="text-center">Achievers Commercial</th>
                <th className="text-center">Wema</th>
                <th className="text-center">Joy Lovers</th>
                <th className="text-center">Kithimani</th>
                <th className="text-center">Total Sales</th>
                <th className="text-center">Observation</th>
                <th className="text-center">Recommendation</th>
              </tr>
            </thead>
            <tbody>
              {filteredMonthlySalesData.map((salesRecord, i) => (
                <tr
                  key={i}
                  className={`${i % 2 === 0 ? "bg-gray-50" : ""}`}
                  onClick={() => handleRowClick(salesRecord)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{i + 1}</td>
                  <td className="text-center">{salesRecord.team}</td>
                  <td className="text-center">
                    {salesRecord.OceanViewRidge}
                  </td>
                  <td className="text-center">
                    {salesRecord.Malindi}
                  </td>
                  <td className="text-center">
                    {salesRecord.GreatOasis}
                  </td>
                  <td className="text-center">
                    {salesRecord.Ushindi}
                  </td>
                  <td className="text-center">
                    {salesRecord.AchieversCommercial}
                  </td>
                  <td className="text-center">
                    {salesRecord.Wema}
                  </td>
                  <td className="text-center">
                    {salesRecord.JoyLovers}
                  </td>
                  <td className="text-center">
                    {salesRecord.kithimani}
                  </td>
                  <td className="text-center">{salesRecord.total_sales}</td>
                  <td className="text-center">{salesRecord.observation}</td>
                  <td className="text-center">{salesRecord.recommendation}</td>
                </tr>
              ))}
            </tbody>
          </table>

        {/* Display Detailed Modal */}
        {selectedRowData && (
          <Modal
            isOpen={true}
            setIsOpen={setSelectedRowData}
            rowData={selectedRowData}
          />
        )}
      </div>
      </div>

      {/* Display Bar Chart */}
      <div className="flex justify-center mt-8">
        <MonthlySalesChart data={filteredMonthlySalesData} />
      </div>
    </Sidebar>
  );
};

export default ViewMonthlySales;
