import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import _404cat from "../assets/svgs/404-cat.svg";
import { toast } from "react-toastify";


function formatDate(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const ViewMaintenanceSuppliers
 = () => {
  const [payment, setPayment] = useState([]);
  const [projects, setProjects] = useState([]);
  // SEARCHBAR: Initialize the search query to an empty string
  const [query, setQuery] = useState("");

 

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const Response = await axios.get(
          "https://workspace.optiven.co.ke/api/suppliers/maintenance-supplier-payments",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        setPayment( Response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/projects/all",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPayments();
    fetchProjects();
  }, []);

  // SEARCHBAR: First filter the array of items(payment) to return an array that does not include the value of the query

  const filteredSuppliers = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      payment.filter((member) => {
        if (query === "") {
          return member;
        } else if (member.name.toLowerCase().includes(query.toLowerCase())) {
          return member;
        }
      }),
    [query, payment]
  );

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://workspace.optiven.co.ke/api/suppliers/supplier-payments/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setPayment(payment.filter((member) => member.id !== id));
      toast.success("Payment deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error("Payment has not been deleted", error.response.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  

  const getProjectName = (projectData) => {
    const project = projects.find(
      (proj) => proj.id === parseInt(projectData, 10)
    );
    return project ? project.project_name : "Unknown Project";
  };

  return (
    <>
      <Sidebar>
        <div className="flex justify-center items-center m-4 flex-col">
          <input
            placeholder="Search supplier by Name"
            className="input input-bordered input-success w-full max-w-xs"
            type="text"
            // SEARCHBAR: Create an onChange event which sets the query to whatever the value of the input is when it changes
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="overflow-x-auto px-10">
        <h1 className="px-2 font-bold text-2xl font-serif">Maintenance Supplier Payments</h1>
          {filteredSuppliers.length === 0 ? (
            <div className="flex flex-col justify-center items-center  w-1/3">
              <img
                src={_404cat}
                alt="not-found"
                className="max-w-lg lg:w-1/3"
              />
              <h1 className="text-black font-bold text-center">
                No Maintenance Suppliers Payments
              </h1>
            </div>
          ) : (
            
            <table className="table table-zebra w-full">
              {/* head */}
              <thead>
                <tr className="text-xl font-serif">
                  <th></th>

                  <th>Project </th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>ID No</th>
                  <th>S. Date</th>
                  <th>E. Date </th>
                  <th>Supplies</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredSuppliers.map((member, i) => {
                  // Find the job group document for this member

                  return (
                    <tr key={member.id} className="font-[Poppin]">
                      <th>{i + 1}</th>
                      <td>{getProjectName(member.projectId)}</td>
                      <td>{member.name}</td>
                      <td>{member.phoneNumber}</td>
                      <td>{member.id_number}</td>
                      <td>{formatDate(member.start_date)}</td>
                      <td>{formatDate(member.end_date)}</td>
                      <td>{member.supplies}</td>
                      <td>{member.amount}</td>
                
                      <td>
                        <button
                          className="btn btn-accent btn-sm"
                          onClick={() => handleDelete(member.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        
      </Sidebar>
    </>
  );
};

export default ViewMaintenanceSuppliers
;
