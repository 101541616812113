import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const IndividualReports = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [marketer, setMarketer] = useState("");
  const [users, setUsers] = useState([]);
  const [regionalManagerDetails, setRegionalManagerDetails] = useState(null);
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.user.user_id);

  useEffect(() => {
    const fetchRegionalManagerDetails = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/users/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setRegionalManagerDetails(response.data);
      } catch (error) {
        console.error("Error fetching user's details:", error);
      }
    };

    const fetchMarketers = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/users",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const data = response.data.filter(
          (user) =>
            user.Accessrole.split("#").includes("113") ||
            user.Accessrole.split("#").includes("workplanAdmin")
        );
        setUsers(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchRegionalManagerDetails();
    fetchMarketers();
  }, [token, userId]);

  const handleDownload = async () => {
    if (!marketer || !startDate || !endDate) {
      toast.error("Please fill in all fields.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (endDate < startDate) {
      toast.error("End date cannot be before the start date.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/workplan-reports/marketer`,
        {
          params: {
            start_date: startDate,
            end_date: endDate,
            marketer: marketer,
          },
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "individual_report.pdf";
      link.click();

      toast.success("PDF downloaded successfully.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error(
        "This marketer doesn't have any activities within this date range.",
        {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <div className="form-control w-full max-w-xs">
          <div className="flex flex-col justify-center">
            <h1 className="font-bold text-lg">INDIVIDUAL MARKETER REPORT</h1>
            <label className="label">
              <span className="label-text font-bold">Marketer</span>
            </label>
            <select
              className="select select-bordered w-full max-w-xs mb-4"
              onChange={(e) => setMarketer(e.target.value)}
              value={marketer}
            >
              <option value="" disabled>
                Select Marketer
              </option>
              {users
                .filter((m) => m.region_id === regionalManagerDetails.region_id)
                .map((user) => (
                  <option key={user.id} value={user.user_id}>
                    {user.fullnames}
                  </option>
                ))}
            </select>
            <label className="label">
              <span className="label-text font-bold">Start Date</span>
            </label>
            <input
              type="date"
              value={startDate}
              className="input input-bordered w-full max-w-xs mb-4"
              onChange={(e) => setStartDate(e.target.value)}
            />
            <label className="label">
              <span className="label-text font-bold">End Date</span>
            </label>
            <input
              type="date"
              className="input input-bordered w-full max-w-xs mb-4"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <button className="btn btn-outline" onClick={handleDownload}>
              Download PDF
            </button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default IndividualReports;
