import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import axios from "axios";
import {toast} from 'react-toastify'
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const ViewJobGroup = () => {
  const [jgs, setJGs] = useState([]);
  const [query, setQuery] = useState("");
  const navigate = useNavigate()
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");
  const maintenance = accessRoles.includes("maintenance");
  const valueAddition = accessRole.includes("valueAddition");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");
  const ict = accessRole.includes(department === "ICT SYSTEMS");


  useEffect(() => {
    const fetchJG = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/labour",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setJGs(response.data);
        
      } catch (error) {
        console.error(error);
      }
    };

    fetchJG();
  }, []);

  const filteredJGs = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      jgs.filter((jg) => {
        if (query === "") {
          return jg;
        } else if (jg.labour_type.toLowerCase().includes(query.toLowerCase())) {
          return jg;
        }
      }),
    [query, jgs]
  );
  console.log(filteredJGs)

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://workspace.optiven.co.ke/api/labour/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setJGs(jgs.filter((jg) => jg.id !== id));
      toast.success("Labour deleted Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error("Labour has associated data cannot be deleted",error.response.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Sidebar>
        <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
          <div className="flex justify-center max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <input
              placeholder="Search labour by labour type"
              className="input input-bordered input-success w-full max-w-xs"
              type="text"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">
            {filteredJGs.map((jg) => (
              <div
                key={jg.id}
                className="cursor-pointer flex flex-col justify-center items-center p-5 duration-300 transform bg-white border rounded shadow-sm hover:-translate-y-2"
              >
                <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-green-50">
                  <svg
                    className="w-7 h-7 text-primary"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                  >
                    <path
                      d="M2,10C2,5.029,6.47,1,12,1c5.379,0,10,4.029,10,9S15.712,23,12,23C8.212,23,2,14.971,2,10Z"
                      fill="none"
                      strokeWidth="2"
                      stroke="currentColor"
                    />
                    <path
                      d="M5,9a6.529,6.529,0,0,1,4.393,1.607C10.705,11.92,10,15,10,15a6.59,6.59,0,0,1-3.436-1.564C5.022,11.894,5,9,5,9Z"
                      fill="none"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    />
                    <path
                      d="M19,9a6.523,6.523,0,0,0-4.392,1.608C13.3,11.92,14,15,14,15a6.59,6.59,0,0,0,3.436-1.564C18.978,11.894,19,9,19,9Z"
                      fill="none"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    />
                  </svg>
                </div>
                <p className="text-sm text-gray-900 text-center text-xl font-serif">
                  <span className="font-bold">Labour Type: </span>
                  {jg.labour_type}
                </p>
               
                <p className="text-sm text-gray-900 text-center text-xl font-serif">
                  <span className="font-bold">Wage Type: </span>
                  {jg.wage_type}
                </p>
                <p className="text-sm text-gray-900 text-center text-xl font-serif">
                  <span className="font-bold">Rate Per Day: </span>
                  {jg.rate_per_day} KES
                </p>
                {(!maintenance && !valueAddition) || operations || valueAdmin || ict ? (
                <div className="grid grid-cols-2 gap-3 " >
                <button
                  className="btn btn-accent text-white mt-4"
                  onClick={() => handleDelete(jg.id)}
                >
                  Delete
                </button>
                
                <button
                  className="btn btn-outline btn-warning mt-4"
                  onClick={() => navigate(`/view-labour/${jg.id}/edit-labour`)}
                >
                 Edit
                </button>

                </div>
                 ) : null}
              </div>
              
            ))}
          </div>
          {(!maintenance && !valueAddition) || operations || valueAdmin || ict ? (
          <div className="text-center">
            <Link to="/add-labour" className="btn btn-primary text-white">
              Create Labour
            </Link>
          </div>
          ) : null}
        </div>
      </Sidebar>
    </>
  );
};

export default ViewJobGroup;
