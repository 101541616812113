import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import formatDate from "../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
function formatCurrency(num) {
  if (typeof num !== "number" || isNaN(num)) {
    // Handle the case where num is not a valid number
    return "Invalid";
  }

  return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

const ViewValueAdditions = () => {
  const [valueAdditions, setValueAdditions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [constructionManagers, setConstructionMangers] = useState([]);
  const [serviceProviders, setServiceProvider] = useState([]);
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");
  const valueAddition = accessRole.includes("valueAddition");
  const maintenance = accessRoles.includes("maintenance");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");
  const ict = accessRole.includes(department === "ICT SYSTEMS");

  const navigate = useNavigate();

  // fetch value additions
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/value-additions`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(response.data);
        setValueAdditions(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/projects/all",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchConstructionManagers = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/construction-managers`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setConstructionMangers(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchServiceProvider = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/suppliers",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setServiceProvider(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    fetchProjects();
    fetchConstructionManagers();
    fetchServiceProvider();
  }, []);
  // get project
  const getProjectName = (projectId) => {
    const project = projects.find(
      (proj) => proj.id === parseInt(projectId, 10)
    );
    return project ? project.project_name : "Unknown Project";
  };
  //   get construction Manager
  const getConstructorManagerName = (constructionManagerId) => {
    const constructorManager = constructionManagers.find(
      (cm) => cm.id === parseInt(constructionManagerId, 10)
    );
    return constructorManager
      ? constructorManager.constructor_name
      : "unknown construction Manager";
  };
  // get service
  const getServiceProvider = (serviceProvideId) => {
    const serviceProvider = serviceProviders.find(
      (sp) => sp.id === parseInt(serviceProvideId, 10)
    );
    return serviceProvider ? serviceProvider.name : "No  Service Provider";
  };
  return (
    <>
      <Sidebar>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <table className="table-auto w-full">
            <thead>
              <tr className="font-serif text-xl">
                <th className="px-4 py-2">#</th>
                <th className="px-4 py-2">Project</th>
                <th className="px-4 py-2">Title</th>
                <th className="px-4 py-2">Construction Manager</th>
                <th className="px-4 py-2">Service Provider</th>
                <th className="px-4 py-2">Plot Allocation</th>
                <th className="px-4 py-2">Budget</th>
                <th className="px-4 py-2">Start Date</th>
                <th className="px-4 py-2">End Date</th>
                {!maintenance && (valueAddition || valueAdmin ||operations || ict) ? (
                <th className="px-4 py-2">Actions</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {valueAdditions.map((valueAddition, index) => (
                <tr
                  key={valueAddition.id}
                  className="font-[Poppin] font-medium text-base"
                >
                  <td className="border px-4 py-2">{index + 1}</td>
                  <td className="border px-4 py-2">
                    {getProjectName(valueAddition.projectId)}
                  </td>
                  <td className="border px-2 py-2">{valueAddition.title}</td>
                  <td className="border px-4 py-2">
                    {getConstructorManagerName(
                      valueAddition.construction_manager
                    )}
                  </td>
                  <td className="border px-4 py-2">
                    {getServiceProvider(valueAddition.service_provider)}
                  </td>
                  <td className="border px-2 py-2">
                    {valueAddition.plot || "No plot allocated"}
                  </td>
                  <td className="border px-4 py-2">
                    {formatCurrency(parseInt(valueAddition.budget))}
                  </td>

                  <td className="border px-4 py-2">
                    {formatDate(valueAddition.startDate)}
                  </td>
                  <td className="border px-4 py-2">
                    {formatDate(valueAddition.endDate)}
                  </td>
                  <td className="border">
                    {!maintenance &&
                    
                    (valueAddition || valueAdmin ||valueAddition || ict) ? (
                      <button
                        className="ml-3 inline-flex items-center justify-center rounded-xl bg-green-600 py-3 px-6 font-dm text-base font-medium text-white shadow-xl shadow-green-400/75 transition-transform duration-200 ease-in-out hover:scale-[1.02]"
                        onClick={() =>
                          navigate(`/value-additions/${valueAddition.id}`)
                        }
                      >
                        View
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Sidebar>
    </>
  );
};

export default ViewValueAdditions;
