const geojsonData = {
  type: "FeatureCollection",

  features: [
    {
      type: "Feature",
      id: "smb7b14a61",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99513016, 40.65010983],
            [-73.99557001, 40.64991616],
            [-73.99559415, 40.64984899],
            [-73.9954146, 40.64946807],
            [-73.99489421, 40.64960927],
            [-73.99513016, 40.65010983],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-18",
      },
    },
    {
      type: "Feature",
      id: "sm4a1e6b00",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99441548, 40.64973916],
            [-73.99407582, 40.64983132],
            [-73.99433217, 40.65062841],
            [-73.99441533, 40.65057754],
            [-73.99436838, 40.65044525],
            [-73.994683, 40.65030672],
            [-73.99441548, 40.64973916],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-20",
      },
    },
    {
      type: "Feature",
      id: "smc0a17380",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.994683, 40.65030672],
            [-73.99513016, 40.65010983],
            [-73.99489421, 40.64960927],
            [-73.99441548, 40.64973916],
            [-73.994683, 40.65030672],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-19",
      },
    },
    {
      type: "Feature",
      id: "smde257d2e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99457489, 40.64929004],
            [-73.99395577, 40.64945803],
            [-73.99407582, 40.64983132],
            [-73.9947445, 40.64964989],
            [-73.99457489, 40.64929004],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-21",
      },
    },
    {
      type: "Feature",
      id: "sm5aed12fb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9947445, 40.64964989],
            [-73.9954146, 40.64946807],
            [-73.99524498, 40.64910822],
            [-73.99457489, 40.64929004],
            [-73.9947445, 40.64964989],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-22",
      },
    },
    {
      type: "Feature",
      id: "sm71d9b799",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99413513, 40.64817872],
            [-73.99359221, 40.64832757],
            [-73.99372887, 40.6487525],
            [-73.99433117, 40.64859035],
            [-73.99413513, 40.64817872],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-26",
      },
    },
    {
      type: "Feature",
      id: "sm09d0901c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99433117, 40.64859035],
            [-73.99492421, 40.6484307],
            [-73.99474523, 40.648048],
            [-73.99466946, 40.64803223],
            [-73.99413513, 40.64817872],
            [-73.99433117, 40.64859035],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-27",
      },
    },
    {
      type: "Feature",
      id: "smce8472d0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99475299, 40.6491005],
            [-73.99512106, 40.64899707],
            [-73.99515862, 40.64893195],
            [-73.99492421, 40.6484307],
            [-73.99449392, 40.64854654],
            [-73.99475299, 40.6491005],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-23",
      },
    },
    {
      type: "Feature",
      id: "sm6cf9f10a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99405205, 40.6486655],
            [-73.99372887, 40.6487525],
            [-73.99395577, 40.64945803],
            [-73.99430567, 40.6493593],
            [-73.99425202, 40.64924127],
            [-73.99431327, 40.64922406],
            [-73.99405205, 40.6486655],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-25",
      },
    },
    {
      type: "Feature",
      id: "sm37a77f47",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99431327, 40.64922406],
            [-73.99475299, 40.6491005],
            [-73.99449392, 40.64854654],
            [-73.99405205, 40.6486655],
            [-73.99431327, 40.64922406],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-24",
      },
    },
    {
      type: "Feature",
      id: "sm1d33ddc9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99372094, 40.64713062],
            [-73.99324917, 40.64725699],
            [-73.99340993, 40.6477587],
            [-73.9939472, 40.6476169],
            [-73.99372094, 40.64713062],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-30",
      },
    },
    {
      type: "Feature",
      id: "sm85d4b30c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9939472, 40.6476169],
            [-73.99447985, 40.64747631],
            [-73.9942745, 40.64702843],
            [-73.99418599, 40.64700605],
            [-73.99372094, 40.64713062],
            [-73.9939472, 40.6476169],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-31",
      },
    },
    {
      type: "Feature",
      id: "sm8f82f2cc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99407945, 40.64804645],
            [-73.99461414, 40.64790224],
            [-73.99464901, 40.64784526],
            [-73.99447985, 40.64747631],
            [-73.99388949, 40.64763213],
            [-73.99407945, 40.64804645],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-28",
      },
    },
    {
      type: "Feature",
      id: "smccd85282",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99388949, 40.64763213],
            [-73.99340993, 40.6477587],
            [-73.99359221, 40.64832757],
            [-73.99386044, 40.64824617],
            [-73.99380679, 40.64811999],
            [-73.99407945, 40.64804645],
            [-73.99388949, 40.64763213],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-29",
      },
    },
    {
      type: "Feature",
      id: "smce728c4b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99412429, 40.64689005],
            [-73.99415921, 40.64678124],
            [-73.99414087, 40.64678488],
            [-73.99364422, 40.64576166],
            [-73.99362276, 40.6458105],
            [-73.99367104, 40.64590005],
            [-73.99374618, 40.64621032],
            [-73.9935316, 40.64649525],
            [-73.99327411, 40.64677202],
            [-73.99323924, 40.64722585],
            [-73.99324917, 40.64725699],
            [-73.99343497, 40.64720956],
            [-73.99338133, 40.64708745],
            [-73.99412429, 40.64689005],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-32",
      },
    },
    {
      type: "Feature",
      id: "sm4c9ce812",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99450905, 40.64582193],
            [-73.9943014, 40.64538813],
            [-73.99419411, 40.64544919],
            [-73.99375151, 40.64553575],
            [-73.9939582, 40.64597213],
            [-73.99450905, 40.64582193],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-17",
      },
    },
    {
      type: "Feature",
      id: "sm03fce362",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9943656, 40.64683224],
            [-73.9945679, 40.64725934],
            [-73.99512449, 40.64710757],
            [-73.99492028, 40.64668099],
            [-73.9943656, 40.64683224],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-16",
      },
    },
    {
      type: "Feature",
      id: "sm7aa5f7ac",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99492028, 40.64668099],
            [-73.99471314, 40.64624828],
            [-73.99416039, 40.64639901],
            [-73.9943656, 40.64683224],
            [-73.99492028, 40.64668099],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-15",
      },
    },
    {
      type: "Feature",
      id: "sm00964e66",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99471314, 40.64624828],
            [-73.99450905, 40.64582193],
            [-73.9939582, 40.64597213],
            [-73.99416039, 40.64639901],
            [-73.99471314, 40.64624828],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-14",
      },
    },
    {
      type: "Feature",
      id: "smd66703b0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9945679, 40.64725934],
            [-73.99477223, 40.64769071],
            [-73.99533074, 40.64753841],
            [-73.99512449, 40.64710757],
            [-73.9945679, 40.64725934],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-13",
      },
    },
    {
      type: "Feature",
      id: "sm5622ff2c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99477223, 40.64769071],
            [-73.99497364, 40.64811591],
            [-73.99553404, 40.6479631],
            [-73.99533074, 40.64753841],
            [-73.99477223, 40.64769071],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-12",
      },
    },
    {
      type: "Feature",
      id: "sm646af270",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99497364, 40.64811591],
            [-73.99517498, 40.64854097],
            [-73.99573727, 40.64838764],
            [-73.99553404, 40.6479631],
            [-73.99497364, 40.64811591],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-11",
      },
    },
    {
      type: "Feature",
      id: "sm8f20625b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99517498, 40.64854097],
            [-73.99538112, 40.64897615],
            [-73.99594535, 40.6488223],
            [-73.99573727, 40.64838764],
            [-73.99517498, 40.64854097],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-10",
      },
    },
    {
      type: "Feature",
      id: "sme7f706e6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99538112, 40.64897615],
            [-73.99558415, 40.64940478],
            [-73.99615029, 40.64925041],
            [-73.99594535, 40.6488223],
            [-73.99538112, 40.64897615],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-09",
      },
    },
    {
      type: "Feature",
      id: "sm61cc9fa1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99558415, 40.64940478],
            [-73.99575651, 40.64976866],
            [-73.99583563, 40.64979511],
            [-73.99606362, 40.64969539],
            [-73.99615482, 40.64980732],
            [-73.99637208, 40.64971371],
            [-73.99615029, 40.64925041],
            [-73.99558415, 40.64940478],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-08",
      },
    },
    {
      type: "Feature",
      id: "sm552febc9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99634674, 40.65022433],
            [-73.99655982, 40.65012985],
            [-73.99637208, 40.64971371],
            [-73.99605851, 40.64985431],
            [-73.99634674, 40.65022433],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-07",
      },
    },
    {
      type: "Feature",
      id: "sm9682970b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99452237, 40.65054312],
            [-73.99433217, 40.65062841],
            [-73.99446233, 40.65105985],
            [-73.99480614, 40.65090741],
            [-73.99452237, 40.65054312],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-01",
      },
    },
    {
      type: "Feature",
      id: "sma4142543",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99508227, 40.65078497],
            [-73.99531819, 40.650679],
            [-73.99533026, 40.65062101],
            [-73.99509959, 40.65033002],
            [-73.99502515, 40.6503117],
            [-73.9947977, 40.65041966],
            [-73.99508227, 40.65078497],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-03",
      },
    },
    {
      type: "Feature",
      id: "sm3de9813d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.9947977, 40.65041966],
            [-73.99452237, 40.65054312],
            [-73.99480614, 40.65090741],
            [-73.99508227, 40.65078497],
            [-73.9947977, 40.65041966],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-02",
      },
    },
    {
      type: "Feature",
      id: "sm9dddfb2d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99556227, 40.65057624],
            [-73.99579558, 40.6504687],
            [-73.99550894, 40.65010074],
            [-73.99527796, 40.6502018],
            [-73.99526455, 40.65026387],
            [-73.99548717, 40.65055691],
            [-73.99556227, 40.65057624],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-04",
      },
    },
    {
      type: "Feature",
      id: "smfb76d3d7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99579558, 40.6504687],
            [-73.99607397, 40.65034527],
            [-73.99578653, 40.64997627],
            [-73.99550894, 40.65010074],
            [-73.99579558, 40.6504687],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-05",
      },
    },
    {
      type: "Feature",
      id: "sm5c5ef16d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-73.99607397, 40.65034527],
            [-73.99634674, 40.65022433],
            [-73.99605851, 40.64985431],
            [-73.99578653, 40.64997627],
            [-73.99607397, 40.65034527],
          ],
        ],
      },
      properties: {
        Unit_Number: "W4-06",
      },
    },
  ],
};

export default geojsonData;
