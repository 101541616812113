import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";

const CreateValueAddition = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);

  // finding the projectId
  const location = useLocation();
  const url = location.pathname;
  const projectId = url.split("/")[2];
  const [valueAdditionId, setValueAdditionId] = useState(null);
  const token = useSelector((state) => state.user.token);
  const [title, setTitle] = useState("");
  const [showPlotField, setShowPlotField] = useState(false);
  const [constructionManagers, setConstructionManagers] = useState([]);
  const [constructionManagerOptions, setConstructionManagersOptions] = useState(
    []
  );
  const [serviceProviders, setServiceProviders] = useState([]);
  const [serviceProviderOptions, setServiceProviderOptions] = useState([]);

  // get construction managers
  useEffect(() => {
    const fetchConstructionMangers = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/construction-managers",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setConstructionManagers(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchServiceProvider = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/suppliers",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(response.data);
        setServiceProviders(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchServiceProvider();
    fetchConstructionMangers();
  }, []);
  // fetch construction managers
  useEffect(() => {
    const options = constructionManagers.map((c) => ({
      value: c.id,
      label: c.constructor_name,
    }));
    setConstructionManagersOptions([{ value: "", label: "" }, ...options]);
  }, [constructionManagers]);
  // fetch service providers
  useEffect(() => {
    const options = serviceProviders.map((s) => ({
      value: s.id,
      label: s.name,
    }));
    setServiceProviderOptions([{ value: "", label: "" }, ...options]);
  }, [serviceProviders]);
  // initialValues
  const initialValues = {
    title: "",
    construction_manager: "",
    service_provider: "",
    startDate: "",
    endDate: "",
    details: "",
    plot: "",
    budget: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/value-addition-level",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const valueAddition = response.data;
        console.log("the value_addition is:", valueAddition);
        const valueAdditionById = valueAddition.find(
          (item) => item.id === parseInt(id)
        );
        console.log("the value_addition is:", valueAdditionById);
        setValueAdditionId(valueAdditionById);
        if (valueAdditionById) {
          setTitle(valueAdditionById.value);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const validationSchema = Yup.object({
    construction_manager: Yup.number()
      .oneOf(constructionManagerOptions.map((option) => option.value))
      .required("Please select a construction manager"),
    service_provider: Yup.number()
      .oneOf(serviceProviderOptions.map((option) => option.value))
      .required("Please select a service provider"),

    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
    details: Yup.string().required("Please add the necessary details"),
    budget: Yup.number()
      .required("Budget is required")
      .min(0, "Budget cannot be Ksh 0"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/value-additions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            title: title || values.title,
            construction_manager: values.construction_manager,
            service_provider: values.service_provider,
            startDate: values.startDate,
            endDate: values.endDate,
            details: values.details,
            plot: values.plot,
            budget: values.budget,
            projectId: projectId,
            value_addition_level_id: valueAdditionId.id,
          }),
        }
      );
      toast.success("Value addition added!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // redirect to home page
      setTimeout(() => {
        navigate(`/projects/${projectId}`);
      }, 2000);
    } catch (error) {
      console.error(error.response);
      toast.error("Something went wrong. Please try again", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setSubmitting(false);
    }
  };
  const handleCheckboxChange = () => {
    setShowPlotField(!showPlotField);
  };

  return (
    <>
      <Sidebar>
        <div className="hero mt-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form className="form-control w-full max-w-xs">
                <label htmlFor="title" className="label">
                  <span className="label-text font-bold">Title</span>
                </label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  value={formik.values.title || title}
                  className="input input-bordered w-full max-w-xs"
                />

                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="construction_manager" className="label">
                  <span className="label-text font-bold">
                    Construction Manager
                  </span>
                </label>
                <Field
                  as="select"
                  id="construction_manager"
                  name="construction_manager"
                  className="select select-bordered"
                >
                  <option value="">Select a Construction Manager</option>
                  {constructionManagerOptions.slice(1).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="construction_manager"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />
                <label htmlFor="construction_manager" className="label">
                  <span className="label-text font-bold">Service Provider</span>
                </label>
                <Field
                  as="select"
                  id="service_provider"
                  name="service_provider"
                  className="select select-bordered"
                >
                  <option value="">Select Service Provider</option>
                  {serviceProviderOptions.slice(1).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="service_provider"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />
                <div className="lg:flex justify-between">
                  <div className="w-36">
                    <label className="label" htmlFor="startDate">
                      <span className="label-text font-bold">Start Date: </span>
                    </label>
                    <Field
                      type="date"
                      className="input input-bordered w-full max-w-xs"
                      id="startDate"
                      name="startDate"
                    />
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="text-red-500 font-bold text-sm mt-2"
                    />
                  </div>
                  <div className="w-36">
                    <label className="label" htmlFor="endDate">
                      <span className="label-text font-bold">End Date: </span>
                    </label>
                    <Field
                      type="date"
                      className="input input-bordered w-full max-w-xs"
                      id="endDate"
                      name="endDate"
                    />
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="text-red-500 font-bold text-sm mt-2"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="plot" className="label">
                    <span className="label-text font-bold">Plot Location</span>
                  </label>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="showPlot"
                      name="showPlot"
                      checked={showPlotField}
                      onChange={handleCheckboxChange}
                      className="mr-2"
                    />
                    <label htmlFor="showPlot" className="text-sm ">
                      Add Plot Location?
                    </label>
                  </div>
                  {showPlotField && (
                    <Field
                      type="text"
                      id="plot"
                      name="plot"
                      placeholder="NK900"
                      className="input input-bordered w-full max-w-xs"
                    />
                  )}
                </div>

                <label htmlFor="budget" className="label">
                  <span className="label-text font-bold">Budget (KES)</span>
                </label>
                <Field
                  type="text"
                  id="budget"
                  name="budget"
                  placeholder="100000"
                  className="input input-bordered w-full max-w-xs"
                />
                <ErrorMessage
                  name="budget"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />
                <label htmlFor="details" className="label">
                  <span className="label-text font-bold">Details</span>
                </label>
                <Field
                  as="textarea"
                  id="details"
                  name="details"
                  placeholder="100m, 10m deep, etc"
                  className="textarea textarea-bordered h-24"
                />
                <ErrorMessage
                  name="details"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <button
                  type="submit"
                  id="submit"
                  className="btn btn-primary w-full max-w-xs mt-4"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting
                    ? "Submitting..."
                    : "Create Value Addition"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateValueAddition;
