import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const ReferalBarChart = ({ referalData, selectedMonth }) => {
  const mapMetricName = (key) => {
    switch (key) {
      case "total_referrals":
        return "Total Referrals";
      case "sales_achieved":
        return "Sales Achieved";
      case "site_visits":
        return "Site Visits";
      default:
        return key;
    }
  };

  const mapMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month - 1] || "";
  };

  // Create an object to store the sums for each team
  const teamSums = {};

  // Filter referalData based on the selected month
  const filteredReferalData = selectedMonth
    ? referalData.filter((record) => {
        const month = new Date(record.endDate).getMonth() + 1; // Assuming endDate is the field containing the end date
        return month.toString() === selectedMonth;
      })
    : referalData;

  console.log("Filtered Referal Data:", filteredReferalData);

  filteredReferalData.forEach((referalRecord) => {
    const teamName = referalRecord.team;

    if (!teamSums[teamName]) {
      teamSums[teamName] = {
        total_referrals: 0,
        sales_achieved: 0,
        site_visits: 0,
      };
    }

    Object.keys(teamSums[teamName]).forEach((key) => {
      teamSums[teamName][key] += referalRecord[key] || 0;
    });
  });

  console.log("Team Sums:", teamSums);

  const chartData = Object.keys(teamSums).map((teamName) => ({
    team: teamName,
    total_referrals: teamSums[teamName].total_referrals,
    sales_achieved: teamSums[teamName].sales_achieved,
    site_visits: teamSums[teamName].site_visits,
  }));

  console.log("Chart Data:", chartData);

  const chartDatasets = ["total_referrals", "sales_achieved", "site_visits"].map(
    (key, index) => ({
      label: mapMetricName(key),
      dataKey: key,
      fill: `#${index === 0 ? "7695bf" : index === 1 ? "bbaabb" : "bc4a3c"}`,
    })
  );

  return (
    <div>
      <h2 className="text-center mb-4">
        {selectedMonth
          ? `Performance for ${mapMonthName(parseInt(selectedMonth))}`
          : "Overall Performance"}
      </h2>

      {chartData.length > 0 ? (
        <BarChart
          width={600}
          height={300}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="team" />
          <YAxis />
          <Tooltip />
          <Legend />
          {chartDatasets.map((dataset, index) => (
            <Bar
              key={index}
              dataKey={dataset.dataKey}
              fill={dataset.fill}
              name={dataset.label}
            />
          ))}
        </BarChart>
      ) : (
        <p className="text-center">No data available for the selected month.</p>
      )}
    </div>
  );
};

export default ReferalBarChart;
