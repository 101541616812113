import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence, motion } from "framer-motion";
import ReferalBarChart from "../dash/Charts/ReferalBarChart";
import { useSelector } from "react-redux";

const ViewReferal = () => {
  const [referalData, setReferalData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");

  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const accessRoles = accessRole.split("#");

  const isIvoryAdmin = accessRoles.includes("isIvoryAdmin");
  const isIvoryHos = accessRoles.includes("isIvoryHos");
  const isIvoryGm = accessRoles.includes("isIvoryGm");

  // Define base URL
  const baseUrl = "https://workspace.optiven.co.ke/api/referal";

  // Function to get the team-specific path based on access role
  const getTeamSpecificPath = () => {
    if (isIvoryHos) {
      return "/absa";
    } else if (isIvoryGm) {
      return "/karen";
    } else {
      // Default path or handle other roles if needed
      return "";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const teamSpecificPath = getTeamSpecificPath();
        const url = baseUrl + teamSpecificPath;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setReferalData(data);
      } catch (error) {
        toast.error("Error fetching data", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error(error);
      }
    };

    fetchData();
  }, [accessRole]);

  const handleRowClick = (rowData) => {
    setSelectedRowData(rowData);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredReferalData = selectedMonth
    ? referalData.filter((record) => {
        const month = new Date(record.endDate).getMonth() + 1; // Assuming endDate is the field containing the end date
        return month.toString() === selectedMonth;
      })
    : referalData;

  const Modal = ({ isOpen, setIsOpen, rowData }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(null)}
            className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
            style={{ zIndex: 9999 }}
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-lg p-6 max-w-3xl w-full overflow-y-auto"
              style={{
                maxWidth: "95%",
                maxHeight: "95%",
                width: "95%",
                zIndex: 9999,
              }}
            >
              <h2 className="text-2xl font-bold mb-4">Referal Details</h2>
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-2">
                  <p className="font-medium">Team</p>
                  <p>{rowData.team}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Total Referrals</p>
                  <p>{rowData.total_referrals}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Sales Achieved</p>
                  <p>{rowData.sales_achieved}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Site Visits</p>
                  <p>{rowData.site_visits}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Observation</p>
                  <p>{rowData.observation}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Recommendation</p>
                  <p>{rowData.recommendation}</p>
                </div>
              </div>
              <button
                onClick={() => setIsOpen(null)}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mt-4"
                style={{ zIndex: 9999 }}
              >
                Close
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  return (
    <Sidebar>
      <div className="container px-4 py-6 mx-auto">
        <div className="overflow-x-auto card bg-base-100 shadow-xl">
          {/* Dropdown for selecting month */}
          <div className="flex justify-end mb-4">
            <label className="mr-2">Filter by Month:</label>
            <select
              className="border p-1"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              <option value="">All Months</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>

          <table className="table table-compact">
            <thead>
              <tr>
                <th>ID</th>
                <th className="text-center">Team</th>
                <th className="text-center">Total Referrals</th>
                <th className="text-center">Sales Achieved</th>
                <th className="text-center">Site Visits</th>
                <th className="text-center">Observation</th>
                <th className="text-center">Recommendation</th>
              </tr>
            </thead>
            <tbody>
              {filteredReferalData.map((referalRecord, index) => (
                <tr
                  key={index}
                  className={`${index % 2 === 0 ? "bg-gray-50" : ""}`}
                  onClick={() => handleRowClick(referalRecord)}
                  style={{ cursor: "pointer" }}
                >
                  <td>{index + 1}</td>
                  <td className="text-center">{referalRecord.team}</td>
                  <td className="text-center">
                    {referalRecord.total_referrals}
                  </td>
                  <td className="text-center">
                    {referalRecord.sales_achieved}
                  </td>
                  <td className="text-center">{referalRecord.site_visits}</td>
                  <td className="text-center">{referalRecord.observation}</td>
                  <td className="text-center">
                    {referalRecord.recommendation}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Render modal based on selectedRowData */}
          {selectedRowData && (
            <Modal
              isOpen={true}
              setIsOpen={setSelectedRowData}
              rowData={selectedRowData}
            />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <ReferalBarChart
          referalData={filteredReferalData}
          selectedMonth={selectedMonth}
        />
      </div>
    </Sidebar>
  );
};

export default ViewReferal;
