import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreateSupplierPayments = () => {
  const [projectData, setProjectData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const navigate = useNavigate();
 
  

  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const response = await fetch("https://workspace.optiven.co.ke/api/suppliers",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }
        );
        const data = await response.json();

        setSupplierData(data);
      } catch (error) {
        console.error("Error fetching supplier data:", error);
      }
    };

    fetchSupplierData();
  }, []);

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const response = await fetch("https://workspace.optiven.co.ke/api/projects/all",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }
        );
        const data = await response.json();

        // Ensure that data is an array before setting the state
        setProjectData(data);
      } catch (error) {
        console.error("Error fetching projects data:", error);
      }
    };

    fetchProjectsData();
  }, []);

  const handleSupplierChange = (supplierId) => {
    const selected = supplierData.find(
      (s) => s.id === parseInt(supplierId, 10)
    );
    setSelectedSupplier(selected || {});
  };

  const validationSchema = Yup.object({
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("Expected end date is required")
      .min(Yup.ref("start_date"), "Expected end date must be after start date"),
     amount:Yup.string().required("Amount is required"),
     invoice:Yup.string().required("Invoice is required"),
     lpo:Yup.string().required("L.P.O number is required")
  });

  const handleSubmit = async (values) => {
    try {
      if (selectedSupplier.id) {
        const response = await axios.post(
          "https://workspace.optiven.co.ke/api/suppliers/payments",
          {
            supplier_id: selectedSupplier.id,
            start_date: values.start_date,
            end_date: values.end_date,
            invoice: values.invoice,
            quantity:"0",
            lpo:values.lpo,
            amount: values.amount || "0",
            status: "pending",
            type: "valueAddition",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("Server response:", response.data);
      }
      toast.success("Payment added successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/view-supplier-payment");
    } catch (error) {
      console.error("Error submitting payment:", error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Sidebar>
        <div className="bg-gray-100 dark:bg-gray-900">
          <div className="w-full max-w-3xl mx-auto p-8">
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md border dark:border-gray-700">
              <h1 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
                Supplier/Service Provider Payment
              </h1>
              <Formik
                initialValues={{
                  supplier_id: "",
                  start_date: "",
                  end_date: "",
                  invoice: "",
                  lpo:"",
                  amount: "",
                  status: "",
                  type:"",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form>
                    <div className="mb-6">
                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="supplier"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Supplier Name
                          </label>
                          <Field
                            as="select"
                            id="supplier"
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                            value={selectedSupplier.id}
                            onChange={(e) =>
                              handleSupplierChange(e.target.value)
                            }
                          >
                            <option value="">Select supplier</option>
                            {supplierData.map((supplier) => (
                              <option key={supplier.id} value={supplier.id}>
                                {supplier.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="supplier"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Project
                          </label>
                          <Field
                            as="select"
                            id="project"
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                            value={selectedSupplier.projectId}
                            readOnly
                          >
                            <option value="">Select a project</option>
                            {projectData.map((project) => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="id_number"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            ID Number
                          </label>
                          <Field
                            type="text"
                            id="id_number"
                            value={selectedSupplier.id_number || ""}
                            readOnly
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="phone"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Contact
                          </label>
                          <input
                            type="text"
                            id="phone"
                            value={selectedSupplier.phoneNumber || ""}
                            readOnly
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2 className="text-xl font-semibold text-gray-700 dark:text-white mb-2">
                        Payment Information
                      </h2>

                      <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
                        <div className="w-44">
                          <label
                            className="label"
                            htmlFor="start_date"
                          >
                            <span className="label-text font-bold">
                              Start Date:{" "}
                            </span>
                          </label>
                          <Field
                            type="date"
                            className="input input-bordered w-full max-w-sm"
                            id="start_date"
                            name="start_date"
                          />
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                        <div className="w-44 ">
                          <label
                            className="label"
                            htmlFor="end_date"
                          >
                            <span className="label-text font-bold">
                              Expected End Date:{" "}
                            </span>
                          </label>
                          <Field
                            type="date"
                            className="input input-bordered w-full max-w-sm"
                            id="end_date"
                            name="end_date"
                          />
                          <ErrorMessage
                            name="end_date"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="supplies"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Supplies
                          </label>
                          <Field
                            as="textarea"
                            type="text"
                            id="supplies"
                            value={selectedSupplier.supplies}
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="lpo"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            L.P.O Number
                          </label>
                          <Field
                            type="text"
                            id="lpo"
                            name ="lpo"
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                          />
                          <ErrorMessage
                            name="lpo"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4 mt-4">
                      <div>
                          <label
                            htmlFor="invoice"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Invoice Number
                          </label>
                          <Field
                            type="text"
                            id="invoice"
                            name ="invoice"
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                          />
                          <ErrorMessage
                            name="invoice"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      <div>
                        <label
                          htmlFor="amount"
                          className="block text-gray-700 dark:text-white mb-1"
                        >
                          Amount
                        </label>
                        <Field
                          type="text"
                          id="amount"
                          name ="amount"
                          className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                        />
                        <ErrorMessage
                            name="amount"
                            component="div"
                            className="text-red-500"
                          />
                      </div>
                    </div>
                    </div>

                    <div className="mt-8 flex justify-end">
                      <button
                        type="submit"
                        className="bg-green-700 text-white px-4 py-2 rounded-lg hover:bg-teal-700 dark:bg-teal-600 dark:text-white dark:hover:bg-teal-900"
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateSupplierPayments;
