import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar";
import _404cat from "../assets/svgs/404-cat.svg";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

const ViewSuppliers = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [projects, setProjects] = useState([]);
  // SEARCHBAR: Initialize the search query to an empty string
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/suppliers",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setSuppliers(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchProjects = async () => {
      try {
        const response = await axios.get("https://workspace.optiven.co.ke/api/projects/all", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSuppliers();
    fetchProjects();
  }, []);

  // SEARCHBAR: First filter the array of items(suppliers) to return an array that does not include the value of the query
  // SEARCHBAR: Memoize the filtered items, to only re-render when the value of the input or suppliers changes
  const filteredSuppliers = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      suppliers.filter((supplier) => {
        if (query === "") {
          return supplier;
        } else if (supplier.name.toLowerCase().includes(query.toLowerCase())) {
          return supplier;
        }
      }),
    [query, suppliers]
  );

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://workspace.optiven.co.ke/api/suppliers/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setSuppliers(suppliers.filter((supplier) => supplier.id !== id));
      toast.success("Supplier deleted!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleNameSort = (columnName) => {
    const sortedSuppliers = [...suppliers].sort((a, b) => {
      if (a[columnName] < b[columnName]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[columnName] > b[columnName]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
    setSuppliers(sortedSuppliers);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <>
      <Sidebar>
        <div className="flex justify-center items-center m-3">
          <input
            placeholder="Search Supplier by Name"
            className="input input-bordered input-success w-full max-w-xs"
            type="text"
            // SEARCHBAR: Create an onChange event which sets the query to whatever the value of the input is when it changes
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <div className="overflow-x-auto m-4">
          {suppliers.length === 0 ? (
            <div className="flex items-center justify-center w-full">
              <div className="flex flex-col items-center p-2 sm:w-1/2 w-full">
                <img
                  src={_404cat}
                  alt="not-found"
                  className="max-w-lg lg:w-1/2"
                />
                <h1 className="text-black font-bold text-center">
                  Wow, such empty! Add some suppliers to get started
                </h1>
              </div>
            </div>
          ) : (
            <table className="table table-compact w-full ">
              <thead>
                <tr className="text-center font-serif ">
                  <th></th>
                  <th>Project(s)</th>
                  <th
                    onClick={() => handleNameSort("name")}
                    className="cursor-pointer"
                  >
                    Name {sortOrder === "asc" ? "▲" : "▼"}
                  </th>
                  <th>Email</th>
                  <th>Id Number</th>
                  <th>Phone Number</th>
                  <th>Location</th>
                  <th>Goods/Services Supplied</th>
                  
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="font-[Poppin] ">
                {filteredSuppliers.map((supplier, i) => (
                  <tr key={supplier.id} className="text-center text-xl">
                    <td>{i + 1}</td>
                    <td>
                      {supplier.projectId && projects.length > 0 ? (
                        <span>
                          {
                            projects.find(
                              (project) => project.id === supplier.projectId
                            ).project_name
                          }
                        </span>
                      ) : (
                        <span>No project found</span>
                      )}
                    </td>
                    <td>{supplier.name ? supplier.name : "N/A"}</td>
                    <td>{supplier.email ? supplier.email : "N/A"}</td>
                    <td>
                      {supplier.id_number ? supplier.id_number : "N/A"}
                    </td>
                    <td>
                      {supplier.phoneNumber ? supplier.phoneNumber : "N/A"}
                    </td>
                    <td>{supplier.location ? supplier.location : "N/A"}</td>
                    <td>{supplier.supplies ? supplier.supplies : "N/A"}</td>
                    
                    <td>
                      <button
                        className="btn btn-warning btn-outline mr-1"
                        onClick={() =>
                          navigate(`/suppliers/${supplier.id}/edit-supplier`)
                        }
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-accent btn-outline ml-1"
                        onClick={() => handleDelete(supplier.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default ViewSuppliers;
