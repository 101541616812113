// Your GeoJSON data goes here
const geojsonData = {
    type: "FeatureCollection",
    
        "features": [
          {
            "type": "Feature",
            "id": "sm796fa65b",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.032757236, 0.003628884],
                  [0.034451468, 0.010043117],
                  [0.034451468, 0.010043117],
                  [0.034408555, 0.010043389],
                  [0.030299407, 0.011512965],
                  [0.028201592, 0.005124952],
                  [0.032757236, 0.003628884]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP1",
            }
          },
          {
            "type": "Feature",
            "id": "sm7f5ab270",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.028201592, 0.005124952],
                  [0.026239261, -0.000850501],
                  [0.031148252, -0.002462607],
                  [0.032757236, 0.003628884],
                  [0.028201592, 0.005124952]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP2",
            }
          },
          {
            "type": "Feature",
            "id": "sma817dbcc",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.026239261, -0.000850501],
                  [0.024432411, -0.0063525],
                  [0.029666753, -0.008071451],
                  [0.031148252, -0.002462607],
                  [0.026239261, -0.000850501]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP3",
            }
          },
          {
            "type": "Feature",
            "id": "sm194e5206",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.024432411, -0.0063525],
                  [0.022689957, -0.01165841],
                  [0.028238054, -0.013480398],
                  [0.029666753, -0.008071451],
                  [0.024432411, -0.0063525]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP19",
            }
          },
          {
            "type": "Feature",
            "id": "sm7ee08798",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.022689957, -0.01165841],
                  [0.021019539, -0.016744963],
                  [0.026961225, -0.018314378],
                  [0.028238054, -0.013480398],
                  [0.022689957, -0.01165841]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP4",
            }
          },
          {
            "type": "Feature",
            "id": "sm5181f217",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.021019539, -0.016744963],
                  [0.019518682, -0.021315192],
                  [0.025637815, -0.023324707],
                  [0.026961225, -0.018314378],
                  [0.021019539, -0.016744963]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP5",
            }
          },
          {
            "type": "Feature",
            "id": "sm423fee22",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.019518682, -0.021315192],
                  [0.018042966, -0.025808864],
                  [0.024427824, -0.027905643],
                  [0.025637815, -0.023324707],
                  [0.019518682, -0.021315192]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP6",
            }
          },
          {
            "type": "Feature",
            "id": "smfe523f6b",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.018042966, -0.025808864],
                  [0.016587956, -0.030436789],
                  [0.016974194, -0.031048332],
                  [0.023475871, -0.031509666],
                  [0.024427824, -0.027905643],
                  [0.018042966, -0.025808864]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP7",
            }
          },
          {
            "type": "Feature",
            "id": "sm16ea64e2",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.008609989, -0.025515884],
                  [0.007275327, -0.030125653],
                  [0.014849885, -0.030769383],
                  [0.015515072, -0.030318772],
                  [0.016297514, -0.02804956],
                  [0.008609989, -0.025515884]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP8",
            }
          },
          {
            "type": "Feature",
            "id": "sm62f4922e",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.016297514, -0.02804956],
                  [0.017482025, -0.024455588],
                  [0.0097945, -0.021921912],
                  [0.008609989, -0.025515884],
                  [0.016297514, -0.02804956]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP9",
            }
          },
          {
            "type": "Feature",
            "id": "sm3fa57acc",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.017482025, -0.024455588],
                  [0.018674444, -0.020837624],
                  [0.010986919, -0.018303948],
                  [0.0097945, -0.021921912],
                  [0.017482025, -0.024455588]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP10",
            }
          },
          {
            "type": "Feature",
            "id": "smaec7501b",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.018674444, -0.020837624],
                  [0.019892595, -0.017141586],
                  [0.01220507, -0.01460791],
                  [0.010986919, -0.018303948],
                  [0.018674444, -0.020837624]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP11",
            }
          },
          {
            "type": "Feature",
            "id": "sm949d5e57",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.019892595, -0.017141586],
                  [0.021064348, -0.013586324],
                  [0.013376824, -0.011052648],
                  [0.01220507, -0.01460791],
                  [0.019892595, -0.017141586]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP12",
            }
          },
          {
            "type": "Feature",
            "id": "sm6af56750",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.021064348, -0.013586324],
                  [0.022250634, -0.00998697],
                  [0.01456311, -0.007453293],
                  [0.013376824, -0.011052648],
                  [0.021064348, -0.013586324]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP13",
            }
          },
          {
            "type": "Feature",
            "id": "sm78bcc6b9",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.022250634, -0.00998697],
                  [0.023480826, -0.006254399],
                  [0.015793302, -0.003720722],
                  [0.01456311, -0.007453293],
                  [0.022250634, -0.00998697]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP14",
            }
          },
          {
            "type": "Feature",
            "id": "smc4ba48b6",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.023480826, -0.006254399],
                  [0.024675901, -0.002628377],
                  [0.016988377, -0.0000947],
                  [0.015793302, -0.003720722],
                  [0.023480826, -0.006254399]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP15",
            }
          },
          {
            "type": "Feature",
            "id": "sm3d10b984",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.024675901, -0.002628377],
                  [0.025849377, 0.000932112],
                  [0.018161853, 0.003465788],
                  [0.016988377, -0.0000947],
                  [0.024675901, -0.002628377]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP16",
            }
          },
          {
            "type": "Feature",
            "id": "sme943654f",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.025849377, 0.000932112],
                  [0.027050134, 0.004575375],
                  [0.01936261, 0.007109051],
                  [0.018161853, 0.003465788],
                  [0.025849377, 0.000932112]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP17",
            }
          },
          {
            "type": "Feature",
            "id": "sme98737bb",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.027050134, 0.004575375],
                  [0.028247649, 0.008208802],
                  [0.020560125, 0.010742478],
                  [0.01936261, 0.007109051],
                  [0.027050134, 0.004575375]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP18",
            }
          },
          {
            "type": "Feature",
            "id": "sm8818dfc0",
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [0.029119915, 0.007921318],
                  [0.030299407, 0.011512965],
                  [0.021797338, 0.014496355],
                  [0.020560125, 0.010742478],
                  [0.029119915, 0.007921318]
                ]
              ]
            },
            "properties": {
                Unit_Number: "KP20",
            }
          },
          {
            "type": "Feature",
            "id": "sm7e1b6795",
            "geometry": {
              "type": "LineString",
              "coordinates": [
                [0.007275327, -0.030125653],
                [0.004174691, -0.043440129],
                [0.004174691, -0.043440129]
              ]
            },
            "properties": {
                
            }
          },
          {
            "type": "Feature",
            "id": "sma14cc9d9",
            "geometry": {
              "type": "LineString",
              "coordinates": [
                [0.005805474, -0.043182637],
                [0.00863789, -0.031509665],
                [0.029988273, -0.032646925]
              ]
            },
            "properties": {
                
            }
          },
          {
            "type": "Feature",
            "id": "smcd8c6d20",
            "geometry": {
              "type": "LineString",
              "coordinates": [
                [0.023475871, -0.031509666],
                [0.030631999, -0.031960281]
              ]
            },
            "properties": {
                
            }
          }
        ],
        


};

export default geojsonData;