import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import formatDate from "../../utils/formatDate";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const formatDateForAPI = (date) => {
  // Format the date for your API endpoint (assuming UTC timezone)
  const formattedDate = new Date(date)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  return formattedDate;
};
 

const ViewMaintenanceSupply = () => {
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");
  const maintenance = accessRoles.includes("maintenance");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");


  const handleDateRangeFilter = async () => {
    const formattedStartDate = formatDateForAPI(start_date);
      const formattedEndDate = formatDateForAPI(end_date);

    try {
      const response = await axios.get(
        "https://workspace.optiven.co.ke/api/suppliers/maintenance-supply-range",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            start_date: formattedStartDate,
            end_date: formattedEndDate,
          },
        }
      );
      console.log(start_date)
      console.log(end_date)
      console.log("payments", response.data);
      setFilteredPayments(response.data);
    } catch (error) {
      console.error("Error filtering payments by date range:", error);
      // Handle error (e.g., show a notification to the user)
    }
  };

  // download by excel
  const handleDownloadExcel = () => {
    // Header row
    const headerRow = [
      "Project",
      "Name",
      "Contact",
      "ID Number",
      "S.Start Date",
      "S.End Date",
      "Supplies",
      "Quantity",
      "Amount",
    ];

    // Data rows
    const data = filteredPayments.map((payment) => [
      payment.project_name,
      payment.supplier_name,
      payment.supplier_phone,
      payment.supplier_id_number,
      formatDate(payment.supplier_payment_start_date),
      formatDate(payment.supplier_payment_end_date),
      payment.supplier_supplies,
      payment.supplier_payment_quantity,
      payment.supplier_payment_amount,
    ]);

    // Add the total amount row
    const totalAmountRow = [
      "Total Amount", // Title for the total amount
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      totalAmount, // Actual total amount value
    ];

    // Combine header row, data rows, and the total amount row
    const completeData = [headerRow, ...data, totalAmountRow];

    const ws = XLSX.utils.aoa_to_sheet(completeData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "FilteredPayments");
    XLSX.writeFile(wb, "filtered_payments.xlsx");
  };
  // handle download pdf
  const dataToPdfRows = (data) => {
    return data.map((payment) => [
      { text: payment.project_name, style: "tableCell" },
      { text: payment.supplier_name, style: "tableCell" },
      { text: payment.supplier_phone, style: "tableCell" },
      { text: payment.supplier_id_number, style: "tableCell" },
      { text: formatDate(payment.supplier_payment_start_date), style: "tableCell" },
      { text: formatDate(payment.supplier_payment_end_date), style: "tableCell" },
      { text: payment.supplier_supplies, style: "tableCell" },
      { text: payment.supplier_payment_quantity, style: "tableCell" },
      { text: payment.supplier_payment_amount, style: "tableCell" },
    ]);
  };
  const handleDownloadPDF = () => {
    const docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      content: [
        {
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*", "*", "*", "*","*"],
            body: [
              [
                { text: "Project Name", style: "tableHeader" },
                { text: "Supplier Name", style: "tableHeader" },
                { text: "Contact", style: "tableHeader" },
                { text: "Id Number", style: "tableHeader" },
                { text: "S.Start Date", style: "tableHeader" },
                { text: "S.End Date", style: "tableHeader" },
                { text: "Supplies", style: "tableHeader" },
                { text: "Quantity", style: "tableHeader" },
                { text: "Total Paid", style: "tableHeader" },
              ],
              ...dataToPdfRows(filteredPayments),
              [
                { text: "Total Payments", style: "tableCell", colSpan: 7 },
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                { text: totalAmount.toFixed(2), style: "tableCell" }, // Display total amount with two decimal places
              ],
            ],
          },
        },
      ],
      styles: {
        tableHeader: {
          bold: true,
          fontSize: 12,
          fillColor: "#CCCCCC",
        },
        tableCell: {
          fontSize: 10,
        },
      },
    };

    pdfMake.createPdf(docDefinition).open(); // Open PDF in a new tab
  };

  //reduce method
  useEffect(() => {
    const total = filteredPayments.reduce(
      (sum, filteredPayment) => sum + filteredPayment.supplier_payment_amount,
      0
    );
    setTotalAmount(total);
  }, [filteredPayments]);
  return (
    <>
      <Sidebar>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
          
          {/* all the payments */}

          <div className="button-gap mb-4">
            <Link to="/view-maintenance-suppliers">
              <button className="btn btn-active mr-4 font-serif">
                All Supppliers
              </button>
            </Link>
          </div>
          <h2 className="text-2xl font-bold mb-4 font-serif">
            Filter Suppliers
          </h2>
          {/* Date Range Filter */}

          <div className="mb-4">
            <label
              htmlFor="startDateFilter"
              className="text-gray-700 px-3  text-xl font-serif "
            >
              Select Start Date:
            </label>
            <input
              type="date"
              id="startDateFilter"
              className="border rounded py-2 px-3  text-xl font-serif"
              value={start_date}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          {/* End Date */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-2">
              <label
                htmlFor="endDateFilter"
                className="text-gray-700 px-3  text-xl font-serif"
              >
                Select End Date:
              </label>
              <input
                type="date"
                id="endDateFilter"
                className="border rounded py-2 px-3  text-xl font-serif"
                value={end_date}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            <div>
              <button
                onClick={handleDateRangeFilter}
                className="bg-blue-500 text-white  text-xl font-serif py-2 px-4 rounded ml-4 sm:ml-0 mt-4 sm:mt-0"
              >
                Filter Suppliers by Date Range
              </button>
            </div>
          </div>

          <div>
            {!operations &&
            (valueAdmin || maintenance || department === "ICT SYSTEMS") ? (
              <button
                onClick={handleDownloadExcel}
                className="bg-green-500 text-white py-3 px-4 rounded mb-4 mr-4"
              >
                Export to Excel
              </button>
            ) : null}
            <button
              onClick={handleDownloadPDF}
              className="bg-red-500 text-white py-2 px-4 rounded mt-4  sm:mt-2"
            >
              Download PDF
            </button>
          </div>

          {/* Display filtered payments */}
          <div className="mt-4">
            <h3 className="text-xl font-bold mb-2 font-serif">
              Filtered Payments
            </h3>
            <table className="table-auto w-full ">
              <thead>
                <tr className="font-serif">
                  <th>#</th>
                  <th className="px-4 py-2">Project</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Contact</th>
                  <th className="px-4 py-2">ID Number</th>
                  <th className="px-4 py-2">S. Date</th>

                  <th className="px-4 py-2">E. Date </th>
                  <th className="px-4 py-2">Supplies</th>
                  <th className="px-4 py-2">Quantity</th>
                  <th className="px-4 py-2">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {filteredPayments.map((payment, index) => (
                  <tr key={payment.id}>
                    <td className="border px-4 py-2">{index + 1}</td>
                    <td className="border px-4 py-2">{payment.project_name}</td>
                    <td className="border px-4 py-2">{payment.supplier_name}</td>
                    <td className="border px-4 py-2">{payment.supplier_phone}</td>
                    <td className="border px-4 py-2">{payment.supplier_id_number}</td>

                    <td className="border px-4 py-2">{formatDate(payment.supplier_payment_start_date)}</td>
                    <td className="border px-4 py-2">{formatDate(payment.supplier_payment_end_date)}</td>
                    <td className="border px-4 py-2">{payment.supplier_supplies}</td>
                    <td className="border px-4 py-2">{payment.supplier_payment_quantity}</td>
                    <td className="border px-4 py-2">{payment.supplier_payment_amount}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="8" className="border px-4 py-2">
                    {/* Leave this cell empty */}
                  </td>
                  <td className="text-right font-bold px-4 py-2 border font-serif">
                    Total Amount:
                  </td>
                  <td
                    colSpan="1"
                    className="border px-4 py-2 font-[Poppin] font-bold text-2xl"
                  >
                    {totalAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default ViewMaintenanceSupply;
