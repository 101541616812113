import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";

const ApproveRequisition = () => {
  const userId = useSelector((state) => state.user.user.user_id);
  const [requisitions, setRequisitions] = useState([]);

 
  const handleApprove = async (id) => {
    try {
      await axios.put(
        `https://workspace.optiven.co.ke/api/requisitions/${id}/pending`,
        { status: "approved" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Filter out the approved requisition
      setRequisitions((prevRequisitions) =>
        prevRequisitions.filter((req) => req.id !== id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleReject = async (id) => {
    try {
      await axios.put(
        `https://workspace.optiven.co.ke/api/requisitions/${id}/reject`,
        { status: "Rejected" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // Filter out the rejected requisition
      setRequisitions((prevRequisitions) =>
        prevRequisitions.filter((req) => req.id !== id)
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchRequisition = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/requisitions/pending`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
  
          }
        );
        setRequisitions(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRequisition();
  }, [userId]);

  return (
    <Sidebar>
      <div className="flex justify-center items-center m-3 font-serif">
        <table className="table table-compact w-3/4 table-zebra">
          <thead>
            <tr className="text-center text-2xl">
              <th></th>
              <th>Commodity Name</th>
              <th>Quantity</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="">
            {requisitions.map((requisition, i) => (
              <tr key={requisition.id} className="text-center">
                <td className="text-lg">{i + 1}</td>
                <td className="text-lg">{requisition.commodity_name}</td>
                <td className="text-lg">{requisition.quantity}</td>
                <td className="text-lg">{requisition.description}</td>
                <td className="font-bold text-xl">{requisition.status}</td>

                <td className="flex items-center justify-center space-x-2">
                  <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleApprove(requisition.id)}
                  >
                    Approve
                  </button>

                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleReject(requisition.id)}
                  >
                    Reject
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Sidebar>
  );
};

export default ApproveRequisition;
