import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EventBooking() {
  const [formData, setFormData] = useState({
    purpose: "",
    venue: "",
    date: "",
    description: "",
    selectedUsers: [],
  });
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.user.user_id);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch("https://workspace.optiven.co.ke/api/users");
      const data = await response.json();
      // console.log("Fetched Users:", data); // Debug log
      const userOptions = data.map((user) => ({
        value: user.user_id,
        label: user.fullnames,
      }));
      setUsers([
        { value: "select_all", label: "Select All Users" },
        ...userOptions,
      ]);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUserSelection = (selectedOptions) => {
    if (selectedOptions.find((option) => option.value === "select_all")) {
      setFormData({
        ...formData,
        selectedUsers: users.filter((user) => user.value !== "select_all"),
      });
    } else {
      setFormData({ ...formData, selectedUsers: selectedOptions });
    }
    // console.log("Selected Users:", selectedOptions);  // Debug log
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const bookingData = {
      ...formData,
      user_id: userId,
      selectedUsers: formData.selectedUsers.map((user) => user.value), // Ensure IDs are sent
    };

    console.log("Booking Data:", bookingData); // Debug log

    try {
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/event-bookings",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success("Event booked successfully!");
        navigate("/booking-summary", {
          state: { ...bookingData, bookingId: data.bookingId },
        });
      } else {
        const errorData = await response.json();
        toast.error(`Error booking event: ${errorData.message}`);
        console.error("Error booking event:", errorData.message);
      }
    } catch (error) {
      toast.error(`Error booking event: ${error.message}`);
      console.error("Error booking event:", error);
    }
  };

  const today = new Date().toISOString().split("T")[0];

  return (
    <Sidebar>
      <section className="bg-white">
        <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
          <aside className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
            <img
              alt=""
              src="https://images.unsplash.com/photo-1552664688-cf412ec27db2?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="absolute inset-0 h-full w-full object-cover"
            />
          </aside>

          <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
            <div className="max-w-xl lg:max-w-3xl">
              <h1 className="mt-6 text-2xl font-bold text-indigo-600 sm:text-3xl md:text-4xl">
                Event Booking
              </h1>

              <p className="mt-4 leading-relaxed text-gray-500">
                Schedule your events seamlessly. Fill in the details below to
                book a venue.
              </p>

              <form
                onSubmit={handleSubmit}
                className="mt-6 grid grid-cols-6 gap-6"
              >
                <div className="col-span-6">
                  <label
                    htmlFor="purpose"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Purpose
                  </label>
                  <input
                    type="text"
                    name="purpose"
                    placeholder="Purpose"
                    value={formData.purpose}
                    onChange={handleChange}
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 h-12"
                    required
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="venue"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Venue
                  </label>
                  <input
                    type="text"
                    name="venue"
                    placeholder="Venue"
                    value={formData.venue}
                    onChange={handleChange}
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 h-12"
                    required
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="date"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    min={today}
                    value={formData.date}
                    onChange={handleChange}
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 h-12"
                    required
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    name="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleChange}
                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 h-24"
                    required
                  />
                </div>

                <div className="col-span-6">
                  <label
                    htmlFor="users"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select Users
                  </label>
                  <Select
                    isMulti
                    name="users"
                    options={users}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleUserSelection}
                    value={formData.selectedUsers}
                    placeholder="Select users..."
                  />
                </div>

                <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                  <button
                    type="submit"
                    className="inline-block shrink-0 rounded-md border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
                  >
                    Book Now
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
      </section>
    </Sidebar>
  );
}

export default EventBooking;
