import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";

const CreateCasualLabourers = () => {
  const [labour, setLabour] = useState([]);
  const [projects, setProjects] = useState([]);
  const [labourOptions, setLabourOptions] = useState([
    { value: "", label: "" },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLabour = async () => {
      try {
        const response = await axios.get("https://workspace.optiven.co.ke/api/labour", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setLabour(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchProjectsData = async () => {
      try {
        const response = await fetch("https://workspace.optiven.co.ke/api/projects/all",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        },
        );
        const data = await response.json();
        console.log(data);

        
        setProjects(data);
      } catch (error) {
        console.error("Error fetching projects data:", error);
      }
    };

    fetchLabour();
    fetchProjectsData();
  }, []);

  useEffect(() => {
    const options = labour.map((s) => ({
      value: s.id,
      label: s.labour_type,
    }));
    setLabourOptions([{ value: "", label: "" }, ...options]);
  }, [labour]);

  const projectOptions = [];

  // Map through the projects and attach the values to projectOptions array
  projects.map((project) =>
    projectOptions.push({
      value: project.id,
      label: project.project_name,
    })
  );

  const initialValues = {
    name: "",
    phone: "",
    id_number: "",
    start_date: "",
    end_date: "",
    labour_id: "",
    work_description: "",
    project_id: "",
    status:""
  };

  const validationSchema = Yup.object({
    project_id: Yup.number()
    .oneOf(projectOptions.map((option) => option.value))
    .required("Select a project"),
    name: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .max(255, "Exceeded the characters limit")
      .required("Name is required"),
    phone: Yup.string()
      .min(10, "Enter a valid phone number in the format 07XXXXXX")
      .max(10, "Enter a valid phone number in the format 07XXXXXX"),
    id_number: Yup.string().required("Id number required"),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("Expected end date is required")
      .min(Yup.ref("start_date"), "Expected end date must be after start date"),

    labour_id: Yup.number()
      .oneOf(labourOptions.map((option) => option.value))
      .required("Please labour type"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const token = localStorage.getItem("token"); // Fetch the token
  
    // Check if token is available
    if (!token) {
      toast.error("Authorization token not found. Please log in again.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSubmitting(false);
      return;
    }
  
    try {
      const response = await axios.post(
        "https://workspace.optiven.co.ke/api/casual-labourers",
        {
          name: values.name,
          phone: values.phone,
          id_number: values.id_number,
          labour_id: values.labour_id,
          start_date: values.start_date,
          end_date: values.end_date,
          work_description: values.work_description,
          project_id: values.project_id,
          status: "active",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Ensure the token is correctly formatted
          },
        }
      );
      console.log(response.data);
      toast.success("Casual Labourer added successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/view-casual-labourer");
    } catch (error) {
      console.error(error.response.data);
      toast.error(error.response?.data?.message || "An error occurred", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setSubmitting(false);
  };
  
  
  return (
    <>
      <Sidebar>
        <div className="hero min-h-screen">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form className="form-control w-full max-w-sm">
              <label htmlFor="project" className="label">
                <span className="label-text font-bold">Project</span>
              </label>
              <Field
                as="select"
                id="project_id"
                name="project_id"
                className="select select-bordered"
                // disable if there's no projects so that we don't get null data
                disabled={projectOptions.length === 0}
             
              >
                <option value="" disabled>
                  Select a Project
                </option>
                {projectOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="project_id"
                component="div"
                className="text-red-500 font-bold text-sm mt-2"
              />
              {/* display if there's no projects to add the supplier to */}
              {projectOptions.length === 0 && (
                <div className="text-red-500 font-bold text-sm mt-2">
                  No projects available. Add a project in order to proceed
                  adding suppliers to it
                </div>
              )}
                <label htmlFor="name" className="label">
                  <span className="label-text font-bold">Name</span>
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="John Smith"
                  className="input input-bordered w-full max-w-sm"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="phone" className="label">
                  <span className="label-text font-bold">Phone Number</span>
                </label>
                <Field
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="07XXXXXXXX"
                  className="input input-bordered w-full max-w-sm"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="id_number" className="label">
                  <span className="label-text font-bold">ID Number</span>
                </label>
                <Field
                  type="id_number"
                  id="id_number"
                  name="id_number"
                  placeholder="23456789"
                  className="input input-bordered w-full max-w-sm"
                />
                <ErrorMessage
                  name="id_number"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />
                <div className="lg:flex justify-between">
                  <div className="w-44">
                    <label className="label" htmlFor="start_date">
                      <span className="label-text font-bold">Start Date: </span>
                    </label>
                    <Field
                      type="date"
                      className="input input-bordered w-full max-w-sm"
                      id="start_date"
                      name="start_date"
                    />
                    <ErrorMessage
                      name="start_date"
                      component="div"
                      className="text-red-500 font-bold text-sm mt-2"
                    />
                  </div>
                  <div className="w-44 ">
                    <label className="label" htmlFor="end_date">
                      <span className="label-text font-bold">
                        Expected End Date:{" "}
                      </span>
                    </label>
                    <Field
                      type="date"
                      className="input input-bordered w-full max-w-sm"
                      id="end_date"
                      name="end_date"
                    />
                    <ErrorMessage
                      name="end_date"
                      component="div"
                      className="text-red-500 font-bold text-sm mt-2"
                    />
                  </div>
                </div>

                <label htmlFor="labour_id" className="label">
                  <span className="label-text font-bold">Labour</span>
                </label>
                <Field
                  as="select"
                  id="labour_id"
                  name="labour_id"
                  className="select select-bordered"
                >
                  <option value= "" disabled>
                   Select Labour type
                  </option>
                  {labourOptions.slice(1).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="labour_id"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />
                <label htmlFor="work_description" className="label">
                  <span className="label-text font-bold">Work Description</span>
                </label>
                <Field
                  as="textarea"
                  id="work_description"
                  name="work_description"
                  placeholder="This went as expected"
                  className="textarea textarea-bordered w-full max-w-sm h-24"
                />
                <ErrorMessage
                  name="work_description"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <button
                  type="submit"
                  id="submit"
                  className="btn btn-primary w-full max-w-xs mt-4 text-white"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting
                    ? "Submitting..."
                    : "Add Casual Labourer"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateCasualLabourers;
