import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryTooltip,
} from "victory";


const CustomLabel = ({ text, x, y, style }) => {
  return (
    <p x={x} y={y} textAnchor="middle" style={style}>
      {text}
    </p>
  );
};

function formatCurrency(num) {
  if (typeof num !== "number" || isNaN(num)) {
    // Handle the case where num is not a valid number
    return "Invalid";
  }

  return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}
const Summary = () => {
  const [totalCosting, setTotalCosting] = useState([]);
  const [query, setQuery] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const costResponse = await axios.get("https://workspace.optiven.co.ke/api/value-additions/cost", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        ;
        console.log(costResponse.data);

        // Do something with the response data, such as setting state
        setTotalCosting(costResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);
  console.log("Data for totalCOSTING",totalCosting)
  const chartData = totalCosting.map((totalCost) => ({
    project_name: totalCost.project_name,
    total_amount: totalCost.total_amount,
  }));

  // get the total cost of all value additions
  // Extract total_value_addition_budget values from totalCosting array
  const valueAdditionBudgets = totalCosting.map((data) =>
    parseFloat(data.total_value_addition_budget)
  );

  // Sum up the total_value_addition_budget values
  const totalValueAdditionBudgetSum = valueAdditionBudgets.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue;
    },
    0
  );
  console.log(totalValueAdditionBudgetSum);

  // total project maintenance
  const totalProjectMaintenance = totalCosting.map((data) =>
    parseInt(data.total_payments || 0)
  );
  const totalProjectMaintenanceSum = totalProjectMaintenance.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue;
    },
    0
  );
  console.log(totalProjectMaintenanceSum);
  // filter projects
  const filteredProject = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      totalCosting.filter((data) => {
        if (query === "") {
          return data;
        } else if (
          data.project_name.toLowerCase().includes(query.toLowerCase())
        ) {
          return data;
        }
      }),
    [query, totalCosting]
  );
console.log(filteredProject)
  return (
    <Sidebar>
      <section className="text-gray-600 body-font bg-base-200 min-h-screen">
        <div className="flex justify-center items-center m-5">
          <input
            placeholder="Search Project by Name"
            className="input input-bordered input-success w-full max-w-xs"
            type="text"
            // SEARCHBAR: Create an onChange event which sets the query to whatever the value of the input is when it changes
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="grid gap-4 lg:gap-8 md:grid-cols-3 p-8 pt-5">
          {filteredProject.slice(0, 3).map((totalCost,index) => (
            <div key={index} className="relative p-6 rounded-2xl bg-white shadow dark:bg-gray-800">
              <div className="space-y-2">
                <div  className="flex items-center space-x-2 rtl:space-x-reverse text-4xl font-extrabold text-gray-500 dark:text-gray-200 font-serif">
                  <span >{totalCost.project_name}</span>
                </div>

                <div className="">
                  <span className="text-xl font-serif font-extrabold sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Value Additions Cost:
                  </span>
                  <span className="font-[Poppin] text-2xl sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    {formatCurrency(parseInt(totalCost.total_value_addition_budget))}
                  </span>
                </div>

                <div className="">
                  <span className="flex items-center space-x-1 rtl:space-x-reverse text-base font-serif font-extrabold text-green-600 sm:text-sm md:text-base lg:text-lg xl:text-xl">
                    V. A & Maintenance Cost:
                    <span className="font-[Poppin] text-base sm:text-sm md:text-base lg:text-lg xl:text-xl">
                      {formatCurrency(parseInt(totalCost.total_payments || 0))}
                    </span>
                  </span>
                </div>
                <div className="">
                  <span className="flex items-center space-x-1 rtl:space-x-reverse text-base font-serif font-extrabold text-green-600 sm:text-sm md:text-base lg:text-lg xl:text-xl">
                    Supplier Cost:
                    <span className="font-[Poppin] text-base sm:text-sm md:text-base lg:text-lg xl:text-xl">
                      {formatCurrency(parseInt(totalCost.total_supplier_payments || 0))}
                    </span>
                  </span>
                </div>
                <div className="">
                  <span className="flex items-center space-x-1 rtl:space-x-reverse text-lg font-serif font-extrabold sm:text-base md:text-lg lg:text-xl xl:text-2xl">
                    Total Cost:
                    <span className="font-[Poppin] text-lg sm:text-base md:text-lg lg:text-xl xl:text-2xl">
                      {formatCurrency(parseInt(totalCost.total_amount))}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="container mx-auto flex flex-col md:flex-row">
          <div className="xl:w-3/4 md:w-full p-2">
            <div className="xl:w-3/4 md:w-full p-2">
              <div className="card bg-base-100 shadow-xl">
                <div className="m-4">
                  <CustomLabel
                    text="Top Projects Summary"
                    x={30}
                    y={30}
                    style={{ fontSize: 20, textAlign: "center" }}
                  />
                </div>
                <VictoryChart
                  width={600}
                  theme={VictoryTheme.material}
                  domainPadding={20}
                  padding={{ top: 50, bottom: 80, left: 80, right: 50 }}
                >
                  <VictoryAxis
                    tickValues={chartData.map((data, index) => index)}
                    tickFormat={chartData.map((data) => data.project_name)}
                    style={{
                      tickLabels: {
                        angle: -45,
                        textAnchor: "end",
                        fontSize: 8,
                      },
                    }}
                  />
                  <VictoryAxis dependentAxis tickCount={5}/>

                  <VictoryBar
                    data={chartData}
                    x="project_name"
                    y={(datum) => parseInt(datum.total_amount)}
                    labels={({ datum }) => parseFloat(datum.total_amount)}
                    labelComponent={<VictoryTooltip />}
                    barWidth={20}
                  />
                </VictoryChart>
              </div>
            </div>
          </div>
          <div className="xl:w-1/3 md:w-full p-4">
            <div className="card bg-base-100 shadow-xl">
              <div className="m-4">
                <CustomLabel
                  text="Total Projects"
                  x={30}
                  y={30}
                  style={{ fontSize: 20, textAlign: "center" }}
                />
                <div className="font-bold text-7xl text-center">
                  {totalCosting.length}
                </div>
              </div>
            </div>
            <div className="card bg-base-100 shadow-xl mt-6">
              <div className="m-4">
                <CustomLabel
                  text="Total Value Addition Cost"
                  x={30}
                  y={30}
                  style={{ fontSize: 20, textAlign: "center" }}
                />
                <div className="font-bold text-center sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl">
                  {formatCurrency(parseInt(totalValueAdditionBudgetSum))}
                </div>
              </div>
            </div>
            <div className="card bg-base-100 shadow-xl mt-5">
              <div className="m-4">
                <CustomLabel
                  text="Total Project Maintenance Cost"
                  x={30}
                  y={30}
                  style={{ fontSize: 17.5, textAlign: "center" }}
                />
                <div className="font-bold text-center sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl">
                  {formatCurrency(parseInt(totalProjectMaintenanceSum))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sidebar>
  );
};
export default Summary;
