import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import logo from "../../../assets/optiven-logo-full.png";
// import AOS from "aos";;
import "aos/dist/aos.css";
import kitale from "../videos/david-clode-jJWBM15E1AA-unsplash.jpg";

const Laikipia = () => {
  // Scroll to the top when the component is mounted
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  const [likedMaps, setLikedMaps] = useState([]);
  const [MapsData, setMapsData] = useState([
    {
      id: 13,
      title: "Vuyanzi Gardens - Kitale",
      imageUrl:
        "https://www.optiven.co.ke/wp-content/uploads/2023/12/tulivugardenskonza.jpeg",
      description:
        "A Serene Haven in the Heart of Kitale Metropolis.Seize this golden opportunity to safeguard your future in the flourishing heart of the Western region, the dynamic urban center, and the esteemed headquarters of Trans Nzoia County.",
      rating: "⭐⭐⭐⭐",
      features: [
        "Internal Murram-Compacted Roads",
        "Mesh Fence",
        "Electricity in Neighbourhood",
        "Water from Kibirichia Project within Neighbourhood",
      ],
      link: "/vuyanzi-g",
    },
  ]);
  const handleHeartClick = (mapId) => {
    if (likedMaps.includes(mapId)) {
      // Unlike the map
      setLikedMaps(likedMaps.filter((id) => id !== mapId));
    } else {
      // Like the map and move it to the top
      setLikedMaps([mapId, ...likedMaps]);
      const updatedMapData = MapsData.slice();
      const likedMapIndex = updatedMapData.findIndex((map) => map.id === mapId);
      if (likedMapIndex !== -1) {
        const [likedMap] = updatedMapData.splice(likedMapIndex, 1);
        updatedMapData.unshift(likedMap);
        setMapsData(updatedMapData);
      }
    }
  };
  const Badge = ({ text = "" }) => (
    <div className="badge" style={{ backgroundColor: "#40a347" }}>
      {text}
    </div>
  );
  return (
    <div>
      <div className="navbar bg-primary">
        <Link className="btn btn-ghost normal-case text-xl" to="/map-home">
          <img src={logo} alt="logo" className="w-40" />
        </Link>
      </div>
      {/* Heading for Properties in Kilifi County */}

      <div style={{ position: "relative" }}>
        <img
          src={kitale}
          alt="Trans Nzoia County"
          style={{
            width: "100%", // Default width for smaller screens
            maxHeight: "300px", // Maximum height for larger screens (adjust as needed)
            height: "auto",
            display: "block",
            margin: "0 auto", // Center the image horizontally
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: "80%", // Adjust the width of the text overlay
          }}
        >
          <div
            className="text-3xl font-bold text-center mt-6 mb-8"
            style={{ color: "#7bd34e" }}
          >
            <h1>Tranquil Trans Nzoia: Serene Living in Kenya's Hidden Gem!</h1>
          </div>
        </div>
      </div>
      <div class="text-center">
        <p className="px-4 text-gray-600">
          Immerse yourself in lavish living surrounded by untouched nature in
          Trans Nzoia County. Our initiatives provide luxury and excitement in
          the heart of Kenya's hidden paradise.
        </p>
      </div>
      <h1
        className="text-3xl font-bold text-center mt-6 mb-8"
        style={{ color: "#01796f" }}
      >
        Active Projects in Trans Nzoia County
      </h1>
      {/* Cards */}
      <div className="flex flex-wrap justify-center">
        {MapsData.map((map) => (
          <div
            className={`relative flex w-full max-w-[26rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg m-4 ${
              likedMaps.includes(map.id) ? "top-0" : ""
            }`}
            key={map.id}
            style={{ boxShadow: "0px 0px 20px rgba(0, 128, 0, 0.4)" }}
          >
            {/* Add this div to create the design effect */}
            <div
              className="absolute w-20 h-20 rounded-full bg-green-00 bg-clip-border z-0 left-4 -top-12 animate-ping"
              style={{ boxShadow: "0px 0px 20px rgba(0, 128, 0, 0.4)" }}
            ></div>

            {/* Add this div to create another design effect 
    <div
      className="absolute w-5 h-5 rounded-full bg-blue-900 bg-clip-border z-0 left-36 -top-12 animate-ping"
      style={{ boxShadow: "0px 0px 20px rgba(0, 128, 0, 0.4)" }}
    ></div>*/}
            <div className="relative mx-4 mt-4 overflow-hidden text-white shadow-lg rounded-xl bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40">
              <img src={map.imageUrl} alt={map.title} />
              <div className="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-tr from-transparent via-transparent to-black/60"></div>
              <button
                className={`!absolute top-4 right-4 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-full text-center align-middle font-sans text-xs font-medium uppercase transition-all focus:outline-none ${
                  likedMaps.includes(map.id) ? "text-red-500" : "text-gray-400"
                }`}
                type="button"
                onClick={() => handleHeartClick(map.id)}
                aria-label="Favorite"
              >
                <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="w-6 h-6"
                  >
                    <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"></path>
                  </svg>
                </span>
              </button>
            </div>
            <div className="p-6">
              <div className="flex items-center justify-between mb-3">
                <h5 className="block font-sans text-xl antialiased font-medium leading-snug tracking-normal text-blue-gray-900">
                  {map.title}
                  {map.id === 13 && <Badge text="Latest Property🏖" />}
                  {map.id === 2 && <Badge text="Going Fast🔥" />}
                  {/*add badges here*/}
                </h5>
                <p className="flex items-center gap-1.5 font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased">
                  {map.rating}
                </p>
              </div>
              <p className="block font-sans text-base antialiased font-light leading-relaxed text-gray-700">
                {map.description}
              </p>
              <div className="inline-flex flex-wrap items-center gap-3 mt-8 group">
                {map.features.map((feature, idx) => (
                  <div
                    key={idx}
                    data-tooltip-target={feature}
                    className={`cursor-pointer rounded-full border ${
                      likedMaps.includes(map.id)
                        ? "border-red-500"
                        : "border-green-500"
                    }/5 bg-green-500/5 p-3 text-green-500 transition-colors hover:border-green-500/10 hover:bg-green-500/10 hover:!opacity-100 group-hover:opacity-70`}
                  >
                    {feature}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col my-4">
              <div className="flex mt-4 justify-center">
                <Link
                  to={map.link}
                  className="btn btn-primary text-white"
                  type="button"
                  data-ripple-light="true"
                  style={{
                    width: "200px",
                    marginRight: "20px",
                    marginLeft: "15px",
                  }}
                >
                  View Map
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* footer*/}
      <div
        className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full md:px-24 lg:px-8"
        style={{ backgroundColor: "#98e695" }}
      >
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <a
              href="/map-home"
              aria-label="Go home"
              title="Company"
              className="inline-flex items-center"
            >
              <div className="flex justify-center text-teal-600 dark:text-teal-300">
                <img src={logo} alt="Your Logo" width="300" height="300" />
              </div>
            </a>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm text-gray-800">
                Optiven Group stands as a prominent entity in the African real
                estate sector, dedicated to empowering property investors and
                effecting positive societal transformation.
              </p>
              <p className="mt-4 text-sm text-gray-800">
                At the core of our Real Estate division lies our flagship
                product: value-added plots meticulously tailored to facilitate
                immediate residential settlement, serve commercial purposes, and
                promise substantial future capital appreciation.
              </p>
              <p className="mt-4 text-sm text-gray-800">
                Discover prime plots available for sale in strategic locations
                including Kiambu, Malindi, Thika (Gatanga Road), Machakos,
                Kitengela, Nyeri, Nanyuki, Kisumu, Vipingo, Naro Moru, Malindi,
                Konza, and Kajiado.
              </p>
            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-base font-bold tracking-wide text-gray-900">
              Contacts
            </p>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                />
              </svg>
              <p className="mr-1 text-gray-800">Phone:</p>
              <a
                href="tel:850-123-5021"
                aria-label="Our phone"
                title="Our phone"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                +254 790 300300 / +254 723 400500
              </a>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
                />
              </svg>

              <p className="mr-1 text-gray-800">Email:</p>
              <a
                href="mailto:info@lorem.mail"
                aria-label="Our email"
                title="Our email"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                info@optiven.co.ke
              </a>
            </div>
            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Head Office: Absa Towers Loita Street , 2nd Floor
              </a>
            </div>

            <div className="flex">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <a
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
                className="transition-colors duration-300 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                Optiven Global Office: Zamani Business Park , Karen
              </a>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide text-gray-900">
              Social
            </span>
            <div className="flex items-center mt-1 space-x-3">
              <a
                href="https://twitter.com/OptivenLimited"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/optivenlimited/"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/Optivenlimited/"
                className="text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
          <p className="text-sm text-gray-600">
            © Copyright 2023 Optiven Limited. All rights reserved.
          </p>
          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            <li>
              <a
                href="https://www.optiven.co.ke/privacy-policy/"
                className="text-sm text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Laikipia;
