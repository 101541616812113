// Your GeoJSON data goes here
const geojsonData = {
  type: "FeatureCollection",

  features: [
    {
      geometry: {
        coordinates: [
          [
            [-73.99130503, 40.64203888],
            [-73.99166802, 40.64203369],
            [-73.99165316, 40.64143464],
            [-73.99130447, 40.64170126],
            [-73.99130503, 40.64203888],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN21",
      },
      type: "Feature",
      id: "sm1d49c26e",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99184764, 40.64927353],
            [-73.99131709, 40.64927404],
            [-73.99131787, 40.64974003],
            [-73.99138493, 40.6497848],
            [-73.99185967, 40.64975834],
            [-73.99184764, 40.64927353],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN01",
      },
      type: "Feature",
      id: "sm43f9c109",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131709, 40.64927404],
            [-73.99184764, 40.64927353],
            [-73.99183991, 40.6489621],
            [-73.99131657, 40.64896261],
            [-73.99131709, 40.64927404],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN02",
      },
      type: "Feature",
      id: "smd86868a0",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131657, 40.64896261],
            [-73.99183991, 40.6489621],
            [-73.99183178, 40.64863463],
            [-73.99131602, 40.64863513],
            [-73.99131657, 40.64896261],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN03",
      },
      type: "Feature",
      id: "sm8a51db9f",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131602, 40.64863513],
            [-73.99183178, 40.64863463],
            [-73.9918237, 40.64830893],
            [-73.99131548, 40.64830942],
            [-73.99131602, 40.64863513],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN04",
      },
      type: "Feature",
      id: "sm496d0f30",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131548, 40.64830942],
            [-73.9918237, 40.64830893],
            [-73.99181527, 40.64796903],
            [-73.99131491, 40.64796951],
            [-73.99131548, 40.64830942],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN05",
      },
      type: "Feature",
      id: "sm506f4435",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131491, 40.64796951],
            [-73.99181527, 40.64796903],
            [-73.99180689, 40.64763125],
            [-73.99131435, 40.64763172],
            [-73.99131491, 40.64796951],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN06",
      },
      type: "Feature",
      id: "smc83810fd",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131435, 40.64763172],
            [-73.99180689, 40.64763125],
            [-73.99179831, 40.64728527],
            [-73.99131377, 40.64728574],
            [-73.99131435, 40.64763172],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN07",
      },
      type: "Feature",
      id: "smd3a742cf",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131369, 40.64723908],
            [-73.99179715, 40.64723861],
            [-73.99178836, 40.64688436],
            [-73.9913131, 40.64688482],
            [-73.99131369, 40.64723908],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN08",
      },
      type: "Feature",
      id: "sm11903e1a",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.9913131, 40.64688482],
            [-73.99178836, 40.64688436],
            [-73.99177968, 40.64653438],
            [-73.99131252, 40.64653483],
            [-73.9913131, 40.64688482],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN09",
      },
      type: "Feature",
      id: "sm8d1090a0",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131252, 40.64653483],
            [-73.99177968, 40.64653438],
            [-73.99177069, 40.64617216],
            [-73.99131192, 40.6461726],
            [-73.99131252, 40.64653483],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN10",
      },
      type: "Feature",
      id: "sm64230e06",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131192, 40.6461726],
            [-73.99177069, 40.64617216],
            [-73.9917614, 40.64579772],
            [-73.99131129, 40.64579815],
            [-73.99131192, 40.6461726],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN11",
      },
      type: "Feature",
      id: "sm663ef765",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131129, 40.64579815],
            [-73.9917614, 40.64579772],
            [-73.99175206, 40.64542121],
            [-73.99131066, 40.64542164],
            [-73.99131129, 40.64579815],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN12",
      },
      type: "Feature",
      id: "sm05509da6",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99131066, 40.64542164],
            [-73.99175206, 40.64542121],
            [-73.99174252, 40.64503657],
            [-73.99131002, 40.64503698],
            [-73.99131066, 40.64542164],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN13",
      },
      type: "Feature",
      id: "smc4e9847e",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99130994, 40.64498615],
            [-73.99174126, 40.64498574],
            [-73.99173156, 40.64459494],
            [-73.99130929, 40.64459534],
            [-73.99130994, 40.64498615],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN14",
      },
      type: "Feature",
      id: "smbe5b88f1",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99130929, 40.64459534],
            [-73.99173156, 40.64459494],
            [-73.99172167, 40.64419619],
            [-73.99130862, 40.64419659],
            [-73.99130929, 40.64459534],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN15",
      },
      type: "Feature",
      id: "sm45f1d22c",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99130862, 40.64419659],
            [-73.99172167, 40.64419619],
            [-73.99171137, 40.6437809],
            [-73.99130793, 40.64378129],
            [-73.99130862, 40.64419659],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN16",
      },
      type: "Feature",
      id: "sm0beb7120",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99130793, 40.64378129],
            [-73.99171137, 40.6437809],
            [-73.99170107, 40.64336575],
            [-73.99130724, 40.64336613],
            [-73.99130793, 40.64378129],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN17",
      },
      type: "Feature",
      id: "sm3b7b60c6",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99130724, 40.64336613],
            [-73.99170107, 40.64336575],
            [-73.99169032, 40.6429323],
            [-73.99130652, 40.64293267],
            [-73.99130724, 40.64336613],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN18",
      },
      type: "Feature",
      id: "sme828ad09",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99130652, 40.64293267],
            [-73.99169032, 40.6429323],
            [-73.99167936, 40.64249062],
            [-73.99130578, 40.64249097],
            [-73.99130652, 40.64293267],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN19",
      },
      type: "Feature",
      id: "sm5432e0b9",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99130578, 40.64249097],
            [-73.99167936, 40.64249062],
            [-73.99166802, 40.64203369],
            [-73.99130503, 40.64203888],
            [-73.99130578, 40.64249097],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN20",
      },
      type: "Feature",
      id: "sm0ed54cc5",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99059748, 40.64929726],
            [-73.99058566, 40.64984584],
            [-73.99105104, 40.64981225],
            [-73.99111542, 40.64975527],
            [-73.99111503, 40.64930368],
            [-73.99059748, 40.64929726],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN41",
      },
      type: "Feature",
      id: "sm2fc3b4cc",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111503, 40.64930368],
            [-73.99111473, 40.64895195],
            [-73.99060505, 40.64894563],
            [-73.99059748, 40.64929726],
            [-73.99111503, 40.64930368],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN40",
      },
      type: "Feature",
      id: "sm5667df1c",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111473, 40.64895195],
            [-73.99111445, 40.64862065],
            [-73.99061205, 40.6486209],
            [-73.99060505, 40.64894563],
            [-73.99111473, 40.64895195],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN39",
      },
      type: "Feature",
      id: "smea6b2661",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111445, 40.64862065],
            [-73.99111417, 40.64829081],
            [-73.99061929, 40.64828467],
            [-73.99061205, 40.6486209],
            [-73.99111445, 40.64862065],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN38",
      },
      type: "Feature",
      id: "smb519e5ef",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111412, 40.648234],
            [-73.99111384, 40.64790235],
            [-73.99062766, 40.64789632],
            [-73.99062038, 40.64823424],
            [-73.99111412, 40.648234],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN37",
      },
      type: "Feature",
      id: "sm9dcd45b6",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111384, 40.64790235],
            [-73.99111355, 40.64755833],
            [-73.99063494, 40.64755857],
            [-73.99062766, 40.64789632],
            [-73.99111384, 40.64790235],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN36",
      },
      type: "Feature",
      id: "smba40b736",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111355, 40.64755833],
            [-73.99111325, 40.64721032],
            [-73.99064244, 40.64721055],
            [-73.99063494, 40.64755857],
            [-73.99111355, 40.64755833],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN35",
      },
      type: "Feature",
      id: "sm21bd9db2",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111325, 40.64721032],
            [-73.99111295, 40.64685997],
            [-73.99065012, 40.64685423],
            [-73.99064244, 40.64721055],
            [-73.99111325, 40.64721032],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN34",
      },
      type: "Feature",
      id: "smba3a78de",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111295, 40.64685997],
            [-73.99111263, 40.64648995],
            [-73.99065796, 40.64649017],
            [-73.99065012, 40.64685423],
            [-73.99111295, 40.64685997],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN33",
      },
      type: "Feature",
      id: "sm498ea8b4",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111263, 40.64648995],
            [-73.99111232, 40.64612366],
            [-73.99066585, 40.64612388],
            [-73.99065796, 40.64649017],
            [-73.99111263, 40.64648995],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN32",
      },
      type: "Feature",
      id: "sm91575221",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111232, 40.64612366],
            [-73.991112, 40.64574922],
            [-73.99067392, 40.64574943],
            [-73.99066585, 40.64612388],
            [-73.99111232, 40.64612366],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN31",
      },
      type: "Feature",
      id: "smd99b2ea5",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.991112, 40.64574922],
            [-73.99111167, 40.64536051],
            [-73.99068229, 40.64536072],
            [-73.99067392, 40.64574943],
            [-73.991112, 40.64574922],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN30",
      },
      type: "Feature",
      id: "sm4a46e023",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111096, 40.64453507],
            [-73.99111061, 40.64411908],
            [-73.99070903, 40.64411927],
            [-73.99070018, 40.64452998],
            [-73.99111096, 40.64453507],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN27",
      },
      type: "Feature",
      id: "sm55937c61",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99070018, 40.64452998],
            [-73.99069172, 40.64492294],
            [-73.9911113, 40.64492814],
            [-73.99111096, 40.64453507],
            [-73.99070018, 40.64452998],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN28",
      },
      type: "Feature",
      id: "smd501833e",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99069172, 40.64492294],
            [-73.99068334, 40.64531187],
            [-73.99111163, 40.64531166],
            [-73.9911113, 40.64492814],
            [-73.99069172, 40.64492294],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN29",
      },
      type: "Feature",
      id: "smf601220a",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111061, 40.64411908],
            [-73.99111026, 40.64370587],
            [-73.99071793, 40.64370607],
            [-73.99070903, 40.64411927],
            [-73.99111061, 40.64411908],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN26",
      },
      type: "Feature",
      id: "sm2f05c822",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99111026, 40.64370587],
            [-73.9911099, 40.64328488],
            [-73.9907271, 40.64328014],
            [-73.99071793, 40.64370607],
            [-73.99111026, 40.64370587],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN25",
      },
      type: "Feature",
      id: "sm71ccfc4b",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.9911099, 40.64328488],
            [-73.99110952, 40.64284297],
            [-73.99073651, 40.64284315],
            [-73.9907271, 40.64328014],
            [-73.9911099, 40.64328488],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN24",
      },
      type: "Feature",
      id: "sm81ad00f1",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99110952, 40.64284297],
            [-73.99110914, 40.64239519],
            [-73.99074616, 40.64239537],
            [-73.99073651, 40.64284315],
            [-73.99110952, 40.64284297],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN23",
      },
      type: "Feature",
      id: "smb733d19d",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99110914, 40.64239519],
            [-73.99110867, 40.64184983],
            [-73.99099065, 40.64191903],
            [-73.99075462, 40.64200248],
            [-73.99074616, 40.64239537],
            [-73.99110914, 40.64239519],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN22",
      },
      type: "Feature",
      id: "sm13895a8a",
    },
    {
      geometry: {
        coordinates: [
          [-73.99186913, 40.6497634],
          [-73.99279181, 40.64967386],
        ],
        type: "LineString",
      },
      properties: {
        Unit_Number: "VN",
      },
      type: "Feature",
      id: "sm120d12bc",
    },
    {
      geometry: {
        coordinates: [
          [-73.99059777, 40.64984887],
          [-73.98966972, 40.64991399],
        ],
        type: "LineString",
      },
      properties: {
        Unit_Number: "VN",
      },
      type: "Feature",
      id: "smbcab4892",
    },
    {
      geometry: {
        coordinates: [
          [-73.99290447, 40.64982852],
          [-73.98966561, 40.65006588],
        ],
        type: "LineString",
      },
      properties: {
        Unit_Number: "VN",
      },
      type: "Feature",
      id: "smb73f6ba5",
    },
    {
      geometry: {
        coordinates: [
          [
            [-73.99076942, 40.64143155],
            [-73.99076406, 40.64176738],
            [-73.99075601, 40.64199939],
            [-73.99098668, 40.64191798],
            [-73.99111274, 40.64184267],
            [-73.99165723, 40.64143359],
            [-73.99164114, 40.64110388],
            [-73.99123344, 40.64128094],
            [-73.99100814, 40.64132572],
            [-73.99076942, 40.64143155],
          ],
        ],
        type: "Polygon",
      },
      properties: {
        Unit_Number: "VN",
      },
      type: "Feature",
      id: "sm189303e2",
    },
    {
      geometry: {
        coordinates: [
          [-73.99068334, 40.64531188],
          [-73.99068229, 40.64536072],
        ],
        type: "LineString",
      },
      properties: {
        Unit_Number: "VN",
      },
      type: "Feature",
      id: "sm7ba17838",
    },
    {
      geometry: {
        coordinates: [
          [-73.99174217, 40.644986],
          [-73.99174225, 40.64503657],
        ],
        type: "LineString",
      },
      properties: {
        Unit_Number: "VN",
      },
      type: "Feature",
      id: "smff1a14ca",
    },
    {
      geometry: {
        coordinates: [
          [-73.99179748, 40.64723887],
          [-73.99179756, 40.64728527],
        ],
        type: "LineString",
      },
      properties: {
        Unit_Number: "VN",
      },
      type: "Feature",
      id: "sm817302ed",
    },
    {
      geometry: {
        coordinates: [
          [-73.99062131, 40.64823478],
          [-73.99062023, 40.64828468],
        ],
        type: "LineString",
      },
      properties: {
        Unit_Number: "VN",
      },
      type: "Feature",
      id: "sma6c36c96",
    },
  ],
};

export default geojsonData;
