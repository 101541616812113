import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";

const CreateProject = () => {

  
  const navigate = useNavigate();

  const [levelOptions,setLevelOptions] = useState([]);

  

  const initialValues = {
    projectName: "",
    location: "",
    sizeInAcres: "",
    level_id: "",
    
  };

  const validationSchema = Yup.object({
    projectName: Yup.string()
      .min(3, "Project Name must be at least 3 characters")
      .max(255, "Exceeded the characters limit")
      .required("Project Name is required"),
    location: Yup.string()
      .min(3, "Location must be at least 3 characters")
      .max(255, "Exceeded the characters limit")
      .required("Location is required"),
    sizeInAcres: Yup.number()
      .min(1, "Must be at least 1 acre")
      .required("Size is required"),
    level: Yup.number()
      .oneOf(levelOptions.map((option) => option.id))
      .required("Level is required"),
   
  });

  // fetch levels from the database
  useEffect(()=>{
    const fetchLevels = async() =>{
      try{
        const response = await axios.get("https://workspace.optiven.co.ke/api/levels",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        })
        console.log(response.data)
        setLevelOptions(response.data)

      }catch(error){
        console.error(error)
      }
    }
    fetchLevels()
  },[])

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post("https://workspace.optiven.co.ke/api/projects", {
        project_name: values.projectName,
        location: values.location,
        size_in_acres: values.sizeInAcres,
        level_id: values.level,
        
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      });
      
      console.log(response);

      toast.success("Project created successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // redirect to home page
      navigate("/projects");
    } catch (error) {
      console.error(error.response.data);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setSubmitting(false);
  };

  return (
    <>
      <Sidebar>
        <div className="hero min-h-screen">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form className="form-control w-full max-w-xs">
                <label htmlFor="projectName" className="label">
                  <span className="label-text font-bold">Project Name</span>
                </label>
                <Field
                  type="text"
                  id="projectName"
                  name="projectName"
                  placeholder="Oasis Gardens"
                  className="input input-bordered w-full max-w-xs"
                />
                <ErrorMessage
                  name="projectName"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />
                <label htmlFor="level" className="label">
                  <span className="label-text font-bold">Level</span>
                </label>
                <Field
                  as="select"
                  id="level_id"
                  name="level"
                  className="select select-bordered"
                >
                  <option value ="" >
                    Select a level
                  </option>
                  {levelOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="level"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="location" className="label">
                  <span className="label-text font-bold">Location</span>
                </label>
                <Field
                  type="text"
                  id="location"
                  name="location"
                  placeholder="Machakos"
                  className="input input-bordered w-full max-w-xs"
                />
                <ErrorMessage
                  name="location"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="sizeInAcres" className="label">
                  <span className="label-text font-bold">Size in Acres</span>
                </label>
                <Field
                  type="text"
                  id="sizeInAcres"
                  name="sizeInAcres"
                  placeholder="100"
                  className="input input-bordered w-full max-w-xs"
                />
                <ErrorMessage
                  name="sizeInAcres"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />
               

                <button
                  type="submit"
                  id="submit"
                  className="mt-4 inline-flex items-center justify-center rounded-xl bg-green-600 py-3 px-4 font-dm text-base font-medium text-white shadow-xl shadow-green-400/75 transition-transform duration-200 ease-in-out hover:scale-[1.02]"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Submitting..." : "Create Project"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateProject;
