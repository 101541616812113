import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const mapMetricName = (key) => {
  switch (key) {
    case "dataCollected":
      return "Data Collected";
    case "siteVisitDone":
      return "Site Visits Done";
    case "scheduledVisits":
      return "Scheduled Visits";
    case "sales":
      return "Sales";
    case "referrals":
      return "Referrals";
    default:
      return key;
  }
};

const EventsBarChart = ({ events, selectedMonth }) => {
  if (!events || events.length === 0) {
    return <p>No data available for the chart</p>;
  }

  const mapMonthName = (month) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month - 1] || "";
  };

  // Create an object to store the sums for each team
  const teamSums = {};

  // Filter events based on the selected month
  const filteredEvents = selectedMonth
    ? events.filter((event) => {
        const month = new Date(event.endDate).getMonth() + 1; // Assuming endDate is the field containing the end date
        return month.toString() === selectedMonth;
      })
    : events;

  console.log("Filtered Events:", filteredEvents);

  filteredEvents.forEach((event) => {
    const teamName = event.region;

    if (!teamSums[teamName]) {
      teamSums[teamName] = {
        dataCollected: 0,
        siteVisitDone: 0,
        scheduledVisits: 0,
        sales: 0,
        referrals: 0,
      };
    }

    Object.keys(teamSums[teamName]).forEach((key) => {
      teamSums[teamName][key] += event[key] || 0;
    });
  });

  console.log("Team Sums:", teamSums);

  const chartData = Object.keys(teamSums).map((teamName) => ({
    region: teamName,
    dataCollected: teamSums[teamName].dataCollected,
    siteVisitDone: teamSums[teamName].siteVisitDone,
    scheduledVisits: teamSums[teamName].scheduledVisits,
    sales: teamSums[teamName].sales,
    referrals: teamSums[teamName].referrals,
  }));

  console.log("Chart Data:", chartData);

  if (chartData.length === 0) {
    console.log("No data available for the selected month");
    return <p>No data available for the selected month</p>;
  }

  const chartDatasets = Object.keys(chartData[0])
    .filter((key) => key !== "region")
    .map((key, index) => ({
      label: mapMetricName(key),
      dataKey: key,
      fill: `#${
        index === 0
          ? "3b3a52"
          : index === 1
          ? "6caeca"
          : index === 2
          ? "b9d7e4"
          : index === 3
          ? "c4b4a3"
          : index === 4
          ? "ced7ba"
          : "33FFFF"
      }`,
    }));

  return (
    <div>
      {" "}
      <h2 className="text-center mb-4">
        {selectedMonth
          ? `Performance for ${mapMonthName(parseInt(selectedMonth))}`
          : "Overall Performance"}
      </h2>
      <BarChart
        width={600}
          height={300}
        data={chartData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="region" />
        <YAxis />
        <Tooltip />
        <Legend />
        {chartDatasets.map((dataset, index) => (
          <Bar
            key={index}
            dataKey={dataset.dataKey}
            fill={dataset.fill}
            name={dataset.label}
          />
        ))}
      </BarChart>
    </div>
  );
};

export default EventsBarChart;
