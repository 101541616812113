import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
function formatDate(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function formatDateToTheDatabase(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const timezoneOffset = -date.getTimezoneOffset();
  const offsetHours = Math.floor(timezoneOffset / 60);
  const offsetMinutes = timezoneOffset % 60;

  const formattedOffset = `${offsetHours >= 0 ? '+' : ''}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000${formattedOffset}`;
}

const EditCasualLabourer = () => {
  // finding the casualLabourerId
  const loc = useLocation();

  const url = loc.pathname;

  // Use a safer method to extract the supplierId from the URL
  const casualLabourerId = url.split("/")[2];

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [labourers, setLabourers] = useState([]);
  const [labour_id, setLabourId] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [id_number, setIdNumber] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [project_id,setProjectId] = useState("");
  const [work_description,setWorkDescription] = useState("");
  const [projects, setProjects] = useState([]);
  

  const [loading, setLoading] = useState(false);

  

  useEffect(() => {
    // Fetch the casualLabourer data from the backend API
    if (casualLabourerId) {
      axios
        .get(`https://workspace.optiven.co.ke/api/casual-labourers/${casualLabourerId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }
        )
        .then((res) => {
          console.log(res.data)
          setName(res.data.name);
          console.log(res.data.name)
          setLabourId(res.data.labour_id);
          setPhoneNumber(res.data.phone);
          setIdNumber(res.data.id_number);
          setStartDate(res.data.start_date);
          setEndDate(res.data.end_date);
          setProjectId(res.data.project_id)
          setWorkDescription(res.data.work_description)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [casualLabourerId]);

  useEffect(() => {
    // Fetch all the projects from the db
    axios
      .get(`https://workspace.optiven.co.ke/api/labour`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
        setLabourers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    // Fetch all the projects from the db
    axios
      .get(`https://workspace.optiven.co.ke/api/projects/all`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const casualLabourer = {
      name,
      labour_id,
      phone,
      id_number,
      start_date:formatDateToTheDatabase(start_date),
      end_date:formatDateToTheDatabase(end_date),
      project_id,
      work_description
      
    };
    console.log(casualLabourer)

    // Send a PATCH request to update the casual labourer data in the backend API
    axios.patch(`https://workspace.optiven.co.ke/api/casual-labourers/${casualLabourerId}`, casualLabourer,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },

    }
    )

      .then((res) => {
        setLoading(false);
        toast.success("Staff updated!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(res.data);
        navigate("/view-casual-labourer");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <form onSubmit={handleSubmit} className="form-control w-full max-w-xs">
        <label htmlFor="project" className="label">
            <span className="label-text font-bold">Project</span>
          </label>
          <select
            id="project"
            as="select"
            onChange={(event) => {
              setProjectId(event.target.value);
            }}
            value={project_id}
            className="select select-bordered"
           
            
          >
            {projects.map((project) => (
              <option key={crypto.randomUUID()} value={project.id}>
                {project.project_name}
              </option>
            ))}
          </select>
          <label htmlFor="name" className="label">
            <span className="label-text font-bold">Name</span>
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="id_number" className="label">
            <span className="label-text font-bold">ID Number</span>
          </label>
          <input
            type="id_number"
            id="id_number"
            value={id_number}
            onChange={(event) => setIdNumber(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
           <label htmlFor="phoneNumber" className="label">
            <span className="label-text font-bold">Phone Number</span>
          </label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(event) => setPhoneNumber(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
           <div className="lg:flex justify-between">
            <div className="w-36">
              <label className="label" htmlFor="startDate">
                <span className="label-text font-bold">Start Date: </span>
              </label>
              <input
                type="date"
                className="input input-bordered w-full max-w-xs"
                id="startDate"
                value={formatDate(start_date)}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </div>
            <div className="w-36">
              <label className="label" htmlFor="expectedDateOfCompletion">
                <span className="label-text font-bold">
                  Expected End Date:{" "}
                </span>
              </label>
              <input
                type="date"
                className="input input-bordered w-full max-w-xs"
                id="expectedDateOfCompletion"
                value={formatDate(end_date)}
                onChange={(event) =>
                  setEndDate(event.target.value)
                }
              />
            </div>
          </div>
                  
                
          <label htmlFor="labour" className="label">
            <span className="label-text font-bold">Labour</span>
          </label>
          <select
            id="labour_id"
            as="select"
            onChange={(event) => {
              setLabourId(event.target.value);
            }}
            value={labour_id}
            className="select select-bordered"
           
          >
            {labourers.map((labour) => (
              <option key={crypto.randomUUID()} value={labour.id}>
                {labour.labour_type}
              </option>
            ))}
          </select>
          <label htmlFor="work_description" className="label">
            <span className="label-text font-bold">
             Work Description
            </span>
          </label>
          <textarea
            id="work_description"
            value={work_description}
            onChange={(event) =>
              setWorkDescription(event.target.value)
            }
            className="textarea textarea-bordered w-full max-w-xs h-24"
          />

         
          

          <button
            type="submit"
            disabled={loading}
            id="submit"
            className="btn btn-primary w-full max-w-xs mt-4 text-white"
          >
            {loading ? "Saving..." : "Update Casual Labourer"}
          </button>
        </form>
      </div>
    </Sidebar>
  );
};

export default EditCasualLabourer;
