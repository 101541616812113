import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

const EditLabour = () => {
  // finding the labourId
  const loc = useLocation();

  const url = loc.pathname;

  // Use a safer method to extract the supplierId from the URL
  const labourId = url.split("/")[2];

  const navigate = useNavigate();

  const [labour_type, setLabourType] = useState("");
  const [wage_type, setWageType] = useState("");
  const [rate_per_day, setRatePerDay] = useState("");
  const [loading, setLoading] = useState(false);

  const labourTypeOptions = [
    { value: "Supervisor", label: "Supervisor" },
    { value: "Skilled", label: "Skilled" },
    { value: "Unskilled", label: "Unskilled" },
  ];
  const wageTypeOptions = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Contract", label: "Contract" },
  ];

  useEffect(() => {
    // Fetch the labour data from the backend API
    if (labourId) {
      axios
        .get(`https://workspace.optiven.co.ke/api/labour/${labourId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }
        )
        .then((res) => {
          console.log(res.data);

          setLabourType(res.data.labour_type);
          console.log(res.data.labour_type);
          setWageType(res.data.wage_type);
          setRatePerDay(res.data.rate_per_day);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [labourId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const labour = {
      labour_type,
      wage_type,
      rate_per_day,
    };
    console.log(labour);

    // Send a PATCH request to update the casual labourer data in the backend API
    axios
      .patch(`https://workspace.optiven.co.ke/api/labour/${labourId}`, labour,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )

      .then((res) => {
        setLoading(false);
        toast.success("Labour updated!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(res.data);
        navigate("/view-labour");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <form onSubmit={handleSubmit} className="form-control w-full max-w-xs">
          <h1 className="font-bold flex justify-center mb-4 text-2xl">
            Labour
          </h1>
          <label htmlFor="labour_type" className="label">
            <span className="label-text font-bold">Labour Type</span>
          </label>
          <select
            type="labour_type"
            id="labour_type"
            value={labour_type} 
            onChange={(event) => setLabourType(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          >
            <option value="">Select a Labour Type</option>
            {labourTypeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <label htmlFor="wage_type" className="label">
            <span className="label-text font-bold">Wage Type</span>
          </label>
          <select
            type="wage_type"
            id="wage_type"
            value={wage_type}
            onChange={(event) => setWageType(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          >
            <option value=""> Select Wage Type</option>
            {wageTypeOptions.map((options) => (
              <option key={options.value} value={options.value}>
                {options.label}
              </option>
            ))}
          </select>

          <label htmlFor="phoneNumber" className="label">
            <span className="label-text font-bold">Rate Per Day</span>
          </label>
          <input
            type="tel"
            id="rate_per_day"
            value={rate_per_day}
            onChange={(event) => setRatePerDay(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />

          <button
            type="submit"
            disabled={loading}
            id="submit"
            className="btn btn-primary w-full max-w-xs mt-4 text-white"
          >
            {loading ? "Saving..." : "Update Labour"}
          </button>
        </form>
      </div>
    </Sidebar>
  );
};

export default EditLabour;
