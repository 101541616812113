import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../../components/sidebar/Sidebar";

function BookingForm() {
  const [formData, setFormData] = useState({
    date: "",
    numberOfPeople: "",
    purpose: "",
  });
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.user.user_id);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const bookingData = {
      ...formData,
      user_id: userId, // Add user_id to the booking data
    };
    navigate("/conf-rooms", { state: bookingData });
  };

  const today = new Date().toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format

  return (
    <Sidebar>
    <section className="bg-white">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <aside className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
          <img
            alt=""
            src="https://images.unsplash.com/photo-1552664688-cf412ec27db2?q=80&w=987&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="absolute inset-0 h-full w-full object-cover"
          />
        </aside>

        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="max-w-xl lg:max-w-3xl">
            

            <h1 className="mt-6 text-2xl font-bold text-indigo-600 sm:text-3xl md:text-4xl">
              Room Scheduling
            </h1>

            <p className="mt-4 leading-relaxed text-gray-500">
              Streamlined room scheduling software. Give teams an easy way to
              reserve a conference room, preview room availability, and grab
              spaces on-the-go. Simplify the process by surfacing smart meeting
              room suggestions based on capacity, resources, and technology.
            </p>

            <h2 className="mt-6 text-2xl font-bold text-indigo-600 sm:text-3xl md:text-4xl">
              Book a Conference Room
            </h2>

            <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
              Please fill in the details to find a suitable room.
            </p>

            <form
              onSubmit={handleSubmit}
              className="mt-6 grid grid-cols-6 gap-6"
            >
              <div className="col-span-6">
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date
                </label>
                <input
                  type="date"
                  name="date"
                  min={today} // Prevent selecting a date before today
                  value={formData.date}
                  onChange={handleChange}
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 h-12"
                  required
                />
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="numberOfPeople"
                  className="block text-sm font-medium text-gray-700"
                >
                  Number of People
                </label>
                <input
                  type="number"
                  name="numberOfPeople"
                  placeholder="Number of People"
                  value={formData.numberOfPeople}
                  onChange={handleChange}
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 h-12"
                  required
                  min="1" // Ensure at least one person is specified
                />
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="purpose"
                  className="block text-sm font-medium text-gray-700"
                >
                  Purpose
                </label>
                <input
                  type="text"
                  name="purpose"
                  placeholder="Purpose"
                  value={formData.purpose}
                  onChange={handleChange}
                  className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 h-12"
                  required
                />
              </div>
              <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                <button
                  type="submit"
                  className="inline-block shrink-0 rounded-md border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
                >
                  View Options
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
    </section>
    </Sidebar>
  );
}

export default BookingForm;
