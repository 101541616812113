import React from "react";

const ActionButtonsCard = () => {
  const handleViewProjectClick = () => {
    window.location.href = "https://www.optiven.co.ke/properties/wema-gardens-naro-moru/";
  };
  const handleFillOfferClick = () => {
    window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSf1YNr5mo0cxrPMGyvJTHxtsv7gVZpIrH_JF1kxflHGEEt_Uw/viewform";
  };const handleEoIClick = () => {
    window.location.href = "https://www.optiven.co.ke/expression-of-interest/";
  };

  return (
    <div
      className="card bg-purple-300 shadow-xl"
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      <div
        className="card-body"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          flex: 1
        }}
      >
        <h2 className="card-title">Action Buttons</h2>
        <button
          className="btn btn-outline btn-accent"
          onClick={handleEoIClick}
          style={{ flex: 1 }}
        >
          Expression of Interest
        </button>
        <button
          className="btn btn-outline btn-secondary"
          onClick={handleFillOfferClick}
          style={{ flex: 1 }}
        >
          Fill Offer Letter
        </button>
        <button
          className="btn btn-outline btn-primary"
          onClick={handleViewProjectClick}
          style={{ flex: 1 }}
        >
          View Project
        </button>
      </div>
    </div>
  );
};

export default ActionButtonsCard;
