import React, { useState, useCallback, useEffect, useMemo } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import formatDate from "../../../utils/formatDate";

const Sales = () => {
  const [eventDate, setEventDate] = useState("");
  const [bookCode, setBookCode] = useState("");
  const [bookSold, setBookSold] = useState("");
  const [saleState, setSaleState] = useState("");
  const [personResponsible, setPersonResponsible] = useState("");
  const [bookAmountGiven, setBookAmountGiven] = useState("");
  const [saleType, setSaleType] = useState("");
  const [booksIssued, setBooksIssued] = useState("");
  const [bookStore, setBookStore] = useState("");
  const [bookEvent, setBookEvent] = useState("");
  const [book, setBook] = useState([]);
  const [marketer, setMarketer] = useState([]);
  const [searchQuery3, setSearchQuery3] = useState("");
  const [searchQuery4, setSearchQuery4] = useState("");
  const [editSale, setEditSale] = useState({
    book_copies: "",
    book_amount_given: "",
    book_id: "",
    book_name: "",
    store: "",
    person_responsible: "",
    book_condition: "",
    book_issued: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery5, setSearchQuery5] = useState("");
  const [searchQuery6, setSearchQuery6] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState([]);
  const [office, setOffice] = useState([]);

  const token = useSelector((state) => state.user.token);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const closeModal2 = useCallback(() => {
    setIsModalOpen2(false);
  }, []);
  useEffect(() => {
    const fetchSales = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/sales",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        console.log(data);
        setSales(data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchBooks = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/books",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setBook(data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchMarketers = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/sales/marketer",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setMarketer(data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchOffice = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/sales/offices",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setOffice(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchOffice();
    fetchSales();
    fetchBooks();
    fetchMarketers();
  }, []);
  const filteredBulk = useMemo(() => {
    return sales.filter((item) => {
      if (
        item.hasOwnProperty("book_status") &&
        typeof item.book_status === "string" &&
        item.hasOwnProperty("book_condition") &&
        typeof item.book_condition === "string"
      ) {
        if (searchQuery === "") {
          return (
            item.book_status === "BULK" && item.book_condition !== "CLEARED"
          );
        } else if (
          item.book_name &&
          item.book_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
          item.book_status === "BULK" &&
          item.book_condition !== "CLEARED"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  }, [searchQuery, sales]);

  const filteredSingle = useMemo(() => {
    return sales.filter((item) => {
      // Check if the item has a book_status and book_condition property and if they are strings
      if (
        item.hasOwnProperty("book_status") &&
        typeof item.book_status === "string" &&
        item.hasOwnProperty("book_condition") &&
        typeof item.book_condition === "string"
      ) {
        if (searchQuery2 === "") {
          // Include only items with status SINGLE when the search query is empty and book_condition is not CLEARED
          return (
            item.book_status === "SINGLE" && item.book_condition !== "CLEARED"
          );
        } else if (
          item.book_name &&
          item.book_name.toLowerCase().includes(searchQuery2.toLowerCase()) &&
          item.book_status === "SINGLE" && // Include only items with status SINGLE that match the search query
          item.book_condition !== "CLEARED" // Ensure book_condition is not CLEARED
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        // Exclude the item if it does not have book_status or book_condition property or if they are not strings
        return false;
      }
    });
  }, [searchQuery2, sales]);

  const filteredDonation = useMemo(() => {
    return sales.filter((item) => {
      // Check if the item has a book_status property and if it is a string
      if (
        item.hasOwnProperty("book_status") &&
        typeof item.book_status === "string"
      ) {
        if (searchQuery5 === "") {
          // Include only items with status SINGLE when the search query is empty
          return item.book_status === "DONATION";
        } else if (
          item.book_name &&
          item.book_name.toLowerCase().includes(searchQuery5.toLowerCase()) &&
          item.book_status === "DONATION" // Include only items with status SINGLE that match the search query
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        // Exclude the item if it does not have a book_status property or if it is not a string
        return false;
      }
    });
  }, [searchQuery5, sales]);

  const filteredBooks = useMemo(() => {
    return book.filter((item) => {
      if (searchQuery3 === "") {
        return true; // Include all items when the search query is empty
      } else if (
        item.book_name &&
        item.book_name.toLowerCase().includes(searchQuery3.toLowerCase())
      ) {
        return true; // Include the item if it matches the search query
      } else {
        return false; // Exclude the item if it doesn't match the search query
      }
    });
  }, [searchQuery3, book]);

  const filteredMarketers = useMemo(() => {
    return marketer.filter((item) => {
      if (searchQuery4 === "") {
        return true; // Include all items when the search query is empty
      } else if (
        item.fullnames &&
        item.fullnames.toLowerCase().includes(searchQuery4.toLowerCase())
      ) {
        return true; // Include the item if it matches the search query
      } else {
        return false; // Exclude the item if it doesn't match the search query
      }
    });
  }, [searchQuery4, marketer]);

  const filteredOffices = useMemo(() => {
    return office.filter((item) => {
      if (searchQuery6 === "") {
        return true; // Include all items when the search query is empty
      } else if (
        item.book_office_issued &&
        item.book_office_issued
          .toLowerCase()
          .includes(searchQuery6.toLowerCase())
      ) {
        return true; // Include the item if it matches the search query
      } else {
        return false; // Exclude the item if it doesn't match the search query
      }
    });
  }, [searchQuery6, office]);

  const handleSubmit = async () => {
    setLoading(true);
    const sales = {
      book_code: bookCode,
      book_copies: bookSold,
      store: bookStore,
      person_responsible: personResponsible,
      book_amount_given: bookAmountGiven,
      book_status: saleType,
      book_issued: booksIssued,
      book_event: bookEvent,
      event_date: eventDate,
    };

    try {
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/sales",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(sales),
        }
      );

      if (!response.ok) {
        toast.error("Error adding Book", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("Book added successfully!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setLoading(false);
      closeModal();

      setBookCode("");
      setBookSold("");
      setBookStore("");
      setPersonResponsible("");
      setBookAmountGiven("");
      setSaleType("");
      setBooksIssued("");
      setBookEvent("");
      setEventDate("");
    } catch (error) {
      // Display error notification
      toast.error(error, {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);
    }
  };
  const handleEditClick = (id) => {
    const data =
      Array.isArray(sales) && sales.filter((item) => item.book_id === id)[0];
    setEditSale(data);
    console.log("selected for edit", data);
    setIsModalOpen2(true);
  };
  const handleSubmitEditBulk = async () => {
    const store = {
      book_copies: editSale.book_copies,
      book_amount_given: editSale.book_amount_given,
      book_id: editSale.book_id,
      book_condition: saleState,
    };
    console.log(store);
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/sales/${editSale.book_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(store),
        }
      );

      if (!response.ok) {
        toast.error("Error Updating Reload", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // Display success notification
      else {
        toast.success("Reload added successfully!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setLoading(false);
      closeModal();
      setEditSale("");
    } catch (error) {
      // Display error notification
      toast.error(error, {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);
    }
  };
  const handleSubmitEditSingle = async () => {
    const store = {
      book_copies: editSale.book_copies,
      book_amount_given: editSale.book_amount_given,
      book_id: editSale.book_id,
      book_condition: saleState,
    };
    console.log(store);
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/sales/${editSale.book_id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(store),
        }
      );

      if (!response.ok) {
        toast.error("Error Updating Reload", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // Display success notification
      else {
        toast.success("Reload added successfully!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setLoading(false);
      closeModal();
      setEditSale("");
    } catch (error) {
      // Display error notification
      toast.error(error, {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);
    }
  };
  return (
    <Sidebar>
      <section className="text-center overflow-x-hidden">
        <div className="container mx-auto text-center mt-4">
          <section className="text-gray-600 body-font">
            <div className="sm:flex sm:items-center sm:justify-between mx-8">
              <div>
                <div className="flex items-center gap-x-3">
                  <h2 className="text-lg font-medium text-gray-800 dark:text-white">
                    Bulk Book Sales
                  </h2>
                  <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">
                    Welcome 😊
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 text-start">
                  These are all the Bulk Book Sales that have been Registered.
                </p>
              </div>
              <div className="flex items-center mt-4 gap-x-3">
                <button
                  className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-primary rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                  onClick={() => setIsModalOpen(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>Add a Sale</span>
                </button>
                <Modal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  className="modal-box container mx-auto"
                >
                  {" "}
                  <button
                    onClick={closeModal}
                    className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                  >
                    ✕
                  </button>
                  <div>
                    <form onSubmit={handleSubmit}>
                      <label className="label font-bold text-xs">
                        Add a Book sale
                      </label>
                      <label className="label font-bold text-xs">
                        Name of the Book
                      </label>
                      <select
                        className="input input-bordered w-full"
                        name="bookName"
                        value={bookCode}
                        onChange={(e) => setBookCode(e.target.value)}
                        required
                      >
                        <option value="">Please select a Book</option>
                        {filteredBooks.map((book) => (
                          <option key={book.book_code} value={book.book_code}>
                            {book.book_name}
                          </option>
                        ))}
                      </select>
                      <label className="label font-bold text-xs">
                        Store Issued From{" "}
                      </label>
                      <select
                        className="input input-bordered w-full"
                        name="bookStore"
                        value={bookStore}
                        onChange={(e) => setBookStore(e.target.value)}
                        type="text"
                        spellCheck
                        required
                      >
                        <option value="">Please select an Office</option>
                        {filteredOffices.map((office) => (
                          <option
                            key={office.book_code}
                            value={office.book_office_issued}
                          >
                            {office.book_office_issued}
                          </option>
                        ))}
                      </select>
                      <label className="label font-bold text-xs">
                        Person in Charge{" "}
                      </label>
                      <select
                        className="input input-bordered w-full"
                        name="personResponsible"
                        value={personResponsible}
                        onChange={(e) => setPersonResponsible(e.target.value)}
                        required
                      >
                        <option value="">Please select a Marketer</option>
                        {filteredMarketers.map((marketer) => (
                          <option
                            key={marketer.user_id}
                            value={marketer.fullnames}
                          >
                            {marketer.fullnames}
                          </option>
                        ))}
                      </select>
                      <label className="label font-bold text-xs">
                        Amount Of Money Got on the Sale{" "}
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="bookAmountGiven"
                        value={bookAmountGiven}
                        onChange={(e) => setBookAmountGiven(e.target.value)}
                        type="number"
                        spellCheck
                        required
                      />
                      <label className="label font-bold text-xs">
                        Type of Sale{" "}
                      </label>
                      <select
                        className="input input-bordered w-full"
                        name="saleType"
                        value={saleType}
                        onChange={(e) => setSaleType(e.target.value)}
                        required
                      >
                        <option value="None Selected">
                          Please specify the type of sale
                        </option>
                        <option value="SINGLE">Single</option>
                        <option value="BULK">Bulk</option>
                        <option value="DONATION">Donation</option>
                      </select>
                      <label className="label font-bold text-xs">
                        Number of Books Issued{" "}
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="booksIssued"
                        value={booksIssued}
                        onChange={(e) => setBooksIssued(e.target.value)}
                        spellCheck
                        type="number"
                        required
                      />{" "}
                      <label className="label font-bold text-xs">
                        Number of Books Sold{" "}
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="bookSold"
                        value={bookSold}
                        onChange={(e) => setBookSold(e.target.value)}
                        spellCheck
                        type="number"
                        required
                      />{" "}
                      <label className="label font-bold text-xs">Event</label>
                      <input
                        className="input input-bordered w-full"
                        name="bookEvent"
                        value={bookEvent}
                        onChange={(e) => setBookEvent(e.target.value)}
                        spellCheck
                        type="text"
                        required
                      />
                      <label className="label font-bold text-xs">
                        Event Date
                      </label>
                      <input
                        className="input input-bordered w-full"
                        name="eventDate"
                        value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                        spellCheck
                        type="date"
                        required
                      />
                      <button
                        type="submit"
                        className="btn btn-outline my-4 w-full bg-green"
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button>
                    </form>{" "}
                  </div>
                </Modal>
                <input
                  type="text"
                  className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                  placeholder="Search book by name..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            {/*BOXES */}{" "}
            <div className="container px-0 py-4 mx-auto">
              <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="mx-2 overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                          <tr>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              <div className="text-center">
                                <div className="flex items-center gap-x-3 focus:outline-none">
                                  <span>Name of the Book</span>
                                  <svg
                                    className="h-3"
                                    viewBox="0 0 10 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.1"
                                    />
                                    <path
                                      d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.1"
                                    />
                                    <path
                                      d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.3"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Person In Charge
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Event
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Event Date
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Amount of money Got
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Book copies Sold
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Books Returned/ Money to give
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {filteredBulk.map((sales, index) => (
                            <tr key={sales.book_code}>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                <div className="flex flex-col justify-center items-start">
                                  {sales.book_name}
                                </div>
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {sales.person_responsible}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {sales.book_event}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {formatDate(sales.event_date)}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                KSH {sales.book_amount_given}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {sales.book_copies}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {sales.book_issued - sales.book_copies !== 0 ? (
                                  <div className="px-3 mt-2 py-1 text-sm font-normal rounded-full text-red-500 gap-x-2 dark:bg-gray-800">
                                    {sales.book_issued - sales.book_copies}{" "}
                                    books<br></br>or<br></br>
                                    KSH{" "}
                                    {sales.book_copies * sales.book_price -
                                      sales.book_amount_given}
                                  </div>
                                ) : (
                                  <div className="px-3 mt-2 py-1 text-sm font-normal rounded-full text-green-500 gap-x-2 bg-green-200 dark:bg-gray-800">
                                    {sales.book_issued - sales.book_copies}
                                  </div>
                                )}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                <button
                                  className="btn btn-circle btn-outline btn-success"
                                  onClick={() => handleEditClick(sales.book_id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="h-6 w-6"
                                  >
                                    <path d="M3 4v16a2 2 0 002 2h14a2 2 0 002-2V8l-6-6H5a2 2 0 00-2 2z" />
                                    <line x1="9" y1="12" x2="15" y2="12" />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={isModalOpen2}
              onRequestClose={closeModal2}
              className="modal-box container mx-auto"
            >
              {" "}
              <button
                onClick={closeModal2}
                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              >
                ✕
              </button>
              <div>
                <form onSubmit={handleSubmitEditBulk}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <label className="label font-bold text-xs">
                      Clear The sale
                    </label>
                  </div>
                  <label className="label font-bold text-xs">
                    Name of the Book
                  </label>
                  <select
                    className="input input-bordered w-full"
                    name="bookName"
                    value={editSale.book_name}
                    required
                    aria-readonly
                  >
                    <option value="">Please select a Book</option>
                    {filteredBooks.map((book) => (
                      <option key={book.book_id} value={book.book_id}>
                        {book.book_name}
                      </option>
                    ))}
                  </select>
                  <label className="label font-bold text-xs">
                    Store Issued From{" "}
                  </label>
                  <input
                    className="input input-bordered w-full"
                    name="bookStore"
                    value={editSale.store}
                    type="text"
                    spellCheck
                    required
                    aria-readonly
                  />
                  <label className="label font-bold text-xs">
                    Person in Charge{" "}
                  </label>
                  <select
                    className="input input-bordered w-full"
                    name="personResponsible"
                    value={editSale.person_responsible}
                    required
                    aria-readonly
                  >
                    <option value="">Please select a Marketer</option>
                    {filteredMarketers.map((marketer) => (
                      <option key={marketer.user_id} value={marketer.fullnames}>
                        {marketer.fullnames}
                      </option>
                    ))}
                  </select>{" "}
                  <label className="label font-bold text-xs">
                    Number of Books Issued{" "}
                  </label>
                  <input
                    className="input input-bordered w-full"
                    name="bookStore"
                    value={editSale.book_issued}
                    type="text"
                    spellCheck
                    required
                    aria-readonly
                  />
                  <label className="label font-bold text-xs">
                    Number of Books Sold{" "}
                  </label>
                  <input
                    className="input input-bordered w-full"
                    name="bookSold"
                    value={editSale.book_copies}
                    onChange={(e) =>
                      setEditSale({
                        ...editSale,
                        book_copies: e.target.value,
                      })
                    }
                    spellCheck
                    type="number"
                    required
                  />{" "}
                  <label className="label font-bold text-xs">
                    Amount Of Money Got on the Sale{" "}
                  </label>
                  <input
                    className="input input-bordered w-full"
                    name="bookAmountGiven"
                    value={editSale.book_amount_given}
                    onChange={(e) =>
                      setEditSale({
                        ...editSale,
                        book_amount_given: e.target.value,
                      })
                    }
                    type="number"
                    spellCheck
                    required
                  />
                  <label className="label font-bold text-xs">Clear sale </label>
                  <select
                    className="input input-bordered w-full"
                    name="saleState"
                    value={saleState}
                    onChange={(e) => setSaleState(e.target.value)}
                    required
                  >
                    <option value="NONE">
                      Please enter the State of the Sale
                    </option>
                    <option value="CLEARED">Sale Cleared</option>
                    <option value="PENDING">Sale Pending</option>
                  </select>
                  <button
                    type="submit"
                    className="btn btn-outline my-4 w-full bg-green"
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </form>{" "}
              </div>
              {/* Add your form fields or other content here */}
            </Modal>
            {/*FILTERED SINGLE SALES*/}
            <div className="sm:flex sm:items-center sm:justify-between mx-8">
              <div>
                <div className="flex items-center gap-x-3 ">
                  <h2 className="text-lg font-medium text-gray-800 dark:text-white">
                    Single Book Sales
                  </h2>
                </div>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 text-start">
                  These are all the Single Book Sales that have been Registered.
                </p>
              </div>
            </div>
            <div className="flex justify-end px-6">
              <input
                type="text"
                className="block flex-col col-end-1 w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="Search book by name..."
                value={searchQuery2}
                onChange={(e) => setSearchQuery2(e.target.value)}
              />
            </div>
            <div className="container px-0 py-4 mx-auto">
              <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="mx-2 overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                          <tr>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              <div className="text-center">
                                <div className="flex items-center gap-x-3 focus:outline-none">
                                  <span>Name of the Book</span>
                                  <svg
                                    className="h-3"
                                    viewBox="0 0 10 11"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.1"
                                    />
                                    <path
                                      d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.1"
                                    />
                                    <path
                                      d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z"
                                      fill="currentColor"
                                      stroke="currentColor"
                                      strokeWidth="0.3"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Person In Charge
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Event
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Amount of money Got
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Book copies Sold
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Books Returned/ Money to give
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {filteredSingle.map((sales, index) => (
                            <tr key={sales.book_code}>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                <div className="flex flex-col justify-center items-start">
                                  {sales.book_name}
                                </div>
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {sales.person_responsible}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {sales.book_event}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                KSH {sales.book_amount_given}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {sales.book_copies}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                {sales.book_issued - sales.book_copies !== 0 ? (
                                  <div className="px-3 mt-2 py-1 text-sm font-normal rounded-full text-red-500 gap-x-2 bg-blue-200 dark:bg-gray-800">
                                    {sales.book_issued - sales.book_copies}{" "}
                                    books<br></br>or<br></br>
                                    KSH{" "}
                                    {sales.book_copies * sales.book_price -
                                      sales.book_amount_given}
                                  </div>
                                ) : (
                                  <div className="px-3 mt-2 py-1 text-sm font-normal rounded-full text-green-500 gap-x-2 bg-green-200 dark:bg-gray-800">
                                    {sales.book_issued - sales.book_copies}
                                  </div>
                                )}
                              </td>
                              <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                <button
                                  className="btn btn-circle btn-outline btn-success"
                                  onClick={() => handleEditClick(sales.book_id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="h-6 w-6"
                                  >
                                    <path d="M3 4v16a2 2 0 002 2h14a2 2 0 002-2V8l-6-6H5a2 2 0 00-2 2z" />
                                    <line x1="9" y1="12" x2="15" y2="12" />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={isModalOpen2}
              onRequestClose={closeModal2}
              className="modal-box container mx-auto"
            >
              {" "}
              <button
                onClick={closeModal2}
                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              >
                ✕
              </button>
              <div>
                <form onSubmit={handleSubmitEditSingle}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <label className="label font-bold text-xs">
                      Clear The sale
                    </label>
                  </div>
                  <label className="label font-bold text-xs">
                    Name of the Book
                  </label>
                  <select
                    className="input input-bordered w-full"
                    name="bookName"
                    value={editSale.book_name}
                    required
                    aria-readonly
                  >
                    <option value="">Please select a Book</option>
                    {filteredBooks.map((book) => (
                      <option key={book.book_id} value={book.book_id}>
                        {book.book_name}
                      </option>
                    ))}
                  </select>
                  <label className="label font-bold text-xs">
                    Store Issued From{" "}
                  </label>
                  <input
                    className="input input-bordered w-full"
                    name="bookStore"
                    value={editSale.store}
                    type="text"
                    spellCheck
                    required
                    aria-readonly
                  />
                  <label className="label font-bold text-xs">
                    Person in Charge{" "}
                  </label>
                  <select
                    className="input input-bordered w-full"
                    name="personResponsible"
                    value={editSale.person_responsible}
                    required
                    aria-readonly
                  >
                    <option value="">Please select a Marketer</option>
                    {filteredMarketers.map((marketer) => (
                      <option key={marketer.user_id} value={marketer.fullnames}>
                        {marketer.fullnames}
                      </option>
                    ))}
                  </select>{" "}
                  <label className="label font-bold text-xs">
                    Number of Books Issued{" "}
                  </label>
                  <input
                    className="input input-bordered w-full"
                    name="bookStore"
                    value={editSale.book_issued}
                    type="text"
                    spellCheck
                    required
                    aria-readonly
                  />
                  <label className="label font-bold text-xs">
                    Number of Books Sold{" "}
                  </label>
                  <input
                    className="input input-bordered w-full"
                    name="bookSold"
                    value={editSale.book_copies}
                    onChange={(e) =>
                      setEditSale({
                        ...editSale,
                        book_copies: e.target.value,
                      })
                    }
                    spellCheck
                    type="number"
                    required
                  />{" "}
                  <label className="label font-bold text-xs">
                    Amount Of Money Got on the Sale{" "}
                  </label>
                  <input
                    className="input input-bordered w-full"
                    name="bookAmountGiven"
                    value={editSale.book_amount_given}
                    onChange={(e) =>
                      setEditSale({
                        ...editSale,
                        book_amount_given: e.target.value,
                      })
                    }
                    type="number"
                    spellCheck
                    required
                  />
                  <label className="label font-bold text-xs">Clear sale </label>
                  <select
                    className="input input-bordered w-full"
                    name="saleState"
                    value={saleState}
                    onChange={(e) => setSaleState(e.target.value)}
                    required
                  >
                    <option value="NONE">
                      Please enter the State of the Sale
                    </option>
                    <option value="CLEARED">Sale Cleared</option>
                    <option value="PENDING">Sale Pending</option>
                  </select>
                  <button
                    type="submit"
                    className="btn btn-outline my-4 w-full bg-green"
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                </form>{" "}
              </div>
              {/* Add your form fields or other content here */}
            </Modal>
            {/*FILTERED DONATION*/}
          </section>
        </div>
      </section>
    </Sidebar>
  );
};

export default Sales;
