import React, { useState, useCallback, useEffect, useMemo } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";

const Initiatives = () => {
  const [initiativeName, setInitiativeName] = useState("");
  const [initiativePillar, setInitiativePillar] = useState("");
  const [initiatives, setInitiatives] = useState([]);
  const [isModal4Open, setIsModal4Open] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);

  const closeModal2 = useCallback(() => {
    setIsModal4Open(false);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const health = {
      initiative_name: initiativeName,
      initiative_pillar: initiativePillar,
    };

    try {
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/initiatives",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(health),
        }
      );

      if (!response.ok) {
        toast.error("Error adding Initiative ", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // Display success notification
      else {
        toast.success("Initiative added successfully!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setLoading(false);
      closeModal2();

      setInitiativeName("");
      setInitiativePillar("");
    } catch (error) {
      // Display error notification
      toast.error(error, {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitiative = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/initiatives",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setInitiatives(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchInitiative();
  }, []);

  const filteredInitiatives = useMemo(() => {
    return initiatives.filter((item) => {
      if (searchQuery === "") {
        return true; // Include all items when the search query is empty
      } else if (
        item.initiative_name &&
        item.initiative_name.toLowerCase().includes(searchQuery.toLowerCase())
      ) {
        return true; // Include the item if it matches the search query
      } else {
        return false; // Exclude the item if it doesn't match the search query
      }
    });
  }, [searchQuery, initiatives]);

  return (
    <Sidebar>
      <section className="text-center overflow-x-hidden">
        <div className="container mx-auto text-center mt-4">
          <section className="text-gray-600 body-font">
            <div className="sm:flex sm:items-center sm:justify-between mx-8">
              <div>
                <div className="flex items-center gap-x-3">
                  <h2 className="text-lg font-medium text-gray-800 dark:text-white">
                    Initiatives
                  </h2>
                  <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-gray-800 dark:text-blue-400">
                    Welcome 😊
                  </span>
                </div>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 text-start">
                  These are all the Initiatives that have been Registered under
                  this Pillar.<br></br>
                </p>
              </div>
              <button
                className="flex items-center justify-center w-1/2 px-5 py-2 mr-2 text-sm tracking-wide text-white transition-colors duration-200 bg-primary rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600"
                onClick={() => setIsModal4Open(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>Add an Initiative</span>
              </button>
              <Modal
                isOpen={isModal4Open}
                onRequestClose={closeModal2}
                className="modal-box container mx-auto"
              >
                {" "}
                <button
                  onClick={closeModal2}
                  className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                >
                  ✕
                </button>
                {/* Add your form fields or other content here */}
                <div>
                  <form onSubmit={handleSubmit}>
                    <label className="label font-bold text-xs">
                      Add an Initiative
                    </label>
                    <label className="label font-bold text-xs">
                      Name of the Initiative
                    </label>
                    <input
                      className="input input-bordered w-full"
                      name="initiativeName"
                      value={initiativeName}
                      onChange={(e) => setInitiativeName(e.target.value)}
                      spellCheck
                      required
                    />
                    <label className="label font-bold text-xs">
                      Gender of the assisted
                    </label>
                    <select
                      className="input input-bordered w-full"
                      name="initiativePillar"
                      value={initiativePillar}
                      onChange={(e) => setInitiativePillar(e.target.value)}
                      required
                    >
                      <option value="None Selected">
                        Please select Pillar
                      </option>
                      <option value="Education">Education</option>
                      <option value="Poverty Alleviation">
                        Poverty Alleviation
                      </option>
                      <option value="Health">Health</option>
                      <option value="Environment">Environment </option>
                    </select>
                    <button
                      type="submit"
                      className="btn btn-outline my-4 w-full bg-green"
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </form>{" "}
                </div>
              </Modal>
            </div>
            {/*BOXES */}
            <div className="container px-0 py-4 mx-auto">
              <div className="mt-6 md:flex md:items-center md:justify-between">
                <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg dark:bg-gray-900 rtl:flex-row-reverse dark:border-gray-700 dark:divide-gray-700"></div>
                <div className="relative flex items-center mt-4 md:mt-0">
                  <input
                    type="text"
                    className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Search Health Project by name..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                          <tr>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Index
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Initiative Name
                            </th>
                            <th
                              scope="col"
                              className="px-12 py-3.5 text-sm font-normal text-center text-gray-500 dark:text-gray-400"
                            >
                              Initiative Pillar
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                          {filteredInitiatives.length > 0 ? (
                            filteredInitiatives.map((Initiatives, index) => (
                              <tr key={index}>
                                <td className="border border-secondary-content px-2 py-2 font-bold">
                                  {index + 1}
                                </td>
                                <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                  {Initiatives.initiative_name}
                                </td>
                                <td className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center">
                                  {Initiatives.initiative_pillar}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="3"
                                className="px-12 py-4 text-sm font-medium whitespace-nowrap text-center"
                              >
                                No data
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Sidebar>
  );
};

export default Initiatives;
