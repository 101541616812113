import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Sidebar from "../components/Sidebar";
import * as Yup from "yup";

const CreateRequisition = () => {
  const userId = useSelector((state) => state.user.user.user_id);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    commodity_name: Yup.string()
      .min(3, "Commodity Name must be at least 3 characters")
      .max(255, "Exceeded the characters limit")
      .required("Commodity Name is required"),
    quantity: Yup.string().required("Quantity is required"),
  });

  const handleSubmit = async (values) => {
    const requisitionData = {
      ...values,
      status: "Pending",
      caretaker_id: userId,
    };

    try {
      const response = await axios.post(
        "https://workspace.optiven.co.ke/api/requisitions",
        requisitionData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      toast.success("Requisition created successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Redirect to view_requisition
      navigate("/view_requisition");

      console.log("Requisition submitted:", response.data);
    } catch (error) {
      console.error("Error submitting requisition:", error);
      toast.error(error.response?.data?.message || "An error occurred", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Sidebar>
    
    <Formik
      initialValues={{
        commodity_name: "",
        quantity: "",
        description: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="max-w-md mx-auto mt-16 p-4 bg-white shadow rounded font-[Poppin]">
        <h2 className="text-2xl font-bold mb-4">Requisition Form</h2>
        <div className="mb-4">
          <label htmlFor="commodity_name" className="block mb-1">
            Commodity Name
          </label>
          <Field
            type="text"
            id="commodity_name"
            name="commodity_name"
            className="w-full py-2 px-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <ErrorMessage
            name="commodity_name"
            component="div"
            className="text-red-500 font-bold text-sm mt-2"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="quantity" className="block mb-1">
            Quantity
          </label>
          <Field
            type="text"
            id="quantity"
            name="quantity"
            className="w-full py-2 px-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <ErrorMessage
            name="quantity"
            component="div"
            className="text-red-500 font-bold text-sm mt-2"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block mb-1">
            Description
          </label>
          <Field
            as="textarea"
            id="description"
            name="description"
            className="w-full py-2 px-4 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <button
          type="submit"
          className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Submit
        </button>
      </Form>
    </Formik>
    
  
  </Sidebar>
  </>
  )
};

export default CreateRequisition;
