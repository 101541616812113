import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";

const CreateUsers = () => {
    const navigate = useNavigate();
  
    const role = [
      { value: "supervisor", label: "supervisor" },
      { value: "caretaker", label: "Caretaker" }
    ];
  
    const initialValues = {
      name: "",
      contact: "",
      role: ""
    };
  
    const validationSchema = Yup.object({
      name: Yup.string().required("Name is required"),
      contact: Yup.number().required("Contact is required"),
      role: Yup.string()
        .oneOf(role.map((option) => option.value), "Invalid Role")
        .required("Role is required")
    });
  
    const handleSubmit = async (values, { setSubmitting }) => {
      try {
        const response = await axios.post(
          "https://workspace.optiven.co.ke/api/value_addition_users",
          {
            name: values.name,
            contact: values.contact,
            role: values.role
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );
        console.log(response.data);
        toast.success("User created successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        // redirect to home page
        navigate("/view-users");
      } catch (error) {
        console.error(error.response.data);
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
      setSubmitting(false);
    };
  
    return (
      <>
        <Sidebar>
          <div className="hero min-h-screen">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form className="form-control w-full max-w-xs">
                  <label htmlFor="name" className="label">
                    <span className="label-text font-bold">Name </span>
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Dennis"
                    className="input input-bordered w-full max-w-xs"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-500 font-bold text-sm mt-2"
                  />
                  <label htmlFor="role" className="label">
                    <span className="label-text font-bold">Role Type</span>
                  </label>
                  <Field
                    as="select"
                    id="role"
                    name="role"
                    className="select select-bordered"
                  >
                    <option value="">Select a role Type</option>
                    {role.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="role"
                    component="div"
                    className="text-red-500 font-bold text-sm mt-2"
                  />
                  <label htmlFor="contact" className="label">
                    <span className="label-text font-bold">Contact </span>
                  </label>
                  <Field
                    type="text"
                    id="contact"
                    name="contact"
                    placeholder="07xxx"
                    className="input input-bordered w-full max-w-xs"
                  />
                  <ErrorMessage
                    name="contact"
                    component="div"
                    className="text-red-500 font-bold text-sm mt-2"
                  />
                  <button
                    type="submit"
                    id="submit"
                    className="btn btn-primary w-full max-w-xs mt-4 text-white"
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Submitting..." : "Create User"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </Sidebar>
      </>
    );
  };
  
  export default CreateUsers;
  