import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { PlusCircle, MinusCircle } from "react-feather";

const CreateTasksForm = () => {
  //const [isFigure, setIsFigure] = useState(false);
  const [tasks, setTasks] = useState([
    {
      taskName: "",
      expectedOutput: "",
      isFigure: false,
      figure: "",
      assignedUser: "",
      startDate: "", // Add start date field to the tasks state
      endDate: "", // Add end date field to the tasks state
    },
  ]);
  // const feather = require('feather-icons');
  const [users, setUsers] = useState([]); // State to hold users fetched from the backend
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const handleCheckboxChange = (e) => {
  //   setIsFigure(e.target.checked);
  // };

  const addTask = () => {
    setTasks([
      ...tasks,
      {
        taskName: "",
        expectedOutput: "",
        isFigure: false,
        figure: "",
        assignedUser: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  const removeTask = (index) => {
    if (tasks.length > 1) {
      const updatedTasks = [...tasks];
      updatedTasks.splice(index, 1);
      setTasks(updatedTasks);
    }
  };
  useEffect(() => {
    // Fetch all users from the backend upon component mount
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/users"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        const userData = await response.json();
        setUsers(userData); // Set the fetched users in state
      } catch (error) {
        console.error(error);
        // Handle errors if needed
      }
    };

    fetchUsers();
  }, []);
  const handleTaskChange = (index, field, value) => {
    setTasks((prevTasks) => {
      const updatedTasks = prevTasks.map((task, i) => {
        if (i === index) {
          if (field === "assignedUser") {
            const selectedUser = users.find((user) => user.fullnames === value);
            const staffId = selectedUser ? selectedUser.user_id : "";

            return {
              ...task,
              assignedUser: value,
              staff_id: staffId,
            };
          }

          if (field === "startDate" || field === "endDate") {
            const formattedDate = value
              ? new Date(value).toISOString().split("T")[0]
              : "";

            // Validate date ranges
            const isStartDateValid =
              field === "startDate"
                ? !task.endDate || formattedDate <= task.endDate
                : true;

            const isEndDateValid =
              field === "endDate"
                ? !task.startDate || formattedDate >= task.startDate
                : true;

            if (isStartDateValid && isEndDateValid) {
              return {
                ...task,
                [field]: formattedDate,
              };
            } else {
              // Display a toast message with the exact error
              toast.error(
                field === "startDate"
                  ? "Start date cannot be after end date"
                  : "End date cannot be before start date",
                {
                  position: "top-center",
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );

              // Log the error to the console
              console.error(
                field === "startDate"
                  ? "Start date cannot be after end date"
                  : "End date cannot be before start date"
              );

              return task; // Do not update the state if the date range is invalid
            }
          }

          return { ...task, [field]: value };
        }

        return task;
      });

      return updatedTasks;
    });
  };

  // Handle checkbox and figure separately
  const handleCheckboxChange = (index, isChecked) => {
    const updatedTasks = tasks.map((task, i) => {
      if (i === index) {
        return {
          ...task,
          isFigure: isChecked,
          figure: isChecked ? task.figure || "" : "", // Update figure based on checkbox state
        };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const handleFigureChange = (index, value) => {
    const updatedTasks = tasks.map((task, i) => {
      if (i === index && task.isFigure) {
        return {
          ...task,
          figure: formatNumber(value),
        };
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  // Update this code.
  const handleSubmitTasks = async () => {
    setLoading(true); // Set loading state to true before making the request
    setError(""); // Clear any previous errors
    try {
      const tasksData = tasks.map((task) => ({
        name: task.taskName,
        expected_output: task.expectedOutput,
        figure: task.figure,
        staff_id: task.staff_id,
        start_date: task.startDate,
        end_date: task.endDate,
      }));
      console.log("tasks data", tasksData);
      console.log("stringified tasks data", JSON.stringify(tasksData));

      const response = await fetch("https://workspace.optiven.co.ke/api/perf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tasksData),
      });

      console.log("Response status:", response.status); // Log the response status

      setLoading(false); // Set loading state to false after the request is completed

      if (!response.ok) {
        throw new Error("An error occurred while submitting tasks");
      }

      // If successful, display success message
      toast.success("Tasks submitted successfully!", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      // Optionally reset the form or perform other actions after successful submission
      // For example, clear the form inputs
      setTasks([
        {
          taskName: "",
          expectedOutput: "",
          isFigure: false,
          figure: "",
          assignedUser: "",
          startDate: "",
          endDate: "",
        },
      ]);
    } catch (error) {
      // Display error message if submission fails
      setLoading(false); // Set loading state to false in case of an error
      setError("An error occurred. Please try again."); // Update error message

      // Display error notification
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.error(error);
    }
  };

  const formatNumber = (value) => {
    // Remove commas from the value (if any exist) and parse it to a number
    const parsedValue = parseFloat(value.replace(/,/g, ""));

    // Check if the parsed value is a valid number
    if (!isNaN(parsedValue)) {
      // Use toLocaleString to add commas for better readability
      return parsedValue.toLocaleString("en-US");
    }

    return value;
  };

  return (
    <Sidebar>
      <div className="flex justify-center items-center mx-10 my-10 flex flex-wrap gap-8">
        {tasks.map((task, index) => (
          <div key={index} className="card w-96 bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title text-2xl font-semibold mb-4">
                Create Task
              </h2>
              <form action="#" className="space-y-4">
                {/* Task Name */}
                <div>
                  <label
                    htmlFor={`taskName_${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Task Name
                  </label>
                  <input
                    type="text"
                    id={`taskName_${index}`}
                    name="task_name"
                    placeholder="Type here"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={task.taskName}
                    onChange={(e) =>
                      handleTaskChange(index, "taskName", e.target.value)
                    }
                  />
                </div>

                {/* Expected Output */}
                <div>
                  <label
                    htmlFor={`expectedOutput_${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Expected Output
                  </label>
                  <input
                    type="text"
                    id={`expectedOutput_${index}`}
                    name="expected_output"
                    placeholder="Type here"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={task.expectedOutput}
                    onChange={(e) =>
                      handleTaskChange(index, "expectedOutput", e.target.value)
                    }
                  />
                </div>

                {/* Checkbox for Is it a figure? */}
                <div className="form-control">
                  <label className="cursor-pointer flex items-center">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-success"
                      checked={task.isFigure}
                      onChange={(e) =>
                        handleTaskChange(index, "isFigure", e.target.checked)
                      }
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      Is it a figure?
                    </span>
                  </label>
                </div>

                {/* Figure Input field */}
                {task.isFigure && (
                  <div>
                    <label
                      htmlFor={`figureInput_${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      Figure
                    </label>
                    <input
                      type="text"
                      id={`figureInput_${index}`}
                      name="figure_input"
                      placeholder="Enter amount in Ksh..."
                      className="input input-bordered input-primary w-full max-w-xs"
                      value={task.figure}
                      onChange={(e) =>
                        handleTaskChange(
                          index,
                          "figure",
                          formatNumber(e.target.value)
                        )
                      }
                    />
                  </div>
                )}

                {/* Assigned User */}
                <div>
                  <label
                    htmlFor={`assignedUser_${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Assigned User
                  </label>
                  <input
                    type="text"
                    id={`assignedUser_${index}`}
                    name="assignedUser"
                    placeholder="Type user's name"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={task.assignedUser}
                    onChange={(e) =>
                      handleTaskChange(index, "assignedUser", e.target.value)
                    }
                    list={`assignedUserSuggestions_${index}`}
                  />
                  <datalist id={`assignedUserSuggestions_${index}`}>
                    {users.map((user) => (
                      <option key={user.user_id} value={user.fullnames} />
                    ))}
                  </datalist>
                </div>
                {/* Start Date */}
                <div>
                  <label
                    htmlFor={`startDate_${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    Start Date
                  </label>
                  <input
                    type="date"
                    id={`startDate_${index}`}
                    name="start_date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={task.startDate}
                    onChange={(e) =>
                      handleTaskChange(index, "startDate", e.target.value)
                    }
                  />
                </div>

                {/* End Date */}
                <div>
                  <label
                    htmlFor={`endDate_${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    End Date
                  </label>
                  <input
                    type="date"
                    id={`endDate_${index}`}
                    name="end_date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={task.endDate}
                    onChange={(e) =>
                      handleTaskChange(index, "endDate", e.target.value)
                    }
                  />
                </div>
                {/* Remove button for additional forms */}
                <div className="w-full flex justify-center mt-4 space-x-4">
                  {index !== 0 && (
                    <button
                      onClick={() => removeTask(index)}
                      className="btn btn-danger btn-circle text-white"
                      style={{ backgroundColor: "#df4747" }}
                    >
                      <MinusCircle size={30} />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        ))}
        <div className="w-full flex justify-center space-x-4 mt-4">
          <button
            onClick={addTask}
            className="btn btn-primary btn-circle text-white"
          >
            <PlusCircle size={30} />
          </button>
          <button onClick={handleSubmitTasks} className="btn btn-primary">
            Submit Tasks
          </button>
        </div>
      </div>
    </Sidebar>
  );
};

export default CreateTasksForm;
