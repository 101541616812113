import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import CountyBarChart from "../dash/Charts/CountyBarChart";
import { useSelector } from "react-redux";

const ViewCounty = () => {
  const [counties, setCounties] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const accessRoles = accessRole.split("#");
  const isIvoryAdmin = accessRoles.includes("isIvoryAdmin");
  const isIvoryHos = accessRoles.includes("isIvoryHos");
  const isIvoryGm = accessRoles.includes("isIvoryGm");

  useEffect(() => {
    const fetchCounties = async () => {
      try {
        let url = "https://workspace.optiven.co.ke/api/county";

        if (isIvoryHos) {
          url += "/region1";
        } else if (isIvoryGm) {
          url += "/region2";
        }

        const response = await fetch(url);
        const data = await response.json();

        if (Array.isArray(data)) {
          setCounties(data);
        } else {
          console.error("Invalid response format. Expected an array.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchCounties();
  }, [isIvoryAdmin, isIvoryHos, isIvoryGm]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredCounties = counties.filter((county) => {
    const month = new Date(county.endDate).getMonth() + 1; // Assuming endDate is the field containing the end date
    return selectedMonth ? month.toString() === selectedMonth : true;
  });

  return (
    <Sidebar>
      <div className="container px-4 py-6 mx-auto">
        {/* Dropdown for selecting month */}
        <div className="flex justify-end mb-4">
          <select
            className="border p-1"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            <option value="">All Months</option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <div className="overflow-x-auto card bg-base-100 shadow-xl">
          <table className="table table-compact">
            <thead>
              <tr>
                <th>ID</th>
                <th className="text-center">Region</th>
                <th className="text-center">County Activated</th>
                <th className="text-center">Contacts Obtained</th>
                <th className="text-center">Site Visits</th>
                <th className="text-center">Sales Achieved</th>
                <th className="text-center">Referrals</th>
                <th className="text-center">On Queue</th>
                {/* Add more columns based on your database schema */}
              </tr>
            </thead>
            <tbody>
              {filteredCounties.map((county, i) => (
                <tr key={county.id}>
                  <td>{i + 1}</td>
                  <td className="text-center">{county.region}</td>
                  <td className="text-center">{county.countyActivated}</td>
                  <td className="text-center">{county.contactsObtained}</td>
                  <td className="text-center">{county.siteVisits}</td>
                  <td className="text-center">{county.salesAchieved}</td>
                  <td className="text-center">{county.referrals}</td>
                  <td className="text-center">{county.onQueue}</td>
                  {/* Add more cells based on your database schema */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <CountyBarChart
          counties={filteredCounties}
          selectedMonth={selectedMonth}
        />
      </div>
    </Sidebar>
  );
};

export default ViewCounty;
