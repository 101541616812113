import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectStats } from "../components/ProjectStats";
import "./styles/Moons.css";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";

const ProjectInfo = () => {
  const [valAdditions, setValAdditions] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const accessRoles = accessRole.split("#");
  const department = useSelector((state) => state.user.user.department);
  const maintenance = accessRoles.includes("maintenance");
  const valueAddition = accessRole.includes("valueAddition");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");
  const ict = accessRole.includes(department === "ICT SYSTEMS");
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [projectResponse, valueAdditionsResponse] = await Promise.all([
          axios.get(`https://workspace.optiven.co.ke/api/projects/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
          axios.get(
            `https://workspace.optiven.co.ke/api/projects/${id}/value-additions`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          ),
        ]);

        setValAdditions(valueAdditionsResponse.data);
        setProjectDetails(projectResponse.data.project_name);

        console.log("value additions in project", valueAdditionsResponse.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false); // Set loading state to false after fetching data
      }
    };

    fetchData();
  }, [id]);

  const addValueAddition = (valueAdditionId) => {
    navigate(`/projects/${id}/add-value-addition/${valueAdditionId}`);
  };
  if (isLoading) {
    // Render loading state if isLoading is true
    return (
      <div className="font-sans flex justify-center items-center mt-20 italic text-3xl">
        Loading...
      </div>
    );
  } else {
    return (
      <>
        <Sidebar>
          <div className="hero min-h-screen moons">
            <ProjectStats
              name={projectDetails || "Project Name"}
              numberOfValueAdditions={valAdditions.length}
              valueAdditions={
                Array.isArray(valAdditions) ? (
                  valAdditions.map((va) => (
                    <button
                      className="btn gap-2 m-1 text-xs"
                      key={va.id}
                      onClick={() => {
                        if (
                          (!maintenance && !operations) ||
                          valueAddition ||
                          valueAdmin ||
                          ict
                        ) {
                          addValueAddition(va.id);
                        }
                      }}
                    >
                      {va.value}
                    </button>
                  ))
                ) : (
                  <span className="italic font-thin text-error-content">
                    No value additions to show. Add one to get started.
                  </span>
                )
              }
            />
          </div>
        </Sidebar>
      </>
    );
  }
};

export default ProjectInfo;
