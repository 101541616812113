import axios from "axios";

import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";

const CreateConstructionManager = () => {
  const navigate = useNavigate();

  const initialValues = {
    constructor_name: "",
    phone: "",
    email: "",
  };

  const validationSchema = Yup.object({
    constructor_name: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .max(255, "Exceeded the characters limit")
      .required("Name is required"),
    phone: Yup.string()
      .min(10, "Enter a valid phone number in the format 07XXXXXX")
      .max(10, "Enter a valid phone number in the format 07XXXXXX"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.post(
        "https://workspace.optiven.co.ke/api/construction-managers",
        {
          constructor_name: values.constructor_name,
          phone: values.phone,
          email: values.email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        },
      );
      console.log(response.data);
      toast.success("Construction Manager added successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // redirect to home page
      navigate("/view-construction-manager");
    } catch (error) {
      console.error(error.response.data);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setSubmitting(false);
  };
  return (
    <>
      <Sidebar>
        <div className="hero min-h-screen">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form className="form-control w-full max-w-xs">
                <label htmlFor="name" className="label">
                  <span className="label-text font-bold">Name</span>
                </label>
                <Field
                  type="text"
                  id="constructor_name"
                  name="constructor_name"
                  placeholder="John Smith"
                  className="input input-bordered w-full max-w-xs"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="phone" className="label">
                  <span className="label-text font-bold">Phone Number</span>
                </label>
                <Field
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="07XXXXXXXX"
                  className="input input-bordered w-full max-w-xs"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <label htmlFor="email" className="label">
                  <span className="label-text font-bold">Email</span>
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="john@mail.com"
                  className="input input-bordered w-full max-w-xs"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

                <button
                  type="submit"
                  id="submit"
                  className="btn btn-primary w-full max-w-xs mt-4 text-white"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting
                    ? "Submitting..."
                    : "Add Construction Manager"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateConstructionManager;
