import React from "react";

const PlotCounterCard = ({
  // openPlotsNumber,
  // reservedPlotsNumber,
  // soldPlotsNumber,
}) => {
  const plotCounterCardStyle = {
    backgroundColor: "#e8cbe8",
    padding: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const plotCardStyle = {
    backgroundColor: "#f2bba0",
    padding: "10px",
    marginBottom: "10px",
    width: "80%",
  };

  // Define a style for white text
  const whiteText = {
    color: "white",
  };

  return (
    <div className="card bg-purple-300 shadow-xl" style={plotCounterCardStyle}>
      <h2 className="card-title" style={{ fontSize: "24px" }}>
        Plot Information
      </h2>
      <div
        className="card"
        style={{ ...plotCardStyle, backgroundColor: "#49e183" }}
      >
        <h2 className="card-title" style={whiteText}>
          Open Plots
        </h2>
        <div
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px", ...whiteText }}
        >
          {/* {openPlotsNumber} */}
        </div>
      </div>
      <div
        className="card"
        style={{ ...plotCardStyle, backgroundColor: "#ffd700" }}
      >
        <h2 className="card-title">Reserved Plots</h2>
        <div
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px" }}
        >
          {/* {reservedPlotsNumber} */}
        </div>
      </div>
      <div
        className="card"
        style={{ ...plotCardStyle, backgroundColor: "#db1515" }}
      >
        <h2 className="card-title" style={whiteText}>
          Sold Plots
        </h2>
        <div
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "10px", ...whiteText }}
        >
          {/* {soldPlotsNumber} */}
        </div>
      </div>
      <div className="legend-item flex items-center m-5">
        <div
          className="legend-color"
          style={{
            width: "20px",
            height: "20px",
            marginRight: "10px",
            backgroundColor: "transparent",
            cursor: "pointer",
          }}
        >
          <div style={{ cursor: "pointer" }}>🖱️</div>
        </div>
        <div className="legend-label">
          Click on the individual plots to view plot info.
        </div>
      </div>
      <div className="legend-item flex items-center m-5">
        <div className="legend-label">
          <span role="img" aria-label="search-icon">
            🔍
          </span>{" "}
          You can now search for a specific plot using the search button at the top right corner.
        </div>
      </div>
    </div>
  );
};

export default PlotCounterCard;
