import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronsDown } from "react-feather";
import useWindowSize from "../hooks/useWindowSize";

const Panel = ({ open, setOpen, id, title, tasks, currentPhase, phaseIndex, toggleTaskCompletion, allTasksCompleted, handleNextPhase }) => {
    const { width } = useWindowSize();
    const isOpen = open === id;

    return (
        <>
            <button
                className="bg-white hover:bg-slate-50 transition-colors p-3 border-b-[1px] border-slate-200 flex flex-row justify-between items-center"
                onClick={() => setOpen(id)}
            >
                <span className="text-xl font-light">
                    {title}
                    <span className={`badge ml-2 ${phaseIndex < currentPhase ? 'bg-green-500' : 'bg-yellow-500'}`}>
                        {phaseIndex < currentPhase ? 'Completed' : 'Upcoming'}
                    </span>
                </span>
                <ChevronsDown className={`w-6 h-6 ${isOpen ? "rotate-180" : ""}`} />
            </button>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        key={`panel-${id}`}
                        variants={width && width > 1024 ? panelVariants : panelVariantsSm}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        className="w-full overflow-hidden"
                    >
                        <div className="px-4 py-2 bg-gray-100">
                            <ul className="list-disc pl-5">
                                {tasks.map((task, taskIndex) => (
                                    <li
                                        key={task.id}
                                        className={`cursor-pointer mb-1 ${task.completed ? 'text-green-500' : 'text-yellow-500'}`}
                                        onClick={() => toggleTaskCompletion(phaseIndex, taskIndex, task.id)}
                                        style={{ pointerEvents: phaseIndex === currentPhase ? 'auto' : 'none', opacity: phaseIndex === currentPhase ? 1 : 0.5 }}
                                    >
                                        {task.name}
                                    </li>
                                ))}
                            </ul>
                            {phaseIndex === currentPhase && allTasksCompleted(tasks) && (
                                <button
                                    onClick={handleNextPhase}
                                    className='btn btn-secondary mt-4'
                                >
                                    {currentPhase < tasks.length - 1
                                        ? 'Proceed to Next Phase'
                                        : 'Complete Construction'}
                                </button>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

const panelVariants = {
    open: {
        width: "100%",
        height: "100%",
    },
    closed: {
        width: "0%",
        height: "100%",
    },
};

const panelVariantsSm = {
    open: {
        width: "100%",
        height: "200px",
    },
    closed: {
        width: "100%",
        height: "0px",
    },
};

export default Panel;
