import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Insights from "../../components/AdminInsights";
import axios from "axios";
import DonutChart from "../../components/DonutChart";
import RadarChart from "../../components/RadarChart";
import UpdateTaskModal from "./UpdateTask";
import { useSelector } from "react-redux";
import TargetCompletionCard from "../../components/TargetCompletion";
import HosInsights from "../../components/HosInsights";
import GmInsights from "../../components/GmInsights";
import TeamInsights from "../../components/TeamsMIBInsights";

const UserDashboard = () => {
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");

  // Define access role checks
  const isIvoryAdmin = accessRoles.includes("isIvoryAdmin");
  const isIvoryDirector = accessRoles.includes("isIvoryDirector");
  const isIvoryTeam = accessRoles.includes("isIvoryTeam");
  const isIvoryHos = accessRoles.includes("isIvoryHos");
  const isIvoryGm = accessRoles.includes("isIvoryGm");

  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTaskForUpdate, setSelectedTaskForUpdate] = useState(null);

  const userId = useSelector((state) => state.user.user.user_id);

  const calculateDaysLeft = (endDate) => {
    const now = new Date();
    const due = new Date(endDate);
    due.setHours(0, 0, 0, 0);

    const timeDiff = due - now;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysLeft;
  };

  const fetchUserTasks = async () => {
    try {
      // Get the current month's start and end dates
      const currentDate = new Date();
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      );

      // Add 1 day to the end date
      endOfMonth.setDate(endOfMonth.getDate() + 1);

      // Format dates to ISO strings
      const startDateString = startOfMonth.toISOString().split("T")[0];
      const endDateString = endOfMonth.toISOString().split("T")[0];

      // Fetch user tasks for the current month
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/perf/assigned/${userId}/${startDateString}/${endDateString}`
      );

      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserTasks();
    }
  }, [userId]);

  const openUpdateModal = (taskId) => {
    setSelectedTaskForUpdate(taskId);
    setIsModalOpen(true);
  };

  const closeUpdateModal = () => {
    setSelectedTaskForUpdate(null);
    setIsModalOpen(false);
  };

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const firstName = user.fullnames
    ? user.fullnames.trim().split(" ")[0]
    : "user";

  const now = new Date();
  const hour = now.getHours();
  let greeting;

  if (hour < 12) {
    greeting = "Good morning,";
  } else if (hour >= 12 && hour < 17) {
    greeting = "Good afternoon,";
  } else {
    greeting = "Good evening,";
  }

  const renderInsightsComponent = () => {
    if (
      isIvoryAdmin ||
      isIvoryDirector ||
      isIvoryTeam ||
      department === "ICT SYSTEMS"
    ) {
      return <Insights />;
    } else if (isIvoryHos) {
      return <HosInsights />;
    } else if (isIvoryGm) {
      return <GmInsights />;
    } else {
      // Add a default component or handle the case where no role matches
      return <div>No matching role found</div>;
    }
  };

  return (
    <Sidebar>
      <div className="h-full w-full flex overflow-hidden antialiased text-gray-800 bg-white">
        <div className="flex-1 flex flex-col overflow-y-auto">
          <main className="flex-grow flex flex-col min-h-0 border-t">
            <section className="flex min-h-0 flex-col flex-auto border-l px-4 md:px-8 lg:px-16 xl:px-24">
              <div className="flex flex-col items-center">
                <h2 className="mt-6 text-4xl sm:text-5xl lg:text-7xl brave tracking-wider font-thick text-center">
                  {greeting}{" "}
                  <span className="brave tracking-wider font-thick">
                    {firstName}.
                  </span>
                </h2>
              </div>

              {renderInsightsComponent()}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div>
                  <DonutChart tasks={tasks} />
                </div>
                <div>
                  <TargetCompletionCard />
                  {/* Uncomment the following line if you want to add the RadarChart */}
                  {/* <RadarChart /> */}
                </div>
              </div>

              {<TeamInsights />}

              <header className="bg-white border-t flex items-center py-1 px-4">
                <div className="flex">
                  <h2 id="content-caption" className="font-semibold">
                    Tasks requiring your attention.
                  </h2>
                </div>
              </header>
              <section className="flex min-h-0">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Expected Output</th>
                      <th>Figure</th>
                      <th>Due Date Progress</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tasks
                      .sort(
                        (a, b) =>
                          new Date(b.created_at) - new Date(a.created_at)
                      )
                      .sort((a, b) => (a.actual_output === null ? -1 : 1))
                      .map((task, index) => {
                        const dueDate = new Date(task.end_date);
                        dueDate.setDate(dueDate.getDate() + 1);
                        const daysLeft = calculateDaysLeft(dueDate);
                        const progress =
                          daysLeft > 0
                            ? Math.min(
                                100,
                                (100 - (daysLeft * 100) / 30).toFixed(2)
                              )
                            : 100;

                        const isActualOutputUpdated =
                          task.actual_output !== null;
                        //const isCompleted = task.actual_output !== null;
                        const isPastDue =
                          daysLeft <= 0 && !isActualOutputUpdated;

                        return (
                          <tr
                            key={index}
                            className={
                              index % 2 === 0 ? "bg-gray-200" : "bg-white"
                            }
                          >
                            <td></td>
                            <td>{task.name}</td>
                            <td>{task.expected_output}</td>
                            <td>{Number(task.figure).toLocaleString()}</td>
                            <td>
                              <div
                                className="progress"
                                style={{ height: "20px" }}
                              >
                                <div
                                  className={`badge ${
                                    isPastDue
                                      ? "badge-error"
                                      : isActualOutputUpdated
                                      ? "badge-success"
                                      : "badge-warning"
                                  } gap-2`}
                                >
                                  {isPastDue
                                    ? "Past due"
                                    : isActualOutputUpdated
                                    ? "Actual output updated"
                                    : `${daysLeft} day${
                                        daysLeft !== 1 ? "s" : ""
                                      } left`}
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                className={`btn btn-outline ${
                                  isActualOutputUpdated || isPastDue
                                    ? "btn-disabled"
                                    : "btn-success"
                                }`}
                                onClick={() =>
                                  !isActualOutputUpdated &&
                                  !isPastDue &&
                                  openUpdateModal(task.id)
                                }
                                disabled={isActualOutputUpdated || isPastDue}
                              >
                                Details
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </section>
            </section>
          </main>
        </div>
      </div>
      <UpdateTaskModal
        isOpen={isModalOpen}
        onClose={closeUpdateModal}
        task={selectedTaskForUpdate}
      />
      <div style={{ height: "100px" }}></div>
    </Sidebar>
  );
};

export default UserDashboard;
