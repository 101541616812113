// ViewActivities component
import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import WorkPlanCalendar from "../../components/WorkPlanCalendar";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

const formatDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date
    .toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-");
};

const ViewActivities = () => {
  const [activities, setActivities] = useState([]);
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.user.user_id);

  useEffect(() => {
    // Fetch activities data from the server
    const fetchActivities = async () => {
      try {
        const response = await fetch(
          `https://workspace.optiven.co.ke/api/workplan-activities?user_id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        console.log("activities:", data); // Debugging statement

        // Format activities and update state
        const formattedActivities = data.map((activity) => ({
          id: activity.id,
          workplan_id: activity.workplan_id,
          title: activity.title,
          time: `${formatDate(activity.date)}T${activity.time}`,
          date: activity.date,
          expected_output: activity.expected_output,
          measurable_achievement: activity.measurable_achievement,
          variance: activity.variance,
          comments: activity.comments,
        }));
        setActivities(formattedActivities);
        console.log("activities", formattedActivities);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivities();
  }, [token, userId]);

  return (
    <Sidebar>
      <div className="p-5">
        <WorkPlanCalendar activities={activities} />
      </div>
    </Sidebar>
  );
};

export default ViewActivities;
