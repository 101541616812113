import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

const EditConstructionManagers = () => {
  // finding the construction Manager
const loc = useLocation();
const url = loc.pathname;
const constructionManagerId = url.split("/")[2];

  const navigate = useNavigate();

  const [constructor_name, setConstructorName] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch the construction manager data from the backend API
    if (constructionManagerId) {
      axios
        .get(
          `https://workspace.optiven.co.ke/api/construction-managers/${constructionManagerId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
  
          }
        )
        .then((res) => {
          console.log(res.data);
          setConstructorName(res.data.constructor_name);
          setPhoneNumber(res.data.phone);
          setEmail(res.data.email);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [constructionManagerId]);


  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const construction_manager = {
      constructor_name,
      phone,
      email,
    };

    // Send a PATCH request to update the construction manager data in the backend API
    axios
      .patch(
        `https://workspace.optiven.co.ke/api/construction-managers/${constructionManagerId}`,
        construction_manager,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }
      )

      .then((res) => {
        setLoading(false);
        toast.success("Construction Manager updated!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(res.data);
        navigate("/view-construction-manager");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <form onSubmit={handleSubmit} className="form-control w-full max-w-xs">
          <label htmlFor="name" className="label">
            <span className="label-text font-bold">Name</span>
          </label>
          <input
            type="text"
            id="name"
            value={constructor_name}
            onChange={(event) => setConstructorName(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="email" className="label">
            <span className="label-text font-bold">Email</span>
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="phoneNumber" className="label">
            <span className="label-text font-bold">Phone Number</span>
          </label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(event) => setPhoneNumber(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />

          <button
            type="submit"
            disabled={loading}
            id="submit"
            className="btn btn-primary w-full max-w-xs mt-4 text-white"
          >
            {loading ? "Saving..." : "Update Construction Manager"}
          </button>
        </form>
      </div>
    </Sidebar>
  );
};

export default EditConstructionManagers;
