import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Profile = () => {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.user.user_id);

  console.log(userId);

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [modalOpened, setModalOpened] = useState(false);
  const [percentile, setPercentile] = useState(null);
  const [user, setUser] = useState([]);

  useEffect(() => {
    fetchUser();
    fetchMarketersPercentiles(); // Fetch marketer percentiles
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/users/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();

      // Parse and format the 'date' field
      const formattedUser = {
        ...data,
        date: new Date(data.date).toISOString().slice(0, 19).replace("T", " "), // Format as 'YYYY-MM-DD HH:MM:SS'
        reset_date: new Date(data.reset_date)
          .toISOString()
          .slice(0, 19)
          .replace("T", " "),
      };

      setUser(formattedUser);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMarketersPercentiles = async () => {
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/site-visit-requests/marketers-percentiles/${userId}`, // Use the new route to fetch marketer percentiles
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      setPercentile(data.percentile);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleChangePhone = async (e) => {
    e.preventDefault();
    setLoading(true);

    const trimmedNum = (num) => {
      return num.replace(/^\+/, "");
    };

    const updatedUserData = {
      ...user,
      phone_number: trimmedNum(phoneNumber),
    };

    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/users/${userId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedUserData),
        }
      );

      const data = await response.json();
      console.log(data);

      // Update the user state with the updated data
      setUser(updatedUserData);

      setLoading(false);
      handleCloseModal();
    } catch (error) {
      console.error("Fetch error:", error);
      setLoading(false);
    }
  };

  const handleView = () => {
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
  };

  const formatPercentile = (percentile) => {
    if (percentile === null) {
      return "Fetching percentile...";
    }

    let percentileString = Math.floor(percentile).toString();

    if (percentileString.endsWith("1") && !percentileString.endsWith("11")) {
      percentileString += "st";
    } else if (
      percentileString.endsWith("2") &&
      !percentileString.endsWith("12")
    ) {
      percentileString += "nd";
    } else if (
      percentileString.endsWith("3") &&
      !percentileString.endsWith("13")
    ) {
      percentileString += "rd";
    } else {
      percentileString += "th";
    }

    return percentileString + " percentile of site visits completed";
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto flex flex-col">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-8">
          <div className="h-full rounded-lg bg-gray-100 pb-10">
            <div className="flex justify-center items-center mt-6">
              <div className="w-15 h-15 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400 border-gray-500 p-2 border-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-10 h-10"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
              </div>
              <div>
                <h2 className="text-md font-bold ml-2">{user.fullnames}</h2>
                <h2 className="text-xs italic ml-2">{user.email}</h2>
              </div>
            </div>
            <div className="flex flex-col items-start justify-center m-6">
              <div className="flex justify-between">
                <div className="flex items-center justify-between">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-phone mr-2"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />{" "}
                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />{" "}
                  </svg>
                  <div className="font-bold text-sm italic mr-2">
                    {user.phone_number
                      ? "+" + user.phone_number
                      : "Missing phone number"}
                  </div>
                  <button
                    className="btn btn-xs text-white"
                    onClick={() => handleView()}
                  >
                    Edit
                  </button>
                </div>
              </div>
              {user.team && (
                <div className="flex justify-start my-1">
                  <div className="flex items-center justify-between">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M10 19.748V16.4C10 15.1174 10.9948 14.1076 12.4667 13.5321C11.5431 13.188 10.5435 13 9.5 13C7.61013 13 5.86432 13.6168 4.45286 14.66C5.33199 17.1544 7.41273 19.082 10 19.748ZM18.8794 16.0859C18.4862 15.5526 17.1708 15 15.5 15C13.4939 15 12 15.7967 12 16.4V20C14.9255 20 17.4843 18.4296 18.8794 16.0859ZM9.55 11.5C10.7926 11.5 11.8 10.4926 11.8 9.25C11.8 8.00736 10.7926 7 9.55 7C8.30736 7 7.3 8.00736 7.3 9.25C7.3 10.4926 8.30736 11.5 9.55 11.5ZM15.5 12.5C16.6046 12.5 17.5 11.6046 17.5 10.5C17.5 9.39543 16.6046 8.5 15.5 8.5C14.3954 8.5 13.5 9.39543 13.5 10.5C13.5 11.6046 14.3954 12.5 15.5 12.5ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <div className="font-bold text-sm italic ml-2">
                      {user.team_name}
                    </div>
                  </div>
                </div>
              )}
              {user.team && (
                <div className="flex justify-start">
                  <div className="flex items-center justify-between">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M2 22C2 17.5817 5.58172 14 10 14C14.4183 14 18 17.5817 18 22H16C16 18.6863 13.3137 16 10 16C6.68629 16 4 18.6863 4 22H2ZM10 13C6.685 13 4 10.315 4 7C4 3.685 6.685 1 10 1C13.315 1 16 3.685 16 7C16 10.315 13.315 13 10 13ZM10 11C12.21 11 14 9.21 14 7C14 4.79 12.21 3 10 3C7.79 3 6 4.79 6 7C6 9.21 7.79 11 10 11ZM18.2837 14.7028C21.0644 15.9561 23 18.752 23 22H21C21 19.564 19.5483 17.4671 17.4628 16.5271L18.2837 14.7028ZM17.5962 3.41321C19.5944 4.23703 21 6.20361 21 8.5C21 11.3702 18.8042 13.7252 16 13.9776V11.9646C17.6967 11.7222 19 10.264 19 8.5C19 7.11935 18.2016 5.92603 17.041 5.35635L17.5962 3.41321Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <div className="font-bold text-sm italic ml-2">
                      {user.region_name}
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-col items-start justify-between">
                <h1 className="menu-title font-bold underline mt-4">
                  Achievements
                </h1>
                <p className="text-start text-xs italic">
                  You need to have completed a few tasks/activities to get
                  achievement trophies
                </p>
              </div>
            </div>
          </div>
          <div className="h-full rounded-lg bg-gray-100 py-10 flex flex-col items-center lg:h-1/2">
            <div className="flex flex-col justify-center">
              <Link to="/" className="hover:underline">
                Menu
              </Link>
              <Link to="/feedback" className="hover:underline">
                Feedback
              </Link>
              <Link to="/login" className="hover:underline">
                Logout
              </Link>
            </div>
          </div>
          <div className="h-full rounded-lg bg-gray-100 lg:col-span-2">
            <div className="flex justify-center items-center my-6 mx-6 lg:justify-start">
              <div className="w-40 h-40 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400 border-gray-500 p-2 border-4">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-20 h-20"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                  <circle cx={12} cy={7} r={4} />
                </svg>
              </div>
            </div>
            <div className="flex justify-center items-center m-6 lg:justify-start">
              <div className="flex flex-col items-center justify-center">
                <div
                  className="radial-progress text-primary border-4 mx-2"
                  style={{ "--value": percentile }}
                >
                  {Math.floor(percentile)}%
                </div>
                <p className="w-1/2 text-sm text-center">
                  {formatPercentile(percentile)}
                </p>
              </div>
              {/* <div className="flex flex-col items-center justify-center">
                <div
                  className="radial-progress text-warning border-4 mx-2"
                  style={{ "--value": 75 }}
                >
                  75%
                </div>
                <p className="w-1/2 text-sm text-center">
                  75th percentile of something
                </p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div
                  className="radial-progress text-error border-4 mx-2"
                  style={{ "--value": 25 }}
                >
                  25%
                </div>
                <p className="w-1/2 text-sm text-center">
                  25th percentile of something
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for editing phone number */}
      {modalOpened && (
        <div className="fixed inset-0 flex justify-center items-center z-10 bg-black bg-opacity-50">
          <div className="modal-box container mx-auto">
            <button
              onClick={handleCloseModal}
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>

            <div className="flex flex-col">
              <label className="label font-bold">
                Enter Phone Number with Country Code
              </label>
              <input
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="input input-bordered"
                placeholder="+254712345678"
                required
                min={11}
                max={13}
              />
              <button
                onClick={handleChangePhone}
                className="btn btn-outline mt-2"
                disabled={!phoneNumber || phoneNumber.length <= 11}
              >
                {loading ? "Editing..." : "Edit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Profile;
