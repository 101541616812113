import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UploadDesign = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [file, setFile] = useState(null);

  useEffect(() => {
    // Fetch the list of clients from the server
    axios
      .get("https://workspace.optiven.co.ke/api/homes-clients")
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the clients!", error);
      });
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const maxSize = 10 * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds the maximum limit of 10MB.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFile(null); // Clear the file input
    } else {
      setFile(selectedFile);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedClient || !file) {
      toast.error("Please select a client and a file.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const formData = new FormData();
    formData.append("client_id", selectedClient);
    formData.append("designContract", file);

    axios
      .post(
        "https://workspace.optiven.co.ke/api/design/upload/design-contract",
        formData
      )
      .then((response) => {
        toast.success("File uploaded successfully!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Clear the inputs
        setSelectedClient("");
        setFile(null);
      })
      .catch((error) => {
        toast.error("There was an error uploading the file.", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error("There was an error uploading the file!", error);
      });
  };

  return (
    <Sidebar>
      <div className="flex items-center justify-center min-h-screen">
        <div className="max-w-md mx-auto my-10 p-8 bg-white shadow-md rounded-md">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">
            Upload Design Contract
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block mb-2 text-lg font-medium text-gray-700">
                Select Client
              </label>
              <select
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select a client</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.client_name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block mb-2 text-lg font-medium text-gray-700">
                Select File
              </label>
              <input
                type="file"
                onChange={handleFileChange}
                className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white p-3 rounded hover:bg-blue-700 transition duration-300"
            >
              Upload
            </button>
          </form>
        </div>
      </div>
    </Sidebar>
  );
};

export default UploadDesign;
