import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";

const ViewRequisition = () => {
  const userId = useSelector((state) => state.user.user.user_id);
  const [requisitions, setRequisitions] = useState([]);

  useEffect(() => {
    const fetchRequisition = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/requisitions?user_id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setRequisitions(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchRequisition();
  }, [userId]);

  return (
    <Sidebar>
      <div className="flex justify-center items-center m-3 font-serif ">
        <table className="table table-compact w-3/4 table-zebra">
          <thead>
            <tr className="text-center text-2xl">
              <th></th>
              <th>Commodity Name</th>
              <th>Quantity</th>
              <th>description</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="">
            {requisitions.map((requisition, i) => (
              <tr key={requisition.id} className="text-center">
                <td className="text-lg">{i + 1}</td>
                <td className="text-lg">{requisition.commodity_name}</td>
                <td className="text-lg">{requisition.quantity}</td>
                <td className="text-lg">{requisition.description}</td>
                <td className="font-bold text-xl">{requisition.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Sidebar>
  );
};

export default ViewRequisition;
