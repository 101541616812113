const geojsonData = {
  type: "FeatureCollection",
  
  
    features: [
      
      {
        "type": "Feature",
        "id": "sm023c10fe",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634035311, 61.617984902],
              [18.633874094, 61.617983383],
              [18.633874535, 61.617942605],
              [18.634036809, 61.61794898],
              [18.634035311, 61.617984902]
            ]
          ]
        },
        properties: {
        Unit_Number: "V37",
      },
      },
      {
        "type": "Feature",
        "id": "sm74450c0c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633874094, 61.617983383],
              [18.634035311, 61.617984902],
              [18.634034037, 61.618015464],
              [18.633873764, 61.618013954],
              [18.633874094, 61.617983383]
            ]
          ]
        },
        properties: {
        Unit_Number: "V36",
      },
      },
      {
        "type": "Feature",
        "id": "smc7b454d4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633873764, 61.618013954],
              [18.634034037, 61.618015464],
              [18.634032654, 61.618048626],
              [18.633873406, 61.618047125],
              [18.633873764, 61.618013954]
            ]
          ]
        },
        properties: {
        Unit_Number: "V35",
      },
      },
      {
        "type": "Feature",
        "id": "smdec8be92",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633873406, 61.618047125],
              [18.634032654, 61.618048626],
              [18.634031298, 61.618081138],
              [18.633873055, 61.618079647],
              [18.633873406, 61.618047125]
            ]
          ]
        },
        properties: {
        Unit_Number: "V34",
      },
      },
      {
        "type": "Feature",
        "id": "sm4fb6beb0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633873055, 61.618079647],
              [18.634031298, 61.618081138],
              [18.634029865, 61.618115498],
              [18.633872684, 61.618114017],
              [18.633873055, 61.618079647]
            ]
          ]
        },
        properties: {
        Unit_Number: "V33",
      },
      },
      {
        "type": "Feature",
        "id": "smc9088fea",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633872684, 61.618114017],
              [18.634029865, 61.618115498],
              [18.63402852, 61.618147741],
              [18.633872336, 61.61814627],
              [18.633872684, 61.618114017]
            ]
          ]
        },
        properties: {
        Unit_Number: "V32",
      },
      },
      {
        "type": "Feature",
        "id": "smb0ea86b2",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633872336, 61.61814627],
              [18.63402852, 61.618147741],
              [18.634027025, 61.618183581],
              [18.633871949, 61.618182119],
              [18.633872336, 61.61814627]
            ]
          ]
        },
        properties: {
        Unit_Number: "V31",
      },
      },
      {
        "type": "Feature",
        "id": "smf17fc0f3",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633871949, 61.618182119],
              [18.634027025, 61.618183581],
              [18.634025588, 61.618218036],
              [18.633871577, 61.618216584],
              [18.633871949, 61.618182119]
            ]
          ]
        },
        properties: {
        Unit_Number: "V30",
      },
      },
      {
        "type": "Feature",
        "id": "smf78d604d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633871577, 61.618216584],
              [18.634025588, 61.618218036],
              [18.634024177, 61.618251879],
              [18.633871211, 61.618250437],
              [18.633871577, 61.618216584]
            ]
          ]
        },
        properties: {
        Unit_Number: "V29",
      },
      },
      {
        "type": "Feature",
        "id": "smee6dd9de",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633871211, 61.618250437],
              [18.634024177, 61.618251879],
              [18.634022731, 61.618286552],
              [18.633870836, 61.618285121],
              [18.633871211, 61.618250437]
            ]
          ]
        },
        properties: {
        Unit_Number: "V28",
      },
      },
      {
        "type": "Feature",
        "id": "smbf1cb68b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633870836, 61.618285121],
              [18.634022731, 61.618286552],
              [18.634021241, 61.618322288],
              [18.63387045, 61.618320867],
              [18.633870836, 61.618285121]
            ]
          ]
        },
        properties: {
        Unit_Number: "V27",
      },
      },
      {
        "type": "Feature",
        "id": "sma9136636",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63387045, 61.618320867],
              [18.634021241, 61.618322288],
              [18.634019748, 61.618358086],
              [18.633870063, 61.618356676],
              [18.63387045, 61.618320867]
            ]
          ]
        },
        properties: {
        Unit_Number: "V26",
      },
      },
      {
        "type": "Feature",
        "id": "sm1282e027",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633870063, 61.618356676],
              [18.634019748, 61.618358086],
              [18.634018155, 61.618396283],
              [18.63386965, 61.618394883],
              [18.633870063, 61.618356676]
            ]
          ]
        },
        properties: {
        Unit_Number: "V25",
      },
      },
      {
        "type": "Feature",
        "id": "smee54c224",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63386965, 61.618394883],
              [18.634018155, 61.618396283],
              [18.634016801, 61.618428744],
              [18.633869299, 61.618427354],
              [18.63386965, 61.618394883]
            ]
          ]
        },
        properties: {
        Unit_Number: "V24",
      },
      },
      {
        "type": "Feature",
        "id": "sm3281ae18",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633869299, 61.618427354],
              [18.634016801, 61.618428744],
              [18.634015177, 61.618467678],
              [18.633868878, 61.618466299],
              [18.633869299, 61.618427354]
            ]
          ]
        },
        properties: {
        Unit_Number: "V23",
      },
      },
      {
        "type": "Feature",
        "id": "smf21527bf",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633868878, 61.618466299],
              [18.634015177, 61.618467678],
              [18.634013742, 61.618502088],
              [18.633868506, 61.61850072],
              [18.633868878, 61.618466299]
            ]
          ]
        },
        properties: {
        Unit_Number: "V22",
      },
      },
      {
        "type": "Feature",
        "id": "sm0184b421",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633868506, 61.61850072],
              [18.634013742, 61.618502088],
              [18.634012208, 61.618538871],
              [18.633868109, 61.618537513],
              [18.633868506, 61.61850072]
            ]
          ]
        },
        properties: {
        Unit_Number: "V21",
      },
      },
      {
        "type": "Feature",
        "id": "sm593d9156",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633868109, 61.618537513],
              [18.634012208, 61.618538871],
              [18.634010545, 61.618578759],
              [18.633867678, 61.618577412],
              [18.633868109, 61.618537513]
            ]
          ]
        },
        properties: {
        Unit_Number: "V20",
      },
      },
      {
        "type": "Feature",
        "id": "sm08235823",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633867678, 61.618577412],
              [18.634010545, 61.618578759],
              [18.634008927, 61.618617566],
              [18.633867259, 61.618616232],
              [18.633867678, 61.618577412]
            ]
          ]
        },
        properties: {
        Unit_Number: "V19",
      },
      },
      {
        "type": "Feature",
        "id": "sm99eb40e7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633867259, 61.618616232],
              [18.634008927, 61.618617566],
              [18.634007335, 61.618655737],
              [18.633866847, 61.618654413],
              [18.633867259, 61.618616232]
            ]
          ]
        },
        properties: {
        Unit_Number: "V18",
      },
      },
      {
        "type": "Feature",
        "id": "smf1d6625a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633866847, 61.618654413],
              [18.634007335, 61.618655737],
              [18.634005611, 61.618697081],
              [18.6338664, 61.618695769],
              [18.633866847, 61.618654413]
            ]
          ]
        },
        properties: {
        Unit_Number: "V17",
      },
      },
      {
        "type": "Feature",
        "id": "sm9224dbe5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.6338664, 61.618695769],
              [18.634005611, 61.618697081],
              [18.634003998, 61.618735762],
              [18.633865982, 61.618734462],
              [18.6338664, 61.618695769]
            ]
          ]
        },
        properties: {
        Unit_Number: "V16",
      },
      },
      {
        "type": "Feature",
        "id": "sm226f2655",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633865982, 61.618734462],
              [18.634003998, 61.618735762],
              [18.634002321, 61.618775971],
              [18.633865548, 61.618774682],
              [18.633865982, 61.618734462]
            ]
          ]
        },
        properties: {
        Unit_Number: "V15",
      },
      },
      {
        "type": "Feature",
        "id": "sm2ba12c3e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633865548, 61.618774682],
              [18.634002321, 61.618775971],
              [18.634000671, 61.618815542],
              [18.63386512, 61.618814264],
              [18.633865548, 61.618774682]
            ]
          ]
        },
        properties: {
        Unit_Number: "V14",
      },
      },
      {
        "type": "Feature",
        "id": "sm074347da",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63386512, 61.618814264],
              [18.634000671, 61.618815542],
              [18.633998964, 61.618856473],
              [18.633864678, 61.618855208],
              [18.63386512, 61.618814264]
            ]
          ]
        },
        properties: {
        Unit_Number: "V13",
      },
      },
      {
        "type": "Feature",
        "id": "sm0b3decca",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633864678, 61.618855208],
              [18.633998964, 61.618856473],
              [18.633997263, 61.618897256],
              [18.633864237, 61.618896002],
              [18.633864678, 61.618855208]
            ]
          ]
        },
        properties: {
        Unit_Number: "V12",
      },
      },
      {
        "type": "Feature",
        "id": "smfdc36902",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633864237, 61.618896002],
              [18.633997263, 61.618897256],
              [18.633995478, 61.618940051],
              [18.633863775, 61.61893881],
              [18.633864237, 61.618896002]
            ]
          ]
        },
        properties: {
        Unit_Number: "V11",
      },
      },
      {
        "type": "Feature",
        "id": "sm0a50f6aa",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633863775, 61.61893881],
              [18.633995478, 61.618940051],
              [18.633993721, 61.618982183],
              [18.63386332, 61.618980954],
              [18.633863775, 61.61893881]
            ]
          ]
        },
        properties: {
        Unit_Number: "V10",
      },
      },
      {
        "type": "Feature",
        "id": "smd3f1d091",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63386332, 61.618980954],
              [18.633993721, 61.618982183],
              [18.633991759, 61.619029236],
              [18.633862812, 61.619028021],
              [18.63386332, 61.618980954]
            ]
          ]
        },
        properties: {
        Unit_Number: "V9",
      },
      },
      {
        "type": "Feature",
        "id": "smea149b55",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633862812, 61.619028021],
              [18.633991759, 61.619029236],
              [18.633989109, 61.619092781],
              [18.63392773, 61.619092203],
              [18.633932202, 61.619076045],
              [18.633879899, 61.619074133],
              [18.633862465, 61.619060109],
              [18.633862812, 61.619028021]
            ]
          ]
        },
        properties: {
        Unit_Number: "V8",
      },
      },
      {
        "type": "Feature",
        "id": "sm0bc5da3b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633465785, 61.619082675],
              [18.633454843, 61.619152127],
              [18.633376985, 61.619149356],
              [18.63338235, 61.619090708],
              [18.633409171, 61.619080508],
              [18.633465785, 61.619082675]
            ]
          ]
        },
        properties: {
        Unit_Number: "V",
      },
      },
      {
        "type": "Feature",
        "id": "sm63c71966",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633909774, 61.619168321],
              [18.633920526, 61.619100079],
              [18.633925497, 61.619100269],
              [18.63392773, 61.619092203],
              [18.633989109, 61.619092781],
              [18.633985846, 61.619171029],
              [18.633909774, 61.619168321]
            ]
          ]
        },
        properties: {
        Unit_Number: "V7",
      },
      },
      {
        "type": "Feature",
        "id": "sm48f9b74e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633920526, 61.619100079],
              [18.633909774, 61.619168321],
              [18.63383149, 61.619165534],
              [18.633842274, 61.619097084],
              [18.633920526, 61.619100079]
            ]
          ]
        },
        properties: {
        Unit_Number: "V6",
      },
      },
      {
        "type": "Feature",
        "id": "smdc4be78e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633842274, 61.619097084],
              [18.63383149, 61.619165534],
              [18.633756108, 61.619162851],
              [18.633766924, 61.6190942],
              [18.633842274, 61.619097084]
            ]
          ]
        },
        properties: {
        Unit_Number: "V5",
      },
      },
      {
        "type": "Feature",
        "id": "sm9b9f19e5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633766924, 61.6190942],
              [18.633756108, 61.619162851],
              [18.633681524, 61.619160196],
              [18.633692372, 61.619091347],
              [18.633766924, 61.6190942]
            ]
          ]
        },
        properties: {
        Unit_Number: "V4",
      },
      },
      {
        "type": "Feature",
        "id": "smb38ac0f0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633692372, 61.619091347],
              [18.633681524, 61.619160196],
              [18.633607276, 61.619157553],
              [18.633618155, 61.619088507],
              [18.633692372, 61.619091347]
            ]
          ]
        },
        properties: {
        Unit_Number: "V3",
      },
      },
      {
        "type": "Feature",
        "id": "smab74b3f0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633618155, 61.619088507],
              [18.633607276, 61.619157553],
              [18.633528992, 61.619154766],
              [18.633539903, 61.619085512],
              [18.633618155, 61.619088507]
            ]
          ]
        },
        properties: {
        Unit_Number: "V2",
      },
      },
      {
        "type": "Feature",
        "id": "sm120bb0c4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539903, 61.619085512],
              [18.633528992, 61.619154766],
              [18.633454843, 61.619152127],
              [18.633465785, 61.619082675],
              [18.633539903, 61.619085512]
            ]
          ]
        },
        properties: {
        Unit_Number: "V1",
      },
      },
      {
        "type": "Feature",
        "id": "smcb775f9a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633486751, 61.617958262],
              [18.633355592, 61.617958084],
              [18.6333562, 61.617916788],
              [18.633486958, 61.6179238],
              [18.633486751, 61.617958262]
            ]
          ]
        },
        properties: {
        Unit_Number: "V90",
      },
      },
      {
        "type": "Feature",
        "id": "sm25c36463",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633340095, 61.619010859],
              [18.633480435, 61.619011049],
              [18.633480252, 61.619041622],
              [18.633462818, 61.619057559],
              [18.633339436, 61.619055646],
              [18.633340095, 61.619010859]
            ]
          ]
        },
        properties: {
        Unit_Number: "V118",
      },
      },
      {
        "type": "Feature",
        "id": "sm9a54831a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633480435, 61.619011049],
              [18.633340095, 61.619010859],
              [18.633340687, 61.618970677],
              [18.633480676, 61.618970867],
              [18.633480435, 61.619011049]
            ]
          ]
        },
        properties: {
        Unit_Number: "V117",
      },
      },
      {
        "type": "Feature",
        "id": "sm854cd379",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633480676, 61.618970867],
              [18.633340687, 61.618970677],
              [18.633341231, 61.618933747],
              [18.633480898, 61.618933936],
              [18.633480676, 61.618970867]
            ]
          ]
        },
        properties: {
        Unit_Number: "V116",
      },
      },
      {
        "type": "Feature",
        "id": "sm0b8bbc0a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633480898, 61.618933936],
              [18.633341231, 61.618933747],
              [18.633341794, 61.618895511],
              [18.633481127, 61.618895699],
              [18.633480898, 61.618933936]
            ]
          ]
        },
        properties: {
        Unit_Number: "V115",
      },
      },
      {
        "type": "Feature",
        "id": "sm19bbcc1b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633481127, 61.618895699],
              [18.633341794, 61.618895511],
              [18.633342357, 61.618857265],
              [18.633481356, 61.618857453],
              [18.633481127, 61.618895699]
            ]
          ]
        },
        properties: {
        Unit_Number: "V114",
      },
      },
      {
        "type": "Feature",
        "id": "sm57d1368a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633481356, 61.618857453],
              [18.633342357, 61.618857265],
              [18.633342948, 61.618817088],
              [18.633481597, 61.618817276],
              [18.633481356, 61.618857453]
            ]
          ]
        },
        properties: {
        Unit_Number: "V113",
      },
      },
      {
        "type": "Feature",
        "id": "sme93b82b7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633481597, 61.618817276],
              [18.633342948, 61.618817088],
              [18.633343502, 61.618779463],
              [18.633481823, 61.618779651],
              [18.633481597, 61.618817276]
            ]
          ]
        },
        properties: {
        Unit_Number: "V112",
      },
      },
      {
        "type": "Feature",
        "id": "sm4c502e82",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633481823, 61.618779651],
              [18.633343502, 61.618779463],
              [18.633344093, 61.618739296],
              [18.633482064, 61.618739483],
              [18.633481823, 61.618779651]
            ]
          ]
        },
        properties: {
        Unit_Number: "V111",
      },
      },
      {
        "type": "Feature",
        "id": "smd1325805",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633482064, 61.618739483],
              [18.633344093, 61.618739296],
              [18.633344647, 61.618701695],
              [18.633482289, 61.618701881],
              [18.633482064, 61.618739483]
            ]
          ]
        },
        properties: {
        Unit_Number: "V110",
      },
      },
      {
        "type": "Feature",
        "id": "sm4b4718a1",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633482289, 61.618701881],
              [18.633344647, 61.618701695],
              [18.633345238, 61.618661527],
              [18.63348253, 61.618661713],
              [18.633482289, 61.618701881]
            ]
          ]
        },
        properties: {
        Unit_Number: "V109",
      },
      },
      {
        "type": "Feature",
        "id": "sm2994ab67",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63348253, 61.618661713],
              [18.633345238, 61.618661527],
              [18.633345819, 61.618622047],
              [18.633482767, 61.618622233],
              [18.63348253, 61.618661713]
            ]
          ]
        },
        properties: {
        Unit_Number: "V108",
      },
      },
      {
        "type": "Feature",
        "id": "sm33b8bef0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633482767, 61.618622233],
              [18.633345819, 61.618622047],
              [18.633346457, 61.61857868],
              [18.633483027, 61.618578865],
              [18.633482767, 61.618622233]
            ]
          ]
        },
        properties: {
        Unit_Number: "V107",
      },
      },
      {
        "type": "Feature",
        "id": "sma277a3d9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633483027, 61.618578865],
              [18.633346457, 61.61857868],
              [18.633347001, 61.618541688],
              [18.633483249, 61.618541873],
              [18.633483027, 61.618578865]
            ]
          ]
        },
        properties: {
        Unit_Number: "V106",
      },
      },
      {
        "type": "Feature",
        "id": "sm18ee7d92",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633483249, 61.618541873],
              [18.633347001, 61.618541688],
              [18.633347602, 61.618500879],
              [18.633483494, 61.618501064],
              [18.633483249, 61.618541873]
            ]
          ]
        },
        properties: {
        Unit_Number: "V105",
      },
      },
      {
        "type": "Feature",
        "id": "sm2c436eda",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633483494, 61.618501064],
              [18.633347602, 61.618500879],
              [18.633348202, 61.618460087],
              [18.633483739, 61.618460271],
              [18.633483494, 61.618501064]
            ]
          ]
        },
        properties: {
        Unit_Number: "V104",
      },
      },
      {
        "type": "Feature",
        "id": "sm907f9343",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633483739, 61.618460271],
              [18.633348202, 61.618460087],
              [18.633348737, 61.618423755],
              [18.633483957, 61.618423938],
              [18.633483739, 61.618460271]
            ]
          ]
        },
        properties: {
        Unit_Number: "V103",
      },
      },
      {
        "type": "Feature",
        "id": "sm44dd99e3",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633483957, 61.618423938],
              [18.633348737, 61.618423755],
              [18.633349291, 61.618386148],
              [18.633484183, 61.61838633],
              [18.633483957, 61.618423938]
            ]
          ]
        },
        properties: {
        Unit_Number: "V102",
      },
      },
      {
        "type": "Feature",
        "id": "sm9185c8ef",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633484183, 61.61838633],
              [18.633349291, 61.618386148],
              [18.633349864, 61.618347256],
              [18.633484416, 61.618347439],
              [18.633484183, 61.61838633]
            ]
          ]
        },
        properties: {
        Unit_Number: "V101",
      },
      },
      {
        "type": "Feature",
        "id": "sm91a03190",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633484416, 61.618347439],
              [18.633349864, 61.618347256],
              [18.633350445, 61.618307763],
              [18.633484653, 61.618307945],
              [18.633484416, 61.618347439]
            ]
          ]
        },
        properties: {
        Unit_Number: "V100",
      },
      },
      {
        "type": "Feature",
        "id": "sm4f7a92f7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633484653, 61.618307945],
              [18.633350445, 61.618307763],
              [18.633351022, 61.618268547],
              [18.633484888, 61.618268728],
              [18.633484653, 61.618307945]
            ]
          ]
        },
        properties: {
        Unit_Number: "V99",
      },
      },
      {
        "type": "Feature",
        "id": "smcdd5b01c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633484888, 61.618268728],
              [18.633351022, 61.618268547],
              [18.633351613, 61.618228363],
              [18.633485129, 61.618228544],
              [18.633484888, 61.618268728]
            ]
          ]
        },
        properties: {
        Unit_Number: "V98",
      },
      },
      {
        "type": "Feature",
        "id": "sm5af0689d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633485129, 61.618228544],
              [18.633351613, 61.618228363],
              [18.633352157, 61.618191403],
              [18.633485351, 61.618191583],
              [18.633485129, 61.618228544]
            ]
          ]
        },
        properties: {
        Unit_Number: "V97",
      },
      },
      {
        "type": "Feature",
        "id": "sm98ecd984",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633485351, 61.618191583],
              [18.633352157, 61.618191403],
              [18.63335272, 61.618153158],
              [18.63348558, 61.618153338],
              [18.633485351, 61.618191583]
            ]
          ]
        },
        properties: {
        Unit_Number: "V96",
      },
      },
      {
        "type": "Feature",
        "id": "sm828bce1b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63348558, 61.618153338],
              [18.63335272, 61.618153158],
              [18.633353302, 61.618113642],
              [18.633485817, 61.618113821],
              [18.63348558, 61.618153338]
            ]
          ]
        },
        properties: {
        Unit_Number: "V95",
      },
      },
      {
        "type": "Feature",
        "id": "sm367d2c4e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633485817, 61.618113821],
              [18.633353302, 61.618113642],
              [18.633353865, 61.61807538],
              [18.633486047, 61.618075559],
              [18.633485817, 61.618113821]
            ]
          ]
        },
        properties: {
        Unit_Number: "V94",
      },
      },
      {
        "type": "Feature",
        "id": "sm86a017e7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633486047, 61.618075559],
              [18.633353865, 61.61807538],
              [18.633354391, 61.618039669],
              [18.633486261, 61.618039848],
              [18.633486047, 61.618075559]
            ]
          ]
        },
        properties: {
        Unit_Number: "V93",
      },
      },
      {
        "type": "Feature",
        "id": "sma6db65d8",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633486261, 61.618039848],
              [18.633354391, 61.618039669],
              [18.633354982, 61.61799952],
              [18.633486502, 61.617999699],
              [18.633486261, 61.618039848]
            ]
          ]
        },
        properties: {
        Unit_Number: "V92",
      },
      },
      {
        "type": "Feature",
        "id": "sm0af837a1",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633486502, 61.617999699],
              [18.633354982, 61.61799952],
              [18.633355592, 61.617958084],
              [18.633486751, 61.617958262],
              [18.633486502, 61.617999699]
            ]
          ]
        },
        properties: {
        Unit_Number: "V91",
      },
      },
      {
        "type": "Feature",
        "id": "smbac2dff4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633823545, 61.61798323],
              [18.633691376, 61.61798275],
              [18.633692257, 61.617933365],
              [18.633824249, 61.617939417],
              [18.633823545, 61.61798323]
            ]
          ]
        },
        properties: {
        Unit_Number: "V38",
      },
      },
      {
        "type": "Feature",
        "id": "sma79eeab9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633685962, 61.61828614],
              [18.633818668, 61.618286622],
              [18.633818213, 61.618314898],
              [18.633800108, 61.618324461],
              [18.633685287, 61.618323998],
              [18.633685962, 61.61828614]
            ]
          ]
        },
        properties: {
        Unit_Number: "V47",
      },
      },
      {
        "type": "Feature",
        "id": "smf0e8d87d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633818668, 61.618286622],
              [18.633685962, 61.61828614],
              [18.633686645, 61.61824786],
              [18.633819283, 61.618248341],
              [18.633818668, 61.618286622]
            ]
          ]
        },
        properties: {
        Unit_Number: "V46",
      },
      },
      {
        "type": "Feature",
        "id": "sm5c7256cd",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633819283, 61.618248341],
              [18.633686645, 61.61824786],
              [18.633687316, 61.61821025],
              [18.633819887, 61.618210732],
              [18.633819283, 61.618248341]
            ]
          ]
        },
        properties: {
        Unit_Number: "V45",
      },
      },
      {
        "type": "Feature",
        "id": "sm652ba762",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633819887, 61.618210732],
              [18.633687316, 61.61821025],
              [18.633687943, 61.618175132],
              [18.633820452, 61.618175614],
              [18.633819887, 61.618210732]
            ]
          ]
        },
        properties: {
        Unit_Number: "V44",
      },
      },
      {
        "type": "Feature",
        "id": "sm22a3f444",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633820452, 61.618175614],
              [18.633687943, 61.618175132],
              [18.633688678, 61.618133903],
              [18.633821115, 61.618134384],
              [18.633820452, 61.618175614]
            ]
          ]
        },
        properties: {
        Unit_Number: "V43",
      },
      },
      {
        "type": "Feature",
        "id": "sm1dc61645",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633821115, 61.618134384],
              [18.633688678, 61.618133903],
              [18.633689372, 61.618095022],
              [18.633821739, 61.618095556],
              [18.633821115, 61.618134384]
            ]
          ]
        },
        properties: {
        Unit_Number: "V42",
      },
      },
      {
        "type": "Feature",
        "id": "sm2d2928e4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633821739, 61.618095556],
              [18.633689372, 61.618095022],
              [18.633689998, 61.618059959],
              [18.633822303, 61.61806044],
              [18.633821739, 61.618095556]
            ]
          ]
        },
        properties: {
        Unit_Number: "V41",
      },
      },
      {
        "type": "Feature",
        "id": "smd709880b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633822303, 61.61806044],
              [18.633689998, 61.618059959],
              [18.633690716, 61.618019732],
              [18.63382295, 61.618020212],
              [18.633822303, 61.61806044]
            ]
          ]
        },
        properties: {
        Unit_Number: "V40",
      },
      },
      {
        "type": "Feature",
        "id": "sm53745b5a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63382295, 61.618020212],
              [18.633690716, 61.618019732],
              [18.633691376, 61.61798275],
              [18.633823545, 61.61798323],
              [18.63382295, 61.618020212]
            ]
          ]
        },
        properties: {
        Unit_Number: "V39",
      },
      },
      {
        "type": "Feature",
        "id": "sm3f52d273",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.618286092],
              [18.633685953, 61.618286684],
              [18.633685287, 61.618323998],
              [18.633562731, 61.618323504],
              [18.633539262, 61.618314261],
              [18.633539262, 61.618286092]
            ]
          ]
        },
        properties: {
        Unit_Number: "V80",
      },
      },
      {
        "type": "Feature",
        "id": "sm9cd7890e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633691377, 61.617982678],
              [18.633539262, 61.617982065],
              [18.633539261, 61.61792635],
              [18.633692257, 61.617933365],
              [18.633691377, 61.617982678]
            ]
          ]
        },
        properties: {
        Unit_Number: "V89",
      },
      },
      {
        "type": "Feature",
        "id": "sm0d8ef614",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.617982065],
              [18.633691377, 61.617982678],
              [18.633690706, 61.618020298],
              [18.633539262, 61.618019688],
              [18.633539262, 61.617982065]
            ]
          ]
        },
        properties: {
        Unit_Number: "V88",
      },
      },
      {
        "type": "Feature",
        "id": "sm5305b370",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.618019688],
              [18.633690706, 61.618020298],
              [18.633689989, 61.618060484],
              [18.633539262, 61.618059877],
              [18.633539262, 61.618019688]
            ]
          ]
        },
        properties: {
        Unit_Number: "V87",
      },
      },
      {
        "type": "Feature",
        "id": "sme52fcfd0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.618059877],
              [18.633689989, 61.618060484],
              [18.633689363, 61.61809556],
              [18.633539262, 61.618094955],
              [18.633539262, 61.618059877]
            ]
          ]
        },
        properties: {
        Unit_Number: "V86",
      },
      },
      {
        "type": "Feature",
        "id": "sm810083be",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.618094955],
              [18.633689363, 61.61809556],
              [18.63368867, 61.618134395],
              [18.633539262, 61.618133793],
              [18.633539262, 61.618094955]
            ]
          ]
        },
        properties: {
        Unit_Number: "V85",
      },
      },
      {
        "type": "Feature",
        "id": "sm10d086a6",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.618133793],
              [18.63368867, 61.618134395],
              [18.63368792, 61.618176428],
              [18.633539262, 61.618175829],
              [18.633539262, 61.618133793]
            ]
          ]
        },
        properties: {
        Unit_Number: "V84",
      },
      },
      {
        "type": "Feature",
        "id": "sm3c2c7f3d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.618175829],
              [18.63368792, 61.618176428],
              [18.633687319, 61.618210137],
              [18.633539262, 61.61820954],
              [18.633539262, 61.618175829]
            ]
          ]
        },
        properties: {
        Unit_Number: "V83",
      },
      },
      {
        "type": "Feature",
        "id": "sm7864afc3",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.61820954],
              [18.633687319, 61.618210137],
              [18.633686659, 61.618247098],
              [18.633539262, 61.618246504],
              [18.633539262, 61.61820954]
            ]
          ]
        },
        properties: {
        Unit_Number: "V82",
      },
      },
      {
        "type": "Feature",
        "id": "smaa62348b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633539262, 61.618246504],
              [18.633686659, 61.618247098],
              [18.633685953, 61.618286684],
              [18.633539262, 61.618286092],
              [18.633539262, 61.618246504]
            ]
          ]
        },
        properties: {
        Unit_Number: "V81",
      },
      },
      {
        "type": "Feature",
        "id": "sm90a91a0c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633532218, 61.618461503],
              [18.633531556, 61.618422081],
              [18.633674263, 61.618422216],
              [18.633674725, 61.61846112],
              [18.633532218, 61.618461503]
            ]
          ]
        },
        properties: {
        Unit_Number: "V79",
      },
      },
      {
        "type": "Feature",
        "id": "smfa599263",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633674725, 61.61846112],
              [18.633675149, 61.618496825],
              [18.633532818, 61.618497207],
              [18.633532218, 61.618461503],
              [18.633674725, 61.61846112]
            ]
          ]
        },
        properties: {
        Unit_Number: "V78",
      },
      },
      {
        "type": "Feature",
        "id": "sm401808c1",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633675149, 61.618496825],
              [18.633675641, 61.618538249],
              [18.633533514, 61.618538631],
              [18.633532818, 61.618497207],
              [18.633675149, 61.618496825]
            ]
          ]
        },
        properties: {
        Unit_Number: "V77",
      },
      },
      {
        "type": "Feature",
        "id": "smd32114ae",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633675641, 61.618538249],
              [18.633676118, 61.618578399],
              [18.633534188, 61.61857878],
              [18.633533514, 61.618538631],
              [18.633675641, 61.618538249]
            ]
          ]
        },
        properties: {
        Unit_Number: "V76",
      },
      },
      {
        "type": "Feature",
        "id": "smf2bb6c65",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633676118, 61.618578399],
              [18.633676565, 61.618615987],
              [18.633534819, 61.618616368],
              [18.633534188, 61.61857878],
              [18.633676118, 61.618578399]
            ]
          ]
        },
        properties: {
        Unit_Number: "V75",
      },
      },
      {
        "type": "Feature",
        "id": "sm14a5eb7b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633676565, 61.618615987],
              [18.633677034, 61.618655484],
              [18.633535482, 61.618655864],
              [18.633534819, 61.618616368],
              [18.633676565, 61.618615987]
            ]
          ]
        },
        properties: {
        Unit_Number: "V74",
      },
      },
      {
        "type": "Feature",
        "id": "sm5406e524",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633677034, 61.618655484],
              [18.633677467, 61.618691974],
              [18.633561393, 61.618692285],
              [18.633535912, 61.618681448],
              [18.633535482, 61.618655864],
              [18.633677034, 61.618655484]
            ]
          ]
        },
        properties: {
        Unit_Number: "V73",
      },
      },
      {
        "type": "Feature",
        "id": "sm5b0bbc30",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633821663, 61.618653111],
              [18.633821568, 61.618675711],
              [18.633798769, 61.618691648],
              [18.633677467, 61.618691974],
              [18.633677004, 61.618652974],
              [18.633821663, 61.618653111]
            ]
          ]
        },
        properties: {
        Unit_Number: "V54",
      },
      },
      {
        "type": "Feature",
        "id": "sm2569e287",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633677004, 61.618652974],
              [18.633676527, 61.618612817],
              [18.633821831, 61.618612955],
              [18.633821663, 61.618653111],
              [18.633677004, 61.618652974]
            ]
          ]
        },
        properties: {
        Unit_Number: "V53",
      },
      },
      {
        "type": "Feature",
        "id": "sm515e76fc",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633676527, 61.618612817],
              [18.633676118, 61.618578393],
              [18.633821975, 61.618578531],
              [18.633821831, 61.618612955],
              [18.633676527, 61.618612817]
            ]
          ]
        },
        properties: {
        Unit_Number: "V52",
      },
      },
      {
        "type": "Feature",
        "id": "sm7ca0a62c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633676118, 61.618578393],
              [18.633675671, 61.618540785],
              [18.633822133, 61.618540924],
              [18.633821975, 61.618578531],
              [18.633676118, 61.618578393]
            ]
          ]
        },
        properties: {
        Unit_Number: "V51",
      },
      },
      {
        "type": "Feature",
        "id": "smad5cd7c0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633675671, 61.618540785],
              [18.633675164, 61.618498071],
              [18.633822312, 61.61849821],
              [18.633822133, 61.618540924],
              [18.633675671, 61.618540785]
            ]
          ]
        },
        properties: {
        Unit_Number: "V50",
      },
      },
      {
        "type": "Feature",
        "id": "smd8ba777d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633675164, 61.618498071],
              [18.633674725, 61.618461094],
              [18.633822467, 61.618461234],
              [18.633822312, 61.61849821],
              [18.633675164, 61.618498071]
            ]
          ]
        },
        properties: {
        Unit_Number: "V49",
      },
      },
      {
        "type": "Feature",
        "id": "smf7477ed0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633674725, 61.618461094],
              [18.633674263, 61.618422216],
              [18.63382263, 61.618422357],
              [18.633822467, 61.618461234],
              [18.633674725, 61.618461094]
            ]
          ]
        },
        properties: {
        Unit_Number: "V48",
      },
      },
      {
        "type": "Feature",
        "id": "sme8fa110d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633531556, 61.618422081],
              [18.633530548, 61.618362071],
              [18.633556029, 61.618348046],
              [18.633805474, 61.618346771],
              [18.633822909, 61.618355696],
              [18.63382263, 61.618422357],
              [18.633531556, 61.618422081]
            ]
          ]
        },
        properties: {
        Unit_Number: "V",
      },
      },
      {
        "type": "Feature",
        "id": "sm89bd6be2",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633684717, 61.619068099],
              [18.633554689, 61.619063932],
              [18.633527866, 61.619048633],
              [18.633527751, 61.619020843],
              [18.633691357, 61.619021289],
              [18.633684717, 61.619068099]
            ]
          ]
        },
        properties: {
        Unit_Number: "V64",
      },
      },
      {
        "type": "Feature",
        "id": "sm5ac0759d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633691357, 61.619021289],
              [18.633816661, 61.619021631],
              [18.633816204, 61.61905947],
              [18.633793405, 61.619071582],
              [18.633684717, 61.619068099],
              [18.633691357, 61.619021289]
            ]
          ]
        },
        properties: {
        Unit_Number: "V63",
      },
      },
      {
        "type": "Feature",
        "id": "sm7e769be5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633817115, 61.618984014],
              [18.633816661, 61.619021631],
              [18.633674527, 61.619021243],
              [18.633674981, 61.618983627],
              [18.633817115, 61.618984014]
            ]
          ]
        },
        properties: {
        Unit_Number: "V62",
      },
      },
      {
        "type": "Feature",
        "id": "sma797f89b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633674981, 61.618983627],
              [18.633675451, 61.618944757],
              [18.633817584, 61.618945144],
              [18.633817115, 61.618984014],
              [18.633674981, 61.618983627]
            ]
          ]
        },
        properties: {
        Unit_Number: "V61",
      },
      },
      {
        "type": "Feature",
        "id": "smaeccd134",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633675451, 61.618944757],
              [18.633675913, 61.618906477],
              [18.633818046, 61.618906864],
              [18.633817584, 61.618945144],
              [18.633675451, 61.618944757]
            ]
          ]
        },
        properties: {
        Unit_Number: "V60",
      },
      },
      {
        "type": "Feature",
        "id": "smd084345e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633675913, 61.618906477],
              [18.633676382, 61.618867614],
              [18.633818515, 61.618868002],
              [18.633818046, 61.618906864],
              [18.633675913, 61.618906477]
            ]
          ]
        },
        properties: {
        Unit_Number: "V59",
      },
      },
      {
        "type": "Feature",
        "id": "sm286eca06",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633676382, 61.618867614],
              [18.633676828, 61.618830646],
              [18.633818961, 61.618831033],
              [18.633818515, 61.618868002],
              [18.633676382, 61.618867614]
            ]
          ]
        },
        properties: {
        Unit_Number: "V58",
      },
      },
      {
        "type": "Feature",
        "id": "sm6463eda0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633676828, 61.618830646],
              [18.63367729, 61.618792406],
              [18.633819422, 61.618792793],
              [18.633818961, 61.618831033],
              [18.633676828, 61.618830646]
            ]
          ]
        },
        properties: {
        Unit_Number: "V57",
      },
      },
      {
        "type": "Feature",
        "id": "sm6a815e4b",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63367729, 61.618792406],
              [18.633677782, 61.618751623],
              [18.633819914, 61.618752011],
              [18.633819422, 61.618792793],
              [18.63367729, 61.618792406]
            ]
          ]
        },
        properties: {
        Unit_Number: "V56",
      },
      },
      {
        "type": "Feature",
        "id": "sm5942c1c9",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633677782, 61.618751623],
              [18.633678225, 61.618714903],
              [18.633793404, 61.618714597],
              [18.633820227, 61.618726071],
              [18.633819914, 61.618752011],
              [18.633677782, 61.618751623]
            ]
          ]
        },
        properties: {
        Unit_Number: "V55",
      },
      },
      {
        "type": "Feature",
        "id": "smc1a427ea",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633526636, 61.618752174],
              [18.633526525, 61.618725434],
              [18.633553347, 61.618715234],
              [18.633678225, 61.618714903],
              [18.63367777, 61.618752586],
              [18.633526636, 61.618752174]
            ]
          ]
        },
        properties: {
        Unit_Number: "V72",
      },
      },
      {
        "type": "Feature",
        "id": "sm2f5e7413",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63367777, 61.618752586],
              [18.63367727, 61.618793995],
              [18.633526808, 61.618793585],
              [18.633526636, 61.618752174],
              [18.63367777, 61.618752586]
            ]
          ]
        },
        properties: {
        Unit_Number: "V71",
      },
      },
      {
        "type": "Feature",
        "id": "sm9fa99a7e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63367727, 61.618793995],
              [18.633676801, 61.618832872],
              [18.633526969, 61.618832464],
              [18.633526808, 61.618793585],
              [18.63367727, 61.618793995]
            ]
          ]
        },
        properties: {
        Unit_Number: "V70",
      },
      },
      {
        "type": "Feature",
        "id": "sme5e69ef7",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633676801, 61.618832872],
              [18.633676355, 61.618869837],
              [18.633527123, 61.61886943],
              [18.633526969, 61.618832464],
              [18.633676801, 61.618832872]
            ]
          ]
        },
        properties: {
        Unit_Number: "V69",
      },
      },
      {
        "type": "Feature",
        "id": "sm3ec243f0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633676355, 61.618869837],
              [18.633675909, 61.618906817],
              [18.633527276, 61.618906411],
              [18.633527123, 61.61886943],
              [18.633676355, 61.618869837]
            ]
          ]
        },
        properties: {
        Unit_Number: "V68",
      },
      },
      {
        "type": "Feature",
        "id": "sm9f94bd8d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633675909, 61.618906817],
              [18.633675431, 61.618946383],
              [18.63352744, 61.618945979],
              [18.633527276, 61.618906411],
              [18.633675909, 61.618906817]
            ]
          ]
        },
        properties: {
        Unit_Number: "V67",
      },
      },
      {
        "type": "Feature",
        "id": "sm2d45f6e0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633675431, 61.618946383],
              [18.633674961, 61.618985304],
              [18.633527602, 61.618984902],
              [18.63352744, 61.618945979],
              [18.633675431, 61.618946383]
            ]
          ]
        },
        properties: {
        Unit_Number: "V66",
      },
      },
      {
        "type": "Feature",
        "id": "smc5547e77",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633674961, 61.618985304],
              [18.633674527, 61.619021243],
              [18.633527751, 61.619020843],
              [18.633527602, 61.618984902],
              [18.633674961, 61.618985304]
            ]
          ]
        },
        properties: {
        Unit_Number: "V65",
      },
      }
    ],
    
  
    
  
};

export default geojsonData;
