const geojsonData = {
  type: "FeatureCollection",

  features: [
    {
      type: "Feature",
      id: "sme8a53a43",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.62509799, 25.749156884],
            [-176.624890757, 25.706778506],
            [-176.539746714, 25.706159819],
            [-176.525327158, 25.722244632],
            [-176.525399509, 25.749552473],
            [-176.62509799, 25.749156884],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN499",
      },
    },
    {
      type: "Feature",
      id: "sm4a48c18d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.52628885, 26.084708806],
            [-176.526357127, 26.110400012],
            [-176.544896556, 26.128279225],
            [-176.544896556, 26.128279225],
            [-176.626950692, 26.127354505],
            [-176.626740426, 26.084493483],
            [-176.52628885, 26.084708806],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN508",
      },
    },
    {
      type: "Feature",
      id: "sm56faacfd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.626740426, 26.084493483],
            [-176.626510579, 26.03762286],
            [-176.526164326, 26.037838043],
            [-176.52628885, 26.084708806],
            [-176.626740426, 26.084493483],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN507",
      },
    },
    {
      type: "Feature",
      id: "smcc6a1e73",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.626510579, 26.03762286],
            [-176.62631399, 25.997519501],
            [-176.52605782, 25.997734564],
            [-176.526164326, 26.037838043],
            [-176.626510579, 26.03762286],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN506",
      },
    },
    {
      type: "Feature",
      id: "smb8053323",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.62631399, 25.997519501],
            [-176.626120229, 25.957979733],
            [-176.525953329, 25.958376479],
            [-176.52605782, 25.997734564],
            [-176.62631399, 25.997519501],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN505",
      },
    },
    {
      type: "Feature",
      id: "smd0813143",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.626120229, 25.957979733],
            [-176.62589666, 25.912340533],
            [-176.525832205, 25.912737027],
            [-176.525953329, 25.958376479],
            [-176.626120229, 25.957979733],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN504",
      },
    },
    {
      type: "Feature",
      id: "sm3f3f18b7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.62589666, 25.912340533],
            [-176.625694011, 25.870956751],
            [-176.525722416, 25.871353015],
            [-176.525832205, 25.912737027],
            [-176.62589666, 25.912340533],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN503",
      },
    },
    {
      type: "Feature",
      id: "sm0158a5f5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.625694011, 25.870956751],
            [-176.625500463, 25.831417999],
            [-176.525617557, 25.831814045],
            [-176.525722416, 25.871353015],
            [-176.625694011, 25.870956751],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN502",
      },
    },
    {
      type: "Feature",
      id: "sma62b50e1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.625500463, 25.831417999],
            [-176.625288833, 25.788170212],
            [-176.525502902, 25.788566018],
            [-176.525617557, 25.831814045],
            [-176.625500463, 25.831417999],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN501",
      },
    },
    {
      type: "Feature",
      id: "smba7e1851",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.625288833, 25.788170212],
            [-176.62509799, 25.749156884],
            [-176.525399509, 25.749552473],
            [-176.525502902, 25.788566018],
            [-176.625288833, 25.788170212],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN500",
      },
    },
    {
      type: "Feature",
      id: "smdc1bb54b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407841306, 25.703689241],
            [-176.326886607, 25.702447631],
            [-176.311780406, 25.723481836],
            [-176.311802129, 25.748431438],
            [-176.406999736, 25.748230785],
            [-176.407841306, 25.703689241],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN519",
      },
    },
    {
      type: "Feature",
      id: "sm0dcd2c05",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.406999736, 25.748230785],
            [-176.502394731, 25.748029716],
            [-176.502324534, 25.721007417],
            [-176.488248301, 25.704922437],
            [-176.407841306, 25.703689241],
            [-176.406999736, 25.748230785],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN518",
      },
    },
    {
      type: "Feature",
      id: "sm9db87024",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407851962, 25.792421802],
            [-176.502509567, 25.792222362],
            [-176.502394731, 25.748029716],
            [-176.407737126, 25.748229231],
            [-176.407851962, 25.792421802],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN517",
      },
    },
    {
      type: "Feature",
      id: "smd87b0e2c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407737126, 25.748229231],
            [-176.311802129, 25.748431438],
            [-176.311840618, 25.792624094],
            [-176.407851962, 25.792421802],
            [-176.407737126, 25.748229231],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN520",
      },
    },
    {
      type: "Feature",
      id: "sm53faa3f5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407156488, 25.833531221],
            [-176.502616425, 25.83333016],
            [-176.502509567, 25.792222362],
            [-176.40704963, 25.792423493],
            [-176.407156488, 25.833531221],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN516",
      },
    },
    {
      type: "Feature",
      id: "sm95b28e38",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.40704963, 25.792423493],
            [-176.311840618, 25.792624094],
            [-176.311876433, 25.833731903],
            [-176.407156488, 25.833531221],
            [-176.40704963, 25.792423493],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN521",
      },
    },
    {
      type: "Feature",
      id: "smc61a4e2b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407146043, 25.87493166],
            [-176.502724081, 25.874730421],
            [-176.502616425, 25.83333016],
            [-176.407038387, 25.83353147],
            [-176.407146043, 25.87493166],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN515",
      },
    },
    {
      type: "Feature",
      id: "sm49781ea9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407038387, 25.83353147],
            [-176.311876433, 25.833731903],
            [-176.311912516, 25.875132174],
            [-176.407146043, 25.87493166],
            [-176.407038387, 25.83353147],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN522",
      },
    },
    {
      type: "Feature",
      id: "smd83a69d3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407832692, 25.916319018],
            [-176.502831745, 25.916119068],
            [-176.502724081, 25.874730421],
            [-176.407725029, 25.874930441],
            [-176.407832692, 25.916319018],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN514",
      },
    },
    {
      type: "Feature",
      id: "sm28a03543",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407725029, 25.874930441],
            [-176.311912516, 25.875132174],
            [-176.311948601, 25.916520831],
            [-176.407832692, 25.916319018],
            [-176.407725029, 25.874930441],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN523",
      },
    },
    {
      type: "Feature",
      id: "sm80d25cc4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407133132, 25.957049945],
            [-176.50293773, 25.956848369],
            [-176.502831745, 25.916119068],
            [-176.407027147, 25.916320714],
            [-176.407133132, 25.957049945],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN513",
      },
    },
    {
      type: "Feature",
      id: "smd1be4635",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407027147, 25.916320714],
            [-176.311948601, 25.916520831],
            [-176.311984124, 25.957250141],
            [-176.407133132, 25.957049945],
            [-176.407027147, 25.916320714],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN524",
      },
    },
    {
      type: "Feature",
      id: "sm15fc74e6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407157242, 25.999027021],
            [-176.503047, 25.998825338],
            [-176.50293773, 25.956848369],
            [-176.407047972, 25.957050124],
            [-176.407157242, 25.999027021],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN512",
      },
    },
    {
      type: "Feature",
      id: "sm2ca9926d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407047972, 25.957050124],
            [-176.311984124, 25.957250141],
            [-176.312020748, 25.999227119],
            [-176.407157242, 25.999027021],
            [-176.407047972, 25.957050124],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN525",
      },
    },
    {
      type: "Feature",
      id: "sm0e8b7c04",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.40646421, 26.043469884],
            [-176.503162728, 26.043266577],
            [-176.503047, 25.998825338],
            [-176.406348483, 25.999028722],
            [-176.40646421, 26.043469884],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN511",
      },
    },
    {
      type: "Feature",
      id: "sma7f712c4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.406348483, 25.999028722],
            [-176.312020748, 25.999227119],
            [-176.312059536, 26.043668368],
            [-176.40646421, 26.043469884],
            [-176.406348483, 25.999028722],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN526",
      },
    },
    {
      type: "Feature",
      id: "sm26b95870",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407142735, 26.085996758],
            [-176.503273515, 26.085794718],
            [-176.503162728, 26.043266577],
            [-176.407031949, 26.04346869],
            [-176.407142735, 26.085996758],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN510",
      },
    },
    {
      type: "Feature",
      id: "sm4c551fe8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.407031949, 26.04346869],
            [-176.312059536, 26.043668368],
            [-176.312096668, 26.086196518],
            [-176.407142735, 26.085996758],
            [-176.407031949, 26.04346869],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN527",
      },
    },
    {
      type: "Feature",
      id: "sm2e035304",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.406471911, 26.130912381],
            [-176.486531688, 26.129512173],
            [-176.503354502, 26.116873836],
            [-176.503273515, 26.085794718],
            [-176.406354865, 26.085998414],
            [-176.406471911, 26.130912381],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN509",
      },
    },
    {
      type: "Feature",
      id: "sm16045ec4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.406354865, 26.085998414],
            [-176.312096668, 26.086196518],
            [-176.31212373, 26.117182105],
            [-176.327916576, 26.13228626],
            [-176.327916576, 26.13228626],
            [-176.406471911, 26.130912381],
            [-176.406354865, 26.085998414],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN528",
      },
    },
    {
      type: "Feature",
      id: "sm09ce217c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188269413, 26.135335324],
            [-176.270753337, 26.133056827],
            [-176.284486248, 26.118261036],
            [-176.284486248, 26.118261036],
            [-176.284544798, 26.091457139],
            [-176.188365637, 26.091287705],
            [-176.188269413, 26.135335324],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN529",
      },
    },
    {
      type: "Feature",
      id: "smb95762f6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188365637, 26.091287705],
            [-176.082026233, 26.091100371],
            [-176.081925823, 26.125659166],
            [-176.092225505, 26.13798834],
            [-176.188269413, 26.135335324],
            [-176.188365637, 26.091287705],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN548",
      },
    },
    {
      type: "Feature",
      id: "sm5715e05d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188623119, 25.700032898],
            [-176.095143748, 25.697807233],
            [-176.083127452, 25.711418556],
            [-176.083029397, 25.745275733],
            [-176.187290783, 25.745459945],
            [-176.188623119, 25.700032898],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN539",
      },
    },
    {
      type: "Feature",
      id: "sm4093e1e1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.187290783, 25.745459945],
            [-176.285299013, 25.745633109],
            [-176.285344554, 25.724719027],
            [-176.264058543, 25.701828922],
            [-176.188623119, 25.700032898],
            [-176.187290783, 25.745459945],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN538",
      },
    },
    {
      type: "Feature",
      id: "smdac5c3b5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188602451, 25.788157886],
            [-176.285206017, 25.788328508],
            [-176.285299013, 25.745633109],
            [-176.188695447, 25.745462427],
            [-176.188602451, 25.788157886],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN537",
      },
    },
    {
      type: "Feature",
      id: "sm0cb6a555",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188695447, 25.745462427],
            [-176.083029397, 25.745275733],
            [-176.082905705, 25.787971204],
            [-176.188602451, 25.788157886],
            [-176.188695447, 25.745462427],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN540",
      },
    },
    {
      type: "Feature",
      id: "sm6e8a0f64",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.187913235, 25.831989349],
            [-176.285110509, 25.832160955],
            [-176.285206017, 25.788328508],
            [-176.188008743, 25.788156838],
            [-176.187913235, 25.831989349],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN536",
      },
    },
    {
      type: "Feature",
      id: "sm198e3497",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188008743, 25.788156838],
            [-176.082905705, 25.787971204],
            [-176.082778673, 25.831803729],
            [-176.187913235, 25.831989349],
            [-176.188008743, 25.788156838],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN541",
      },
    },
    {
      type: "Feature",
      id: "sm6120d87c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.18791716, 25.874679262],
            [-176.285017458, 25.874850635],
            [-176.285110509, 25.832160955],
            [-176.188010211, 25.83198952],
            [-176.18791716, 25.874679262],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN535",
      },
    },
    {
      type: "Feature",
      id: "sm364c5188",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188010211, 25.83198952],
            [-176.082778673, 25.831803729],
            [-176.082654907, 25.874493484],
            [-176.18791716, 25.874679262],
            [-176.188010211, 25.83198952],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN542",
      },
    },
    {
      type: "Feature",
      id: "smb979ba8b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.187922598, 25.916042255],
            [-176.284927267, 25.916213399],
            [-176.285017458, 25.874850635],
            [-176.188012789, 25.874679431],
            [-176.187922598, 25.916042255],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN534",
      },
    },
    {
      type: "Feature",
      id: "smfe64687f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188012789, 25.874679431],
            [-176.082654907, 25.874493484],
            [-176.082534946, 25.915856321],
            [-176.187922598, 25.916042255],
            [-176.188012789, 25.874679431],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN543",
      },
    },
    {
      type: "Feature",
      id: "sme24ae049",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.187253444, 25.957422252],
            [-176.284837005, 25.957594357],
            [-176.284927267, 25.916213399],
            [-176.187343706, 25.916041234],
            [-176.187253444, 25.957422252],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN533",
      },
    },
    {
      type: "Feature",
      id: "smbaf68c2b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.187343706, 25.916041234],
            [-176.082534946, 25.915856321],
            [-176.08241489, 25.957237351],
            [-176.187253444, 25.957422252],
            [-176.187343706, 25.916041234],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN544",
      },
    },
    {
      type: "Feature",
      id: "sm341ba316",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188608541, 25.999709745],
            [-176.284744738, 25.999879236],
            [-176.284837005, 25.957594357],
            [-176.188700808, 25.957424805],
            [-176.188608541, 25.999709745],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN532",
      },
    },
    {
      type: "Feature",
      id: "sm080a624c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188700808, 25.957424805],
            [-176.08241489, 25.957237351],
            [-176.082292168, 25.999522305],
            [-176.188608541, 25.999709745],
            [-176.188700808, 25.957424805],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN545",
      },
    },
    {
      type: "Feature",
      id: "sm3f4854b9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.187924568, 26.042604434],
            [-176.284651104, 26.042774904],
            [-176.284744738, 25.999879236],
            [-176.188018202, 25.999708704],
            [-176.187924568, 26.042604434],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN531",
      },
    },
    {
      type: "Feature",
      id: "sm093197bb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188018202, 25.999708704],
            [-176.082292168, 25.999522305],
            [-176.082167628, 26.042418049],
            [-176.187924568, 26.042604434],
            [-176.188018202, 25.999708704],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN546",
      },
    },
    {
      type: "Feature",
      id: "sm02659f64",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.18859582, 26.09128811],
            [-176.284544798, 26.091457139],
            [-176.284651104, 26.042774904],
            [-176.188702126, 26.042605805],
            [-176.18859582, 26.09128811],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN530",
      },
    },
    {
      type: "Feature",
      id: "sm958142df",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.188702126, 26.042605805],
            [-176.082167628, 26.042418049],
            [-176.082026233, 26.091100371],
            [-176.18859582, 26.09128811],
            [-176.188702126, 26.042605805],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN547",
      },
    },
    {
      type: "Feature",
      id: "smfc01a48d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.058532585, 25.741003964],
            [-176.058579878, 25.712501204],
            [-176.045533614, 25.697033811],
            [-175.957299666, 25.695796333],
            [-175.957299666, 25.695796333],
            [-175.956119331, 25.740866071],
            [-176.058532585, 25.741003964],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN558",
      },
    },
    {
      type: "Feature",
      id: "sm012fe917",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.956119331, 25.740866071],
            [-175.95485539, 25.789109284],
            [-176.058452509, 25.789248714],
            [-176.058532585, 25.741003964],
            [-175.956119331, 25.740866071],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN557",
      },
    },
    {
      type: "Feature",
      id: "sm6eb09c0c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.95485539, 25.789109284],
            [-175.953737788, 25.831750573],
            [-176.058381705, 25.831891362],
            [-176.058452509, 25.789248714],
            [-175.95485539, 25.789109284],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN556",
      },
    },
    {
      type: "Feature",
      id: "sm3388d429",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.953737788, 25.831750573],
            [-175.952692992, 25.871600113],
            [-176.058315513, 25.87174217],
            [-176.058381705, 25.831891362],
            [-175.953737788, 25.831750573],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN555",
      },
    },
    {
      type: "Feature",
      id: "sma61f24d2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.952692992, 25.871600113],
            [-175.951599328, 25.913299143],
            [-176.058246225, 25.913442527],
            [-176.058315513, 25.87174217],
            [-175.952692992, 25.871600113],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN554",
      },
    },
    {
      type: "Feature",
      id: "smfa5ff496",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.951599328, 25.913299143],
            [-175.950417432, 25.958345685],
            [-176.058171347, 25.958490503],
            [-176.058246225, 25.913442527],
            [-175.951599328, 25.913299143],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN553",
      },
    },
    {
      type: "Feature",
      id: "sm2a2df413",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.950417432, 25.958345685],
            [-175.949185481, 26.005281665],
            [-176.058093298, 26.005427975],
            [-176.058171347, 25.958490503],
            [-175.950417432, 25.958345685],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN552",
      },
    },
    {
      type: "Feature",
      id: "sm533b6a6c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.949185481, 26.005281665],
            [-175.94808437, 26.04721691],
            [-176.058023538, 26.047364553],
            [-176.058093298, 26.005427975],
            [-175.949185481, 26.005281665],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN551",
      },
    },
    {
      type: "Feature",
      id: "sm4d175622",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.94808437, 26.04721691],
            [-175.946909605, 26.09194068],
            [-176.057949112, 26.092089743],
            [-176.058023538, 26.047364553],
            [-175.94808437, 26.04721691],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN550",
      },
    },
    {
      type: "Feature",
      id: "sm3a2eb50a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.946909605, 26.09194068],
            [-175.945626691, 26.140762223],
            [-176.045876935, 26.138912973],
            [-176.057893231, 26.125659163],
            [-176.057949112, 26.092089743],
            [-175.946909605, 26.09194068],
          ],
        ],
      },
      properties: {
        Unit_Number: "WN549",
      },
    },
  ],
};

export default geojsonData;
