const geojsonData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "sm70e1301c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.73601281, -74.55626962],
            [-53.71361239, -72.73244264],
            [-59.67085202, -72.72633192],
            [-60.85737546, -73.07519066],
            [-60.8354028, -74.21324147],
            [-59.62690671, -74.55626962],
            [-53.73601281, -74.55626962],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK594",
      },
    },
    {
      type: "Feature",
      id: "smc75b6326",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.71361239, -72.73244264],
            [-53.73601281, -74.55626962],
            [-50.20887684, -74.55626962],
            [-50.20887684, -72.73603669],
            [-53.71361239, -72.73244264],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK593",
      },
    },
    {
      type: "Feature",
      id: "sma0774883",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-50.20887684, -72.73603669],
            [-50.20887684, -74.55626962],
            [-46.60536122, -74.55626962],
            [-46.60536122, -72.73973128],
            [-50.20887684, -72.73603669],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK592",
      },
    },
    {
      type: "Feature",
      id: "smdf9c2a3b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.60536122, -72.73973128],
            [-46.60536122, -74.55626962],
            [-42.83787898, -74.55626962],
            [-42.81560865, -72.74361598],
            [-46.60536122, -72.73973128],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK591",
      },
    },
    {
      type: "Feature",
      id: "smef31fc10",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-42.81560865, -72.74361598],
            [-42.83787898, -74.55626962],
            [-39.32255765, -74.55626962],
            [-39.30032929, -72.74721858],
            [-42.81560865, -72.74361598],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK590",
      },
    },
    {
      type: "Feature",
      id: "sm5aeebbc5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-39.30032929, -72.74721858],
            [-39.32255765, -74.55626962],
            [-35.88270497, -74.55626962],
            [-35.88270497, -72.7507204],
            [-39.30032929, -72.74721858],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK589",
      },
    },
    {
      type: "Feature",
      id: "sm1dd2274c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-35.88270497, -72.7507204],
            [-35.88270497, -74.55626962],
            [-32.29312967, -74.55626962],
            [-32.27098522, -72.75442035],
            [-35.88270497, -72.7507204],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK588",
      },
    },
    {
      type: "Feature",
      id: "sm2079d237",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-32.27098522, -72.75442035],
            [-32.29312967, -74.55626962],
            [-28.60111608, -74.55626962],
            [-28.5790157, -72.75820171],
            [-32.27098522, -72.75442035],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK587",
      },
    },
    {
      type: "Feature",
      id: "smcea195bd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-28.5790157, -72.75820171],
            [-28.60111608, -74.55626962],
            [-24.99668945, -74.55626962],
            [-24.97463209, -72.76189259],
            [-28.5790157, -72.75820171],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK586",
      },
    },
    {
      type: "Feature",
      id: "sm55305f58",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-24.97463209, -72.76189259],
            [-24.99668945, -74.55626962],
            [-21.39287015, -74.55626962],
            [-21.37085582, -72.76558208],
            [-24.97463209, -72.76189259],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK585",
      },
    },
    {
      type: "Feature",
      id: "sm36bdee28",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-21.37085582, -72.76558208],
            [-21.39287015, -74.55626962],
            [-17.79269489, -74.55626962],
            [-17.77072354, -72.76926708],
            [-21.37085582, -72.76558208],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK584",
      },
    },
    {
      type: "Feature",
      id: "sm405871d4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-17.77072354, -72.76926708],
            [-17.79269489, -74.55626962],
            [-15.24214108, -74.55626962],
            [-14.0775903, -74.26695273],
            [-14.14350827, -73.09436991],
            [-15.15425046, -72.77194475],
            [-17.77072354, -72.76926708],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK583",
      },
    },
    {
      type: "Feature",
      id: "sme15e2287",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.01945558, -66.14683571],
            [16.04142825, -74.51672512],
            [46.96794191, -74.50498927],
            [46.48454347, -70.1810847],
            [46.17692628, -65.77989604],
            [17.22795167, -65.68507372],
            [16.01945558, -66.14683571],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK552",
      },
    },
    {
      type: "Feature",
      id: "sme1736cde",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.26912338, -74.54871572],
            [-9.29907734, -72.77237449],
            [-11.86384519, -72.77357162],
            [-12.78669675, -73.08957708],
            [-12.78669675, -74.2624836],
            [-11.77595457, -74.55188066],
            [-9.26912338, -74.54871572],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK582",
      },
    },
    {
      type: "Feature",
      id: "sm13ce5c95",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.29907734, -72.77237449],
            [-9.26912338, -74.54871572],
            [-5.40618808, -74.54383744],
            [-5.43608413, -72.77057125],
            [-9.29907734, -72.77237449],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK581",
      },
    },
    {
      type: "Feature",
      id: "smbb0305d8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-5.43608413, -72.77057125],
            [-5.40618808, -74.54383744],
            [-1.97832242, -74.53950733],
            [-2.00816709, -72.76897095],
            [-5.43608413, -72.77057125],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK580",
      },
    },
    {
      type: "Feature",
      id: "sm8cefc435",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-2.00816709, -72.76897095],
            [-1.97832242, -74.53950733],
            [1.58095967, -74.53500995],
            [1.55116837, -72.76730914],
            [-2.00816709, -72.76897095],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK579",
      },
    },
    {
      type: "Feature",
      id: "sm5b2806e4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.55116837, -72.76730914],
            [1.58095967, -74.53500995],
            [5.3164356, -74.53028857],
            [5.2867003, -72.76556491],
            [1.55116837, -72.76730914],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK578",
      },
    },
    {
      type: "Feature",
      id: "sm9ac3113c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.2867003, -72.76556491],
            [5.3164356, -74.53028857],
            [8.78782904, -74.52589971],
            [8.75814579, -72.76394383],
            [5.2867003, -72.76556491],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK577",
      },
    },
    {
      type: "Feature",
      id: "sm6a844214",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.75814579, -72.76394383],
            [8.78782904, -74.52589971],
            [12.43583286, -74.52128626],
            [12.40620429, -72.76224012],
            [8.75814579, -72.76394383],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK576",
      },
    },
    {
      type: "Feature",
      id: "sme944c74b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.40620429, -72.76224012],
            [12.43583286, -74.52128626],
            [16.04142825, -74.51672512],
            [16.01945559, -72.7605525],
            [12.40620429, -72.76224012],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK575",
      },
    },
    {
      type: "Feature",
      id: "sm1213a8df",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.3343535, -62.11054668],
            [32.98665797, -62.11293918],
            [32.99419339, -64.96434217],
            [36.46501221, -64.98363929],
            [37.51969971, -64.49608301],
            [37.3343535, -62.11054668],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK282",
      },
    },
    {
      type: "Feature",
      id: "sm0ba6af4c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.98665797, -62.11293918],
            [37.3343535, -62.11054668],
            [37.1241919, -59.1585919],
            [35.98161378, -58.52193684],
            [32.9781207, -58.5237819],
            [32.98665797, -62.11293918],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK271",
      },
    },
    {
      type: "Feature",
      id: "sm6ce08a6d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.22819581, -61.98998841],
            [19.62810705, -61.9881099],
            [19.6199016, -58.53198673],
            [17.30485597, -58.53340847],
            [16.22819581, -59.1135039],
            [16.22819581, -61.98998841],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK276",
      },
    },
    {
      type: "Feature",
      id: "sm27bf9d77",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.62810705, -61.9881099],
            [16.22819581, -61.98998841],
            [16.22819581, -64.43925906],
            [17.238938, -64.87657063],
            [19.63574925, -64.88994165],
            [19.62810705, -61.9881099],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK277",
      },
    },
    {
      type: "Feature",
      id: "sm887654d5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.87943977, -61.98811024],
            [19.62811155, -61.98990666],
            [19.63574925, -64.88994165],
            [22.88713224, -64.90806941],
            [22.87943977, -61.98811024],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK278",
      },
    },
    {
      type: "Feature",
      id: "sma2080c4a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.62811155, -61.98990666],
            [22.87943977, -61.98811024],
            [22.87122982, -58.52998989],
            [19.6199016, -58.53198673],
            [19.62811155, -61.98990666],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK275",
      },
    },
    {
      type: "Feature",
      id: "sm6a8e7ec3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.04314167, -61.96798672],
            [22.87939375, -61.96973591],
            [22.88713224, -64.90806941],
            [26.05093346, -64.92569711],
            [26.04314167, -61.96798672],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK279",
      },
    },
    {
      type: "Feature",
      id: "smea4d2e22",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.87939375, -61.96973591],
            [26.04314167, -61.96798672],
            [26.03497774, -58.52804673],
            [22.87122982, -58.52998989],
            [22.87939375, -61.96973591],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK274",
      },
    },
    {
      type: "Feature",
      id: "smccd284c5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.55967058, -61.95731782],
            [26.04311984, -61.95926274],
            [26.05093346, -64.92569711],
            [29.56754344, -64.94527696],
            [29.55967058, -61.95731782],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK280",
      },
    },
    {
      type: "Feature",
      id: "sm4a3170b8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.04311984, -61.95926274],
            [29.55967058, -61.95731782],
            [29.55152848, -58.52588675],
            [26.03497774, -58.52804673],
            [26.04311984, -61.95926274],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK273",
      },
    },
    {
      type: "Feature",
      id: "sm1cbd290d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.98626821, -61.95758581],
            [29.55967599, -61.95948096],
            [29.56754344, -64.94527696],
            [32.99419339, -64.96434217],
            [32.98626821, -61.95758581],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK281",
      },
    },
    {
      type: "Feature",
      id: "sm9cd90d1c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.55967599, -61.95948096],
            [32.98626821, -61.95758581],
            [32.9781207, -58.5237819],
            [29.55152848, -58.52588675],
            [29.55967599, -61.95948096],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK272",
      },
    },
    {
      type: "Feature",
      id: "sm4a23d044",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-12.50883069, -69.38951825],
            [-7.26225478, -69.39538516],
            [-7.24655833, -67.5849591],
            [-11.68806394, -67.5795777],
            [-12.50105222, -67.92068581],
            [-12.50883069, -69.38951825],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK543",
      },
    },
    {
      type: "Feature",
      id: "sm8e970b7a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.26225478, -69.39538516],
            [-12.50883069, -69.38951825],
            [-12.51713451, -70.85424871],
            [-7.27594713, -70.85970932],
            [-7.26225478, -69.39538516],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK544",
      },
    },
    {
      type: "Feature",
      id: "smda88815c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.27594713, -70.85970932],
            [-12.51713451, -70.85424871],
            [-12.52302488, -71.83199545],
            [-11.66609129, -72.19162138],
            [-7.28930694, -72.19162138],
            [-7.27594713, -70.85970932],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK560",
      },
    },
    {
      type: "Feature",
      id: "smf3d55f9d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.26405264, -72.19162138],
            [-4.26405264, -70.08209032],
            [-7.26852518, -70.07883814],
            [-7.28930694, -72.19162138],
            [-4.26405264, -72.19162138],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK559",
      },
    },
    {
      type: "Feature",
      id: "sm52984647",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.26405264, -70.08209032],
            [-4.26405264, -72.19162138],
            [-0.66053702, -72.19162138],
            [-0.66053702, -70.08599027],
            [-4.26405264, -70.08209032],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK558",
      },
    },
    {
      type: "Feature",
      id: "sme54ec21a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.66053702, -70.08599027],
            [-0.66053702, -72.19162138],
            [2.59141611, -72.19162138],
            [2.59141611, -70.0895091],
            [-0.66053702, -70.08599027],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK557",
      },
    },
    {
      type: "Feature",
      id: "smb01358e5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [2.59141611, -70.0895091],
            [2.59141611, -72.19162138],
            [6.01915048, -72.19162138],
            [6.01915048, -70.09321749],
            [2.59141611, -70.0895091],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK556",
      },
    },
    {
      type: "Feature",
      id: "smf4f0c9bf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.01915048, -70.09321749],
            [6.01915048, -72.19162138],
            [9.31504892, -72.19162138],
            [9.31504892, -70.09678263],
            [6.01915048, -70.09321749],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK555",
      },
    },
    {
      type: "Feature",
      id: "sm3d9f2a67",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.31504892, -70.09678263],
            [9.31504892, -72.19162138],
            [12.52305673, -72.19162138],
            [12.52305673, -70.1002521],
            [9.31504892, -70.09678263],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK554",
      },
    },
    {
      type: "Feature",
      id: "sm5b4d25b7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.52305673, -70.1002521],
            [12.52305673, -72.19162138],
            [16.01945559, -72.19162138],
            [15.97718627, -70.10398712],
            [12.52305673, -70.1002521],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK553",
      },
    },
    {
      type: "Feature",
      id: "smd4b99c01",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.54482841, -67.60892379],
            [12.52286156, -70.10025189],
            [15.97718627, -70.10398712],
            [15.97551027, -70.01653724],
            [15.97551027, -67.61307541],
            [12.54482841, -67.60892379],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK551",
      },
    },
    {
      type: "Feature",
      id: "sm91170f6b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.52286156, -70.10025189],
            [12.54482841, -67.60892379],
            [9.3796809, -67.60509286],
            [9.35771405, -70.09682877],
            [12.52286156, -70.10025189],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK550",
      },
    },
    {
      type: "Feature",
      id: "sm9218093b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.35771405, -70.09682877],
            [9.3796809, -67.60509286],
            [5.77927325, -67.60073436],
            [5.7573064, -70.09293423],
            [9.35771405, -70.09682877],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK549",
      },
    },
    {
      type: "Feature",
      id: "sm73f89a6e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.7573064, -70.09293423],
            [5.77927325, -67.60073436],
            [2.61412574, -67.5969021],
            [2.59215889, -70.0895099],
            [5.7573064, -70.09293423],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK548",
      },
    },
    {
      type: "Feature",
      id: "sma68015a9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [2.59215889, -70.0895099],
            [2.61412574, -67.5969021],
            [-0.54990482, -67.59307057],
            [-0.57187167, -70.08608622],
            [2.59215889, -70.0895099],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK547",
      },
    },
    {
      type: "Feature",
      id: "sm793ae08e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.57187167, -70.08608622],
            [-0.54990482, -67.59307057],
            [-4.15450101, -67.58870477],
            [-4.17646787, -70.08218512],
            [-0.57187167, -70.08608622],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK546",
      },
    },
    {
      type: "Feature",
      id: "sm9e7b3fc4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-4.17646787, -70.08218512],
            [-4.15450101, -67.58870477],
            [-7.24655833, -67.5849591],
            [-7.26852518, -70.07883814],
            [-4.17646787, -70.08218512],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK545",
      },
    },
    {
      type: "Feature",
      id: "sm12c00baf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-17.22475967, -67.57615005],
            [-17.25258722, -70.15652382],
            [-13.80278132, -70.16107106],
            [-13.84138425, -67.86279878],
            [-14.50056394, -67.5795777],
            [-17.22475967, -67.57615005],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK542",
      },
    },
    {
      type: "Feature",
      id: "sm955d6725",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-17.25258722, -70.15652382],
            [-17.22475967, -67.57615005],
            [-20.56558459, -67.57194588],
            [-20.59340455, -70.15211929],
            [-17.25258722, -70.15652382],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK541",
      },
    },
    {
      type: "Feature",
      id: "smd5ef1718",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-20.59340455, -70.15211929],
            [-20.56558459, -67.57194588],
            [-24.08082324, -67.56752141],
            [-24.10863522, -70.1474838],
            [-20.59340455, -70.15211929],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK540",
      },
    },
    {
      type: "Feature",
      id: "smb138fd68",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-24.10863522, -70.1474838],
            [-24.08082324, -67.56752141],
            [-27.33307602, -67.56342721],
            [-27.36088062, -70.14319417],
            [-24.10863522, -70.1474838],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK539",
      },
    },
    {
      type: "Feature",
      id: "sm7230dd27",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-27.36088062, -70.14319417],
            [-27.33307602, -67.56342721],
            [-30.76144912, -67.55911053],
            [-30.78924593, -70.13867129],
            [-27.36088062, -70.14319417],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK538",
      },
    },
    {
      type: "Feature",
      id: "sma1929533",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-30.78924593, -70.13867129],
            [-30.76144912, -67.55911053],
            [-34.01233664, -67.55501659],
            [-34.04012606, -70.13438164],
            [-30.78924593, -70.13867129],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK537",
      },
    },
    {
      type: "Feature",
      id: "smfe56f2f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.04012606, -70.13438164],
            [-34.01233664, -67.55501659],
            [-37.26527206, -67.55091937],
            [-37.29305409, -70.1300884],
            [-34.04012606, -70.13438164],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK536",
      },
    },
    {
      type: "Feature",
      id: "sm1d96923b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.29305409, -70.1300884],
            [-37.26527206, -67.55091937],
            [-40.51650089, -67.54682359],
            [-40.54427554, -70.12579653],
            [-37.29305409, -70.1300884],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK535",
      },
    },
    {
      type: "Feature",
      id: "sm2bbad35f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-40.54427554, -70.12579653],
            [-40.51650089, -67.54682359],
            [-43.94521531, -67.54250345],
            [-43.97298217, -70.12126939],
            [-40.54427554, -70.12579653],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK534",
      },
    },
    {
      type: "Feature",
      id: "smcc495288",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-43.97298217, -70.12126939],
            [-43.94521531, -67.54250345],
            [-47.28330968, -67.53829673],
            [-47.31106897, -70.11686096],
            [-43.97298217, -70.12126939],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK533",
      },
    },
    {
      type: "Feature",
      id: "sm845cac7d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-47.31106897, -70.11686096],
            [-47.28330968, -67.53829673],
            [-50.80025492, -67.53386381],
            [-50.82800622, -70.11221531],
            [-47.31106897, -70.11686096],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK532",
      },
    },
    {
      type: "Feature",
      id: "sm5e0fd857",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-50.82800622, -70.11221531],
            [-50.80025492, -67.53386381],
            [-54.05148375, -67.52976508],
            [-54.07922766, -70.10791973],
            [-50.82800622, -70.11221531],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK531",
      },
    },
    {
      type: "Feature",
      id: "smb7a955d5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-54.07922766, -70.10791973],
            [-54.05148375, -67.52976508],
            [-57.4825874, -67.52543882],
            [-57.51032352, -70.10338553],
            [-54.07922766, -70.10791973],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK530",
      },
    },
    {
      type: "Feature",
      id: "smf7c800a3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.51032352, -70.10338553],
            [-57.4825874, -67.52543882],
            [-59.46211179, -67.52294248],
            [-60.774978, -67.97842902],
            [-60.79801804, -70.0990399],
            [-57.51032352, -70.10338553],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK529",
      },
    },
    {
      type: "Feature",
      id: "smbedeaa30",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.52643255, -72.23440066],
            [-57.50076662, -70.10339816],
            [-60.79801804, -70.0990399],
            [-60.81892331, -71.85253773],
            [-59.54450925, -72.23860129],
            [-57.52643255, -72.23440066],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK574",
      },
    },
    {
      type: "Feature",
      id: "sma3f336c0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-17.3322849, -70.15641876],
            [-17.35628069, -72.15058613],
            [-14.45661862, -72.14452124],
            [-13.76997311, -71.93277909],
            [-13.80278132, -70.16107106],
            [-17.3322849, -70.15641876],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK561",
      },
    },
    {
      type: "Feature",
      id: "sm8b65b6a8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-17.35628069, -72.15058613],
            [-17.3322849, -70.15641876],
            [-20.58043448, -70.15213639],
            [-20.60456533, -72.15737783],
            [-17.35628069, -72.15058613],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK562",
      },
    },
    {
      type: "Feature",
      id: "sm4484f885",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-20.60456533, -72.15737783],
            [-20.58043448, -70.15213639],
            [-24.09941964, -70.14749595],
            [-24.1236968, -72.16473301],
            [-20.60456533, -72.15737783],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK563",
      },
    },
    {
      type: "Feature",
      id: "smd7c31e76",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-24.1236968, -72.16473301],
            [-24.09941964, -70.14749595],
            [-27.3547369, -70.14320228],
            [-27.37914941, -72.17153447],
            [-24.1236968, -72.16473301],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK564",
      },
    },
    {
      type: "Feature",
      id: "smaf881514",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-27.37914941, -72.17153447],
            [-27.3547369, -70.14320228],
            [-30.78241958, -70.1386803],
            [-30.8069746, -72.17869335],
            [-27.37914941, -72.17153447],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK565",
      },
    },
    {
      type: "Feature",
      id: "sm17756ac3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-30.8069746, -72.17869335],
            [-30.78241958, -70.1386803],
            [-33.94643436, -70.13450529],
            [-33.97112094, -72.18529908],
            [-30.8069746, -72.17869335],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK566",
      },
    },
    {
      type: "Feature",
      id: "sm8f908530",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-33.97112094, -72.18529908],
            [-33.94643436, -70.13450529],
            [-37.20243425, -70.13020802],
            [-37.22725621, -72.19209438],
            [-33.97112094, -72.18529908],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK567",
      },
    },
    {
      type: "Feature",
      id: "smb6588b8e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.22725621, -72.19209438],
            [-37.20243425, -70.13020802],
            [-40.62158399, -70.12569446],
            [-40.64654811, -72.19922748],
            [-37.22725621, -72.19209438],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK568",
      },
    },
    {
      type: "Feature",
      id: "sm3c3c713d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-40.64654811, -72.19922748],
            [-40.62158399, -70.12569446],
            [-44.05199721, -70.12116505],
            [-44.07710396, -72.20638129],
            [-40.64654811, -72.19922748],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK569",
      },
    },
    {
      type: "Feature",
      id: "sm6aa8e90c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.07710396, -72.20638129],
            [-44.05199721, -70.12116505],
            [-47.30287734, -70.11687178],
            [-47.32811926, -72.21315814],
            [-44.07710396, -72.20638129],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK570",
      },
    },
    {
      type: "Feature",
      id: "sm6707bcf1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-47.32811926, -72.21315814],
            [-47.30287734, -70.11687178],
            [-50.73192529, -70.11234224],
            [-50.75730978, -72.22030369],
            [-47.32811926, -72.21315814],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK571",
      },
    },
    {
      type: "Feature",
      id: "smcb5617e8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-50.75730978, -72.22030369],
            [-50.73192529, -70.11234224],
            [-54.15994929, -70.10781306],
            [-54.18547631, -72.22744434],
            [-50.75730978, -72.22030369],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK572",
      },
    },
    {
      type: "Feature",
      id: "sm1fc2d24f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-54.18547631, -72.22744434],
            [-54.15994929, -70.10781306],
            [-57.50076662, -70.10339816],
            [-57.52643255, -72.23440066],
            [-54.18547631, -72.22744434],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK573",
      },
    },
    {
      type: "Feature",
      id: "sm0518e70c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.73323078, -67.81503577],
            [-71.74263816, -70.19866499],
            [-68.36102828, -70.20028294],
            [-68.3516209, -67.81683922],
            [-71.73323078, -67.81503577],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK635",
      },
    },
    {
      type: "Feature",
      id: "sm7475773a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.74263816, -70.19866499],
            [-71.73323078, -67.81503577],
            [-75.24903497, -67.81316061],
            [-75.25844236, -70.19698269],
            [-71.74263816, -70.19866499],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK636",
      },
    },
    {
      type: "Feature",
      id: "smba6c3445",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.25844236, -70.19698269],
            [-75.24903497, -67.81316061],
            [-78.63281728, -67.81135573],
            [-78.64222467, -70.19536345],
            [-75.25844236, -70.19698269],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK637",
      },
    },
    {
      type: "Feature",
      id: "smdc63ed08",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.64222467, -70.19536345],
            [-78.63281728, -67.81135573],
            [-82.28002267, -67.80941017],
            [-82.28943005, -70.193618],
            [-78.64222467, -70.19536345],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK638",
      },
    },
    {
      type: "Feature",
      id: "sm7884bede",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.28943005, -70.193618],
            [-82.28002267, -67.80941017],
            [-85.62085287, -67.80762791],
            [-85.63026025, -70.19201904],
            [-82.28943005, -70.193618],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK639",
      },
    },
    {
      type: "Feature",
      id: "smbff20157",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.48472221, -70.18873804],
            [-92.47531483, -67.80397078],
            [-94.87104734, -67.80269242],
            [-95.91474851, -68.19777098],
            [-95.96642665, -70.18707127],
            [-92.48472221, -70.18873804],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK642",
      },
    },
    {
      type: "Feature",
      id: "smf3989cf2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.47531483, -67.80397078],
            [-92.48472221, -70.18873804],
            [-89.18907863, -70.19031562],
            [-89.17967124, -67.80572921],
            [-92.47531483, -67.80397078],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK641",
      },
    },
    {
      type: "Feature",
      id: "sm95b8c731",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.17967124, -67.80572921],
            [-89.18907863, -70.19031562],
            [-85.63026025, -70.19201904],
            [-85.62085287, -67.80762791],
            [-89.17967124, -67.80572921],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK640",
      },
    },
    {
      type: "Feature",
      id: "sm3c6da49b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.53452217, -72.21630104],
            [-92.52562603, -70.18871846],
            [-95.96642665, -70.18707127],
            [-96.01362546, -71.85082674],
            [-94.87104734, -72.21680412],
            [-92.53452217, -72.21630104],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK624",
      },
    },
    {
      type: "Feature",
      id: "sm28b29224",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-92.52562603, -70.18871846],
            [-92.53452217, -72.21630104],
            [-89.14911592, -72.2155721],
            [-89.14022991, -70.19033901],
            [-92.52562603, -70.18871846],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK625",
      },
    },
    {
      type: "Feature",
      id: "sm8e714603",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.14022991, -70.19033901],
            [-89.14911592, -72.2155721],
            [-85.62785261, -72.21481387],
            [-85.63228255, -70.19201808],
            [-89.14022991, -70.19033901],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK626",
      },
    },
    {
      type: "Feature",
      id: "sm531253b4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-85.63228255, -70.19201808],
            [-85.62785261, -72.21481387],
            [-82.11235328, -72.21405685],
            [-82.11677797, -70.19370063],
            [-85.63228255, -70.19201808],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK627",
      },
    },
    {
      type: "Feature",
      id: "smc5f17a62",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.11677797, -70.19370063],
            [-82.11235328, -72.21405685],
            [-78.72844198, -72.21332814],
            [-78.73286162, -70.19532007],
            [-82.11677797, -70.19370063],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK628",
      },
    },
    {
      type: "Feature",
      id: "sm39cbe8a0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.73286162, -70.19532007],
            [-78.72844198, -72.21332814],
            [-75.2564852, -72.21258044],
            [-75.26089965, -70.19698152],
            [-78.73286162, -70.19532007],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK629",
      },
    },
    {
      type: "Feature",
      id: "sm3b12f8b7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.26089965, -70.19698152],
            [-75.2564852, -72.21258044],
            [-71.74049018, -72.21182322],
            [-71.74489939, -70.1986639],
            [-75.26089965, -70.19698152],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK630",
      },
    },
    {
      type: "Feature",
      id: "sm6729ef67",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.74489939, -70.1986639],
            [-71.74049018, -72.21182322],
            [-68.36985215, -72.21109728],
            [-68.36102828, -70.20028294],
            [-71.74489939, -70.1986639],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK631",
      },
    },
    {
      type: "Feature",
      id: "smf183f8ef",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.53684639, -71.08859187],
            [-68.36480295, -71.08592379],
            [-68.36985215, -72.21109728],
            [-63.70283445, -72.21009209],
            [-62.52729734, -71.82342954],
            [-62.53684639, -71.08859187],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK632",
      },
    },
    {
      type: "Feature",
      id: "sm76a95241",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.36480295, -71.08592379],
            [-62.53684639, -71.08859187],
            [-62.55427498, -69.67302356],
            [-68.35884577, -69.67017538],
            [-68.36480295, -71.08592379],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK633",
      },
    },
    {
      type: "Feature",
      id: "sma7618322",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.35884577, -69.67017538],
            [-62.55427498, -69.67302356],
            [-62.57124265, -68.19777098],
            [-63.75776609, -67.81928894],
            [-68.3516209, -67.81683922],
            [-68.35884577, -69.67017538],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK634",
      },
    },
    {
      type: "Feature",
      id: "sm5309a37a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.28595504, -65.41043622],
            [7.88817251, -65.45123595],
            [7.93989749, -66.81540825],
            [13.30583252, -66.81753131],
            [14.2726294, -66.41640147],
            [14.28595504, -65.41043622],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK283",
      },
    },
    {
      type: "Feature",
      id: "sm10a0ddf5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.88817251, -65.45123595],
            [14.28595504, -65.41043622],
            [14.30357835, -64.01819434],
            [7.83818364, -64.06160224],
            [7.88817251, -65.45123595],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK284",
      },
    },
    {
      type: "Feature",
      id: "smcb8f2f07",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.83818364, -64.06160224],
            [14.30357835, -64.01819434],
            [14.32311891, -62.3889572],
            [7.78275643, -62.43541258],
            [7.83818364, -64.06160224],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK285",
      },
    },
    {
      type: "Feature",
      id: "smd25dad1e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.78275643, -62.43541258],
            [14.32311891, -62.3889572],
            [14.34486822, -60.46525403],
            [7.72106408, -60.51529536],
            [7.78275643, -62.43541258],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK286",
      },
    },
    {
      type: "Feature",
      id: "sm149ffc20",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.72106408, -60.51529536],
            [14.34486822, -60.46525403],
            [14.36052002, -59.00618177],
            [13.26188721, -58.45877577],
            [7.66023665, -58.50369016],
            [7.72106408, -60.51529536],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK287",
      },
    },
    {
      type: "Feature",
      id: "sm6d3b70b8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.50088212, -60.53364992],
            [7.72017319, -60.48669342],
            [7.66023665, -58.50369016],
            [2.51725831, -58.54487635],
            [1.52848877, -59.07400318],
            [1.50088212, -60.53364992],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK288",
      },
    },
    {
      type: "Feature",
      id: "smdd03ed52",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.88597733, -65.39170431],
            [1.39802634, -65.43310816],
            [1.37468018, -66.43397723],
            [2.37443604, -66.81320605],
            [7.93989749, -66.81540825],
            [7.88597733, -65.39170431],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK292",
      },
    },
    {
      type: "Feature",
      id: "sm5439c679",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.39802634, -65.43310816],
            [7.88597733, -65.39170431],
            [7.8373775, -64.03860266],
            [1.428175, -64.08160212],
            [1.39802634, -65.43310816],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK291",
      },
    },
    {
      type: "Feature",
      id: "smf28ac574",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.428175, -64.08160212],
            [7.8373775, -64.03860266],
            [7.78196063, -62.4113926],
            [1.46255259, -62.45624613],
            [1.428175, -64.08160212],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK290",
      },
    },
    {
      type: "Feature",
      id: "sm0fe035dc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.46255259, -62.45624613],
            [7.78196063, -62.4113926],
            [7.72017319, -60.48669342],
            [1.50088212, -60.53364992],
            [1.46255259, -62.45624613],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK289",
      },
    },
    {
      type: "Feature",
      id: "sm10909d5d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.43341856, -61.32642246],
            [-71.48452345, -64.51031176],
            [-68.0973864, -64.52094946],
            [-68.04612808, -61.32819933],
            [-71.43341856, -61.32642246],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK675",
      },
    },
    {
      type: "Feature",
      id: "smca2e6ead",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.60847977, -64.44702457],
            [-91.55828647, -61.31586351],
            [-93.82734616, -61.31467278],
            [-94.96992429, -61.87905132],
            [-94.99250492, -64.43636787],
            [-91.60847977, -64.44702457],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK682",
      },
    },
    {
      type: "Feature",
      id: "smd94f71fa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.55828647, -61.31586351],
            [-91.60847977, -64.44702457],
            [-88.17996576, -64.45781715],
            [-88.12961715, -61.31766269],
            [-91.55828647, -61.31586351],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK681",
      },
    },
    {
      type: "Feature",
      id: "smd46b01e5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.12961715, -61.31766269],
            [-88.17996576, -64.45781715],
            [-84.8406207, -64.46832494],
            [-84.79012082, -61.31941498],
            [-88.12961715, -61.31766269],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK680",
      },
    },
    {
      type: "Feature",
      id: "sm94a0ddca",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.79012082, -61.31941498],
            [-84.8406207, -64.46832494],
            [-81.41306894, -64.47910609],
            [-81.3624138, -61.32121344],
            [-84.79012082, -61.31941498],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK679",
      },
    },
    {
      type: "Feature",
      id: "smdbcc565d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.3624138, -61.32121344],
            [-81.41306894, -64.47910609],
            [-78.20459053, -64.48919432],
            [-78.15379006, -61.32289687],
            [-81.3624138, -61.32121344],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK678",
      },
    },
    {
      type: "Feature",
      id: "sma3cf6d5a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.15379006, -61.32289687],
            [-78.20459053, -64.48919432],
            [-74.73373731, -64.50010332],
            [-74.68277961, -61.32471786],
            [-78.15379006, -61.32289687],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK677",
      },
    },
    {
      type: "Feature",
      id: "smf4e53080",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.68277961, -61.32471786],
            [-74.73373731, -64.50010332],
            [-71.48452345, -64.51031176],
            [-71.43341856, -61.32642246],
            [-74.68277961, -61.32471786],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK676",
      },
    },
    {
      type: "Feature",
      id: "smd9a7919b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.67739513, -67.0757247],
            [-91.63061163, -64.44695489],
            [-94.99250492, -64.43636787],
            [-95.0138696, -66.65481115],
            [-93.9591821, -67.06923632],
            [-91.67739513, -67.0757247],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK664",
      },
    },
    {
      type: "Feature",
      id: "sm1534b48c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.63061163, -64.44695489],
            [-91.67739513, -67.0757247],
            [-88.34093683, -67.08520896],
            [-88.29415333, -64.45745776],
            [-91.63061163, -64.44695489],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK665",
      },
    },
    {
      type: "Feature",
      id: "sm4774bacc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-88.29415333, -64.45745776],
            [-88.34093683, -67.08520896],
            [-84.94963, -67.09484533],
            [-84.90284651, -64.46812917],
            [-88.29415333, -64.45745776],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK666",
      },
    },
    {
      type: "Feature",
      id: "sma1b7ab1a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-84.90284651, -64.46812917],
            [-84.94963, -67.09484533],
            [-81.47941828, -67.10470193],
            [-81.43263479, -64.47904456],
            [-84.90284651, -64.46812917],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK667",
      },
    },
    {
      type: "Feature",
      id: "sm0a430567",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-81.43263479, -64.47904456],
            [-81.47941828, -67.10470193],
            [-78.27286439, -67.11380609],
            [-78.22608089, -64.48912676],
            [-81.43263479, -64.47904456],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK668",
      },
    },
    {
      type: "Feature",
      id: "sm647939e3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-78.22608089, -64.48912676],
            [-78.27286439, -67.11380609],
            [-74.80393567, -67.12365134],
            [-74.75715218, -64.50002974],
            [-78.22608089, -64.48912676],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK669",
      },
    },
    {
      type: "Feature",
      id: "smff2988df",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.75715218, -64.50002974],
            [-74.80393567, -67.12365134],
            [-71.60507982, -67.13272653],
            [-71.55829632, -64.51008002],
            [-74.75715218, -64.50002974],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK670",
      },
    },
    {
      type: "Feature",
      id: "smff46a34e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-71.55829632, -64.51008002],
            [-71.60507982, -67.13272653],
            [-68.14416989, -67.14254134],
            [-68.0973864, -64.52094946],
            [-71.55829632, -64.51008002],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK671",
      },
    },
    {
      type: "Feature",
      id: "sm794a0232",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.50543398, -65.5218588],
            [-68.11439091, -65.50488942],
            [-68.14416989, -67.14254134],
            [-63.85664304, -67.15469482],
            [-62.49433835, -66.63739082],
            [-62.50543398, -65.5218588],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK672",
      },
    },
    {
      type: "Feature",
      id: "sm0b8439d2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.11439091, -65.50488942],
            [-62.50543398, -65.5218588],
            [-62.52188162, -63.77604448],
            [-68.08461954, -63.75809667],
            [-68.11439091, -65.50488942],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK673",
      },
    },
    {
      type: "Feature",
      id: "smf865bdd8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.08461954, -63.75809667],
            [-62.52188162, -63.77604448],
            [-62.53828367, -61.92044918],
            [-63.68086179, -61.33048906],
            [-68.04612808, -61.32819933],
            [-68.08461954, -63.75809667],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK674",
      },
    },
    {
      type: "Feature",
      id: "sm059996d7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.67920622, -40.19442181],
            [-53.65018976, -40.21390785],
            [-53.63077858, -36.00830019],
            [-59.63239989, -36.00397307],
            [-60.67610106, -36.98431149],
            [-60.67920622, -40.19442181],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK528",
      },
    },
    {
      type: "Feature",
      id: "smc0cdb2d3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.65018976, -40.21390785],
            [-60.67920622, -40.19442181],
            [-60.68159551, -42.56583298],
            [-53.66166341, -42.5845967],
            [-53.65018976, -40.21390785],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK527",
      },
    },
    {
      type: "Feature",
      id: "sm26268eed",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.66166341, -42.5845967],
            [-60.68159551, -42.56583298],
            [-60.68385506, -44.72860298],
            [-53.67251407, -44.74668072],
            [-53.66166341, -42.5845967],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK526",
      },
    },
    {
      type: "Feature",
      id: "sm89dc73c9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.67251407, -44.74668072],
            [-60.68385506, -44.72860298],
            [-60.6863446, -47.0212569],
            [-53.68446914, -47.03858029],
            [-53.67251407, -44.74668072],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK525",
      },
    },
    {
      type: "Feature",
      id: "sm066acbda",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.68446914, -47.03858029],
            [-60.6863446, -47.0212569],
            [-60.6886707, -49.07809821],
            [-53.6956394, -49.09472208],
            [-53.68446914, -47.03858029],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK524",
      },
    },
    {
      type: "Feature",
      id: "smb9e4e6a6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.6956394, -49.09472208],
            [-60.6886707, -49.07809821],
            [-60.69103027, -51.08097568],
            [-53.70697033, -51.09689866],
            [-53.6956394, -49.09472208],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK523",
      },
    },
    {
      type: "Feature",
      id: "sm65f93f86",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.70697033, -51.09689866],
            [-60.69103027, -51.08097568],
            [-60.6933571, -52.97467818],
            [-53.71814407, -52.9899214],
            [-53.70697033, -51.09689866],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK522",
      },
    },
    {
      type: "Feature",
      id: "smd9100e9e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.71814407, -52.9899214],
            [-60.6933571, -52.97467818],
            [-60.69581655, -54.88993147],
            [-53.72995469, -54.90447117],
            [-53.71814407, -52.9899214],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK521",
      },
    },
    {
      type: "Feature",
      id: "sm3ffbe389",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.72995469, -54.90447117],
            [-60.69581655, -54.88993147],
            [-60.69807371, -56.57111207],
            [-60.69811801, -56.61781884],
            [-53.74110179, -56.63171065],
            [-53.72995469, -54.90447117],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK520",
      },
    },
    {
      type: "Feature",
      id: "sm2c105a7a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.74110179, -56.63171065],
            [-60.69811801, -56.61781884],
            [-60.69974673, -58.29529327],
            [-53.75242288, -58.30854344],
            [-53.74110179, -56.63171065],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK519",
      },
    },
    {
      type: "Feature",
      id: "sm2af220ef",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.75242288, -58.30854344],
            [-60.69974673, -58.29529327],
            [-60.70137645, -59.89782554],
            [-53.76375091, -59.91045298],
            [-53.75242288, -58.30854344],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK518",
      },
    },
    {
      type: "Feature",
      id: "sm5733b466",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.76375091, -59.91045298],
            [-60.70137645, -59.89782554],
            [-60.70300617, -61.42660544],
            [-53.77507895, -61.43863041],
            [-53.76375091, -59.91045298],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK517",
      },
    },
    {
      type: "Feature",
      id: "sm06d70ba3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.77507895, -61.43863041],
            [-60.70300617, -61.42660544],
            [-60.70470406, -62.94343603],
            [-53.78688088, -62.95485561],
            [-53.77507895, -61.43863041],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK516",
      },
    },
    {
      type: "Feature",
      id: "sm3d2c9320",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.78688088, -62.95485561],
            [-60.70470406, -62.94343603],
            [-60.70640196, -64.38552584],
            [-53.79868281, -64.39636327],
            [-53.78688088, -62.95485561],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK515",
      },
    },
    {
      type: "Feature",
      id: "sm3ac21e69",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.79868281, -64.39636327],
            [-60.70640196, -64.38552584],
            [-60.70805687, -65.72186828],
            [-53.81018598, -65.73216087],
            [-53.79868281, -64.39636327],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK514",
      },
    },
    {
      type: "Feature",
      id: "sm2feb663a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.81018598, -65.73216087],
            [-60.70805687, -65.72186828],
            [-60.70906004, -66.49977637],
            [-59.58845457, -66.95553793],
            [-53.8213313, -66.96373079],
            [-53.81018598, -65.73216087],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK513",
      },
    },
    {
      type: "Feature",
      id: "smb68adcdd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.54916846, -65.70370736],
            [-53.80983917, -65.69286138],
            [-53.8213313, -66.96373079],
            [-47.48152098, -66.97273405],
            [-46.55866942, -66.43397723],
            [-46.54916846, -65.70370736],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK512",
      },
    },
    {
      type: "Feature",
      id: "sm042dfcdb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.65046246, -40.27125483],
            [-46.31746385, -40.2915604],
            [-46.29499754, -36.9667577],
            [-47.30573973, -36.01286025],
            [-53.63077858, -36.00830019],
            [-53.65046246, -40.27125483],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK497",
      },
    },
    {
      type: "Feature",
      id: "smc27540c5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.31746385, -40.2915604],
            [-53.65046246, -40.27125483],
            [-53.66180323, -42.61295277],
            [-46.33395126, -42.63252461],
            [-46.31746385, -40.2915604],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK498",
      },
    },
    {
      type: "Feature",
      id: "sma6f3c4f2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.33395126, -42.63252461],
            [-53.66180323, -42.61295277],
            [-53.67247933, -44.73988307],
            [-46.34947236, -44.75876058],
            [-46.33395126, -42.63252461],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK499",
      },
    },
    {
      type: "Feature",
      id: "sme13c3166",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.34947236, -44.75876058],
            [-53.67247933, -44.73988307],
            [-53.68445177, -47.035319],
            [-46.3668781, -47.05341856],
            [-46.34947236, -44.75876058],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK500",
      },
    },
    {
      type: "Feature",
      id: "sm390b4389",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.3668781, -47.05341856],
            [-53.68445177, -47.035319],
            [-53.69560293, -49.08814141],
            [-46.38308984, -49.10552111],
            [-46.3668781, -47.05341856],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK501",
      },
    },
    {
      type: "Feature",
      id: "smfee0da45",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.38308984, -49.10552111],
            [-53.69560293, -49.08814141],
            [-53.70694023, -51.09168939],
            [-46.3995722, -51.10834549],
            [-46.38308984, -49.10552111],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK502",
      },
    },
    {
      type: "Feature",
      id: "sme296eb93",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.3995722, -51.10834549],
            [-53.70694023, -51.09168939],
            [-53.71841677, -53.03512189],
            [-46.416257, -53.05105715],
            [-46.3995722, -51.10834549],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK503",
      },
    },
    {
      type: "Feature",
      id: "smce48740e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.416257, -53.05105715],
            [-53.71841677, -53.03512189],
            [-53.729913, -54.89786763],
            [-46.43297041, -54.91309525],
            [-46.416257, -53.05105715],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK504",
      },
    },
    {
      type: "Feature",
      id: "sm95f1a245",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.43297041, -54.91309525],
            [-53.729913, -54.89786763],
            [-53.74105837, -56.62513011],
            [-46.44917374, -56.63968763],
            [-46.43297041, -54.91309525],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK505",
      },
    },
    {
      type: "Feature",
      id: "sm245e8199",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.44917374, -56.63968763],
            [-53.74105837, -56.62513011],
            [-53.75256444, -58.32902593],
            [-46.46590146, -58.34290993],
            [-46.44917374, -56.63968763],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK506",
      },
    },
    {
      type: "Feature",
      id: "smdcd7d510",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.46590146, -58.34290993],
            [-53.75256444, -58.32902593],
            [-53.76373007, -59.90757393],
            [-46.48213425, -59.92082343],
            [-46.46590146, -58.34290993],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK507",
      },
    },
    {
      type: "Feature",
      id: "sm28177559",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.48213425, -59.92082343],
            [-53.76373007, -59.90757393],
            [-53.77522166, -61.45742314],
            [-46.49884093, -61.47004033],
            [-46.48213425, -59.92082343],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK508",
      },
    },
    {
      type: "Feature",
      id: "smc7940cb5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.49884093, -61.47004033],
            [-53.77522166, -61.45742314],
            [-53.78685077, -62.95108394],
            [-46.51574752, -62.96308344],
            [-46.49884093, -61.47004033],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK509",
      },
    },
    {
      type: "Feature",
      id: "smcc627cdc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.51574752, -62.96308344],
            [-53.78685077, -62.95108394],
            [-53.79881974, -64.41266054],
            [-46.53314821, -64.42404818],
            [-46.51574752, -62.96308344],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK510",
      },
    },
    {
      type: "Feature",
      id: "sm477b1952",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.53314821, -64.42404818],
            [-53.79881974, -64.41266054],
            [-53.80983917, -65.69286138],
            [-46.54916846, -65.70370736],
            [-46.53314821, -64.42404818],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK511",
      },
    },
    {
      type: "Feature",
      id: "sm1fbd8b8d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [46.04081837, -64.1684336],
            [39.32914129, -64.27269253],
            [39.4642798, -65.75847859],
            [46.17692628, -65.77989604],
            [46.04081837, -64.1684336],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK270",
      },
    },
    {
      type: "Feature",
      id: "sm5a2f8b39",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.32914129, -64.27269253],
            [46.04081837, -64.1684336],
            [45.91809509, -62.63035684],
            [39.19719619, -62.74051255],
            [39.32914129, -64.27269253],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK269",
      },
    },
    {
      type: "Feature",
      id: "sma82a83de",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.19719619, -62.74051255],
            [45.91809509, -62.63035684],
            [45.80175433, -61.09465737],
            [39.07211321, -61.21062962],
            [39.19719619, -62.74051255],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK268",
      },
    },
    {
      type: "Feature",
      id: "smf263f303",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [39.07211321, -61.21062962],
            [45.80175433, -61.09465737],
            [45.68586151, -59.48698057],
            [38.94751183, -59.60896079],
            [39.07211321, -61.21062962],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK267",
      },
    },
    {
      type: "Feature",
      id: "sma57bd6d6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.94751183, -59.60896079],
            [45.68586151, -59.48698057],
            [45.56056289, -57.65833837],
            [38.81279787, -57.78704483],
            [38.94751183, -59.60896079],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK266",
      },
    },
    {
      type: "Feature",
      id: "smbba9b107",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.81279787, -57.78704483],
            [45.56056289, -57.65833837],
            [45.44097488, -55.82255878],
            [38.68422363, -55.95789545],
            [38.81279787, -57.78704483],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK265",
      },
    },
    {
      type: "Feature",
      id: "smf4c1cc44",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.68422363, -55.95789545],
            [45.44097488, -55.82255878],
            [45.32463412, -53.949412],
            [38.55914065, -54.0913799],
            [38.68422363, -55.95789545],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK264",
      },
    },
    {
      type: "Feature",
      id: "sm5e272a4b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.55914065, -54.0913799],
            [45.32463412, -53.949412],
            [45.1963965, -51.78259915],
            [39.19786135, -51.86408536],
            [38.45079103, -52.40356634],
            [38.55914065, -54.0913799],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK263",
      },
    },
    {
      type: "Feature",
      id: "smbf463260",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.09863171, -48.80362583],
            [22.10706839, -44.54331587],
            [18.77822456, -44.54009442],
            [18.76978784, -48.80066689],
            [22.09863171, -48.80362583],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK222",
      },
    },
    {
      type: "Feature",
      id: "sm648760a0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.43276576, -44.55330732],
            [32.4243292, -48.81280305],
            [35.27574222, -48.81533701],
            [36.440293, -48.01323331],
            [36.25610819, -44.55700647],
            [32.43276576, -44.55330732],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK226",
      },
    },
    {
      type: "Feature",
      id: "sm6713aa85",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.4243292, -48.81280305],
            [32.43276576, -44.55330732],
            [29.05104351, -44.55003526],
            [29.04265814, -48.80979769],
            [32.4243292, -48.81280305],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK225",
      },
    },
    {
      type: "Feature",
      id: "sm68a5004e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.04265814, -48.80979769],
            [29.05104351, -44.55003526],
            [25.62161289, -44.54671684],
            [25.61317625, -48.80674966],
            [29.04265814, -48.80979769],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK224",
      },
    },
    {
      type: "Feature",
      id: "sm693b3fe5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.61317625, -48.80674966],
            [25.62161289, -44.54671684],
            [22.10706839, -44.54331587],
            [22.09863171, -48.80362583],
            [25.61317625, -48.80674966],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK223",
      },
    },
    {
      type: "Feature",
      id: "sm7e1455f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.52674812, -39.49450277],
            [32.51749384, -44.5533893],
            [36.25610819, -44.55700647],
            [36.04478519, -40.32285393],
            [34.92417972, -39.49701469],
            [32.52674812, -39.49450277],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK207",
      },
    },
    {
      type: "Feature",
      id: "smd0390718",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.51749384, -44.5533893],
            [32.52674812, -39.49450277],
            [29.2311541, -39.49104964],
            [29.22189981, -44.55020058],
            [32.51749384, -44.5533893],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK208",
      },
    },
    {
      type: "Feature",
      id: "sme1e6a6aa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.22189981, -44.55020058],
            [29.2311541, -39.49104964],
            [25.67141135, -39.48731955],
            [25.66215706, -44.54675607],
            [29.22189981, -44.55020058],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK209",
      },
    },
    {
      type: "Feature",
      id: "sm8bb16e79",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.66215706, -44.54675607],
            [25.67141135, -39.48731955],
            [22.17818312, -39.48365896],
            [22.16892883, -44.54337573],
            [25.66215706, -44.54675607],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK210",
      },
    },
    {
      type: "Feature",
      id: "sm07e5c1fd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.16892883, -44.54337573],
            [22.17818312, -39.48365896],
            [18.78747884, -39.48010562],
            [18.77822456, -44.54009442],
            [22.16892883, -44.54337573],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK211",
      },
    },
    {
      type: "Feature",
      id: "sm31483f36",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.28442633, -44.52270804],
            [18.77825123, -44.52609016],
            [18.78747884, -39.48010562],
            [15.29365394, -39.47644403],
            [15.28442633, -44.52270804],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK212",
      },
    },
    {
      type: "Feature",
      id: "smdcbb9c9f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.77825123, -44.52609016],
            [15.28442633, -44.52270804],
            [15.2759629, -48.7975611],
            [18.76978784, -48.80066689],
            [18.77825123, -44.52609016],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK221",
      },
    },
    {
      type: "Feature",
      id: "sm31e20ff0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.90162052, -44.50770055],
            [15.28444867, -44.51097607],
            [15.29365394, -39.47644403],
            [11.9108258, -39.47289858],
            [11.90162052, -44.50770055],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK213",
      },
    },
    {
      type: "Feature",
      id: "smf00c8813",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.28444867, -44.51097607],
            [11.90162052, -44.50770055],
            [11.89313472, -48.7945538],
            [15.2759629, -48.7975611],
            [15.28444867, -44.51097607],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK220",
      },
    },
    {
      type: "Feature",
      id: "smff58bb89",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.40710328, -44.4918787],
            [11.9016442, -44.4952633],
            [11.9108258, -39.47289858],
            [8.41628488, -39.46923586],
            [8.40710328, -44.4918787],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK214",
      },
    },
    {
      type: "Feature",
      id: "sm98477d06",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.9016442, -44.4952633],
            [8.40710328, -44.4918787],
            [8.39859376, -48.791447],
            [11.89313472, -48.7945538],
            [11.9016442, -44.4952633],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK219",
      },
    },
    {
      type: "Feature",
      id: "sm9a612ea9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.98035213, -44.44551969],
            [8.40718517, -44.44884135],
            [8.41628488, -39.46923586],
            [4.98945183, -39.46564391],
            [4.98035213, -44.44551969],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK215",
      },
    },
    {
      type: "Feature",
      id: "sm2d9bc468",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.40718517, -44.44884135],
            [4.98035213, -44.44551969],
            [4.97176068, -48.78840021],
            [8.39859376, -48.791447],
            [8.40718517, -44.44884135],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK218",
      },
    },
    {
      type: "Feature",
      id: "smac3a4ec8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.49249309, -44.38199877],
            [4.98046644, -44.38538337],
            [4.98945183, -39.46564391],
            [2.55845706, -39.46309568],
            [1.50376956, -40.20548685],
            [1.49249309, -44.38199877],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK216",
      },
    },
    {
      type: "Feature",
      id: "sm96abccbd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.98046644, -44.38538337],
            [1.49249309, -44.38199877],
            [1.4817969, -48.08667496],
            [2.71226565, -48.7863912],
            [4.97176068, -48.78840021],
            [4.98046644, -44.38538337],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK217",
      },
    },
    {
      type: "Feature",
      id: "sm14157cd3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [1.54262228, -54.03521213],
            [5.02074354, -54.04891899],
            [5.06526113, -49.96970415],
            [2.70127932, -49.9594984],
            [1.52574221, -50.61929621],
            [1.54262228, -54.03521213],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK236",
      },
    },
    {
      type: "Feature",
      id: "smeb0b9f70",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.02074354, -54.04891899],
            [1.54262228, -54.03521213],
            [1.5587012, -57.04772943],
            [2.64634768, -57.55221714],
            [4.97890374, -57.55015328],
            [5.02074354, -54.04891899],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK237",
      },
    },
    {
      type: "Feature",
      id: "smb01e5ddd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.7269644, -54.38871996],
            [33.05116869, -54.37435266],
            [33.01337405, -57.52533908],
            [35.95689455, -57.5227327],
            [36.92369142, -56.99989141],
            [36.7269644, -54.38871996],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK246",
      },
    },
    {
      type: "Feature",
      id: "smff3242bb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.05116869, -54.37435266],
            [36.7269644, -54.38871996],
            [36.47325198, -50.75849963],
            [35.44053713, -50.10064741],
            [33.09815847, -50.09056247],
            [33.05116869, -54.37435266],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK227",
      },
    },
    {
      type: "Feature",
      id: "sm4cef2018",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.50516675, -54.19465915],
            [33.05307479, -54.2085873],
            [33.09815847, -50.09056247],
            [29.55025043, -50.07528317],
            [29.50516675, -54.19465915],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK228",
      },
    },
    {
      type: "Feature",
      id: "sm77951e4c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [33.05307479, -54.2085873],
            [29.50516675, -54.19465915],
            [29.46526693, -57.52848056],
            [33.01337405, -57.52533908],
            [33.05307479, -54.2085873],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK245",
      },
    },
    {
      type: "Feature",
      id: "smd282885a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.9374178, -54.13626502],
            [29.50567544, -54.15029283],
            [29.55025043, -50.07528317],
            [25.98199279, -50.05991132],
            [25.9374178, -54.13626502],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK229",
      },
    },
    {
      type: "Feature",
      id: "smf001f175",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.50567544, -54.15029283],
            [25.9374178, -54.13626502],
            [25.89680907, -57.53163978],
            [29.46526693, -57.52848056],
            [29.50567544, -54.15029283],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK244",
      },
    },
    {
      type: "Feature",
      id: "smd6224206",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.46280671, -54.16043711],
            [25.93698433, -54.17408715],
            [25.98199279, -50.05991132],
            [22.50781518, -50.04494003],
            [22.46280671, -54.16043711],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK230",
      },
    },
    {
      type: "Feature",
      id: "sma2dc410f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.93698433, -54.17408715],
            [22.46280671, -54.16043711],
            [22.42243652, -57.53471544],
            [25.89680907, -57.53163978],
            [25.93698433, -54.17408715],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK243",
      },
    },
    {
      type: "Feature",
      id: "sm9ee6618b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.99290051, -54.1600987],
            [22.4626543, -54.17373147],
            [22.50781518, -50.04494003],
            [19.03806139, -50.02998314],
            [18.99290051, -54.1600987],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK231",
      },
    },
    {
      type: "Feature",
      id: "sm5e9d7d80",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.4626543, -54.17373147],
            [18.99290051, -54.1600987],
            [18.95248803, -57.53778693],
            [22.42243652, -57.53471544],
            [22.4626543, -54.17373147],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK242",
      },
    },
    {
      type: "Feature",
      id: "sm2d9a9d58",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.39352765, -54.13316642],
            [18.99304699, -54.14731816],
            [19.03806139, -50.02998314],
            [15.43854205, -50.01446196],
            [15.39352765, -54.13316642],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK232",
      },
    },
    {
      type: "Feature",
      id: "sm1ba37a57",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.99304699, -54.14731816],
            [15.39352765, -54.13316642],
            [15.35276672, -57.54097302],
            [18.95248803, -57.53778693],
            [18.99304699, -54.14731816],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK241",
      },
    },
    {
      type: "Feature",
      id: "sm982051b2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.87750282, -54.11657239],
            [15.39355932, -54.13040159],
            [15.43854205, -50.01446196],
            [11.92248556, -49.99929582],
            [11.87750282, -54.11657239],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK233",
      },
    },
    {
      type: "Feature",
      id: "sm8e618d81",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [15.39355932, -54.13040159],
            [11.87750282, -54.11657239],
            [11.83651294, -57.54408496],
            [15.35276672, -57.54097302],
            [15.39355932, -54.13040159],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK240",
      },
    },
    {
      type: "Feature",
      id: "sm30f8de39",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.44799496, -54.09114535],
            [11.8776394, -54.10464301],
            [11.92248556, -49.99929582],
            [8.49284112, -49.98449781],
            [8.44799496, -54.09114535],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK234",
      },
    },
    {
      type: "Feature",
      id: "sm9782d3bd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.8776394, -54.10464301],
            [8.44799496, -54.09114535],
            [8.40667606, -57.54712016],
            [11.83651294, -57.54408496],
            [11.8776394, -54.10464301],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK239",
      },
    },
    {
      type: "Feature",
      id: "sm2a7a836f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [5.02054165, -54.06657622],
            [8.44812164, -54.08007374],
            [8.49284112, -49.98449781],
            [5.06526113, -49.96970415],
            [5.02054165, -54.06657622],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK235",
      },
    },
    {
      type: "Feature",
      id: "sm02138012",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [8.44812164, -54.08007374],
            [5.02054165, -54.06657622],
            [4.97890374, -57.55015328],
            [8.40667606, -57.54712016],
            [8.44812164, -54.08007374],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK238",
      },
    },
    {
      type: "Feature",
      id: "smb2fe6ee2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.057794, -38.50772897],
            [-7.33988903, -38.51277867],
            [-7.33636975, -35.58956833],
            [-12.99543701, -35.58514747],
            [-14.05012451, -36.50894025],
            [-14.057794, -38.50772897],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK437",
      },
    },
    {
      type: "Feature",
      id: "sm63bae6b6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.38400215, -65.58473297],
            [-14.19755558, -65.58202739],
            [-14.2039331, -66.43397723],
            [-13.28108154, -66.8434669],
            [-7.38699022, -66.83911789],
            [-7.38400215, -65.58473297],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK422",
      },
    },
    {
      type: "Feature",
      id: "sm880d3e68",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.19755558, -65.58202739],
            [-7.38400215, -65.58473297],
            [-7.3808786, -64.20548639],
            [-14.18765939, -64.20264097],
            [-14.19755558, -65.58202739],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK423",
      },
    },
    {
      type: "Feature",
      id: "sm20d9b639",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.18765939, -64.20264097],
            [-7.3808786, -64.20548639],
            [-7.3777963, -62.77359297],
            [-14.17789386, -62.77060426],
            [-14.18765939, -64.20264097],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK424",
      },
    },
    {
      type: "Feature",
      id: "sm5aba929d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.17789386, -62.77060426],
            [-7.3777963, -62.77359297],
            [-7.3745461, -61.18447808],
            [-14.16759643, -61.18133269],
            [-14.17789386, -62.77060426],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK425",
      },
    },
    {
      type: "Feature",
      id: "sm7cefca40",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.16759643, -61.18133269],
            [-7.3745461, -61.18447808],
            [-7.3712952, -59.51062051],
            [-14.15729675, -59.50731288],
            [-14.16759643, -61.18133269],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK426",
      },
    },
    {
      type: "Feature",
      id: "smf1a7c0a1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.15729675, -59.50731288],
            [-7.3712952, -59.51062051],
            [-7.36819222, -57.8314545],
            [-14.1474657, -57.82798712],
            [-14.15729675, -59.50731288],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK427",
      },
    },
    {
      type: "Feature",
      id: "sm278c0abe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.1474657, -57.82798712],
            [-7.36819222, -57.8314545],
            [-7.3650476, -56.04603455],
            [-14.13750276, -56.04240076],
            [-14.1474657, -57.82798712],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK428",
      },
    },
    {
      type: "Feature",
      id: "sm6c04054c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.13750276, -56.04240076],
            [-7.3650476, -56.04603455],
            [-7.36196696, -54.21294598],
            [-14.1277425, -54.20914518],
            [-14.13750276, -56.04240076],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK429",
      },
    },
    {
      type: "Feature",
      id: "sm49bfb5c7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.1277425, -54.20914518],
            [-7.36196696, -54.21294598],
            [-7.35871657, -52.18652497],
            [-14.11744443, -52.18254426],
            [-14.1277425, -54.20914518],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK430",
      },
    },
    {
      type: "Feature",
      id: "smf9bf9b88",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.11744443, -52.18254426],
            [-7.35871657, -52.18652497],
            [-7.35542315, -50.03459303],
            [-14.10701004, -50.03042694],
            [-14.11744443, -52.18254426],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK431",
      },
    },
    {
      type: "Feature",
      id: "smf2956176",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.10701004, -50.03042694],
            [-7.35542315, -50.03459303],
            [-7.3522998, -47.90054243],
            [-14.09711449, -47.89619848],
            [-14.10701004, -50.03042694],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK432",
      },
    },
    {
      type: "Feature",
      id: "smeabb2b56",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.09711449, -47.89619848],
            [-7.3522998, -47.90054243],
            [-7.34917496, -45.67365999],
            [-14.08721419, -45.66913708],
            [-14.09711449, -47.89619848],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK433",
      },
    },
    {
      type: "Feature",
      id: "smcf484729",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.08721419, -45.66913708],
            [-7.34917496, -45.67365999],
            [-7.34600985, -43.32401979],
            [-14.07718631, -43.31931564],
            [-14.08721419, -45.66913708],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK434",
      },
    },
    {
      type: "Feature",
      id: "sm8b363f69",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.07718631, -43.31931564],
            [-7.34600985, -43.32401979],
            [-7.34297016, -40.97850744],
            [-14.06755581, -40.97363045],
            [-14.07718631, -43.31931564],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK435",
      },
    },
    {
      type: "Feature",
      id: "smb42d9810",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.06755581, -40.97363045],
            [-7.34297016, -40.97850744],
            [-7.33988903, -38.51277867],
            [-14.057794, -38.50772897],
            [-14.06755581, -40.97363045],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK436",
      },
    },
    {
      type: "Feature",
      id: "sm89d0e976",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.62738252, -65.56842591],
            [-7.38395803, -65.56574127],
            [-7.38699022, -66.83911789],
            [-1.56965576, -66.83482475],
            [-0.63581787, -66.44715094],
            [-0.62738252, -65.56842591],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK421",
      },
    },
    {
      type: "Feature",
      id: "sm745ec319",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.38395803, -65.56574127],
            [-0.62738252, -65.56842591],
            [-0.61520617, -64.24563041],
            [-7.38096104, -64.24280624],
            [-7.38395803, -65.56574127],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK420",
      },
    },
    {
      type: "Feature",
      id: "smd6cf7eca",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.38096104, -64.24280624],
            [-0.61520617, -64.24563041],
            [-0.60216534, -62.75512705],
            [-7.37775128, -62.75214724],
            [-7.38096104, -64.24280624],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK419",
      },
    },
    {
      type: "Feature",
      id: "sm362350f4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.37775128, -62.75214724],
            [-0.60216534, -62.75512705],
            [-0.5894705, -61.22801905],
            [-7.37462668, -61.22488165],
            [-7.37775128, -62.75214724],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK418",
      },
    },
    {
      type: "Feature",
      id: "sm98c8fd70",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.37462668, -61.22488165],
            [-0.5894705, -61.22801905],
            [-0.57618788, -59.54676938],
            [-7.37135741, -59.54346083],
            [-7.37462668, -61.22488165],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK417",
      },
    },
    {
      type: "Feature",
      id: "smbfe8a17d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.37135741, -59.54346083],
            [-0.57618788, -59.54676938],
            [-0.56323157, -57.8217629],
            [-7.36816845, -57.81828146],
            [-7.37135741, -59.54346083],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK416",
      },
    },
    {
      type: "Feature",
      id: "sm7b180ad9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.36816845, -57.81828146],
            [-0.56323157, -57.8217629],
            [-0.55062702, -56.06042774],
            [-7.36506608, -56.05677279],
            [-7.36816845, -57.81828146],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK415",
      },
    },
    {
      type: "Feature",
      id: "sm4bdb08af",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.36506608, -56.05677279],
            [-0.55062702, -56.06042774],
            [-0.53819274, -54.24029585],
            [-7.36200561, -54.23646498],
            [-7.36506608, -56.05677279],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK414",
      },
    },
    {
      type: "Feature",
      id: "sma0544fa4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.36200561, -54.23646498],
            [-0.53819274, -54.24029585],
            [-0.52481893, -52.18884153],
            [-7.35871389, -52.18481676],
            [-7.36200561, -54.23646498],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK413",
      },
    },
    {
      type: "Feature",
      id: "sm19d32fb5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.35871389, -52.18481676],
            [-0.52481893, -52.18884153],
            [-0.51178502, -50.09406724],
            [-7.35550583, -50.08984953],
            [-7.35871389, -52.18481676],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK412",
      },
    },
    {
      type: "Feature",
      id: "sm11eb57c3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.35550583, -50.08984953],
            [-0.51178502, -50.09406724],
            [-0.49909347, -47.96231442],
            [-7.35238204, -47.95790588],
            [-7.35550583, -50.08984953],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK411",
      },
    },
    {
      type: "Feature",
      id: "sma2fdc116",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.35238204, -47.95790588],
            [-0.49909347, -47.96231442],
            [-0.48622893, -45.70787124],
            [-7.34921567, -45.70326727],
            [-7.35238204, -47.95790588],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK410",
      },
    },
    {
      type: "Feature",
      id: "sm9dc011a4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.34921567, -45.70326727],
            [-0.48622893, -45.70787124],
            [-0.47353573, -43.39060608],
            [-7.34609148, -43.38580838],
            [-7.34921567, -45.70326727],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK409",
      },
    },
    {
      type: "Feature",
      id: "smca9bbbb3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.34609148, -43.38580838],
            [-0.47353573, -43.39060608],
            [-0.46066922, -40.94770134],
            [-7.34292462, -40.94270766],
            [-7.34609148, -43.38580838],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK408",
      },
    },
    {
      type: "Feature",
      id: "smc32213ff",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.34292462, -40.94270766],
            [-0.46066922, -40.94770134],
            [-0.448495, -38.54982452],
            [-7.33992816, -38.54464704],
            [-7.34292462, -40.94270766],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK407",
      },
    },
    {
      type: "Feature",
      id: "sm53016a10",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.33992816, -38.54464704],
            [-0.448495, -38.54982452],
            [-0.43806396, -36.42942572],
            [-1.55866943, -35.59408162],
            [-7.33636975, -35.58956833],
            [-7.33992816, -38.54464704],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK406",
      },
    },
    {
      type: "Feature",
      id: "sm19cf7d4b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.56940459, -40.95711138],
            [-16.03505778, -40.98802376],
            [-16.01667724, -36.2435749],
            [-17.0933374, -35.45996473],
            [-28.3433374, -35.47785993],
            [-29.55183349, -36.4382647],
            [-29.56940459, -40.95711138],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK438",
      },
    },
    {
      type: "Feature",
      id: "smc0b2c94a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.57929889, -43.37466307],
            [-22.99327494, -43.38914305],
            [-22.9834063, -40.97215557],
            [-29.56940459, -40.95711138],
            [-29.57929889, -43.37466307],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK466",
      },
    },
    {
      type: "Feature",
      id: "sme56780bb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.11278177, -65.6033787],
            [-29.69911639, -65.59514774],
            [-29.70564209, -66.46909169],
            [-28.89265381, -66.85210601],
            [-23.12208958, -66.84361645],
            [-23.11278177, -65.6033787],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK453",
      },
    },
    {
      type: "Feature",
      id: "smabbd5ba6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.69911639, -65.59514774],
            [-23.11278177, -65.6033787],
            [-23.10268224, -64.18725695],
            [-29.68899061, -64.17858005],
            [-29.69911639, -65.59514774],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK454",
      },
    },
    {
      type: "Feature",
      id: "sm59d6cfa5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.68899061, -64.17858005],
            [-23.10268224, -64.18725695],
            [-23.09244253, -62.67361706],
            [-29.67872428, -62.66446939],
            [-29.68899061, -64.17858005],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK455",
      },
    },
    {
      type: "Feature",
      id: "sm634197ef",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.67872428, -62.66446939],
            [-23.09244253, -62.67361706],
            [-23.08260686, -61.14295294],
            [-29.66886304, -61.1333357],
            [-29.67872428, -62.66446939],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK456",
      },
    },
    {
      type: "Feature",
      id: "smd5443b05",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.66886304, -61.1333357],
            [-23.08260686, -61.14295294],
            [-23.07264871, -59.51377125],
            [-29.658879, -59.50366178],
            [-29.66886304, -61.1333357],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK457",
      },
    },
    {
      type: "Feature",
      id: "smf1755785",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.658879, -59.50366178],
            [-23.07264871, -59.51377125],
            [-23.06268453, -57.8008521],
            [-29.64888891, -57.79023393],
            [-29.658879, -59.50366178],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK458",
      },
    },
    {
      type: "Feature",
      id: "sm3ae90740",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.64888891, -57.79023393],
            [-23.06268453, -57.8008521],
            [-23.05350831, -56.14793866],
            [-29.63968884, -56.1368386],
            [-29.64888891, -57.79023393],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK459",
      },
    },
    {
      type: "Feature",
      id: "sm4d4055f8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.63968884, -56.1368386],
            [-23.05350831, -56.14793866],
            [-23.04365454, -54.29022104],
            [-29.62980946, -54.27859042],
            [-29.63968884, -56.1368386],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK460",
      },
    },
    {
      type: "Feature",
      id: "sm5e9c872c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.62980946, -54.27859042],
            [-23.04365454, -54.29022104],
            [-23.03355984, -52.29607612],
            [-29.61968851, -52.28388963],
            [-29.62980946, -54.27859042],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK461",
      },
    },
    {
      type: "Feature",
      id: "sm7338ca4e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.61968851, -52.28388963],
            [-23.03355984, -52.29607612],
            [-23.02279764, -50.06649241],
            [-29.60889833, -50.05370193],
            [-29.61968851, -52.28388963],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK462",
      },
    },
    {
      type: "Feature",
      id: "smb5f76c9c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.60889833, -50.05370193],
            [-23.02279764, -50.06649241],
            [-23.01295955, -47.93330952],
            [-29.59903467, -47.91995928],
            [-29.60889833, -50.05370193],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK463",
      },
    },
    {
      type: "Feature",
      id: "sm82ec0395",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.59903467, -47.91995928],
            [-23.01295955, -47.93330952],
            [-23.00324596, -45.73708275],
            [-29.58929583, -45.72317557],
            [-29.59903467, -47.91995928],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK464",
      },
    },
    {
      type: "Feature",
      id: "sm35c7f44b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.58929583, -45.72317557],
            [-23.00324596, -45.73708275],
            [-22.99327494, -43.38914305],
            [-29.57929889, -43.37466307],
            [-29.58929583, -45.72317557],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK465",
      },
    },
    {
      type: "Feature",
      id: "sm5cfd33cd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.16400872, -65.55404531],
            [-23.11235723, -65.54534538],
            [-23.12208958, -66.84361645],
            [-17.14826904, -66.83482476],
            [-16.17048583, -66.42519089],
            [-16.16400872, -65.55404531],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK452",
      },
    },
    {
      type: "Feature",
      id: "sm6032b657",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.11235723, -65.54534538],
            [-16.16400872, -65.55404531],
            [-16.15416983, -64.1728135],
            [-23.10251834, -64.1636548],
            [-23.11235723, -65.54534538],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK451",
      },
    },
    {
      type: "Feature",
      id: "sm0cd36963",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.10251834, -64.1636548],
            [-16.15416983, -64.1728135],
            [-16.14447554, -62.74108488],
            [-23.09282405, -62.73145626],
            [-23.10251834, -64.1636548],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK450",
      },
    },
    {
      type: "Feature",
      id: "sm9aeb0024",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.09282405, -62.73145626],
            [-16.14447554, -62.74108488],
            [-16.13436998, -61.17089353],
            [-23.08271848, -61.16075647],
            [-23.09282405, -62.73145626],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK449",
      },
    },
    {
      type: "Feature",
      id: "smce2014c6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.08271848, -61.16075647],
            [-16.13436998, -61.17089353],
            [-16.12453471, -59.56372663],
            [-23.07288322, -59.55307705],
            [-23.08271848, -61.16075647],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK448",
      },
    },
    {
      type: "Feature",
      id: "sm7e3977f6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.07288322, -59.55307705],
            [-16.12453471, -59.56372663],
            [-16.1144211, -57.82703003],
            [-23.06276961, -57.81583605],
            [-23.07288322, -59.55307705],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK447",
      },
    },
    {
      type: "Feature",
      id: "sm947e2eff",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.06276961, -57.81583605],
            [-16.1144211, -57.82703003],
            [-16.10539672, -56.2032349],
            [-23.05374523, -56.19154121],
            [-23.06276961, -57.81583605],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK446",
      },
    },
    {
      type: "Feature",
      id: "sm21701403",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.05374523, -56.19154121],
            [-16.10539672, -56.2032349],
            [-16.09554295, -54.3481616],
            [-23.04389146, -54.33590855],
            [-23.05374523, -56.19154121],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK445",
      },
    },
    {
      type: "Feature",
      id: "sm29b059c9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.04389146, -54.33590855],
            [-16.09554295, -54.3481616],
            [-16.08530244, -52.32734058],
            [-23.03365095, -52.31449284],
            [-23.04389146, -54.33590855],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK444",
      },
    },
    {
      type: "Feature",
      id: "sm046e912d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.03365095, -52.31449284],
            [-16.08530244, -52.32734058],
            [-16.07455512, -50.10246205],
            [-23.02290363, -50.08897809],
            [-23.03365095, -52.31449284],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK443",
      },
    },
    {
      type: "Feature",
      id: "sm3e0100ff",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.02290363, -50.08897809],
            [-16.07455512, -50.10246205],
            [-16.06458209, -47.94097892],
            [-23.0129306, -47.92689633],
            [-23.02290363, -50.08897809],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK442",
      },
    },
    {
      type: "Feature",
      id: "sm863ba0b5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.0129306, -47.92689633],
            [-16.06458209, -47.94097892],
            [-16.05487453, -45.74646372],
            [-23.00322304, -45.73179387],
            [-23.0129306, -47.92689633],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK441",
      },
    },
    {
      type: "Feature",
      id: "smb9084188",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.00322304, -45.73179387],
            [-16.05487453, -45.74646372],
            [-16.04491517, -43.40171155],
            [-22.99326368, -43.38643804],
            [-23.00322304, -45.73179387],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK440",
      },
    },
    {
      type: "Feature",
      id: "sm012cd01b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-22.99326368, -43.38643804],
            [-16.04491517, -43.40171155],
            [-16.03505778, -40.98802376],
            [-22.9834063, -40.97215557],
            [-22.99326368, -43.38643804],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK439",
      },
    },
    {
      type: "Feature",
      id: "sm583b4556",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.37669944, -39.12473892],
            [-38.03936529, -39.08362901],
            [-38.07321261, -35.8712995],
            [-43.21882568, -35.90614802],
            [-44.3614038, -36.8261817],
            [-44.37669944, -39.12473892],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK496",
      },
    },
    {
      type: "Feature",
      id: "sm938773b5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.66390772, -65.47074307],
            [-44.61228977, -65.49484627],
            [-44.62507567, -66.48663041],
            [-43.37263427, -66.86074207],
            [-37.63504599, -66.86539046],
            [-37.66390772, -65.47074307],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK482",
      },
    },
    {
      type: "Feature",
      id: "smb11a99f5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.61228977, -65.49484627],
            [-37.66390772, -65.47074307],
            [-37.69187725, -64.04427091],
            [-44.59473958, -64.06951524],
            [-44.61228977, -65.49484627],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK483",
      },
    },
    {
      type: "Feature",
      id: "smb51e8446",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.59473958, -64.06951524],
            [-37.69187725, -64.04427091],
            [-37.7224304, -62.39805622],
            [-44.57556824, -62.42458772],
            [-44.59473958, -64.06951524],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK484",
      },
    },
    {
      type: "Feature",
      id: "smee6d1d16",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.57556824, -62.42458772],
            [-37.7224304, -62.39805622],
            [-37.7514098, -60.74824541],
            [-44.55738438, -60.77603463],
            [-44.57556824, -62.42458772],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK485",
      },
    },
    {
      type: "Feature",
      id: "smf93c5999",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.55738438, -60.77603463],
            [-37.7514098, -60.74824541],
            [-37.78042299, -59.00694216],
            [-44.53917932, -59.0360234],
            [-44.55738438, -60.77603463],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK486",
      },
    },
    {
      type: "Feature",
      id: "smd81ebafa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.53917932, -59.0360234],
            [-37.78042299, -59.00694216],
            [-37.80978815, -57.15002015],
            [-44.52075341, -57.1804386],
            [-44.53917932, -59.0360234],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK487",
      },
    },
    {
      type: "Feature",
      id: "sm12c5e547",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.52075341, -57.1804386],
            [-37.80978815, -57.15002015],
            [-37.8384033, -55.24615566],
            [-44.50279812, -55.27790097],
            [-44.52075341, -57.1804386],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK488",
      },
    },
    {
      type: "Feature",
      id: "sm4007dc52",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.50279812, -55.27790097],
            [-37.8384033, -55.24615566],
            [-37.86849084, -53.14100926],
            [-44.48391893, -53.17416886],
            [-44.50279812, -55.27790097],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK489",
      },
    },
    {
      type: "Feature",
      id: "sm4c3958ab",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.48391893, -53.17416886],
            [-37.86849084, -53.14100926],
            [-37.89567965, -51.14544263],
            [-44.46685863, -51.17989079],
            [-44.48391893, -53.17416886],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK490",
      },
    },
    {
      type: "Feature",
      id: "sm03e8c1a6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.46685863, -51.17989079],
            [-37.89567965, -51.14544263],
            [-37.92325728, -49.02922167],
            [-44.44955435, -49.06497995],
            [-44.46685863, -51.17989079],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK491",
      },
    },
    {
      type: "Feature",
      id: "sm7332ce1d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.44955435, -49.06497995],
            [-37.92325728, -49.02922167],
            [-37.95377664, -46.57765548],
            [-44.4304042, -46.61485772],
            [-44.44955435, -49.06497995],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK492",
      },
    },
    {
      type: "Feature",
      id: "smb2d35201",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.4304042, -46.61485772],
            [-37.95377664, -46.57765548],
            [-37.98130207, -44.26704955],
            [-44.41313268, -44.3055389],
            [-44.4304042, -46.61485772],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK493",
      },
    },
    {
      type: "Feature",
      id: "sm4fcc7789",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.41313268, -44.3055389],
            [-37.98130207, -44.26704955],
            [-38.01180301, -41.5962718],
            [-44.39399409, -41.63615803],
            [-44.41313268, -44.3055389],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK494",
      },
    },
    {
      type: "Feature",
      id: "sm9bbe9c79",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.39399409, -41.63615803],
            [-38.01180301, -41.5962718],
            [-38.03936529, -39.08362901],
            [-44.37669944, -39.12473892],
            [-44.39399409, -41.63615803],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK495",
      },
    },
    {
      type: "Feature",
      id: "sm5ce4968c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-31.65470616, -65.47484262],
            [-37.66340528, -65.49568419],
            [-37.63504599, -66.86539046],
            [-32.71589599, -66.86937508],
            [-31.66120848, -66.48663041],
            [-31.65470616, -65.47484262],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK481",
      },
    },
    {
      type: "Feature",
      id: "sm69b3d15f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.66340528, -65.49568419],
            [-31.65470616, -65.47484262],
            [-31.6459667, -64.05054834],
            [-37.69133489, -64.07265301],
            [-37.66340528, -65.49568419],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK480",
      },
    },
    {
      type: "Feature",
      id: "sm2c83b21f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.69133489, -64.07265301],
            [-31.6459667, -64.05054834],
            [-31.63635767, -62.39592992],
            [-37.72204349, -62.41949313],
            [-37.69133489, -64.07265301],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK479",
      },
    },
    {
      type: "Feature",
      id: "sm1e777184",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.72204349, -62.41949313],
            [-31.63635767, -62.39592992],
            [-31.62757497, -60.79910018],
            [-37.75011128, -60.82406033],
            [-37.72204349, -62.41949313],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK478",
      },
    },
    {
      type: "Feature",
      id: "smac65b708",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.75011128, -60.82406033],
            [-31.62757497, -60.79910018],
            [-31.61803956, -58.97044156],
            [-37.78058458, -58.99698657],
            [-37.75011128, -60.82406033],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK477",
      },
    },
    {
      type: "Feature",
      id: "sm31ae67f1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.78058458, -58.99698657],
            [-31.61803956, -58.97044156],
            [-31.60917844, -57.17933154],
            [-37.80890302, -57.20741132],
            [-37.78058458, -58.99698657],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK476",
      },
    },
    {
      type: "Feature",
      id: "sm21d3dff9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.80890302, -57.20741132],
            [-31.60917844, -57.17933154],
            [-31.60011625, -55.25320585],
            [-37.837864, -55.28291435],
            [-37.80890302, -57.20741132],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK475",
      },
    },
    {
      type: "Feature",
      id: "smd4a60eb4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.837864, -55.28291435],
            [-31.60011625, -55.25320585],
            [-31.59104446, -53.22678722],
            [-37.86685569, -53.25818226],
            [-37.837864, -55.28291435],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK474",
      },
    },
    {
      type: "Feature",
      id: "sm0acc682d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.86685569, -53.25818226],
            [-31.59104446, -53.22678722],
            [-31.58229635, -51.17737012],
            [-37.89481294, -51.21043988],
            [-37.86685569, -53.25818226],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK473",
      },
    },
    {
      type: "Feature",
      id: "sm40f02578",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.89481294, -51.21043988],
            [-31.58229635, -51.17737012],
            [-31.57336064, -48.98557648],
            [-37.92336974, -49.02039972],
            [-37.89481294, -51.21043988],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK472",
      },
    },
    {
      type: "Feature",
      id: "smc0b5ccdc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.92336974, -49.02039972],
            [-31.57336064, -48.98557648],
            [-31.56389213, -46.5530539],
            [-37.95362925, -46.58977385],
            [-37.92336974, -49.02039972],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK471",
      },
    },
    {
      type: "Feature",
      id: "sm4f6e4116",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.95362925, -46.58977385],
            [-31.56389213, -46.5530539],
            [-31.55503389, -44.1739874],
            [-37.98193847, -44.21250814],
            [-37.95362925, -46.58977385],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK470",
      },
    },
    {
      type: "Feature",
      id: "sma74a4cf0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.98193847, -44.21250814],
            [-31.55503389, -44.1739874],
            [-31.54595441, -41.63173361],
            [-38.01095473, -41.67211499],
            [-37.98193847, -44.21250814],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK469",
      },
    },
    {
      type: "Feature",
      id: "sm5a841c1e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.01095473, -41.67211499],
            [-31.54595441, -41.63173361],
            [-31.53698314, -39.01727383],
            [-38.03962515, -39.05949543],
            [-38.01095473, -41.67211499],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK468",
      },
    },
    {
      type: "Feature",
      id: "smd88df380",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.03962515, -39.05949543],
            [-31.53698314, -39.01727383],
            [-31.52937254, -36.72057988],
            [-32.70490965, -35.83492647],
            [-38.07321261, -35.8712995],
            [-38.03962515, -39.05949543],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK467",
      },
    },
    {
      type: "Feature",
      id: "smb30c2c4c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.34517093, 13.98548647],
            [-38.34243099, 10.10533398],
            [-31.45287616, 10.11001704],
            [-31.44422845, 12.95990299],
            [-32.63075189, 13.99621161],
            [-38.34517093, 13.98548647],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK346",
      },
    },
    {
      type: "Feature",
      id: "sm708e2c8f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.34243099, 10.10533398],
            [-38.34517093, 13.98548647],
            [-43.99061517, 13.9748903],
            [-45.15516595, 12.79925425],
            [-45.15326472, 10.10070437],
            [-38.34243099, 10.10533398],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK375",
      },
    },
    {
      type: "Feature",
      id: "sm5d7c78d7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.35290716, -33.79407491],
            [-38.33165274, -31.15871293],
            [-45.12359132, -31.16272582],
            [-45.12220697, -32.86271213],
            [-43.94666986, -33.76244559],
            [-38.35290716, -33.79407491],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK361",
      },
    },
    {
      type: "Feature",
      id: "sm11326054",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.33165274, -31.15871293],
            [-38.35290716, -33.79407491],
            [-32.64173822, -33.82635604],
            [-31.58705072, -32.98259782],
            [-31.58063791, -31.15472406],
            [-38.33165274, -31.15871293],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK360",
      },
    },
    {
      type: "Feature",
      id: "sm76a8b993",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.52051911, -28.49059337],
            [-38.51839526, -31.15882327],
            [-31.58063791, -31.15472406],
            [-31.57149345, -28.48637639],
            [-38.52051911, -28.49059337],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK359",
      },
    },
    {
      type: "Feature",
      id: "smf86223eb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.51839526, -31.15882327],
            [-38.52051911, -28.49059337],
            [-45.12571517, -28.49460154],
            [-45.12359132, -31.16272582],
            [-38.51839526, -31.15882327],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK362",
      },
    },
    {
      type: "Feature",
      id: "sm5cd41e2f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.60907714, -25.43617759],
            [-38.60671055, -28.49064568],
            [-31.57149345, -28.48637639],
            [-31.56130386, -25.43178308],
            [-38.60907714, -25.43617759],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK358",
      },
    },
    {
      type: "Feature",
      id: "smf95734f5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.60671055, -28.49064568],
            [-38.60907714, -25.43617759],
            [-45.12808176, -25.44024225],
            [-45.12571517, -28.49460154],
            [-38.60671055, -28.49064568],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK363",
      },
    },
    {
      type: "Feature",
      id: "sm0eff021a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.69684656, -22.38322266],
            [-38.69454036, -25.43623088],
            [-31.56130386, -25.43178308],
            [-31.55137429, -22.37866071],
            [-38.69684656, -22.38322266],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK357",
      },
    },
    {
      type: "Feature",
      id: "sm69550bbc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.69454036, -25.43623088],
            [-38.69684656, -22.38322266],
            [-45.13038796, -22.38732996],
            [-45.12808176, -25.44024225],
            [-38.69454036, -25.43623088],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK364",
      },
    },
    {
      type: "Feature",
      id: "smae7d4192",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43244661, -19.4277018],
            [-38.43026198, -22.38305246],
            [-31.55137429, -22.37866071],
            [-31.54196819, -19.4232151],
            [-38.43244661, -19.4277018],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK356",
      },
    },
    {
      type: "Feature",
      id: "sm0848612e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43026198, -22.38305246],
            [-38.43244661, -19.4277018],
            [-45.13257258, -19.43206443],
            [-45.13038796, -22.38732996],
            [-38.43026198, -22.38305246],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK365",
      },
    },
    {
      type: "Feature",
      id: "smdee91c37",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43238554, -16.33379977],
            [-38.43014061, -19.4277003],
            [-31.54196819, -19.4232151],
            [-31.53230243, -16.32922784],
            [-38.43238554, -16.33379977],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK355",
      },
    },
    {
      type: "Feature",
      id: "smfc33bb62",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43014061, -19.4277003],
            [-38.43238554, -16.33379977],
            [-45.13481751, -16.33824063],
            [-45.13257258, -19.43206443],
            [-38.43014061, -19.4277003],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK366",
      },
    },
    {
      type: "Feature",
      id: "sm2070672d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43244627, -13.10398436],
            [-38.43014024, -16.33379828],
            [-31.53230243, -16.32922784],
            [-31.52237358, -13.0993375],
            [-38.43244627, -13.10398436],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK354",
      },
    },
    {
      type: "Feature",
      id: "sm51560b63",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43014024, -16.33379828],
            [-38.43244627, -13.10398436],
            [-45.13712354, -13.10849301],
            [-45.13481751, -16.33824063],
            [-38.43014024, -16.33379828],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK367",
      },
    },
    {
      type: "Feature",
      id: "smdc971bb4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43226409, -9.65822941],
            [-38.42983686, -13.10398261],
            [-31.52237358, -13.0993375],
            [-31.51192289, -9.65351896],
            [-38.43226409, -9.65822941],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK353",
      },
    },
    {
      type: "Feature",
      id: "smf342a279",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.42983686, -13.10398261],
            [-38.43226409, -9.65822941],
            [-45.13955077, -9.66279478],
            [-45.13712354, -13.10849301],
            [-38.42983686, -13.10398261],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK368",
      },
    },
    {
      type: "Feature",
      id: "sm69249448",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43232511, -6.43826645],
            [-38.43007947, -9.65822792],
            [-31.51192289, -9.65351896],
            [-31.50225407, -6.43351175],
            [-38.43232511, -6.43826645],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK352",
      },
    },
    {
      type: "Feature",
      id: "sme1f40ddc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.43007947, -9.65822792],
            [-38.43232511, -6.43826645],
            [-45.14179642, -6.44286976],
            [-45.13955077, -9.66279478],
            [-38.43007947, -9.65822792],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK369",
      },
    },
    {
      type: "Feature",
      id: "smaa0fdf7e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.3453447, -3.02278219],
            [-38.34297811, -6.43820515],
            [-31.50225407, -6.43351175],
            [-31.49206448, -3.01805693],
            [-38.3453447, -3.02278219],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK351",
      },
    },
    {
      type: "Feature",
      id: "smf0ffc6de",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.34297811, -6.43820515],
            [-38.3453447, -3.02278219],
            [-45.14416301, -3.02746989],
            [-45.14179642, -6.44286976],
            [-38.34297811, -6.43820515],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK370",
      },
    },
    {
      type: "Feature",
      id: "smeb9e0564",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.34491996, 0.22782809],
            [-38.34267461, -3.02278035],
            [-31.49206448, -3.01805693],
            [-31.48239691, 0.23256627],
            [-38.34491996, 0.22782809],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK350",
      },
    },
    {
      type: "Feature",
      id: "smf00d6836",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.34267461, -3.02278035],
            [-38.34491996, 0.22782809],
            [-45.14640835, 0.22313205],
            [-45.14416301, -3.02746989],
            [-38.34267461, -3.02278035],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK371",
      },
    },
    {
      type: "Feature",
      id: "sm8d5821b2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.34570814, 3.73976325],
            [-38.34328149, 0.22782922],
            [-31.48239691, 0.23256627],
            [-31.47194875, 3.74449911],
            [-38.34570814, 3.73976325],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK349",
      },
    },
    {
      type: "Feature",
      id: "sm8b57da99",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.34328149, 0.22782922],
            [-38.34570814, 3.73976325],
            [-45.148835, 3.73507603],
            [-45.14640835, 0.22313205],
            [-38.34328149, 0.22782922],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK372",
      },
    },
    {
      type: "Feature",
      id: "sm30efdb6c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.25733212, 7.15163461],
            [-38.25496541, 3.73982577],
            [-31.47194875, 3.74449911],
            [-31.46175863, 7.15629008],
            [-38.25733212, 7.15163461],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK348",
      },
    },
    {
      type: "Feature",
      id: "sm4a26e964",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.25496541, 3.73982577],
            [-38.25733212, 7.15163461],
            [-45.15120171, 7.14691176],
            [-45.148835, 3.73507603],
            [-38.25496541, 3.73982577],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK373",
      },
    },
    {
      type: "Feature",
      id: "sm1541439f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.25708914, 10.10539199],
            [-38.25502613, 7.15163619],
            [-31.46175863, 7.15629008],
            [-31.45287616, 10.11001704],
            [-38.25708914, 10.10539199],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK347",
      },
    },
    {
      type: "Feature",
      id: "sma7122598",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-38.25502613, 7.15163619],
            [-38.25708914, 10.10539199],
            [-45.15326472, 10.10070437],
            [-45.15120171, 7.14691176],
            [-38.25502613, 7.15163619],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK374",
      },
    },
    {
      type: "Feature",
      id: "smd61ecc7b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.11552669, -1.38659845],
            [-7.60413491, -1.46598106],
            [-7.55680793, 2.41395668],
            [-12.99818354, 2.4802544],
            [-14.09681635, 1.38226247],
            [-14.11552669, -1.38659845],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK314",
      },
    },
    {
      type: "Feature",
      id: "smf1c3365b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.97888877, -30.62418218],
            [-14.32317436, -30.55758088],
            [-14.33851557, -32.49281666],
            [-13.23988276, -33.34127824],
            [-8.01821011, -33.3583834],
            [-7.97888877, -30.62418218],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK304",
      },
    },
    {
      type: "Feature",
      id: "smabb24b49",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.32317436, -30.55758088],
            [-7.97888877, -30.62418218],
            [-7.93593019, -27.54638806],
            [-14.2993714, -27.47756091],
            [-14.32317436, -30.55758088],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK305",
      },
    },
    {
      type: "Feature",
      id: "sm09c0707b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.2993714, -27.47756091],
            [-7.93593019, -27.54638806],
            [-7.89511496, -24.53987537],
            [-14.27675606, -24.46906026],
            [-14.2993714, -27.47756091],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK306",
      },
    },
    {
      type: "Feature",
      id: "sm81f59602",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.27675606, -24.46906026],
            [-7.89511496, -24.53987537],
            [-7.85335874, -21.38775399],
            [-14.25361933, -21.31505914],
            [-14.27675606, -24.46906026],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK307",
      },
    },
    {
      type: "Feature",
      id: "smb1236213",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.25361933, -21.31505914],
            [-7.85335874, -21.38775399],
            [-7.81049164, -18.0796644],
            [-14.22986706, -18.00522883],
            [-14.25361933, -21.31505914],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK308",
      },
    },
    {
      type: "Feature",
      id: "sm96e4d5ed",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.22986706, -18.00522883],
            [-7.81049164, -18.0796644],
            [-7.76879904, -14.80117491],
            [-14.20700213, -14.75911292],
            [-14.22986706, -18.00522883],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK309",
      },
    },
    {
      type: "Feature",
      id: "sm63a5c458",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.20700213, -14.75911292],
            [-7.76879904, -14.80117491],
            [-7.72886118, -11.61334881],
            [-14.18463637, -11.53622091],
            [-14.20700213, -14.75911292],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK310",
      },
    },
    {
      type: "Feature",
      id: "smbec8dec5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.18463637, -11.53622091],
            [-7.72886118, -11.61334881],
            [-7.68754932, -8.277181],
            [-14.16174585, -8.1990421],
            [-14.18463637, -11.53622091],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK311",
      },
    },
    {
      type: "Feature",
      id: "sme7e9f592",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.16174585, -8.1990421],
            [-7.68754932, -8.277181],
            [-7.64482598, -4.79719425],
            [-14.13807324, -4.71828116],
            [-14.16174585, -8.1990421],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK312",
      },
    },
    {
      type: "Feature",
      id: "sm1444583e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-14.13807324, -4.71828116],
            [-7.64482598, -4.79719425],
            [-7.60413491, -1.46598106],
            [-14.11552669, -1.38659845],
            [-14.13807324, -4.71828116],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK313",
      },
    },
    {
      type: "Feature",
      id: "smac2b7d54",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.983279, -30.66161705],
            [-7.97837907, -30.5882094],
            [-8.01821011, -33.3583834],
            [-2.03382807, -33.37798292],
            [-0.97914057, -32.56691822],
            [-0.983279, -30.66161705],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK303",
      },
    },
    {
      type: "Feature",
      id: "sm7ee3a62c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.97837907, -30.5882094],
            [-0.983279, -30.66161705],
            [-0.98977757, -27.59297455],
            [-7.93553811, -27.51787871],
            [-7.97837907, -30.5882094],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK302",
      },
    },
    {
      type: "Feature",
      id: "sm60f4e422",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.93553811, -27.51787871],
            [-0.98977757, -27.59297455],
            [-0.9959986, -24.57253101],
            [-7.89452684, -24.49599831],
            [-7.93553811, -27.51787871],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK301",
      },
    },
    {
      type: "Feature",
      id: "sma77a6a4c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.89452684, -24.49599831],
            [-0.9959986, -24.57253101],
            [-1.00232469, -21.42462586],
            [-7.8528229, -21.3468354],
            [-7.89452684, -24.49599831],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK300",
      },
    },
    {
      type: "Feature",
      id: "sm0bf919ba",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.8528229, -21.3468354],
            [-1.00232469, -21.42462586],
            [-1.00884903, -18.1055303],
            [-7.80981204, -18.02668066],
            [-7.8528229, -21.3468354],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK299",
      },
    },
    {
      type: "Feature",
      id: "sm59b53ddd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.80981204, -18.02668066],
            [-1.00884903, -18.1055303],
            [-1.01512781, -14.85074689],
            [-7.76957674, -14.86282207],
            [-7.80981204, -18.02668066],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK298",
      },
    },
    {
      type: "Feature",
      id: "smb35bd03b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.76957674, -14.86282207],
            [-1.01512781, -14.85074689],
            [-1.02090251, -11.81309451],
            [-7.73035108, -11.73299324],
            [-7.76957674, -14.86282207],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK297",
      },
    },
    {
      type: "Feature",
      id: "sm0d9e4b0e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.73035108, -11.73299324],
            [-1.02090251, -11.81309451],
            [-1.02741496, -8.34694317],
            [-7.68741863, -8.26657571],
            [-7.73035108, -11.73299324],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK296",
      },
    },
    {
      type: "Feature",
      id: "sm412d9d95",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.68741863, -8.26657571],
            [-1.02741496, -8.34694317],
            [-1.03383423, -4.89995561],
            [-7.64510044, -4.81962027],
            [-7.68741863, -8.26657571],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK295",
      },
    },
    {
      type: "Feature",
      id: "sm630ea712",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.64510044, -4.81962027],
            [-1.03383423, -4.89995561],
            [-1.04003642, -1.55243205],
            [-7.60421333, -1.47240901],
            [-7.64510044, -4.81962027],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK294",
      },
    },
    {
      type: "Feature",
      id: "sm7ea0c9c2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.60421333, -1.47240901],
            [-1.04003642, -1.55243205],
            [-1.04505854, 1.16259022],
            [-2.18763667, 2.34853552],
            [-7.55680793, 2.41395668],
            [-7.60421333, -1.47240901],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK293",
      },
    },
    {
      type: "Feature",
      id: "sme4c56375",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.76498093, -32.31402151],
            [4.49197468, -32.33249352],
            [4.50658703, -26.97323967],
            [1.93772954, -26.96781806],
            [0.79515141, -27.86502308],
            [0.76498093, -32.31402151],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK196",
      },
    },
    {
      type: "Feature",
      id: "smdb6c4b3c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.49197468, -32.33249352],
            [0.76498093, -32.31402151],
            [0.7512061, -34.27692944],
            [1.48729008, -35.00004435],
            [1.52024907, -37.0259855],
            [2.68479985, -37.96733687],
            [4.47565214, -37.96395919],
            [4.49197468, -32.33249352],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK197",
      },
    },
    {
      type: "Feature",
      id: "sm63855951",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.80545081, -37.95767852],
            [7.79075095, -32.95582196],
            [4.49024019, -32.94926354],
            [4.47565214, -37.96395919],
            [7.80545081, -37.95767852],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK198",
      },
    },
    {
      type: "Feature",
      id: "smb698b658",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.01795882, -33.0039488],
            [32.00368109, -37.91201969],
            [34.83079594, -37.90668345],
            [35.75364751, -37.09612166],
            [35.58184601, -33.01102617],
            [32.01795882, -33.0039488],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK206",
      },
    },
    {
      type: "Feature",
      id: "sm853a4d0f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [32.00368109, -37.91201969],
            [32.01795882, -33.0039488],
            [28.66415672, -32.99728811],
            [28.67861886, -37.91829532],
            [32.00368109, -37.91201969],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK205",
      },
    },
    {
      type: "Feature",
      id: "sm1164d5d3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.67861886, -37.91829532],
            [28.66415672, -32.99728811],
            [25.16336103, -32.99033495],
            [25.14900603, -37.92495642],
            [28.67861886, -37.91829532],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK204",
      },
    },
    {
      type: "Feature",
      id: "sm0239890b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.14900603, -37.92495642],
            [25.16336103, -32.99033495],
            [21.66686649, -32.98338978],
            [21.65247208, -37.93155451],
            [25.14900603, -37.92495642],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK203",
      },
    },
    {
      type: "Feature",
      id: "smc29bf292",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.65247208, -37.93155451],
            [21.66686649, -32.98338978],
            [18.19525831, -32.97649351],
            [18.18082475, -37.93810505],
            [21.65247208, -37.93155451],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK202",
      },
    },
    {
      type: "Feature",
      id: "smbc202e48",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.18082475, -37.93810505],
            [18.19525831, -32.97649351],
            [14.67944489, -32.96950888],
            [14.66497171, -37.9447384],
            [18.18082475, -37.93810505],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK201",
      },
    },
    {
      type: "Feature",
      id: "smf4e2a834",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.66497171, -37.9447384],
            [14.67944489, -32.96950888],
            [11.26213491, -32.9627194],
            [11.27679524, -37.9511303],
            [14.66497171, -37.9447384],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK200",
      },
    },
    {
      type: "Feature",
      id: "sm74ee3161",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.27679524, -37.9511303],
            [11.26213491, -32.9627194],
            [7.79075095, -32.95582196],
            [7.80545081, -37.95767852],
            [11.27679524, -37.9511303],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK199",
      },
    },
    {
      type: "Feature",
      id: "sm80dd14bf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.94162855, -27.03112542],
            [31.9252817, -33.00376475],
            [35.58184601, -33.01102617],
            [35.38011235, -27.96210429],
            [34.41331547, -27.03633902],
            [31.94162855, -27.03112542],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK187",
      },
    },
    {
      type: "Feature",
      id: "sm90207aab",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.9252817, -33.00376475],
            [31.94162855, -27.03112542],
            [28.51412152, -27.02389529],
            [28.49777468, -32.99695766],
            [31.9252817, -33.00376475],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK188",
      },
    },
    {
      type: "Feature",
      id: "smf46eab6e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.49777468, -32.99695766],
            [28.51412152, -27.02389529],
            [25.17200708, -27.01684483],
            [25.15566024, -32.99031965],
            [28.49777468, -32.99695766],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK189",
      },
    },
    {
      type: "Feature",
      id: "sm8e45b39b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [25.15566024, -32.99031965],
            [25.17200708, -27.01684483],
            [21.65744244, -27.0094301],
            [21.6410956, -32.98333859],
            [25.15566024, -32.99031965],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK190",
      },
    },
    {
      type: "Feature",
      id: "sm5cdcd339",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.6410956, -32.98333859],
            [21.65744244, -27.0094301],
            [18.18661474, -27.00210717],
            [18.17026789, -32.97644387],
            [21.6410956, -32.98333859],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK191",
      },
    },
    {
      type: "Feature",
      id: "sm00555695",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [18.17026789, -32.97644387],
            [18.18661474, -27.00210717],
            [14.71433013, -26.99478068],
            [14.69798329, -32.96954571],
            [18.17026789, -32.97644387],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK192",
      },
    },
    {
      type: "Feature",
      id: "sm299a3121",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.69798329, -32.96954571],
            [14.71433013, -26.99478068],
            [11.24319023, -26.98745613],
            [11.22684339, -32.96264928],
            [14.69798329, -32.96954571],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK193",
      },
    },
    {
      type: "Feature",
      id: "sm97702968",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [11.22684339, -32.96264928],
            [11.24319023, -26.98745613],
            [7.85900389, -26.9803146],
            [7.84265705, -32.9559251],
            [11.22684339, -32.96264928],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK194",
      },
    },
    {
      type: "Feature",
      id: "smc94e21a5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.84265705, -32.9559251],
            [7.85900389, -26.9803146],
            [4.50658703, -26.97323967],
            [4.49024019, -32.94926354],
            [7.84265705, -32.9559251],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK195",
      },
    },
    {
      type: "Feature",
      id: "sm338a0237",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [45.10350919, -48.80868136],
            [38.21428799, -48.97062382],
            [38.29148931, -50.52510032],
            [39.13743657, -51.04603009],
            [45.22386235, -50.976908],
            [45.10350919, -48.80868136],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK262",
      },
    },
    {
      type: "Feature",
      id: "sm2feca3c0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.21428799, -48.97062382],
            [45.10350919, -48.80868136],
            [44.98728491, -46.62185261],
            [38.10996241, -46.79045603],
            [38.21428799, -48.97062382],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK261",
      },
    },
    {
      type: "Feature",
      id: "sm1f80ab23",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.10996241, -46.79045603],
            [44.98728491, -46.62185261],
            [44.88563275, -44.63376671],
            [38.0187171, -44.80820376],
            [38.10996241, -46.79045603],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK260",
      },
    },
    {
      type: "Feature",
      id: "sm025e5437",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [38.0187171, -44.80820376],
            [44.88563275, -44.63376671],
            [44.75663142, -42.00929013],
            [37.90292255, -42.19109334],
            [38.0187171, -44.80820376],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK259",
      },
    },
    {
      type: "Feature",
      id: "sm9748c1ce",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.90292255, -42.19109334],
            [44.75663142, -42.00929013],
            [44.62516508, -39.21849956],
            [37.78491535, -39.40770316],
            [37.90292255, -42.19109334],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK258",
      },
    },
    {
      type: "Feature",
      id: "sm4f5c6d4c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.78491535, -39.40770316],
            [44.62516508, -39.21849956],
            [44.51398301, -36.76805256],
            [37.68511577, -36.96337399],
            [37.78491535, -39.40770316],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK257",
      },
    },
    {
      type: "Feature",
      id: "sm5199cbf5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.68511577, -36.96337399],
            [44.51398301, -36.76805256],
            [44.39484495, -34.05254451],
            [37.57817471, -34.2542174],
            [37.68511577, -36.96337399],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK256",
      },
    },
    {
      type: "Feature",
      id: "sme7915e34",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.57817471, -34.2542174],
            [44.39484495, -34.05254451],
            [44.27727435, -31.28467134],
            [37.47264064, -31.49234183],
            [37.57817471, -34.2542174],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK255",
      },
    },
    {
      type: "Feature",
      id: "sm1eeb967d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.47264064, -31.49234183],
            [44.27727435, -31.28467134],
            [44.15880945, -28.41110931],
            [37.36630382, -28.62448393],
            [37.47264064, -31.49234183],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK254",
      },
    },
    {
      type: "Feature",
      id: "sme5ccd862",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.36630382, -28.62448393],
            [44.15880945, -28.41110931],
            [44.02980812, -25.19103567],
            [37.25050927, -25.41015377],
            [37.36630382, -28.62448393],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK253",
      },
    },
    {
      type: "Feature",
      id: "sm45020f9b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.25050927, -25.41015377],
            [44.02980812, -25.19103567],
            [43.91683419, -22.29894452],
            [37.14910128, -22.52262371],
            [37.25050927, -25.41015377],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK252",
      },
    },
    {
      type: "Feature",
      id: "sme52dc2d7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.14910128, -22.52262371],
            [43.91683419, -22.29894452],
            [43.79792051, -19.18889609],
            [37.04236164, -19.41683666],
            [37.14910128, -22.52262371],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK251",
      },
    },
    {
      type: "Feature",
      id: "smec06fc4a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [37.04236164, -19.41683666],
            [43.79792051, -19.18889609],
            [43.68113527, -16.07619572],
            [36.93753252, -16.30772254],
            [37.04236164, -19.41683666],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK250",
      },
    },
    {
      type: "Feature",
      id: "sm98e5b13c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.93753252, -16.30772254],
            [43.68113527, -16.07619572],
            [43.55784931, -12.73670316],
            [36.82686821, -12.97131172],
            [36.93753252, -16.30772254],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK249",
      },
    },
    {
      type: "Feature",
      id: "sm6cf6c222",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.82686821, -12.97131172],
            [43.55784931, -12.73670316],
            [43.44151283, -9.54438913],
            [36.72244192, -9.78119432],
            [36.82686821, -12.97131172],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK248",
      },
    },
    {
      type: "Feature",
      id: "smc4415c12",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.72244192, -9.78119432],
            [43.44151283, -9.54438913],
            [43.31224126, -5.96216083],
            [37.55540532, -6.04956903],
            [36.63255376, -7.01008255],
            [36.72244192, -9.78119432],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK247",
      },
    },
    {
      type: "Feature",
      id: "sm3082151f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.87364506, 41.02958953],
            [41.65051614, 41.19808306],
            [41.53794925, 43.71417533],
            [35.51194829, 43.71020476],
            [34.77586431, 43.1757698],
            [34.87364506, 41.02958953],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK103",
      },
    },
    {
      type: "Feature",
      id: "smac78ecaa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [43.19496082, -1.51037657],
            [36.45553531, -1.73806438],
            [36.53367681, -4.04697341],
            [37.32469243, -4.75897259],
            [43.30125493, -4.72612645],
            [43.19496082, -1.51037657],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK118",
      },
    },
    {
      type: "Feature",
      id: "smbedcc52b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.45553531, -1.73806438],
            [43.19496082, -1.51037657],
            [43.0865793, 1.77336162],
            [36.34453884, 1.54558948],
            [36.45553531, -1.73806438],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK117",
      },
    },
    {
      type: "Feature",
      id: "sm26b55257",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.34453884, 1.54558948],
            [43.0865793, 1.77336162],
            [42.97185039, 5.24284901],
            [36.22686033, 5.02115054],
            [36.34453884, 1.54558948],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK116",
      },
    },
    {
      type: "Feature",
      id: "sm91ccb884",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.22686033, 5.02115054],
            [42.97185039, 5.24284901],
            [42.86353567, 8.50099068],
            [36.1159322, 8.28069727],
            [36.22686033, 5.02115054],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK115",
      },
    },
    {
      type: "Feature",
      id: "sm8c9de639",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.1159322, 8.28069727],
            [42.86353567, 8.50099068],
            [42.75590469, 11.71139326],
            [36.00570431, 11.49318221],
            [36.1159322, 8.28069727],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK114",
      },
    },
    {
      type: "Feature",
      id: "sm675e95c4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [36.00570431, 11.49318221],
            [42.75590469, 11.71139326],
            [42.64401572, 15.00960428],
            [35.89129742, 14.78904894],
            [36.00570431, 11.49318221],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK113",
      },
    },
    {
      type: "Feature",
      id: "smf75e68b9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.89129742, 14.78904894],
            [42.64401572, 15.00960428],
            [42.54483084, 17.89173128],
            [35.78953769, 17.67945521],
            [35.89129742, 14.78904894],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK112",
      },
    },
    {
      type: "Feature",
      id: "smf309b14d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.78953769, 17.67945521],
            [42.54483084, 17.89173128],
            [42.42932063, 21.18977349],
            [35.67124046, 20.98168364],
            [35.78953769, 17.67945521],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK111",
      },
    },
    {
      type: "Feature",
      id: "sme0277053",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.67124046, 20.98168364],
            [42.42932063, 21.18977349],
            [42.31323674, 24.43167395],
            [35.55235571, 24.22837419],
            [35.67124046, 20.98168364],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK110",
      },
    },
    {
      type: "Feature",
      id: "sm4a2b0d5a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.55235571, 24.22837419],
            [42.31323674, 24.43167395],
            [42.20285186, 27.43919397],
            [35.43930748, 27.24092351],
            [35.55235571, 24.22837419],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK109",
      },
    },
    {
      type: "Feature",
      id: "smabfeac40",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.43930748, 27.24092351],
            [42.20285186, 27.43919397],
            [42.09835724, 30.21281695],
            [35.33229162, 30.01967229],
            [35.43930748, 27.24092351],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK108",
      },
    },
    {
      type: "Feature",
      id: "sm57b0a0c2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.33229162, 30.01967229],
            [42.09835724, 30.21281695],
            [41.98246457, 33.19989435],
            [35.21360271, 33.01277947],
            [35.33229162, 30.01967229],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK107",
      },
    },
    {
      type: "Feature",
      id: "sm78be919c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.21360271, 33.01277947],
            [41.98246457, 33.19989435],
            [41.87854363, 35.79474545],
            [35.10717437, 35.61328538],
            [35.21360271, 33.01277947],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK106",
      },
    },
    {
      type: "Feature",
      id: "sm52497c96",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [35.10717437, 35.61328538],
            [41.87854363, 35.79474545],
            [41.76815875, 38.46108587],
            [34.99412614, 38.28582756],
            [35.10717437, 35.61328538],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK105",
      },
    },
    {
      type: "Feature",
      id: "sm4d85edb5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [34.99412614, 38.28582756],
            [41.76815875, 38.46108587],
            [41.65051614, 41.19808306],
            [34.87364506, 41.02958953],
            [34.99412614, 38.28582756],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK104",
      },
    },
    {
      type: "Feature",
      id: "sm1d39e1d6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.59218539, -12.81420615],
            [31.52355145, -19.40386952],
            [35.15355973, -19.43810416],
            [34.97911137, -13.90390152],
            [33.77610843, -12.81899156],
            [31.59218539, -12.81420615],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK167",
      },
    },
    {
      type: "Feature",
      id: "sm9f446314",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.52355145, -19.40386952],
            [31.59218539, -12.81420615],
            [28.2524167, -12.80688786],
            [28.18404166, -19.37236821],
            [31.52355145, -19.40386952],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK168",
      },
    },
    {
      type: "Feature",
      id: "smceb61a06",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.18404166, -19.37236821],
            [28.2524167, -12.80688786],
            [24.73774958, -12.7991861],
            [24.669647, -19.33921065],
            [28.18404166, -19.37236821],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK169",
      },
    },
    {
      type: "Feature",
      id: "sm4a7a62f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.669647, -19.33921065],
            [24.73774958, -12.7991861],
            [21.30789502, -12.79166996],
            [21.24005832, -19.30684672],
            [24.669647, -19.33921065],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK170",
      },
    },
    {
      type: "Feature",
      id: "sm5559320f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.24005832, -19.30684672],
            [21.30789502, -12.79166996],
            [17.92637921, -12.78425953],
            [17.85880465, -19.27493264],
            [21.24005832, -19.30684672],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK171",
      },
    },
    {
      type: "Feature",
      id: "sm26fcc6df",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.85880465, -19.27493264],
            [17.92637921, -12.78425953],
            [14.62923651, -12.7770338],
            [14.56191754, -19.24380887],
            [17.85880465, -19.27493264],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK172",
      },
    },
    {
      type: "Feature",
      id: "smebaa176f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.56191754, -19.24380887],
            [14.62923651, -12.7770338],
            [11.01877077, -12.76912117],
            [10.95173168, -19.20972067],
            [14.56191754, -19.24380887],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK173",
      },
    },
    {
      type: "Feature",
      id: "smcdc3b83c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.95173168, -19.20972067],
            [11.01877077, -12.76912117],
            [7.59462896, -12.76161666],
            [7.52785532, -19.17738513],
            [10.95173168, -19.20972067],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK174",
      },
    },
    {
      type: "Feature",
      id: "sme6fa4ab2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.52785532, -19.17738513],
            [7.59462896, -12.76161666],
            [4.25486026, -12.75429685],
            [4.18834552, -19.14584024],
            [7.52785532, -19.17738513],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK175",
      },
    },
    {
      type: "Feature",
      id: "smb349a616",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.18834552, -19.14584024],
            [4.25486026, -12.75429685],
            [1.99815434, -12.74935068],
            [0.85557622, -13.86123988],
            [0.8007753, -19.11383521],
            [4.18834552, -19.14584024],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK176",
      },
    },
    {
      type: "Feature",
      id: "sm49f36a04",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.13249036, -25.2096214],
            [4.19801252, -19.14593156],
            [0.8007753, -19.11383521],
            [0.74571294, -24.22864378],
            [1.97618169, -25.20664284],
            [4.13249036, -25.2096214],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK177",
      },
    },
    {
      type: "Feature",
      id: "sma37608c7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.54552191, -19.40407675],
            [31.48231695, -25.24739403],
            [34.35838384, -25.25136546],
            [35.31419438, -24.37383045],
            [35.15355973, -19.43810416],
            [31.54552191, -19.40407675],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK186",
      },
    },
    {
      type: "Feature",
      id: "smb02ee4aa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.48231695, -25.24739403],
            [31.54552191, -19.40407675],
            [28.17085938, -19.37224385],
            [28.10736847, -25.24273355],
            [31.48231695, -25.24739403],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK185",
      },
    },
    {
      type: "Feature",
      id: "sm27b44e01",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [28.10736847, -25.24273355],
            [28.17085938, -19.37224385],
            [24.74390715, -19.33991135],
            [24.68012587, -25.23800067],
            [28.10736847, -25.24273355],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK184",
      },
    },
    {
      type: "Feature",
      id: "sm0b7cf34f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.68012587, -25.23800067],
            [24.74390715, -19.33991135],
            [21.30640911, -19.30747291],
            [21.24233656, -25.23325305],
            [24.68012587, -25.23800067],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK183",
      },
    },
    {
      type: "Feature",
      id: "sm0557f93d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [21.24233656, -25.23325305],
            [21.30640911, -19.30747291],
            [17.83156128, -19.27467547],
            [17.76719431, -25.22845365],
            [21.24233656, -25.23325305],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK182",
      },
    },
    {
      type: "Feature",
      id: "smc304a2dc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.76719431, -25.22845365],
            [17.83156128, -19.27467547],
            [14.48409508, -19.24307412],
            [14.47422463, -25.22390567],
            [17.76719431, -25.22845365],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK181",
      },
    },
    {
      type: "Feature",
      id: "sm228dc639",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.47422463, -25.22390567],
            [14.48409508, -19.24307412],
            [10.90339667, -19.20926424],
            [10.83844267, -25.21888403],
            [14.47422463, -25.22390567],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK180",
      },
    },
    {
      type: "Feature",
      id: "smfbe58c1f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.83844267, -25.21888403],
            [10.90339667, -19.20926424],
            [7.55597751, -19.17765074],
            [7.49073987, -25.21426009],
            [10.83844267, -25.21888403],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK179",
      },
    },
    {
      type: "Feature",
      id: "sm414e7505",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.49073987, -25.21426009],
            [7.55597751, -19.17765074],
            [4.19801252, -19.14593156],
            [4.13249036, -25.2096214],
            [7.49073987, -25.21426009],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK178",
      },
    },
    {
      type: "Feature",
      id: "sma013740d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.14361444, 2.24915245],
            [31.109339, -4.73705514],
            [34.6721084, -4.76490242],
            [34.49571294, 1.25046136],
            [33.26524419, 2.23876028],
            [31.14361444, 2.24915245],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK147",
      },
    },
    {
      type: "Feature",
      id: "sme8adf0a7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.109339, -4.73705514],
            [31.14361444, 2.24915245],
            [27.62620035, 2.26638129],
            [27.57144203, -4.70940116],
            [31.109339, -4.73705514],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK148",
      },
    },
    {
      type: "Feature",
      id: "smace3261d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.57144203, -4.70940116],
            [27.62620035, 2.26638129],
            [24.24012878, 2.2829666],
            [24.18544857, -4.6829335],
            [27.57144203, -4.70940116],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK149",
      },
    },
    {
      type: "Feature",
      id: "sm28fa1c30",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.18544857, -4.6829335],
            [24.24012878, 2.2829666],
            [20.94642499, 2.2990993],
            [20.89182075, -4.65718689],
            [24.18544857, -4.6829335],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK150",
      },
    },
    {
      type: "Feature",
      id: "sme1133f81",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.89182075, -4.65718689],
            [20.94642499, 2.2990993],
            [17.6039524, 2.31547068],
            [17.54942526, -4.63105809],
            [20.89182075, -4.65718689],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK151",
      },
    },
    {
      type: "Feature",
      id: "sm4d1b3de3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.54942526, -4.63105809],
            [17.6039524, 2.31547068],
            [14.26423705, 2.33182838],
            [14.20978695, -4.60494988],
            [17.54942526, -4.63105809],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK152",
      },
    },
    {
      type: "Feature",
      id: "sm7972d444",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.20978695, -4.60494988],
            [14.26423705, 2.33182838],
            [10.96915463, 2.34796727],
            [10.91478054, -4.57918965],
            [14.20978695, -4.60494988],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK153",
      },
    },
    {
      type: "Feature",
      id: "smfc9899b3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.91478054, -4.57918965],
            [10.96915463, 2.34796727],
            [7.62840532, 2.36432965],
            [7.57410828, -4.55307146],
            [10.91478054, -4.57918965],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK154",
      },
    },
    {
      type: "Feature",
      id: "sm06fb817c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.57410828, -4.55307146],
            [7.62840532, 2.36432965],
            [4.19356495, 2.38115267],
            [4.13934714, -4.52621667],
            [7.57410828, -4.55307146],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK155",
      },
    },
    {
      type: "Feature",
      id: "sm2a6fc61d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.13934714, -4.52621667],
            [4.19356495, 2.38115267],
            [1.88829107, 2.39244323],
            [1.00938482, 1.29439583],
            [0.96389045, -4.50138837],
            [4.13934714, -4.52621667],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK156",
      },
    },
    {
      type: "Feature",
      id: "sm2dd3f422",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [4.14812742, -10.91557642],
            [4.19731916, -4.52666993],
            [0.96389045, -4.50138837],
            [0.92149419, -9.86248113],
            [2.05308599, -10.89988901],
            [4.14812742, -10.91557642],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK157",
      },
    },
    {
      type: "Feature",
      id: "smb12bbbe8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [31.00704788, -4.7362556],
            [30.9579006, -11.11625124],
            [33.74864263, -11.13713243],
            [34.82530278, -9.94906147],
            [34.6721084, -4.76490242],
            [31.00704788, -4.7362556],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK166",
      },
    },
    {
      type: "Feature",
      id: "sma90e8937",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.9579006, -11.11625124],
            [31.00704788, -4.7362556],
            [27.57851323, -4.70945643],
            [27.52936027, -11.09059579],
            [30.9579006, -11.11625124],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK165",
      },
    },
    {
      type: "Feature",
      id: "smb6060471",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.52936027, -11.09059579],
            [27.57851323, -4.70945643],
            [24.3264195, -4.68403546],
            [24.27726114, -11.06625856],
            [27.52936027, -11.09059579],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK164",
      },
    },
    {
      type: "Feature",
      id: "sm5d2bccb2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [24.27726114, -11.06625856],
            [24.3264195, -4.68403546],
            [20.85494744, -4.65689864],
            [20.80578332, -11.04027736],
            [24.27726114, -11.06625856],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK163",
      },
    },
    {
      type: "Feature",
      id: "sm8108f5a6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.80578332, -11.04027736],
            [20.85494744, -4.65689864],
            [17.55857593, -4.63112962],
            [17.50940635, -11.01560452],
            [20.80578332, -11.04027736],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK162",
      },
    },
    {
      type: "Feature",
      id: "sm11588890",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [17.50940635, -11.01560452],
            [17.55857593, -4.63112962],
            [14.17834015, -4.60470403],
            [14.12916496, -10.99030183],
            [17.50940635, -11.01560452],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK161",
      },
    },
    {
      type: "Feature",
      id: "sm29c1b955",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [14.12916496, -10.99030183],
            [14.17834015, -4.60470403],
            [10.92423587, -4.57926357],
            [10.87505528, -10.96594124],
            [14.12916496, -10.99030183],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK160",
      },
    },
    {
      type: "Feature",
      id: "smde68e2e8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [10.87505528, -10.96594124],
            [10.92423587, -4.57926357],
            [7.5829164, -4.55314032],
            [7.53373028, -10.94092566],
            [10.87505528, -10.96594124],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK159",
      },
    },
    {
      type: "Feature",
      id: "sma21b5d0d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [7.53373028, -10.94092566],
            [7.5829164, -4.55314032],
            [4.19731916, -4.52666993],
            [4.14812742, -10.91557642],
            [7.53373028, -10.94092566],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK158",
      },
    },
    {
      type: "Feature",
      id: "sm17c33628",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.52755109, 16.93759517],
            [30.47171327, 10.21143847],
            [34.12764162, 10.18242087],
            [33.96287602, 15.8274415],
            [32.86424321, 16.92365064],
            [30.52755109, 16.93759517],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK119",
      },
    },
    {
      type: "Feature",
      id: "sm796306c1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.47171327, 10.21143847],
            [30.52755109, 16.93759517],
            [27.18504031, 16.95754026],
            [27.12925172, 10.23796574],
            [30.47171327, 10.21143847],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK120",
      },
    },
    {
      type: "Feature",
      id: "sm98632817",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.12925172, 10.23796574],
            [27.18504031, 16.95754026],
            [23.53847161, 16.97929728],
            [23.48273674, 10.26690357],
            [27.12925172, 10.23796574],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK121",
      },
    },
    {
      type: "Feature",
      id: "sm410be508",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.48273674, 10.26690357],
            [23.53847161, 16.97929728],
            [20.28561893, 16.99870309],
            [20.22993197, 10.29271478],
            [23.48273674, 10.26690357],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK122",
      },
    },
    {
      type: "Feature",
      id: "sm6ec419cb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.22993197, 10.29271478],
            [20.28561893, 16.99870309],
            [16.85805698, 17.01914901],
            [16.80242051, 10.31991002],
            [20.22993197, 10.29271478],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK123",
      },
    },
    {
      type: "Feature",
      id: "sm687a5f1f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.80242051, 10.31991002],
            [16.85805698, 17.01914901],
            [13.38867822, 17.03984209],
            [13.33309284, 10.34743466],
            [16.80242051, 10.31991002],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK124",
      },
    },
    {
      type: "Feature",
      id: "sma2de42a4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.33309284, 10.34743466],
            [13.38867822, 17.03984209],
            [10.04758495, 17.05976786],
            [9.99204879, 10.37393924],
            [13.33309284, 10.34743466],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK125",
      },
    },
    {
      type: "Feature",
      id: "sm21ae1038",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.99204879, 10.37393924],
            [10.04758495, 17.05976786],
            [6.4442506, 17.0812552],
            [6.38876751, 10.40252165],
            [9.99204879, 10.37393924],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK126",
      },
    },
    {
      type: "Feature",
      id: "smf0753253",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.38876751, 10.40252165],
            [6.4442506, 17.0812552],
            [3.14816358, 17.10090821],
            [3.09272903, 10.42866461],
            [6.38876751, 10.40252165],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK127",
      },
    },
    {
      type: "Feature",
      id: "sm024403fa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.09272903, 10.42866461],
            [3.14816358, 17.10090821],
            [-0.28010713, 17.12134716],
            [-0.33549118, 10.45585366],
            [3.09272903, 10.42866461],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK128",
      },
    },
    {
      type: "Feature",
      id: "smfbbd8a62",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.33549118, 10.45585366],
            [-0.28010713, 17.12134716],
            [-3.83949838, 17.14256546],
            [-3.89483, 10.48408008],
            [-0.33549118, 10.45585366],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK129",
      },
    },
    {
      type: "Feature",
      id: "sm461f926c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.89483, 10.48408008],
            [-3.83949838, 17.14256546],
            [-7.22418036, 17.16274003],
            [-7.27946214, 10.51091866],
            [-3.89483, 10.48408008],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK130",
      },
    },
    {
      type: "Feature",
      id: "sm2a5488bb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.27946214, 10.51091866],
            [-7.22418036, 17.16274003],
            [-10.56243859, 17.18263575],
            [-10.61767119, 10.53738683],
            [-7.27946214, 10.51091866],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK131",
      },
    },
    {
      type: "Feature",
      id: "sme970b82e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.61767119, 10.53738683],
            [-10.56243859, 17.18263575],
            [-12.92677241, 17.19672567],
            [-14.04737788, 16.08095695],
            [-14.09311186, 10.56494068],
            [-10.61767119, 10.53738683],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK132",
      },
    },
    {
      type: "Feature",
      id: "sm1bbd8308",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.66411471, 4.26307231],
            [-10.61306432, 10.53735031],
            [-14.09311186, 10.56494068],
            [-14.1352685, 5.38993212],
            [-13.080581, 4.27332663],
            [-10.66411471, 4.26307231],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK133",
      },
    },
    {
      type: "Feature",
      id: "sm357a12c0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.48305326, 10.21134847],
            [30.43326528, 4.08865419],
            [33.39158696, 4.07609752],
            [34.27049321, 5.2039622],
            [34.12764162, 10.18242087],
            [30.48305326, 10.21134847],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK146",
      },
    },
    {
      type: "Feature",
      id: "smdfd55930",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.43326528, 4.08865419],
            [30.48305326, 10.21134847],
            [27.1505142, 10.23779699],
            [27.10062385, 4.10279942],
            [30.43326528, 4.08865419],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK145",
      },
    },
    {
      type: "Feature",
      id: "sm1b3da154",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [27.10062385, 4.10279942],
            [27.1505142, 10.23779699],
            [23.54439792, 10.26641426],
            [23.49439679, 4.11810559],
            [27.10062385, 4.10279942],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK144",
      },
    },
    {
      type: "Feature",
      id: "smb697b954",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.49439679, 4.11810559],
            [23.54439792, 10.26641426],
            [20.25828194, 10.29248984],
            [20.20817987, 4.13205327],
            [23.49439679, 4.11810559],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK143",
      },
    },
    {
      type: "Feature",
      id: "sm1626d275",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.20817987, 4.13205327],
            [20.25828194, 10.29248984],
            [16.73296308, 10.3204611],
            [16.68275271, 4.14701595],
            [20.20817987, 4.13205327],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK142",
      },
    },
    {
      type: "Feature",
      id: "sm6483b36c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.68275271, 4.14701595],
            [16.73296308, 10.3204611],
            [13.39546277, 10.34693985],
            [13.34514989, 4.1611812],
            [16.68275271, 4.14701595],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK141",
      },
    },
    {
      type: "Feature",
      id: "sm4b79e869",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.34514989, 4.1611812],
            [13.39546277, 10.34693985],
            [9.96688819, 10.37413883],
            [9.91646998, 4.17573273],
            [13.34514989, 4.1611812],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK140",
      },
    },
    {
      type: "Feature",
      id: "sm6b415b9b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.91646998, 4.17573273],
            [9.96688819, 10.37413883],
            [6.4433412, 10.40208877],
            [6.39281476, 4.19068706],
            [9.91646998, 4.17573273],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK139",
      },
    },
    {
      type: "Feature",
      id: "smc4c471d7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.39281476, 4.19068706],
            [6.4433412, 10.40208877],
            [3.14269586, 10.42826831],
            [3.09206802, 4.20469512],
            [6.39281476, 4.19068706],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK138",
      },
    },
    {
      type: "Feature",
      id: "sm907e524a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.09206802, 4.20469512],
            [3.14269586, 10.42826831],
            [-0.28871373, 10.45548268],
            [-0.33944697, 4.21925787],
            [3.09206802, 4.20469512],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK137",
      },
    },
    {
      type: "Feature",
      id: "smb719a254",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.33944697, 4.21925787],
            [-0.28871373, 10.45548268],
            [-3.83175132, 10.48357988],
            [-3.8825934, 4.23429408],
            [-0.33944697, 4.21925787],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK136",
      },
    },
    {
      type: "Feature",
      id: "sme23033f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.8825934, 4.23429408],
            [-3.83175132, 10.48357988],
            [-7.22240782, 10.51046626],
            [-7.27335405, 4.24868333],
            [-3.8825934, 4.23429408],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK135",
      },
    },
    {
      type: "Feature",
      id: "sm99c9dfdc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-7.27335405, 4.24868333],
            [-7.22240782, 10.51046626],
            [-10.61306432, 10.53735031],
            [-10.66411471, 4.26307231],
            [-7.27335405, 4.24868333],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK134",
      },
    },
    {
      type: "Feature",
      id: "sm7fc2f714",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-19.76952219, 17.25423827],
            [-19.8037336, 10.27649382],
            [-16.0376638, 10.2588588],
            [-16.00843744, 16.23396632],
            [-16.97523432, 17.24394798],
            [-19.76952219, 17.25423827],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK315",
      },
    },
    {
      type: "Feature",
      id: "sm59874586",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.05686782, 10.29172618],
            [-23.02267038, 17.26621764],
            [-25.5225976, 17.27542282],
            [-26.59925776, 16.29724582],
            [-26.59925776, 14.70931564],
            [-28.48890619, 14.70931564],
            [-29.76332026, 13.30227256],
            [-29.76752946, 10.3231456],
            [-23.05686782, 10.29172618],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK345",
      },
    },
    {
      type: "Feature",
      id: "sm766e730e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.02267038, 17.26621764],
            [-23.05686782, 10.29172618],
            [-19.8037336, 10.27649382],
            [-19.76952219, 17.25423827],
            [-23.02267038, 17.26621764],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK316",
      },
    },
    {
      type: "Feature",
      id: "sm76153570",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.77145733, 7.51729074],
            [-22.95656516, 7.48513747],
            [-22.94304795, 10.29119324],
            [-29.76752946, 10.3231456],
            [-29.77145733, 7.51729074],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK344",
      },
    },
    {
      type: "Feature",
      id: "sm53d3b09e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.14643845, -30.79338909],
            [-29.82666241, -30.78545361],
            [-29.82923822, -32.37227135],
            [-28.70863275, -33.33209965],
            [-23.16069624, -33.33209965],
            [-23.14643845, -30.79338909],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK331",
      },
    },
    {
      type: "Feature",
      id: "sm62ad00c4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.82666241, -30.78545361],
            [-23.14643845, -30.79338909],
            [-23.12968505, -27.72260779],
            [-29.82176298, -27.69441343],
            [-29.82666241, -30.78545361],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK332",
      },
    },
    {
      type: "Feature",
      id: "smabd7c753",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.82176298, -27.69441343],
            [-23.12968505, -27.72260779],
            [-23.11422, -24.80883857],
            [-29.81730039, -24.80042464],
            [-29.82176298, -27.69441343],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK333",
      },
    },
    {
      type: "Feature",
      id: "sm85564c10",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.81730039, -24.80042464],
            [-23.11422, -24.80883857],
            [-23.09958376, -21.98653103],
            [-29.8130474, -21.97792251],
            [-29.81730039, -24.80042464],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK334",
      },
    },
    {
      type: "Feature",
      id: "sm635dfea1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.8130474, -21.97792251],
            [-23.09958376, -21.98653103],
            [-23.08455134, -19.0282788],
            [-29.8086479, -18.9980258],
            [-29.8130474, -21.97792251],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK335",
      },
    },
    {
      type: "Feature",
      id: "smf78c0af2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.8086479, -18.9980258],
            [-23.08455134, -19.0282788],
            [-23.06737341, -15.58259349],
            [-29.80365629, -15.55171272],
            [-29.8086479, -18.9980258],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK336",
      },
    },
    {
      type: "Feature",
      id: "sm61953876",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.80365629, -15.55171272],
            [-23.06737341, -15.58259349],
            [-23.05188637, -12.42537781],
            [-29.79915603, -12.394019],
            [-29.80365629, -15.55171272],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK337",
      },
    },
    {
      type: "Feature",
      id: "smadf4059e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.79915603, -12.394019],
            [-23.05188637, -12.42537781],
            [-23.03644711, -9.2392773],
            [-29.79466964, -9.20753199],
            [-29.79915603, -12.394019],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK338",
      },
    },
    {
      type: "Feature",
      id: "smc52b69a9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.79466964, -9.20753199],
            [-23.03644711, -9.2392773],
            [-23.02031678, -5.87966706],
            [-29.78998245, -5.84762003],
            [-29.79466964, -9.20753199],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK339",
      },
    },
    {
      type: "Feature",
      id: "sm08364b41",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.78998245, -5.84762003],
            [-23.02031678, -5.87966706],
            [-23.00462063, -2.59079727],
            [-29.78542142, -2.55856127],
            [-29.78998245, -5.84762003],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK340",
      },
    },
    {
      type: "Feature",
      id: "sm47594ef8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.78542142, -2.55856127],
            [-23.00462063, -2.59079727],
            [-22.98873625, 0.74618431],
            [-29.78080569, 0.77850365],
            [-29.78542142, -2.55856127],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK341",
      },
    },
    {
      type: "Feature",
      id: "sm29808ba3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.78080569, 0.77850365],
            [-22.98873625, 0.74618431],
            [-22.97285883, 4.07917714],
            [-29.77619199, 4.11147027],
            [-29.78080569, 0.77850365],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK342",
      },
    },
    {
      type: "Feature",
      id: "sm919963ee",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-29.77619199, 4.11147027],
            [-22.97285883, 4.07917714],
            [-22.95656516, 7.48513747],
            [-29.77145733, 7.51729074],
            [-29.77619199, 4.11147027],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK343",
      },
    },
    {
      type: "Feature",
      id: "sm0f6cc37e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.24096804, -30.80604566],
            [-23.14635219, -30.77781663],
            [-23.16069624, -33.33209965],
            [-17.17298822, -33.33209965],
            [-16.25013666, -32.44647215],
            [-16.24096804, -30.80604566],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK330",
      },
    },
    {
      type: "Feature",
      id: "sm92f7b82d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.14635219, -30.77781663],
            [-16.24096804, -30.80604566],
            [-16.22404203, -27.70353957],
            [-23.12942617, -27.67444132],
            [-23.14635219, -30.77781663],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK329",
      },
    },
    {
      type: "Feature",
      id: "sm7e701ce0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.12942617, -27.67444132],
            [-16.22404203, -27.70353957],
            [-16.20899712, -24.86941392],
            [-23.11438127, -24.83959617],
            [-23.12942617, -27.67444132],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK328",
      },
    },
    {
      type: "Feature",
      id: "smf3952ae9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.11438127, -24.83959617],
            [-16.20899712, -24.86941392],
            [-16.19412054, -22.00159284],
            [-23.09950468, -21.97112131],
            [-23.11438127, -24.83959617],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK327",
      },
    },
    {
      type: "Feature",
      id: "sm64d295d0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.09950468, -21.97112131],
            [-16.19412054, -22.00159284],
            [-16.17825308, -18.87768389],
            [-23.08363722, -18.84658713],
            [-23.09950468, -21.97112131],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK326",
      },
    },
    {
      type: "Feature",
      id: "sma6e322dc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.08363722, -18.84658713],
            [-16.17825308, -18.87768389],
            [-16.16192557, -15.6013543],
            [-23.06730972, -15.56970115],
            [-23.08363722, -18.84658713],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK325",
      },
    },
    {
      type: "Feature",
      id: "smee4c0d01",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.06730972, -15.56970115],
            [-16.16192557, -15.6013543],
            [-16.14643456, -12.44358163],
            [-23.0518187, -12.41149016],
            [-23.06730972, -15.56970115],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK324",
      },
    },
    {
      type: "Feature",
      id: "sm1a388df0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.0518187, -12.41149016],
            [-16.14643456, -12.44358163],
            [-16.13098335, -9.25519927],
            [-23.03636749, -9.22276413],
            [-23.0518187, -12.41149016],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK323",
      },
    },
    {
      type: "Feature",
      id: "sme14914de",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.03636749, -9.22276413],
            [-16.13098335, -9.25519927],
            [-16.11488487, -5.90237007],
            [-23.02026902, -5.86968188],
            [-23.03636749, -9.22276413],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK322",
      },
    },
    {
      type: "Feature",
      id: "smbdefb1f9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.02026902, -5.86968188],
            [-16.11488487, -5.90237007],
            [-16.09939286, -2.65645062],
            [-23.00477701, -2.62362406],
            [-23.02026902, -5.86968188],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK321",
      },
    },
    {
      type: "Feature",
      id: "sm4e192204",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.00477701, -2.62362406],
            [-16.09939286, -2.65645062],
            [-16.08331926, 0.72022605],
            [-22.98870341, 0.75308477],
            [-23.00477701, -2.62362406],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK320",
      },
    },
    {
      type: "Feature",
      id: "sm95f9214c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-22.98870341, 0.75308477],
            [-16.08331926, 0.72022605],
            [-16.06761913, 4.01612204],
            [-22.97300327, 4.04890212],
            [-22.98870341, 0.75308477],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK319",
      },
    },
    {
      type: "Feature",
      id: "sm9d65bd6d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-22.97300327, 4.04890212],
            [-16.06761913, 4.01612204],
            [-16.05237207, 7.20431748],
            [-22.95775621, 7.23691832],
            [-22.97300327, 4.04890212],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK318",
      },
    },
    {
      type: "Feature",
      id: "sm80ae84e2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-22.95775621, 7.23691832],
            [-16.05237207, 7.20431748],
            [-16.0376638, 10.2588588],
            [-22.94304795, 10.29119324],
            [-22.95775621, 7.23691832],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK317",
      },
    },
    {
      type: "Feature",
      id: "sm14c21f8c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.27764799, 18.96266478],
            [-23.24893463, 24.85111372],
            [-26.47546629, 24.86803606],
            [-26.50587398, 20.00771671],
            [-25.47315913, 18.97205274],
            [-23.27764799, 18.96266478],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK102",
      },
    },
    {
      type: "Feature",
      id: "sm03687fb8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.28379958, 24.5700112],
            [30.25539092, 18.73359563],
            [32.84227055, 18.72251841],
            [33.74314946, 19.69771529],
            [33.62122529, 24.55246526],
            [30.28379958, 24.5700112],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK85",
      },
    },
    {
      type: "Feature",
      id: "smeb2f1c31",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [30.25539092, 18.73359563],
            [30.28379958, 24.5700112],
            [26.59686104, 24.58939178],
            [26.5684314, 18.74938223],
            [30.25539092, 18.73359563],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK86",
      },
    },
    {
      type: "Feature",
      id: "sm5f13cb1a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.5684314, 18.74938223],
            [26.59686104, 24.58939178],
            [23.34062167, 24.60650588],
            [23.31217349, 18.76332344],
            [26.5684314, 18.74938223],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK87",
      },
    },
    {
      type: "Feature",
      id: "sm74238989",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.31217349, 18.76332344],
            [23.34062167, 24.60650588],
            [19.91377165, 24.62451415],
            [19.88530397, 18.77799387],
            [23.31217349, 18.76332344],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK88",
      },
    },
    {
      type: "Feature",
      id: "smbc02f8de",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.88530397, 18.77799387],
            [19.91377165, 24.62451415],
            [16.83926971, 24.6406686],
            [16.81078453, 18.79115479],
            [19.88530397, 18.77799387],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK89",
      },
    },
    {
      type: "Feature",
      id: "smea985abb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.81078453, 18.79115479],
            [16.83926971, 24.6406686],
            [13.40924061, 24.65868864],
            [13.3807359, 18.8058364],
            [16.81078453, 18.79115479],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK90",
      },
    },
    {
      type: "Feature",
      id: "smfa3b13a5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.3807359, 18.8058364],
            [13.40924061, 24.65868864],
            [9.98159582, 24.67669356],
            [9.9530716, 18.82050652],
            [13.3807359, 18.8058364],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK91",
      },
    },
    {
      type: "Feature",
      id: "smed54edfd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.9530716, 18.82050652],
            [9.98159582, 24.67669356],
            [6.72654861, 24.69378944],
            [6.69800586, 18.83443675],
            [9.9530716, 18.82050652],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK92",
      },
    },
    {
      type: "Feature",
      id: "sm0176de76",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.69800586, 18.83443675],
            [6.72654861, 24.69378944],
            [3.47229616, 24.7108788],
            [3.44373489, 18.84836242],
            [6.69800586, 18.83443675],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK93",
      },
    },
    {
      type: "Feature",
      id: "smd9662309",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.44373489, 18.84836242],
            [3.47229616, 24.7108788],
            [0.13015539, 24.72842726],
            [0.1015751, 18.86266299],
            [3.44373489, 18.84836242],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK94",
      },
    },
    {
      type: "Feature",
      id: "sm7ed1c41b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.1015751, 18.86266299],
            [0.13015539, 24.72842726],
            [-3.11535459, 24.74546597],
            [-3.14395335, 18.87654891],
            [0.1015751, 18.86266299],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK95",
      },
    },
    {
      type: "Feature",
      id: "sm9ddaf985",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.14395335, 18.87654891],
            [-3.11535459, 24.74546597],
            [-6.54299938, 24.76345835],
            [-6.57161765, 18.89121286],
            [-3.14395335, 18.87654891],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK96",
      },
    },
    {
      type: "Feature",
      id: "sm8aaab773",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.57161765, 18.89121286],
            [-6.54299938, 24.76345835],
            [-9.71055565, 24.78008315],
            [-9.73919196, 18.90476296],
            [-6.57161765, 18.89121286],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK97",
      },
    },
    {
      type: "Feature",
      id: "smc4100b17",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.73919196, 18.90476296],
            [-9.71055565, 24.78008315],
            [-13.13978998, 24.79807885],
            [-13.1684458, 18.91943123],
            [-9.73919196, 18.90476296],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK98",
      },
    },
    {
      type: "Feature",
      id: "sm01132e53",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.1684458, 18.91943123],
            [-13.13978998, 24.79807885],
            [-16.56862693, 24.81606985],
            [-16.59730227, 18.93409651],
            [-13.1684458, 18.91943123],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK99",
      },
    },
    {
      type: "Feature",
      id: "sm2c312e9a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.59730227, 18.93409651],
            [-16.56862693, 24.81606985],
            [-19.99468218, 24.83404365],
            [-20.02337702, 18.94874861],
            [-16.59730227, 18.93409651],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK100",
      },
    },
    {
      type: "Feature",
      id: "sm4e4d6cb5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-20.02337702, 18.94874861],
            [-19.99468218, 24.83404365],
            [-23.24893463, 24.85111372],
            [-23.27764799, 18.96266478],
            [-20.02337702, 18.94874861],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK101",
      },
    },
    {
      type: "Feature",
      id: "sm2624df94",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.94556697, 30.97654929],
            [29.9036921, 24.57200939],
            [33.62122529, 24.55246526],
            [33.47947758, 29.96758235],
            [32.24900883, 30.9712867],
            [29.94556697, 30.97654929],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK84",
      },
    },
    {
      type: "Feature",
      id: "sm944b7a28",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.9036921, 24.57200939],
            [29.94556697, 30.97654929],
            [26.78053714, 30.98377985],
            [26.73871928, 24.58864615],
            [29.9036921, 24.57200939],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK83",
      },
    },
    {
      type: "Feature",
      id: "sm73f2e00c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.73871928, 24.58864615],
            [26.78053714, 30.98377985],
            [23.52863411, 30.9912083],
            [23.4868748, 24.60573726],
            [26.73871928, 24.58864615],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK82",
      },
    },
    {
      type: "Feature",
      id: "smffa591a3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [23.4868748, 24.60573726],
            [23.52863411, 30.9912083],
            [20.09993647, 30.99903998],
            [20.05823891, 24.62375502],
            [23.4868748, 24.60573726],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK81",
      },
    },
    {
      type: "Feature",
      id: "sm017be92e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [20.05823891, 24.62375502],
            [20.09993647, 30.99903998],
            [16.67377898, 31.00686521],
            [16.63214313, 24.64175683],
            [20.05823891, 24.62375502],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK80",
      },
    },
    {
      type: "Feature",
      id: "smc942ae68",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.63214313, 24.64175683],
            [16.67377898, 31.00686521],
            [13.42136791, 31.01429302],
            [13.37979063, 24.65884335],
            [16.63214313, 24.64175683],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK79",
      },
    },
    {
      type: "Feature",
      id: "sm1e1f5315",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.37979063, 24.65884335],
            [13.42136791, 31.01429302],
            [9.99470239, 31.02211817],
            [9.95318682, 24.67684278],
            [13.37979063, 24.65884335],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK78",
      },
    },
    {
      type: "Feature",
      id: "smc89f7267",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.95318682, 24.67684278],
            [9.99470239, 31.02211817],
            [6.8286565, 31.02934759],
            [6.78719795, 24.69347092],
            [9.95318682, 24.67684278],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK77",
      },
    },
    {
      type: "Feature",
      id: "sme66f0761",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.78719795, 24.69347092],
            [6.8286565, 31.02934759],
            [3.57878559, 31.03676785],
            [3.53738557, 24.71053702],
            [6.78719795, 24.69347092],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK76",
      },
    },
    {
      type: "Feature",
      id: "sm4d1f3d15",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.53738557, 24.71053702],
            [3.57878559, 31.03676785],
            [0.14703976, 31.04460274],
            [0.10570155, 24.72855565],
            [3.53738557, 24.71053702],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK75",
      },
    },
    {
      type: "Feature",
      id: "smd511b61a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [0.10570155, 24.72855565],
            [0.14703976, 31.04460274],
            [-3.20088103, 31.05224564],
            [-3.24215896, 24.74613164],
            [0.10570155, 24.72855565],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK74",
      },
    },
    {
      type: "Feature",
      id: "smb1e6413b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.24215896, 24.74613164],
            [-3.20088103, 31.05224564],
            [-6.54524562, 31.05987981],
            [-6.58646331, 24.76368648],
            [-3.24215896, 24.74613164],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK73",
      },
    },
    {
      type: "Feature",
      id: "smf8600a2a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.58646331, 24.76368648],
            [-6.54524562, 31.05987981],
            [-9.78241577, 31.0672687],
            [-9.82357517, 24.78067629],
            [-6.58646331, 24.76368648],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK72",
      },
    },
    {
      type: "Feature",
      id: "smb8220e02",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-9.82357517, 24.78067629],
            [-9.78241577, 31.0672687],
            [-13.1242402, 31.07489586],
            [-13.16533941, 24.79821292],
            [-9.82357517, 24.78067629],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK71",
      },
    },
    {
      type: "Feature",
      id: "sm4c75b3ce",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.16533941, 24.79821292],
            [-13.1242402, 31.07489586],
            [-16.63625484, 31.08291079],
            [-16.6772908, 24.81663997],
            [-13.16533941, 24.79821292],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK70",
      },
    },
    {
      type: "Feature",
      id: "sm1e652aa2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.6772908, 24.81663997],
            [-16.63625484, 31.08291079],
            [-19.88714182, 31.09032919],
            [-19.92811923, 24.83369448],
            [-16.6772908, 24.81663997],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK69",
      },
    },
    {
      type: "Feature",
      id: "smb8eeaf7c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-19.92811923, 24.83369448],
            [-19.88714182, 31.09032919],
            [-23.22947428, 31.09795566],
            [-23.2703915, 24.85122627],
            [-19.92811923, 24.83369448],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK68",
      },
    },
    {
      type: "Feature",
      id: "sm996b2a8f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.2703915, 24.85122627],
            [-23.22947428, 31.09795566],
            [-25.47315913, 31.10307492],
            [-26.439956, 30.30962193],
            [-26.47546629, 24.86803606],
            [-23.2703915, 24.85122627],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK67",
      },
    },
    {
      type: "Feature",
      id: "sm4db725c4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-37.67347653, 25.39196145],
            [-37.51966793, 35.25305513],
            [-38.4425195, 36.07422209],
            [-41.67249996, 36.10973308],
            [-42.5843652, 35.31583167],
            [-42.61732418, 25.57047901],
            [-41.47474606, 24.51542824],
            [-38.6402734, 24.53541846],
            [-37.67347653, 25.39196145],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK7",
      },
    },
    {
      type: "Feature",
      id: "sm5f7d4c71",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.35019879, 32.70475861],
            [-23.30483971, 38.12934428],
            [-26.45815445, 38.14623259],
            [-26.49488765, 33.78214985],
            [-25.20948726, 32.70715082],
            [-23.35019879, 32.70475861],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK66",
      },
    },
    {
      type: "Feature",
      id: "sm8909f052",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.47160728, 37.84610799],
            [29.46184783, 32.63678238],
            [32.27098149, 32.6331652],
            [33.23777836, 33.59017657],
            [33.0954684, 37.82661965],
            [29.47160728, 37.84610799],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK49",
      },
    },
    {
      type: "Feature",
      id: "smdb9fbf85",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.46184783, 32.63678238],
            [29.47160728, 37.84610799],
            [26.21773939, 37.8636022],
            [26.20795366, 32.64097208],
            [29.46184783, 32.63678238],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK50",
      },
    },
    {
      type: "Feature",
      id: "sm8cceefbe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.20795366, 32.64097208],
            [26.21773939, 37.8636022],
            [22.89270818, 37.88147472],
            [22.84901012, 32.64529683],
            [26.20795366, 32.64097208],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK51",
      },
    },
    {
      type: "Feature",
      id: "sm0a73a688",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.84901012, 32.64529683],
            [22.89270818, 37.88147472],
            [19.63791358, 37.89896551],
            [19.5940985, 32.64948743],
            [22.84901012, 32.64529683],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK52",
      },
    },
    {
      type: "Feature",
      id: "sm696418d9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.5940985, 32.64948743],
            [19.63791358, 37.89896551],
            [16.46164562, 37.91603031],
            [16.45178113, 32.65353289],
            [19.5940985, 32.64948743],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK53",
      },
    },
    {
      type: "Feature",
      id: "smc1268895",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.45178113, 32.65353289],
            [16.46164562, 37.91603031],
            [12.94578845, 37.93491496],
            [12.93589557, 32.65805907],
            [16.45178113, 32.65353289],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK54",
      },
    },
    {
      type: "Feature",
      id: "sm88113e1c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [12.93589557, 32.65805907],
            [12.94578845, 37.93491496],
            [9.70733189, 37.95230533],
            [9.66315975, 32.66227202],
            [12.93589557, 32.65805907],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK55",
      },
    },
    {
      type: "Feature",
      id: "sm57ae9e0e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.66315975, 32.66227202],
            [9.70733189, 37.95230533],
            [6.36465074, 37.97025106],
            [6.32035842, 32.66657497],
            [9.66315975, 32.66227202],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK56",
      },
    },
    {
      type: "Feature",
      id: "smf6dfc594",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.32035842, 32.66657497],
            [6.36465074, 37.97025106],
            [3.15768934, 37.98746404],
            [3.11328172, 32.670703],
            [6.32035842, 32.66657497],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK57",
      },
    },
    {
      type: "Feature",
      id: "sme0bcb0ec",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.11328172, 32.670703],
            [3.15768934, 37.98746404],
            [-0.05046896, 38.00467939],
            [-0.09499193, 32.67483239],
            [3.11328172, 32.670703],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK58",
      },
    },
    {
      type: "Feature",
      id: "sm6e05ab7b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.09499193, 32.67483239],
            [-0.05046896, 38.00467939],
            [-3.47475293, 38.02305005],
            [-3.51939903, 32.67923976],
            [-0.09499193, 32.67483239],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK59",
      },
    },
    {
      type: "Feature",
      id: "smae2cf0bf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.51939903, 32.67923976],
            [-3.47475293, 38.02305005],
            [-6.7292483, 38.04050556],
            [-6.77401141, 32.68342839],
            [-3.51939903, 32.67923976],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK60",
      },
    },
    {
      type: "Feature",
      id: "sm4ff503f9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.77401141, 32.68342839],
            [-6.7292483, 38.04050556],
            [-9.98883049, 38.05798418],
            [-10.0337108, 32.68762337],
            [-6.77401141, 32.68342839],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK61",
      },
    },
    {
      type: "Feature",
      id: "sm417c8d4b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.0337108, 32.68762337],
            [-9.98883049, 38.05798418],
            [-13.24811346, 38.07545702],
            [-13.29311095, 32.69181777],
            [-10.0337108, 32.68762337],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK62",
      },
    },
    {
      type: "Feature",
      id: "sm03385965",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.58568348, 32.69605465],
            [-16.54056761, 38.09310345],
            [-20.00251115, 38.11165369],
            [-20.04775149, 32.70050943],
            [-16.58568348, 32.69605465],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK64",
      },
    },
    {
      type: "Feature",
      id: "sm0741eb04",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.54056761, 38.09310345],
            [-16.58568348, 32.69605465],
            [-13.29311095, 32.69181777],
            [-13.24811346, 38.07545702],
            [-16.54056761, 38.09310345],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK63",
      },
    },
    {
      type: "Feature",
      id: "sm081b7c72",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-20.04775149, 32.70050943],
            [-20.00251115, 38.11165369],
            [-23.30483971, 38.12934428],
            [-23.35019879, 32.70475861],
            [-20.04775149, 32.70050943],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK65",
      },
    },
    {
      type: "Feature",
      id: "sm7e0ecabd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [29.68326164, 43.30823899],
            [29.6342392, 37.8452335],
            [33.0954684, 37.82661965],
            [32.93016117, 42.4585528],
            [31.78758305, 43.30382512],
            [29.68326164, 43.30823899],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK48",
      },
    },
    {
      type: "Feature",
      id: "sm15e5e8ed",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.21354592, 37.86362474],
            [26.26247689, 43.31541348],
            [22.92239945, 43.32241788],
            [22.8735578, 37.88157765],
            [26.21354592, 37.86362474],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK46",
      },
    },
    {
      type: "Feature",
      id: "sm44fc2c03",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [26.26247689, 43.31541348],
            [26.21354592, 37.86362474],
            [29.6342392, 37.8452335],
            [29.68326164, 43.30823899],
            [26.26247689, 43.31541348],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK47",
      },
    },
    {
      type: "Feature",
      id: "smc67bb72c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [22.8735578, 37.88157765],
            [22.92239945, 43.32241788],
            [19.67290402, 43.32923155],
            [19.62414925, 37.89903947],
            [22.8735578, 37.88157765],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK45",
      },
    },
    {
      type: "Feature",
      id: "sm1efd01f6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [19.62414925, 37.89903947],
            [19.67290402, 43.32923155],
            [16.50172203, 43.33588027],
            [16.45305206, 37.91607647],
            [19.62414925, 37.89903947],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK44",
      },
    },
    {
      type: "Feature",
      id: "sma6163bfc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [16.45305206, 37.91607647],
            [16.50172203, 43.33588027],
            [13.16314076, 43.34287918],
            [13.11456006, 37.93400856],
            [16.45305206, 37.91607647],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK43",
      },
    },
    {
      type: "Feature",
      id: "sm83005bcb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [13.11456006, 37.93400856],
            [13.16314076, 43.34287918],
            [9.5608928, 43.35042993],
            [9.51240841, 37.95335193],
            [13.11456006, 37.93400856],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK42",
      },
    },
    {
      type: "Feature",
      id: "sm9457e769",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [9.51240841, 37.95335193],
            [9.5608928, 43.35042993],
            [6.4820882, 43.35688273],
            [6.43368614, 37.96988048],
            [9.51240841, 37.95335193],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK41",
      },
    },
    {
      type: "Feature",
      id: "sm1d1b9115",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [6.43368614, 37.96988048],
            [6.4820882, 43.35688273],
            [3.14649926, 43.36387294],
            [3.09818639, 37.98778337],
            [6.43368614, 37.96988048],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK40",
      },
    },
    {
      type: "Feature",
      id: "sme3d1026c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [3.09818639, 37.98778337],
            [3.14649926, 43.36387294],
            [-0.0207927, 43.37050972],
            [-0.06902088, 38.00477894],
            [3.09818639, 37.98778337],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK39",
      },
    },
    {
      type: "Feature",
      id: "smf848cc8f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-0.06902088, 38.00477894],
            [-0.0207927, 43.37050972],
            [-3.40092456, 43.37759169],
            [-3.44906236, 38.02291225],
            [-0.06902088, 38.00477894],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK38",
      },
    },
    {
      type: "Feature",
      id: "sm57c22f27",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-3.44906236, 38.02291225],
            [-3.40092456, 43.37759169],
            [-6.74219892, 43.38459143],
            [-6.79024738, 38.04083269],
            [-3.44906236, 38.02291225],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK37",
      },
    },
    {
      type: "Feature",
      id: "sm03b9b9b0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-6.79024738, 38.04083269],
            [-6.74219892, 43.38459143],
            [-10.08227635, 43.39158785],
            [-10.13023551, 38.05874233],
            [-6.79024738, 38.04083269],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK36",
      },
    },
    {
      type: "Feature",
      id: "sme68e832f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-10.13023551, 38.05874233],
            [-10.08227635, 43.39158785],
            [-13.2902212, 43.39830674],
            [-13.33809458, 38.07593934],
            [-10.13023551, 38.05874233],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK35",
      },
    },
    {
      type: "Feature",
      id: "sm7c34291b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-13.33809458, 38.07593934],
            [-13.2902212, 43.39830674],
            [-16.58575572, 43.4052083],
            [-16.63354098, 38.09360169],
            [-13.33809458, 38.07593934],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK34",
      },
    },
    {
      type: "Feature",
      id: "sm43f16b3b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-16.63354098, 38.09360169],
            [-16.58575572, 43.4052083],
            [-19.9697776, 43.41229436],
            [-20.01747238, 38.11173385],
            [-16.63354098, 38.09360169],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK33",
      },
    },
    {
      type: "Feature",
      id: "smadcd0fb1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-20.01747238, 38.11173385],
            [-19.9697776, 43.41229436],
            [-23.22884848, 43.41911799],
            [-23.27645612, 38.12919224],
            [-20.01747238, 38.11173385],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK32",
      },
    },
    {
      type: "Feature",
      id: "sma59e86c6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-23.27645612, 38.12919224],
            [-23.22884848, 43.41911799],
            [-25.38526851, 43.42363254],
            [-26.41798336, 42.63661629],
            [-26.45815445, 38.14623259],
            [-23.27645612, 38.12919224],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK31",
      },
    },
    {
      type: "Feature",
      id: "sme3558e4d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.80867847, 40.66646594],
            [-28.37542217, 40.63378119],
            [-28.35707028, 42.68105279],
            [-29.30189449, 43.37174504],
            [-33.91615231, 43.38771516],
            [-34.79505856, 42.69720364],
            [-34.80867847, 40.66646594],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK30",
      },
    },
    {
      type: "Feature",
      id: "sm1b6d0eb5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-28.54023433, 19.54057015],
            [-34.93199909, 19.57073576],
            [-34.94337399, 17.41699292],
            [-33.78431637, 16.50802959],
            [-29.43373043, 16.55015888],
            [-28.55482418, 17.47463823],
            [-28.54023433, 19.54057015],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK22",
      },
    },
    {
      type: "Feature",
      id: "sma5597ece",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.93199909, 19.57073576],
            [-28.54023433, 19.54057015],
            [-28.51606319, 22.90470109],
            [-34.91392102, 22.93421412],
            [-34.93199909, 19.57073576],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK23",
      },
    },
    {
      type: "Feature",
      id: "sm31170e77",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.91392102, 22.93421412],
            [-28.51606319, 22.90470109],
            [-28.49371213, 25.94326247],
            [-34.89715009, 25.98181519],
            [-34.91392102, 22.93421412],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK24",
      },
    },
    {
      type: "Feature",
      id: "sm42088c65",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.89715009, 25.98181519],
            [-28.49371213, 25.94326247],
            [-28.46897824, 29.21645258],
            [-34.87865108, 29.2539072],
            [-34.89715009, 25.98181519],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK25",
      },
    },
    {
      type: "Feature",
      id: "sm0478b190",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.87865108, 29.2539072],
            [-28.46897824, 29.21645258],
            [-28.44661034, 32.08948043],
            [-34.86197588, 32.11669786],
            [-34.87865108, 29.2539072],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK26",
      },
    },
    {
      type: "Feature",
      id: "sm8ad4d45d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.86197588, 32.11669786],
            [-28.44661034, 32.08948043],
            [-28.42427259, 34.87125025],
            [-34.84526904, 34.89763017],
            [-34.86197588, 32.11669786],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK27",
      },
    },
    {
      type: "Feature",
      id: "smfd1d1794",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.84526904, 34.89763017],
            [-28.42427259, 34.87125025],
            [-28.397765, 38.05397033],
            [-34.8254435, 38.07931494],
            [-34.84526904, 34.89763017],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK28",
      },
    },
    {
      type: "Feature",
      id: "sm3c2564b0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-34.8254435, 38.07931494],
            [-28.397765, 38.05397033],
            [-28.37542217, 40.63378119],
            [-34.80867847, 40.66646594],
            [-34.8254435, 38.07931494],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK29",
      },
    },
    {
      type: "Feature",
      id: "smd0f11135",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-41.35322778, 42.98766064],
            [-41.38900476, 38.17122977],
            [-38.62928707, 38.15899745],
            [-37.53065426, 39.08597619],
            [-37.46473629, 42.22712469],
            [-38.65125973, 42.97916359],
            [-41.35322778, 42.98766064],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK6",
      },
    },
    {
      type: "Feature",
      id: "sm175888cf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-41.38900476, 38.17122977],
            [-41.35322778, 42.98766064],
            [-46.06519987, 43.00247588],
            [-46.09245358, 38.19207287],
            [-41.38900476, 38.17122977],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK5",
      },
    },
    {
      type: "Feature",
      id: "sm7ca67aa7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.09245358, 38.19207287],
            [-46.06519987, 43.00247588],
            [-49.53832666, 43.01339369],
            [-49.56556039, 38.20745995],
            [-46.09245358, 38.19207287],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK4",
      },
    },
    {
      type: "Feature",
      id: "smb27d2b8f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-49.56556039, 38.20745995],
            [-49.53832666, 43.01339369],
            [-53.14120898, 43.02471734],
            [-53.16842196, 38.22341844],
            [-49.56556039, 38.20745995],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK3",
      },
    },
    {
      type: "Feature",
      id: "smc81421ad",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.16842196, 38.22341844],
            [-53.14120898, 43.02471734],
            [-56.52682458, 43.03535623],
            [-56.55401808, 38.23841139],
            [-53.16842196, 38.22341844],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK2",
      },
    },
    {
      type: "Feature",
      id: "smfecf4445",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-56.55401808, 38.23841139],
            [-56.52682458, 43.03535623],
            [-59.09681637, 43.04343089],
            [-60.14051754, 42.34091512],
            [-60.10206539, 39.04759157],
            [-59.09132321, 38.2496457],
            [-56.55401808, 38.23841139],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1",
      },
    },
    {
      type: "Feature",
      id: "smb5d40aef",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.44890892, 10.73941241],
            [-53.85524388, 10.75184627],
            [-53.86222029, 14.29942497],
            [-59.37147453, 14.28917796],
            [-60.42616203, 13.45725045],
            [-60.44890892, 10.73941241],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK405",
      },
    },
    {
      type: "Feature",
      id: "smb3d6a50c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.77165709, -31.08539103],
            [-60.80526401, -31.09695189],
            [-60.82166986, -32.79809107],
            [-59.67909173, -33.75331163],
            [-53.76554674, -33.77200443],
            [-53.77165709, -31.08539103],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK391",
      },
    },
    {
      type: "Feature",
      id: "sm43d1cc7c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.80526401, -31.09695189],
            [-53.77165709, -31.08539103],
            [-53.77815251, -28.14388012],
            [-60.77757212, -28.15572562],
            [-60.80526401, -31.09695189],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK392",
      },
    },
    {
      type: "Feature",
      id: "sm056e7ad2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.77757212, -28.15572562],
            [-53.77815251, -28.14388012],
            [-53.78464502, -25.1206991],
            [-60.74989266, -25.132803],
            [-60.77757212, -28.15572562],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK393",
      },
    },
    {
      type: "Feature",
      id: "sm7ecc27f3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.74989266, -25.132803],
            [-53.78464502, -25.1206991],
            [-53.79097207, -22.10073823],
            [-60.72291858, -22.11306516],
            [-60.74989266, -25.132803],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK394",
      },
    },
    {
      type: "Feature",
      id: "smddf039f1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.72291858, -22.11306516],
            [-53.79097207, -22.10073823],
            [-53.79730251, -19.01306752],
            [-60.69593002, -19.02558579],
            [-60.72291858, -22.11306516],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK395",
      },
    },
    {
      type: "Feature",
      id: "sm57963e21",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.69593002, -19.02558579],
            [-53.79730251, -19.01306752],
            [-53.80379534, -15.78559613],
            [-60.66824917, -15.79827437],
            [-60.69593002, -19.02558579],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK396",
      },
    },
    {
      type: "Feature",
      id: "smfd1f1d3b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.66824917, -15.79827437],
            [-53.80379534, -15.78559613],
            [-53.80970195, -12.80391455],
            [-60.64306757, -12.81670396],
            [-60.66824917, -15.79827437],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK397",
      },
    },
    {
      type: "Feature",
      id: "sm35896e32",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.64306757, -12.81670396],
            [-53.80970195, -12.80391455],
            [-53.81611108, -9.52845027],
            [-60.61574356, -9.54132108],
            [-60.64306757, -12.81670396],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK398",
      },
    },
    {
      type: "Feature",
      id: "sm77f9e0c4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.61574356, -9.54132108],
            [-53.81611108, -9.52845027],
            [-53.8225215, -6.22058887],
            [-60.58841403, -6.23349861],
            [-60.61574356, -9.54132108],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK399",
      },
    },
    {
      type: "Feature",
      id: "sm3f295c65",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.58841403, -6.23349861],
            [-53.8225215, -6.22058887],
            [-53.82909803, -2.80535201],
            [-60.56037635, -2.81825637],
            [-60.58841403, -6.23349861],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK400",
      },
    },
    {
      type: "Feature",
      id: "sm1ab7663c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.56037635, -2.81825637],
            [-53.82909803, -2.80535201],
            [-53.83542767, 0.49126235],
            [-60.53339123, 0.47840683],
            [-60.56037635, -2.81825637],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK401",
      },
    },
    {
      type: "Feature",
      id: "sm172add38",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.53339123, 0.47840683],
            [-53.83542767, 0.49126235],
            [-53.84186092, 3.84011452],
            [-60.50541355, 3.89451653],
            [-60.53339123, 0.47840683],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK402",
      },
    },
    {
      type: "Feature",
      id: "sm0eb011b6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.50541355, 3.89451653],
            [-53.84186092, 3.84011452],
            [-53.84850027, 7.28240461],
            [-60.4776589, 7.26978316],
            [-60.50541355, 3.89451653],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK403",
      },
    },
    {
      type: "Feature",
      id: "sm483a9df0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.4776589, 7.26978316],
            [-53.84850027, 7.28240461],
            [-53.85524388, 10.75184627],
            [-60.44890892, 10.73941241],
            [-60.4776589, 7.26978316],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK404",
      },
    },
    {
      type: "Feature",
      id: "sm1c7e84fb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-46.9780328, -31.22826691],
            [-53.7713116, -31.23941595],
            [-53.76554674, -33.77200443],
            [-48.12147454, -33.78984163],
            [-46.97889642, -32.83502315],
            [-46.9780328, -31.22826691],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK390",
      },
    },
    {
      type: "Feature",
      id: "sm679bdb39",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.7713116, -31.23941595],
            [-46.9780328, -31.22826691],
            [-46.97643051, -28.17423748],
            [-53.77806136, -28.18574501],
            [-53.7713116, -31.23941595],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK389",
      },
    },
    {
      type: "Feature",
      id: "sm3c3d2290",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.77806136, -28.18574501],
            [-46.97643051, -28.17423748],
            [-46.9749088, -25.19063709],
            [-53.78447163, -25.20246367],
            [-53.77806136, -28.18574501],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK388",
      },
    },
    {
      type: "Feature",
      id: "sm1a431c29",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.78447163, -25.20246367],
            [-46.9749088, -25.19063709],
            [-46.97340796, -22.17456031],
            [-53.79079398, -22.18667717],
            [-53.78447163, -25.20246367],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK387",
      },
    },
    {
      type: "Feature",
      id: "sm4bce2678",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.79079398, -22.18667717],
            [-46.97340796, -22.17456031],
            [-46.9719057, -19.08942178],
            [-53.79712232, -19.10180115],
            [-53.79079398, -22.18667717],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK386",
      },
    },
    {
      type: "Feature",
      id: "smc9f9114c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.79712232, -19.10180115],
            [-46.9719057, -19.08942178],
            [-46.97044437, -16.0320714],
            [-53.80327825, -16.04467578],
            [-53.79712232, -19.10180115],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK385",
      },
    },
    {
      type: "Feature",
      id: "smd87372d8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.80327825, -16.04467578],
            [-46.97044437, -16.0320714],
            [-46.96896214, -12.88239698],
            [-53.80952224, -12.89519585],
            [-53.80327825, -16.04467578],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK384",
      },
    },
    {
      type: "Feature",
      id: "sma7a7c30f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.80952224, -12.89519585],
            [-46.96896214, -12.88239698],
            [-46.9674417, -9.60997399],
            [-53.81592717, -9.62293417],
            [-53.80952224, -12.89519585],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK383",
      },
    },
    {
      type: "Feature",
      id: "sm747455aa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.81592717, -9.62293417],
            [-46.9674417, -9.60997399],
            [-46.96584459, -6.13833273],
            [-53.8226863, -6.13522642],
            [-53.81592717, -9.62293417],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK382",
      },
    },
    {
      type: "Feature",
      id: "sm5aedde6f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.8226863, -6.13522642],
            [-46.96584459, -6.13833273],
            [-46.96427719, -2.70903593],
            [-53.82925783, -2.72219767],
            [-53.8226863, -6.13522642],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK381",
      },
    },
    {
      type: "Feature",
      id: "sm665d5a4f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.82925783, -2.72219767],
            [-46.96427719, -2.70903593],
            [-46.96283458, 0.4561233],
            [-53.83533491, 0.44293272],
            [-53.82925783, -2.72219767],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK380",
      },
    },
    {
      type: "Feature",
      id: "sm0fd64314",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.83533491, 0.44293272],
            [-46.96283458, 0.4561233],
            [-46.96127317, 3.88005298],
            [-53.84191241, 3.86687654],
            [-53.83533491, 0.44293272],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK379",
      },
    },
    {
      type: "Feature",
      id: "sm7699935e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.84191241, 3.86687654],
            [-46.96127317, 3.88005298],
            [-46.95971188, 7.2899144],
            [-53.84848942, 7.27679886],
            [-53.84191241, 3.86687654],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK378",
      },
    },
    {
      type: "Feature",
      id: "sm89f09f60",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.84848942, 7.27679886],
            [-46.95971188, 7.2899144],
            [-46.95810927, 10.76311171],
            [-53.85524048, 10.75010606],
            [-53.84848942, 7.27679886],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK377",
      },
    },
    {
      type: "Feature",
      id: "sma66217ea",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.85524048, 10.75010606],
            [-46.95810927, 10.76311171],
            [-46.95692375, 13.30761829],
            [-47.92372062, 14.31046983],
            [-53.86222029, 14.29942497],
            [-53.85524048, 10.75010606],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK376",
      },
    },
    {
      type: "Feature",
      id: "sm3f2e6cf6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-41.26020081, 22.11967672],
            [-41.3316795, 15.77551194],
            [-38.66224606, 15.74815241],
            [-37.69544918, 16.99195679],
            [-37.73939449, 21.24666931],
            [-38.66224606, 22.10425627],
            [-41.26020081, 22.11967672],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK21",
      },
    },
    {
      type: "Feature",
      id: "sm2c79c8db",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-41.3316795, 15.77551194],
            [-41.26020081, 22.11967672],
            [-46.92922732, 22.15332001],
            [-47.00044989, 15.83360001],
            [-41.3316795, 15.77551194],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK20",
      },
    },
    {
      type: "Feature",
      id: "smfcb5165b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-47.00044989, 15.83360001],
            [-46.92922732, 22.15332001],
            [-50.22162511, 22.17285531],
            [-50.29269893, 15.86732813],
            [-47.00044989, 15.83360001],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK19",
      },
    },
    {
      type: "Feature",
      id: "smc578bd6d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-50.29269893, 15.86732813],
            [-50.22162511, 22.17285531],
            [-53.6509975, 22.19320046],
            [-53.72191638, 15.90245345],
            [-50.29269893, 15.86732813],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK18",
      },
    },
    {
      type: "Feature",
      id: "sm9eb1a62b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.72191638, 15.90245345],
            [-53.6509975, 22.19320046],
            [-56.95088279, 22.21277463],
            [-57.02165259, 15.93624671],
            [-53.72191638, 15.90245345],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK17",
      },
    },
    {
      type: "Feature",
      id: "sm268434e4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-57.02165259, 15.93624671],
            [-56.95088279, 22.21277463],
            [-59.23963863, 22.2263494],
            [-60.37123043, 21.36949291],
            [-60.34925778, 17.05498656],
            [-59.29457028, 15.95952081],
            [-57.02165259, 15.93624671],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK16",
      },
    },
    {
      type: "Feature",
      id: "smbd5286f0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-44.67062377, 27.30137145],
            [-51.51624996, 27.27237307],
            [-51.51624996, 25.46141644],
            [-50.65931637, 24.53541846],
            [-45.6715234, 24.56539783],
            [-44.66078121, 25.45149675],
            [-44.67062377, 27.30137145],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK8",
      },
    },
    {
      type: "Feature",
      id: "sm7d943d2c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-51.51624996, 27.27237307],
            [-44.67062377, 27.30137145],
            [-44.68674606, 30.2653822],
            [-51.51624996, 30.23726262],
            [-51.51624996, 27.27237307],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK9",
      },
    },
    {
      type: "Feature",
      id: "sm7f23cc0e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-51.51624996, 30.23726262],
            [-44.68674606, 30.2653822],
            [-44.70223098, 33.0305213],
            [-51.51624996, 33.00328764],
            [-51.51624996, 30.23726262],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK10",
      },
    },
    {
      type: "Feature",
      id: "smfef5acac",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-51.51624996, 33.00328764],
            [-44.70223098, 33.0305213],
            [-44.71571285, 35.36960139],
            [-45.71546871, 36.14522802],
            [-50.83509762, 36.14522802],
            [-51.51624996, 35.57538681],
            [-51.51624996, 33.00328764],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK11",
      },
    },
    {
      type: "Feature",
      id: "smbc38c75d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.41517575, 33.1037715],
            [-53.46613344, 33.1037715],
            [-53.47181637, 35.68254387],
            [-54.17494137, 36.18070691],
            [-58.98695309, 36.16296948],
            [-60.41517575, 35.2889334],
            [-60.41517575, 33.1037715],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK12",
      },
    },
    {
      type: "Feature",
      id: "sm616ea921",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-53.45406846, 27.37497521],
            [-60.41517575, 27.37497521],
            [-60.41517575, 25.53083136],
            [-59.18470699, 24.59537002],
            [-54.15296871, 24.51542824],
            [-53.44984371, 25.2926707],
            [-53.45406846, 27.37497521],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK15",
      },
    },
    {
      type: "Feature",
      id: "sm58ffef47",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.41517575, 27.37497521],
            [-53.45406846, 27.37497521],
            [-53.4599012, 30.18624443],
            [-60.41517575, 30.18624443],
            [-60.41517575, 27.37497521],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK14",
      },
    },
    {
      type: "Feature",
      id: "sm5ed1adf6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-60.41517575, 30.18624443],
            [-53.4599012, 30.18624443],
            [-53.46613344, 33.1037715],
            [-60.41517575, 33.1037715],
            [-60.41517575, 30.18624443],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK13",
      },
    },
    {
      type: "Feature",
      id: "sm7f95c150",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.51958986, -56.81495782],
            [-68.88322329, -56.79416983],
            [-68.90438749, -58.68694087],
            [-62.54075406, -58.70666839],
            [-62.51958986, -56.81495782],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK786",
      },
    },
    {
      type: "Feature",
      id: "smcfae0985",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.71465432, -37.95555148],
            [-62.35102089, -37.98548362],
            [-62.3350366, -35.84383267],
            [-63.63142332, -34.85818004],
            [-68.69174988, -34.86696761],
            [-68.71465432, -37.95555148],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK777",
      },
    },
    {
      type: "Feature",
      id: "sm02d32cab",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.35102089, -37.98548362],
            [-68.71465432, -37.95555148],
            [-68.73694239, -40.84142839],
            [-62.37330896, -40.87014669],
            [-62.35102089, -37.98548362],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK778",
      },
    },
    {
      type: "Feature",
      id: "smcfa3c95e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.37330896, -40.87014669],
            [-68.73694239, -40.84142839],
            [-68.7576526, -43.41513053],
            [-62.39401917, -43.4427048],
            [-62.37330896, -40.87014669],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK779",
      },
    },
    {
      type: "Feature",
      id: "sm8c478690",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.39401917, -43.4427048],
            [-68.7576526, -43.41513053],
            [-68.77863909, -45.91614706],
            [-62.41500566, -45.94255632],
            [-62.39401917, -43.4427048],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK780",
      },
    },
    {
      type: "Feature",
      id: "sm7a11f67e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.41500566, -45.94255632],
            [-68.77863909, -45.91614706],
            [-68.80025729, -48.3796895],
            [-62.43662385, -48.40490204],
            [-62.41500566, -45.94255632],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK781",
      },
    },
    {
      type: "Feature",
      id: "sm99d41005",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.43662385, -48.40490204],
            [-68.80025729, -48.3796895],
            [-68.82057296, -50.5911447],
            [-62.45693952, -50.61524326],
            [-62.43662385, -48.40490204],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK782",
      },
    },
    {
      type: "Feature",
      id: "sm52d84016",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.45693952, -50.61524326],
            [-68.82057296, -50.5911447],
            [-68.84208383, -52.82460192],
            [-62.4784504, -52.84753901],
            [-62.45693952, -50.61524326],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK783",
      },
    },
    {
      type: "Feature",
      id: "sm7676b59f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.4784504, -52.84753901],
            [-68.84208383, -52.82460192],
            [-68.86223524, -54.81781378],
            [-62.49860181, -54.83968487],
            [-62.4784504, -52.84753901],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK784",
      },
    },
    {
      type: "Feature",
      id: "smcbf6c2b2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-62.49860181, -54.83968487],
            [-68.86223524, -54.81781378],
            [-68.88322329, -56.79416983],
            [-62.51958986, -56.81495782],
            [-62.49860181, -54.83968487],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK785",
      },
    },
    {
      type: "Feature",
      id: "smcd57ef6e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-75.13052195, -37.96580598],
            [-68.71496032, -37.99597811],
            [-68.69174988, -34.86696761],
            [-74.0135034, -34.87620815],
            [-75.12312254, -35.77255513],
            [-75.13052195, -37.96580598],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK776",
      },
    },
    {
      type: "Feature",
      id: "smbf1569d0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.71496032, -37.99597811],
            [-75.13052195, -37.96580598],
            [-75.14025279, -40.75311585],
            [-68.73647432, -40.78205377],
            [-68.71496032, -37.99597811],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK775",
      },
    },
    {
      type: "Feature",
      id: "sm51080016",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.73647432, -40.78205377],
            [-75.14025279, -40.75311585],
            [-75.14986292, -43.39580341],
            [-68.75772144, -43.42351002],
            [-68.73647432, -40.78205377],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK774",
      },
    },
    {
      type: "Feature",
      id: "sm24c9ea3a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.75772144, -43.42351002],
            [-75.14986292, -43.39580341],
            [-75.1593396, -45.8936693],
            [-68.77867351, -45.92015996],
            [-68.75772144, -43.42351002],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK773",
      },
    },
    {
      type: "Feature",
      id: "sm94776ffe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.77867351, -45.92015996],
            [-75.1593396, -45.8936693],
            [-75.16907469, -48.34771781],
            [-68.8001969, -48.37296698],
            [-68.77867351, -45.92015996],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK772",
      },
    },
    {
      type: "Feature",
      id: "smfaeb962e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.8001969, -48.37296698],
            [-75.16907469, -48.34771781],
            [-75.17831984, -50.57388397],
            [-68.82063709, -50.59796882],
            [-68.8001969, -48.37296698],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK771",
      },
    },
    {
      type: "Feature",
      id: "smc8ea6278",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.82063709, -50.59796882],
            [-75.17831984, -50.57388397],
            [-75.18803441, -52.80488683],
            [-68.84211512, -52.82777046],
            [-68.82063709, -50.59796882],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK770",
      },
    },
    {
      type: "Feature",
      id: "sm4df8f21a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.84211512, -52.82777046],
            [-75.18803441, -52.80488683],
            [-75.19705512, -54.77900604],
            [-68.86205909, -54.80079965],
            [-68.84211512, -52.82777046],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK769",
      },
    },
    {
      type: "Feature",
      id: "sm1c69ea1d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.86205909, -54.80079965],
            [-75.19705512, -54.77900604],
            [-75.20665676, -56.77939193],
            [-68.88328743, -56.80005657],
            [-68.86205909, -54.80079965],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK768",
      },
    },
    {
      type: "Feature",
      id: "sm739e3272",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.88328743, -56.80005657],
            [-75.20665676, -56.77939193],
            [-75.21620037, -58.66736296],
            [-68.90438749, -58.68694087],
            [-68.88328743, -56.80005657],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK767",
      },
    },
    {
      type: "Feature",
      id: "sm89ea2cd5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.89731911, -60.35913594],
            [-68.87764368, -58.6870238],
            [-75.21620037, -58.66736296],
            [-75.2219995, -59.7670244],
            [-74.0135034, -60.35913594],
            [-68.89731911, -60.35913594],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK766",
      },
    },
    {
      type: "Feature",
      id: "smdbebe61f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.87764368, -58.6870238],
            [-68.89731911, -60.35913594],
            [-63.46113524, -60.35913594],
            [-62.55476317, -59.90503343],
            [-62.54075406, -58.70666839],
            [-68.87764368, -58.6870238],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK787",
      },
    },
    {
      type: "Feature",
      id: "sm85f1a1d8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.75059717, -38.12530705],
            [-83.40558023, -38.13572674],
            [-83.39706608, -34.85818004],
            [-88.55940184, -34.85818004],
            [-89.74592527, -35.86164206],
            [-89.75059717, -38.12530705],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK754",
      },
    },
    {
      type: "Feature",
      id: "smed38d66e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.47181633, -58.76823387],
            [-89.8028894, -58.76138002],
            [-89.80635008, -59.83334013],
            [-88.58686766, -60.40800005],
            [-83.47860277, -60.41326594],
            [-83.47181633, -58.76823387],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK744",
      },
    },
    {
      type: "Feature",
      id: "sm8dc84951",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.8028894, -58.76138002],
            [-83.47181633, -58.76823387],
            [-83.46447456, -56.89644653],
            [-89.7970932, -56.8892254],
            [-89.8028894, -58.76138002],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK745",
      },
    },
    {
      type: "Feature",
      id: "smda33bc1a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.7970932, -56.8892254],
            [-83.46447456, -56.89644653],
            [-83.45713355, -54.92614247],
            [-89.7912976, -54.91854298],
            [-89.7970932, -56.8892254],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK746",
      },
    },
    {
      type: "Feature",
      id: "smfd27ee28",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.7912976, -54.91854298],
            [-83.45713355, -54.92614247],
            [-83.44961058, -52.80171944],
            [-89.78535835, -52.79372197],
            [-89.7912976, -54.91854298],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK747",
      },
    },
    {
      type: "Feature",
      id: "sm9b179b12",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.78535835, -52.79372197],
            [-83.44961058, -52.80171944],
            [-83.44227417, -50.62492062],
            [-89.77956639, -50.61652668],
            [-89.78535835, -52.79372197],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK748",
      },
    },
    {
      type: "Feature",
      id: "smd2e53268",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.77956639, -50.61652668],
            [-83.44227417, -50.62492062],
            [-83.4343827, -48.16544661],
            [-89.77333621, -48.1566192],
            [-89.77956639, -50.61652668],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK749",
      },
    },
    {
      type: "Feature",
      id: "smb2e077d3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.77333621, -48.1566192],
            [-83.4343827, -48.16544661],
            [-83.42722786, -45.82852585],
            [-89.76768759, -45.81930153],
            [-89.77333621, -48.1566192],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK750",
      },
    },
    {
      type: "Feature",
      id: "smeef786df",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.76768759, -45.81930153],
            [-83.42722786, -45.82852585],
            [-83.41988877, -43.32503687],
            [-89.7618935, -43.3154043],
            [-89.76768759, -45.81930153],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK751",
      },
    },
    {
      type: "Feature",
      id: "smf6295053",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.7618935, -43.3154043],
            [-83.41988877, -43.32503687],
            [-83.41255274, -40.71507243],
            [-89.75610184, -40.70503373],
            [-89.7618935, -43.3154043],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK752",
      },
    },
    {
      type: "Feature",
      id: "sm1403d9ee",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.75610184, -40.70503373],
            [-83.41255274, -40.71507243],
            [-83.40558023, -38.13572674],
            [-89.75059717, -38.12530705],
            [-89.75610184, -40.70503373],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK753",
      },
    },
    {
      type: "Feature",
      id: "sm7370dfb5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -58.95490258],
            [-83.47254224, -58.94805348],
            [-83.47860277, -60.41326594],
            [-78.06196532, -60.41884879],
            [-77.11164793, -59.90227886],
            [-77.11164793, -58.95490258],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK765",
      },
    },
    {
      type: "Feature",
      id: "sm0598b992",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-83.40501912, -37.92401811],
            [-77.11164793, -37.92401811],
            [-77.11164793, -35.77255513],
            [-78.03449949, -34.85818004],
            [-83.39706608, -34.85818004],
            [-83.40501912, -37.92401811],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK755",
      },
    },
    {
      type: "Feature",
      id: "sm1b7c3728",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -37.92401811],
            [-83.40501912, -37.92401811],
            [-83.41272561, -40.77780601],
            [-77.11164793, -40.77780601],
            [-77.11164793, -37.92401811],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK756",
      },
    },
    {
      type: "Feature",
      id: "smd2fe5b23",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -40.77780601],
            [-83.41272561, -40.77780601],
            [-83.4202486, -43.45029646],
            [-77.11164793, -43.45029646],
            [-77.11164793, -40.77780601],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK757",
      },
    },
    {
      type: "Feature",
      id: "smf03f2dc2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -43.45029646],
            [-83.4202486, -43.45029646],
            [-83.4277716, -46.00971496],
            [-77.11164793, -46.00971496],
            [-77.11164793, -43.45029646],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK758",
      },
    },
    {
      type: "Feature",
      id: "smc0a0230d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -46.00971496],
            [-83.4277716, -46.00971496],
            [-83.4350975, -48.39330406],
            [-77.11164793, -48.40206919],
            [-77.11164793, -46.00971496],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK759",
      },
    },
    {
      type: "Feature",
      id: "sm435d8d6d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -48.40206919],
            [-83.4350975, -48.39330406],
            [-83.44207038, -50.5629534],
            [-77.11164793, -50.5713478],
            [-77.11164793, -48.40206919],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK760",
      },
    },
    {
      type: "Feature",
      id: "sm95c84eee",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -50.5713478],
            [-83.44207038, -50.5629534],
            [-83.44977951, -52.85061278],
            [-77.11164793, -52.85860279],
            [-77.11164793, -50.5713478],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK761",
      },
    },
    {
      type: "Feature",
      id: "smbfd02a69",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -52.85860279],
            [-83.44977951, -52.85061278],
            [-83.45693742, -54.87212227],
            [-77.11164793, -54.87974389],
            [-77.11164793, -52.85860279],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK762",
      },
    },
    {
      type: "Feature",
      id: "smcfefe5c3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -54.87974389],
            [-83.45693742, -54.87212227],
            [-83.46483157, -56.98972751],
            [-77.11164793, -56.99695258],
            [-77.11164793, -54.87974389],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK763",
      },
    },
    {
      type: "Feature",
      id: "smc5e5e786",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-77.11164793, -56.99695258],
            [-83.46483157, -56.98972751],
            [-83.47254224, -58.94805348],
            [-77.11164793, -58.95490258],
            [-77.11164793, -56.99695258],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK764",
      },
    },
    {
      type: "Feature",
      id: "sm4747a4db",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.94509968, -34.90698882],
            [-95.95933494, -39.64596484],
            [-91.57059602, -39.65403684],
            [-91.55317625, -35.82601929],
            [-92.62983641, -34.91225252],
            [-95.94509968, -34.90698882],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK733",
      },
    },
    {
      type: "Feature",
      id: "sma620aa77",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-95.95933494, -39.64596484],
            [-95.94509968, -34.90698882],
            [-99.98930943, -34.9005673],
            [-100.00354032, -39.6385257],
            [-95.95933494, -39.64596484],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK732",
      },
    },
    {
      type: "Feature",
      id: "sme44922cb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.00354032, -39.6385257],
            [-99.98930943, -34.9005673],
            [-103.97871336, -34.89423232],
            [-105.06635985, -35.79929171],
            [-105.0779296, -39.62919044],
            [-100.00354032, -39.6385257],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK731",
      },
    },
    {
      type: "Feature",
      id: "smafed6a55",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.08548628, -42.02251286],
            [-98.51213606, -42.0341767],
            [-98.50457938, -39.64128307],
            [-105.0779296, -39.62919044],
            [-105.08548628, -42.02251286],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK730",
      },
    },
    {
      type: "Feature",
      id: "sm7cb09d4c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.57596071, -58.81450101],
            [-105.14931094, -58.80636927],
            [-105.15425047, -59.86093277],
            [-104.07759032, -60.41884879],
            [-98.58351362, -60.41347769],
            [-98.57596071, -58.81450101],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK721",
      },
    },
    {
      type: "Feature",
      id: "smc5486298",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.14931094, -58.80636927],
            [-98.57596071, -58.81450101],
            [-98.56745375, -56.92089145],
            [-105.14080397, -56.91232023],
            [-105.14931094, -58.80636927],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK722",
      },
    },
    {
      type: "Feature",
      id: "sm2d2db895",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.14080397, -56.91232023],
            [-98.56745375, -56.92089145],
            [-98.55999853, -55.17828811],
            [-105.13334875, -55.16932073],
            [-105.14080397, -56.91232023],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK723",
      },
    },
    {
      type: "Feature",
      id: "sm37207d4e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.13334875, -55.16932073],
            [-98.55999853, -55.17828811],
            [-98.55192525, -53.20130789],
            [-105.12527548, -53.19190113],
            [-105.13334875, -55.16932073],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK724",
      },
    },
    {
      type: "Feature",
      id: "sm54b88844",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.12527548, -53.19190113],
            [-98.55192525, -53.20130789],
            [-98.54393865, -51.15150276],
            [-105.11728887, -51.14165227],
            [-105.12527548, -53.19190113],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK725",
      },
    },
    {
      type: "Feature",
      id: "smec9b2e29",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.11728887, -51.14165227],
            [-98.54393865, -51.15150276],
            [-98.53606278, -49.03682715],
            [-105.109413, -49.02653211],
            [-105.11728887, -51.14165227],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK726",
      },
    },
    {
      type: "Feature",
      id: "sm282fc993",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.109413, -49.02653211],
            [-98.53606278, -49.03682715],
            [-98.52787826, -46.73979315],
            [-105.10122848, -46.72903113],
            [-105.109413, -49.02653211],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK727",
      },
    },
    {
      type: "Feature",
      id: "sm131b123d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.10122848, -46.72903113],
            [-98.52787826, -46.73979315],
            [-98.51990218, -44.40293512],
            [-105.0932524, -44.39171578],
            [-105.10122848, -46.72903113],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK728",
      },
    },
    {
      type: "Feature",
      id: "sm630fb7d8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.0932524, -44.39171578],
            [-98.51990218, -44.40293512],
            [-98.51213606, -42.0341767],
            [-105.08548628, -42.02251286],
            [-105.0932524, -44.39171578],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK729",
      },
    },
    {
      type: "Feature",
      id: "sm6fcc98cb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-91.67805475, -58.8186844],
            [-98.57594065, -58.81015217],
            [-98.58351362, -60.41347769],
            [-92.98139891, -60.40800005],
            [-91.68501219, -59.8057246],
            [-91.67805475, -58.8186844],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK743",
      },
    },
    {
      type: "Feature",
      id: "sm674b155b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.57594065, -58.81015217],
            [-91.67805475, -58.8186844],
            [-91.66524801, -56.92582214],
            [-98.56743594, -56.91682329],
            [-98.57594065, -58.81015217],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK742",
      },
    },
    {
      type: "Feature",
      id: "sm1af6c15b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.56743594, -56.91682329],
            [-91.66524801, -56.92582214],
            [-91.65339311, -55.08356471],
            [-98.55956334, -55.07412087],
            [-98.56743594, -56.91682329],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK741",
      },
    },
    {
      type: "Feature",
      id: "sme9e4bd09",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.55956334, -55.07412087],
            [-91.65339311, -55.08356471],
            [-91.64169441, -53.17838933],
            [-98.55179445, -53.16849532],
            [-98.55956334, -55.07412087],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK740",
      },
    },
    {
      type: "Feature",
      id: "sme3f67496",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.55179445, -53.16849532],
            [-91.64169441, -53.17838933],
            [-91.62984329, -51.15810901],
            [-98.54392435, -51.14774934],
            [-98.55179445, -53.16849532],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK739",
      },
    },
    {
      type: "Feature",
      id: "sm72bff7a7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.54392435, -51.14774934],
            [-91.62984329, -51.15810901],
            [-91.61814836, -49.07377244],
            [-98.53615798, -49.06294559],
            [-98.54392435, -51.14774934],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK738",
      },
    },
    {
      type: "Feature",
      id: "sma16d5608",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.53615798, -49.06294559],
            [-91.61814836, -49.07377244],
            [-91.60597166, -46.80656777],
            [-98.52807166, -46.79524875],
            [-98.53615798, -49.06294559],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK737",
      },
    },
    {
      type: "Feature",
      id: "sm7a7798c5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.52807166, -46.79524875],
            [-91.60597166, -46.80656777],
            [-91.59395869, -44.4721066],
            [-98.52009408, -44.46029906],
            [-98.52807166, -46.79524875],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK736",
      },
    },
    {
      type: "Feature",
      id: "smc7c1b1f7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.52009408, -44.46029906],
            [-91.59395869, -44.4721066],
            [-91.58194572, -42.04039063],
            [-98.5121165, -42.02809478],
            [-98.52009408, -44.46029906],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK735",
      },
    },
    {
      type: "Feature",
      id: "sm5901c1f5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-98.5121165, -42.02809478],
            [-91.58194572, -42.04039063],
            [-91.57059602, -39.65403684],
            [-98.50457938, -39.64128307],
            [-98.5121165, -42.02809478],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK734",
      },
    },
    {
      type: "Feature",
      id: "smdb4ddd42",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.35326785, -34.96903018],
            [-113.37435879, -45.83440375],
            [-107.98872314, -45.83585084],
            [-106.94502197, -45.08066727],
            [-106.95600829, -35.65659277],
            [-108.16450439, -34.97529209],
            [-113.35326785, -34.96903018],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK801",
      },
    },
    {
      type: "Feature",
      id: "sm16024456",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-138.28960553, -40.03972351],
            [-134.98689567, -40.04344734],
            [-134.97742129, -34.94292858],
            [-138.00337157, -34.93927541],
            [-138.83833251, -35.87944746],
            [-138.28960553, -40.03972351],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK810",
      },
    },
    {
      type: "Feature",
      id: "smb1d8ee1b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-134.98689567, -40.04344734],
            [-138.28960553, -40.03972351],
            [-137.56391845, -45.17368218],
            [-136.47627196, -45.82819596],
            [-134.99854685, -45.82859307],
            [-134.98689567, -40.04344734],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK809",
      },
    },
    {
      type: "Feature",
      id: "sm6aa3ba69",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-131.99913536, -45.82939908],
            [-131.9889073, -40.78006286],
            [-134.98831399, -40.77671784],
            [-134.99854685, -45.82859307],
            [-131.99913536, -45.82939908],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK808",
      },
    },
    {
      type: "Feature",
      id: "sm4ad24b40",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.43268874, -40.79740893],
            [-116.44289189, -45.83357924],
            [-113.37435879, -45.83440375],
            [-113.36416055, -40.80082998],
            [-116.43268874, -40.79740893],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK802",
      },
    },
    {
      type: "Feature",
      id: "sm0c6b5b6e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.44289189, -45.83357924],
            [-116.43268874, -40.79740893],
            [-119.6833404, -40.79378464],
            [-119.69354876, -45.83270577],
            [-116.44289189, -45.83357924],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK803",
      },
    },
    {
      type: "Feature",
      id: "smbe4f35fb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.69354876, -45.83270577],
            [-119.6833404, -40.79378464],
            [-122.67174461, -40.79045257],
            [-122.68195775, -45.83190276],
            [-119.69354876, -45.83270577],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK804",
      },
    },
    {
      type: "Feature",
      id: "smfbcb132c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.68195775, -45.83190276],
            [-122.67174461, -40.79045257],
            [-125.65950161, -40.78712106],
            [-125.66971954, -45.83109991],
            [-122.68195775, -45.83190276],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK805",
      },
    },
    {
      type: "Feature",
      id: "sm82382c2b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-125.66971954, -45.83109991],
            [-125.65950161, -40.78712106],
            [-128.82368669, -40.78359263],
            [-128.83390968, -45.83024964],
            [-125.66971954, -45.83109991],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK806",
      },
    },
    {
      type: "Feature",
      id: "sm7777c941",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-128.83390968, -45.83024964],
            [-128.82368669, -40.78359263],
            [-131.9889073, -40.78006286],
            [-131.99913536, -45.82939908],
            [-128.83390968, -45.83024964],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK807",
      },
    },
    {
      type: "Feature",
      id: "sm10f92050",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.42425541, -34.96532383],
            [-116.43514812, -40.79740619],
            [-113.36416055, -40.80082998],
            [-113.35326785, -34.96903018],
            [-116.42425541, -34.96532383],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK817",
      },
    },
    {
      type: "Feature",
      id: "smc9e79b2f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-116.43514812, -40.79740619],
            [-116.42425541, -34.96532383],
            [-119.58779329, -34.9615056],
            [-119.59868599, -40.79387903],
            [-116.43514812, -40.79740619],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK816",
      },
    },
    {
      type: "Feature",
      id: "smed3a1f78",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-119.59868599, -40.79387903],
            [-119.58779329, -34.9615056],
            [-122.66499402, -34.95779141],
            [-122.67588672, -40.79044795],
            [-119.59868599, -40.79387903],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK815",
      },
    },
    {
      type: "Feature",
      id: "sm0308a6ae",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.67588672, -40.79044795],
            [-122.66499402, -34.95779141],
            [-125.6523627, -34.95418548],
            [-125.6632554, -40.78711687],
            [-122.67588672, -40.79044795],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK814",
      },
    },
    {
      type: "Feature",
      id: "sm27bc5986",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-125.6632554, -40.78711687],
            [-125.6523627, -34.95418548],
            [-128.81706554, -34.95036533],
            [-128.82795824, -40.78358787],
            [-125.6632554, -40.78711687],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK813",
      },
    },
    {
      type: "Feature",
      id: "sm0b5ac1f5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-128.82795824, -40.78358787],
            [-128.81706554, -34.95036533],
            [-131.97956789, -34.94654766],
            [-131.99046059, -40.78006113],
            [-128.82795824, -40.78358787],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK812",
      },
    },
    {
      type: "Feature",
      id: "sm4e832ec5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-131.99046059, -40.78006113],
            [-131.97956789, -34.94654766],
            [-134.97742129, -34.94292858],
            [-134.98831399, -40.77671784],
            [-131.99046059, -40.78006113],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK811",
      },
    },
    {
      type: "Feature",
      id: "smc05bf0aa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-127.59668415, -74.60038502],
            [-127.5935069, -72.82314994],
            [-130.26899656, -72.82393069],
            [-131.18086179, -73.11990943],
            [-130.78535398, -74.3619999],
            [-129.79658445, -74.60009239],
            [-127.59668415, -74.60038502],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK613",
      },
    },
    {
      type: "Feature",
      id: "sm882d09ed",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.97511312, -72.80603565],
            [-68.96875922, -74.60818171],
            [-63.99946531, -74.60884237],
            [-62.54926999, -74.22519273],
            [-62.57124265, -73.07519067],
            [-63.56001218, -72.80445381],
            [-68.97511312, -72.80603565],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK595",
      },
    },
    {
      type: "Feature",
      id: "smf7fef771",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-68.96875922, -74.60818171],
            [-68.97511312, -72.80603565],
            [-72.66166298, -72.80711246],
            [-72.6648812, -74.60769029],
            [-68.96875922, -74.60818171],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK596",
      },
    },
    {
      type: "Feature",
      id: "smf3c29816",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-72.6648812, -74.60769029],
            [-72.66166298, -72.80711246],
            [-76.08939905, -72.80811362],
            [-76.09261472, -74.60723454],
            [-72.6648812, -74.60769029],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK597",
      },
    },
    {
      type: "Feature",
      id: "smd15236ad",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-76.09261472, -74.60723454],
            [-76.08939905, -72.80811362],
            [-79.42924445, -72.80908906],
            [-79.43245763, -74.60679046],
            [-76.09261472, -74.60723454],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK598",
      },
    },
    {
      type: "Feature",
      id: "sm6e3a2b0b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-79.43245763, -74.60679046],
            [-79.42924445, -72.80908906],
            [-82.85706857, -72.81009013],
            [-82.8602792, -74.60633468],
            [-79.43245763, -74.60679046],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK599",
      },
    },
    {
      type: "Feature",
      id: "smbbcbb6d3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-82.8602792, -74.60633468],
            [-82.85706857, -72.81009013],
            [-86.37260726, -72.81111676],
            [-86.37581526, -74.60586721],
            [-82.8602792, -74.60633468],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK600",
      },
    },
    {
      type: "Feature",
      id: "smb10555f0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.37581526, -74.60586721],
            [-86.37260726, -72.81111676],
            [-89.71245267, -72.81209204],
            [-89.71565817, -74.6054231],
            [-86.37581526, -74.60586721],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK601",
      },
    },
    {
      type: "Feature",
      id: "smd3ef56b4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-89.71565817, -74.6054231],
            [-89.71245267, -72.81209204],
            [-93.22785928, -72.81311851],
            [-93.23106216, -74.60495563],
            [-89.71565817, -74.6054231],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK602",
      },
    },
    {
      type: "Feature",
      id: "smfb66baec",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-93.23106216, -74.60495563],
            [-93.22785928, -72.81311851],
            [-96.47976998, -72.814068],
            [-96.48297045, -74.60452319],
            [-93.23106216, -74.60495563],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK603",
      },
    },
    {
      type: "Feature",
      id: "sma2fef816",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-96.48297045, -74.60452319],
            [-96.47976998, -72.814068],
            [-99.907418, -72.81506874],
            [-99.91061591, -74.60406736],
            [-96.48297045, -74.60452319],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK604",
      },
    },
    {
      type: "Feature",
      id: "sm8c5da458",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-99.91061591, -74.60406736],
            [-99.907418, -72.81506874],
            [-103.68706896, -72.81617219],
            [-103.69026404, -74.6035647],
            [-99.91061591, -74.60406736],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK605",
      },
    },
    {
      type: "Feature",
      id: "sm906f319d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.69026404, -74.6035647],
            [-103.68706896, -72.81617219],
            [-106.93893564, -72.8171215],
            [-106.9421283, -74.60313223],
            [-103.69026404, -74.6035647],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK606",
      },
    },
    {
      type: "Feature",
      id: "sme0490319",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.9421283, -74.60313223],
            [-106.93893564, -72.8171215],
            [-110.54240902, -72.81817339],
            [-110.54559899, -74.60265297],
            [-106.9421283, -74.60313223],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK607",
      },
    },
    {
      type: "Feature",
      id: "sm3627a42d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.54559899, -74.60265297],
            [-110.54240902, -72.81817339],
            [-113.97023315, -72.81917395],
            [-113.97342056, -74.60219707],
            [-110.54559899, -74.60265297],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK608",
      },
    },
    {
      type: "Feature",
      id: "sm3d495aba",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.97342056, -74.60219707],
            [-113.97023315, -72.81917395],
            [-117.57414679, -72.82022585],
            [-117.57733152, -74.60171773],
            [-113.97342056, -74.60219707],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK609",
      },
    },
    {
      type: "Feature",
      id: "sm84e229a1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.57733152, -74.60171773],
            [-117.57414679, -72.82022585],
            [-120.91372804, -72.82120054],
            [-120.91691027, -74.60127353],
            [-117.57733152, -74.60171773],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK610",
      },
    },
    {
      type: "Feature",
      id: "sm0b971b6b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.91691027, -74.60127353],
            [-120.91372804, -72.82120054],
            [-124.07757197, -72.82212389],
            [-124.08075185, -74.6008527],
            [-120.91691027, -74.60127353],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK611",
      },
    },
    {
      type: "Feature",
      id: "sm9a9bd572",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-124.08075185, -74.6008527],
            [-124.07757197, -72.82212389],
            [-127.5935069, -72.82314994],
            [-127.59668415, -74.60038502],
            [-124.08075185, -74.6008527],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK612",
      },
    },
    {
      type: "Feature",
      id: "sm1e1dadc7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-125.60822411, -72.2562018],
            [-125.55633274, -70.22769387],
            [-128.87668102, -70.21846088],
            [-128.92887182, -72.25898948],
            [-125.60822411, -72.2562018],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK615",
      },
    },
    {
      type: "Feature",
      id: "sm1203d7d4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.55731223, -70.29430569],
            [-101.60703931, -72.23604023],
            [-98.67231687, -72.23357347],
            [-97.96919187, -72.01776571],
            [-97.93899369, -70.30433],
            [-101.55731223, -70.29430569],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK623",
      },
    },
    {
      type: "Feature",
      id: "sm5f1d8b4f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.60703931, -72.23604023],
            [-101.55731223, -70.29430569],
            [-104.9905932, -70.28478948],
            [-105.0406299, -72.23892588],
            [-101.60703931, -72.23604023],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK622",
      },
    },
    {
      type: "Feature",
      id: "smb9d3f6cf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-105.0406299, -72.23892588],
            [-104.9905932, -70.28478948],
            [-108.59603224, -70.27479134],
            [-108.64639409, -72.24195575],
            [-105.0406299, -72.23892588],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK621",
      },
    },
    {
      type: "Feature",
      id: "sm042096ca",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.64639409, -72.24195575],
            [-108.59603224, -70.27479134],
            [-111.848488, -70.26576787],
            [-111.89914316, -72.24468856],
            [-108.64639409, -72.24195575],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK620",
      },
    },
    {
      type: "Feature",
      id: "smbc15a758",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.89914316, -72.24468856],
            [-111.848488, -70.26576787],
            [-115.38946376, -70.25593944],
            [-115.37237729, -72.24760615],
            [-111.89914316, -72.24468856],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK619",
      },
    },
    {
      type: "Feature",
      id: "sme17e02e0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.37237729, -72.24760615],
            [-115.38946376, -70.25593944],
            [-118.78271132, -70.24651664],
            [-118.83399183, -72.25051353],
            [-115.37237729, -72.24760615],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK618",
      },
    },
    {
      type: "Feature",
      id: "smfb1e2c5f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.83399183, -72.25051353],
            [-118.78271132, -70.24651664],
            [-122.12521856, -70.23723051],
            [-122.1768005, -72.25332068],
            [-118.83399183, -72.25051353],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK617",
      },
    },
    {
      type: "Feature",
      id: "sm17086ae5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.1768005, -72.25332068],
            [-122.12521856, -70.23723051],
            [-125.55633274, -70.22769387],
            [-125.60822411, -72.2562018],
            [-122.1768005, -72.25332068],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK616",
      },
    },
    {
      type: "Feature",
      id: "smfe854da6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.61538309, -67.83880817],
            [-101.67192057, -70.29398809],
            [-97.93899369, -70.30433],
            [-97.9032739, -68.07503262],
            [-98.55146726, -67.84830451],
            [-101.61538309, -67.83880817],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK643",
      },
    },
    {
      type: "Feature",
      id: "sm5ae7486b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-101.67192057, -70.29398809],
            [-101.61538309, -67.83880817],
            [-104.7792319, -67.82899803],
            [-104.83576938, -70.28521871],
            [-101.67192057, -70.29398809],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK644",
      },
    },
    {
      type: "Feature",
      id: "smc5e409af",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-104.83576938, -70.28521871],
            [-104.7792319, -67.82899803],
            [-108.29678625, -67.81808632],
            [-108.35332373, -70.27546454],
            [-104.83576938, -70.28521871],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK645",
      },
    },
    {
      type: "Feature",
      id: "sm9038b779",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-108.35332373, -70.27546454],
            [-108.29678625, -67.81808632],
            [-111.71851105, -67.80746698],
            [-111.77504853, -70.26597166],
            [-108.35332373, -70.27546454],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK646",
      },
    },
    {
      type: "Feature",
      id: "sm6d8e184e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-111.77504853, -70.26597166],
            [-111.71851105, -67.80746698],
            [-115.15034749, -67.79681142],
            [-115.20688497, -70.25644632],
            [-111.77504853, -70.26597166],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK647",
      },
    },
    {
      type: "Feature",
      id: "sm7df0fe63",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-115.20688497, -70.25644632],
            [-115.15034749, -67.79681142],
            [-118.75000845, -67.78562955],
            [-118.80654593, -70.24645043],
            [-115.20688497, -70.25644632],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK648",
      },
    },
    {
      type: "Feature",
      id: "sm0ba7d7d1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-118.80654593, -70.24645043],
            [-118.75000845, -67.78562955],
            [-122.17967811, -67.77497077],
            [-122.23621559, -70.23692207],
            [-118.80654593, -70.24645043],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK649",
      },
    },
    {
      type: "Feature",
      id: "sm1d135118",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-122.23621559, -70.23692207],
            [-122.17967811, -67.77497077],
            [-125.43285614, -67.764856],
            [-125.48939362, -70.22787996],
            [-122.23621559, -70.23692207],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK650",
      },
    },
    {
      type: "Feature",
      id: "smfc58ebf9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-125.48939362, -70.22787996],
            [-125.43285614, -67.764856],
            [-128.82014354, -67.75431962],
            [-128.87668102, -70.21846088],
            [-125.48939362, -70.22787996],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK651",
      },
    },
    {
      type: "Feature",
      id: "sm385a8b52",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-132.50870092, -69.94744197],
            [-128.87038842, -69.95769185],
            [-128.82014354, -67.75431962],
            [-131.97187742, -67.74451168],
            [-133.09797605, -68.15079871],
            [-132.50870092, -69.94744197],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK652",
      },
    },
    {
      type: "Feature",
      id: "smbdd5904b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-128.87038842, -69.95769185],
            [-132.50870092, -69.94744197],
            [-131.80708249, -71.90208896],
            [-130.57661374, -72.2603726],
            [-128.92887182, -72.25898948],
            [-128.87038842, -69.95769185],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK614",
      },
    },
    {
      type: "Feature",
      id: "sm737e7e94",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.40285282, -61.29959914],
            [-100.3931921, -64.45408824],
            [-96.8454272, -64.45195438],
            [-96.85407956, -61.64253355],
            [-97.49128659, -61.29621036],
            [-100.40285282, -61.29959914],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK683",
      },
    },
    {
      type: "Feature",
      id: "sm7e7c6dcc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-130.49696278, -64.14258565],
            [-130.50406433, -61.33461259],
            [-133.75715574, -61.33839421],
            [-134.43830808, -61.75712079],
            [-134.0565331, -64.15695643],
            [-130.49696278, -64.14258565],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK693",
      },
    },
    {
      type: "Feature",
      id: "smde86ed59",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-130.50406433, -61.33461259],
            [-130.49696278, -64.44992255],
            [-127.06806748, -64.47012694],
            [-127.07768993, -61.33062903],
            [-130.50406433, -61.33461259],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK692",
      },
    },
    {
      type: "Feature",
      id: "smc9638c99",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-127.07768993, -61.33062903],
            [-127.06806748, -64.47012694],
            [-123.72798507, -64.46811918],
            [-123.7376123, -61.32674532],
            [-127.07768993, -61.33062903],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK691",
      },
    },
    {
      type: "Feature",
      id: "sm999e3e5f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-123.7376123, -61.32674532],
            [-123.72798507, -64.46811918],
            [-120.65169659, -64.46626986],
            [-120.66132825, -61.32316791],
            [-123.7376123, -61.32674532],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK690",
      },
    },
    {
      type: "Feature",
      id: "smbac50758",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.66132825, -61.32316791],
            [-120.65169659, -64.46626986],
            [-117.04782024, -64.46410321],
            [-117.05745706, -61.31897645],
            [-120.66132825, -61.32316791],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK689",
      },
    },
    {
      type: "Feature",
      id: "sm3d73d5ac",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.05745706, -61.31897645],
            [-117.04782024, -64.46410321],
            [-113.70773782, -64.462095],
            [-113.71737944, -61.3150913],
            [-117.05745706, -61.31897645],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK688",
      },
    },
    {
      type: "Feature",
      id: "sm5cf4f790",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.71737944, -61.3150913],
            [-113.70773782, -64.462095],
            [-110.32554889, -64.46006133],
            [-110.33519536, -61.31115667],
            [-113.71737944, -61.3150913],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK687",
      },
    },
    {
      type: "Feature",
      id: "smf44f3254",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.33519536, -61.31115667],
            [-110.32554889, -64.46006133],
            [-107.07347951, -64.45810576],
            [-107.08313065, -61.30737295],
            [-110.33519536, -61.31115667],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK686",
      },
    },
    {
      type: "Feature",
      id: "sm5a2ee85c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.08313065, -61.30737295],
            [-107.07347951, -64.45810576],
            [-103.68932929, -64.45607061],
            [-103.69898528, -61.30343508],
            [-107.08313065, -61.30737295],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK685",
      },
    },
    {
      type: "Feature",
      id: "sm0ecf845c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.69898528, -61.30343508],
            [-103.68932929, -64.45607061],
            [-100.3931921, -64.45408824],
            [-100.40285282, -61.29959914],
            [-103.69898528, -61.30343508],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK684",
      },
    },
    {
      type: "Feature",
      id: "sm023fa204",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-130.48877943, -67.04919443],
            [-130.49696278, -64.14258565],
            [-134.0565331, -64.15695643],
            [-133.42756589, -66.62213796],
            [-132.26301511, -67.04568226],
            [-130.48877943, -67.04919443],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK653",
      },
    },
    {
      type: "Feature",
      id: "sm8973c64f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-130.49696278, -64.44992255],
            [-130.48877943, -67.04919443],
            [-127.1461327, -67.05960334],
            [-127.06806748, -64.47012694],
            [-130.49696278, -64.44992255],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK654",
      },
    },
    {
      type: "Feature",
      id: "sm097e0ea9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-127.06806748, -64.47012694],
            [-127.1461327, -67.05960334],
            [-123.92095412, -67.06219126],
            [-123.88884602, -64.46821588],
            [-127.06806748, -64.47012694],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK655",
      },
    },
    {
      type: "Feature",
      id: "sm8deae210",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.65502926, -64.46627186],
            [-120.64617789, -67.068669],
            [-117.24143873, -67.07540197],
            [-117.20911123, -64.46420018],
            [-120.65502926, -64.46627186],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK656",
      },
    },
    {
      type: "Feature",
      id: "sm4e907403",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.64617789, -67.068669],
            [-120.65502926, -64.46627186],
            [-123.88884602, -64.46821588],
            [-123.92095412, -67.06219126],
            [-120.64617789, -67.068669],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK657",
      },
    },
    {
      type: "Feature",
      id: "smebf3bb28",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-117.20911123, -64.46420018],
            [-117.24143873, -67.07540197],
            [-113.78990214, -67.08222558],
            [-113.79881538, -64.46214977],
            [-117.20911123, -64.46420018],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK658",
      },
    },
    {
      type: "Feature",
      id: "smb64e07c4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.79881538, -64.46214977],
            [-113.78990214, -67.08222558],
            [-110.36281769, -67.08899895],
            [-110.37176186, -64.46008912],
            [-113.79881538, -64.46214977],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK659",
      },
    },
    {
      type: "Feature",
      id: "smd3bc66bb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-110.37176186, -64.46008912],
            [-110.36281769, -67.08899895],
            [-107.11035122, -67.09542545],
            [-107.11932474, -64.45813333],
            [-110.37176186, -64.46008912],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK660",
      },
    },
    {
      type: "Feature",
      id: "sm592b4e66",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-107.11932474, -64.45813333],
            [-107.11035122, -67.09542545],
            [-103.68197967, -67.10219767],
            [-103.69098413, -64.4560716],
            [-107.11932474, -64.45813333],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK661",
      },
    },
    {
      type: "Feature",
      id: "sm035a5410",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-103.69098413, -64.4560716],
            [-103.68197967, -67.10219767],
            [-100.38685468, -67.10870489],
            [-100.39588888, -64.45408986],
            [-103.69098413, -64.4560716],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK662",
      },
    },
    {
      type: "Feature",
      id: "smf5c28ba7",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-100.39588888, -64.45408986],
            [-100.38685468, -67.10870489],
            [-97.63410886, -67.11413968],
            [-96.83760007, -66.7677447],
            [-96.8454272, -64.45195438],
            [-100.39588888, -64.45408986],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK663",
      },
    },
    {
      type: "Feature",
      id: "sm3a3d948f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-106.93911956, -58.86411987],
            [-114.12872164, -58.8291631],
            [-114.15724168, -60.36456891],
            [-108.12055907, -60.36456891],
            [-106.93403563, -59.65065425],
            [-106.93911956, -58.86411987],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK720",
      },
    },
    {
      type: "Feature",
      id: "sm26c295cc",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.12872164, -58.8291631],
            [-106.93911956, -58.86411987],
            [-106.94987242, -57.13997768],
            [-114.09813758, -57.10350703],
            [-114.12872164, -58.8291631],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK719",
      },
    },
    {
      type: "Feature",
      id: "sm43e2bac9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.09813758, -57.10350703],
            [-106.94987242, -57.13997768],
            [-106.96061437, -55.33347582],
            [-114.06758458, -55.29546477],
            [-114.09813758, -57.10350703],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK718",
      },
    },
    {
      type: "Feature",
      id: "sm1ef27c49",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.06758458, -55.29546477],
            [-106.96061437, -55.33347582],
            [-106.97138633, -53.43527418],
            [-114.03694618, -53.3956959],
            [-114.06758458, -55.29546477],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK717",
      },
    },
    {
      type: "Feature",
      id: "sm40d31744",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.03694618, -53.3956959],
            [-106.97138633, -53.43527418],
            [-106.98123525, -51.62211106],
            [-114.00893318, -51.58108582],
            [-114.03694618, -53.3956959],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK716",
      },
    },
    {
      type: "Feature",
      id: "smb91310e4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.00893318, -51.58108582],
            [-106.98123525, -51.62211106],
            [-106.99169772, -49.61331364],
            [-113.97917508, -49.5707435],
            [-114.00893318, -51.58108582],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK715",
      },
    },
    {
      type: "Feature",
      id: "smf7fe915d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-113.97917508, -49.5707435],
            [-106.99169772, -49.61331364],
            [-106.9999536, -47.96727909],
            [-108.03266844, -47.15178049],
            [-113.94442728, -47.11398188],
            [-113.97917508, -49.5707435],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK714",
      },
    },
    {
      type: "Feature",
      id: "sm6f9a32b8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.95804273, -49.490965],
            [-113.97863634, -49.53355547],
            [-113.94442728, -47.11398188],
            [-119.72212157, -47.0770145],
            [-120.95259032, -47.81995052],
            [-120.95804273, -49.490965],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK713",
      },
    },
    {
      type: "Feature",
      id: "sm8cb2b387",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-114.12803541, -58.7913503],
            [-120.99230381, -58.75790598],
            [-120.99653563, -59.76149215],
            [-119.81001219, -60.36456891],
            [-114.15724168, -60.36456891],
            [-114.12803541, -58.7913503],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK707",
      },
    },
    {
      type: "Feature",
      id: "smeafc8981",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.99230381, -58.75790598],
            [-114.12803541, -58.7913503],
            [-114.09911881, -57.16016691],
            [-120.98567248, -57.12505132],
            [-120.99230381, -58.75790598],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK708",
      },
    },
    {
      type: "Feature",
      id: "sme4fad21d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.98567248, -57.12505132],
            [-114.09911881, -57.16016691],
            [-114.06869001, -55.36237326],
            [-120.97869437, -55.3254431],
            [-120.98567248, -57.12505132],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK709",
      },
    },
    {
      type: "Feature",
      id: "smd2714c3e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.97869437, -55.3254431],
            [-114.06869001, -55.36237326],
            [-114.03887367, -53.51780954],
            [-120.97196494, -53.5089245],
            [-120.97869437, -55.3254431],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK710",
      },
    },
    {
      type: "Feature",
      id: "sm9d89de99",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.97196494, -53.5089245],
            [-114.03887367, -53.51780954],
            [-114.00747531, -51.48459314],
            [-120.96465625, -51.44385681],
            [-120.97196494, -53.5089245],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK711",
      },
    },
    {
      type: "Feature",
      id: "sm92c08a20",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-120.96465625, -51.44385681],
            [-114.00747531, -51.48459314],
            [-113.97863634, -49.53355547],
            [-120.95804273, -49.490965],
            [-120.96465625, -51.44385681],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK712",
      },
    },
    {
      type: "Feature",
      id: "sm4854434b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-127.33957151, -47.08489166],
            [-127.33006537, -50.95961963],
            [-122.90044505, -50.95513376],
            [-122.90815673, -47.83470224],
            [-123.88593993, -47.11441062],
            [-127.33957151, -47.08489166],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK701",
      },
    },
    {
      type: "Feature",
      id: "sme0237d36",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-131.72571568, -50.96407067],
            [-131.73532193, -47.04729653],
            [-136.13569579, -47.00963529],
            [-137.19038329, -47.81257309],
            [-136.65245141, -50.96905898],
            [-131.72571568, -50.96407067],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK699",
      },
    },
    {
      type: "Feature",
      id: "smbd19c9d9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-131.73532193, -47.04729653],
            [-131.72571568, -50.96407067],
            [-127.33006537, -50.95961963],
            [-127.33957151, -47.08489166],
            [-131.73532193, -47.04729653],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK700",
      },
    },
    {
      "type": "Feature",
      "id": "smd2b5a108",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-129.57081509, -52.64159706],
            [-129.57518275, -50.96189309],
            [-136.65245141, -50.96905898],
            [-136.35129355, -52.64821133],
            [-129.57081509, -52.64159706]
          ]
        ]
      },
      "properties": { Unit_Number: "NK698" }
    },
    {
      "type": "Feature",
      "id": "smff942c10",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-129.57518275, -50.96189309],
            [-129.57081509, -52.64159706],
            [-122.89607738, -52.63508497],
            [-122.90044505, -50.95513376],
            [-129.57518275, -50.96189309]
          ]
        ]
      },
      "properties": { Unit_Number: "NK702" }
    },
    {
      type: "Feature",
      id: "sm62b9296c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-129.08399951, -60.3192503],
            [-129.10969955, -58.63398596],
            [-135.17144271, -58.63905819],
            [-134.90522704, -59.86644855],
            [-133.81758055, -60.33739502],
            [-129.08399951, -60.3192503],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK694",
      },
    },
    {
      type: "Feature",
      id: "sma2f690a9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-129.10969955, -58.63398596],
            [-129.08399951, -60.3192503],
            [-123.88593993, -60.29931353],
            [-122.87519774, -59.82781885],
            [-122.8789661, -58.62877155],
            [-129.10969955, -58.63398596],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK706",
      },
    },
    {
      type: "Feature",
      id: "sm06607338",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-129.35945998, -56.60986796],
            [-129.35338055, -58.63418987],
            [-122.8789661, -58.62877155],
            [-122.88504553, -56.60413905],
            [-129.35945998, -56.60986796],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK705",
      },
    },
    {
      type: "Feature",
      id: "sm0652eda9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-129.35338055, -58.63418987],
            [-129.35945998, -56.60986796],
            [-135.59062951, -56.61538081],
            [-135.17144271, -58.63905819],
            [-129.35338055, -58.63418987],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK695",
      },
    },
    {
      type: "Feature",
      id: "sme86a91c2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-129.44749009, -54.72735338],
            [-129.44212217, -56.6099411],
            [-122.88504553, -56.60413905],
            [-122.89041345, -54.7212653],
            [-129.44749009, -54.72735338],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK704",
      },
    },
    {
      type: "Feature",
      id: "sm3239c909",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-129.44212217, -56.6099411],
            [-129.44749009, -54.72735338],
            [-135.96075637, -54.73339988],
            [-135.59062951, -56.61538081],
            [-129.44212217, -56.6099411],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK696",
      },
    },
    {
      type: "Feature",
      id: "sm216e0a4a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-129.53468579, -52.64156182],
            [-129.52902186, -54.72742907],
            [-122.89041345, -54.7212653],
            [-122.89607738, -52.63508497],
            [-129.53468579, -52.64156182],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK703",
      },
    },
    {
      type: "Feature",
      id: "smb3c2e4d3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-129.52902186, -54.72742907],
            [-129.53468579, -52.64156182],
            [-136.35129355, -52.64821133],
            [-135.96075637, -54.73339988],
            [-129.52902186, -54.72742907],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK697",
      },
    },
    {
      type: "Feature",
      id: "smc87fdc1b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-140.04251548, -40.8509545],
            [-146.70709893, -41.37232494],
            [-146.36207324, -43.81799112],
            [-139.69765508, -43.31660735],
            [-140.04251548, -40.8509545],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1068",
      },
    },
    {
      type: "Feature",
      id: "sm78d521cb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-146.70709893, -41.37232494],
            [-140.04251548, -40.8509545],
            [-140.39810909, -38.20879952],
            [-147.06286297, -38.75052812],
            [-146.70709893, -41.37232494],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1067",
      },
    },
    {
      type: "Feature",
      id: "sm8d393eed",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-147.06286297, -38.75052812],
            [-140.39810909, -38.20879952],
            [-140.73540892, -35.61057601],
            [-147.40032447, -36.17121432],
            [-147.06286297, -38.75052812],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1066",
      },
    },
    {
      type: "Feature",
      id: "smf9eb2398",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-147.40032447, -36.17121432],
            [-140.73540892, -35.61057601],
            [-141.08207889, -32.84933271],
            [-147.7471606, -33.42881666],
            [-147.40032447, -36.17121432],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1065",
      },
    },
    {
      type: "Feature",
      id: "sm0e7f49a9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-139.69765508, -43.31660735],
            [-146.36207324, -43.81799112],
            [-145.99357562, -46.32377246],
            [-139.32929959, -45.84405598],
            [-139.69765508, -43.31660735],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1069",
      },
    },
    {
      type: "Feature",
      id: "sm6913a7d2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-139.32929959, -45.84405598],
            [-145.99357562, -46.32377246],
            [-145.6657338, -48.46083777],
            [-139.00161482, -48.00013015],
            [-139.32929959, -45.84405598],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1070",
      },
    },
    {
      type: "Feature",
      id: "sm428ad96b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-139.00161482, -48.00013015],
            [-145.6657338, -48.46083777],
            [-145.3326114, -50.54390508],
            [-138.66865201, -50.10235096],
            [-139.00161482, -48.00013015],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1071",
      },
    },
    {
      type: "Feature",
      id: "sm552570fa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-138.66865201, -50.10235096],
            [-145.3326114, -50.54390508],
            [-145.00860088, -52.48545179],
            [-138.34479672, -52.06228284],
            [-138.66865201, -50.10235096],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1072",
      },
    },
    {
      type: "Feature",
      id: "sm59010890",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-138.34479672, -52.06228284],
            [-145.00860088, -52.48545179],
            [-144.6427705, -54.57903325],
            [-137.97914159, -54.17624031],
            [-138.34479672, -52.06228284],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1073",
      },
    },
    {
      type: "Feature",
      id: "sm78c924fe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-137.97914159, -54.17624031],
            [-144.6427705, -54.57903325],
            [-144.28068817, -56.55042807],
            [-137.61723273, -56.1673208],
            [-137.97914159, -54.17624031],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1074",
      },
    },
    {
      type: "Feature",
      id: "sm620dc56a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-137.61723273, -56.1673208],
            [-144.28068817, -56.55042807],
            [-143.95190934, -58.25588835],
            [-137.2886114, -57.89018308],
            [-137.61723273, -56.1673208],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1075",
      },
    },
    {
      type: "Feature",
      id: "smd8b33964",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-137.2886114, -57.89018308],
            [-143.95190934, -58.25588835],
            [-143.60413622, -59.97472919],
            [-136.94100489, -59.62689488],
            [-137.2886114, -57.89018308],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1076",
      },
    },
    {
      type: "Feature",
      id: "smf515af1a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-136.94100489, -59.62689488],
            [-143.60413622, -59.97472919],
            [-143.25355206, -61.62162766],
            [-136.59058869, -61.29121437],
            [-136.94100489, -59.62689488],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1077",
      },
    },
    {
      type: "Feature",
      id: "smd84d97a8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-136.59058869, -61.29121437],
            [-143.25355206, -61.62162766],
            [-142.91668161, -63.12568817],
            [-136.25387961, -62.81142724],
            [-136.59058869, -61.29121437],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1078",
      },
    },
    {
      type: "Feature",
      id: "sm36463434",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-136.25387961, -62.81142724],
            [-142.91668161, -63.12568817],
            [-142.56984551, -64.59688237],
            [-135.90720967, -64.29863344],
            [-136.25387961, -62.81142724],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1079",
      },
    },
    {
      type: "Feature",
      id: "smc21091b8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-135.90720967, -64.29863344],
            [-142.56984551, -64.59688237],
            [-142.23127178, -65.9602199],
            [-135.56879814, -65.67698739],
            [-135.90720967, -64.29863344],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1080",
      },
    },
    {
      type: "Feature",
      id: "sm0cf83dd4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-135.56879814, -65.67698739],
            [-142.23127178, -65.9602199],
            [-141.89346431, -67.25166389],
            [-135.2311525, -66.98280607],
            [-135.56879814, -65.67698739],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1081",
      },
    },
    {
      type: "Feature",
      id: "smd2d10356",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-135.2311525, -66.98280607],
            [-141.89346431, -67.25166389],
            [-141.53853659, -68.53763884],
            [-134.87639482, -68.28323251],
            [-135.2311525, -66.98280607],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1082",
      },
    },
    {
      type: "Feature",
      id: "sm30ea23f9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-134.87639482, -68.28323251],
            [-141.53853659, -68.53763884],
            [-141.19979211, -69.70016305],
            [-134.53781261, -69.45893293],
            [-134.87639482, -68.28323251],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1083",
      },
    },
    {
      type: "Feature",
      id: "sm9967b86d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-134.53781261, -69.45893293],
            [-141.19979211, -69.70016305],
            [-140.85142348, -70.83269469],
            [-134.18961088, -70.6043977],
            [-134.53781261, -69.45893293],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1084",
      },
    },
    {
      type: "Feature",
      id: "sm3edf21a6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-134.18961088, -70.6043977],
            [-140.85142348, -70.83269469],
            [-140.50765242, -71.89053276],
            [-133.84600451, -71.67439768],
            [-134.18961088, -70.6043977],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1085",
      },
    },
    {
      type: "Feature",
      id: "sm14b02191",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-133.84600451, -71.67439768],
            [-140.50765242, -71.89053276],
            [-140.15400322, -72.91980741],
            [-133.49252473, -72.71557753],
            [-133.84600451, -71.67439768],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1086",
      },
    },
    {
      type: "Feature",
      id: "sm55e96b94",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-133.49252473, -72.71557753],
            [-140.15400322, -72.91980741],
            [-139.96992429, -73.43287628],
            [-139.83641163, -73.8056263],
            [-133.17139592, -73.61158782],
            [-133.49252473, -72.71557753],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1087",
      },
    },
    {
      type: "Feature",
      id: "sm44b1e58e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-133.17139592, -73.61158782],
            [-139.83641163, -73.8056263],
            [-139.53047116, -74.62924013],
            [-133.6857446, -74.64088415],
            [-132.89472897, -74.34718576],
            [-133.17139592, -73.61158782],
          ],
        ],
      },
      properties: {
        Unit_Number: "NK1088",
      },
    },

    // Phase 4

    
    {
      "type": "Feature",
      "id": "sm8d03eb0c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-149.49551082, -25.42569563],
            [-149.21908767, -27.84262178],
            [-142.62729079, -28.34659398],
            [-141.74838454, -27.25819418],
            [-142.01254075, -24.77736685],
            [-149.49551082, -25.42569563]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1063",
      },
    },
    {
      "type": "Feature",
      "id": "sm8f39e8f3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-142.01254075, -24.77736685],
            [-142.31926174, -21.83358717],
            [-149.82301044, -22.49846055],
            [-149.49551082, -25.42569563],
            [-142.01254075, -24.77736685]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1062",
      },
    },
    {
      "type": "Feature",
      "id": "sm4d4c1308",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-142.31926174, -21.83358717],
            [-142.62004167, -18.88671249],
            [-150.14416653, -19.56644235],
            [-149.82301044, -22.49846055],
            [-142.31926174, -21.83358717]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1061",
      },
    },
    {
      "type": "Feature",
      "id": "smdc69c393",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-142.62004167, -18.88671249],
            [-142.94386542, -15.65528534],
            [-150.48992752, -16.34929027],
            [-150.14416653, -19.56644235],
            [-142.62004167, -18.88671249]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1060",
      },
    },
    {
      "type": "Feature",
      "id": "smf0850df9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-142.94386542, -15.65528534],
            [-143.23515502, -12.70390855],
            [-150.80095036, -13.40906426],
            [-150.48992752, -16.34929027],
            [-142.94386542, -15.65528534]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1059",
      },
    },
    {
      "type": "Feature",
      "id": "sm455057d3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-143.23515502, -12.70390855],
            [-143.56455962, -9.3249831],
            [-151.15267027, -10.04064486],
            [-150.80095036, -13.40906426],
            [-143.23515502, -12.70390855]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1058",
      },
    },
    {
      "type": "Feature",
      "id": "sm5b243475",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-143.56455962, -9.3249831],
            [-143.88830652, -5.97184245],
            [-151.49834921, -6.6955012],
            [-151.15267027, -10.04064486],
            [-143.56455962, -9.3249831]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1057",
      },
    },
    {
      "type": "Feature",
      "id": "sm59d16f2a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-143.88830652, -5.97184245],
            [-144.20973847, -2.62222376],
            [-151.84155638, -3.35141811],
            [-151.49834921, -6.6955012],
            [-143.88830652, -5.97184245]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1056",
      },
    },
    {
      "type": "Feature",
      "id": "smabedc906",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-144.20973847, -2.62222376],
            [-144.53790875, 0.80680617],
            [-152.19195836, 0.07451452],
            [-151.84155638, -3.35141811],
            [-144.20973847, -2.62222376]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1055",
      },
    },
    {
      "type": "Feature",
      "id": "sm6a213853",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-144.53790875, 0.80680617],
            [-144.86049818, 4.17477638],
            [-152.53640141, 3.44199399],
            [-152.19195836, 0.07451452],
            [-144.53790875, 0.80680617]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1054",
      },
    },
    {
      "type": "Feature",
      "id": "smac78eb7c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-144.86049818, 4.17477638],
            [-145.20808616, 7.78731121],
            [-152.90753653, 7.05682636],
            [-152.53640141, 3.44199399],
            [-144.86049818, 4.17477638]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1053",
      },
    },
    {
      "type": "Feature",
      "id": "sm6ea312d3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-145.20808616, 7.78731121],
            [-145.50451956, 10.84433091],
            [-153.22405163, 10.11807617],
            [-152.90753653, 7.05682636],
            [-145.20808616, 7.78731121]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1052",
      },
    },
    {
      "type": "Feature",
      "id": "sm3d09e92a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-145.50451956, 10.84433091],
            [-145.80689402, 13.93070499],
            [-153.54691026, 13.2108423],
            [-153.22405163, 10.11807617],
            [-145.50451956, 10.84433091]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1051",
      },
    },
    {
      "type": "Feature",
      "id": "smc8cffbf3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-145.80689402, 13.93070499],
            [-146.13390682, 17.22252566],
            [-153.89607635, 16.51181751],
            [-153.54691026, 13.2108423],
            [-145.80689402, 13.93070499]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1050",
      },
    },
    {
      "type": "Feature",
      "id": "sm3a3040cb",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-146.13390682, 17.22252566],
            [-146.45374425, 20.38647967],
            [-154.23758097, 19.68682537],
            [-153.89607635, 16.51181751],
            [-146.13390682, 17.22252566]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1049",
      },
    },
    {
      "type": "Feature",
      "id": "sm43e0661a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-146.45374425, 20.38647967],
            [-146.78981033, 23.64229894],
            [-154.59641364, 22.95630029],
            [-154.23758097, 19.68682537],
            [-146.45374425, 20.38647967]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1048",
      },
    },
    {
      "type": "Feature",
      "id": "sm96c547bf",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-146.78981033, 23.64229894],
            [-147.11319702, 26.70078284],
            [-154.94170797, 26.02968931],
            [-154.59641364, 22.95630029],
            [-146.78981033, 23.64229894]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1047",
      },
    },
    {
      "type": "Feature",
      "id": "sm72eebb1e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-147.11319702, 26.70078284],
            [-147.42513865, 29.57533373],
            [-155.2747819, 28.92005221],
            [-154.94170797, 26.02968931],
            [-147.11319702, 26.70078284]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1046",
      },
    },
    {
      "type": "Feature",
      "id": "sm057fed89",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-147.42513865, 29.57533373],
            [-147.7465706, 32.45405991],
            [-155.61798907, 31.81633107],
            [-155.2747819, 28.92005221],
            [-147.42513865, 29.57533373]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1045",
      },
    },
    {
      "type": "Feature",
      "id": "smbc9a476c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-147.7465706, 32.45405991],
            [-148.04746343, 35.06815869],
            [-155.93404035, 34.40566284],
            [-155.61798907, 31.81633107],
            [-147.7465706, 32.45405991]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1044",
      },
    },
    {
      "type": "Feature",
      "id": "sm742393f8",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-148.04746343, 35.06815869],
            [-148.38160714, 37.87597704],
            [-156.29080543, 37.23503859],
            [-155.93404035, 34.40566284],
            [-148.04746343, 35.06815869]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1043",
      },
    },
    {
      "type": "Feature",
      "id": "sm6e7f5f4a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-148.38160714, 37.87597704],
            [-148.69502416, 40.41598729],
            [-156.62544064, 39.79595417],
            [-156.29080543, 37.23503859],
            [-148.38160714, 37.87597704]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1042",
      },
    },
    {
      "type": "Feature",
      "id": "sm16a5f36e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-148.69502416, 40.41598729],
            [-149.04330642, 43.13013485],
            [-150.44955642, 44.27380026],
            [-157.30502517, 44.71266424],
            [-156.62544064, 39.79595417],
            [-148.69502416, 40.41598729]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1041",
      },
    },
    {
      "type": "Feature",
      "id": "sm5d4d346d",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.78093365, 44.04914242],
            [-110.81235807, 38.98273355],
            [-108.2181111, 38.97337516],
            [-107.25131422, 39.72100443],
            [-107.5149861, 43.21026082],
            [-108.39389235, 44.03733617],
            [-110.78093365, 44.04914242]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1030",
      },
    },
    {
      "type": "Feature",
      "id": "sm92228b41",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.81235807, 38.98273355],
            [-110.78093365, 44.04914242],
            [-114.29603857, 44.06652377],
            [-114.32749954, 38.99541197],
            [-110.81235807, 38.98273355]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1031",
      },
    },
    {
      "type": "Feature",
      "id": "sm9c175642",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-114.32749954, 38.99541197],
            [-114.29603857, 44.06652377],
            [-117.46081571, 44.08216847],
            [-117.49230958, 39.00682487],
            [-114.32749954, 38.99541197]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1032",
      },
    },
    {
      "type": "Feature",
      "id": "sm81044988",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-117.49230958, 39.00682487],
            [-117.46081571, 44.08216847],
            [-121.06503194, 44.09998044],
            [-121.09656328, 39.01982025],
            [-117.49230958, 39.00682487]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1033",
      },
    },
    {
      "type": "Feature",
      "id": "smb7d37908",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-121.09656328, 39.01982025],
            [-121.06503194, 44.09998044],
            [-124.14151343, 44.11518011],
            [-124.17307676, 39.03091094],
            [-121.09656328, 39.01982025]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1034",
      },
    },
    {
      "type": "Feature",
      "id": "sm7e1f5616",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-124.17307676, 39.03091094],
            [-124.14151343, 44.11518011],
            [-127.48125055, 44.131676],
            [-127.51284859, 39.04294869],
            [-124.17307676, 39.03091094]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1035",
      },
    },
    {
      "type": "Feature",
      "id": "sm52edd1b1",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-127.51284859, 39.04294869],
            [-127.48125055, 44.131676],
            [-130.73106068, 44.14772329],
            [-130.76269251, 39.05466034],
            [-127.51284859, 39.04294869]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1036",
      },
    },
    {
      "type": "Feature",
      "id": "sm2bf3dae0",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-130.76269251, 39.05466034],
            [-130.73106068, 44.14772329],
            [-134.15909345, 44.1646459],
            [-134.19076091, 39.06701216],
            [-130.76269251, 39.05466034]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1037",
      },
    },
    {
      "type": "Feature",
      "id": "sm2c662817",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-134.19076091, 39.06701216],
            [-134.15909345, 44.1646459],
            [-137.32101576, 44.18025055],
            [-137.3527161, 39.07840322],
            [-134.19076091, 39.06701216]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1038",
      },
    },
    {
      "type": "Feature",
      "id": "sm56b74738",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-137.3527161, 39.07840322],
            [-137.32101576, 44.18025055],
            [-140.75679734, 44.19720206],
            [-140.7885334, 39.0907788],
            [-137.3527161, 39.07840322]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1039",
      },
    },
    {
      "type": "Feature",
      "id": "sm6f37d817",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-140.7885334, 39.0907788],
            [-140.75679734, 44.19720206],
            [-146.71420485, 44.22658325],
            [-147.32943922, 43.21026082],
            [-147.06576735, 39.85607669],
            [-146.09897047, 39.10990235],
            [-140.7885334, 39.0907788]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1040",
      },
    },
    {
      "type": "Feature",
      "id": "sm54189ddc",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-139.26236534, 26.86312024],
            [-139.18659916, 32.25099275],
            [-142.30829128, 32.28326175],
            [-142.38405746, 26.89715944],
            [-139.26236534, 26.86312024]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK998",
      },
    },
    {
      "type": "Feature",
      "id": "smb3123849",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-139.18659916, 32.25099275],
            [-139.26236534, 26.86312024],
            [-135.47503881, 26.82180915],
            [-135.39927264, 32.21182768],
            [-139.18659916, 32.25099275]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK997",
      },
    },
    {
      "type": "Feature",
      "id": "sm2b0523d7",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-135.39927264, 32.21182768],
            [-135.47503881, 26.82180915],
            [-131.95886484, 26.78344223],
            [-131.88309867, 32.17545152],
            [-135.39927264, 32.21182768]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK996",
      },
    },
    {
      "type": "Feature",
      "id": "sm7d5b2523",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-131.88309867, 32.17545152],
            [-131.95886484, 26.78344223],
            [-128.36125916, 26.74417333],
            [-128.28549299, 32.13821787],
            [-131.88309867, 32.17545152]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK995",
      },
    },
    {
      "type": "Feature",
      "id": "smb9a4db64",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-128.28549299, 32.13821787],
            [-128.36125916, 26.74417333],
            [-125.19444657, 26.70959544],
            [-125.1186804, 32.10543015],
            [-128.28549299, 32.13821787]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK994",
      },
    },
    {
      "type": "Feature",
      "id": "smd4f45667",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-125.1186804, 32.10543015],
            [-125.19444657, 26.70959544],
            [-121.67290112, 26.67113194],
            [-121.59713494, 32.06895586],
            [-125.1186804, 32.10543015]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK993",
      },
    },
    {
      "type": "Feature",
      "id": "sm34f9e01d",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-121.59713494, 32.06895586],
            [-121.67290112, 26.67113194],
            [-118.15135566, 26.63265547],
            [-118.07558949, 32.03246702],
            [-121.59713494, 32.06895586]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK992",
      },
    },
    {
      "type": "Feature",
      "id": "smd257776f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-118.07558949, 32.03246702],
            [-118.15135566, 26.63265547],
            [-114.53656123, 26.59314668],
            [-114.46079506, 31.99499684],
            [-118.07558949, 32.03246702]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK991",
      },
    },
    {
      "type": "Feature",
      "id": "sm74e945b7",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-114.46079506, 31.99499684],
            [-114.53656123, 26.59314668],
            [-111.10181897, 26.55559317],
            [-111.0260528, 31.95937886],
            [-114.46079506, 31.99499684]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK990",
      },
    },
    {
      "type": "Feature",
      "id": "smd75286f6",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-111.0260528, 31.95937886],
            [-111.10181897, 26.55559317],
            [-108.39389235, 26.52597757],
            [-107.5149861, 27.62158757],
            [-107.39004849, 31.92165875],
            [-111.0260528, 31.95937886]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK989",
      },
    },
    {
      "type": "Feature",
      "id": "sm4b52174f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.91404774, 37.47765755],
            [-110.99618735, 31.9590691],
            [-107.39004849, 31.92165875],
            [-107.25131422, 36.47108229],
            [-108.39389235, 37.45423501],
            [-110.91404774, 37.47765755]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1010",
      },
    },
    {
      "type": "Feature",
      "id": "sme5eee226",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.99618735, 31.9590691],
            [-110.91404774, 37.47765755],
            [-114.52564203, 37.51121123],
            [-114.60775889, 31.99652054],
            [-110.99618735, 31.9590691]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1009",
      },
    },
    {
      "type": "Feature",
      "id": "sm031f0b4e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-114.60775889, 31.99652054],
            [-114.52564203, 37.51121123],
            [-118.12756758, 37.54466006],
            [-118.20966175, 32.03385649],
            [-114.60775889, 31.99652054]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1008",
      },
    },
    {
      "type": "Feature",
      "id": "sm62efafe5",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-118.20966175, 32.03385649],
            [-118.12756758, 37.54466006],
            [-121.73808756, 37.57817365],
            [-121.82015899, 32.07126626],
            [-118.20966175, 32.03385649]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1007",
      },
    },
    {
      "type": "Feature",
      "id": "smb84bc6c1",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-121.82015899, 32.07126626],
            [-121.73808756, 37.57817365],
            [-125.07530468, 37.60913697],
            [-125.15735509, 32.10583064],
            [-121.82015899, 32.07126626]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1006",
      },
    },
    {
      "type": "Feature",
      "id": "smf9593430",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-125.15735509, 32.10583064],
            [-125.07530468, 37.60913697],
            [-128.49395402, 37.64084248],
            [-128.57598289, 32.14122487],
            [-125.15735509, 32.10583064]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1005",
      },
    },
    {
      "type": "Feature",
      "id": "sm121cd0b3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-128.57598289, 32.14122487],
            [-128.49395402, 37.64084248],
            [-131.90400893, 37.67245481],
            [-131.98601632, 32.17651644],
            [-128.57598289, 32.14122487]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1004",
      },
    },
    {
      "type": "Feature",
      "id": "sm8d54f97f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-131.98601632, 32.17651644],
            [-131.90400893, 37.67245481],
            [-135.51560321, 37.70592079],
            [-135.59758786, 32.21387889],
            [-131.98601632, 32.17651644]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1003",
      },
    },
    {
      "type": "Feature",
      "id": "sm13c2a391",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-135.59758786, 32.21387889],
            [-135.51560321, 37.70592079],
            [-138.93640116, 37.73760488],
            [-139.01836426, 32.24925338],
            [-135.59758786, 32.21387889]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1002",
      },
    },
    {
      "type": "Feature",
      "id": "sm503d96c4",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-139.01836426, 32.24925338],
            [-138.93640116, 37.73760488],
            [-142.22634891, 37.76806422],
            [-142.30829128, 32.28326175],
            [-139.01836426, 32.24925338]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1001",
      },
    },
    {
      "type": "Feature",
      "id": "smbc661915",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-146.264738, 32.79558805],
            [-142.30145413, 32.75485049],
            [-142.22634891, 37.76806422],
            [-145.92318922, 37.80227573],
            [-146.80209547, 36.61230905],
            [-146.264738, 32.79558805]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1000",
      },
    },
    {
      "type": "Feature",
      "id": "smae4a4301",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-142.30145413, 32.75485049],
            [-146.264738, 32.79558805],
            [-145.57162672, 27.62158757],
            [-144.34115797, 26.9184946],
            [-142.38405746, 26.89715944],
            [-142.30145413, 32.75485049]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK999",
      },
    },
    {
      "type": "Feature",
      "id": "smbffbf6f7",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-144.47954617, 19.40369058],
            [-143.98959547, 13.34296346],
            [-142.93490797, 12.40047269],
            [-141.06003706, 12.38642271],
            [-141.00421956, 19.37853826],
            [-144.47954617, 19.40369058]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK977",
      },
    },
    {
      "type": "Feature",
      "id": "sm1e4f538b",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-141.00421956, 19.37853826],
            [-140.9570387, 25.06787462],
            [-143.98959547, 25.10186631],
            [-144.86850172, 24.06286688],
            [-144.47954617, 19.40369058],
            [-141.00421956, 19.37853826]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK978",
      },
    },
    {
      "type": "Feature",
      "id": "smb6f18cc7",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-137.7492231, 25.0319082],
            [-140.9570387, 25.06787462],
            [-141.00950541, 18.72736062],
            [-137.80157405, 18.70404883],
            [-137.7492231, 25.0319082]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK979",
      },
    },
    {
      "type": "Feature",
      "id": "sm17a47308",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-111.08439687, 18.50977271],
            [-107.64539534, 18.48474965],
            [-107.69076735, 24.06286688],
            [-108.48178297, 24.70327243],
            [-111.03301003, 24.73195414],
            [-111.08439687, 18.50977271]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK988",
      },
    },
    {
      "type": "Feature",
      "id": "sm1be6d64b",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-111.03301003, 24.73195414],
            [-114.46244181, 24.7704985],
            [-114.51395241, 18.53472339],
            [-111.08439687, 18.50977271],
            [-111.03301003, 24.73195414]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK987",
      },
    },
    {
      "type": "Feature",
      "id": "sm51fb63c3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-114.46244181, 24.7704985],
            [-117.80399131, 24.80804362],
            [-117.8556225, 18.55903119],
            [-114.51395241, 18.53472339],
            [-114.46244181, 24.7704985]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK986",
      },
    },
    {
      "type": "Feature",
      "id": "sm9db27067",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-117.80399131, 24.80804362],
            [-120.87919682, 24.8425861],
            [-120.93093898, 18.58139843],
            [-117.8556225, 18.55903119],
            [-117.80399131, 24.80804362]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK985",
      },
    },
    {
      "type": "Feature",
      "id": "sma1e897b9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-120.87919682, 24.8425861],
            [-124.57362401, 24.88407128],
            [-124.6254995, 18.60826565],
            [-120.93093898, 18.58139843],
            [-120.87919682, 24.8425861]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK984",
      },
    },
    {
      "type": "Feature",
      "id": "sm97e97555",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-124.57362401, 24.88407128],
            [-127.73468893, 24.91955622],
            [-127.78667849, 18.6312507],
            [-124.6254995, 18.60826565],
            [-124.57362401, 24.88407128]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK983",
      },
    },
    {
      "type": "Feature",
      "id": "sm982ad638",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-127.73468893, 24.91955622],
            [-131.07016983, 24.95698802],
            [-131.12227976, 18.65550061],
            [-127.78667849, 18.6312507],
            [-127.73468893, 24.91955622]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK982",
      },
    },
    {
      "type": "Feature",
      "id": "smbe2af0ce",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-131.07016983, 24.95698802],
            [-134.41037076, 24.99446138],
            [-134.46260122, 18.67978136],
            [-131.12227976, 18.65550061],
            [-131.07016983, 24.95698802]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK981",
      },
    },
    {
      "type": "Feature",
      "id": "sm122b045e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-134.41037076, 24.99446138],
            [-137.7492231, 25.0319082],
            [-137.80157405, 18.70404883],
            [-134.46260122, 18.67978136],
            [-134.41037076, 24.99446138]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK980",
      },
    },
    {
      "type": "Feature",
      "id": "smc84aa86a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.91465297, 12.16041477],
            [-108.5696736, 12.14282573],
            [-107.60287672, 13.08627369],
            [-107.64539534, 18.48474965],
            [-110.86412132, 18.50817004],
            [-110.91465297, 12.16041477]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK967",
      },
    },
    {
      "type": "Feature",
      "id": "sm3025cd2a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-137.78201898, 18.70390671],
            [-141.00950541, 18.72736062],
            [-141.06003706, 12.38642271],
            [-137.83255063, 12.36223468],
            [-137.78201898, 18.70390671]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK976",
      },
    },
    {
      "type": "Feature",
      "id": "sm3d5a7909",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-137.83255063, 12.36223468],
            [-134.49762367, 12.3372391],
            [-134.44709202, 18.67966863],
            [-137.78201898, 18.70390671],
            [-137.83255063, 12.36223468]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK975",
      },
    },
    {
      "type": "Feature",
      "id": "sma8d35be2",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-134.49762367, 12.3372391],
            [-131.06537088, 12.31151156],
            [-131.01483923, 18.65471956],
            [-134.44709202, 18.67966863],
            [-134.49762367, 12.3372391]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK974",
      },
    },
    {
      "type": "Feature",
      "id": "sm5849bc4c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-131.06537088, 12.31151156],
            [-127.72235217, 12.28645048],
            [-127.67182052, 18.63041561],
            [-131.01483923, 18.65471956],
            [-131.06537088, 12.31151156]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK973",
      },
    },
    {
      "type": "Feature",
      "id": "sm800e4469",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-127.72235217, 12.28645048],
            [-124.55645299, 12.26271498],
            [-124.50592134, 18.60739613],
            [-127.67182052, 18.63041561],
            [-127.72235217, 12.28645048]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK972",
      },
    },
    {
      "type": "Feature",
      "id": "sm49862c82",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-124.55645299, 12.26271498],
            [-120.94910361, 12.23566722],
            [-120.89857196, 18.58116304],
            [-124.50592134, 18.60739613],
            [-124.55645299, 12.26271498]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK971",
      },
    },
    {
      "type": "Feature",
      "id": "sm65327074",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-120.94910361, 12.23566722],
            [-117.69262172, 12.21124787],
            [-117.64209007, 18.55747803],
            [-120.89857196, 18.58116304],
            [-120.94910361, 12.23566722]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK970",
      },
    },
    {
      "type": "Feature",
      "id": "smea4142b9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-117.69262172, 12.21124787],
            [-114.43209396, 12.18679593],
            [-114.38156231, 18.5337603],
            [-117.64209007, 18.55747803],
            [-117.69262172, 12.21124787]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK969",
      },
    },
    {
      "type": "Feature",
      "id": "smf3659da5",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-114.43209396, 12.18679593],
            [-110.91465297, 12.16041477],
            [-110.86412132, 18.50817004],
            [-114.38156231, 18.5337603],
            [-114.43209396, 12.18679593]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK968",
      },
    },
    {
      "type": "Feature",
      "id": "sm102f9505",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-136.64187141, -3.71916944],
            [-136.5278816, 3.66605208],
            [-139.67187827, 3.71444505],
            [-139.78608892, -3.68506448],
            [-136.64187141, -3.71916944]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK954",
      },
    },
    {
      "type": "Feature",
      "id": "sm87468faf",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-136.5278816, 3.66605208],
            [-136.64187141, -3.71916944],
            [-133.38463891, -3.75449887],
            [-133.30446471, 3.61643394],
            [-136.5278816, 3.66605208]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK953",
      },
    },
    {
      "type": "Feature",
      "id": "sm937b82d3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-133.30446471, 3.61643394],
            [-133.38463891, -3.75449887],
            [-130.21569648, -3.78886929],
            [-130.13567914, 3.56765409],
            [-133.30446471, 3.61643394]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK952",
      },
    },
    {
      "type": "Feature",
      "id": "sm67c23fab",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-130.13567914, 3.56765409],
            [-130.21569648, -3.78886929],
            [-127.09308222, -3.8227359],
            [-127.01321944, 3.51958484],
            [-130.13567914, 3.56765409]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK951",
      },
    },
    {
      "type": "Feature",
      "id": "smba26cc15",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-127.01321944, 3.51958484],
            [-127.09308222, -3.8227359],
            [-123.79231943, -3.85853318],
            [-123.71262003, 3.4687705],
            [-127.01321944, 3.51958484]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK950",
      },
    },
    {
      "type": "Feature",
      "id": "sm67379510",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-123.71262003, 3.4687705],
            [-123.79231943, -3.85853318],
            [-120.66588429, -3.89243846],
            [-120.58633965, 3.42063736],
            [-123.71262003, 3.4687705]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK949",
      },
    },
    {
      "type": "Feature",
      "id": "smbd2631e5",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-120.58633965, 3.42063736],
            [-120.66588429, -3.89243846],
            [-117.32595748, -3.92865749],
            [-117.24657816, 3.36921473],
            [-120.58633965, 3.42063736]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK948",
      },
    },
    {
      "type": "Feature",
      "id": "sm69b9e3c9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-117.24657816, 3.36921473],
            [-117.32595748, -3.92865749],
            [-114.07247808, -3.96393755],
            [-113.9932598, 3.31912046],
            [-117.24657816, 3.36921473]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK947",
      },
    },
    {
      "type": "Feature",
      "id": "sm43a64f7e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-113.9932598, 3.31912046],
            [-114.07247808, -3.96393755],
            [-110.9106998, -3.99822178],
            [-110.83163802, 3.27043569],
            [-113.9932598, 3.31912046]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK946",
      },
    },
    {
      "type": "Feature",
      "id": "sm98cdff55",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.83163802, 3.27043569],
            [-110.9106998, -3.99822178],
            [-108.74545485, -4.02169943],
            [-107.69076735, -2.88121272],
            [-107.55902281, 3.22003928],
            [-110.83163802, 3.27043569]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK945",
      },
    },
    {
      "type": "Feature",
      "id": "smb67ff576",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.66598927, 10.1062577],
            [-110.77222748, 3.26952082],
            [-107.55902281, 3.22003928],
            [-107.42709547, 9.29334968],
            [-108.48178297, 10.07308916],
            [-110.66598927, 10.1062577]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK966",
      },
    },
    {
      "type": "Feature",
      "id": "smdd213bc5",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.77222748, 3.26952082],
            [-110.66598927, 10.1062577],
            [-114.0793552, 10.15808494],
            [-114.18559341, 3.32208206],
            [-110.77222748, 3.26952082]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK965",
      },
    },
    {
      "type": "Feature",
      "id": "sm83f50591",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-114.18559341, 3.32208206],
            [-114.0793552, 10.15808494],
            [-117.29800724, 10.20694803],
            [-117.40424546, 3.3716424],
            [-114.18559341, 3.32208206]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK964",
      },
    },
    {
      "type": "Feature",
      "id": "sme586a02a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-117.40424546, 3.3716424],
            [-117.29800724, 10.20694803],
            [-120.53834436, 10.25613274],
            [-120.64458257, 3.4215341],
            [-117.40424546, 3.3716424]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK963",
      },
    },
    {
      "type": "Feature",
      "id": "sm2126f8c8",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-120.64458257, 3.4215341],
            [-120.53834436, 10.25613274],
            [-123.69216707, 10.30399693],
            [-123.79840529, 3.47009123],
            [-120.64458257, 3.4215341]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK962",
      },
    },
    {
      "type": "Feature",
      "id": "sma15ef2d2",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-123.79840529, 3.47009123],
            [-123.69216707, 10.30399693],
            [-126.77031792, 10.35070567],
            [-126.87655613, 3.51748089],
            [-123.79840529, 3.47009123]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK961",
      },
    },
    {
      "type": "Feature",
      "id": "sm8fb35d0b",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-126.87655613, 3.51748089],
            [-126.77031792, 10.35070567],
            [-129.92820658, 10.39861715],
            [-130.0344448, 3.56609565],
            [-126.87655613, 3.51748089]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK960",
      },
    },
    {
      "type": "Feature",
      "id": "sm2a2f1589",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-130.0344448, 3.56609565],
            [-129.92820658, 10.39861715],
            [-133.26183469, 10.44918697],
            [-133.3680729, 3.61741308],
            [-130.0344448, 3.56609565]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK959",
      },
    },
    {
      "type": "Feature",
      "id": "sm217800e6",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-133.3680729, 3.61741308],
            [-133.26183469, 10.44918697],
            [-136.50217181, 10.49833372],
            [-136.60841002, 3.66729162],
            [-133.3680729, 3.61741308]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK958",
      },
    },
    {
      "type": "Feature",
      "id": "smf0dc2a29",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-136.60841002, 3.66729162],
            [-136.50217181, 10.49833372],
            [-139.56564005, 10.54479068],
            [-139.67187827, 3.71444505],
            [-136.60841002, 3.66729162]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK957",
      },
    },
    {
      "type": "Feature",
      "id": "sm2a98c0e1",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-143.18212365, 4.43058154],
            [-139.66164196, 4.37644135],
            [-139.56564005, 10.54479068],
            [-142.6712361, 10.59187935],
            [-143.81381422, 9.64012378],
            [-143.18212365, 4.43058154]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK956",
      },
    },
    {
      "type": "Feature",
      "id": "smd2944fbd",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-139.66164196, 4.37644135],
            [-143.18212365, 4.43058154],
            [-142.40756422, -2.00311337],
            [-141.08920485, -3.67092935],
            [-139.78608892, -3.68506448],
            [-139.66164196, 4.37644135]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK955",
      },
    },
    {
      "type": "Feature",
      "id": "sm8974fa9a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.2137412, 16.10464601],
            [-94.18834099, 19.88450847],
            [-99.85087746, 19.91852997],
            [-99.87627768, 16.1394054],
            [-94.2137412, 16.10464601]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK935",
      },
    },
    {
      "type": "Feature",
      "id": "sm369a871a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-99.9744678, 0.99037171],
            [-99.99977604, -2.96898882],
            [-95.29818922, -2.96898882],
            [-94.33139235, -2.09094789],
            [-94.31193132, 0.95419457],
            [-99.9744678, 0.99037171]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK930",
      },
    },
    {
      "type": "Feature",
      "id": "smb6b4e242",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.31193132, 0.95419457],
            [-94.28948612, 4.46239405],
            [-99.9520226, 4.49846582],
            [-99.9744678, 0.99037171],
            [-94.31193132, 0.95419457]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK931",
      },
    },
    {
      "type": "Feature",
      "id": "sm0c6c817d",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.28948612, 4.46239405],
            [-94.26309193, 8.56551813],
            [-99.92562841, 8.60129522],
            [-99.9520226, 4.49846582],
            [-94.28948612, 4.46239405]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK932",
      },
    },
    {
      "type": "Feature",
      "id": "sm1f3d9430",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.26309193, 8.56551813],
            [-94.23785319, 12.4483582],
            [-99.90038967, 12.48368751],
            [-99.92562841, 8.60129522],
            [-94.26309193, 8.56551813]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK933",
      },
    },
    {
      "type": "Feature",
      "id": "sm65c20e8f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.23785319, 12.4483582],
            [-94.2137412, 16.10464601],
            [-99.87627768, 16.1394054],
            [-99.90038967, 12.48368751],
            [-94.23785319, 12.4483582]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK934",
      },
    },
    {
      "type": "Feature",
      "id": "sm6673f8ab",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.65156272, 0.81326054],
            [-105.66928297, -1.91527413],
            [-104.61459547, -2.96898882],
            [-99.99977604, -2.96898882],
            [-99.97583138, 0.77699738],
            [-105.65156272, 0.81326054]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK944",
      },
    },
    {
      "type": "Feature",
      "id": "sm775c25f6",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-99.87639968, 16.12106339],
            [-99.85087746, 19.91852997],
            [-105.52458042, 19.95261122],
            [-105.55051694, 16.15589097],
            [-99.87639968, 16.12106339]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK939",
      },
    },
    {
      "type": "Feature",
      "id": "sm57f79f6c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.55051694, 16.15589097],
            [-105.57620185, 12.3227475],
            [-99.90167431, 12.28732152],
            [-99.87639968, 16.12106339],
            [-105.55051694, 16.15589097]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK940",
      },
    },
    {
      "type": "Feature",
      "id": "smff96b435",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.57620185, 12.3227475],
            [-105.60302814, 8.25886272],
            [-99.92807209, 8.22297545],
            [-99.90167431, 12.28732152],
            [-105.57620185, 12.3227475]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK941",
      },
    },
    {
      "type": "Feature",
      "id": "sm9d2d133e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.60302814, 8.25886272],
            [-105.6275826, 4.50158724],
            [-99.95223432, 4.46543401],
            [-99.92807209, 8.22297545],
            [-105.60302814, 8.25886272]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK942",
      },
    },
    {
      "type": "Feature",
      "id": "sm68364129",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.6275826, 4.50158724],
            [-105.65156272, 0.81326054],
            [-99.97583138, 0.77699738],
            [-99.95223432, 4.46543401],
            [-105.6275826, 4.50158724]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK943",
      },
    },
    {
      "type": "Feature",
      "id": "smca150fc3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-101.41875264, 25.76373212],
            [-104.7024861, 25.81606236],
            [-105.49350172, 24.38347521],
            [-105.52458042, 19.95261122],
            [-101.45923831, 19.92819195],
            [-101.41875264, 25.76373212]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK938",
      },
    },
    {
      "type": "Feature",
      "id": "sm926c4792",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-97.76517488, 19.90599956],
            [-94.18834099, 19.88450847],
            [-94.1556111, 24.6233996],
            [-94.77084547, 25.65771911],
            [-97.72495612, 25.70483945],
            [-97.76517488, 19.90599956]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK936",
      },
    },
    {
      "type": "Feature",
      "id": "sm9a66b2c0",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-97.72495612, 25.70483945],
            [-101.41875264, 25.76373212],
            [-101.45923831, 19.92819195],
            [-97.76517488, 19.90599956],
            [-97.72495612, 25.70483945]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK937",
      },
    },
    {
      "type": "Feature",
      "id": "smae13dfb3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-134.8426166, -18.78253411],
            [-134.8626127, -11.96180016],
            [-138.07002428, -11.92769186],
            [-138.1474462, -18.79137241],
            [-134.8426166, -18.78253411]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK871",
      },
    },
    {
      "type": "Feature",
      "id": "sme056e980",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-134.8626127, -11.96180016],
            [-134.8426166, -18.78253411],
            [-131.32478516, -18.77312566],
            [-131.34464517, -11.99920603],
            [-134.8626127, -11.96180016]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK870",
      },
    },
    {
      "type": "Feature",
      "id": "smee2defe4",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-131.34464517, -11.99920603],
            [-131.32478516, -18.77312566],
            [-127.9876991, -18.76420013],
            [-128.00743001, -12.0346852],
            [-131.34464517, -11.99920603]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK869",
      },
    },
    {
      "type": "Feature",
      "id": "smc8a6f049",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-128.00743001, -12.0346852],
            [-127.9876991, -18.76420013],
            [-124.56073691, -18.75503372],
            [-124.58033525, -12.07111504],
            [-128.00743001, -12.0346852]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK868",
      },
    },
    {
      "type": "Feature",
      "id": "sm5fb09d4f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-124.58033525, -12.07111504],
            [-124.56073691, -18.75503372],
            [-121.21992671, -18.74609727],
            [-121.2393958, -12.10662429],
            [-124.58033525, -12.07111504]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK867",
      },
    },
    {
      "type": "Feature",
      "id": "sm9349355d",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-121.2393958, -12.10662429],
            [-121.21992671, -18.74609727],
            [-118.10872375, -18.73777458],
            [-118.03428428, -12.14068545],
            [-121.2393958, -12.10662429]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK866",
      },
    },
    {
      "type": "Feature",
      "id": "sm03f7f71c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-118.03428428, -12.14068545],
            [-118.10872375, -18.73777458],
            [-114.67228586, -18.72858139],
            [-114.59835786, -12.17719467],
            [-118.03428428, -12.14068545]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK865",
      },
    },
    {
      "type": "Feature",
      "id": "smac8b9d82",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-114.59835786, -12.17719467],
            [-114.67228586, -18.72858139],
            [-111.24826768, -18.71942092],
            [-111.17484929, -12.21356695],
            [-114.59835786, -12.17719467]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK864",
      },
    },
    {
      "type": "Feature",
      "id": "sm53445885",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-111.17484929, -12.21356695],
            [-111.24826768, -18.71942092],
            [-108.65756422, -18.71248954],
            [-107.77865797, -17.79435343],
            [-107.6461498, -12.25105158],
            [-111.17484929, -12.21356695]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK863",
      },
    },
    {
      "type": "Feature",
      "id": "sm9191d58e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-111.13802141, -5.74524946],
            [-111.20923721, -12.21320163],
            [-107.6461498, -12.25105158],
            [-107.5149861, -6.64676579],
            [-108.5696736, -5.77302537],
            [-111.13802141, -5.74524946]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK882",
      },
    },
    {
      "type": "Feature",
      "id": "sma2593ace",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-111.20923721, -12.21320163],
            [-111.13802141, -5.74524946],
            [-114.48129149, -5.70909096],
            [-114.5525073, -12.17768183],
            [-111.20923721, -12.21320163]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK881",
      },
    },
    {
      "type": "Feature",
      "id": "sm2bf8d2e2",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-114.5525073, -12.17768183],
            [-114.48129149, -5.70909096],
            [-117.9926803, -5.67111175],
            [-118.0638961, -12.14037077],
            [-114.5525073, -12.17768183]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK880",
      },
    },
    {
      "type": "Feature",
      "id": "smdb704224",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-118.0638961, -12.14037077],
            [-117.9926803, -5.67111175],
            [-121.42000974, -5.63403932],
            [-121.49122555, -12.10394787],
            [-118.0638961, -12.14037077]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK879",
      },
    },
    {
      "type": "Feature",
      "id": "sm32a8895b",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-121.49122555, -12.10394787],
            [-121.42000974, -5.63403932],
            [-124.40793798, -5.60171783],
            [-124.47915378, -12.07219052],
            [-121.49122555, -12.10394787]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK878",
      },
    },
    {
      "type": "Feature",
      "id": "sm067d76b6",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-124.47915378, -12.07219052],
            [-124.40793798, -5.60171783],
            [-128.01484878, -5.56269821],
            [-128.08606459, -12.03384926],
            [-124.47915378, -12.07219052]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK877",
      },
    },
    {
      "type": "Feature",
      "id": "sm05952828",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-128.08606459, -12.03384926],
            [-128.01484878, -5.56269821],
            [-131.35525321, -5.52655935],
            [-131.42646901, -11.99833607],
            [-128.08606459, -12.03384926]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK876",
      },
    },
    {
      "type": "Feature",
      "id": "smc5bf7d9a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-131.42646901, -11.99833607],
            [-131.35525321, -5.52655935],
            [-134.52180759, -5.49229928],
            [-134.59302339, -11.96466683],
            [-131.42646901, -11.99833607]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK875",
      },
    },
    {
      "type": "Feature",
      "id": "sm30382bfe",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-134.59302339, -11.96466683],
            [-134.52180759, -5.49229928],
            [-137.99880847, -5.45467812],
            [-138.07002428, -11.92769186],
            [-134.59302339, -11.96466683]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK874",
      },
    },
    {
      "type": "Feature",
      "id": "sm39b706ee",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-141.68519681, -11.16098449],
            [-138.06194644, -11.19962023],
            [-137.99880847, -5.45467812],
            [-140.9134236, -5.42314015],
            [-142.05600172, -6.4721353],
            [-141.68519681, -11.16098449]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK873",
      },
    },
    {
      "type": "Feature",
      "id": "sm2bd7d3d4",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-138.06194644, -11.19962023],
            [-141.68519681, -11.16098449],
            [-141.17709547, -17.45929772],
            [-139.77084547, -18.79571379],
            [-138.1474462, -18.79137241],
            [-138.06194644, -11.19962023]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK872",
      },
    },
    {
      "type": "Feature",
      "id": "smbaa09af0",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-133.3612589, -32.73081389],
            [-133.30266236, -26.74858654],
            [-136.43462896, -26.72481509],
            [-136.49360739, -32.74622188],
            [-133.3612589, -32.73081389]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK826",
      },
    },
    {
      "type": "Feature",
      "id": "sm20699afb",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-133.30266236, -26.74858654],
            [-133.3612589, -32.73081389],
            [-130.01676726, -32.71435943],
            [-129.95857846, -26.77396246],
            [-133.30266236, -26.74858654]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK825",
      },
    },
    {
      "type": "Feature",
      "id": "sm9878bb80",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-129.95857846, -26.77396246],
            [-130.01676726, -32.71435943],
            [-126.76465251, -32.69835654],
            [-126.70686019, -26.79863205],
            [-129.95857846, -26.77396246]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK824",
      },
    },
    {
      "type": "Feature",
      "id": "sm6d11ff84",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-126.70686019, -26.79863205],
            [-126.76465251, -32.69835654],
            [-123.42016086, -32.68189609],
            [-123.3627763, -26.82399679],
            [-126.70686019, -26.79863205]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK823",
      },
    },
    {
      "type": "Feature",
      "id": "sm2436b998",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-123.3627763, -26.82399679],
            [-123.42016086, -32.68189609],
            [-120.16356421, -32.66586531],
            [-120.10657668, -26.84868947],
            [-123.3627763, -26.82399679]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK822",
      },
    },
    {
      "type": "Feature",
      "id": "sm159ead15",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-120.10657668, -26.84868947],
            [-120.16356421, -32.66586531],
            [-116.91294342, -32.64986108],
            [-116.8563522, -26.87333147],
            [-120.10657668, -26.84868947]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK821",
      },
    },
    {
      "type": "Feature",
      "id": "sm4e07ab6e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-116.8563522, -26.87333147],
            [-116.91294342, -32.64986108],
            [-113.65858772, -32.63383559],
            [-113.60239326, -26.8979964],
            [-116.8563522, -26.87333147]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK820",
      },
    },
    {
      "type": "Feature",
      "id": "sm85142051",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-113.60239326, -26.8979964],
            [-113.65858772, -32.63383559],
            [-110.66866398, -32.61910972],
            [-110.61283404, -26.92065245],
            [-113.60239326, -26.8979964]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK819",
      },
    },
    {
      "type": "Feature",
      "id": "smb81b4000",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.61283404, -26.92065245],
            [-110.66866398, -32.61910972],
            [-107.95443922, -32.60573961],
            [-107.1634236, -31.86230273],
            [-107.11897594, -26.9471245],
            [-110.61283404, -26.92065245]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK818",
      },
    },
    {
      "type": "Feature",
      "id": "sm8c1d436a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.31807253, -20.51837363],
            [-110.3775631, -26.92243523],
            [-107.11897594, -26.9471245],
            [-107.07553297, -21.92638304],
            [-108.39389235, -20.53368824],
            [-110.31807253, -20.51837363]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK837",
      },
    },
    {
      "type": "Feature",
      "id": "sm8f38cd62",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-110.3775631, -26.92243523],
            [-110.31807253, -20.51837363],
            [-113.74406556, -20.49110224],
            [-113.80355614, -26.89647175],
            [-110.3775631, -26.92243523]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK836",
      },
    },
    {
      "type": "Feature",
      "id": "sm33cd26a7",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-113.80355614, -26.89647175],
            [-113.74406556, -20.49110224],
            [-116.99354315, -20.46523146],
            [-117.05303373, -26.87184045],
            [-113.80355614, -26.89647175]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK835",
      },
    },
    {
      "type": "Feature",
      "id": "smdde641f8",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-117.05303373, -26.87184045],
            [-116.99354315, -20.46523146],
            [-120.15737714, -20.44003834],
            [-120.21686772, -26.84785318],
            [-117.05303373, -26.87184045]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK834",
      },
    },
    {
      "type": "Feature",
      "id": "sm856928a5",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-120.21686772, -26.84785318],
            [-120.15737714, -20.44003834],
            [-123.31822357, -20.41486489],
            [-123.37771414, -26.82388349],
            [-120.21686772, -26.84785318]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK833",
      },
    },
    {
      "type": "Feature",
      "id": "smcadf1c5f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-123.37771414, -26.82388349],
            [-123.31822357, -20.41486489],
            [-126.65633233, -20.38827522],
            [-126.7158229, -26.79856406],
            [-123.37771414, -26.82388349]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK832",
      },
    },
    {
      "type": "Feature",
      "id": "sm53315e86",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-126.7158229, -26.79856406],
            [-126.65633233, -20.38827522],
            [-130.0026569, -20.36161551],
            [-130.06214747, -26.77317664],
            [-126.7158229, -26.79856406]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK831",
      },
    },
    {
      "type": "Feature",
      "id": "sm83a84d3c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-130.06214747, -26.77317664],
            [-130.0026569, -20.36161551],
            [-133.25064071, -20.33573486],
            [-133.31013128, -26.74852986],
            [-130.06214747, -26.77317664]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK830",
      },
    },
    {
      "type": "Feature",
      "id": "smd6c7b9ba",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-133.31013128, -26.74852986],
            [-133.25064071, -20.33573486],
            [-136.37513839, -20.31083409],
            [-136.43462896, -26.72481509],
            [-133.31013128, -26.74852986]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK829",
      },
    },
    {
      "type": "Feature",
      "id": "sma83b71ac",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-140.28351878, -25.86490437],
            [-136.42675592, -25.89439428],
            [-136.37513839, -20.31083409],
            [-139.41928297, -20.28656984],
            [-140.82553297, -21.10874109],
            [-140.28351878, -25.86490437]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK828",
      },
    },
    {
      "type": "Feature",
      "id": "sm73f804fe",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-136.42675592, -25.89439428],
            [-140.28351878, -25.86490437],
            [-139.59506422, -31.6380895],
            [-138.01303297, -32.75369496],
            [-136.49360739, -32.74622188],
            [-136.42675592, -25.89439428]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK827",
      },
    },
    {
      "type": "Feature",
      "id": "sm90e8daa4",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-95.8511867, -19.91585609],
            [-99.0888578, -19.89869598],
            [-99.11222944, -26.27255563],
            [-95.87453361, -26.28234141],
            [-95.8511867, -19.91585609]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK839",
      },
    },
    {
      "type": "Feature",
      "id": "smeacba3af",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-66.69251134, -26.3705054],
            [-62.8665486, -26.38205932],
            [-62.8665486, -22.04862993],
            [-64.80014235, -20.08033623],
            [-66.66938734, -20.07043954],
            [-66.69251134, -26.3705054]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK849",
      },
    },
    {
      "type": "Feature",
      "id": "sm149edab4",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-66.66938734, -20.07043954],
            [-70.09735284, -20.0522886],
            [-70.12050302, -26.36015232],
            [-66.69251134, -26.3705054],
            [-66.66938734, -20.07043954]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK848",
      },
    },
    {
      "type": "Feature",
      "id": "sm7e41c244",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-70.09735284, -20.0522886],
            [-73.26194773, -20.03553034],
            [-73.28512209, -26.35059384],
            [-70.12050302, -26.36015232],
            [-70.09735284, -20.0522886]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK847",
      },
    },
    {
      "type": "Feature",
      "id": "sm82d77bc9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-73.26194773, -20.03553034],
            [-76.51413532, -20.01830636],
            [-76.53733452, -26.34076997],
            [-73.28512209, -26.35059384],
            [-73.26194773, -20.03553034]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK846",
      },
    },
    {
      "type": "Feature",
      "id": "sm1b04d11c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-76.51413532, -20.01830636],
            [-79.59113751, -20.00200845],
            [-79.61436022, -26.33147452],
            [-76.53733452, -26.34076997],
            [-76.51413532, -20.01830636]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK845",
      },
    },
    {
      "type": "Feature",
      "id": "smb0c3c842",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-79.59113751, -20.00200845],
            [-83.10462193, -19.98339656],
            [-83.12787147, -26.32085956],
            [-79.61436022, -26.33147452],
            [-79.59113751, -20.00200845]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK844",
      },
    },
    {
      "type": "Feature",
      "id": "sm1556a144",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-83.10462193, -19.98339656],
            [-86.26921682, -19.96663096],
            [-86.29249053, -26.31129784],
            [-83.12787147, -26.32085956],
            [-83.10462193, -19.98339656]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK843",
      },
    },
    {
      "type": "Feature",
      "id": "smee3df168",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-86.26921682, -19.96663096],
            [-89.69747857, -19.94846647],
            [-89.72077848, -26.30093857],
            [-86.29249053, -26.31129784],
            [-86.26921682, -19.96663096]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK842",
      },
    },
    {
      "type": "Feature",
      "id": "sme6a86093",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-89.69747857, -19.94846647],
            [-92.68511053, -19.93263494],
            [-92.70843326, -26.29191],
            [-89.72077848, -26.30093857],
            [-89.69747857, -19.94846647]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK841",
      },
    },
    {
      "type": "Feature",
      "id": "smec417bee",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-92.68511053, -19.93263494],
            [-95.8511867, -19.91585609],
            [-95.87453361, -26.28234141],
            [-92.70843326, -26.29191],
            [-92.68511053, -19.93263494]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK840",
      },
    },
    {
      "type": "Feature",
      "id": "sm73a1df26",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-66.60580508, -32.78552868],
            [-64.80014235, -32.79064548],
            [-62.8665486, -31.75026399],
            [-62.8665486, -26.38205932],
            [-66.58092114, -26.3708424],
            [-66.60580508, -32.78552868]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK788",
      },
    },
    {
      "type": "Feature",
      "id": "sm562a2640",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-66.58092114, -26.3708424],
            [-70.09561742, -26.36022748],
            [-70.12050136, -32.77556807],
            [-66.60580508, -32.78552868],
            [-66.58092114, -26.3708424]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK789",
      },
    },
    {
      "type": "Feature",
      "id": "sm46b26386",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-70.09561742, -26.36022748],
            [-73.43749702, -26.35013359],
            [-73.46238096, -32.76609618],
            [-70.12050136, -32.77556807],
            [-70.09561742, -26.36022748]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK790",
      },
    },
    {
      "type": "Feature",
      "id": "sm48af9dee",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-73.43749702, -26.35013359],
            [-76.60241235, -26.34057339],
            [-76.62729628, -32.75712493],
            [-73.46238096, -32.76609618],
            [-73.43749702, -26.35013359]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK791",
      },
    },
    {
      "type": "Feature",
      "id": "smfd48f0d6",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-76.60241235, -26.34057339],
            [-79.76792018, -26.33101061],
            [-79.79280412, -32.7481511],
            [-76.62729628, -32.75712493],
            [-76.60241235, -26.34057339]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK792",
      },
    },
    {
      "type": "Feature",
      "id": "smed6d686f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-79.76792018, -26.33101061],
            [-83.01865133, -26.32118955],
            [-83.04353527, -32.73893473],
            [-79.79280412, -32.7481511],
            [-79.76792018, -26.33101061]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK793",
      },
    },
    {
      "type": "Feature",
      "id": "smf7ff588b",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-83.01865133, -26.32118955],
            [-86.26908622, -26.31136856],
            [-86.29397016, -32.72971825],
            [-83.04353527, -32.73893473],
            [-83.01865133, -26.32118955]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK794",
      },
    },
    {
      "type": "Feature",
      "id": "sm170acd70",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-86.26908622, -26.31136856],
            [-89.52070614, -26.30154315],
            [-89.54559008, -32.72049745],
            [-86.29397016, -32.72971825],
            [-86.26908622, -26.31136856]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK795",
      },
    },
    {
      "type": "Feature",
      "id": "sme7cd6520",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-89.52070614, -26.30154315],
            [-92.59476926, -26.2922535],
            [-92.6196532, -32.71177928],
            [-89.54559008, -32.72049745],
            [-89.52070614, -26.30154315]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK796",
      },
    },
    {
      "type": "Feature",
      "id": "sm1fdc44f0",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-92.59476926, -26.2922535],
            [-96.02157592, -26.281897],
            [-96.04645986, -32.70205971],
            [-92.6196532, -32.71177928],
            [-92.59476926, -26.2922535]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK797",
      },
    },
    {
      "type": "Feature",
      "id": "sm72b960ff",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-96.02157592, -26.281897],
            [-99.11222944, -26.27255563],
            [-99.13711338, -32.69329268],
            [-96.04645986, -32.70205971],
            [-96.02157592, -26.281897]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK798",
      },
    },
    {
      "type": "Feature",
      "id": "sm7c0f56d3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.08495514, -29.14069442],
            [-105.0540486, -31.48831282],
            [-103.91147047, -32.67974792],
            [-99.13711338, -32.69329268],
            [-99.12321892, -29.15824507],
            [-105.08495514, -29.14069442]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK800",
      },
    },
    {
      "type": "Feature",
      "id": "sm6ab5f937",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-99.12321892, -29.15824507],
            [-99.1101896, -25.72865205],
            [-105.12888194, -25.71037425],
            [-105.08495514, -29.14069442],
            [-99.12321892, -29.15824507]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK799",
      },
    },
    {
      "type": "Feature",
      "id": "sm0aa03b15",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-99.1101896, -25.72865205],
            [-99.0888578, -19.89869598],
            [-103.77963453, -19.8738309],
            [-105.18588453, -21.10874109],
            [-105.12888194, -25.71037425],
            [-99.1101896, -25.72865205]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK838",
      },
    },
    {
      "type": "Feature",
      "id": "smb54fd7c9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-96.48789989, -18.12646186],
            [-96.42224794, -11.71597632],
            [-99.94399953, -11.68186936],
            [-100.00965888, -18.09406872],
            [-96.48789989, -18.12646186]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK860",
      },
    },
    {
      "type": "Feature",
      "id": "sm97eebaa8",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-96.42224794, -11.71597632],
            [-96.48789989, -18.12646186],
            [-93.14837576, -18.15717326],
            [-93.08273085, -11.74831452],
            [-96.42224794, -11.71597632]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK859",
      },
    },
    {
      "type": "Feature",
      "id": "sm618ccb9f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-93.08273085, -11.74831452],
            [-93.14837576, -18.15717326],
            [-89.76882178, -18.1882473],
            [-89.70318397, -11.78103648],
            [-93.08273085, -11.74831452]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK858",
      },
    },
    {
      "type": "Feature",
      "id": "smdee1f1cd",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-89.70318397, -11.78103648],
            [-89.76882178, -18.1882473],
            [-86.55894806, -18.21775605],
            [-86.49331701, -11.81211193],
            [-89.70318397, -11.78103648]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK857",
      },
    },
    {
      "type": "Feature",
      "id": "smba8d2ea4",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-86.49331701, -11.81211193],
            [-86.55894806, -18.21775605],
            [-83.22029311, -18.2484434],
            [-83.15466907, -11.8444304],
            [-86.49331701, -11.81211193]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK856",
      },
    },
    {
      "type": "Feature",
      "id": "sm1ab7e840",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-83.15466907, -11.8444304],
            [-83.22029311, -18.2484434],
            [-79.69544375, -18.28083628],
            [-79.62982712, -11.8785471],
            [-83.15466907, -11.8444304]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK855",
      },
    },
    {
      "type": "Feature",
      "id": "smf0a8b513",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-79.62982712, -11.8785471],
            [-79.69544375, -18.28083628],
            [-76.44380184, -18.31071306],
            [-76.37819205, -11.91001566],
            [-79.62982712, -11.8785471]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK854",
      },
    },
    {
      "type": "Feature",
      "id": "sm5aee81a8",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-76.37819205, -11.91001566],
            [-76.44380184, -18.31071306],
            [-73.23740478, -18.34016907],
            [-73.17180174, -11.94104278],
            [-76.37819205, -11.91001566]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK853",
      },
    },
    {
      "type": "Feature",
      "id": "sm8c027323",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-73.17180174, -11.94104278],
            [-73.23740478, -18.34016907],
            [-69.93877969, -18.37046711],
            [-69.87318359, -11.97295865],
            [-73.17180174, -11.94104278]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK852",
      },
    },
    {
      "type": "Feature",
      "id": "sm16961cf3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.87318359, -11.97295865],
            [-69.93877969, -18.37046711],
            [-66.42609864, -18.40272541],
            [-66.36050993, -12.00694148],
            [-69.87318359, -11.97295865]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK851",
      },
    },
    {
      "type": "Feature",
      "id": "smb6454d57",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-66.36050993, -12.00694148],
            [-66.42609864, -18.40272541],
            [-64.44857985, -18.42088307],
            [-62.8665486, -17.08162259],
            [-63.03742627, -12.0390862],
            [-66.36050993, -12.00694148]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK850",
      },
    },
    {
      "type": "Feature",
      "id": "sma0dc527c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-66.31571079, -5.18596773],
            [-66.38397732, -12.00671446],
            [-63.03742627, -12.0390862],
            [-63.2181111, -6.60311393],
            [-64.44857985, -5.204358],
            [-66.31571079, -5.18596773]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK894",
      },
    },
    {
      "type": "Feature",
      "id": "sme0eb2f37",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-66.38397732, -12.00671446],
            [-66.31571079, -5.18596773],
            [-70.0041485, -5.14963695],
            [-70.07241505, -11.97103109],
            [-66.38397732, -12.00671446]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK893",
      },
    },
    {
      "type": "Feature",
      "id": "smc894f37e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-70.07241505, -11.97103109],
            [-70.0041485, -5.14963695],
            [-73.25839106, -5.11758124],
            [-73.32665761, -11.93954437],
            [-70.07241505, -11.97103109]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK892",
      },
    },
    {
      "type": "Feature",
      "id": "sm768e50e4",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-73.32665761, -11.93954437],
            [-73.25839106, -5.11758124],
            [-76.38124516, -5.08681825],
            [-76.4495117, -11.90932547],
            [-73.32665761, -11.93954437]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK891",
      },
    },
    {
      "type": "Feature",
      "id": "sm5bc8e394",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-76.4495117, -11.90932547],
            [-76.38124516, -5.08681825],
            [-79.80516763, -5.05308777],
            [-79.87343418, -11.87618937],
            [-76.4495117, -11.90932547]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK890",
      },
    },
    {
      "type": "Feature",
      "id": "sm6d4dbdb1",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-79.87343418, -11.87618937],
            [-79.80516763, -5.05308777],
            [-83.01286169, -5.02148585],
            [-83.08112823, -11.84514223],
            [-79.87343418, -11.87618937]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK889",
      },
    },
    {
      "type": "Feature",
      "id": "smb68ccbd0",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-83.08112823, -11.84514223],
            [-83.01286169, -5.02148585],
            [-86.44373747, -4.98768347],
            [-86.51200401, -11.81193102],
            [-83.08112823, -11.84514223]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK888",
      },
    },
    {
      "type": "Feature",
      "id": "sm10e868fc",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-86.51200401, -11.81193102],
            [-86.44373747, -4.98768347],
            [-89.69363421, -4.95566256],
            [-89.76190075, -11.78046799],
            [-86.51200401, -11.81193102]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK887",
      },
    },
    {
      "type": "Feature",
      "id": "smc919f060",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-89.76190075, -11.78046799],
            [-89.69363421, -4.95566256],
            [-92.99051404, -4.92317715],
            [-93.05878058, -11.74854642],
            [-89.76190075, -11.78046799]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK886",
      },
    },
    {
      "type": "Feature",
      "id": "sm984cfd68",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-93.05878058, -11.74854642],
            [-92.99051404, -4.92317715],
            [-96.37136466, -4.88986269],
            [-96.4396312, -11.71580798],
            [-93.05878058, -11.74854642]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK885",
      },
    },
    {
      "type": "Feature",
      "id": "sm25a331e9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-96.4396312, -11.71580798],
            [-96.37136466, -4.88986269],
            [-99.87573299, -4.85532936],
            [-99.94399953, -11.68186936],
            [-96.4396312, -11.71580798]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK884",
      },
    },
    {
      "type": "Feature",
      "id": "smd3712dd0",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.81994398, -10.82771753],
            [-99.93596168, -10.88486926],
            [-99.87573299, -4.85532936],
            [-104.43881422, -4.81036043],
            [-105.66928297, -5.94788784],
            [-105.81994398, -10.82771753]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK883",
      },
    },
    {
      "type": "Feature",
      "id": "sm7beaff90",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-99.97334428, -14.5710842],
            [-105.93533405, -14.51400861],
            [-106.02084547, -17.20760021],
            [-105.31772047, -18.04523386],
            [-100.00965888, -18.09406872],
            [-99.97334428, -14.5710842]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK862",
      },
    },
    {
      "type": "Feature",
      "id": "sm2d13148c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.93533405, -14.51400861],
            [-99.97334428, -14.5710842],
            [-99.93596168, -10.88486926],
            [-105.81994398, -10.82771753],
            [-105.93533405, -14.51400861]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK861",
      },
    },
    {
      "type": "Feature",
      "id": "smb0c1e60c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-68.97092059, 25.44983568],
            [-69.02955097, 19.78831209],
            [-62.82871966, 19.73255707],
            [-62.77865797, 24.58344404],
            [-64.18490797, 25.3801125],
            [-68.97092059, 25.44983568]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK902",
      },
    },
    {
      "type": "Feature",
      "id": "sm1315bbce",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-72.63315752, 19.82070509],
            [-72.5743008, 25.50230351],
            [-75.08334547, 25.53882349],
            [-76.48959547, 24.50349466],
            [-76.50563773, 19.85550766],
            [-72.63315752, 19.82070509]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK904",
      },
    },
    {
      "type": "Feature",
      "id": "sme56f4dfe",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-72.5743008, 25.50230351],
            [-72.63315752, 19.82070509],
            [-69.02955097, 19.78831209],
            [-68.97092059, 25.44983568],
            [-72.5743008, 25.50230351]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK903",
      },
    },
    {
      "type": "Feature",
      "id": "sm3996d96c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-76.51672008, 16.56097673],
            [-69.62077264, 16.49781465],
            [-69.58763349, 19.79332915],
            [-76.50563773, 19.85550766],
            [-76.51672008, 16.56097673]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK905",
      },
    },
    {
      "type": "Feature",
      "id": "sm43521319",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.62077264, 16.49781465],
            [-76.51672008, 16.56097673],
            [-76.52795445, 13.1628209],
            [-69.65436637, 13.09886673],
            [-69.62077264, 16.49781465]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK906",
      },
    },
    {
      "type": "Feature",
      "id": "smc6cf4d3c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.65436637, 13.09886673],
            [-76.52795445, 13.1628209],
            [-76.53807906, 10.05907638],
            [-69.68464164, 9.99459778],
            [-69.65436637, 13.09886673]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK907",
      },
    },
    {
      "type": "Feature",
      "id": "sm623989ac",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.68464164, 9.99459778],
            [-76.53807906, 10.05907638],
            [-76.54846837, 6.84297259],
            [-69.71570842, 6.77815209],
            [-69.68464164, 9.99459778]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK908",
      },
    },
    {
      "type": "Feature",
      "id": "smb3d4fe6b",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.71570842, 6.77815209],
            [-76.54846837, 6.84297259],
            [-76.55941658, 3.43045753],
            [-69.74844646, 3.36549898],
            [-69.71570842, 6.77815209]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK909",
      },
    },
    {
      "type": "Feature",
      "id": "sm54960ac9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.74844646, 3.36549898],
            [-76.55941658, 3.43045753],
            [-76.57035406, 0.00907765],
            [-69.78115241, -0.05578733],
            [-69.74844646, 3.36549898]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK910",
      },
    },
    {
      "type": "Feature",
      "id": "smab15ef11",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.78115241, -0.05578733],
            [-76.57035406, 0.00907765],
            [-76.57748609, -2.2226904],
            [-75.52279859, -3.36389201],
            [-69.81234052, -3.318286],
            [-69.78115241, -0.05578733]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK911",
      },
    },
    {
      "type": "Feature",
      "id": "sm06185491",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-63.02092563, 0.01705857],
            [-69.77983947, 0.08163416],
            [-69.81234052, -3.318286],
            [-64.53647047, -3.27614891],
            [-63.04232984, -2.2226904],
            [-63.02092563, 0.01705857]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK895",
      },
    },
    {
      "type": "Feature",
      "id": "sme25666d1",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.77983947, 0.08163416],
            [-63.02092563, 0.01705857],
            [-62.99075465, 3.17333117],
            [-69.74966848, 3.23780574],
            [-69.77983947, 0.08163416]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK896",
      },
    },
    {
      "type": "Feature",
      "id": "smd3ddd20e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.74966848, 3.23780574],
            [-62.99075465, 3.17333117],
            [-62.95552977, 6.84548149],
            [-69.7144436, 6.90959244],
            [-69.74966848, 3.23780574]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK897",
      },
    },
    {
      "type": "Feature",
      "id": "smd5b9a7f2",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.7144436, 6.90959244],
            [-62.95552977, 6.84548149],
            [-62.92448706, 10.05888972],
            [-69.68340089, 10.12246645],
            [-69.7144436, 6.90959244]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK898",
      },
    },
    {
      "type": "Feature",
      "id": "sm7a830ef1",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.68340089, 10.12246645],
            [-62.92448706, 10.05888972],
            [-62.89183726, 13.40433348],
            [-69.65075109, 13.46714173],
            [-69.68340089, 10.12246645]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK899",
      },
    },
    {
      "type": "Feature",
      "id": "sm484c398b",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.65075109, 13.46714173],
            [-62.89183726, 13.40433348],
            [-62.86255405, 16.36607969],
            [-69.62146788, 16.42802891],
            [-69.65075109, 13.46714173]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK900",
      },
    },
    {
      "type": "Feature",
      "id": "smd5395c7c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-69.62146788, 16.42802891],
            [-62.86255405, 16.36607969],
            [-62.82871966, 19.73255707],
            [-69.58763349, 19.79332915],
            [-69.62146788, 16.42802891]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK901",
      },
    },
    {
      "type": "Feature",
      "id": "sm048a219d",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-92.11959001, 22.34216445],
            [-85.43962267, 22.43792226],
            [-85.4894872, 25.37777485],
            [-90.99154859, 25.30067863],
            [-92.13412672, 24.42349439],
            [-92.11959001, 22.34216445]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK921",
      },
    },
    {
      "type": "Feature",
      "id": "sm9bcecc3c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.43962267, 22.43792226],
            [-92.11959001, 22.34216445],
            [-92.09820908, 19.22427304],
            [-85.38791795, 19.32247783],
            [-85.43962267, 22.43792226]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK922",
      },
    },
    {
      "type": "Feature",
      "id": "sm6e683854",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.38791795, 19.32247783],
            [-92.09820908, 19.22427304],
            [-92.07681942, 16.04477806],
            [-85.33619211, 16.14518763],
            [-85.38791795, 19.32247783]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK923",
      },
    },
    {
      "type": "Feature",
      "id": "sm921ade1f",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.33619211, 16.14518763],
            [-92.07681942, 16.04477806],
            [-92.05597564, 12.89675401],
            [-85.28578635, 12.99904937],
            [-85.33619211, 16.14518763]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK924",
      },
    },
    {
      "type": "Feature",
      "id": "sm0bb693bd",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.28578635, 12.99904937],
            [-92.05597564, 12.89675401],
            [-92.03455978, 9.62065443],
            [-85.23399715, 9.72459062],
            [-85.28578635, 12.99904937]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK925",
      },
    },
    {
      "type": "Feature",
      "id": "sm10b373e3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.23399715, 9.72459062],
            [-92.03455978, 9.62065443],
            [-92.01487762, 6.58124788],
            [-85.18640051, 6.68640691],
            [-85.23399715, 9.72459062]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK926",
      },
    },
    {
      "type": "Feature",
      "id": "smc6645f92",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.18640051, 6.68640691],
            [-92.01487762, 6.58124788],
            [-91.99403384, 3.34224074],
            [-85.13599475, 3.44838027],
            [-85.18640051, 6.68640691]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK927",
      },
    },
    {
      "type": "Feature",
      "id": "sm0386d872",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.13599475, 3.44838027],
            [-91.99403384, 3.34224074],
            [-91.97263545, 0.00598847],
            [-85.08424779, 0.11278511],
            [-85.13599475, 3.44838027]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK928",
      },
    },
    {
      "type": "Feature",
      "id": "smfafca790",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.08424779, 0.11278511],
            [-91.97263545, 0.00598847],
            [-91.95834547, -2.2226904],
            [-90.90365797, -3.27614891],
            [-85.03314647, -3.18161357],
            [-85.08424779, 0.11278511]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK929",
      },
    },
    {
      "type": "Feature",
      "id": "sm9d759f47",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-78.40966078, -0.11281163],
            [-85.07914702, -0.21621408],
            [-85.03314647, -3.18161357],
            [-80.00522047, -3.10063983],
            [-78.42318922, -2.2226904],
            [-78.40966078, -0.11281163]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK912",
      },
    },
    {
      "type": "Feature",
      "id": "smaf3b87dc",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.07914702, -0.21621408],
            [-78.40966078, -0.11281163],
            [-78.38831928, 3.21477367],
            [-85.13074613, 3.11039914],
            [-85.07914702, -0.21621408]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK913",
      },
    },
    {
      "type": "Feature",
      "id": "smfa0c1e7e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.13074613, 3.11039914],
            [-78.38831928, 3.21477367],
            [-78.36569574, 6.73020367],
            [-85.18776221, 6.77363157],
            [-85.13074613, 3.11039914]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK914",
      },
    },
    {
      "type": "Feature",
      "id": "sm21c597c3",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.18776221, 6.77363157],
            [-78.36569574, 6.73020367],
            [-78.34427351, 10.0358872],
            [-85.23723925, 9.93063768],
            [-85.18776221, 6.77363157]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK915",
      },
    },
    {
      "type": "Feature",
      "id": "sm98d86710",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.23723925, 9.93063768],
            [-78.34427351, 10.0358872],
            [-78.32344306, 13.21831348],
            [-85.28760277, 13.11318004],
            [-85.23723925, 9.93063768]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK916",
      },
    },
    {
      "type": "Feature",
      "id": "smff84a90e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.28760277, 13.11318004],
            [-78.32344306, 13.21831348],
            [-78.30311491, 16.28456375],
            [-85.33675182, 16.17986218],
            [-85.28760277, 13.11318004]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK917",
      },
    },
    {
      "type": "Feature",
      "id": "smdeae08af",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.33675182, 16.17986218],
            [-78.30311491, 16.28456375],
            [-78.28172974, 19.45917818],
            [-85.38845654, 19.35525683],
            [-85.33675182, 16.17986218]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK918",
      },
    },
    {
      "type": "Feature",
      "id": "sm05f5e85e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.38845654, 19.35525683],
            [-78.28172974, 19.45917818],
            [-78.26134044, 22.42932337],
            [-85.43775344, 22.32643999],
            [-85.38845654, 19.35525683]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK919",
      },
    },
    {
      "type": "Feature",
      "id": "sm68f6325c",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-85.43775344, 22.32643999],
            [-78.26134044, 22.42932337],
            [-78.24740797, 24.42349439],
            [-79.65365797, 25.45949416],
            [-85.4894872, 25.37777485],
            [-85.43775344, 22.32643999]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK920",
      },
    },
    {
      "type": "Feature",
      "id": "sm29019fab",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-101.49449092, 38.95748598],
            [-101.54683022, 43.72828406],
            [-104.08725172, 43.75232868],
            [-105.58139234, 42.79245823],
            [-105.58139234, 39.75479735],
            [-104.35092359, 38.93920215],
            [-101.49449092, 38.95748598]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1029",
      },
    },
    {
      "type": "Feature",
      "id": "sm6f1d9335",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-101.54683022, 43.72828406],
            [-101.49449092, 38.95748598],
            [-97.76626401, 38.981343],
            [-97.8179487, 43.69297339],
            [-101.54683022, 43.72828406]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1028",
      },
    },
    {
      "type": "Feature",
      "id": "smb241b5e7",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-97.8179487, 43.69297339],
            [-97.76626401, 38.981343],
            [-94.02574049, 39.00527063],
            [-94.07676841, 43.65752536],
            [-97.8179487, 43.69297339]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1027",
      },
    },
    {
      "type": "Feature",
      "id": "smc91acaae",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.07676841, 43.65752536],
            [-94.02574049, 39.00527063],
            [-90.51071528, 39.02774841],
            [-90.56112603, 43.62419523],
            [-94.07676841, 43.65752536]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1026",
      },
    },
    {
      "type": "Feature",
      "id": "smd9a8fbe1",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-90.56112603, 43.62419523],
            [-90.51071528, 39.02774841],
            [-86.59623074, 39.05277223],
            [-86.64595418, 43.5870556],
            [-90.56112603, 43.62419523]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1025",
      },
    },
    {
      "type": "Feature",
      "id": "sm413d6df9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-86.64595418, 43.5870556],
            [-86.59623074, 39.05277223],
            [-82.99350172, 39.07579528],
            [-83.04259258, 43.55285356],
            [-86.64595418, 43.5870556]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1024",
      },
    },
    {
      "type": "Feature",
      "id": "smd59dd7e6",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-72.71386185, 43.45470869],
            [-72.75624367, 27.21104381],
            [-62.86654859, 27.19244779],
            [-62.86654859, 42.27434184],
            [-63.83334547, 43.37019738],
            [-72.71386185, 43.45470869]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1018",
      },
    },
    {
      "type": "Feature",
      "id": "sm8d58af7d",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.36736309, 32.56243356],
            [-98.27399966, 32.43933847],
            [-98.14979208, 37.38636398],
            [-104.43881422, 37.48911239],
            [-105.49350172, 36.57702655],
            [-105.36736309, 32.56243356]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1011",
      },
    },
    {
      "type": "Feature",
      "id": "sm6305b9bc",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-98.33741737, 29.80120347],
            [-105.28757738, 29.92522183],
            [-105.22982984, 27.9714566],
            [-104.43881422, 27.27059702],
            [-98.39695825, 27.2592427],
            [-98.33741737, 29.80120347]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1013",
      },
    },
    {
      "type": "Feature",
      "id": "sm49f88307",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-105.28757738, 29.92522183],
            [-98.33741737, 29.80120347],
            [-98.27399966, 32.43933847],
            [-105.36736309, 32.56243356],
            [-105.28757738, 29.92522183]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1012",
      },
    },
    {
      "type": "Feature",
      "id": "sm8646d3d9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.37803048, 27.25168938],
            [-94.36548986, 32.39495899],
            [-98.27342686, 32.46282984],
            [-98.39695825, 27.2592427],
            [-94.37803048, 27.25168938]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1014",
      },
    },
    {
      "type": "Feature",
      "id": "smfb3174a5",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-86.89548332, 32.2650821],
            [-86.90773237, 27.23764807],
            [-83.08055873, 27.23045376],
            [-83.06845905, 32.19847161],
            [-86.89548332, 32.2650821]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1017",
      },
    },
    {
      "type": "Feature",
      "id": "sm459d37c9",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-86.90773237, 27.23764807],
            [-86.89548332, 32.2650821],
            [-90.58617158, 32.32927335],
            [-90.59856468, 27.24458565],
            [-86.90773237, 27.23764807]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1016",
      },
    },
    {
      "type": "Feature",
      "id": "sm3e793fea",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-90.59856468, 27.24458565],
            [-90.58617158, 32.32927335],
            [-94.36548986, 32.39495899],
            [-94.37803048, 27.25168938],
            [-90.59856468, 27.24458565]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1015",
      },
    },
    {
      "type": "Feature",
      "id": "sm4cd4992e",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-86.79500742, 37.20049545],
            [-86.9186422, 32.26548504],
            [-83.06845905, 32.19847161],
            [-83.05413637, 37.74552746],
            [-83.08139234, 37.13960701],
            [-86.79500742, 37.20049545]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1020",
      },
    },
    {
      "type": "Feature",
      "id": "sm9be36c1a",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.3109841, 32.394012],
            [-94.18734932, 37.32155421],
            [-98.14979208, 37.38636398],
            [-98.27342686, 32.46282984],
            [-94.3109841, 32.394012]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1023",
      },
    },
    {
      "type": "Feature",
      "id": "sm31d91a2d",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-94.18734932, 37.32155421],
            [-94.3109841, 32.394012],
            [-90.44001013, 32.32673206],
            [-90.31637535, 37.25818648],
            [-94.18734932, 37.32155421]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1022",
      },
    },
    {
      "type": "Feature",
      "id": "smbd694b51",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-90.31637535, 37.25818648],
            [-90.44001013, 32.32673206],
            [-86.9186422, 32.26548504],
            [-86.79500742, 37.20049545],
            [-90.31637535, 37.25818648]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1021",
      },
    },
    {
      "type": "Feature",
      "id": "smc5512c33",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [-83.05413637, 37.74552746],
            [-83.08055873, 27.23045376],
            [-72.75624367, 27.21104381],
            [-72.71386185, 43.45470869],
            [-83.04259258, 43.55285356],
            [-82.99350172, 39.07579528],
            [-82.99350172, 39.07579528],
            [-83.05413637, 37.74552746]
          ]
        ]
      },
      properties: {
        Unit_Number: "NK1019",
      },
    }
  
  ],
};

export default geojsonData;
