import React, { useState } from "react";
import Panel from "./Panel";

const VerticalAccordion = ({ phases, currentPhase, toggleTaskCompletion, allTasksCompleted, handleNextPhase }) => {
    const [open, setOpen] = useState(phases[currentPhase]?.id || 0);

    return (
        <section className="p-4">
            <div className="flex flex-col w-full shadow overflow-hidden">
                {phases.map((phase, index) => (
                    <Panel
                        key={phase.id}
                        open={open}
                        setOpen={setOpen}
                        id={phase.id}
                        title={phase.label}
                        tasks={phase.tasks}
                        currentPhase={currentPhase}
                        phaseIndex={index}
                        toggleTaskCompletion={toggleTaskCompletion}
                        allTasksCompleted={allTasksCompleted}
                        handleNextPhase={handleNextPhase}
                    />
                ))}
            </div>
        </section>
    );
};

export default VerticalAccordion;
