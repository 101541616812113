import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';

function ConferenceRoom() {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch rooms data from the backend
    axios.get('https://workspace.optiven.co.ke/api/rooms')
      .then(response => {
        setRooms(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the rooms!", error);
      });
  }, []);

  const handleRoomClick = (room) => {
    const date = new Date().toISOString().split('T')[0]; // Current date
    navigate(`/room-schedule`, { state: { room, date } });
  };

  const handleAllRoomsClick = () => {
    navigate('/entire-schedule');
  };

  return (
    <Sidebar>
    <div className="p-6">
      <button
        className="mb-6 p-2 bg-blue-500 text-white font-bold"
        onClick={handleAllRoomsClick}
      >
        View All Rooms Schedule
      </button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {rooms.map(room => (
          <div
            key={room.id}
            className="group relative block bg-black cursor-pointer"
            onClick={() => handleRoomClick(room)}
          >
            <img
              alt={room.name}
              src="https://images.unsplash.com/photo-1517048676732-d65bc937f952?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
            />
            <div className="relative p-4 sm:p-6 lg:p-8">
              <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
                {room.capacity} People
              </p>
              <p className="text-xl font-bold text-white sm:text-2xl">{room.name}</p>
              <div className="mt-32 sm:mt-48 lg:mt-64">
                <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                  <p className="text-sm text-white">
                    {room.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </Sidebar>
  );
}

export default ConferenceRoom;
