import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import { AnimatePresence, motion } from "framer-motion";

const ViewTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 5;

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/perf"
        );
        const tasksWithFullName = await Promise.all(
          response.data.map(async (task) => {
            const userResponse = await axios.get(
              `https://workspace.optiven.co.ke/api/users/${task.staff_id}`
            );
            const assignedUserName = userResponse.data.fullnames;
            return { ...task, assignedUserName };
          })
        );

        // Sort tasks based on end date in descending order (latest first)
        const sortedTasks = tasksWithFullName.sort(
          (a, b) => new Date(b.end_date) - new Date(a.end_date)
        );

        // Filter tasks based on selected month
        const filteredTasks = selectedMonth
          ? sortedTasks.filter((task) => {
              const taskMonth = new Date(task.end_date).getMonth() + 1;
              return taskMonth.toString() === selectedMonth;
            })
          : sortedTasks;

        setTasks(filteredTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
        // Handle error fetching data
      }
    };

    fetchTasks();
  }, [selectedMonth]);

  const calculateDaysLeft = (dueDate) => {
    const now = new Date();
    const due = new Date(dueDate);
    due.setHours(0, 0, 0, 0);

    const timeDiff = due - now;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysLeft;
  };

  const calculateProgress = (daysLeft) => {
    if (daysLeft <= 0) {
      return 100; // Past due date, show as completed (100%)
    } else {
      return Math.min(100, (100 - (daysLeft * 100) / 30).toFixed(2)); // Assuming a month duration
    }
  };

  const openModal = (task) => {
    setSelectedTask({
      ...task,
      dueDate: task.end_date,
      actualOutput: "", // Initialize with an empty string, user will update it
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTask(null);
    setIsModalOpen(false);
  };

  const TaskModal = ({ isOpen, setIsOpen, taskDetails }) => {
    return (
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setIsOpen(false)}
            className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
            style={{ zIndex: 9999 }}
          >
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-lg p-6 max-w-3xl w-full overflow-y-auto"
              style={{
                maxWidth: "95%",
                maxHeight: "95%",
                width: "95%",
                zIndex: 9999,
              }}
            >
              <h2 className="text-2xl font-bold mb-4">
                {taskDetails.name} Details
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <div className="mb-2">
                  <p className="font-medium">Expected Output</p>
                  <p>{taskDetails.expected_output}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Assigned User</p>
                  <p>{taskDetails.assignedUserName}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Figure</p>
                  <p>{Number(taskDetails.figure).toLocaleString()}</p>
                </div>
                <div className="mb-2">
                  <p className="font-medium">Actual Output</p>
                  <p>{taskDetails.actual_output}</p>
                </div>
                {/* Add more details as needed */}
              </div>
              <button
                onClick={() => setIsOpen(false)}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded mt-4"
              >
                Close
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  // Handle month change
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Paginate tasks
  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Sidebar>
      <div className="flex justify-end mr-4 mt-4">
        <label className="block text-sm font-medium text-gray-700">
          Select Month
        </label>
        <select
          className="mt-1 block w-40 border p-2"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          <option value="">All Months</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>

      <table className="table w-full mt-8">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Expected Output</th>
            <th>Figure</th>
            <th>Assigned User</th>
            <th>Due Date Progress</th>
            <th>Actual Output</th>
          </tr>
        </thead>
        <tbody>
          {currentTasks.map((task, index) => {
            const daysLeft = calculateDaysLeft(task.end_date);
            const progress = calculateProgress(daysLeft);

            const isActualOutputUpdated = task.actual_output !== null;
            const isPastDue = daysLeft <= 0 && !isActualOutputUpdated;

            return (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}
                onClick={() => openModal(task)}
                style={{ cursor: "pointer" }}
              >
                <td></td>
                <td>{task.name}</td>
                <td>{task.expected_output}</td>
                <td>{Number(task.figure).toLocaleString()}</td>
                <td>{task.assignedUserName}</td>

                <td>
                  <div className="progress" style={{ height: "20px" }}>
                    <div
                      className={`badge ${
                        isActualOutputUpdated
                          ? "badge-success"
                          : isPastDue
                          ? "badge-error"
                          : "badge-warning"
                      } gap-2`}
                    >
                      {isActualOutputUpdated
                        ? "Actual output updated"
                        : isPastDue
                        ? "Past due"
                        : `${daysLeft} day${daysLeft !== 1 ? "s" : ""} left`}
                    </div>
                  </div>
                </td>

                <td>{task.actual_output}</td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <div className="join">
          <button
            className="join-item btn btn-small btn-round"
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            style={{ backgroundColor: "#1e7335", color: "#fff" }}
          >
            «
          </button>
          {Array.from({ length: Math.ceil(tasks.length / tasksPerPage) }).map(
            (item, index) => (
              <label
                key={index}
                className={`join-item btn btn-small btn-round ${
                  currentPage === index + 1 ? "btn-active" : "btn-green"
                }`}
                style={{
                  backgroundColor:
                    currentPage === index + 1 ? "#1e7335" : "#89bf70",
                  color: "#fff",
                }}
              >
                <input
                  type="radio"
                  name="options"
                  className="hidden"
                  aria-label={index + 1}
                  checked={currentPage === index + 1}
                  onChange={() => paginate(index + 1)}
                />
                {index + 1}
              </label>
            )
          )}
          <button
            className="join-item btn btn-small btn-round"
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === Math.ceil(tasks.length / tasksPerPage)}
            style={{ backgroundColor: "#1e7335", color: "#fff" }}
          >
            »
          </button>
        </div>
      </div>

      <TaskModal
        isOpen={isModalOpen}
        setIsOpen={closeModal}
        taskDetails={selectedTask}
      />
    </Sidebar>
  );
};

export default ViewTasks;
