import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ children }) => {
  const accessRole = useSelector((state) => state.user.accessRole).trim();

  const department = useSelector((state) => state.user.user.department);

  const accessRoles = accessRole.split("#");

  const maintenance = accessRoles.includes("maintenance");
  const valueAddition = accessRole.includes("valueAddition");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");

  return (
    <>
      <div className="drawer">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content overflow-visible">{children}</div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 bg-base-100 text-base-content">
            <li>
              <Link to="/" className="font-bold my-1">
                Dashboard
              </Link>
            </li>
            <li>
              <Link to="/value-addition-home" className="font-bold my-1">
                Home
              </Link>
            </li>
            {(valueAdmin || department === "ICT SYSTEMS") && (
              <li>
                <Link to="/summary" className="font-bold my-1">
                  Summary
                </Link>
              </li>
            )}
            {(maintenance ||
              valueAddition ||
              operations ||
              valueAdmin ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Projects</div>
                <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                  {(maintenance ||
                    valueAddition ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      to="/create-project"
                      className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                    >
                      Create Project
                    </Link>
                  )}
                 
                  {(maintenance ||
                    valueAddition ||
                    operations ||
                    valueAdmin ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/projects"
                    >
                      View Projects
                    </Link>
                  )}
                  {(maintenance ||
                    valueAddition ||
                    operations ||
                    valueAdmin ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      to="/view-value-additions"
                      className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                    >
                      View Value Additions
                    </Link>
                  )}
                </div>
              </div>
            )}
            {department === "ICT SYSTEMS" && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Requisitions</div>
                <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                  {department === "ICT SYSTEMS" && (
                    <Link
                      className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                      to="/create_requisition"
                    >
                      Create Requisition
                    </Link>
                  )}
                  {department === "ICT SYSTEMS" && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/view_requisition"
                    >
                      View Requisition
                    </Link>
                  )}
                  {department === "ICT SYSTEMS" && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/approved_requisitions"
                    >
                      Approved Requisitions
                    </Link>
                  )}
                </div>
              </div>
            )}

            {(valueAddition ||
              operations ||
              valueAdmin ||
              maintenance ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">
                  Suppliers/Service Provider
                </div>

                <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                  {(maintenance ||
                    valueAddition ||
                    operations ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                      to="/add-supplier"
                    >
                      Add Supplier/Service Provider
                    </Link>
                  )}
                  {(maintenance ||
                    valueAddition ||
                    operations ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/suppliers"
                    >
                      View Suppliers/Service Provider
                    </Link>
                  )}
                  {(valueAddition ||
                    operations ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/create-supplier-payment"
                    >
                      Value Addition Supplier Payment
                    </Link>
                  )}
                  {(valueAddition ||
                    operations ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/view-supplier-payment"
                    >
                      View Value Addition  Payment
                    </Link>
                  )}
                  {(maintenance ||
                    operations ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/maintenance-supply"
                    >
                   Maintenance Supplier Payment
                    </Link>
                  )}
                  {(maintenance ||
                    operations ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/view-maintenance-supply"
                    >
                      View Maintenance Payment
                    </Link>
                  )}
                </div>
              </div>
            )}

            {(maintenance ||
              valueAddition ||
              operations ||
              valueAdmin ||
              operations ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Labour</div>

                <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                  {(maintenance ||
                    valueAddition ||
                    operations ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                      <input type="checkbox" className="peer" />
                      <div className="collapse-title font-bold">Labour</div>
                      <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                        {(operations ||
                          valueAdmin ||
                          department === "ICT SYSTEMS") && (
                          <Link
                            className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                            to="/add-labour"
                          >
                            Add Labour
                          </Link>
                        )}
                        <Link
                          className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                          to="/view-labour"
                        >
                          View Labour
                        </Link>
                      </div>
                    </div>
                  )}

                  {(maintenance ||
                    valueAddition ||
                    valueAdmin ||
                    department === "ICT SYSTEMS") && (
                    <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                      <input type="checkbox" className="peer" />
                      <div className="collapse-title font-bold">
                        Casual Labourers
                      </div>
                      <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                        <Link
                          className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                          to="/add-casual-labourer"
                        >
                          Add Casual Labourer
                        </Link>
                        <Link
                          className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                          to="/view-casual-labourer"
                        >
                          View Casual Labourer
                        </Link>
                        <Link
                          className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                          to="/archive-casual-labourer"
                        >
                          Archive Casual Labourer
                        </Link>
                      </div>
                    </div>
                  )}
                  {(valueAddition ||
                    valueAdmin ||
                    department === "ICT SYSTEMS") && (
                    <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                      <input type="checkbox" className="peer" />
                      <div className="collapse-title font-bold">
                        Construction Manager
                      </div>
                      <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                        <Link
                          className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                          to="/add-construction-manager"
                        >
                          Add Construction Manager
                        </Link>
                        <Link
                          className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                          to="/view-construction-manager"
                        >
                          view Construction Manager
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {department === "ICT SYSTEMS" && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Approvals</div>
                <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                  <Link
                    className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                    to="/approve_requisitions"
                  >
                    Requisition Approval
                  </Link>
                  <Link
                    className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                    to="/suppliers"
                  >
                    Payment Approval
                  </Link>
                </div>
              </div>
            )}
            {(maintenance ||
              valueAddition ||
              operations ||
              valueAdmin ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Payments</div>
                <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                  {(maintenance ||
                    valueAddition ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                      to="/create_payments"
                    >
                      Create Payments
                    </Link>
                  )}
                  {(maintenance || valueAdmin || department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                      to="/update_payments"
                    >
                      Maintenance payment updates
                    </Link>
                  )}
                  {(valueAddition ||
                    valueAdmin ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                      to="/value-addition-update-payments"
                    >
                      Value Addition payment updates
                    </Link>
                  )}

                  {(maintenance ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/view-maintenance-payments"
                    >
                      View Maintenance Payments
                    </Link>
                  )}
                  {(valueAddition ||
                    valueAdmin ||
                    operations ||
                    department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/view-value-addition-payments"
                    >
                      View Value Addition Payments
                    </Link>
                  )}
                  {(operations || valueAdmin || department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/view_payments"
                    >
                      View Payments
                    </Link>
                  )}
                </div>
              </div>
            )}
            {(operations || valueAdmin || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-plus border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Reports</div>
                <div className="collapse-content -mt-3 flex flex-col menu bg-base-100">
                  {(valueAdmin || department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-1 hover:bg-base-200 rounded p-2"
                      to="/value-addition-report"
                    >
                      Value Addition Report
                    </Link>
                  )}

                  {(operations || valueAdmin || department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/casual-labourer-payment-report"
                    >
                      Payment Reports
                    </Link>
                  )}
                  {(operations || valueAdmin || department === "ICT SYSTEMS") && (
                    <Link
                      className="font-medium mt-3 hover:bg-base-200 rounded p-2"
                      to="/supplier-report"
                    >
                      Supplier Reports
                    </Link>
                  )}
                </div>
              </div>
            )}

            {/* for other links, li>Link */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
