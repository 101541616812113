import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const DonutChart = ({ tasks }) => {
  const [totalTarget, setTotalTarget] = useState(0);
  const [averageCompletion, setAverageCompletion] = useState(0);
  const [currentMonthMIB, setCurrentMonthMIB] = useState(0);
  const token = useSelector((state) => state.user.token);
  const accessRoles = useSelector((state) => state.user.accessRole).split("#");

  useEffect(() => {
    // Fetch current month's MIB data based on access roles
    let endpoint = "https://workspace.optiven.co.ke/api/mib"; // Default endpoint for general access

    if (accessRoles.includes("isIvoryHos")) {
      endpoint = "https://workspace.optiven.co.ke/api/mib/absa";
    } else if (accessRoles.includes("isIvoryGm")) {
      endpoint = "https://workspace.optiven.co.ke/api/mib/karen";
    }

    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setCurrentMonthMIB(response.data.MIB.total))
      .catch((error) =>
        console.error("Error fetching current month's MIB data:", error)
      );
  }, [token, accessRoles]);

  useEffect(() => {
    // Calculate the total figure from tasks with figures
    const totalFigure = tasks.reduce((total, task) => {
      return task.figure ? total + task.figure : total;
    }, 0);

    // Set the total figure as the total target
    setTotalTarget(totalFigure);
  }, [tasks]);

  useEffect(() => {
    // Calculate average completion
    const calculatedAverage = (currentMonthMIB / totalTarget) * 100;
    setAverageCompletion(isFinite(calculatedAverage) ? calculatedAverage : 0);
  }, [currentMonthMIB, totalTarget]);
  return (
    <div className="card w-96 bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">MIB Target Completion</h2>
        <div className="flex flex-col items-center">
          <svg width="120" height="120" viewBox="0 0 120 120">
            <circle
              cx="60"
              cy="60"
              r={50}
              fill="transparent"
              stroke="#d1d5db"
              strokeWidth="10"
            />
            <circle
              cx="60"
              cy="60"
              r={50}
              fill="transparent"
              stroke="#10b981"
              strokeWidth="10"
              strokeDasharray={2 * Math.PI * 50}
              strokeDashoffset={(averageCompletion / 100) * (2 * Math.PI * 50)}
              transform="rotate(-90 60 60)"
            />
          </svg>
          <div className="text-2xl font-semibold mt-2">
            {averageCompletion.toFixed(2)}%
          </div>
          <div className="text-sm text-gray-500">Average Goal Completion</div>
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
