import React from "react";
import ActionButtonsCard from "./CallToAction";
import PlotCounterCard from "../PlotCounter";

const Legend = ({ open, reserved, sold }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10 m-10">
    <PlotCounterCard
        openPlotsNumber={open}
        reservedPlotsNumber={reserved}
        soldPlotsNumber={sold}
      />
      <ActionButtonsCard />
    
    </div>
  );
};

export default Legend;
