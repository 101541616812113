import { useState, useEffect } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { motion } from "framer-motion";
import axios from "axios";
import Sidebar from "../../components/Sidebar";

const ClientReports = () => {
  const [clients, setClients] = useState([]);
  const [expandedClient, setExpandedClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("https://workspace.optiven.co.ke/api/homes-clients");
        setClients(response.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
        setError("Failed to fetch clients");
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const toggleExpand = (id) => {
    setExpandedClient(expandedClient === id ? null : id);
  };

  return (
    <Sidebar>
      <div className="min-h-screen bg-gradient-to-br from-violet-600 to-indigo-600 p-8 w-full">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <div className="w-full bg-white shadow-lg rounded-lg overflow-x-scroll max-w-4xl mx-auto">
            <table className="w-full">
              <thead>
                <tr className="border-b-[1px] border-slate-200 text-slate-400 text-sm uppercase">
                  <th className="pl-4 w-8"></th>
                  <th className="text-start p-4 font-medium">Client Name</th>
                  <th className="text-start p-4 font-medium">ID No</th>
                  <th className="text-start p-4 font-medium">Plot Size</th>
                  <th className="text-start p-4 font-medium">Cost</th>
                  <th className="text-start p-4 font-medium">Status</th>
                  <th className="text-start p-4 font-medium">Details</th>
                </tr>
              </thead>

              <tbody>
                {clients.map((client) => (
                  <ClientRow
                    key={client.id}
                    client={client}
                    isExpanded={expandedClient === client.id}
                    toggleExpand={toggleExpand}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Sidebar>
  );
};

const ClientRow = ({ client, isExpanded, toggleExpand }) => {
  const [phaseDetails, setPhaseDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPhaseDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://workspace.optiven.co.ke/api/homes-clients/clients/${client.id}/tasks/costs`);
      setPhaseDetails(response.data);
    } catch (error) {
      console.error("Error fetching phase details:", error);
      setError("Failed to fetch phase details");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isExpanded && !phaseDetails) {
      fetchPhaseDetails();
    }
  }, [isExpanded]);

  return (
    <>
      <tr className={`text-sm ${client.id % 2 ? "bg-slate-100" : "bg-white"}`}>
        <td className="pl-4 w-8">
          <button
            className="hover:text-violet-600"
            onClick={() => toggleExpand(client.id)}
          >
            {isExpanded ? <ChevronUp /> : <ChevronDown />}
          </button>
        </td>
        <td className="p-4">{client.client_name}</td>
        <td className="p-4">{client.id_no}</td>
        <td className="p-4">{client.plot_size}</td>
        <td className="p-4">{client.cost?.toLocaleString()}</td>
        <td className="p-4">{client.status}</td>
        <td className="p-4">
          <button
            className="text-blue-500 hover:underline"
            onClick={() => toggleExpand(client.id)}
          >
            {isExpanded ? "Hide Details" : "Show Details"}
          </button>
        </td>
      </tr>
      {isExpanded && (
        <tr className="bg-gray-100 text-sm">
          <td colSpan="7" className="p-4">
            {loading ? (
              <p>Loading phase details...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              phaseDetails && <ClientDetails client={client} phases={phaseDetails} />
            )}
          </td>
        </tr>
      )}
    </>
  );
};

const ClientDetails = ({ client, phases }) => {
  const formattedDate = (dateString) => new Date(dateString).toLocaleDateString();

  const typeBadgeClass = {
    Mansion: "bg-blue-200 text-blue-800",
    Bungalow: "bg-yellow-200 text-yellow-800",
    // Add more types as needed
  }[client.type] || "bg-gray-200 text-gray-800";

  const statusBadgeClass = {
    design: "bg-green-200 text-green-800",
    construction: "bg-yellow-200 text-red-800",
    terminated: "bg-red-200 text-red-800",
    // Add more statuses as needed
  }[client.status] || "bg-gray-200 text-gray-800";

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className="bg-violet-100 p-4 rounded-lg mb-4">
        <h3 className="font-bold text-lg mb-2">Client Details</h3>
        <div className="grid grid-cols-2 gap-4">
          <p><strong>ID:</strong> {client.id}</p>
          <p><strong>Name:</strong> {client.client_name}</p>
          <p><strong>ID No:</strong> {client.id_no}</p>
          <p><strong>LR No:</strong> {client.LR_no}</p>
          <p><strong>Plot Size:</strong> {client.plot_size}</p>
          <p><strong>Type:</strong> <span className={`inline-block px-2 py-1 rounded-full text-xs font-bold ${typeBadgeClass}`}>{client.type}</span></p>
          <p><strong>Cost:</strong> {client.cost?.toLocaleString()}</p>
          <p><strong>Actual Cost:</strong> {client.actual_cost?.toLocaleString()}</p>
          <p><strong>Construction Cost:</strong> {client.const_cost?.toLocaleString()}</p>
          <p><strong>Proposed Start Date:</strong> {client.prop_start_date ? formattedDate(client.prop_start_date) : 'N/A'}</p>
          <p><strong>Proposed End Date:</strong> {client.prop_end_date ? formattedDate(client.prop_end_date) : 'N/A'}</p>
          <p><strong>Status:</strong> <span className={`inline-block px-2 py-1 rounded-full text-xs font-bold ${statusBadgeClass}`}>{client.status}</span></p>
          <p><strong>Email:</strong> {client.email}</p>
          <p><strong>Date:</strong> {client.date ? formattedDate(client.date) : 'N/A'}</p>
        </div>
      </div>

      <h3 className="font-bold text-lg mb-2 mt-4">Client Phase Details</h3>
      {phases.map((phase) => {
        const totalTaskCost = phase.tasks.reduce((total, task) => total + parseFloat(task.cost || 0), 0);
        const percentageCost = (phase.percentage / 100) * parseFloat(client.const_cost || 0);
        const variance = percentageCost - totalTaskCost;
        const comparison = percentageCost >= totalTaskCost ? 'Within Budget' : 'Over Budget';
        const comparisonBadgeClass = comparison === 'Within Budget' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800';
        const varianceBadgeClass = variance >= 0 ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800';

        return (
          <div key={phase.id} className="bg-indigo-100 p-4 rounded-lg mb-4">
            <h4 className="font-semibold text-indigo-800">
              {phase.label} - <span className={`inline-block px-2 py-1 rounded-full text-xs font-bold ${comparisonBadgeClass}`}>{comparison}</span>
            </h4>
            <ul className="ml-4 list-disc">
              {phase.tasks.map((task) => (
                <li key={task.id} className={task.completed ? "text-green-600" : "text-red-600"}>
                  {task.name} - {task.completed ? "Completed" : "Pending"} - Ksh. {parseFloat(task.cost || 0).toLocaleString()}
                </li>
              ))}
            </ul>
            <p><strong>Total Task Cost:</strong> Ksh. {totalTaskCost.toLocaleString()}</p>
            <p><strong>Expected Cost:</strong> Ksh. {percentageCost.toLocaleString()}</p>
            <p>
              <strong>Variance:</strong> <span className={`inline-block px-2 py-1 rounded-full text-xs font-bold ${varianceBadgeClass}`}>
                Ksh. {variance.toLocaleString()}
              </span>
            </p>
          </div>
        );
      })}
    </motion.div>
  );
};

export default ClientReports;
