import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useSelector } from "react-redux";
import EventsBarChart from "../dash/Charts/EventBarChart";

const formatDate = (dateTimeString) => {
  if (!dateTimeString) return null;
  const dateTime = new Date(dateTimeString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  };
  return dateTime.toLocaleDateString("en-CA", options).replace(/\//g, "-");
};

const ViewEvents = () => {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const token = useSelector((state) => state.user.token);
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const accessRoles = accessRole.split("#");
  const isIvoryAdmin = accessRoles.includes("isIvoryAdmin");
  const isIvoryHos = accessRoles.includes("isIvoryHos");
  const isIvoryGm = accessRoles.includes("isIvoryGm");

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        let url = "https://workspace.optiven.co.ke/api/event";

        if (isIvoryHos) {
          url += "/region1";
        } else if (isIvoryGm) {
          url += "/region2";
        }

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();

        if (Array.isArray(data)) {
          setEvents(data);
        } else {
          console.error("Invalid response format. Expected an array.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchEvents();
  }, [token, isIvoryAdmin, isIvoryHos, isIvoryGm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredEvents = events
    .filter(
      (event) =>
        event.region.toLowerCase().includes(searchTerm.toLowerCase()) ||
        event.event.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((event) => {
      const month = new Date(event.endDate).getMonth() + 1; // Assuming endDate is the field containing the end date
      return selectedMonth ? month.toString() === selectedMonth : true;
    });

  return (
    <Sidebar>
      <div className="container px-4 py-6 mx-auto">
        <div className="flex justify-center mb-4">
          <input
            type="text"
            className="border border-gray-300 rounded-md px-3 py-2 mr-2 w-72"
            placeholder="Search by region or event"
            value={searchTerm}
            onChange={handleSearch}
          />
          <button className="btn btn-primary">Search</button>
        </div>
        {/* Dropdown for selecting month */}
        <div className="flex justify-end mb-4">
          <select
            className="border p-1"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            <option value="">All Months</option>
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <div className="overflow-x-auto card bg-base-100 shadow-xl">
          <table className="table table-compact">
            <thead>
              <tr>
                <th>ID</th>
                <th className="text-center">Region</th>
                <th className="text-center">Event</th>
                <th className="text-center">Data Collected</th>
                <th className="text-center">Site Visit Done</th>
                <th className="text-center">Scheduled Visits</th>
                <th className="text-center">Sales</th>
                <th className="text-center">Referrals</th>
                <th className="text-center">Cost</th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.map((event, i) => (
                <tr key={event.id}>
                  <td>{i + 1}</td>
                  <td className="text-center">{event.region}</td>
                  <td className="text-center">{event.event}</td>
                  <td className="text-center">{event.dataCollected}</td>
                  <td className="text-center">{event.siteVisitDone}</td>
                  <td className="text-center">{event.scheduledVisits}</td>
                  <td className="text-center">{event.sales}</td>
                  <td className="text-center">{event.referrals}</td>
                  <td className="text-center">{event.cost}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <EventsBarChart events={filteredEvents} selectedMonth={selectedMonth} />
      </div>
    </Sidebar>
  );
};

export default ViewEvents;
