const geojsonData = {
  type: "FeatureCollection",

  
    features: [
      {
        "type": "Feature",
        "id": "sm78546d3a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63377805, 61.617294808],
              [18.633879572, 61.617414108],
              [18.633844703, 61.617456183],
              [18.633327779, 61.617560233],
              [18.633191765, 61.617407545],
              [18.63377805, 61.617294808]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT21",
      },
      },
      {
        "type": "Feature",
        "id": "smdbff3d32",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633191765, 61.617407545],
              [18.633095341, 61.6172993],
              [18.633567095, 61.617146353],
              [18.633663655, 61.617160379],
              [18.63377805, 61.617294808],
              [18.633191765, 61.617407545]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT20",
      },
      },
      {
        "type": "Feature",
        "id": "sm4d1206ec",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.632991821, 61.617627857],
              [18.632723539, 61.617681858],
              [18.632762431, 61.617725845],
              [18.632605764, 61.617748781],
              [18.632403015, 61.617523756],
              [18.632787926, 61.617398966],
              [18.632991821, 61.617627857]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT23",
      },
      },
      {
        "type": "Feature",
        "id": "sm3ae57fbe",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.632787926, 61.617398966],
              [18.633095341, 61.6172993],
              [18.633327779, 61.617560233],
              [18.632991821, 61.617627857],
              [18.632787926, 61.617398966]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT22",
      },
      },
      {
        "type": "Feature",
        "id": "sm9619c5fd",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635100228, 61.620084911],
              [18.634790183, 61.620173098],
              [18.634468543, 61.619816146],
              [18.634665461, 61.619771506],
              [18.634705694, 61.619813578],
              [18.634834857, 61.619787537],
              [18.635100228, 61.620084911]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT55",
      },
      },
      {
        "type": "Feature",
        "id": "sm93d6de2e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635453291, 61.619662852],
              [18.635749073, 61.619603218],
              [18.635840268, 61.619618517],
              [18.635993154, 61.619789352],
              [18.635969014, 61.619837798],
              [18.635682206, 61.619919376],
              [18.635453291, 61.619662852]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT52",
      },
      },
      {
        "type": "Feature",
        "id": "sm6d1d50d5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635682206, 61.619919376],
              [18.635375148, 61.620006714],
              [18.635126999, 61.619728637],
              [18.635453291, 61.619662852],
              [18.635682206, 61.619919376]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT53",
      },
      },
      {
        "type": "Feature",
        "id": "sm00668134",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635375148, 61.620006714],
              [18.635100228, 61.620084911],
              [18.634834857, 61.619787537],
              [18.635126999, 61.619728637],
              [18.635375148, 61.620006714]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT54",
      },
      },
      {
        "type": "Feature",
        "id": "smeda51478",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634928917, 61.619047041],
              [18.635201901, 61.61899125],
              [18.635295778, 61.619014199],
              [18.63550036, 61.619244218],
              [18.63516762, 61.619311958],
              [18.634928917, 61.619047041]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT47",
      },
      },
      {
        "type": "Feature",
        "id": "sma2696acf",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63516762, 61.619311958],
              [18.634849701, 61.61937668],
              [18.634611189, 61.619111977],
              [18.634928917, 61.619047041],
              [18.63516762, 61.619311958]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT46",
      },
      },
      {
        "type": "Feature",
        "id": "sm43ad62cb",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634849701, 61.61937668],
              [18.634522058, 61.619443382],
              [18.634283743, 61.619178898],
              [18.634611189, 61.619111977],
              [18.634849701, 61.61937668]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT45",
      },
      },
      {
        "type": "Feature",
        "id": "sm5a008365",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634522058, 61.619443382],
              [18.634193017, 61.619510369],
              [18.633920686, 61.619208134],
              [18.634068667, 61.61917994],
              [18.634100854, 61.619216276],
              [18.634283743, 61.619178898],
              [18.634522058, 61.619443382]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT44",
      },
      },
      {
        "type": "Feature",
        "id": "smd3efe41a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63477492, 61.619704383],
              [18.634630592, 61.619733259],
              [18.634665461, 61.619771506],
              [18.634468543, 61.619816146],
              [18.634193017, 61.619510369],
              [18.634537001, 61.61944034],
              [18.63477492, 61.619704383]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT51",
      },
      },
      {
        "type": "Feature",
        "id": "sm8cfc37f1",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634537001, 61.61944034],
              [18.634845204, 61.619377596],
              [18.635083949, 61.619642555],
              [18.63477492, 61.619704383],
              [18.634537001, 61.61944034]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT50",
      },
      },
      {
        "type": "Feature",
        "id": "smcd399e2e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634845204, 61.619377596],
              [18.63517803, 61.619309839],
              [18.635417666, 61.619575788],
              [18.635083949, 61.619642555],
              [18.634845204, 61.619377596]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT49",
      },
      },
      {
        "type": "Feature",
        "id": "smbe0a702a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63517803, 61.619309839],
              [18.63550036, 61.619244218],
              [18.635714204, 61.61948465],
              [18.635682018, 61.619522898],
              [18.635417666, 61.619575788],
              [18.63517803, 61.619309839]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT48",
      },
      },
      {
        "type": "Feature",
        "id": "smcf20255e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634353746, 61.618301128],
              [18.634551464, 61.618260062],
              [18.634650706, 61.618277912],
              [18.634916259, 61.618575655],
              [18.634649955, 61.618629871],
              [18.634353746, 61.618301128]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT38",
      },
      },
      {
        "type": "Feature",
        "id": "sma1b060a3",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634649955, 61.618629871],
              [18.63438254, 61.618684313],
              [18.634087165, 61.618356496],
              [18.634353746, 61.618301128],
              [18.634649955, 61.618629871]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT37",
      },
      },
      {
        "type": "Feature",
        "id": "smc450c588",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63438254, 61.618684313],
              [18.63412289, 61.618737174],
              [18.633828325, 61.618410256],
              [18.634087165, 61.618356496],
              [18.63438254, 61.618684313]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT36",
      },
      },
      {
        "type": "Feature",
        "id": "sm2280d212",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63412289, 61.618737174],
              [18.63386454, 61.61878977],
              [18.633570782, 61.618463747],
              [18.633828325, 61.618410256],
              [18.63412289, 61.618737174]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT35",
      },
      },
      {
        "type": "Feature",
        "id": "sm7c9ff52e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63386454, 61.61878977],
              [18.633593449, 61.61884496],
              [18.63326841, 61.618484221],
              [18.633409513, 61.618454818],
              [18.633440358, 61.618490836],
              [18.633570782, 61.618463747],
              [18.63386454, 61.61878977]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT34",
      },
      },
      {
        "type": "Feature",
        "id": "sm20005de5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634164152, 61.619111165],
              [18.634031117, 61.61913723],
              [18.634068667, 61.61917994],
              [18.633920686, 61.619208134],
              [18.633593449, 61.61884496],
              [18.633873008, 61.618788046],
              [18.634164152, 61.619111165]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT43",
      },
      },
      {
        "type": "Feature",
        "id": "sm4cbb9856",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633873008, 61.618788046],
              [18.634126825, 61.618736373],
              [18.634419457, 61.619061143],
              [18.634164152, 61.619111165],
              [18.633873008, 61.618788046]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT42",
      },
      },
      {
        "type": "Feature",
        "id": "sm16f00e8a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634126825, 61.618736373],
              [18.634385816, 61.618683646],
              [18.634679966, 61.619010101],
              [18.634419457, 61.619061143],
              [18.634126825, 61.618736373]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT41",
      },
      },
      {
        "type": "Feature",
        "id": "sm77a75b40",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634385816, 61.618683646],
              [18.634648698, 61.618630127],
              [18.63494439, 61.618958293],
              [18.634679966, 61.619010101],
              [18.634385816, 61.618683646]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT40",
      },
      },
      {
        "type": "Feature",
        "id": "smef1128a5",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634648698, 61.618630127],
              [18.634916259, 61.618575655],
              [18.635177761, 61.618868855],
              [18.635130823, 61.618921765],
              [18.63494439, 61.618958293],
              [18.634648698, 61.618630127]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT39",
      },
      },
      {
        "type": "Feature",
        "id": "sm4cf57e9d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633699734, 61.617578259],
              [18.633923827, 61.617533958],
              [18.634004294, 61.617551808],
              [18.634268751, 61.617846967],
              [18.633990894, 61.61790322],
              [18.633699734, 61.617578259]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT28",
      },
      },
      {
        "type": "Feature",
        "id": "sm5ddc972e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633990894, 61.61790322],
              [18.633732241, 61.617955586],
              [18.633440143, 61.617629577],
              [18.633699734, 61.617578259],
              [18.633990894, 61.61790322]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT27",
      },
      },
      {
        "type": "Feature",
        "id": "sm8398fe81",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633732241, 61.617955586],
              [18.633479475, 61.618006759],
              [18.633186459, 61.617679727],
              [18.633440143, 61.617629577],
              [18.633732241, 61.617955586]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT26",
      },
      },
      {
        "type": "Feature",
        "id": "smd61d0e2a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633479475, 61.618006759],
              [18.633205181, 61.618062291],
              [18.63291117, 61.617734149],
              [18.633186459, 61.617679727],
              [18.633479475, 61.618006759]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT25",
      },
      },
      {
        "type": "Feature",
        "id": "sm4555d61f",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633205181, 61.618062291],
              [18.632937136, 61.618116557],
              [18.632605764, 61.617748781],
              [18.632762431, 61.617725845],
              [18.632791935, 61.61775772],
              [18.63291117, 61.617734149],
              [18.633205181, 61.618062291]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT24",
      },
      },
      {
        "type": "Feature",
        "id": "sm8865993d",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.63349422, 61.618383654],
              [18.633365928, 61.618409874],
              [18.633409513, 61.618454818],
              [18.63326841, 61.618484221],
              [18.632937136, 61.618116557],
              [18.633206112, 61.618062102],
              [18.63349422, 61.618383654]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT33",
      },
      },
      {
        "type": "Feature",
        "id": "sm3506e906",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633206112, 61.618062102],
              [18.633465941, 61.618009499],
              [18.633753671, 61.618330629],
              [18.63349422, 61.618383654],
              [18.633206112, 61.618062102]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT32",
      },
      },
      {
        "type": "Feature",
        "id": "smc258cd0c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633465941, 61.618009499],
              [18.633738551, 61.617954308],
              [18.634025885, 61.618274995],
              [18.633753671, 61.618330629],
              [18.633465941, 61.618009499]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT31",
      },
      },
      {
        "type": "Feature",
        "id": "sm2b351a48",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633738551, 61.617954308],
              [18.633995186, 61.617902351],
              [18.634282145, 61.618222622],
              [18.634025885, 61.618274995],
              [18.633738551, 61.617954308]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT30",
      },
      },
      {
        "type": "Feature",
        "id": "sm6bb19636",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633995186, 61.617902351],
              [18.634268751, 61.617846967],
              [18.634524643, 61.618132564],
              [18.634476362, 61.618182929],
              [18.634282145, 61.618222622],
              [18.633995186, 61.617902351]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT29",
      },
      },
      {
        "type": "Feature",
        "id": "smfa016f96",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.633885726, 61.617192688],
              [18.6338152, 61.617113205],
              [18.633844704, 61.617060929],
              [18.634330184, 61.616904101],
              [18.634480624, 61.617073409],
              [18.633885726, 61.617192688]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT1",
      },
      },
      {
        "type": "Feature",
        "id": "sm29a69b46",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.636629037, 61.619491161],
              [18.636738809, 61.61961469],
              [18.636237235, 61.619758755],
              [18.636151405, 61.619746006],
              [18.636031685, 61.619611093],
              [18.636629037, 61.619491161]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT19",
      },
      },
      {
        "type": "Feature",
        "id": "sm4757caf0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.636031685, 61.619611093],
              [18.635906596, 61.619470127],
              [18.636503797, 61.619350225],
              [18.636629037, 61.619491161],
              [18.636031685, 61.619611093]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT18",
      },
      },
      {
        "type": "Feature",
        "id": "sm5491d7f0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635906596, 61.619470127],
              [18.635779763, 61.619327196],
              [18.636376812, 61.619207325],
              [18.636503797, 61.619350225],
              [18.635906596, 61.619470127]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT17",
      },
      },
      {
        "type": "Feature",
        "id": "sm7b8fa091",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635779763, 61.619327196],
              [18.635650444, 61.619181463],
              [18.636247337, 61.619061622],
              [18.636376812, 61.619207325],
              [18.635779763, 61.619327196]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT16",
      },
      },
      {
        "type": "Feature",
        "id": "sme0becb5e",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635650444, 61.619181463],
              [18.635526671, 61.61904198],
              [18.636123415, 61.618922168],
              [18.636247337, 61.619061622],
              [18.635650444, 61.619181463]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT15",
      },
      },
      {
        "type": "Feature",
        "id": "sm60e41082",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635526671, 61.61904198],
              [18.635400167, 61.618899418],
              [18.635996759, 61.618779636],
              [18.636123415, 61.618922168],
              [18.635526671, 61.61904198]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT14",
      },
      },
      {
        "type": "Feature",
        "id": "sm47f5bc69",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635400167, 61.618899418],
              [18.635274224, 61.618757488],
              [18.635870665, 61.618637736],
              [18.635996759, 61.618779636],
              [18.635400167, 61.618899418]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT13",
      },
      },
      {
        "type": "Feature",
        "id": "smf911b277",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635274224, 61.618757488],
              [18.635149142, 61.618616528],
              [18.635745561, 61.618496949],
              [18.635870665, 61.618637736],
              [18.635274224, 61.618757488]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT12",
      },
      },
      {
        "type": "Feature",
        "id": "sm06f421e0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635149142, 61.618616528],
              [18.635023131, 61.61847452],
              [18.635619398, 61.618354971],
              [18.635745561, 61.618496949],
              [18.635149142, 61.618616528]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT11",
      },
      },
      {
        "type": "Feature",
        "id": "smfcd330c4",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.635023131, 61.61847452],
              [18.634898904, 61.618334521],
              [18.635495022, 61.618215002],
              [18.635619398, 61.618354971],
              [18.635023131, 61.61847452]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT10",
      },
      },
      {
        "type": "Feature",
        "id": "smfaab1df0",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634898904, 61.618334521],
              [18.634773447, 61.618193136],
              [18.635369287, 61.618073503],
              [18.635495022, 61.618215002],
              [18.634898904, 61.618334521]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT9",
      },
      },
      {
        "type": "Feature",
        "id": "sm2c8f0cf6",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634773447, 61.618193136],
              [18.634644335, 61.618047631],
              [18.635240019, 61.617928028],
              [18.635369287, 61.618073503],
              [18.634773447, 61.618193136]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT8",
      },
      },
      {
        "type": "Feature",
        "id": "sm7ffd191c",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634644335, 61.618047631],
              [18.634520729, 61.61790833],
              [18.635116264, 61.617788756],
              [18.635240019, 61.617928028],
              [18.634644335, 61.618047631]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT7",
      },
      },
      {
        "type": "Feature",
        "id": "sm42c09270",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634520729, 61.61790833],
              [18.634388541, 61.617759357],
              [18.634984045, 61.617639959],
              [18.635116264, 61.617788756],
              [18.634520729, 61.61790833]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT6",
      },
      },
      {
        "type": "Feature",
        "id": "sm319e755a",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634388541, 61.617759357],
              [18.634269326, 61.617625004],
              [18.634864686, 61.617505633],
              [18.634984045, 61.617639959],
              [18.634388541, 61.617759357]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT5",
      },
      },
      {
        "type": "Feature",
        "id": "sm730ff6da",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634269326, 61.617625004],
              [18.634135773, 61.617474491],
              [18.634730972, 61.617355152],
              [18.634864686, 61.617505633],
              [18.634269326, 61.617625004]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT4",
      },
      },
      {
        "type": "Feature",
        "id": "sme3746952",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634135773, 61.617474491],
              [18.634016917, 61.617340541],
              [18.634611973, 61.61722123],
              [18.634730972, 61.617355152],
              [18.634135773, 61.617474491]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT3",
      },
      },
      {
        "type": "Feature",
        "id": "smd452b3cb",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [18.634016917, 61.617340541],
              [18.633885726, 61.617192688],
              [18.634480624, 61.617073409],
              [18.634611973, 61.61722123],
              [18.634016917, 61.617340541]
            ]
          ]
        },
        properties: {
        Unit_Number: "KT2",
      },
      }
    ],
    
  
};

export default geojsonData;
