const geojsonData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "sm0803605b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-158.86926287, 4.60041084],
            [-156.80383319, 5.91319607],
            [-156.71868913, 6.31464626],
            [-157.47653249, 7.84411729],
            [-159.93768328, 6.6694118],
            [-158.86926287, 4.60041084],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-14",
      },
    },
    {
      type: "Feature",
      id: "sm71c68609",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-159.91845721, 6.65577164],
            [-161.97725328, 5.64597444],
            [-160.84680194, 3.24681124],
            [-158.92419451, 4.53470222],
            [-159.91845721, 6.65577164],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-15",
      },
    },
    {
      type: "Feature",
      id: "sm12cd6f04",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-161.99922594, 5.67877262],
            [-163.99438494, 4.7427586],
            [-162.71997087, 2.26468404],
            [-162.34643572, 2.23175042],
            [-160.84680194, 3.24681124],
            [-161.99922594, 5.67877262],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-16",
      },
    },
    {
      type: "Feature",
      id: "sma15e3d99",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-154.42668497, 7.50254673],
            [-155.85529962, 10.36437247],
            [-157.53717632, 9.54755602],
            [-156.16662609, 6.79215611],
            [-155.68322765, 6.61757714],
            [-154.42668497, 7.50254673],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-2",
      },
    },
    {
      type: "Feature",
      id: "sm06878127",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-155.85529962, 10.36437247],
            [-154.42668497, 7.50254673],
            [-152.95861827, 8.53420023],
            [-154.26111129, 11.13664403],
            [-155.85529962, 10.36437247],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-1",
      },
    },
    {
      type: "Feature",
      id: "sm73d19b39",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-157.53717632, 9.54755602],
            [-154.26111129, 11.13664403],
            [-154.98244261, 12.5683012],
            [-158.26210675, 10.98523525],
            [-157.57287609, 9.61905919],
            [-157.53717632, 9.54755602],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-3",
      },
    },
    {
      type: "Feature",
      id: "sm21af9fec",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-158.26210675, 10.98523525],
            [-154.98244261, 12.5683012],
            [-155.64312748, 13.87265265],
            [-158.92630384, 12.2958437],
            [-158.26210675, 10.98523525],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-4",
      },
    },
    {
      type: "Feature",
      id: "sm59a6de6b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-158.92630384, 12.2958437],
            [-155.64312748, 13.87265265],
            [-156.36276788, 15.28507818],
            [-159.64549881, 13.70762374],
            [-158.92630384, 12.2958437],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-5",
      },
    },
    {
      type: "Feature",
      id: "smff219d2a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-159.64549881, 13.70762374],
            [-156.36276788, 15.28507818],
            [-157.80498879, 18.08647139],
            [-161.09537664, 16.52728595],
            [-159.64549881, 13.70762374],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-6",
      },
    },
    {
      type: "Feature",
      id: "sm6ec0ca06",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-161.09537664, 16.52728595],
            [-157.80498879, 18.08647139],
            [-158.55240231, 19.52130647],
            [-161.84675826, 17.97296082],
            [-161.09537664, 16.52728595],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-7",
      },
    },
    {
      type: "Feature",
      id: "sm260d401c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-161.84675826, 17.97296082],
            [-158.55240231, 19.52130647],
            [-159.23895029, 20.8281883],
            [-162.53695121, 19.29058492],
            [-161.84675826, 17.97296082],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-8",
      },
    },
    {
      type: "Feature",
      id: "sm4016d7eb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-162.53695121, 19.29058492],
            [-159.23895029, 20.8281883],
            [-159.96044195, 22.18946192],
            [-163.26227336, 20.66392462],
            [-162.53695121, 19.29058492],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-9",
      },
    },
    {
      type: "Feature",
      id: "smd3a95155",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-163.26227336, 20.66392462],
            [-159.96044195, 22.18946192],
            [-160.70823667, 23.5865673],
            [-164.01403821, 22.07433887],
            [-163.26227336, 20.66392462],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-10",
      },
    },
    {
      type: "Feature",
      id: "sm91ff1c30",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-164.01403821, 22.07433887],
            [-160.70823667, 23.5865673],
            [-161.36911693, 24.80906106],
            [-164.67842716, 23.30924243],
            [-164.01403821, 22.07433887],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-11",
      },
    },
    {
      type: "Feature",
      id: "sm6e2bb663",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-164.67842716, 23.30924243],
            [-161.36911693, 24.80906106],
            [-162.13393875, 26.20889426],
            [-165.44730951, 24.724156],
            [-164.67842716, 23.30924243],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-12",
      },
    },
    {
      type: "Feature",
      id: "sm55a8cba6",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-165.44730951, 24.724156],
            [-162.13393875, 26.20889426],
            [-162.36291515, 26.62477629],
            [-166.23010265, 26.938626],
            [-166.53771984, 26.70332001],
            [-165.44730951, 24.724156],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-13",
      },
    },
    {
      type: "Feature",
      id: "sm12421720",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-164.73192816, 6.13056011],
            [-163.99438494, 4.7427586],
            [-160.70241184, 6.3537375],
            [-161.39805225, 7.75774981],
            [-164.73192816, 6.13056011],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-17",
      },
    },
    {
      type: "Feature",
      id: "sm679201c2",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-161.39805225, 7.75774981],
            [-162.12600352, 9.22195992],
            [-165.46693703, 7.59693799],
            [-164.73192816, 6.13056011],
            [-161.39805225, 7.75774981],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-20",
      },
    },
    {
      type: "Feature",
      id: "sm7047967b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-162.12600352, 9.22195992],
            [-162.83655385, 10.64534211],
            [-166.18437625, 9.02346008],
            [-165.46693703, 7.59693799],
            [-162.12600352, 9.22195992],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-21",
      },
    },
    {
      type: "Feature",
      id: "smd043e61c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-162.83655385, 10.64534211],
            [-163.55623788, 12.08027394],
            [-166.91103771, 10.462589],
            [-166.18437625, 9.02346008],
            [-162.83655385, 10.64534211],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-24",
      },
    },
    {
      type: "Feature",
      id: "sm7ebd6174",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-163.55623788, 12.08027394],
            [-164.29487521, 13.54503312],
            [-167.65683623, 11.93270161],
            [-166.91103771, 10.462589],
            [-163.55623788, 12.08027394],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-25",
      },
    },
    {
      type: "Feature",
      id: "sm7a4d6127",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-159.72920215, 12.16446281],
            [-161.17885914, 15.02974591],
            [-164.29487521, 13.54503312],
            [-162.84521823, 10.66265955],
            [-159.72920215, 12.16446281],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-23",
      },
    },
    {
      type: "Feature",
      id: "sm685b7db4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-161.39527243, 7.75214804],
            [-160.70241184, 6.3537375],
            [-157.58639576, 7.87404592],
            [-158.27925635, 9.26738294],
            [-161.39527243, 7.75214804],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-18",
      },
    },
    {
      type: "Feature",
      id: "smdbf09d51",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-159.00951813, 10.72995735],
            [-159.72920215, 12.16446281],
            [-162.84521823, 10.66265955],
            [-162.1255342, 9.22101777],
            [-159.00951813, 10.72995735],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-22",
      },
    },
    {
      type: "Feature",
      id: "sm4dfbd39c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-162.1255342, 9.22101777],
            [-161.39527243, 7.75214804],
            [-158.27925635, 9.26738294],
            [-159.00951813, 10.72995735],
            [-162.1255342, 9.22101777],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-19",
      },
    },
    {
      type: "Feature",
      id: "sme5aeef95",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-165.08652894, 14.90711118],
            [-168.37427545, 13.33944191],
            [-167.65683623, 11.93270161],
            [-164.3759786, 13.5062605],
            [-165.08652894, 14.90711118],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-26",
      },
    },
    {
      type: "Feature",
      id: "sm76090c24",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-164.3759786, 13.5062605],
            [-161.17885914, 15.02974591],
            [-161.88940948, 16.42070592],
            [-165.08652894, 14.90711118],
            [-164.3759786, 13.5062605],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-27",
      },
    },
    {
      type: "Feature",
      id: "sm18efa809",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-165.82039439, 16.37434417],
            [-169.1277288, 14.80801859],
            [-168.37427545, 13.33944191],
            [-165.07417574, 14.91298076],
            [-165.82039439, 16.37434417],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-29",
      },
    },
    {
      type: "Feature",
      id: "smc436af0c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-165.07417574, 14.91298076],
            [-161.88940948, 16.42070592],
            [-162.63562813, 17.87083933],
            [-165.82039439, 16.37434417],
            [-165.07417574, 14.91298076],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-28",
      },
    },
    {
      type: "Feature",
      id: "smbf472b98",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-166.53339461, 17.75600798],
            [-169.84575125, 16.19835147],
            [-169.1277288, 14.80801859],
            [-165.82226664, 16.37346092],
            [-166.53339461, 17.75600798],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-30",
      },
    },
    {
      type: "Feature",
      id: "sm2c7bd214",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-165.82226664, 16.37346092],
            [-162.63562813, 17.87083933],
            [-163.34675609, 19.24187002],
            [-166.53339461, 17.75600798],
            [-165.82226664, 16.37346092],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-31",
      },
    },
    {
      type: "Feature",
      id: "smbabe5aab",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-167.2784269, 19.17550971],
            [-170.59056558, 17.63026977],
            [-169.84575125, 16.19835147],
            [-166.54076431, 17.75255687],
            [-167.2784269, 19.17550971],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-33",
      },
    },
    {
      type: "Feature",
      id: "sm981eac49",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-166.54076431, 17.75255687],
            [-163.34675609, 19.24187002],
            [-164.08441868, 20.65204997],
            [-167.2784269, 19.17550971],
            [-166.54076431, 17.75255687],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-32",
      },
    },
    {
      type: "Feature",
      id: "sm8872ff8c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-167.96107456, 20.56841381],
            [-171.31693543, 19.01586751],
            [-170.59056558, 17.63026977],
            [-167.24167935, 19.19257513],
            [-167.96107456, 20.56841381],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-34",
      },
    },
    {
      type: "Feature",
      id: "sm9954ddeb",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-167.24167935, 19.19257513],
            [-164.08441868, 20.65204997],
            [-164.80381389, 22.01483066],
            [-167.96107456, 20.56841381],
            [-167.24167935, 19.19257513],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-35",
      },
    },
    {
      type: "Feature",
      id: "sm81389bab",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-168.75225482, 22.00281024],
            [-172.08795842, 20.47409854],
            [-171.31693543, 19.01586751],
            [-167.98863523, 20.55572554],
            [-168.75225482, 22.00281024],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-37",
      },
    },
    {
      type: "Feature",
      id: "sm3e5997c1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-167.98863523, 20.55572554],
            [-164.80381389, 22.01483066],
            [-165.56743348, 23.44717161],
            [-168.75225482, 22.00281024],
            [-167.98863523, 20.55572554],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-36",
      },
    },
    {
      type: "Feature",
      id: "sm710269dd",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-169.48087192, 23.35258323],
            [-172.81607795, 21.83859857],
            [-172.08795842, 20.47409854],
            [-168.75974383, 21.99939604],
            [-169.48087192, 23.35258323],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-38",
      },
    },
    {
      type: "Feature",
      id: "sm9decbfaa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-168.75974383, 21.99939604],
            [-165.56743348, 23.44717161],
            [-166.28856157, 24.78571786],
            [-169.48087192, 23.35258323],
            [-168.75974383, 21.99939604],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-39",
      },
    },
    {
      type: "Feature",
      id: "sm3459ce4d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-170.1964296, 24.71117314],
            [-173.41516124, 23.24326681],
            [-172.81607795, 21.83859857],
            [-169.46807819, 23.35835848],
            [-170.1964296, 24.71117314],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-41",
      },
    },
    {
      type: "Feature",
      id: "smbd47f6da",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-169.46807819, 23.35835848],
            [-166.28856157, 24.78571786],
            [-167.02111828, 26.14252153],
            [-170.1964296, 24.71117314],
            [-169.46807819, 23.35835848],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-40",
      },
    },
    {
      type: "Feature",
      id: "sm3b7608f8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-173.03723497, 26.8962528],
            [-174.19519054, 24.98795272],
            [-174.17321788, 24.52903653],
            [-173.41516124, 23.24326681],
            [-171.465088, 24.13863574],
            [-173.03723497, 26.8962528],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-42",
      },
    },
    {
      type: "Feature",
      id: "sme3ae653f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-171.465088, 24.13863574],
            [-169.85009777, 24.89829833],
            [-171.08056652, 27.15878034],
            [-172.82464612, 27.22229997],
            [-173.03723497, 26.8962528],
            [-171.465088, 24.13863574],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-43",
      },
    },
    {
      type: "Feature",
      id: "sm24ebb978",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-169.85009777, 24.89829833],
            [-167.02111828, 26.14252153],
            [-167.24359148, 26.53635003],
            [-167.37542742, 26.73275991],
            [-167.72698991, 26.88964421],
            [-171.08056652, 27.15878034],
            [-169.85009777, 24.89829833],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-44",
      },
    },
    {
      type: "Feature",
      id: "smec54d3b5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-170.23678268, 15.69136519],
            [-172.71192112, 14.51413611],
            [-171.81766054, 12.75013499],
            [-169.51226837, 13.78009794],
            [-169.42437774, 14.12129003],
            [-170.23678268, 15.69136519],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-52",
      },
    },
    {
      type: "Feature",
      id: "smf51158ac",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-172.71192112, 14.51413611],
            [-170.23678268, 15.69136519],
            [-171.09429962, 17.33559255],
            [-171.35797149, 17.46139672],
            [-173.66304988, 16.37497281],
            [-172.71192112, 14.51413611],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-53",
      },
    },
    {
      type: "Feature",
      id: "sm8a924caf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.01234561, 13.38323174],
            [-172.69923941, 14.48921426],
            [-173.66304988, 16.37497281],
            [-174.34625274, 16.05178163],
            [-174.34625274, 16.05178163],
            [-176.16994867, 15.08252796],
            [-175.01234561, 13.38323174],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-54",
      },
    },
    {
      type: "Feature",
      id: "sm7624bf67",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-164.72772237, 0.61731277],
            [-166.26031524, 3.80468052],
            [-167.95770285, 2.84637782],
            [-165.99664337, -0.14759822],
            [-164.72772237, 0.61731277],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-46",
      },
    },
    {
      type: "Feature",
      id: "sm07cd09ad",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-166.25482199, 3.78412632],
            [-164.72772237, 0.61731277],
            [-163.44781524, 1.61000219],
            [-163.31597931, 1.82962964],
            [-164.45855743, 4.24304927],
            [-164.89801056, 4.41832856],
            [-166.07692807, 3.88731401],
            [-166.25482199, 3.78412632],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-45",
      },
    },
    {
      type: "Feature",
      id: "sm3f4e1d8c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-167.95770285, 2.84637782],
            [-166.07692807, 3.88731401],
            [-166.26031524, 3.80468052],
            [-166.61187774, 4.33069399],
            [-165.11773712, 5.07524371],
            [-165.11773712, 5.51282119],
            [-165.85931418, 6.98302928],
            [-169.4848025, 5.16962236],
            [-167.95770285, 2.84637782],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-47",
      },
    },
    {
      type: "Feature",
      id: "sm62a40ccf",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-169.4848025, 5.16962236],
            [-165.85931418, 6.98302928],
            [-168.02927215, 11.27101277],
            [-172.00341823, 8.90341822],
            [-169.4848025, 5.16962236],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-48",
      },
    },
    {
      type: "Feature",
      id: "sm26c17651",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-171.24536158, 12.19174702],
            [-170.10553004, 10.03582525],
            [-168.02927215, 11.27101277],
            [-168.94097931, 13.05342206],
            [-169.24859649, 13.13902672],
            [-171.24536158, 12.19174702],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-49",
      },
    },
    {
      type: "Feature",
      id: "smf3b9f8d4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-170.09179713, 10.04123432],
            [-170.55322292, 10.92706388],
            [-171.24536158, 12.19174702],
            [-171.88256861, 11.9338975],
            [-171.88256861, 11.9338975],
            [-173.37670924, 11.11577842],
            [-172.00341823, 8.90341822],
            [-170.09179713, 10.04123432],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-50",
      },
    },
    {
      type: "Feature",
      id: "sm8de83343",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-173.37670924, 11.11577842],
            [-171.88256861, 11.9338975],
            [-172.25610377, 12.5137061],
            [-172.25610377, 12.5137061],
            [-171.81766054, 12.72870324],
            [-172.69923941, 14.46793943],
            [-175.01234561, 13.36185483],
            [-173.37670924, 11.11577842],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-51",
      },
    },
    {
      type: "Feature",
      id: "sm4d1ca104",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-172.34631152, 19.85017218],
            [-175.17022734, 18.59486846],
            [-174.96665716, 18.31993344],
            [-174.07708769, 16.95766451],
            [-171.78369169, 18.00684381],
            [-171.64086943, 18.36172306],
            [-172.34631152, 19.85017218],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-56",
      },
    },
    {
      type: "Feature",
      id: "sm2ee9eda5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-175.17022734, 18.59486846],
            [-172.34631152, 19.85017218],
            [-173.18195435, 21.38883186],
            [-176.25787382, 20.04370421],
            [-175.17022734, 18.59486846],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-57",
      },
    },
    {
      type: "Feature",
      id: "sm9303ebf0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.25787382, 20.04370421],
            [-173.18195435, 21.38883186],
            [-174.56597931, 23.90156047],
            [-174.80767853, 23.92164726],
            [-176.6753543, 20.58718106],
            [-176.25787382, 20.04370421],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-59",
      },
    },
    {
      type: "Feature",
      id: "sme273924e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-177.35650665, 16.79996886],
            [-174.96665716, 18.31993344],
            [-176.6753543, 20.58718106],
            [-178.12554962, 17.92193168],
            [-177.35650665, 16.79996886],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-58",
      },
    },
    {
      type: "Feature",
      id: "sm94bbd3ce",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.96665716, 18.31993344],
            [-177.35650665, 16.79996886],
            [-176.16994867, 15.08252796],
            [-174.34625274, 16.05178163],
            [-174.69781524, 16.66319317],
            [-174.07708769, 16.95766451],
            [-174.96665716, 18.31993344],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-55",
      },
    },
    {
      type: "Feature",
      id: "sm0b1c814c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-169.65509064, 27.68416305],
            [-162.76666297, 27.31385096],
            [-165.09576454, 31.53233728],
            [-165.52423134, 31.56042548],
            [-167.75445587, 31.74279256],
            [-168.76519806, 31.76147662],
            [-169.29254181, 32.04128438],
            [-169.65509064, 27.68416305],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-64",
      },
    },
    {
      type: "Feature",
      id: "smb1081d48",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-169.29254181, 32.04128438],
            [-169.29254181, 32.04128438],
            [-169.34747352, 32.10645029],
            [-169.95172156, 32.67235241],
            [-171.20416302, 33.624365],
            [-171.92926067, 33.84364084],
            [-173.0718388, 33.91660812],
            [-173.0718388, 33.91660812],
            [-174.14849895, 33.82538925],
            [-172.96197552, 28.12107104],
            [-172.96197053, 28.12105409],
            [-172.65435828, 27.83971011],
            [-170.2263797, 27.72307066],
            [-169.65509064, 27.68416305],
            [-169.29254181, 32.04128438],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-63",
      },
    },
    {
      "type": "Feature",
      "id": "sm34215c6c",
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [-194.31115698, 42.88957113],
          [-185.2584226, 46.11658277],
          [-179.89434855, 30.86984078],
          [-179.23516878, 29.8603233],
          [-179.34503206, 29.13359646],
          [-180.26788362, 27.74251926],
          [-181.68512003, 22.90340392],
          [-183.76428198, 22.81355757],
          [-185.12658666, 22.93502589],
          [-194.31115698, 42.88957113]
        ]
      },
      "properties": {}
    },
    {
      type: "Feature",
      id: "smd6645201",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-181.68512003, 22.90340392],
            [-178.54303018, 18.52196315],
            [-177.48834268, 20.36589973],
            [-176.41168246, 22.54873654],
            [-180.26788362, 27.74251926],
            [-180.26788362, 27.74251926],
            [-180.82818637, 26.01300537],
            [-181.42144809, 24.24260969],
            [-181.68512003, 22.90340392],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-60",
      },
    },
    {
      type: "Feature",
      id: "sm0a8cbff3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-176.41168246, 22.54873654],
            [-174.69781526, 25.5777773],
            [-178.85064729, 31.92946336],
            [-179.89434855, 30.86984078],
            [-179.39996424, 30.26680344],
            [-179.23516878, 29.8603233],
            [-179.34503206, 29.13359646],
            [-180.19097934, 27.93196092],
            [-180.26788362, 27.74251926],
            [-176.41168246, 22.54873654],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-61",
      },
    },
    {
      type: "Feature",
      id: "sm62db4d9e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-174.69781526, 25.5777773],
            [-173.63214142, 27.30408915],
            [-173.57720978, 27.75710343],
            [-174.91754191, 33.88469261],
            [-174.91754191, 33.88469261],
            [-175.9282841, 33.84820311],
            [-176.67535441, 33.628939],
            [-177.11480754, 33.37242381],
            [-177.48834269, 33.08753999],
            [-178.85064729, 31.92946336],
            [-174.69781526, 25.5777773],
          ],
        ],
      },
      properties: {
        Unit_Number: "W3-62",
      },
    },
  ],
};

export default geojsonData;
