import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const mapMetricName = (key) => {
  switch (key) {
    case "contactsObtained":
      return "Contacts Obtained";
    case "siteVisits":
      return "Site Visits";
    case "salesAchieved":
      return "Sales Achieved";
    case "referrals":
      return "Referrals";
    case "onQueue":
      return "On Queue";
    default:
      return key;
  }
};

const mapMonthName = (month) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[month - 1] || "";
};

const CountyBarChart = ({ counties, selectedMonth }) => {
  if (!counties || counties.length === 0) {
    console.log("No data available for the chart");
    return <p>No data available for the chart</p>;
  }

  // Create an object to store the sums for each team
  const teamSums = {};

  // Filter counties based on the selected month
  const filteredCounties = selectedMonth
    ? counties.filter((county) => {
        const month = new Date(county.endDate).getMonth() + 1; // Assuming endDate is the field containing the end date
        return month.toString() === selectedMonth;
      })
    : counties;

  console.log("Filtered Counties:", filteredCounties);

  filteredCounties.forEach((county) => {
    const teamName = county.region;

    if (!teamSums[teamName]) {
      teamSums[teamName] = {
        contactsObtained: 0,
        siteVisits: 0,
        salesAchieved: 0,
        referrals: 0,
        onQueue: 0,
      };
    }

    Object.keys(teamSums[teamName]).forEach((key) => {
      teamSums[teamName][key] += county[key] || 0;
    });
  });

  console.log("Team Sums:", teamSums);

  const chartData = Object.keys(teamSums).map((teamName) => ({
    region: teamName,
    contactsObtained: teamSums[teamName].contactsObtained,
    siteVisits: teamSums[teamName].siteVisits,
    salesAchieved: teamSums[teamName].salesAchieved,
    referrals: teamSums[teamName].referrals,
    onQueue: teamSums[teamName].onQueue,
  }));

  console.log("Chart Data:", chartData);

  if (chartData.length === 0) {
    console.log("No data available for the selected month");
    return <p>No data available for the selected month</p>;
  }

  const chartDatasets = Object.keys(chartData[0])
    .filter((key) => key !== "region")
    .map((key, index) => ({
      label: mapMetricName(key),
      dataKey: key,
      fill: `#${
        index === 0
          ? "3b3a52"
          : index === 1
          ? "6caeca"
          : index === 2
          ? "b9d7e4"
          : index === 3
          ? "c4b4a3"
          : index === 4
          ? "ced7ba"
          : "33FFFF"
      }`,
    }));

  return (
    <div>
      <h2 className="text-center mb-4">
        {selectedMonth
          ? `Performance for ${mapMonthName(parseInt(selectedMonth))}`
          : "Overall Performance"}
      </h2>
      <BarChart
        width={600}
          height={300}
        data={chartData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="region" />
        <YAxis />
        <Tooltip />
        <Legend />
        {chartDatasets.map((dataset, index) => (
          <Bar
            key={index}
            dataKey={dataset.dataKey}
            fill={dataset.fill}
            name={dataset.label}
          />
        ))}
      </BarChart>
    </div>
  );
};

export default CountyBarChart;
