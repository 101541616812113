import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

function formatDate(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function formatDateToTheDatabase(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const timezoneOffset = -date.getTimezoneOffset();
  const offsetHours = Math.floor(timezoneOffset / 60);
  const offsetMinutes = timezoneOffset % 60;

  const formattedOffset = `${offsetHours >= 0 ? '+' : ''}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000${formattedOffset}`;
}

const EditTask = () => {
  // finding the supplierId
  const loc = useLocation();
  const url = loc.pathname;
  const taskId = url.split("/")[2];
  const navigate = useNavigate();
  function handleGoBack() {
    navigate(-1); // navigates back to the previous page in the history stack
  }

  const [title, setTitle] = useState("");
  const [cost, setCost] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expectedDateOfCompletion, setExpectedDateOfCompletion] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch the supplier data from the backend API
    axios
      .get(`https://workspace.optiven.co.ke/api/tasks/${taskId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
       
        setTitle(res.data.title);
        setCost(res.data.cost);
        setStatus(res.data.status);
        setStartDate(res.data.startDate);
        setExpectedDateOfCompletion(res.data.expectedDateOfCompletion);
        
      })
      .catch((err) => {
        console.log(err);
      });
  }, [taskId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const task = {
      title, 
      cost,
      status,
      startDate:formatDateToTheDatabase(startDate),
      expectedDateOfCompletion:formatDateToTheDatabase(expectedDateOfCompletion),
      
    };
  
    try {
      const response = await fetch(`https://workspace.optiven.co.ke/api/tasks/${taskId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(task),
      });
  
      const responseData = await response.json();
  
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to update task");
      }
  
      toast.success("Task updated!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
      handleGoBack();
    } catch (error) {
      console.error("Fetch error:", error);
      toast.error(`Error: ${error.message}`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  
  
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <form onSubmit={handleSubmit} className="form-control w-full max-w-xs">
          <label htmlFor="title" className="label">
            <span className="label-text font-bold">Title</span>
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          
          <label htmlFor="status" className="label">
            <span className="label-text font-bold">Status</span>
          </label>
          <input
            type="text"
            id="status"
            value={status}
            onChange={(event) => setStatus(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          
          <div className="lg:flex justify-between">
            <div className="w-36">
              <label className="label" htmlFor="startDate">
                <span className="label-text font-bold">Start Date: </span>
              </label>
              <input
                type="date"
                className="input input-bordered w-full max-w-xs"
                id="startDate"
                value={formatDate(startDate)}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </div>
            <div className="w-36">
              <label className="label" htmlFor="expectedDateOfCompletion">
                <span className="label-text font-bold">
                  Expected End Date:{" "}
                </span>
              </label>
              <input
                type="date"
                className="input input-bordered w-full max-w-xs"
                id="expectedDateOfCompletion"
                value={formatDate(expectedDateOfCompletion)}
                onChange={(event) =>
                  setExpectedDateOfCompletion(event.target.value)
                }
              />
            </div>
          </div>
         
          
          <label htmlFor="cost" className="label">
            <span className="label-text font-bold">Cost (KES)</span>
          </label>
          <input
            type="text"
            id="cost"
            value={cost}
            onChange={(event) => setCost(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
         
         
          <button
            type="submit"
            disabled={loading}
            id="submit"
            className="btn btn-primary w-full max-w-xs mt-4 text-white"
          >
            {loading ? "Saving..." : "Update Task"}
          </button>
        </form>
      </div>
    </Sidebar>
  );
};

export default EditTask;
