import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

function BookingSummary() {
  const [bookings, setBookings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    try {
      const response = await fetch("https://workspace.optiven.co.ke/api/event-bookings");
      const data = await response.json();
      setBookings(data);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Booking Summary</h1>
      <table className="min-w-full divide-y divide-gray-200 overflow-x-auto font-[Poppin]">
        <thead className="bg-gray-50 ">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Booking ID
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Purpose
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Venue
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Description
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {bookings.length > 0 ? (
            bookings.map((booking) => (
              <tr key={booking.bookingId}>
                <td className="px-6 py-3 whitespace-nowrap">{booking.id}</td>
                <td className="px-6 py-3 whitespace-nowrap">{booking.purpose}</td>
                <td className="px-6 py-3 whitespace-nowrap">{booking.venue}</td>
                <td className="px-6 py-3 whitespace-nowrap">
                  {format(new Date(booking.date), "dd/MM/yyyy")}
                </td>
                <td className="px-6 py-3 whitespace-nowrap">{booking.description}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="px-6 py-3 whitespace-nowrap text-center" colSpan="5">
                No bookings available
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <button
        onClick={() => navigate("/event-booking")}
        className="mt-4 inline-block rounded-md border border-indigo-600 bg-indigo-600 px-4 py-2 text-sm font-medium text-white transition hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
      >
        Book Another Event
      </button>
    </div>
  );
}

export default BookingSummary;
