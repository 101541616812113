import axios from "axios";
import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";


const EditSupplier = () => {
  // finding the supplierId
  const loc = useLocation();

  const url = loc.pathname;

  
  const supplierId = url.split("/")[2];

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [id_number, setIdNumber] = useState("");
  const [supplies, setSupplies] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch the supplier data from the backend API
    if (supplierId) {
      axios
        .get(`https://workspace.optiven.co.ke/api/suppliers/${supplierId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        }
        )
        .then((res) => {
          setName(res.data.name);
          setProjectId(res.data.projectId);
          setPhoneNumber(res.data.phoneNumber);
          setLocation(res.data.location);
          setIdNumber(res.data.id_number);
          setEmail(res.data.email);
          setSupplies(res.data.supplies);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [supplierId]);


  useEffect(() => {
    // Fetch all the projects from the db
    axios
      .get(`https://workspace.optiven.co.ke/api/projects/all`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const supplier = {
      name,
      projectId,
      phoneNumber,
      location,
      email,
      id_number,
      supplies,
    };

    // Send a PATCH request to update the supplier data in the backend API
    axios
      .patch(`https://workspace.optiven.co.ke/api/suppliers/${supplierId}`, supplier,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
        setLoading(false);
        toast.success("Supplier updated!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(res.data);
        navigate("/suppliers");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <form onSubmit={handleSubmit} className="form-control w-full max-w-xs">
          <label htmlFor="name" className="label">
            <span className="label-text font-bold">Name</span>
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="project" className="label">
            <span className="label-text font-bold">Project</span>
          </label>
          <select
            id="project"
            as="select"
            onChange={(event) => {
              setProjectId(event.target.value);
            }}
            value={projectId}
            className="select select-bordered"
           
            
          >
            {projects.map((project) => (
              <option key={crypto.randomUUID()} value={project.id}>
                {project.project_name}
              </option>
            ))}
          </select>
          <label htmlFor="location" className="label">
            <span className="label-text font-bold">Location</span>
          </label>
          <input
            type="text"
            id="location"
            value={location}
            onChange={(event) => setLocation(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="id_number" className="label">
            <span className="label-text font-bold">Id Number</span>
          </label>
          <input
            type="text"
            id="id_number"
            value={id_number}
            onChange={(event) => setIdNumber(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          
          <label htmlFor="phoneNumber" className="label">
            <span className="label-text font-bold">Phone Number</span>
          </label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="email" className="label">
            <span className="label-text font-bold">Email</span>
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="supplies" className="label">
            <span className="label-text font-bold">
              Goods/Services Supplied
            </span>
          </label>
          <textarea
            id="supplies"
            value={supplies}
            onChange={(event) => setSupplies(event.target.value)}
            className="textarea textarea-bordered w-full max-w-xs h-24"
          />
          <button
            type="submit"
            disabled={loading}
            id="submit"
            className="btn btn-primary w-full max-w-xs mt-4 text-white"
          >
            {loading ? "Saving..." : "Update Supplier"}
          </button>
        </form>
      </div>
    </Sidebar>
  );
};

export default EditSupplier;
