import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreatePayment = () => {
  const [projectData, setProjectData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/casual-labourers",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();

        setEmployeeData(data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, []);

  // Handler for selecting an employee
  const handleEmployeeSelection = (event) => {
    const selectedIdNumber = event.target.value;
    const selectedEmployee = employeeData.find(
      (employee) => employee.id_number === selectedIdNumber
    );
    setSelectedEmployee(selectedEmployee || {});
  };

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/projects/all",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const data = await response.json();

        // Ensure that data is an array before setting the state
        setProjectData(data);
      } catch (error) {
        console.error("Error fetching projects data:", error);
      }
    };

    fetchProjectsData();
  }, []);

  // const handleEmployeeChange = (employeeId) => {
  //   const selected = employeeData.find(
  //     (emp) => emp.id === parseInt(employeeId, 10)
  //   );
  //   setSelectedEmployee(selected || {});
  // };
  const validationSchema = Yup.object({
    payment_type: Yup.string().required("Payment type is required"),
    start_date: Yup.date().required("Start date is required"),
    end_date: Yup.date()
      .required("Expected end date is required")
      .min(Yup.ref("start_date"), "Expected end date must be after start date"),
  });
  const initialValues = {
    employee: "",
    project: "",
    payment_type: "",
    start_date: "",
    end_date: "",
  };

  const handleSubmit = async (values) => {
    try {
      if (selectedEmployee.id) {
        // Handle employee payment submission
        const response = await axios.post(
          "https://workspace.optiven.co.ke/api/value_addition_payments",
          {
            employee: selectedEmployee,
            payment_type: values.payment_type,
            start_date: values.start_date,
            end_date: values.end_date,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        console.log("Server response:", response.data);
      }
      toast.success("Payment added successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate("/update_payments");
    } catch (error) {
      console.error("Error submitting payment:", error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <Sidebar>
        <div className="bg-gray-100 dark:bg-gray-900">
          <div className="w-full max-w-3xl mx-auto p-8">
            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md border dark:border-gray-700">
              <h1 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
                Employee Payment
              </h1>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form>
                    <div className="mb-6">
                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="employee"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Payment Type
                          </label>
                          <Field
                            as="select"
                            id="payment_type"
                            name="payment_type"
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                            onChange={formik.handleChange}
                          >
                            <option value="">Select Payment Type</option>
                            <option value="value addition">
                              Value addition
                            </option>
                            <option value="maintenance">
                              Project Maintenance
                            </option>
                          </Field>
                          <ErrorMessage
                            name="payment_type"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="employee"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Search or Select Employee
                          </label>
                          <Field
                            as="input"
                            type="text"
                            list="employee-list"
                            id="employee"
                            placeholder="Select or search employee"
                            className="w-full rounded-lg border py-2 px-8 dark:bg-gray-700 dark:text-white dark:border-none"
                            value={
                              selectedEmployee
                                ? selectedEmployee.name ||
                                  selectedEmployee.id_number
                                : ""
                            }
                            onChange={handleEmployeeSelection}
                          />

                          <datalist id="employee-list" className="mt-1">
                            {employeeData.map((employee, index) => (
                              <option
                                key={employee.id_number}
                                value={employee.id_number}
                                className="bg-white text-black px-4 py-2"
                              >
                                {`${employee.name} (${employee.id_number})`}
                              </option>
                            ))}
                          </datalist>
                          <ErrorMessage
                            name="employee"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="first_name"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Project
                          </label>
                          <Field
                            as="select"
                            id="project"
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                            value={selectedEmployee.project_id}
                            readOnly
                          >
                            <option value="">Select a project</option>
                            {projectData.map((project) => (
                              <option key={project.id} value={project.id}>
                                {project.project_name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="id_number"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            ID Number
                          </label>
                          <Field
                            type="text"
                            id="id_number"
                            value={selectedEmployee.id_number || ""}
                            readOnly
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="phone"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Contact
                          </label>
                          <Field
                            type="text"
                            id="phone"
                            value={selectedEmployee.phone || ""}
                            readOnly
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2 className="text-xl font-semibold text-gray-700 dark:text-white mb-2">
                        Payment Information
                      </h2>

                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2 gap-4 mt-4">
                        <div className="w-44">
                          <label className="label" htmlFor="start_date">
                            <span className="label-text font-bold">
                              Start Date:{" "}
                            </span>
                          </label>
                          <Field
                            type="date"
                            className="input input-bordered w-full max-w-sm"
                            id="start_date"
                            name="start_date"
                          />
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                        <div className="w-44 ">
                          <label className="label" htmlFor="end_date">
                            <span className="label-text font-bold">
                              Expected End Date:{" "}
                            </span>
                          </label>
                          <Field
                            type="date"
                            className="input input-bordered w-full max-w-sm"
                            id="end_date"
                            name="end_date"
                          />
                          <ErrorMessage
                            name="end_date"
                            component="div"
                            className="text-red-500"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div>
                          <label
                            htmlFor="description"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Description
                          </label>
                          <Field
                            as="textarea"
                            type="text"
                            id="description"
                            value={selectedEmployee.work_description}
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                            readOnly
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="custom_rate_per_day"
                            className="block text-gray-700 dark:text-white mb-1"
                          >
                            Rate Per Day
                          </label>
                          <input
                            type="text"
                            id="custom_rate_per_day"
                            value={selectedEmployee.rate_per_day || ""}
                            className="w-full rounded-lg border py-2 px-3 dark:bg-gray-700 dark:text-white dark:border-none"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-8 flex justify-end">
                      <button
                        className="bg-green-700 text-white px-4 py-2 rounded-lg hover:bg-teal-700 dark:bg-teal-600 dark:text-white dark:hover:bg-teal-900"
                        disabled={!formik.isValid || formik.isSubmitting}
                        type="submit"
                        id="submit"
                      >
                        {formik.isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CreatePayment;
