import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { PlusCircle, MinusCircle } from "react-feather";
import { useNavigate } from "react-router-dom";

const County = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  const [countyForms, setCountyForms] = useState([
    {
      region: "",
      countyActivated: "",
      contactsObtained: "",
      siteVisits: "",
      salesAchieved: "",
      referrals: "",
      onQueue: "",
      startDate: "", // Add startDate property
      endDate: "",
    },
  ]);
  const [regionOptions, setRegionOptions] = useState([]);

  useEffect(() => {
    // Update the start and end dates whenever the currentMonth, startDate, or endDate changes
    setCountyForms((prevForms) => {
      return prevForms.map((form) => {
        return {
          ...form,
          startDate,
          endDate,
        };
      });
    });
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchRegionOptions = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/category"
        );
        if (!response.ok) {
          throw new Error("An error occurred while fetching region options");
        }
        const data = await response.json();

        const categories = data.map((item) => item.category);
        setRegionOptions(categories);
      } catch (error) {
        console.error("Error fetching region options:", error);
      }
    };

    fetchRegionOptions();
  }, []);

  const addCountyForm = () => {
    setCountyForms([
      ...countyForms,
      {
        region: "",
        countyActivated: "",
        contactsObtained: "",
        siteVisits: "",
        salesAchieved: "",
        referrals: "",
        onQueue: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };
  const removeTask = (index) => {
    if (countyForms.length > 1) {
      const updatedCountyForms = [...countyForms];
      updatedCountyForms.splice(index, 1);
      setCountyForms(updatedCountyForms);
    }
  };

  const handleCountyChange = (index, field, value) => {
    const updatedCountyForms = countyForms.map((form, i) => {
      if (i === index) {
        return { ...form, [field]: value };
      }
      return form;
    });
    setCountyForms(updatedCountyForms);
  };

  const handleSubmitCounty = async () => {
    // Check if both start and end dates are selected
    if (!startDate || !endDate) {
      toast.error("Both dates must be chosen before submitting the form.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Convert start and end dates to JavaScript Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Check if the end date is before the start date
    if (endDateObj < startDateObj) {
      toast.error("End date cannot be before start date.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (
      !countyForms.every(
        (form) =>
          form.region &&
          form.countyActivated &&
          form.contactsObtained &&
          form.siteVisits &&
          form.salesAchieved &&
          form.referrals &&
          form.onQueue
      )
    ) {
      // Display error message for missing required fields
      toast.error("Please fill in all required fields.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent form submission if there are empty fields
    }

    try {
      // Proceed with form submission
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/county",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(countyForms),
        }
      );

      if (!response.ok) {
        throw new Error("An error occurred while submitting county forms");
      }

      toast.success("County forms submitted successfully!", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setCountyForms([
        {
          region: "",
          countyActivated: "",
          contactsObtained: "",
          siteVisits: "",
          salesAchieved: "",
          referrals: "",
          onQueue: "",
        },
      ]);
      navigate("/regional-events");
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.error(error);
    }
  };

  return (
    <Sidebar>
      <div className="flex justify-center items-center mx-10 my-10 flex flex-wrap gap-8">
        {countyForms.map((form, index) => (
          <div key={index} className="card w-96 bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title text-2xl font-semibold mb-4">
                Activation Form
              </h2>
              <form action="#" className="space-y-4">
                {/* Start Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                {/* End Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                {/* Select Region */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Select Region
                  </label>
                  {regionOptions.length > 0 ? (
                    <select
                      className="select select-bordered select-primary w-full max-w-xs"
                      value={form.region}
                      onChange={(e) =>
                        handleCountyChange(index, "region", e.target.value)
                      }
                    >
                      <option value="">Select Region</option>
                      {regionOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p>Loading region options...</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    County Activated
                  </label>
                  <textarea
                    className="textarea textarea-bordered textarea-primary w-full max-w-xs"
                    value={form.countyActivated}
                    onChange={(e) =>
                      handleCountyChange(
                        index,
                        "countyActivated",
                        e.target.value
                      )
                    }
                  ></textarea>
                </div>

                {/* Contacts Obtained */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Contacts Obtained
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.contactsObtained}
                    onChange={(e) =>
                      handleCountyChange(
                        index,
                        "contactsObtained",
                        e.target.value
                      )
                    }
                  />
                </div>

                {/* Site Visits */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Site Visits
                  </label>
                  <input
                    type="text"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.siteVisits}
                    onChange={(e) =>
                      handleCountyChange(index, "siteVisits", e.target.value)
                    }
                  />
                </div>

                {/* Sales Achieved */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Sales Achieved
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.salesAchieved}
                    onChange={(e) =>
                      handleCountyChange(index, "salesAchieved", e.target.value)
                    }
                  />
                </div>

                {/* Referrals */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Referrals
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.referrals}
                    onChange={(e) =>
                      handleCountyChange(index, "referrals", e.target.value)
                    }
                  />
                </div>

                {/* On Queue */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    On Queue
                  </label>
                  <input
                    type="text"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.onQueue}
                    onChange={(e) =>
                      handleCountyChange(index, "onQueue", e.target.value)
                    }
                  />
                </div>
                {/* Remove button for additional forms */}
                <div className="w-full flex justify-center mt-4 space-x-4">
                  {index !== 0 && (
                    <button
                      onClick={() => removeTask(index)}
                      className="btn btn-danger btn-circle text-white"
                      style={{ backgroundColor: "#df4747" }}
                    >
                      <MinusCircle size={30} />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center space-x-4">
        <button
          className="btn btn-primary"
          type="button"
          onClick={addCountyForm}
        >
          <PlusCircle size={30} />
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSubmitCounty}
        >
          Submit County Forms
        </button>
      </div>
    </Sidebar>
  );
};

export default County;
