import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Home,
  Aperture,
  Target,
  Upload,
  Calendar,
  Briefcase,
  Navigation,
  Crosshair,
} from "react-feather";

const Sidebar = ({ children }) => {
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");

  // Define access role checks
  const isIvoryAdmin = accessRoles.includes("isIvoryAdmin");
  const isIvoryDirector = accessRoles.includes("isIvoryDirector");
  const isIvoryTeam = accessRoles.includes("isIvoryTeam");
  const isIvoryHos = accessRoles.includes("isIvoryHos");
  const isIvoryGm = accessRoles.includes("isIvoryGm");

  return (
    <>
      <div className="drawer">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content overflow-visible">{children}</div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 bg-base-100 text-base-content">
            {/* Home */}
            <li>
              <Link
                to="/home"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-black hover:bg-gray-100 dark:hover:bg-gray-700 group"
              >
                <Home size={20} />
                <span className="danger tracking-wider font-thick">Home</span>
              </Link>
            </li>

            {/* Admin Dashboard */}
            {(isIvoryAdmin || isIvoryDirector || department === "ICT SYSTEMS") && (
              <li>
                <Link
                  to="/Admin"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-black hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <Aperture size={20} />
                  <span className="danger tracking-wider font-thin">
                    Admin Dashboard
                  </span>
                </Link>
              </li>
            )}
            {/* Sales Dashboard */}
            {(isIvoryAdmin || isIvoryDirector || department === "ICT SYSTEMS") && (
              <li>
                <Link
                  to="/sales-dash"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-black hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <Aperture size={20} />
                  <span className="danger tracking-wider font-thin">
                    Sales Dashboard
                  </span>
                </Link>
              </li>
            )}

            {/* Dashboard */}
            {(isIvoryTeam ||
              isIvoryHos ||
              isIvoryGm ||
              department === "ICT SYSTEMS") && (
              <li>
                <Link
                  to="/dash"
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-black hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <Aperture size={20} />
                  <span className="danger tracking-wider font-thick">
                    Dashboard
                  </span>
                </Link>
              </li>
            )}

            {/* Targets */}
            {(isIvoryAdmin || isIvoryDirector || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold flex items-center">
                  <Target size={20} className="mr-2" /> Targets
                </div>

                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/create-tasks"
                  >
                    Create Targets
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/view-tasks"
                  >
                    View Assigned Targets
                  </Link>
                </div>
              </div>
            )}

            {/* FOW */}
            {(isIvoryAdmin || isIvoryDirector || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold flex items-center">
                  <Crosshair size={20} className="mr-2" /> Focus Of The Week
                </div>

                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/create-fow"
                  >
                    Create FOW
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/view-fow"
                  >
                    View FOW
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/approve-fow"
                  >
                    Approve FOW
                  </Link>
                  
                </div>
              </div>
            )}

            {/* Region Reports */}
            {(isIvoryTeam ||
              isIvoryHos ||
              isIvoryAdmin||
              isIvoryGm ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold flex items-center">
                  <Upload size={20} className="mr-2" /> Region Updates
                </div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                {(isIvoryTeam ||
                    department === "ICT SYSTEMS") && (
                      <>
                        <Link
                          className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                          to="/monthly-sales"
                        >
                          Update Monthly Sales
                        </Link>
                        <Link
                          className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                          to="/referal"
                        >
                          Update Referral Business
                        </Link>
                      </>
                    )}

                  {(isIvoryTeam ||
                    isIvoryGm ||
                    isIvoryAdmin ||
                    isIvoryHos ||
                    department === "ICT SYSTEMS") && (
                    <>
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/view-monthly-sales"
                      >
                        View Monthly Sales
                      </Link>
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/view-referal"
                      >
                        View Referral Data
                      </Link>
                    </>
                  )}
                </div>
              </div>
            )}

            {/* Activations */}
            {(isIvoryTeam || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold flex items-center">
                  <Navigation size={20} className="mr-2" /> Activation
                </div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/county"
                  >
                    Update Activations
                  </Link>
                  

                  
                </div>
              </div>
            )}
            {/* View Activations */}
            {(isIvoryTeam ||
              isIvoryHos ||
              isIvoryGm ||
              isIvoryAdmin ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold flex items-center">
                  <Navigation size={20} className="mr-2" /> Visualize Activation
                  Data
                </div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/view-county"
                  >
                    Visualize Activation
                  Data
                  </Link>

                  
                </div>
              </div>
            )}

            {/* Expos/Events */}
            {(isIvoryTeam ||
              isIvoryGm ||
              isIvoryHos ||
              isIvoryAdmin ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold flex items-center">
                  <Briefcase size={20} className="mr-2" /> Expos/Events
                </div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  {(isIvoryTeam || department === "ICT SYSTEMS") && (
                    <>
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/regional-events"
                      >
                        Update Regional Events
                      </Link>
                    </>
                  )}

                  {(isIvoryTeam ||
                    isIvoryGm ||
                    isIvoryHos ||
                    isIvoryAdmin ||
                    department === "ICT SYSTEMS") && (
                    <>
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/view-regional-events"
                      >
                        View Regional Events
                      </Link>
                    </>
                  )}
                </div>
              </div>
            )}

            {/* Monthly Reports */}
            {(isIvoryHos ||
              isIvoryGm ||
              isIvoryAdmin ||
              isIvoryDirector ||
              department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold flex items-center">
                  <Calendar size={20} className="mr-2" /> Monthly Reports
                </div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/monthly-report"
                  >
                    View Monthly Sales Reports
                  </Link>

                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/referral-report"
                  >
                    View Referal Business Reports
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/activation-report"
                  >
                    View Activation Reports
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/regional-event-report"
                  >
                    View Event Reports
                  </Link>
                </div>
              </div>
            )}

            {/* ... (Continue adding other sections based on access roles) */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
