import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { PlusCircle, MinusCircle } from "react-feather";
import { useNavigate } from "react-router-dom";

const MonthlySales = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();

  const [salesForms, setSalesForms] = useState([
    {
      OceanViewRidge: "",
      Malindi: "",
      GreatOasis: "",
      Ushindi: "",
      AchieversCommercial: "",
      Wema: "",
      JoyLovers: "",
      kithimani: "",
      team: "",
      totalSales: "",
      observation: "",
      recommendation: "",
      startDate: "",
      endDate: "",
    },
  ]);
  const [teamOptions, setTeamOptions] = useState([]);

  useEffect(() => {
    const fetchTeamOptions = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/category"
        );
        if (!response.ok) {
          throw new Error("An error occurred while fetching team options");
        }
        const data = await response.json();

        const categories = data.map((item) => item.category);
        setTeamOptions(categories);
      } catch (error) {
        console.error("Error fetching team options:", error);
      }
    };

    fetchTeamOptions();
  }, []);

  useEffect(() => {
    // Update the start and end dates whenever the currentMonth, startDate, or endDate changes
    setSalesForms((prevForms) => {
      return prevForms.map((form) => {
        return {
          ...form,
          startDate,
          endDate,
        };
      });
    });
  }, [startDate, endDate]);

  const addSalesForm = () => {
    setSalesForms([
      ...salesForms,
      {
        OceanViewRidge: "",
        Malindi: "",
        GreatOasis: "",
        Ushindi: "",
        AchieversCommercial: "",
        Wema: "",
        JoyLovers: "",
        kithimani: "",
        team: "",
        totalSales: "",
        observation: "",
        recommendation: "",
        startDate: "",
        endDate: "",
      },
    ]);
  };

  const removeTask = (index) => {
    if (salesForms.length > 1) {
      const updatedSalesForms = [...salesForms];
      updatedSalesForms.splice(index, 1);
      setSalesForms(updatedSalesForms);
    }
  };

  const handleSalesChange = (index, field, value) => {
    setSalesForms((prevForms) => {
      return prevForms.map((form, i) => {
        if (i === index) {
          let updatedForm = { ...form, [field]: value };

          // Calculate totalSales
          if (
            field === "OceanViewRidge" ||
            field === "Malindi" ||
            field === "GreatOasis" ||
            field === "Ushindi" ||
            field === "AchieversCommercial" ||
            field === "Wema" ||
            field === "JoyLovers" ||
            field === "kithimani"
          ) {
            const oceanView = parseFloat(updatedForm.OceanViewRidge) || 0;
            const malindi = parseFloat(updatedForm.Malindi) || 0;
            const greatOasis = parseFloat(updatedForm.GreatOasis) || 0;
            const ushindi = parseFloat(updatedForm.Ushindi) || 0;
            const achieversCommercial =
              parseFloat(updatedForm.AchieversCommercial) || 0;
            const wema = parseFloat(updatedForm.Wema) || 0;
            const joyLovers = parseFloat(updatedForm.JoyLovers) || 0;
            const kithimani = parseFloat(updatedForm.kithimani) || 0;

            updatedForm.totalSales = (
              oceanView +
              malindi +
              greatOasis +
              ushindi +
              achieversCommercial +
              wema +
              joyLovers +
              kithimani
            ).toString();
          }

          return updatedForm;
        }
        return form;
      });
    });
  };

  const handleSubmitSales = async () => {
    // Check if both start and end dates are selected
    if (!startDate || !endDate) {
      toast.error("Both dates must be chosen before submitting the form.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Convert start and end dates to JavaScript Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Check if the end date is before the start date
    if (endDateObj < startDateObj) {
      toast.error("End date cannot be before start date.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (
      !salesForms.every(
        (form) =>
          form.OceanViewRidge &&
          form.Malindi &&
          form.GreatOasis &&
          form.Ushindi &&
          form.AchieversCommercial &&
          form.Wema &&
          form.JoyLovers &&
          form.kithimani &&
          form.team &&
          form.totalSales &&
          form.observation &&
          form.recommendation
      )
    ) {
      // Display error message for missing required fields
      toast.error("Please fill in all required fields.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent form submission if there are empty fields
    }

    try {
      // Proceed with form submission
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/monthly-sales",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(salesForms),
        }
      );

      if (!response.ok) {
        throw new Error("An error occurred while submitting sales forms");
      }

      toast.success("Sales forms submitted successfully!", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setSalesForms([
        {
          OceanViewRidge: "",
          Malindi: "",
          GreatOasis: "",
          Ushindi: "",
          AchieversCommercial: "",
          Wema: "",
          JoyLovers: "",
          kithimani: "",
          team: "",
          totalSales: "",
          observation: "",
          recommendation: "",
        },
      ]);
      navigate("/referal");
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.error(error);
    }
  };

  return (
    <Sidebar>
      <div className="flex justify-center items-center mx-10 my-10 flex flex-wrap gap-8">
        {salesForms.map((form, index) => (
          <div key={index} className="card w-96 bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title text-2xl font-semibold mb-4">
                Monthly Sales Form
              </h2>
              <form action="#" className="space-y-4">
                {/* Start Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                {/* End Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>

                {/* OceanViewRidge */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Ocean View Ridge
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.OceanViewRidge}
                    onChange={(e) =>
                      handleSalesChange(index, "OceanViewRidge", e.target.value)
                    }
                    min="0"
                  />
                </div>

                {/* Malindi */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Malindi
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.Malindi}
                    onChange={(e) =>
                      handleSalesChange(index, "Malindi", e.target.value)
                    }
                    min="0"
                  />
                </div>
                {/* Great Oasis */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Great Oasis
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.GreatOasis}
                    onChange={(e) =>
                      handleSalesChange(index, "GreatOasis", e.target.value)
                    }
                    min="0"
                  />
                </div>

                {/* Ushindi */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Ushindi
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.Ushindi}
                    onChange={(e) =>
                      handleSalesChange(index, "Ushindi", e.target.value)
                    }
                    min="0"
                  />
                </div>
                {/* AchieversCommercial */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Achievers Commercial
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.AchieversCommercial}
                    onChange={(e) =>
                      handleSalesChange(
                        index,
                        "AchieversCommercial",
                        e.target.value
                      )
                    }
                    min="0"
                  />
                </div>

                {/* Wema */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Wema
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.Wema}
                    onChange={(e) =>
                      handleSalesChange(index, "Wema", e.target.value)
                    }
                    min="0"
                  />
                </div>
                {/* JoyLovers */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Joy Lovers
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.JoyLovers}
                    onChange={(e) =>
                      handleSalesChange(index, "JoyLovers", e.target.value)
                    }
                    min="0"
                  />
                </div>

                {/* kithimani */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Kithimani
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.kithimani}
                    onChange={(e) =>
                      handleSalesChange(index, "kithimani", e.target.value)
                    }
                    min="0"
                  />
                </div>

                {/* Select Team */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Select Team
                  </label>
                  {teamOptions.length > 0 ? (
                    <select
                      className="select select-bordered select-primary w-full max-w-xs"
                      value={form.team}
                      onChange={(e) =>
                        handleSalesChange(index, "team", e.target.value)
                      }
                    >
                      <option value="">Select Team</option>
                      {teamOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p>Loading team options...</p>
                  )}
                </div>

                {/* Total Sales */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Total Sales
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.totalSales}
                    onChange={(e) =>
                      handleSalesChange(index, "totalSales", e.target.value)
                    }
                    min="0"
                    disabled
                  />
                </div>

                {/* Observation */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Observation
                  </label>
                  <textarea
                    className="textarea textarea-bordered textarea-primary w-full max-w-xs"
                    value={form.observation}
                    onChange={(e) =>
                      handleSalesChange(index, "observation", e.target.value)
                    }
                  ></textarea>
                </div>

                {/* Recommendation */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Recommendation
                  </label>
                  <textarea
                    className="textarea textarea-bordered textarea-primary w-full max-w-xs"
                    value={form.recommendation}
                    onChange={(e) =>
                      handleSalesChange(index, "recommendation", e.target.value)
                    }
                  ></textarea>
                </div>
                {/* Remove button for additional forms */}
                <div className="w-full flex justify-center mt-4 space-x-4">
                  {index !== 0 && (
                    <button
                      onClick={() => removeTask(index)}
                      className="btn btn-danger btn-circle text-white"
                      style={{ backgroundColor: "#df4747" }}
                    >
                      <MinusCircle size={30} />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        ))}

        <div className="w-full flex justify-center space-x-4 mt-4">
          <button
            onClick={addSalesForm}
            className="btn btn-primary btn-circle text-white"
          >
            <PlusCircle size={30} />
          </button>
          <button onClick={handleSubmitSales} className="btn btn-primary">
            Submit Monthly Sales
          </button>
        </div>
      </div>
    </Sidebar>
  );
};

export default MonthlySales;
