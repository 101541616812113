import React, { useEffect, useState, useMemo } from "react";

const teams = [
  "PUMA",
  "JAGUAR",
  "REGION KAREN",
  "LEOPARDS",
  "LIONS",
  "HQ P",
  "TIGERS",
  "STAFF",
  "KAREN BLUE",
  "HQ BLUE",
];

const TeamInsights = () => {
  const [teamData, setTeamData] = useState({});
  const [previousMonthData, setPreviousMonthData] = useState({});
  const [percentageVariances, setPercentageVariances] = useState({});
  const [monthlyTargets, setMonthlyTargets] = useState({});
  const [variances, setVariances] = useState({});

  const calculateVariances = async () => {
    const varianceData = {};

    for (const team of teams) {
      try {
        // Fetch current month data
        const currentResponse = await fetch(
          `https://workspace.optiven.co.ke/api/mib/current/${team.toLowerCase()}`
        );
        const currentData = await currentResponse.json();
        const currentMIB = parseFloat(currentData?.MIB?.total) || 0;

        // Fetch monthly target data
        const targetResponse = await fetch(
          `https://workspace.optiven.co.ke/api/mib/monthly-target/${team.toLowerCase()}`
        );
        const targetData = await targetResponse.json();
        const monthlyTarget = parseFloat(targetData.monthlyTarget) || 0;

        // Calculate percentage variance
        const variance =
          monthlyTarget !== 0
            ? ((currentMIB - monthlyTarget) / monthlyTarget) * 100
            : "N/A";

        varianceData[team] = isNaN(variance) ? "N/A" : variance.toFixed(2);
      } catch (error) {
        console.error(`Error fetching data for ${team}:`, error.message);
        // Handle the error as needed
      }
    }

    setVariances(varianceData);
  };

  const fetchMonthlyTarget = async (team) => {
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/mib/monthly-target/${team.toLowerCase()}`
      );
      const data = await response.json();

      setMonthlyTargets((prevData) => ({
        ...prevData,
        [team]: data.monthlyTarget,
      }));
    } catch (error) {
      console.error(
        `Error fetching monthly target for ${team}:`,
        error.message
      );
    }
  };

  const fetchTeamData = async (team, region) => {
    try {
      const currentResponse = await fetch(
        `https://workspace.optiven.co.ke/api/mib/current/${team.toLowerCase()}`
      );
      const currentData = await currentResponse.json();

      const previousResponse = await fetch(
        `https://workspace.optiven.co.ke/api/mib/previous/${team.toLowerCase()}`
      );
      const previousData = await previousResponse.json();

      setTeamData((prevData) => ({ ...prevData, [region]: currentData }));
      setPreviousMonthData((prevData) => ({
        ...prevData,
        [region]: previousData,
      }));
    } catch (error) {
      console.error(`Error fetching data for ${team}:`, error.message);
    }
  };

  const fetchTeamsData = async () => {
    const teams = [
      { name: "PUMA", region: "PUMA" },
      { name: "JAGUAR", region: "JAGUAR" },
      { name: "REGION KAREN", region: "REGION KAREN" },
      { name: "LEOPARDS", region: "LEOPARDS" },
      { name: "LIONS", region: "LIONS" },
      { name: "HQ", region: "HQ" },
      { name: "TIGERS", region: "TIGERS" },
      { name: "STAFF", region: "STAFF" },
      { name: "KAREN BLUE", region: "KAREN BLUE" },
      { name: "HQ BLUE", region: "HQ BLUE" },
      // Add other teams and regions as needed
    ];
    try {
      const teamPromises = teams.map(({ name, region }) => {
        fetchTeamData(name, region);
        fetchMonthlyTarget(name);
      });

      await Promise.all(teamPromises);

      // Now that both team data and monthly targets are fetched, calculate variances
      calculatePercentageVariance();
      calculateVariances();
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchTeamsData();
  }, []); // Only run once on mount

  const calculatePercentageVariance = async () => {
    const variances = {};

    for (const team of teams) {
      try {
        // Fetch current month data
        const currentResponse = await fetch(
          `https://workspace.optiven.co.ke/api/mib/current/${team.toLowerCase()}`
        );
        const currentData = await currentResponse.json();
        const currentMonthTotal = parseFloat(currentData?.MIB?.total) || 0;

        // Fetch previous month data
        const previousResponse = await fetch(
          `https://workspace.optiven.co.ke/api/mib/previous/${team.toLowerCase()}`
        );
        const previousData = await previousResponse.json();
        const previousMonthTotal = parseFloat(previousData?.MIB?.total) || 0;

        // Calculate percentage variance
        const variance =
          previousMonthTotal !== 0
            ? (
                ((currentMonthTotal - previousMonthTotal) /
                  previousMonthTotal) *
                100
              ).toFixed(2)
            : "N/A";

        variances[team] = variance;
      } catch (error) {
        console.error(`Error fetching data for ${team}:`, error.message);
        // Handle the error as needed
      }
    }

    setPercentageVariances(variances);
  };

  // Use useMemo to memoize the teams rendering
  const teamsRenderer = useMemo(
    () =>
      teams.map((team, index) => (
        <tr key={team} className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}>
          <th></th>
          <td>{team}</td>
          <td>
            Ksh. {parseFloat(teamData[team]?.MIB?.total).toLocaleString()}{" "}
          </td>
          <td>
            <span
              className={`badge ${
                percentageVariances[team] >= 0
                  ? "badge-success"
                  : "badge-danger"
              }`}
              style={{
                backgroundColor:
                  percentageVariances[team] >= 0 ? "#28a745" : "#dc3545",
                color: "white",
              }}
            >
              {`${percentageVariances[team]}%`}
            </span>
          </td>
          <td>Ksh. {parseFloat(monthlyTargets[team]).toLocaleString()} </td>
          <td>
            <span
              className={`badge ${
                variances[team] >= 0 ? "badge-success" : "badge-danger"
              }`}
              style={{
                backgroundColor: variances[team] >= 0 ? "#28a745" : "#dc3545",
                color: "white",
              }}
            >
              {`${variances[team]}%`}
            </span>
          </td>

          {/* You can add more columns based on your requirements */}
        </tr>
      )),
    [teamData, percentageVariances, monthlyTargets, variances]
  );

  const LoadingSkeleton = () => (
    <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-slate-700 h-10 w-10"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-slate-700 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-700 rounded col-span-2"></div>
              <div className="h-2 bg-slate-700 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-700 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="p-4">
      <div className="overflow-x-auto">
        <div className="md:card lg:card lg:overflow-hidden">
          <table className="table w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-300"></th>
                <th className="border-b border-gray-300">Team</th>
                <th className="border-b border-gray-300">MIB Total</th>
                <th className="border-b border-gray-300">Monthly Variance</th>
                <th className="border-b border-gray-300">Monthly Targets</th>
                <th className="border-b border-gray-300">Target Variance</th>
              </tr>
            </thead>
            <tbody>{teamsRenderer}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TeamInsights;
