import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const OptivenHomes = () => {
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");

  // Define access role checks
  
  return (
    <section className="relative bg-[url(https://images.unsplash.com/photo-1556156653-e5a7c69cc263?q=80&w=1171&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)] bg-cover bg-center bg-no-repeat">
      <div className="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

      <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
        <div className="max-w-xl mx-auto text-center">
          <h1 className="text-3xl font-extrabold sm:text-5xl">
            <strong className="block font-extrabold text-green-500">Optiven</strong>
            Homes
          </h1>

          <p className="mt-4 max-w-lg sm:text-xl/relaxed text-white">
            The Freedom to Build Your Way
          </p>

          <div className="mt-8 flex justify-center flex-wrap gap-4">
            
            
              <Link
                to="/optiven-dash"
                className="block px-12 py-3 text-sm font-medium rounded bg-rose-600 text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
              >
                Get Started
              </Link>
            

            
          </div>
        </div>
      </div>
    </section>
  );
};

export default OptivenHomes;
