import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import formatDateDash from "../../../utils/formatDate";
import huh from "../../../assets/app-illustrations/Shrug-bro.png";
import formatToAMPM from "../../../utils/formatToAMPM";

function formatDate(inputDate) {
  // Create a new Date object from the input string
  const date = new Date(inputDate);

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

function formatTimeToAMPM(time) {
  // Split the time into hours, minutes, and seconds
  const [hour, minute] = time.split(":").map(Number);

  // Determine whether it's AM or PM
  const period = hour >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  const formattedHour = hour % 12 || 12; // Use 12 for 0 and 12, else use the remainder

  // Create the formatted time string
  const formattedTime = `${formattedHour}:${
    (minute < 10 ? "0" : "") + minute
  }${period}`;

  return formattedTime;
}

const UpdateActivities = () => {
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedViewActivity, setSelectedViewActivity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const userId = useSelector((state) => state.user.user.user_id);
  const token = useSelector((state) => state.user.token);
  const [formData, setFormData] = useState({
    measurable_achievement: "",
    variance: "",
    comments: "",
  });
  const [fileInput, setFileInput] = useState(null);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch(
          `https://workspace.optiven.co.ke/api/workplan-activities?user_id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        const formattedActivities = data
          .filter(
            (activity) =>
              activity.measurable_achievement === null &&
              activity.variance === null &&
              activity.comments === null &&
              activity.supporting_document === null
          )
          .map((activity) => ({
            id: activity.id,
            workplan_id: activity.workplan_id,
            title: activity.title,
            date: formatDateDash(activity.date),
            time: activity.time,
            expected_output: activity.expected_output,
            measurable_achievement: activity.measurable_achievement,
            variance: activity.variance,
            comments: activity.comments,
            supporting_document: activity.supporting_document,
          }));
        setActivities(formattedActivities);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivities();
  }, [userId, token]);

  const handleUpdateClick = (activity) => {
    setSelectedActivity(activity);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedActivity(null);
    setIsModalOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleViewClick = (activity) => {
    setSelectedViewActivity(activity);
    setIsViewModalOpen(true);
  };

  const handleUpdateActivity = async () => {
    try {
      if (!selectedActivity) {
        throw new Error("No activity selected for update.");
      }

      // Construct the request body for the PATCH request
      const requestBody = new FormData();
      requestBody.append("workplan_id", selectedActivity.workplan_id);
      requestBody.append("date", selectedActivity.date);
      requestBody.append("time", selectedActivity.time);
      requestBody.append("title", selectedActivity.title);
      requestBody.append("expected_output", selectedActivity.expected_output);
      requestBody.append(
        "measurable_achievement",
        formData.measurable_achievement
      );
      requestBody.append("variance", formData.variance);
      requestBody.append("comments", formData.comments);
      requestBody.append("supporting_document", fileInput);

      const response = await fetch(
        `https://workspace.optiven.co.ke/api/workplan-activities/${selectedActivity.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: requestBody,
        }
      );

      if (response.ok) {
        toast.success("Activity updated successfully!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        handleCloseModal();
      } else {
        const errorData = await response.json();
        console.error("Error Data:", errorData);
        toast.error(
          errorData.message || "Failed to update activity. Please try again.",
          {
            position: "top-center",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Sidebar>
      <div className="container mx-auto py-6">
        <div className="block mx-10">
          <div className="text-sm breadcrumbs">
            <ul>
              <li>
                <Link to="/workplan-home">Home</Link>
              </li>
              <li>Update Activities</li>
            </ul>
          </div>
        </div>
        <div className="block">
          {Array.isArray(activities) && activities.length > 0 ? (
            <table className="table w-full bg-base-100 shadow-xl mx-10">
              <tbody>
                {activities.map((activity) => (
                  <tr key={activity.id}>
                    <td className="w-24">
                      <h1 className="stat-title text-xs">Title</h1>
                      <p className="text-md">{activity.title}</p>
                    </td>
                    <td className="w-24">
                      <h1 className="stat-title text-xs">Date</h1>
                      <p className="text-md">{formatDate(activity.date)}</p>
                    </td>
                    <td className="w-24">
                      <h1 className="stat-title text-xs">Time</h1>
                      <p className="text-md">
                        {formatTimeToAMPM(activity.time)}
                      </p>
                    </td>
                    <td className="w-24">
                      <h1 className="stat-title text-xs">Expected Output</h1>
                      <p className="text-md">{activity.expected_output}</p>
                    </td>
                    <td className="w-8">
                      {activity.measurable_achievement === null ? (
                        <button
                          onClick={() => handleUpdateClick(activity)}
                          className="btn btn-sm text-white"
                        >
                          Update
                        </button>
                      ) : (
                        <button
                          onClick={() => handleViewClick(activity)}
                          className="btn btn-sm text-white"
                        >
                          View
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center">
              <div className="flex flex-col items-center mt-20">
                <img src={huh} alt="huh" className="lg:w-96" />
                <h1 className="font-bold text-center w-1/2">
                  Nothing to see here. Wait for your workplan to be approved to
                  be able to update activities.
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Modal for updating activities */}
      {selectedActivity && (
        <div
          className="fixed inset-0 flex justify-center items-center z-10 bg-black bg-opacity-50"
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        >
          <div className="modal-box container mx-auto">
            <button
              onClick={handleCloseModal}
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <label className="label font-bold">
              {selectedActivity.title} at {formatToAMPM(selectedActivity.time)}{" "}
              on {formatDate(selectedActivity.date)}
            </label>
            <form onSubmit={handleUpdateActivity}>
              <div>
                <label className="label label-text font-bold text-sm">
                  Measurable Achievement*
                </label>
                <textarea
                  name="measurable_achievement"
                  value={formData.measurable_achievement}
                  onChange={handleFormChange}
                  className="textarea textarea-bordered w-full"
                  required
                />
                <div>
                  <span className="label label-text font-bold text-sm">
                    Upload Supporting Documents (optional)
                  </span>
                  <input
                    type="file"
                    accept=".doc, .docx, .xls, .xlsx, .pdf, .csv"
                    className="file-input file-input-bordered w-full"
                    onChange={(e) => setFileInput(e.target.files[0])}
                  />
                </div>
              </div>
              <div>
                <label className="label label-text font-bold text-sm">
                  Variance
                </label>
                <textarea
                  name="variance"
                  value={formData.variance}
                  onChange={handleFormChange}
                  className="textarea textarea-bordered w-full"
                />
              </div>
              <div>
                <label className="label label-text font-bold text-sm">
                  Comments*
                </label>
                <textarea
                  name="comments"
                  value={formData.comments}
                  onChange={handleFormChange}
                  className="textarea textarea-bordered w-full"
                  required
                />
              </div>
              <div className="flex flex-col">
                <button
                  type="submit"
                  className="btn btn-primary text-white mt-2"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {selectedViewActivity && isViewModalOpen && (
        <div
          className="fixed inset-0 flex justify-center items-center z-10 bg-black bg-opacity-50"
          isOpen={isViewModalOpen}
          onRequestClose={() => setIsViewModalOpen(false)}
        >
          <div className="modal-box container mx-auto">
            <button
              onClick={() => setIsViewModalOpen(false)}
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <label className="label font-bold uppercase">
              {selectedViewActivity.title}
            </label>
            <div>
              <div>
                <label className="label font-bold">Date</label>
                <p className="ml-1">{formatDate(selectedViewActivity.date)}</p>
              </div>
              <div>
                <label className="label font-bold">Time</label>
                <p className="ml-1">{selectedViewActivity.time}</p>
              </div>
              <div>
                <label className="label font-bold">Expected Output</label>
                <p className="ml-1">{selectedViewActivity.expected_output}</p>
              </div>
              <div>
                <label className="label font-bold">
                  Measurable Achievement
                </label>
                <p className="ml-1">
                  {selectedViewActivity.measurable_achievement}
                </p>
              </div>
              <div>
                <label className="label font-bold">Variance</label>
                <p className="ml-1">{selectedViewActivity.variance}</p>
              </div>
              <div>
                <label className="label font-bold">Comments</label>
                <p className="ml-1">{selectedViewActivity.comments}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default UpdateActivities;
