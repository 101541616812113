import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/Sidebar";

const ViewConstruction = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    // Fetch the list of clients from the server
    axios
      .get("https://workspace.optiven.co.ke/api/homes-clients")
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the clients!", error);
      });
  }, []);

  const fetchDocuments = (client_id) => {
    axios
      .get(
        `https://workspace.optiven.co.ke/api/design/client/${client_id}/documents?doc_type=construction_contract`
      )
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the documents!", error);
      });
  };

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    if (clientId) {
      fetchDocuments(clientId);
    } else {
      setDocuments([]);
    }
  };

  const getOriginalFileName = (filePath) => {
    const fileName = filePath.split("/").pop();
    const originalFileName = fileName.substring(fileName.indexOf("-") + 1);
    return originalFileName;
  };

  return (
    <Sidebar>
      <div className="flex items-center justify-center min-h-screen">
        <div className="max-w-2xl mx-auto my-10 p-8 bg-white shadow-md rounded-md">
          <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">
            View Construction Contracts
          </h2>
          <div className="mb-6">
            <label className="block mb-2 text-lg font-medium text-gray-700">
              Select Client
            </label>
            <select
              value={selectedClient}
              onChange={handleClientChange}
              className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.client_name}
                </option>
              ))}
            </select>
          </div>
          <div>
            {documents.length > 0 ? (
              <div className="grid grid-cols-1 gap-4">
                {documents.map((doc) => (
                  <div
                    key={doc.id}
                    className="p-4 border rounded shadow-md flex justify-between items-center"
                  >
                    <div>
                      <p className="text-lg font-medium text-gray-800">
                        {getOriginalFileName(doc.file_path)}
                      </p>
                      <p className="text-sm text-gray-500">
                        {new Date(doc.upload_date).toLocaleDateString()}
                      </p>
                    </div>
                    <a
                      href={`https://workspace.optiven.co.ke/api/design/download/${doc.file_path
                        .split("/")
                        .pop()}`}
                      className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition duration-300"
                      download={getOriginalFileName(doc.file_path)}
                    >
                      Download
                    </a>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center text-gray-500">
                No documents found for the selected client.
              </p>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ViewConstruction;
