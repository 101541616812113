import React, { useEffect, useState } from "react";
import { UserPlus } from "react-feather";
import ClientModal from "../../components/CreateClient";
import axios from "axios";
import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import HomeAdminInsights from "../../components/HomeAdminInsights";
import EditClient from "./EditClient";

const HomesDash = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(
        "https://workspace.optiven.co.ke/api/homes-clients"
      );
      setClients(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Failed to fetch clients", error);
    }
  };

  const addClient = async (clientData) => {
    try {
      const response = await axios.post(
        "https://workspace.optiven.co.ke/api/homes-clients",
        clientData
      );
      setClients([...clients, response.data]);
      setIsOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to add client", error);
    }
  };

  const updateClient = async (id, clientData) => {
    try {
      const response = await axios.patch(
        `https://workspace.optiven.co.ke/api/homes-clients/${id}`,
        clientData
      );
      setClients(
        clients.map((client) => (client.id === id ? response.data : client))
      );
      setIsEditOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to update client", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const getTypeBadge = (type) => {
    const typeLower = type ? type.toLowerCase() : "";
    switch (typeLower) {
      case "mansion":
        return "badge badge-success badge-outline";
      case "bungalow":
        return "badge badge-warning badge-outline";
      case "custom":
        return "badge badge-info badge-outline";
      default:
        return "badge badge-outline";
    }
  };

  const filteredClients = clients.filter((client) => {
    const clientName = client.client_name
      ? client.client_name.toLowerCase()
      : "";
    const clientId = client.id_no ? client.id_no.toString().toLowerCase() : "";
    const clientType = client.type ? client.type.toLowerCase() : "";

    return (
      clientName.includes(searchTerm.toLowerCase()) ||
      clientId.includes(searchTerm.toLowerCase()) ||
      clientType.includes(searchTerm.toLowerCase())
    );
  });

  const user = JSON.parse(localStorage.getItem("user")) || {};
  const firstName = user.fullnames
    ? user.fullnames.trim().split(" ")[0]
    : "user";

  const now = new Date();
  const hour = now.getHours();
  let greeting;

  if (hour < 12) {
    greeting = "Good morning,";
  } else if (hour >= 12 && hour < 17) {
    greeting = "Good afternoon,";
  } else {
    greeting = "Good evening,";
  }

  return (
    <Sidebar>
      <section className="p-6">
        <div className="mb-4 sm:text-center sm:text-4xl text-2xl font-bold text-gray-700">
          {greeting} <span style={{ color: "green" }}>{firstName}</span>
        </div>
        <HomeAdminInsights />
        <div className="mb-8 flex justify-between items-center">
          <div className="flex items-center justify-center flex-1">
            <input
              type="text"
              placeholder="Search by name, ID number, or type"
              className="border border-gray-300 rounded-md px-3 py-2 mr-4 sm:w-72 w-60"
              value={searchTerm}
              onChange={handleSearch}
            />
            <ClientModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              addClient={addClient}
            />
          </div>

          <button
            className="relative z-10 rounded-lg bg-gradient-to-br from-indigo-500 to-fuchsia-500 p-0.5 duration-300 group-hover:scale-110"
            onClick={() => setIsOpen(true)}
          >
            <span className="block rounded-md bg-slate-950 px-4 py-1.5 sm:px-8 py-1.5 font-semibold text-slate-100 duration-300 group-hover:bg-slate-950/50 group-hover:text-slate-50 group-active:bg-slate-950/80 flex items-center">
              <UserPlus className="text-sm sm:text-lg mr-2" />
              <span>Create Client</span>
            </span>
          </button>
        </div>

        <table className="min-w-full divide-y divide-gray-200 overflow-x-auto font-[Poppin]">
          <thead className="bg-gray-50 ">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                ID Number
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Type
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                LR_NO/Plot_Size
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredClients.map((client) => (
              <tr key={client.id}>
                <td className="px-6 py-3 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-10 w-10">
                      <img
                        className="h-10 w-10 rounded-full"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAV1BMVEVHcExMdUuFQTSEQTSEQDOFQTSGQzaFQjWFQDOFQTSFQTSEQDSHRThObESGQjYIiUkMiUmFQjUIiUkJh0cMiUoKiEgMiEkLiEgLiEgKi0sQiksKh0gLiEir1KB0AAAAHXRSTlMACoHd/7pQa8SOqPIkFjhjXXSA/02SdMmwNijy3XDL+RsAAAD9SURBVHgBdZEHDoRACEWx94pjv/85d5HMoCS8tB+f0gSTKE7SLLd9nj5kli/Yl5av2NeWr9nHli/ZF5ZP2FsbRA371vAt66YzfM8++X4k1XK//jCKTmTgya8/Iy7+BZ6pCueh7PBP9+2ahfNMsCKxAdN1XKNpZP0diQOIEw+6TKAHAomdEpUbIGMZ1ucmFxBUbfbtk8j3xTDmRWnhBTMIIHH6RDt3xdSDIFPelFZQRFLB8byKBYlIov532+tSKFkVGABCD3Rvf6I/g5TAPQp+4Cfhhx/IuGfoaLxRDi1fcNH7vnze1EwKvfi5KT+CZtnEXjNoeDq3XXjv6wlvfnnIGIdux2dNAAAAAElFTkSuQmCC"
                        alt=""
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {client.client_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {client.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <div className="text-sm text-gray-900">{client.id_no}</div>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <span
                    className={`text-sm font-light  ${getTypeBadge(
                      client.type
                    )}`}
                  >
                    {client.type}
                  </span>
                </td>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                  <div className="text-sm text-gray-900">{client.LR_no}</div>
                  <div className="text-sm text-gray-900">
                    {client.plot_size}HA
                  </div>
                </td>

                <td className="px-4 py-3 whitespace-nowrap  text-sm font-medium">
                  <button
                    className="text-indigo-600 hover:text-indigo-900 px-4"
                    onClick={() => {
                      setSelectedClient(client);
                      setIsEditOpen(true);
                    }}
                  >
                    Edit
                  </button>
                  {client.status === "design" && (
                    <Link
                      className="ml-2 text-red-600 hover:text-red-900 px-4"
                      to={`/client/${client.id}`}
                    >
                      Details
                    </Link>
                  )}

                  {client.status === "construction" && (
                    <Link
                      className="ml-2 text-red-600 hover:text-red-900 px-4"
                      to={`/const-phase/${client.id}`}
                    >
                      Details
                    </Link>
                  )}
                  {client.status === "terminated" && (
                    <Link
                      className="ml-2 text-red-600 hover:text-red-900 px-4"
                      to={`/client/${client.id}`}
                    >
                      Details
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <EditClient
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
          clientData={selectedClient}
          updateClient={updateClient}
        />
      </section>
    </Sidebar>
  );
};

export default HomesDash;
