import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AppMenu = ({ onAppSelect }) => {
  const handleAppSelect = (appName) => {
    if (typeof onAppSelect === "function") {
      onAppSelect(appName);
    }
  };

  const department = useSelector((state) => state.user.user.department);
  const accessRole = useSelector((state) => state.user.user.Accessrole);

  return (
    <>
      <section className="min-h-screen">
        <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 -mb-4">
          <div
            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div
            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
            <p className="text-sm leading-6 text-gray-900">
              <strong className="font-semibold mr-2">Important!</strong>
              Please add your phone number
            </p>
            <Link to="/profile" className="underline -mx-3">
              here
            </Link>
            <p className="text-sm leading-6 text-gray-900">
              if you haven't already
            </p>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
            >
              <span className="sr-only">Dismiss</span>
            </button>
          </div>
        </div>
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
          <ul className="grid gap-4 mt-8 sm:grid-cols-2 lg:grid-cols-3">
            <li>
              <Link
                to="/logistics-home"
                className="block overflow-hidden group"
                onClick={() => handleAppSelect("Logistics")}
              >
                <img
                  src={require("../../assets/media/home.jpg")}
                  alt=""
                  className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                />
                <div className="relative pt-3 bg-white">
                  <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                    Logistics
                  </h3>
                </div>
              </Link>
            </li>
            <li>
              <Link
                to="/workplan-home"
                className="block overflow-hidden group"
                onClick={() => handleAppSelect("Workplan")}
              >
                <img
                  src="https://images.unsplash.com/photo-1603899607191-e9425cdfdd7e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1935&q=80"
                  alt=""
                  className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                />
                <div className="relative pt-3 bg-white">
                  <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                    Workplan
                  </h3>
                </div>
              </Link>
            </li>
            {(department === "ICT SYSTEMS" ||
              department === "Customer Exp" ||
              accessRole.split("#").includes("visitorsManagementHR") ||
              accessRole.split("#").includes("conferenceSuperuser")) && (
              <li>
                <Link
                  to="/visitors-management"
                  className="block overflow-hidden group"
                  onClick={() => handleAppSelect("Visitor Management")}
                >
                  <img
                    src="https://images.unsplash.com/photo-1559056961-1f4dbbf9d36a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80"
                    alt=""
                    className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                  />
                  <div className="relative pt-3 bg-white">
                    <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                      Visitors Management
                    </h3>
                  </div>
                </Link>
              </li>
            )}
            {department === "ICT SYSTEMS" && (
              <li>
                <Link
                  to="/view-feedback"
                  className="block overflow-hidden group"
                  onClick={() => handleAppSelect("Feedback")}
                >
                  <img
                    src="https://images.pexels.com/photos/6610213/pexels-photo-6610213.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                    className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                  />
                  <div className="relative pt-3 bg-white">
                    <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                      Feedback
                    </h3>
                  </div>
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/map-home"
                className="block overflow-hidden group"
                onClick={() => handleAppSelect("Maps")}
              >
                <img
                  src="https://images.unsplash.com/photo-1619468129361-605ebea04b44?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80"
                  alt=""
                  className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                />
                <div className="relative pt-3 bg-white">
                  <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                    Optiven Maps
                  </h3>
                </div>
              </Link>
            </li>

            {(department === "ICT SYSTEMS" || accessRole === "foundation") && (
              <li>
                <Link
                  to="/foundation-home"
                  className="block overflow-hidden group"
                  onClick={() => handleAppSelect("Optiven Foundation")}
                >
                  <img
                    src={require("../../assets/media/1I2A7740 (1).jpg")}
                    alt=""
                    className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                  />
                  <div className="relative pt-3 bg-white">
                    <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                      Optiven Foundation
                    </h3>
                  </div>
                </Link>
              </li>
            )}
            {(department === "ICT SYSTEMS" ||
              accessRole.split("#").includes("maintenance") ||
              accessRole.split("#").includes("valueAddition") ||
              accessRole.split("#").includes("operations") ||
              accessRole.split("#").includes("valueAdmin") ||
              accessRole.split("#").includes("approvals")) && (
              <li>
                <Link
                  to="/value-addition-home"
                  className="block overflow-hidden group"
                  onClick={() => handleAppSelect("Value Addition")}
                >
                  <img
                    src="https://images.unsplash.com/photo-1465343161283-c1959138ddaa?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                    className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                  />
                  <div className="relative pt-3 bg-white">
                    <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                      Value Addition
                    </h3>
                  </div>
                </Link>
              </li>
            )}

            {(department === "ICT SYSTEMS" ||
              accessRole.split("#").includes("isIvoryAdmin") ||
              accessRole.split("#").includes("isIvoryHos") ||
              accessRole.split("#").includes("isIvoryGm") ||
              accessRole.split("#").includes("isIvoryTeam") ||
              accessRole.split("#").includes("isIvoryDirector")) && (
              <li>
                <Link
                  to="/home"
                  className="block overflow-hidden group"
                  onClick={() => handleAppSelect("Senior Leadership Reporting")}
                >
                  <img
                    src="https://images.unsplash.com/photo-1556740714-a8395b3bf30f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                    className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                  />
                  <div className="relative pt-3 bg-white">
                    <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                      Optiven Performance Review
                    </h3>
                  </div>
                </Link>
              </li>
            )}
            {(department === "ICT SYSTEMS" ||
              accessRole.split("#").includes("optivenHomes")) && (
              <li>
                <Link
                  to="/optiven-home"
                  className="block overflow-hidden group"
                  onClick={() => handleAppSelect("Optiven Homes")}
                >
                  <img
                    src="https://images.unsplash.com/photo-1584738766473-61c083514bf4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                    className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                  />
                  <div className="relative pt-3 bg-white">
                    <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                      Optiven Homes
                    </h3>
                  </div>
                </Link>
              </li>
            )}
            {(department === "ICT SYSTEMS" ||
              accessRole.split("#").includes("ui-uxConsult")) && (
              <li>
                <Link
                  to="/survey"
                  className="block overflow-hidden group"
                  onClick={() => handleAppSelect("Feedback")}
                >
                  <img
                    src="https://images.unsplash.com/photo-1526628953301-3e589a6a8b74?q=80&w=1106&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                    className="h-[350px] w-full object-cover transition duration-500 group-hover:scale-105 sm:h-[450px]"
                  />
                  <div className="relative pt-3 bg-white">
                    <h3 className="text-sm text-gray-700 group-hover:underline group-hover:underline-offset-4">
                      System Survey
                    </h3>
                  </div>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </section>
    </>
  );
};

export default AppMenu;
