import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import huh from "../../../../assets/app-illustrations/Shrug-pana.png";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "react-feather";
import { toast } from "react-toastify";

function formatDate(inputDate) {
  // Create a new Date object from the input string
  const date = new Date(inputDate);

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

const ApproveFow = () => {
  const [fowList, setFowList] = useState([]);
  const [selectedFow, setSelectedFow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvers, setApprovers] = useState({});

  useEffect(() => {
    const fetchFowData = async () => {
      try {
        // Fetch the list of FOW entries
        const response = await fetch("https://workspace.optiven.co.ke/api/fow/test/completed");
        if (response.ok) {
          const fowData = await response.json();
    
          // Fetch user details for each FOW and add the full name to the FOW data
          const fowWithFullNames = await Promise.all(
            fowData.map(async (fow) => {
              // Fetch user details based on staff_id
              const userResponse = await fetch(`https://workspace.optiven.co.ke/api/users/${fow.staff_id}`);
              if (userResponse.ok) {
                const userData = await userResponse.json();
                // Assuming the user data object has a 'fullnames' field
                const assignedUserName = userData.fullnames;
                return { ...fow, staffName: assignedUserName }; // Merge FOW data with the assigned user's full name
              }
              return fow; // Return the FOW entry as is if there's an issue fetching user data
            })
          );
    
          setFowList(fowWithFullNames);

          // Initialize the approvers state
          const initialApprovers = {};
          fowWithFullNames.forEach(fow => {
            if (fow.approved_by) {
              initialApprovers[fow.id] = fow.approved_by.split(',');
            }
          });
          setApprovers(initialApprovers);
        } else {
          console.error("Failed to fetch Focus of the Week data");
        }
      } catch (error) {
        console.error("Error fetching Focus of the Week data:", error);
      }
    };

    fetchFowData();
  }, []);

  const handleRowClick = (fow) => {
    setSelectedFow(fow);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFow(null);
  };

  const handleApprove = async (fowId) => {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const firstName = user.fullnames ? user.fullnames.trim().split(" ")[0] : "user";

    try {
      const response = await fetch(`https://workspace.optiven.co.ke/api/fow/approve/${fowId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ approved_by: firstName }),
      });

      if (response.ok) {
        setApprovers(prev => ({
          ...prev,
          [fowId]: prev[fowId] ? [...prev[fowId], firstName] : [firstName]
        }));
        closeModal();
      } else {
        console.error("Failed to approve Focus of the Week");
      }
    } catch (error) {
      console.error("Error approving Focus of the Week:", error);
    }
  };

  const FowDetailsModal = ({ fow, closeModal }) => {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    const firstName = user.fullnames ? user.fullnames.trim().split(" ")[0] : "user";
    const isApprovedByUser = approvers[fow.id]?.includes(firstName);

    // Modal content with Fow details and Approve/Reject buttons
    const [actual_mib] = useState(fow.actual_mib );
    const [actual_sales] = useState(fow.actual_sales );
    const [variance] = useState(fow.variance );
    const [comments] = useState(fow.comments );
    const [recommendations] = useState(
      fow.recommendations 
    );
    return (
      <motion.div
        initial={{ y: "-100vh" }}
        animate={{ y: 0 }}
        exit={{ y: "100vh" }}
        transition={{ type: "spring", stiffness: 260, damping: 20 }}
        className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
      >
        <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
          <div className="absolute top-0 right-0 m-3 cursor-pointer" onClick={closeModal}>
            <X size={25} className="mr-2 text-red-500" />
          </div>

          <h1 className="font-bold mb-2">{fow.title}</h1>
          <p>
            <strong>Start Date: </strong>{" "}
          </p>
          <p> {formatDate(fow.start_date)}</p>
          <p>
            <strong>End Date: </strong>{" "}
          </p>
          <p> {formatDate(fow.end_date)}</p>
          <p>
            <strong>Description: </strong>{" "}
          </p>
          <p> {fow.description}</p>
          <p>
            <strong>Expected MIB: </strong>{" "}
          </p>
          <p> {fow.expected_mib}</p>

          <p>
            <strong>Expected Sales: </strong>{" "}
          </p>
          <p> {fow.expected_sales}</p>
          <p>
            <strong>Custom Activities: </strong>{" "}
          </p>
          <p> {fow.custom_activity}</p>

          <p>
            <strong>Status: </strong>{" "}
          </p>
          <p>
            {" "}
            {fow.status === "pending" && (
              <span className="badge badge-warning">Pending</span>
            )}
            {fow.status === "approved" && (
              <span className="badge badge-info">Approved</span>
            )}
            {fow.status === "completed" && (
              <span className="badge badge-success">Completed</span>
            )}
            {fow.status === "rejected" && (
              <span className="badge badge-error">Rejected</span>
            )}
          </p>

          <p>
            <strong>Actual MIB: </strong>
          </p>
          
            <p>{actual_mib}</p>
          
          <p>
            <strong>Actual Sales: </strong>
          </p>
          
            <p>{actual_sales}</p>
          
          <p>
            <strong>Variance: </strong>
          </p>
         
            <p>{variance}</p>
         

          <p>
            <strong>Comments: </strong>
          </p>
          
            <p>{comments}</p>
          

          <p>
            <strong>Recommendations: </strong>
          </p>
          
            <p>{recommendations}</p>
          
            <div className="mt-4">
            {!isApprovedByUser && (
              <button
                className="btn btn-success mr-2"
                onClick={() => handleApprove(fow.id)}
              >
                Approve
              </button>
            )}

            {isApprovedByUser && (
              <button className="btn btn-disabled" disabled>
                Approved by You
              </button>
            )}
          </div>
        </div>
      </motion.div>
    );
  };


  return (
    <Sidebar>
      <div className="container mx-auto py-6">
        <div className="block mx-10">
          <div className="text-sm breadcrumbs">
            <ul>
              <li><Link to="/home">Home</Link></li>
              <li>Approve Focus of the Week</li>
            </ul>
          </div>
        </div>
        <div className="block">
          {Array.isArray(fowList) && fowList.length > 0 ? (
            <table className="min-w-full bg-base-100 shadow-xl mx-10">
            <thead>
              <tr>
                {/* Adjust the column headers to better match content width */}
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">End Date</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Title</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Description</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Expected MIB</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Expected Sales</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Custom Activity</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Approved by</th>
                <th className="px-6 py-3 text-left text-base font-medium text-gray-500 uppercase tracking-wider">Staff Name</th>
              </tr>
            </thead>
            <tbody>
              {fowList.map((fow) => (
                <tr
                  key={fow.id}
                  onClick={() => handleRowClick(fow)}
                  className={`cursor-pointer border-b border-gray-200 ${
                    fow.status === "rejected" ? "text-gray-400 line-through" : "text-gray-900"
                  }`}
                >
                  {/* Set padding and alignment for each cell */}
                  <td className="px-6 py-6 whitespace-nowrap text-sm font-medium text-gray-900">{formatDate(fow.start_date)}</td>
                  <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-900">{formatDate(fow.end_date)}</td>
                  <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-900">{fow.title}</td>
                  <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-900 truncate">{fow.description}</td>
                  <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-900">{fow.expected_mib}</td>
                  <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-900">{fow.expected_sales}</td>
                  <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-900">{fow.custom_activity}</td>
                  <td className="px-6 py-6 whitespace-nowrap text-sm">
                
                    <div className="w-24">
                      
                      {fow.status === "pending" && (
                        <span className="badge badge-warning">Pending</span>
                      )}
                      {fow.status === "approved" && (
                        <span className="badge badge-info">Approved</span>
                      )}
                      {fow.status === "completed" && (
                        <span className="badge badge-success">Completed</span>
                      )}
                      {fow.status === "rejected" && (
                        <span className="badge badge-error">Rejected</span>
                      )}
                    </div>
                  
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {/* Display the approvers as badges */}
                    {approvers[fow.id] ? (
                      approvers[fow.id].map((approver, index) => (
                        <span key={index} className="inline-block bg-blue-100 text-blue-800 text-xs px-2 rounded-full mr-1">
                          {approver}
                        </span>
                      ))
                    ) : (
                      <span className="inline-block bg-gray-100 text-gray-800 text-xs px-2 rounded-full">
                        Not yet approved
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-6 whitespace-nowrap text-sm font-medium text-gray-900">{fow.staffName || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          ) : (
            <div className="flex justify-center">
              <div className="flex flex-col items-center mt-20">
                <img src={huh} alt="huh" className="lg:w-96" />
                <h1 className="font-bold text-center">No completed Focus of the Week entries.</h1>
              </div>
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && <FowDetailsModal fow={selectedFow} closeModal={closeModal} />}
      </AnimatePresence>
    </Sidebar>
  );
};

export default ApproveFow;
