import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateTaskModal = ({ isOpen, onClose, task }) => {
  const [fetchedTaskData, setFetchedTaskData] = useState({});
  const [actualOutputUpdated, setActualOutputUpdated] = useState(false);
  const [updatedTaskData, setUpdatedTaskData] = useState({
    actual_output: "",
  });

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/perf/${task}`
        );

        const taskData = response.data;
        console.log("Fetched Task Data:", taskData);

        setFetchedTaskData({
          name: taskData.name,
          expected_output: taskData.expected_output,
          figure: taskData.figure,
        });

        // Check if actual output has been updated
        setActualOutputUpdated(taskData.actual_output !== null);
      } catch (error) {
        console.error("Error fetching task data:", error);
      }
    };

    if (isOpen && task) {
      fetchTaskData();
    }
  }, [isOpen, task]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedTaskData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdateTask = async () => {
    try {
      const response = await axios.patch(
        `https://workspace.optiven.co.ke/api/perf/${task}`,
        {
          actual_output: updatedTaskData.actual_output,
        }
      );

      console.log("Actual output updated successfully:", response.data);
      setActualOutputUpdated(true);
      toast.success("Actual Output submitted successfully!", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      onClose();
    } catch (error) {
      console.error("Error updating actual output:", error);
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Update Task Modal"
        className="custom-modal"
      >
        <div className="w-96 mx-auto p-8 bg-white rounded-lg shadow-lg mt-20">
          <h2 className="text-2xl font-bold mb-4">Update Task</h2>
          <label className="block text-lg mb-2">
            <span className="font-bold">Name:</span>
            <div className="text-lg">{fetchedTaskData.name}</div>
          </label>
          <label className="block text-lg mb-2">
            <span className="font-bold">Expected Output:</span>
            <div className="text-lg">{fetchedTaskData.expected_output}</div>
          </label>
          <label className="block text-lg mb-2">
            <span className="font-bold">Figure:</span>
            <div className="text-lg">{Number(fetchedTaskData.figure).toLocaleString()}</div>
          </label>
          <label className="block text-lg mb-2">
            <span className="font-bold">Actual Output:</span>

            <textarea
              name="actual_output"
              value={updatedTaskData.actual_output}
              onChange={handleInputChange}
              placeholder="Enter the Actual Output"
              className="textarea textarea-success w-full"
            ></textarea>
          </label>
          <button
            onClick={handleUpdateTask}
            className="btn btn-primary mr-2"
            disabled={actualOutputUpdated}
          >
            Update Task
          </button>
          <button onClick={onClose} className="btn btn-error">
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default UpdateTaskModal;
