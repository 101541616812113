import React, { useEffect, useState } from "react";
import MapComponent from "../components/VICTORY3/Victory3";
import geojsonData from "../geojsonData/Victory3";
import Legend from "../components/VICTORY3/Legend";
import { Link } from "react-router-dom";
import logo from "../../assets/optiven-logo-full.png";
import logo1 from "../components/OG/images/logo192.png";
import preloaderVideo from "./videos/load.mp4";

const Victory3 = () => {
  const [plotUnitsData, setPlotUnitsData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [open, setOpen] = useState(0);
  const [reserved, setReserved] = useState(0);
  const [sold, setSold] = useState(0);
  const [loading, setLoading] = useState(true);

  const projectId = 45;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/plots"
        );
        if (response.ok) {
          const data = await response.json();
          setTimeout(() => {
            setPlotUnitsData(data);
            setLoading(false);
            window.scrollTo(0, 0);
          }, 500);
        } else {
          console.error("Failed to fetch plot data");
        }
      } catch (error) {
        console.error("Error fetching plot data:", error);
      } 
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetch(
          `https://workspace.optiven.co.ke/api/plots/all/${projectId}`
        );
        if (response.ok) {
          const data = await response.json();

          setProjectData(data);
          setOpen(data.openPlotsCount);
          setReserved(data.reservedPlotsCount);
          setSold(data.soldPlotsCount);
        } else {
          console.error("Failed to fetch project data");
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjectData();
  }, [projectId]);

  return (
    <>
      <div className="navbar bg-primary">
        <Link className="btn btn-ghost normal-case text-xl" to="/map-home">
          <img src={logo} alt="logo" className="w-40" />
        </Link>
      </div>
      <div className="AchieversPark">
        <div className="header">
          <h1>
            <b>Victory Phase 3</b>
          </h1>
        </div>
        {loading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
              position: "relative",
            }}
          >
            <video
              autoPlay
              loop
              muted
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                zIndex: -1,
              }}
            >
              <source src={preloaderVideo} type="video/mp4" />
            </video>
            <img
              src={logo1}
              alt="Your Logo"
              style={{ width: "100px", height: "120px" }}
            />
            <div className="text-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* SVG animation here */}
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            <p className="text-center mt-4 text-slate-700">
              <p style={{ color: "brown", fontWeight: "bold" }}>
                Please wait as the map is being optimized...
              </p>
            </p>
          </div>
        ) : (
          <div>
            <MapComponent
              geojsonData={geojsonData}
              plotUnitsData={plotUnitsData}
            />
            <Legend open={open} reserved={reserved} sold={sold} />
          </div>
        )}
      </div>
    </>
  );
};

export default Victory3;
