import React, { useEffect, useState, useMemo } from "react";
import Sidebar from "../components/Sidebar";
import _404cat from "../assets/svgs/404-cat.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ViewConstructionManagers = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [sortOrder, setSortOrder] = useState("");

  // fetch construction managers
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/value_addition_users",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(response.data);
        setUsers(response.data);
        
       
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, []);

  // handle delete construction managers
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://workspace.optiven.co.ke/api/value_addition_users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUsers(
        users.filter(
          (user) => user.id !== id
        )
      );
      toast.success("User deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error(
        "User has not been deleted",
        error.response.data,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  const handleNameSort = (columnName) => {
    const sortedUsers = [...users].sort(
      (a, b) => {
        if (a[columnName] < b[columnName]) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (a[columnName] > b[columnName]) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      }
    );
    setUsers(sortedUsers);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // filterConstructionManagers
  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      if (query === "") {
        return true; // Include all items when the query is empty
      } else if (
        user.constructor_name
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        return true; // Include items that match the query
      }
      return false; // Exclude items that don't match the query
    });
  }, [users, query]);

  return (
    <>
      <Sidebar>
        <div className="flex justify-center items-center m-3">
          <input
            placeholder="Search  user by Name"
            className="input input-bordered input-success w-full max-w-xs"
            type="text"
            // SEARCHBAR: Create an onChange event which sets the query to whatever the value of the input is when it changes
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        <div className="overflow-x-auto m-4">
          {users.length === 0 ? (
            <div className="flex items-center justify-center w-full">
              <div className="flex flex-col items-center p-2 sm:w-1/2 w-full">
                <img
                  src={_404cat}
                  alt="not-found"
                  className="max-w-lg lg:w-1/2"
                />
                <h1 className="text-black font-bold text-center">
                  Wow, such empty! Add some construction managers to get started
                </h1>
              </div>
            </div>
          ) : (
            <table className="table table-compact w-full">
              <thead>
                <tr className="text-center font-serif">
                  <th></th>
                  <th
                    onClick={() => handleNameSort("name")}
                    className="cursor-pointer"
                  >
                    Name {sortOrder === "asc" ? "▲" : "▼"}
                  </th>
                  <th>Contact</th>
                  <th>Role</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="font-serif text-2xl">
                {filteredUsers.map((user, i) => {
                  return (
                    <tr key={user.id} className="text-center font-[Poppin] text-2xl">
                      <td>{i + 1}</td>
                      <td className="text-xl">
                        {user.name
                          ? user.name
                          : "N/A"}
                      </td>
                      <td className="text-xl">
                        {user.contact
                          ? user.contact
                          : "N/A"}
                      </td>
                      <td className="text-xl">
                        {user.role
                          ? user.role
                          : "N/A"}
                      </td>

                      <td>
                        <button
                          className="btn btn-warning btn-outline mr-1"
                          onClick={() =>
                            navigate(
                              `/view-users/${user.id}/edit-user`
                            )
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-accent btn-outline ml-1"
                          onClick={() => handleDelete(user.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default ViewConstructionManagers;
