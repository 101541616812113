import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import Sidebar from '../../components/sidebar/Sidebar';

const getFirstName = (fullName) => fullName ? fullName.trim().split(' ')[0] : 'User';

function AllRoomSchedule() {
  const [bookings, setBookings] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [users, setUsers] = useState({});
  const calendarRef = useRef(null);

  useEffect(() => {
    // Fetch rooms and bookings data from the backend
    axios.get('https://workspace.optiven.co.ke/api/rooms')
      .then(response => {
        setRooms(response.data);
        return axios.get('https://workspace.optiven.co.ke/api/bookings');
      })
      .then(response => {
        setBookings(response.data);
        const userIds = response.data.map(booking => booking.user_id);
        return axios.get(`https://workspace.optiven.co.ke/api/users?ids=${userIds.join(',')}`);
      })
      .then(response => {
        const usersById = response.data.reduce((acc, user) => {
          acc[user.user_id] = user.fullnames;
          return acc;
        }, {});
        setUsers(usersById);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  const getColorForRoom = (roomId) => {
    const room = rooms.find(room => room.id === roomId);
    return room ? room.backgroundColor : '#000000'; 
  };

  // Define recurring event for the specific room 
  const recurringEvent = {
    title: 'Morning Devotion',
   // startRecur: new Date().toISOString().split('T')[0], 
    startTime: '08:00:00',
    endTime: '09:00:00',
    daysOfWeek: [1, 2, 3, 4, 5], 
    backgroundColor: '#1f77b4', 
    borderColor: '#1f77b4'
  };

  const events = bookings.map(booking => ({
    title: `${booking.purpose} (Booked by ${getFirstName(users[booking.user_id])})`,
    start: new Date(`${booking.date.split('T')[0]}T${booking.start_time}`).toISOString(),
    end: new Date(`${booking.date.split('T')[0]}T${booking.end_time}`).toISOString(),
    backgroundColor: getColorForRoom(booking.room_id),
    borderColor: getColorForRoom(booking.room_id)
  }));

  // Check if the specific room ("OTC") exists and add the recurring event
  const otcRoom = rooms.find(room => room.name === 'OTC');
  if (otcRoom) {
   // recurringEvent.startRecur = new Date().toISOString().split('T')[0]; // Ensure start date is set correctly
    recurringEvent.groupId = otcRoom.id; 
    events.push(recurringEvent);
  }

  return (
    <Sidebar>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">All Rooms Schedule</h1>
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth'
          }}
          initialView="timeGridWeek"
          events={events}
          ref={calendarRef}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          allDaySlot={false}
        />
        <div className="mt-4">
          <h2 className="text-xl font-semibold">Room Legend</h2>
          <div className="flex flex-wrap">
            {rooms.map(room => (
              <div key={room.id} className="flex items-center mr-4 mb-2">
                <div
                  className="w-4 h-4 mr-2 rounded-full"
                  style={{ backgroundColor: room.backgroundColor }}
                ></div>
                <span>{room.description}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default AllRoomSchedule;
