import React, { useEffect, useState } from "react";
import { Eye } from "react-feather";

const HomeAdminInsights = () => {
  const [clients, setClients] = useState([]);
  const [constructionClients, setConstructionClients] = useState(0);
  const [terminatedClients, setTerminatedClients] = useState(0);
  const [designClients, setDesignClients] = useState(0);
  const [currentMonthClients, setCurrentMonthClients] = useState(0);
  const [previousMonthClients, setPreviousMonthClients] = useState(0);
  const [currentMonthConstructionClients, setCurrentMonthConstructionClients] = useState(0);
  const [previousMonthConstructionClients, setPreviousMonthConstructionClients] = useState(0);
  const [currentMonthTerminatedClients, setCurrentMonthTerminatedClients] = useState(0);
  const [previousMonthTerminatedClients, setPreviousMonthTerminatedClients] = useState(0);
  const [currentMonthDesignClients, setCurrentMonthDesignClients] = useState(0);
  const [previousMonthDesignClients, setPreviousMonthDesignClients] = useState(0);

  // Load initial blurred state from local storage or default to false
  const initialBlurredState = localStorage.getItem("isCardBlurred") === "true";
  const [isCardBlurred, setIsCardBlurred] = useState(initialBlurredState);

  // Update local storage when blurred state changes
  useEffect(() => {
    localStorage.setItem("isCardBlurred", isCardBlurred.toString());
  }, [isCardBlurred]);

  const toggleBlur = () => {
    setIsCardBlurred(!isCardBlurred);
  };

  const cardStyle = {
    filter: isCardBlurred ? "blur(5px)" : "none",
  };
  const eyeIconStyle = {
    cursor: "pointer",
    float: "right",
    marginRight: "10px",
  };
  const textStyle = { color: "white" };

  useEffect(() => {
    // Fetch total clients data
    fetch("https://workspace.optiven.co.ke/api/homes-clients")
      .then((response) => response.json())
      .then((data) => {
        setClients(data);
        const constructionCount = data.filter((client) => client.status === "construction").length;
        const terminatedCount = data.filter((client) => client.status === "terminated").length;
        const designCount = data.filter((client) => client.status === "design").length;
        setConstructionClients(constructionCount);
        setTerminatedClients(terminatedCount);
        setDesignClients(designCount);
      })
      .catch((error) =>
        console.error("Error fetching clients data:", error)
      );

    // Fetch current month clients data
    fetch("https://workspace.optiven.co.ke/api/homes-clients/clients/current-month")
      .then((response) => response.json())
      .then((data) => {
        setCurrentMonthClients(data.length);
        setCurrentMonthConstructionClients(data.filter((client) => client.status === "construction").length);
        setCurrentMonthTerminatedClients(data.filter((client) => client.status === "terminated").length);
        setCurrentMonthDesignClients(data.filter((client) => client.status === "design").length);
      })
      .catch((error) =>
        console.error("Error fetching current month clients data:", error)
      );

    // Fetch previous month clients data
    fetch("https://workspace.optiven.co.ke/api/homes-clients/clients/previous-month")
      .then((response) => response.json())
      .then((data) => {
        setPreviousMonthClients(data.length);
        setPreviousMonthConstructionClients(data.filter((client) => client.status === "construction").length);
        setPreviousMonthTerminatedClients(data.filter((client) => client.status === "terminated").length);
        setPreviousMonthDesignClients(data.filter((client) => client.status === "design").length);
      })
      .catch((error) =>
        console.error("Error fetching previous month clients data:", error)
      );
  }, []);

  const calculateVariance = (current, previous) => {
    return previous ? (((current - previous) / previous) * 100).toFixed(2) : "N/A";
  };

  return (
    <div className="p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div className="card bg-blue-900 bordered" style={cardStyle}>
        <div className="card-body">
          <h2 className="card-title" style={textStyle}>All Clients</h2>
          <p className="text-lg font-semibold"  style={{ color: "white" }}>Total: {clients.length}</p>
          <p className="text-lg font-semibold"  style={{ color: "white" }}>Current Month: {currentMonthClients}</p>
          <p>
            <span className="badge" style={{ backgroundColor: calculateVariance(currentMonthClients, previousMonthClients) >= 0 ? "#28a745" : "#dc3545", color: "white" }}>
              {`Variance: `}
              <strong>{`${calculateVariance(currentMonthClients, previousMonthClients)}%`}</strong>
            </span>
          </p>
          <p className="text-xl" style={textStyle}>
            <span role="img" aria-label="eye-icon" onClick={toggleBlur} style={eyeIconStyle}>
              <Eye size={30} />
            </span>
          </p>
          <div className="justify-end card-actions">
            <i className="fas fa-users"></i>
          </div>
        </div>
      </div>

      <div className="card bg-green-900 bordered">
        <div className="card-body">
          <h2 className="card-title" style={textStyle}>Design Clients</h2>
          <p className="text-lg font-semibold"  style={{ color: "white" }}>Total: {designClients}</p>
          <p className="text-lg font-semibold"  style={{ color: "white" }}>Current Month: {currentMonthDesignClients}</p>
          <p>
            <span className="badge" style={{ backgroundColor: calculateVariance(currentMonthDesignClients, previousMonthDesignClients) >= 0 ? "#28a745" : "#dc3545", color: "white" }}>
              {`Variance: `}
              <strong>{`${calculateVariance(currentMonthDesignClients, previousMonthDesignClients)}%`}</strong>
            </span>
          </p>
          <div className="justify-end card-actions">
            <i className="fas fa-wallet"></i>
          </div>
        </div>
      </div>

      <div className="card bg-yellow-900 bordered">
        <div className="card-body">
          <h2 className="card-title" style={textStyle}>Construction Clients</h2>
          <p className="text-lg font-semibold"  style={{ color: "white" }}>Total: {constructionClients}</p>
          <p className="text-lg font-semibold"  style={{ color: "white" }}>Current Month: {currentMonthConstructionClients}</p>
          <p>
            <span className="badge" style={{ backgroundColor: calculateVariance(currentMonthConstructionClients, previousMonthConstructionClients) >= 0 ? "#28a745" : "#dc3545", color: "white" }}>
              {`Variance: `}
              <strong>{`${calculateVariance(currentMonthConstructionClients, previousMonthConstructionClients)}%`}</strong>
            </span>
          </p>
          <div className="justify-end card-actions">
            <i className="fas fa-file-alt"></i>
          </div>
        </div>
      </div>

      <div className="card bg-red-900 bordered">
        <div className="card-body">
          <h2 className="card-title" style={textStyle}>Terminated Clients</h2>
          <p className="text-lg font-semibold"  style={{ color: "white" }}>Total: {terminatedClients}</p>
          <p className="text-lg font-semibold"  style={{ color: "white" }}>Current Month: {currentMonthTerminatedClients}</p>
          <p>
            <span className="badge" style={{ backgroundColor: calculateVariance(currentMonthTerminatedClients, previousMonthTerminatedClients) >= 0 ? "#28a745" : "#dc3545", color: "white" }}>
              {`Variance: `}
              <strong>{`${calculateVariance(currentMonthTerminatedClients, previousMonthTerminatedClients)}%`}</strong>
            </span>
          </p>
          <div className="justify-end card-actions">
            <i className="fas fa-users"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAdminInsights;
