import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../components/Sidebar";

const ClientNotification = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    // Fetch clients when component mounts
    const fetchClients = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/homes-clients",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setClients(data);
      } catch (error) {
        toast.error("Error fetching clients", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };
    fetchClients();
  }, [token]);

  const handleClientChange = async (selectedOption) => {
    setSelectedClient(selectedOption);
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/homes-clients/${selectedOption.value}/email`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setEmail(data.email);
    } catch (error) {
      toast.error("Error fetching client email", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const notificationData = {
      client_id: selectedClient.value,
      email,
      subject,
      body,
    };
    try {
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/homes-clients/notify",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(notificationData),
        }
      );

      const responseData = await response.json();
      console.log(responseData);
      setLoading(false);
      toast.success("Notification sent!", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // Clear the form
      setSelectedClient(null);
      setEmail("");
      setSubject("");
      setBody("");
    } catch (error) {
      toast.error(error.message, {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <div className="hero-content text-center">
          <div className="max-w-md">
            <h1 className="text-2xl font-bold">✉️ Send a Notification ✉️</h1>
            <Select
              options={clients.map((client) => ({
                value: client.id,
                label: client.client_name,
              }))}
              onChange={handleClientChange}
              placeholder="Select a client"
              isSearchable
            />
            {selectedClient && (
              <>
                <p className="py-6 text-start">
                  Sending to: <strong>{email}</strong>
                </p>
                <input
                  type="text"
                  className="input input-bordered input-success w-full mb-4"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Subject"
                  required
                />
                <textarea
                  className="textarea textarea-bordered textarea-success h-48 w-full mb-1"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  placeholder="Write your message here..."
                  required
                  spellCheck
                />
                <button
                  className="btn btn-outline btn-success w-full lg:w-1/2"
                  onClick={handleSubmit}
                  disabled={!body}
                >
                  {loading ? "Sending..." : "Send"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ClientNotification;
