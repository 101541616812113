import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { PlusCircle, MinusCircle } from "react-feather";
import { useNavigate } from "react-router-dom";

const Referral = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  const [referralForms, setReferralForms] = useState([
    {
      team: "",
      totalReferrals: "",
      salesAchieved: "",
      siteVisits: "",
      observation: "",
      recommendation: "",
      startDate: "", // Add startDate property
      endDate: "",
    },
  ]);
  const [teamOptions, setTeamOptions] = useState([]);

  useEffect(() => {
    const fetchTeamOptions = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/category"
        );
        if (!response.ok) {
          throw new Error("An error occurred while fetching team options");
        }
        const data = await response.json();

        const categories = data.map((item) => item.category);
        setTeamOptions(categories);
      } catch (error) {
        console.error("Error fetching team options:", error);
      }
    };

    fetchTeamOptions();
  }, []);

  useEffect(() => {
    // Update the start and end dates whenever the currentMonth, startDate, or endDate changes
    setReferralForms((prevForms) => {
      return prevForms.map((form) => {
        return {
          ...form,
          startDate,
          endDate,
        };
      });
    });
  }, [startDate, endDate]);

  const addReferralForm = () => {
    setReferralForms([
      ...referralForms,
      {
        team: "",
        totalReferrals: "",
        salesAchieved: "",
        siteVisits: "",
        observation: "",
        recommendation: "",
        startDate: "", // Add startDate property
        endDate: "",
      },
    ]);
  };

  const removeTask = (index) => {
    if (referralForms.length > 1) {
      const updatedReferralForms = [...referralForms];
      updatedReferralForms.splice(index, 1);
      setReferralForms(updatedReferralForms);
    }
  };

  const handleReferralChange = (index, field, value) => {
    setReferralForms((prevForms) => {
      return prevForms.map((form, i) => {
        if (i === index) {
          let updatedForm = { ...form, [field]: value };

          return updatedForm;
        }
        return form;
      });
    });
  };

  const handleSubmitReferral = async () => {
    // Check if both start and end dates are selected
    if (!startDate || !endDate) {
      toast.error("Both dates must be chosen before submitting the form.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Convert start and end dates to JavaScript Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Check if the end date is before the start date
    if (endDateObj < startDateObj) {
      toast.error("End date cannot be before start date.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (
      !referralForms.every(
        (form) =>
          form.team &&
          form.totalReferrals &&
          form.salesAchieved &&
          form.siteVisits &&
          form.observation &&
          form.recommendation
      )
    ) {
      // Display error message for missing required fields
      toast.error("Please fill in all required fields.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent form submission if there are empty fields
    }

    try {
      // Proceed with form submission
      const response = await fetch(
        "https://workspace.optiven.co.ke/api/referal",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(referralForms),
        }
      );

      if (!response.ok) {
        throw new Error("An error occurred while submitting referral forms");
      }

      toast.success("Referral forms submitted successfully!", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setReferralForms([
        {
          team: "",
          totalReferrals: "",
          salesAchieved: "",
          siteVisits: "",
          observation: "",
          recommendation: "",
        },
      ]);
      navigate("/county");
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.error(error);
    }
  };

  return (
    <Sidebar>
      <div className="flex justify-center items-center mx-10 my-10 flex flex-wrap gap-8">
        {referralForms.map((form, index) => (
          <div key={index} className="card w-96 bg-base-100 shadow-xl">
            <div className="card-body">
              <h2 className="card-title text-2xl font-semibold mb-4">
                Referral Form
              </h2>
              <form action="#" className="space-y-4">
                {/* Start Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>

                {/* End Date */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                {/* Select Team */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Select Team
                  </label>
                  {teamOptions.length > 0 ? (
                    <select
                      className="select select-bordered select-primary w-full max-w-xs"
                      value={form.team}
                      onChange={(e) =>
                        handleReferralChange(index, "team", e.target.value)
                      }
                    >
                      <option value="">Select Team</option>
                      {teamOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p>Loading team options...</p>
                  )}
                </div>

                {/* Total Referrals */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Total Referrals
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.totalReferrals}
                    onChange={(e) =>
                      handleReferralChange(
                        index,
                        "totalReferrals",
                        e.target.value
                      )
                    }
                    min="0"
                  />
                </div>

                {/* Sales Achieved */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Sales Achieved
                  </label>
                  <input
                    type="number"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.salesAchieved}
                    onChange={(e) =>
                      handleReferralChange(
                        index,
                        "salesAchieved",
                        e.target.value
                      )
                    }
                    min="0"
                  />
                </div>

                {/* Site Visits */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Site Visits
                  </label>
                  <input
                    type="text"
                    className="input input-bordered input-primary w-full max-w-xs"
                    value={form.siteVisits}
                    onChange={(e) =>
                      handleReferralChange(index, "siteVisits", e.target.value)
                    }
                  />
                </div>

                {/* Observation */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Observation
                  </label>
                  <textarea
                    className="textarea textarea-bordered textarea-primary w-full max-w-xs"
                    value={form.observation}
                    onChange={(e) =>
                      handleReferralChange(index, "observation", e.target.value)
                    }
                  ></textarea>
                </div>

                {/* Recommendation */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Recommendation
                  </label>
                  <textarea
                    className="textarea textarea-bordered textarea-primary w-full max-w-xs"
                    value={form.recommendation}
                    onChange={(e) =>
                      handleReferralChange(
                        index,
                        "recommendation",
                        e.target.value
                      )
                    }
                  ></textarea>
                </div>
                {/* Remove button for additional forms */}
                <div className="w-full flex justify-center mt-4 space-x-4">
                  {index !== 0 && (
                    <button
                      onClick={() => removeTask(index)}
                      className="btn btn-danger btn-circle text-white"
                      style={{ backgroundColor: "#df4747" }}
                    >
                      <MinusCircle size={30} />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        ))}
        <div className="w-full flex justify-center space-x-4 mt-4">
          <button
            onClick={addReferralForm}
            className="btn btn-primary btn-circle text-white"
          >
            <PlusCircle size={30} />
          </button>
          <button onClick={handleSubmitReferral} className="btn btn-primary">
            Submit Referrals
          </button>
        </div>
      </div>
    </Sidebar>
  );
};

export default Referral;
