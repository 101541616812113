import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const DonutChart = () => {
  const [tasks, setTasks] = useState([]);
  const userId = useSelector((state) => state.user.user.user_id);

  useEffect(() => {
    const fetchUserTasks = async () => {
      try {
        // Get the current month's start and end dates
        const currentDate = new Date();
        const startOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const endOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        );

        // Add 1 day to the end date
        endOfMonth.setDate(endOfMonth.getDate() + 1);

        // Format dates to ISO strings
        const startDateString = startOfMonth.toISOString().split("T")[0];
        const endDateString = endOfMonth.toISOString().split("T")[0];

        // Fetch user tasks for the current month
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/perf/assigned/${userId}/${startDateString}/${endDateString}`
        );

        setTasks(response.data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    if (userId) {
      fetchUserTasks();
    }
  }, [userId]);

  if (!tasks || tasks.length === 0) {
    // Handle the case where tasks is undefined or an empty array
    return <div>No tasks available</div>;
  }

  // Calculate progress based on the number of tasks in different states
  const calculateDaysLeft = (endDate) => {
    const now = new Date();
    const due = new Date(endDate);
    due.setDate(due.getDate() + 1); // Adding 1 day to the due date
    due.setHours(0, 0, 0, 0);

    const timeDiff = due - now;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysLeft;
  };
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(
    (task) => task.actual_output !== null
  ).length;
  const pastDueTasks = tasks.filter((task) => {
    const daysLeft = calculateDaysLeft(task.end_date);
    return daysLeft <= 0 && task.actual_output === null;
  }).length;

  const progress = (completedTasks / totalTasks) * 100;
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="flex flex-col items-center">
      <svg width="120" height="120" viewBox="0 0 120 120">
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="transparent"
          stroke="#d1d5db"
          strokeWidth="10"
        />
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="transparent"
          stroke="#10b981"
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          transform="rotate(-90 60 60)"
        />
      </svg>
      <div className="text-2xl font-semibold mt-2">{progress.toFixed(2)}%</div>
      <div className="text-sm text-gray-500">
        <b>Task Completion</b>
      </div>
    </div>
  );
};
const TaskCompletionCard = () => {
  const [tasks, setTasks] = useState([]);
  const userId = useSelector((state) => state.user.user.user_id);

  useEffect(() => {
    const fetchUserTasks = async () => {
      try {
        // Get the current month's start and end dates
        const currentDate = new Date();
        const startOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const endOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        );

        // Add 1 day to the end date
        endOfMonth.setDate(endOfMonth.getDate() + 1);

        // Format dates to ISO strings
        const startDateString = startOfMonth.toISOString().split("T")[0];
        const endDateString = endOfMonth.toISOString().split("T")[0];

        // Fetch user tasks for the current month
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/perf/assigned/${userId}/${startDateString}/${endDateString}`
        );

        setTasks(response.data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    if (userId) {
      fetchUserTasks();
    }
  }, [userId]);

  if (!tasks || tasks.length === 0) {
    // Handle the case where tasks is undefined or an empty array
    return <div>No tasks available</div>;
  }

  // Calculate progress based on the number of tasks in different states
  const calculateDaysLeft = (endDate) => {
    const now = new Date();
    const due = new Date(endDate);
    due.setDate(due.getDate() + 1); // Adding 1 day to the due date
    due.setHours(0, 0, 0, 0);

    const timeDiff = due - now;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysLeft;
  };
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(
    (task) => task.actual_output !== null
  ).length;
  const pastDueTasks = tasks.filter((task) => {
    const daysLeft = calculateDaysLeft(task.end_date);
    return daysLeft <= 0 && task.actual_output === null;
  }).length;

  return (
    <div className="mt-4">
      <div className="text-sm text-gray-500">
        <span style={{ color: "red" }}>{pastDueTasks}</span>{" "}
        <strong> overdue</strong>
      </div>
      <div className="text-sm text-gray-500">
        <span style={{ color: "#66cdaa" }}>{completedTasks} </span>
        <strong>completed </strong>
      </div>
      <div className="text-sm text-gray-500">
        <span style={{ color: "#daa520" }}>
          {totalTasks - completedTasks - pastDueTasks}
        </span>
        <strong> active </strong>
      </div>
    </div>
  );
};

const YourComponent = ({ tasks }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center justify-center space-x-4 mt-4">
      {/* DonutChart Card */}
      <div className="card bg-base-100 shadow-xl md:w-full">
        <div className="card-body">
          <h2 className="card-title">Task Completion</h2>
          {/* Place the DonutChart component here */}
          <DonutChart tasks={tasks} />
          <div className="card-actions justify-end"></div>
        </div>
      </div>

      {/* Task Completion Card */}
      <div className="card bg-base-100 shadow-xl md:w-full">
        <div className="card-body">
          <h2 className="card-title">Task Status</h2>
          {/* Place the TaskCompletionCard component here */}
          <TaskCompletionCard tasks={tasks} />
          <div className="card-actions justify-end"></div>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
