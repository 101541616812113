import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import _404cat from "../assets/svgs/404-cat.svg";
import "./styles/Moons.css";
import getStatusColor from "../../utils/getStatusColor";
import { useLocation } from "react-router-dom";
//format currency
function formatCurrency(num) {
  if (typeof num !== "number" || isNaN(num)) {
    // Handle the case where num is not a valid number
    return "Invalid";
  }

  return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

const ValueAdditionDetails = () => {
  const [valueAdditions, setValueAddition] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tasksDetails, setTasksDetails] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [taskCost, setTaskCost] = useState([]);
  const [status, setStatus] = useState("");
  const token = useSelector((state) => state.user.token);
  const loc = useLocation();
  const url = loc.pathname;
  const valueAdditionId = url.split("/")[2];
  const department = useSelector((state) => state.user.user.department);
  const accessRole = useSelector((state) => state.user.user.Accessrole);
  const accessRoles = accessRole.split("#");
  const valueAddition = accessRole.includes("valueAddition");
  const maintenance = accessRoles.includes("maintenance");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");
 

  // parameter definition
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/value-additions/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log(`value addition of id ${id}`, response.data);
        setValueAddition(response.data);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          // Display error message
          toast.error(error.response.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          // Display generic error message
          toast.error("Something went wrong. Please try again", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        // Redirect to home page
        navigate("/");
      }
    };

    fetchItem();
  }, [id, navigate]);

  useEffect(() => {
    //fetch already set task for a certain value additions
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/tasks/${valueAdditions.value_addition_level_id}/value-addition-subtasks`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const subtasksData = response.data;

        setTasks(subtasksData);
      } catch (error) {
        console.error(error);
      }
    };
    if (valueAdditions && valueAdditions.value_addition_level_id) {
      fetchTasks();
    }
  }, [valueAdditions]);
  //get tasks
  useEffect(() => {
    const fetchTasksDetails = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/tasks/${valueAdditionId}/value-addition-tasks`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const tasksData = response.data;
        console.log("taskData", tasksData);

        if (Array.isArray(tasksData)) {
          setTasksDetails(tasksData);
        } else {
          // Handle case where tasksData is not an array
          console.error("Response data is not an array:", tasksData);
          // Display error message or handle it accordingly
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchTasksDetails();
  }, [valueAdditionId]);

  const addTask = (taskTitle) => {
    navigate(`/value-additions/${id}/add-task`, { state: { taskTitle } });
  };

  const newTask = () => {
    navigate(`/value-additions/${id}/add-task`);
  };

  const deleteVA = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/value-additions/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Redirect to home page after displaying success message
      if (!response.ok) {
        toast.error("Something went wrong. Please try again", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setLoading(false);
        toast.success("Value addition deleted!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      navigate("/view-value-additions");
    } catch (error) {
      console.error(error);
      // Display error message
      toast.error("Something went wrong. Please try again", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://workspace.optiven.co.ke/api/tasks/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setTasksDetails(tasksDetails.filter((task) => task.id !== id));
      toast.success("Task deleted!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  // fetch the total cost per tasks in a value Addition
  useEffect(() => {
    const fetchTaskCost = async () => {
      try {
        if (!valueAdditionId) {
          console.error("valueAdditionId is missing");
          return;
        }

        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/tasks/${valueAdditionId}/tasks-cost`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const taskCost = response.data;
        setTaskCost(taskCost);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTaskCost();
  }, [valueAdditionId]);

  function formatDate(date) {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  //handle complete status
  const handleCompleted = async (id) => {
    try {
      await axios.patch(
        `https://workspace.optiven.co.ke/api/tasks/${id}/completed`,
        {
          status: "Completed",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error marking value addition as completed:", error);
    }
  };
  // handle stalled status
  const handleStalled = async (id) => {
    try {
      await axios.patch(
        `https://workspace.optiven.co.ke/api/tasks/${id}/stalled`,
        {
          status: "Stalled",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error marking task as stalled:", error);
    }
  };
  // handle Title sort
  const handleTitleSort = (columnName) => {
    const sortedTasks = [...tasks].sort((a, b) => {
      if (a[columnName] < b[columnName]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[columnName] > b[columnName]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
    setTasks(sortedTasks);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  // route to update the status of value-addition when all are completed or pending
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/tasks/${valueAdditionId}/all-completed`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setStatus(response.data.message);
        console.log(response.data.message);
      } catch (error) {
        console.error("Error fetching status:", error);
        setStatus("Error");
      }
    };

    fetchStatus();
  }, [valueAdditionId]);
  console.log(status);

  return (
    <>
      <Sidebar>
        <div className="text-gray-600 body-font h-screen">
          <div className="container px-5 py-10 mx-auto">
            <div className="flex flex-col">
              <div className="flex justify-between items-center">
                <h1 className="max-w-1/2 sm:text-3xl text-3xl font-bold font-serif text-start uppercase text-gray-900 m-2">
                  {valueAdditions.title} VALUE ADDITION
                </h1>
              </div>
              <p className="text-start text-base p-2 leading-relaxed xl:w-5/12 lg:w-1/2 font-serif text-xl">
                {valueAdditions.details}
              </p>
              <div className="text-start text-base p-2 leading-relaxed xl:w-5/12 lg:w-1/2 font-serif">
                <span className="font-bold text-xl pr-2">Status:</span>
                <span className="font-bold">{status}</span>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {(!maintenance || !operations) &&
                (department === "ICT SYSTEMS" || valueAddition || valueAdmin) ? (
                  <div className="mb-1">
                    <button
                      className="btn btn-warning btn-outline m-1"
                      onClick={() =>
                        navigate(`/value-additions/${id}/edit-value-addition`)
                      }
                    >
                      Edit Value Addition
                    </button>
                    <button
                      className="btn btn-accent m-1 text-white"
                      onClick={deleteVA}
                    >
                      Delete Value Addition
                    </button>
                    <button
                      className="ml-3 inline-flex items-center justify-center rounded-xl bg-green-600 py-3 px-6 font-dm text-base font-medium text-white shadow-xl shadow-green-400/75 transition-transform duration-200 ease-in-out hover:scale-[1.02]"
                      onClick={newTask}
                    >
                      Add Task
                    </button>
                  </div>
                ) : null}

                <div className="max-w-sm p-8 border border-gray-200 rounded-lg shadow bg-gradient-to-r from-cyan-500 to-blue-500 dark:bg-gray-800 dark:border-gray-700">
                  <h1 className="font-bold text-3xl font-[Poppin] text-white">
                    Total Cost:{" "}
                    {taskCost.map((tCost) => (
                      <span key={tCost.valueAdditionId}>
                        {formatCurrency(parseInt(tCost.totalCost) || 0)}
                      </span>
                    ))}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 sm:grid-cols-6 lg:grid-cols-10 gap-4 mx-auto ml-4 mb-2">
            {tasks.map((task, index) => (
              <div
                key={index}
                className="bg-blue-600 text-white font-[Poppin] px-3 py-2 rounded-lg shadow-md text-base sm:text-lg lg:text-xl text-center"
                onClick={() =>
                  (!maintenance || !operations) &&
                  (department === "ICT SYSTEMS" || valueAddition || valueAdmin)
                    ? addTask(task.id, task.title)
                    : null
                }
              >
                {task.title}
              </div>
            ))}
          </div>

          <div className="overflow-x-auto px-10">
            {tasks.length === 0 ? (
              <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                <div className="flex items-center justify-center w-full">
                  <div className="flex flex-col items-center p-2 sm:w-1/2 w-full">
                    <img
                      src={_404cat}
                      alt="not-found"
                      className="max-w-lg lg:w-3/5"
                    />
                    <h1 className="text-black font-bold text-center">
                      Wow, such empty! Add some tasks to get started
                    </h1>
                  </div>
                </div>
              </div>
            ) : (
              <table className="table table-zebra w-full">
                <thead>
                  <tr className="text-center font-bold font-serif">
                    <th></th>
                    <th
                      onClick={() => handleTitleSort("title")}
                      className="cursor-pointer"
                    >
                      Title {sortOrder === "asc" ? "▲" : "▼"}
                    </th>

                    <th>Start Date</th>
                    <th>Completion Date</th>
                    <th>Cost(Ksh)</th>
                    <th>Status</th>
                    {(!maintenance || !operations) &&
                    (department === "ICT SYSTEMS" ||
                      valueAddition ||
                      valueAdmin) ? (
                      <th>Action</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {tasksDetails.map((task, i) => {
                    return (
                      <tr key={task.id} className="text-center font-[Poppin]">
                        <td>{i + 1}</td>
                        <td>{task.title}</td>
                        <td>{formatDate(task.startDate)}</td>
                        <td>{formatDate(task.expectedDateOfCompletion)}</td>
                        <td>{formatCurrency(parseInt(task.cost))}</td>
                        <td>{getStatusColor(task.status)}</td>
                        {(!maintenance || !operations) &&
                        (department === "ICT SYSTEMS" ||
                          valueAddition ||
                          valueAdmin) ? (
                          <td>
                            <button
                              className="ml-1 inline-flex items-center justify-center rounded-xl bg-green-600 py-1 px-6 font-dm text-base font-medium text-white shadow-xl shadow-green-400/75 transition-transform duration-200 ease-in-out hover:scale-[1.02]"
                              onClick={() => handleCompleted(task.id)}
                            >
                              Completed
                            </button>

                            <button
                              className=" ml-2 mr-2 inline-flex items-center justify-center rounded-xl bg-red-600 py-1 px-6 font-dm text-base font-medium text-white shadow-xl shadow-red-400/75 transition-transform duration-200 ease-in-out hover:scale-[1.02]"
                              onClick={() => handleStalled(task.id)}
                            >
                              Stalled
                            </button>
                            <button
                              className="btn btn-warning btn-outline btn-sm mr-1"
                              onClick={() =>
                                navigate(`/tasks/${task.id}/edit-task`)
                              }
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-accent btn-sm text-white ml-1"
                              disabled={loading}
                              onClick={() => handleDelete(task.id)}
                            >
                              Delete
                            </button>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default ValueAdditionDetails;
