import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Insights from "../../components/AdminInsights";
import axios from "axios";
import ReferalBarChart from "./Charts/ReferalBarChart";
import MonthlyBarChart from "./Charts/MonthlyBarChart";
import CountyBarChart from "./Charts/CountyBarChart";
import EventsBarChart from "./Charts/EventBarChart";

const SalesDashboard = () => {
  const [monthlySalesData, setMonthlySales] = useState([]);
  const [referalData, setReferalData] = useState([]);
  const [counties, setCounties] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventResponse = await axios.get(
          "https://workspace.optiven.co.ke/api/event"
        );
        setEvents(eventResponse.data);

        const monthlySalesResponse = await axios.get(
          "https://workspace.optiven.co.ke/api/monthly-sales"
        );
        setMonthlySales(monthlySalesResponse.data);

        const referralBusinessResponse = await axios.get(
          "https://workspace.optiven.co.ke/api/referal"
        );
        setReferalData(referralBusinessResponse.data);

        const countiesResponse = await axios.get(
          "https://workspace.optiven.co.ke/api/county"
        );
        setCounties(countiesResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filterDataByMonth = (data) => {
    return data.filter((record) => {
      const month = new Date(record.endDate).getMonth() + 1;
      return selectedMonth ? month.toString() === selectedMonth : true;
    });
  };

  return (
    <Sidebar>
      <div className="h-full w-full flex overflow-hidden antialiased text-gray-800 bg-white">
        <div className="flex-1 flex flex-col overflow-y-auto">
          <main className="flex-grow flex flex-col min-h-0 border-t">
            <section className="flex min-h-0 flex-col flex-auto border-l px-4 md:px-8 lg:px-16 xl:px-24">
              <div className="flex flex-col items-center">
                <h2 className="mt-6 text-4xl sm:text-5xl lg:text-7xl brave tracking-wider font-thick text-center">
                  Welcome to Sales Dashboard!
                </h2>
              </div>

              <Insights />

              <div className="flex justify-end mb-4">
                <select
                  className="border p-1"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  <option value="">All Months</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>

               <div className="flex flex-wrap justify-center" style={{ flexWrap: 'wrap' }}>
               <div className="mb-4 md:w-full lg:w-1/2 p-4">
                  <div className="bg-white rounded-lg shadow-md">
                    <h2 className="font-bold text-center">
                      Monthly Sales Chart
                    </h2>
                    <MonthlyBarChart
                      data={filterDataByMonth(monthlySalesData)}
                      selectedMonth={selectedMonth}
                    />
                  </div>
                </div>

                <div className="mb-4 md:w-full lg:w-1/2 p-4">
                  <div className="bg-white rounded-lg shadow-md">
                    <h2 className="font-bold text-center">
                      Referral Business Chart
                    </h2>
                    <ReferalBarChart
                      referalData={filterDataByMonth(referalData)}
                      selectedMonth={selectedMonth}
                    />
                  </div>
                </div>

                <div className="mb-4 md:w-full lg:w-1/2 p-4">
                  <div className="bg-white rounded-lg shadow-md">
                    <h2 className="font-bold text-center">
                      County Activation Chart
                    </h2>
                    <CountyBarChart
                      counties={filterDataByMonth(counties)}
                      selectedMonth={selectedMonth}
                    />
                  </div>
                </div>

                

                {events.length === 0 && (
                  <div className="mb-4 md:w-full lg:w-1/2 p-4">
                    <div className="bg-white rounded-lg shadow-md">
                      <h2 className="font-bold text-center">
                        No data available for Events Chart
                      </h2>
                    </div>
                  </div>
                )}

                {events.length > 0 && (
                  <div className="mb-4 md:w-full lg:w-1/2 p-4">
                    <div className="bg-white rounded-lg shadow-md">
                      <h2 className="font-bold text-center">
                        Regional Events Chart
                      </h2>
                      <EventsBarChart
                        events={filterDataByMonth(events)}
                        selectedMonth={selectedMonth}
                      />
                    </div>
                  </div>
                )}
              </div>
            </section>
          </main>
        </div>
      </div>
    </Sidebar>
  );
};

export default SalesDashboard;
