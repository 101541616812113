import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const mapMetricName = (key) => {
  switch (key) {
    case "OceanViewRidge":
      return "Ocean View Ridge";
    case "Malindi":
      return "Malindi";
      case "GreatOasis":
      return "Great Oasis";
    case "Ushindi":
      return "Ushindi";
      case "AchieversCommercial":
      return "Achievers Commercial";
    case "Wema":
      return "Wema";
      case "JoyLovers":
      return "Joy Lovers";
    case "kithimani":
      return "Kithimani";
    case "total_sales":
      return "Total Sales";
    default:
      return key; // Return the key itself if not matched
  }
};

const mapMonthName = (month) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[month - 1] || "";
};

const MonthlyBarChart = ({ data, selectedMonth }) => {
  if (!data || data.length === 0) {
    return <p>No data available for the chart</p>;
  }

  // Function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

  // Create an object to store the sums for each team
  const teamSums = {};

  // Filter data based on the selected month
  const filteredData = selectedMonth
    ? data.filter((record) => {
        const month = new Date(record.endDate).getMonth() + 1; // Assuming endDate is the field containing the end date
        return month.toString() === selectedMonth;
      })
    : data;

  console.log("Filtered Data:", filteredData);

  filteredData.forEach((record) => {
    const teamName = record.team;

    if (!teamSums[teamName]) {
      teamSums[teamName] = {
        OceanViewRidge: 0,
        Malindi: 0,
        GreatOasis: 0,
        Ushindi: 0,
        AchieversCommercial: 0,
        Wema: 0,
        JoyLovers: 0,
        kithimani: 0,
        total_sales: 0,
      };
    }

    Object.keys(teamSums[teamName]).forEach((key) => {
      teamSums[teamName][key] += record[key] || 0;
    });
  });

  console.log("Team Sums:", teamSums);

  const chartData = Object.keys(teamSums).map((teamName) => ({
    team: teamName,
    OceanViewRidge: teamSums[teamName].OceanViewRidge,
    Malindi: teamSums[teamName].Malindi,
    GreatOasis: teamSums[teamName].GreatOasis,
    Ushindi: teamSums[teamName].Ushindi,
    AchieversCommercial: teamSums[teamName].AchieversCommercial,
    Wema: teamSums[teamName].Wema,
    JoyLovers: teamSums[teamName].JoyLovers,
    kithimani: teamSums[teamName].kithimani,
    total_sales: teamSums[teamName].total_sales,
  }));

  console.log("Chart Data:", chartData);

  const chartDatasets = ["OceanViewRidge", "Malindi","GreatOasis","Ushindi", "AchieversCommercial","Wema", "JoyLovers", "kithimani", "total_sales"].map(
    (key, index) => ({
      label: mapMetricName(key),
      dataKey: key,
      fill: getRandomColor(), // Using a function to get a random color
}));



  return (
    <div>
      <h2 className="text-center mb-4">
        {selectedMonth
          ? `Performance for ${mapMonthName(parseInt(selectedMonth))}`
          : "Overall Performance"}
      </h2>
      {chartData.length > 0 ? (
        <BarChart
          width={600}
          height={300}
          data={chartData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="team" />
          <YAxis />
          <Tooltip />
          <Legend />
          {chartDatasets.map((dataset, index) => (
            <Bar
              key={index}
              dataKey={dataset.dataKey}
              fill={dataset.fill}
              name={dataset.label}
            />
          ))}
        </BarChart>
      ) : (
        <p className="text-center">No data available for the selected month.</p>
      )}
    </div>
  );
};

export default MonthlyBarChart;
