import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";

const EditProject = (props) => {
  // finding the projectId
  const loc = useLocation();
  const url = loc.pathname;
  const projectId = url.split("/")[2];

  const navigate = useNavigate();

  const [project_name, setProjectName] = useState("");
  const [level_id, setLevel] = useState("");
  const [size_in_acres, setSizeInAcres] = useState("");
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [levelOptions,setLevelOptions] = useState([]);

  // fetching the users from users database
 

  useEffect(() => {
    // Fetch the project data from the backend API
    axios
      .get(`https://workspace.optiven.co.ke/api/projects/${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
        setProjectName(res.data.project_name);
        setLevel(res.data.level_id);
        console.log(res.data.level_id)
        setSizeInAcres(res.data.size_in_acres);
        setLocation(res.data.location);

      })
      .catch((err) => {
        console.log(err);
      });
  }, [projectId]);
  useEffect(()=>{
    const fetchLevels = async() =>{
      try{
        const response = await axios.get("https://workspace.optiven.co.ke/api/levels",{
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },

        })
        console.log(response.data)
        setLevelOptions(response.data)

      }catch(error){
        console.error(error)
      }
    }
    fetchLevels()
  },[])

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const project = {
      project_name,
      level_id,
      size_in_acres,
      location,
    };
    // Send a PATCH request to update the project data in the backend API
    axios
      .patch(`https://workspace.optiven.co.ke/api/projects/${projectId}`, project,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
        setLoading(false);
        toast.success("Project updated!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/projects");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(err);
      });
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <form onSubmit={handleSubmit} className="form-control w-full max-w-xs">
          <label htmlFor="projectName" className="label">
            <span className="label-text font-bold">Project Name</span>
          </label>
          <input
            type="text"
            id="projectName"
            value={project_name}
            onChange={(event) => setProjectName(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="level_id" className="label">
            <span className="label-text font-bold">Level</span>
          </label>
          <select
            id="level_id"
            as="select"
            value={level_id}
            onChange={(event) => setLevel(event.target.value)}
            className="select select-bordered"
          >
        {levelOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
          </select>
          <label htmlFor="location" className="label">
            <span className="label-text font-bold">Location</span>
          </label>
          <input
            type="text"
            id="location"
            value={location}
            onChange={(event) => setLocation(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />
          <label htmlFor="sizeInAcres" className="label">
            <span className="label-text font-bold">Size in Acres</span>
          </label>
          <input
            type="number"
            id="sizeInAcres"
            value={size_in_acres}
            onChange={(event) => setSizeInAcres(event.target.value)}
            className="input input-bordered w-full max-w-xs"
          />

          <button
            type="submit"
            disabled={loading}
            id="submit"
            className="btn btn-primary w-full max-w-xs mt-4 text-white"
          >
            {loading ? "Saving..." : "Update Project"}
          </button>
        </form>
      </div>
    </Sidebar>
  );
};

export default EditProject;
