import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";

const ValueAdditionReports = () => {
  const [project_name, setProjectName] = useState();
  const [projects, setProjects] = useState();
  const handleDownload = async () => {
    try {
      const response = await axios.get(
        "https://workspace.optiven.co.ke/api/value-additions/report",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            project_name: project_name, // Send the project name as a parameter
          },
          responseType: "blob", // Ensure response type is set to blob
        }
      );

      const file = new Blob([response.data], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "value-addition.pdf";
      link.click();

      toast.success("PDF downloaded successfully.");
    } catch (error) {
      console.error(error);
      toast.error(
        "An error occurred while downloading the PDF. Please try again."
      );
    }
  };
  // fetch projects.
  useEffect(() => {
    // Fetch all the projects from the db
    axios
      .get(`https://workspace.optiven.co.ke/api/projects/all`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Sidebar>
      <div className="min-h-screen flex items-center justify-center">
        <div>
          <div className="text-sm breadcrumbs">
            <ul>
              <li>
                <Link to="/value-addition-home">Home</Link>
              </li>
              <li>value_additions</li>
            </ul>
          </div>

          <div>
            <label className="label">
              <span className="label-text font-bold text-xl font-serif">
                Project{" "}
              </span>
            </label>
            <select
              type="text"
              value={project_name}
              className="input input-bordered w-full max-w-xs mb-4 px-10"
              onChange={(e) => setProjectName(e.target.value)}
            >
              <option value="">Select a Project</option>
              {projects &&
                projects.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.project_name}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <button className="btn btn-outline w-full" onClick={handleDownload}>
              Download PDF
            </button>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ValueAdditionReports;
