import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-search/dist/leaflet-search.min.css";
import "leaflet-search";
import pin from "../OG/images/pin.png";

const MapComponent = ({ geojsonData }) => {
  const mapRef = useRef(null);
  const [plotsUnitsData, setPlotsUnitsData] = useState([]);
  const searchControlRef = useRef(null);
  const [setSearchResults] = useState([]);
  const selectedPlotLayerRef = useRef(null);

  const [currentStyleMode, setCurrentStyleMode] = useState("Unit_Status");
  const dataFetched = plotsUnitsData.length > 0;
  // Define a custom golden pin icon
  const goldenPinIcon = L.icon({
    iconUrl: pin,
    iconSize: [100, 80], // Adjust the size of the golden pin
    iconAnchor: [16, 48], // Adjust the anchor point
  });
  useEffect(() => {
    let geoJsonLayer;

    if (!mapRef.current && geojsonData.features.length > 0) {
      const firstFeature = geojsonData.features[0];
      const coordinates = firstFeature.geometry.coordinates[0];

      if (coordinates.length >= 3) {
        const centerLng =
          coordinates.reduce((sum, coord) => sum + coord[0], 0) /
          coordinates.length;
        const centerLat =
          coordinates.reduce((sum, coord) => sum + coord[1], 0) /
          coordinates.length;

        mapRef.current = L.map("map", {
          minZoom: 5, // Set the minimum zoom level to 2
        }).setView([centerLat, centerLng], 6);

        // Calculate the coordinates for the top-left and bottom-right corners of your project
        const topLeftCoordinates = [5.08, -124.11];
        const bottomRightCoordinates = [-24, -69];

        // Set the maximum bounds for the map based on these coordinates
        mapRef.current.setMaxBounds([
          topLeftCoordinates,
          bottomRightCoordinates,
        ]);

        L.tileLayer(
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAzklEQVRIDb3BAQEAAAABIP5mz6iDBTAYzFRMXAuN7ABTGYMKEzzLMvBDFRgwoTPsDAUxiwwTM82zIAxUYMKRM+wMBTGLDNEzPMDEUYMKRM+wMBTGJ6m8F9B5K3RgEoYMKRM+wMBTGYsM0TMsxMVGAwTKhM+wMBTGJ6SBDHAk4MwBaxjAzRM9bAtwRWWoMKEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwFMb7JzEz7AwF9AAAAABJRU5ErkJggg=="
        ).addTo(mapRef.current);
        // addImageOverlays();
      } else {
        console.error("Invalid coordinates.");
      }
    }

    if (!geoJsonLayer) {
      geoJsonLayer = L.geoJSON(geojsonData, {
        style: styleFunction,
        onEachFeature: onEachFeature,
      });
      geoJsonLayer.addTo(mapRef.current);
    }

    if (!dataFetched) {
      fetch("https://workspace.optiven.co.ke/api/plots")
        .then((response) => response.json())
        .then((data) => {
          setPlotsUnitsData(data);
        })
        .catch((error) => {
          console.error("Error fetching plot units data:", error);
        });
    }

    // Add the Leaflet-Search control to the map with styling and animations
    if (mapRef.current && !searchControlRef.current) {
      searchControlRef.current = new L.Control.Search({
        layer: geoJsonLayer,
        propertyName: "Unit_Number",
        marker: false,
        autoCollapse: true,
        position: "topright",
        initial: false,
        style:
          "background-color: #f0feff; border: 2px solid #0078d4; border-radius: 4px; padding: 5px;",
        textPlaceholder: "Enter Plot Number...",
        textErr: "Plot not found!",
        textCancel: "Cancel",
        searchMove: false,
      });
      // Customize the zoom level and animation duration
      searchControlRef.current._moveToLocation = function (latlng, title, map) {
        const options = {
          animate: true,
          duration: 0.5, // Adjust animation duration (in seconds)
        };

        map.setView(latlng, map.getZoom(), options);
      };
      // Customize the search function to handle numbers without prefixes
      searchControlRef.current._searchText = (text) => {
        if (!isNaN(text)) {
          text = "og" + text;
        }
        L.Control.Search.prototype._searchText.call(
          searchControlRef.current,
          text
        );
      };

      // Handle search result selection
      searchControlRef.current.on("search:locationfound", (e) => {
        if (selectedPlotLayerRef.current) {
          mapRef.current.removeLayer(selectedPlotLayerRef.current);
        }

        const selectedPlot = e.layer;
        selectedPlotLayerRef.current = L.marker(
          selectedPlot.getBounds().getCenter(),
          { icon: goldenPinIcon }
        ).addTo(mapRef.current);

        mapRef.current.fitBounds(e.layer.getBounds());

        setTimeout(() => {
          if (selectedPlotLayerRef.current) {
            mapRef.current.removeLayer(selectedPlotLayerRef.current);
          }
        }, 3000);
      });

      searchControlRef.current.on("search:searchcomplete", (e) => {
        const sortedResults = e.target._recordsList.slice().sort((a, b) => {
          return a.elem.properties.Unit_Number.localeCompare(
            b.elem.properties.Unit_Number
          );
        });
        setSearchResults(sortedResults);
      });

      searchControlRef.current.addTo(mapRef.current);

      const searchInput = document.querySelector(".search-input");
      searchInput.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          searchControlRef.current._searchText(searchInput.value);
        }
      });
    }
  }, [
    currentStyleMode,
    geojsonData.features.length,
    plotsUnitsData,
    dataFetched,
  ]);

  // Define a function to style the GeoJSON features based on Unit_Status
  const styleFunction = (feature) => {
    const Unit_Number = feature.properties.Unit_Number;
    let fillColor = "#f0fdff";

    if (plotsUnitsData) {
      const plotsInfo = plotsUnitsData.find(
        (plots) => plots.Unit_Number === Unit_Number
      );

      if (plotsInfo) {
        if (currentStyleMode === "Unit_Status") {
          const Unit_Status = plotsInfo.Unit_Status;

          if (Unit_Status === "Open") {
            fillColor = "#49e183";
          } else if (Unit_Status === "Reserved") {
            fillColor = "#ffd700";
          } else if (Unit_Status === "Sold") {
            fillColor = "#db1515";
          }
        } else if (currentStyleMode === "Unit_Type") {
          const landSizeRaw = plotsInfo.Unit_Type; // Retrieve land size from DB

          if (landSizeRaw !== undefined && landSizeRaw !== null) {
            // Remove " HA" from the land size
            const landSizeHectares = parseFloat(landSizeRaw.replace(" HA", ""));

            // Convert land size from hectares to acres
            const landSizeAcres = landSizeHectares * 2.47105;

            const sizeColors = {
              Eighth: "#2098ae",
              Quarter: "#9966cc",
              Half: "#ffe135",
              Acre: "#660000",
              "2 Acres": "#489754",
              "5 Acres": "#ff0202",
            };

            const sizeRanges = {
              Eighth: { min: 0, max: 0.19 },
              Quarter: { min: 0.2, max: 0.3 },
              Half: { min: 0.4, max: 0.6 },
              Acre: { min: 0.8, max: 1.3 },
              "2 Acres": { min: 1.8, max: 2.5 },
              "5 Acres": { min: 4.5, max: Infinity },
            };

            const matchingSize = Object.entries(sizeRanges).find(
              ([size, range]) => {
                return landSizeAcres >= range.min && landSizeAcres <= range.max;
              }
            );

            if (matchingSize) {
              fillColor = sizeColors[matchingSize[0]];
            }
          }
        }
      }
    }

    return {
      fillColor: fillColor,
      fillOpacity: 0.9,
      color: "black",
      weight: 2,
    };
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const onEachFeature = (feature, layer) => {
    let popupContent = "<b>Plot Information</b><br />";

    if (feature.properties.project) {
      popupContent += `Plots Name: ${feature.properties.project}<br />`;
    }

    const Unit_Number = feature.properties.Unit_Number;

    if (plotsUnitsData) {
      const plotsInfo = plotsUnitsData.find(
        (plots) => plots.Unit_Number === Unit_Number
      );

      if (plotsInfo) {
        const formattedPrice = `Ksh. ${formatPriceWithCommas(
          plotsInfo.Cash_Price
        )}`;

        const plotIdLabel = L.divIcon({
          className: "plot-id-label",
          html: `<strong><span style="font-size: 8px; font-weight: bold;">${plotsInfo.Unit_Number}</span></strong>`,
          iconSize: [20, 10],
        });

        L.marker(layer.getBounds().getCenter(), { icon: plotIdLabel }).addTo(
          mapRef.current
        );

        if (isMobile) {
          // For mobile, open the tooltip on click
          layer.on("click", function (e) {
            this.openTooltip();
          });
        } else {
          // For desktop, open the tooltip on hover
          layer.on("mouseover", function (e) {
            this.openTooltip();
          });

          layer.on("mouseout", function (e) {
            this.closeTooltip();
          });
        }

        layer.bindTooltip(
          `
        <b>Plots Information</b><br />
        <b>Project Name:</b> ${plotsInfo.Name}<br />
        <b>Status:</b> ${plotsInfo.Unit_Status}<br />
        <b>Price:</b> ${formattedPrice}<br />
        <b>Plot Size:</b> ${plotsInfo.Unit_Type} HA<br />
        
      `,
          {
            className: "custom-tooltip",
          }
        );
      }
    }
  };

  

  const formatPriceWithCommas = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div id="map" style={{ height: "450px", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "180px",
          transform: "translateX(-50%)",
          zIndex: "1000",
          backgroundColor: "white",
          padding: "5px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        <span className="label-text">View Options Status / Size</span>
        <br />
        <label style={{ display: "inline-block", margin: "0 auto" }}>
          <input
            type="checkbox"
            className="toggle toggle-accent"
            checked={currentStyleMode === "Unit_Type"}
            onChange={(e) =>
              setCurrentStyleMode(
                e.target.checked ? "Unit_Type" : "Unit_Status"
              )
            }
          />
          <span className="toggle-mark"></span>
        </label>
      </div>

      {/* Custom CSS for styling the tooltip */}
      <style>
        {`
          /* Apply the custom font to the tooltip */
          .leaflet-tooltip {
            font-family: 'Lausanne-Regular', sans-serif;
            background-color: #86bc39; /* Tooltip background color */
            color: black; /* Default text color */
          }
  
          /* Adjust text color for specific status background colors */
          .leaflet-tooltip.leaflet-tooltip-reserved {
            color: black; /* Text color for Reserved status */
          }
  
          /* Additional styles for legend */
          .legend {
            position: absolute;
            bottom: 10px;
            left: 10px;
            background-color: white;
            padding: 5px;
            border: 1px solid #ccc;
            border-radius: 5px;
            z-index: 999;
          }
  
          .legend-item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          }
  
          .legend-color {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border-radius: 50%;
          }
        `}
      </style>

      {/* Legend */}
      {currentStyleMode === "Unit_Type" && (
        <div className="legend">
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "#2098ae" }}
            ></div>
            Eighth
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "#9966cc" }}
            ></div>
            Quarter
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "#ffe135" }}
            ></div>
            Half
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "#660000" }}
            ></div>
            Acre
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "#489754" }}
            ></div>
            2 Acres
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "#ff0202" }}
            ></div>
            5 Acres
          </div>
          {/* Add more legend items as needed */}
        </div>
      )}
    </div>
  );
};

export default MapComponent;
