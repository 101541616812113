import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import Sidebar from '../../components/sidebar/Sidebar';

const getFirstName = (fullName) => fullName ? fullName.trim().split(' ')[0] : 'User';

function RoomSchedule() {
  const [bookings, setBookings] = useState([]);
  const [users, setUsers] = useState({});
  const location = useLocation();
  const { room, date } = location.state || {};
  const calendarRef = useRef(null);

  useEffect(() => {
    if (!room || !date) {
      console.error('Room or date is missing.');
      return;
    }

    // Fetch room bookings
    axios.get(`https://workspace.optiven.co.ke/api/bookings?roomId=${room.id}`)
      .then(response => {
        const filteredBookings = response.data.filter(booking => booking.room_id === room.id);
        setBookings(filteredBookings);
        const userIds = filteredBookings.map(booking => booking.user_id);
        return axios.get(`https://workspace.optiven.co.ke/api/users?ids=${userIds.join(',')}`);
      })
      .then(response => {
        const usersById = response.data.reduce((acc, user) => {
          acc[user.user_id] = user.fullnames;
          return acc;
        }, {});
        setUsers(usersById);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  }, [room]);

  // Define recurring event for a specific room (e.g., "OTC")
  const recurringEvent = {
    title: 'Morning Devotion',
    startRecur: date, 
    startTime: '08:00:00',
    endTime: '09:00:00',
    daysOfWeek: [ 1, 2, 3, 4, 5] 
  };

  const events = bookings.map(booking => {
    return {
      title: `${booking.purpose} (Booked by ${getFirstName(users[booking.user_id])})`,
      start: new Date(booking.date.split('T')[0] + 'T' + booking.start_time).toISOString(),
      end: new Date(booking.date.split('T')[0] + 'T' + booking.end_time).toISOString(),
    };
  });

  // Add recurring event to the events array only if the room is "OTC"
  if (room.name === 'OTC') {
    events.push(recurringEvent);
  }

  if (!room || !date) {
    return <div className="p-6">Room or date information is missing.</div>;
  }

  return (
    <Sidebar>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Room Schedule for {room.name} on {date}</h1>
        <FullCalendar
          plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek'
          }}
          initialView="timeGridDay"
          initialDate={date}
          events={events}
          ref={calendarRef}
          slotMinTime="00:00:00"
          slotMaxTime="24:00:00"
          allDaySlot={false}
        />
      </div>
    </Sidebar>
  );
}

export default RoomSchedule;
