import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import OverwriteModal from '../../components/conference-components/OverwriteModal'; 

function RoomBookingForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.user.user_id);
  const accessRole = useSelector((state) => state.user.user.Accessrole); 
  const department = useSelector((state) => state.user.user.department);
  const { room, date, numberOfPeople, purpose } = location.state || {};
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);  
  const [bookingData, setBookingData] = useState(null);  
  
  useEffect(() => {
    // Fetch the user's email from the backend using user_id
    axios.get(`https://workspace.optiven.co.ke/api/users/${userId}`)
      .then(response => {
        setEmail(response.data.email);
      })
      .catch(error => {
        console.error("There was an error fetching the user's email!", error);
      });
  }, [userId]);

  const handleBooking = (e) => {
    e.preventDefault();

    const start = new Date(`${date}T${startTime}`);
    const end = new Date(`${date}T${endTime}`);

    if (!startTime || !endTime) {
      setError('Both start time and end time must be filled.');
      return;
    }

    if (start >= end) {
      setError('End time must be after start time.');
      return;
    }

    const bookingData = {
      user_id: userId,
      room_id: room.id,
      date,
      start_time: startTime,
      end_time: endTime,
      number_of_people: numberOfPeople,
      purpose,
      email,
    };

    axios.post('https://workspace.optiven.co.ke/api/bookings', bookingData)
      .then(response => {
        // Display success notification
        toast.success("Booking successful!", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate(`/booking`, { state: { room, date } });
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          if (accessRole === 'conferenceSuperuser' || department === "ICT SYSTEMS") {
            setBookingData(bookingData);  // Store booking data in state
            setShowModal(true);  // Show the modal
          } else {
            toast.error("Booking conflict detected. Please choose another time or room.", {
              position: "top-center",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.error("Error booking the room!", {
            position: "top-center",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const handleClose = () => setShowModal(false);  

  const handleConfirm = () => {
    if (bookingData) {
      axios.post('https://workspace.optiven.co.ke/api/bookings/overwrite', bookingData)
        .then(response => {
          toast.success("Booking overwritten successfully!", {
            position: "top-center",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate(`/booking`, { state: { room, date } });
        })
        .catch(error => {
          toast.error("Error overwriting the booking!", {
            position: "top-center",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      setShowModal(false);  // Hide the modal after confirming
    }
  };

  return (
    <Sidebar>
      <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
        <ToastContainer />
        <div className="mx-auto max-w-lg">
          <h1 className="text-center text-2xl font-bold text-indigo-600 sm:text-3xl">Book Room: {room.name}</h1>
          <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
            Select your start and end time.
          </p>
          <form onSubmit={handleBooking} className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow-lg sm:p-6 lg:p-8">
            <div>
              <label htmlFor="startTime" className="sr-only">Start Time</label>
              <input
                type="time"
                name="startTime"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="endTime" className="sr-only">End Time</label>
              <input
                type="time"
                name="endTime"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                className="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow-sm"
                required
              />
            </div>
            {error && <div className="text-red-500">{error}</div>}
            <button
              type="submit"
              className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
            >
              Book Now
            </button>
          </form>
        </div>
      </div>
      <OverwriteModal show={showModal} handleClose={handleClose} handleConfirm={handleConfirm} />  {/* Include the modal component */}
    </Sidebar>
  );
}

export default RoomBookingForm;
