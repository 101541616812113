import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Eye } from "react-feather";

const Insights = () => {
  const [currentMonthData, setCurrentMonthData] = useState(null);
  const [previousMonthData, setPreviousMonthData] = useState(null);
  const [totalSalesData, setTotalSalesData] = useState(null);
  const [previousMonthTotalSales, setPreviousMonthTotalSales] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [tasks, setTasks] = useState([]);
  const userId = useSelector((state) => state.user.user.user_id);
  const [previousMonthTotalCost, setPreviousMonthTotalCost] = useState(null);
  const [totalCostVariance, setTotalCostVariance] = useState(null);

  const BUDGET = 200000;
  const [percentageDifference, setPercentageDifference] = useState(null);

  // Load initial blurred state from local storage or default to false
  const initialBlurredState = localStorage.getItem("isCardBlurred") === "true";
  const [isCardBlurred, setIsCardBlurred] = useState(initialBlurredState);

  // Update local storage when blurred state changes
  useEffect(() => {
    localStorage.setItem("isCardBlurred", isCardBlurred.toString());
  }, [isCardBlurred]);

  const toggleBlur = () => {
    setIsCardBlurred(!isCardBlurred);
  };

  const cardStyle = {
    // Your card style here
    filter: isCardBlurred ? "blur(5px)" : "none",
  };
  const eyeIconStyle = {
    cursor: "pointer",
    float: "right", // Move the eye icon to the right
    marginRight: "10px", // Add some space to the right of the icon
  };

  useEffect(() => {
    // Fetch current month's MIB data
    fetch("https://workspace.optiven.co.ke/api/mib/karen")
      .then((response) => response.json())
      .then((data) => setCurrentMonthData(data))
      .catch((error) =>
        console.error("Error fetching current month's MIB data:", error)
      );

    // Fetch last month's MIB data
    fetch("https://workspace.optiven.co.ke/api/mib/previous/karen")
      .then((response) => response.json())
      .then((data) => setPreviousMonthData(data))
      .catch((error) =>
        console.error("Error fetching last month's MIB data:", error)
      );
    // Fetch total sales data
    fetch("https://workspace.optiven.co.ke/api/mib/total-sales-karen/current")
      .then((response) => response.json())
      .then((data) => setTotalSalesData(data))
      .catch((error) =>
        console.error("Error fetching total sales data:", error)
      );
    // Fetch total sales data for the previous month
    fetch("https://workspace.optiven.co.ke/api/mib/total-sales-karen/previous")
      .then((response) => response.json())
      .then((data) => {
        // Set the previous month's total sales data
        setPreviousMonthTotalSales(data.totalSales);
      })
      .catch((error) =>
        console.error(
          "Error fetching previous month's total sales data:",
          error
        )
      );
    // Fetch total cost from your API endpoint
    fetch(
      "https://workspace.optiven.co.ke/api/event/total-cost/current-month/karen"
    )
      .then((response) => response.json())
      .then((data) => {
        const currentTotalCost = data.totalCost;

        // Calculate variance and percentage difference
        const variance = currentTotalCost - BUDGET;
        const percentageDifference = ((variance / BUDGET) * 100).toFixed(2);

        // Update state with current total cost, variance, and percentage difference
        setTotalCost(currentTotalCost);
        setTotalCostVariance(variance);
        setPercentageDifference(percentageDifference);
      })
      .catch((error) => {
        console.error("Error fetching total cost:", error);
      });
    // Fetch total cost data for the previous month
    fetch(
      "https://workspace.optiven.co.ke/api/event/total-cost/previous-month/karen"
    )
      .then((response) => response.json())
      .then((data) => {
        // Set the previous month's total cost data
        const previousMonthTotalCost = data.totalCost;

        // Calculate variance
        const variance = previousMonthTotalCost
          ? (
              ((totalCost - previousMonthTotalCost) / previousMonthTotalCost) *
              100
            ).toFixed(2)
          : "N/A";

        // Update state with previous month's total cost and variance
        setPreviousMonthTotalCost(previousMonthTotalCost);
        setTotalCostVariance(variance);
      })
      .catch((error) => {
        console.error(
          "Error fetching previous month's total cost data:",
          error
        );
      });
    const fetchUserTasks = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/perf/assigned/${userId}`
        );
        setTasks(response.data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    if (userId) {
      fetchUserTasks();
    }
  }, [userId, totalCost]);

  if (!tasks || tasks.length === 0) {
    // Handle the case where tasks is undefined or an empty array
    return <div>No tasks available</div>;
  }

  // Calculate progress based on the number of tasks in different states
  const calculateDaysLeft = (endDate) => {
    const now = new Date();
    const due = new Date(endDate);
    due.setDate(due.getDate() + 1);
    due.setHours(0, 0, 0, 0);

    const timeDiff = due - now;
    const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysLeft;
  };
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(
    (task) => task.actual_output !== null
  ).length;
  const pastDueTasks = tasks.filter((task) => {
    const daysLeft = calculateDaysLeft(task.end_date);
    return daysLeft <= 0 && task.actual_output === null;
  }).length;

  const textStyle = { color: "white" };

  // Check if both data are available
  if (!currentMonthData || !previousMonthData) {
    return null;
  }

  // Calculate percentage change
  const percentageChange = previousMonthData.MIB.total
    ? (
        ((currentMonthData.MIB.total - previousMonthData.MIB.total) /
          previousMonthData.MIB.total) *
        100
      ).toFixed(2)
    : "N/A";
  // Determine badge color based on the sign of the percentage change
  //const badgeColor = percentageChange >= 0 ? "badge-success" : "badge-danger";

  return (
    <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div className="card bg-blue-900 bordered" style={cardStyle}>
        <div className="card-body">
          <h2 className="card-title" style={textStyle}>
            {" "}
            Current Month's MIB
          </h2>
          <p className="text-xl" style={textStyle}>
            Ksh. {parseFloat(currentMonthData.MIB.total).toLocaleString()}{" "}
            <span
              role="img"
              aria-label="eye-icon"
              onClick={toggleBlur}
              style={eyeIconStyle}
            >
              <Eye size={30} />
            </span>
          </p>
          <p>
            <span
              className="badge"
              style={{
                backgroundColor: percentageChange >= 0 ? "#28a745" : "#dc3545",
                color: "white",
              }}
            >
              {`Variance : `}
              <span
                className={
                  percentageChange >= 0 ? "text-success" : "text-danger"
                }
              >
                <strong>{`${percentageChange}%`}</strong>
              </span>
            </span>
          </p>

          <div className="justify-end card-actions">
            <i className="fas fa-users"></i>
          </div>
        </div>
      </div>

      <div className="card bg-green-900 bordered">
        <div className="card-body">
          <h2 className="card-title" style={textStyle}>
            Total Cost Accrued
          </h2>
          <p className="text-xl" style={textStyle}>
            {totalCost ? `Ksh. ${totalCost}` : "Loading..."}
          </p>
          <p style={textStyle}>Budget: Ksh. {BUDGET}</p>
          {totalCostVariance !== null && (
            <span
              className={`badge ${
                totalCostVariance >= 0 ? "badge-success" : "badge-error"
              }`}
            >
              Variance: {totalCostVariance} %
            </span>
          )}
          {percentageDifference !== null && (
            <span
              className={`badge ${
                percentageDifference >= 0 ? "badge-success" : "badge-error"
              }`}
            >
              % Difference: {percentageDifference}%
            </span>
          )}
          <div className="justify-end card-actions">
            <i className="fas fa-wallet"></i>
          </div>
        </div>
      </div>

      <div className="card bg-yellow-900 bordered">
        <div className="card-body">
          <h2 className="card-title" style={textStyle}>
            Total Sales
          </h2>
          <p className="text-xl" style={textStyle}>
            {totalSalesData ? totalSalesData.totalSales : "Loading..."}
          </p>
          <p style={textStyle}>Current Month</p>
          {previousMonthTotalSales !== null && (
            <span
              className={`badge ${
                totalSalesData.totalSales - previousMonthTotalSales >= 0
                  ? "badge-success"
                  : "badge-error"
              }`}
            >
              Variance:{" "}
              {(
                ((totalSalesData.totalSales - previousMonthTotalSales) /
                  previousMonthTotalSales) *
                100
              ).toFixed(2)}
              %
            </span>
          )}
          <div className="justify-end card-actions">
            <i className="fas fa-file-alt"></i>
          </div>
        </div>
      </div>

      <div className="card bg-red-900 bordered">
        <div className="card-body">
          <h2 className="card-title" style={textStyle}>
            Pending Tasks
          </h2>
          <p className="text-xl" style={textStyle}>
            {totalTasks - completedTasks - pastDueTasks}
          </p>
          {/*<p>
          <span className="badge badge-success"><span style={{ color: "black" }}>{completedTasks}  </span>
          <strong> completed </strong></span>
              </p>*/}
          <div className="justify-end card-actions">
            <i className="fas fa-users"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insights;
