import React, { useState, useCallback, useEffect, useMemo } from "react";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import axios from "axios";

const HighSchoolSpecific = () => {
  const [startDate, setStartDate] = useState("");
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [endDate, setEndDate] = useState("");
  const token = localStorage.getItem("token");

  const handleDownload = async () => {
    if (!startDate || !endDate) {
      toast.error("Both dates must be chosen before submitting the form.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (endDate < startDate) {
      toast.error("End date cannot be before start date.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const response = await axios.get(
        "https://workspace.optiven.co.ke/api/payments-education/download-pdf-institution",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { selectedSchool, startDate, endDate }, // Ensure schools is included
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "Specific Institution Payments Report.pdf";
      link.click();
      toast.success("PDF downloaded successfully.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error(
        "An error occurred while downloading the PDF. Please try again.",
        {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await fetch(
          "https://workspace.optiven.co.ke/api/payments-education/all-schools",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setSchools(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSchools();
  }, [token]);

  return (
    <Sidebar>
      {" "}
      <section className="text-center overflow-x-hidden">
        {" "}
        <div className="hero min-h-screen bg-white">
          {" "}
          <div className="hero-content flex-col lg:flex-row-reverse">
            {" "}
            <div className="text-center lg:text-left">
              {" "}
              <h1 className="text-5xl font-bold">
                Institution Specific
                <br></br>Reports Payments{" "}
              </h1>{" "}
              <p className="py-6">
                Download the Institution Specific Payments Report you require
                <br></br>by choosing the range of dates specified.{" "}
              </p>{" "}
            </div>{" "}
            <br></br>
            <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
              {" "}
              <div className="form-control card-body">
                {" "}
                <h1 className="font-bold text-lg">
                  Institution Payments Reports{" "}
                </h1>{" "}
                <label className="label">
                  {" "}
                  <span className="label-text font-bold">
                    Select Institution:
                  </span>
                   {" "}
                </label>{" "}
                <select
                  className="input input-bordered w-full"
                  name="selectedSchool"
                  value={selectedSchool}
                  onChange={(e) => setSelectedSchool(e.target.value)}
                  required
                >
                  <option value="NONE SELECTED">Please select a School</option>
                  {schools.map((school) => (
                    <option
                      key={school.pay_institution}
                      value={school.pay_institution}
                    >
                      {school.pay_institution}
                    </option>
                  ))}
                </select>
                <label className="label">
                  {" "}
                  <span className="label-text font-bold">Start Date</span> {" "}
                </label>{" "}
                <input
                  type="date"
                  value={startDate}
                  className="input input-bordered w-full max-w-xs mb-4"
                  onChange={(e) => setStartDate(e.target.value)}
                />{" "}
                <label className="label">
                  {" "}
                  <span className="label-text font-bold">End Date</span> {" "}
                </label>{" "}
                <input
                  type="date"
                  className="input input-bordered w-full max-w-xs mb-4"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />{" "}
                <div className="form-control mt-6">
                  {" "}
                  <button className="btn btn-primary" onClick={handleDownload}>
                    Download PDF{" "}
                  </button>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </section>{" "}
    </Sidebar>
  );
};
export default HighSchoolSpecific;
