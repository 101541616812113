const geojsonData = {
  type: "FeatureCollection",

  features: [
    {
      type: "Feature",
      id: "sme3ce0a96",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.11614277, 40.67539455],
            [-74.11366378, 40.68286974],
            [-74.11842168, 40.68377715],
            [-74.12109251, 40.67636989],
            [-74.11614277, 40.67539455],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX10",
      },
    },
    {
      type: "Feature",
      id: "smaa9758c4",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07322424, 40.67465743],
            [-74.07567809, 40.66742054],
            [-74.07068919, 40.66643736],
            [-74.06825364, 40.67368791],
            [-74.07322424, 40.67465743],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX01",
      },
    },
    {
      type: "Feature",
      id: "sme338a076",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07567809, 40.66742054],
            [-74.07322424, 40.67465743],
            [-74.07815018, 40.67561822],
            [-74.0806009, 40.66839069],
            [-74.07567809, 40.66742054],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX02",
      },
    },
    {
      type: "Feature",
      id: "smc4e1ead3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.0806009, 40.66839069],
            [-74.07815018, 40.67561822],
            [-74.08316116, 40.67659558],
            [-74.08560868, 40.66937756],
            [-74.0806009, 40.66839069],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX03",
      },
    },
    {
      type: "Feature",
      id: "sm6fb67349",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08560868, 40.66937756],
            [-74.08316116, 40.67659558],
            [-74.08827907, 40.67759378],
            [-74.09072333, 40.67038548],
            [-74.08560868, 40.66937756],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX04",
      },
    },
    {
      type: "Feature",
      id: "sme404b96d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.09072333, 40.67038548],
            [-74.08827907, 40.67759378],
            [-74.09337059, 40.67858682],
            [-74.09581161, 40.67138819],
            [-74.09072333, 40.67038548],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX05",
      },
    },
    {
      type: "Feature",
      id: "sm1af2259d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.09659769, 40.67154309],
            [-74.09415717, 40.67874023],
            [-74.09912095, 40.67970834],
            [-74.10155831, 40.67252063],
            [-74.09659769, 40.67154309],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX06",
      },
    },
    {
      type: "Feature",
      id: "sm1430e00c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.10155831, 40.67252063],
            [-74.09912095, 40.67970834],
            [-74.10413087, 40.68068544],
            [-74.1041215, 40.68076365],
            [-74.10660478, 40.67351507],
            [-74.10155831, 40.67252063],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX07",
      },
    },
    {
      type: "Feature",
      id: "smf3f6bdd3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.10660478, 40.67351507],
            [-74.1041215, 40.68076365],
            [-74.10408796, 40.68104343],
            [-74.10889136, 40.68195954],
            [-74.11137972, 40.67445599],
            [-74.10660478, 40.67351507],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX08",
      },
    },
    {
      type: "Feature",
      id: "sm32ed748c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.11137972, 40.67445599],
            [-74.10889136, 40.68195954],
            [-74.11366378, 40.68286974],
            [-74.11614277, 40.67539455],
            [-74.11137972, 40.67445599],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX09",
      },
    },
    {
      type: "Feature",
      id: "sm1057c33f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.06671022, 40.67815162],
            [-74.07627469, 40.67999195],
            [-74.07753011, 40.6762394],
            [-74.06799615, 40.67433888],
            [-74.06671022, 40.67815162],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX56",
      },
    },
    {
      type: "Feature",
      id: "sm02ab314b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07627469, 40.67999195],
            [-74.06671022, 40.67815162],
            [-74.06551354, 40.68169954],
            [-74.07508711, 40.68354152],
            [-74.07627469, 40.67999195],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX55",
      },
    },
    {
      type: "Feature",
      id: "smc4bc8ff0",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07508711, 40.68354152],
            [-74.06551354, 40.68169954],
            [-74.06415131, 40.68573803],
            [-74.07373525, 40.68758189],
            [-74.07508711, 40.68354152],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX50",
      },
    },
    {
      type: "Feature",
      id: "smbf3412e8",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07373525, 40.68758189],
            [-74.06415131, 40.68573803],
            [-74.06297494, 40.68922534],
            [-74.07256782, 40.69107083],
            [-74.07373525, 40.68758189],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX49",
      },
    },
    {
      type: "Feature",
      id: "sm898e007a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07256782, 40.69107083],
            [-74.06297494, 40.68922534],
            [-74.06167457, 40.69308004],
            [-74.07127733, 40.69492733],
            [-74.07256782, 40.69107083],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX44",
      },
    },
    {
      type: "Feature",
      id: "smed5dc626",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07127733, 40.69492733],
            [-74.06167457, 40.69308004],
            [-74.06042558, 40.69678221],
            [-74.07003784, 40.69863122],
            [-74.07127733, 40.69492733],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX43",
      },
    },
    {
      type: "Feature",
      id: "sm1e85b15f",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07003784, 40.69863122],
            [-74.06042558, 40.69678221],
            [-74.05911013, 40.70068117],
            [-74.06873239, 40.70253199],
            [-74.07003784, 40.69863122],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX38",
      },
    },
    {
      type: "Feature",
      id: "sm34ceef17",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.06873239, 40.70253199],
            [-74.05911013, 40.70068117],
            [-74.05787375, 40.70434555],
            [-74.06750541, 40.70619808],
            [-74.06873239, 40.70253199],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX37",
      },
    },
    {
      type: "Feature",
      id: "sm698cab5c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.06750541, 40.70619808],
            [-74.05787375, 40.70434555],
            [-74.0566237, 40.70805022],
            [-74.06078652, 40.70879841],
            [-74.06524963, 40.70967669],
            [-74.06653709, 40.70909117],
            [-74.06653709, 40.70909117],
            [-74.06750541, 40.70619808],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX32",
      },
    },
    {
      type: "Feature",
      id: "sm93abde9c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.09002831, 40.7103397],
            [-74.08010277, 40.70837225],
            [-74.07874121, 40.7123191],
            [-74.08756561, 40.71406819],
            [-74.088925, 40.71361026],
            [-74.09002831, 40.7103397],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX34",
      },
    },
    {
      type: "Feature",
      id: "sm43e6ae09",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08010277, 40.70837225],
            [-74.09002831, 40.7103397],
            [-74.09131642, 40.70652112],
            [-74.08141818, 40.70455897],
            [-74.08010277, 40.70837225],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX35",
      },
    },
    {
      type: "Feature",
      id: "smfe523cef",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08141818, 40.70455897],
            [-74.09131642, 40.70652112],
            [-74.09251875, 40.70295663],
            [-74.082646, 40.70099942],
            [-74.08141818, 40.70455897],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX40",
      },
    },
    {
      type: "Feature",
      id: "sm30f74078",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.082646, 40.70099942],
            [-74.09251875, 40.70295663],
            [-74.09378398, 40.69920546],
            [-74.08392407, 40.69729397],
            [-74.082646, 40.70099942],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX41",
      },
    },
    {
      type: "Feature",
      id: "smacab1391",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08392407, 40.69729397],
            [-74.09378398, 40.69920546],
            [-74.09510413, 40.69529123],
            [-74.08528618, 40.69334466],
            [-74.08392407, 40.69729397],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX46",
      },
    },
    {
      type: "Feature",
      id: "sme2026a53",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08528618, 40.69334466],
            [-74.09510413, 40.69529123],
            [-74.09632703, 40.69166513],
            [-74.08653501, 40.68972359],
            [-74.08528618, 40.69334466],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX47",
      },
    },
    {
      type: "Feature",
      id: "smf2578b39",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08653501, 40.68972359],
            [-74.09632703, 40.69166513],
            [-74.09755706, 40.68801771],
            [-74.08777725, 40.68612143],
            [-74.08653501, 40.68972359],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX52",
      },
    },
    {
      type: "Feature",
      id: "smbea04b2e",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08777725, 40.68612143],
            [-74.09755706, 40.68801771],
            [-74.09877349, 40.68441041],
            [-74.08903333, 40.68247895],
            [-74.08777725, 40.68612143],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX53",
      },
    },
    {
      type: "Feature",
      id: "sm78e692c3",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08903333, 40.68247895],
            [-74.09877349, 40.68441041],
            [-74.10004325, 40.68064476],
            [-74.09033409, 40.67870668],
            [-74.08903333, 40.68247895],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX58",
      },
    },
    {
      type: "Feature",
      id: "sm50e57b45",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07928103, 40.68048779],
            [-74.0890518, 40.68242539],
            [-74.09033409, 40.67870668],
            [-74.08055967, 40.67675551],
            [-74.07928103, 40.68048779],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX57",
      },
    },
    {
      type: "Feature",
      id: "smfae5810d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.0890518, 40.68242539],
            [-74.07928103, 40.68048779],
            [-74.07804519, 40.68409492],
            [-74.08780847, 40.68603092],
            [-74.0890518, 40.68242539],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX54",
      },
    },
    {
      type: "Feature",
      id: "sm42bb3874",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08780847, 40.68603092],
            [-74.07804519, 40.68409492],
            [-74.07676856, 40.68782093],
            [-74.08652409, 40.68975529],
            [-74.08780847, 40.68603092],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX51",
      },
    },
    {
      type: "Feature",
      id: "smf91d6b22",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08652409, 40.68975529],
            [-74.07676856, 40.68782093],
            [-74.07549001, 40.69155234],
            [-74.08523778, 40.69348505],
            [-74.08652409, 40.68975529],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX48",
      },
    },
    {
      type: "Feature",
      id: "sm39b338c5",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08523778, 40.69348505],
            [-74.07549001, 40.69155234],
            [-74.0742468, 40.69518041],
            [-74.08398702, 40.69711151],
            [-74.08523778, 40.69348505],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX45",
      },
    },
    {
      type: "Feature",
      id: "sm60eeb49c",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08398702, 40.69711151],
            [-74.0742468, 40.69518041],
            [-74.0729464, 40.69897514],
            [-74.08267873, 40.70090457],
            [-74.08398702, 40.69711151],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX42",
      },
    },
    {
      type: "Feature",
      id: "sm39c2a25a",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08267873, 40.70090457],
            [-74.0729464, 40.69897514],
            [-74.07170157, 40.70260752],
            [-74.08142634, 40.70453535],
            [-74.08267873, 40.70090457],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX39",
      },
    },
    {
      type: "Feature",
      id: "sm7638f729",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08142634, 40.70453535],
            [-74.07170157, 40.70260752],
            [-74.07046478, 40.70621625],
            [-74.08018204, 40.70814249],
            [-74.08142634, 40.70453535],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX36",
      },
    },
    {
      type: "Feature",
      id: "sm010ee5e9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08018204, 40.70814249],
            [-74.07046478, 40.70621625],
            [-74.06929747, 40.70962206],
            [-74.06941242, 40.70964417],
            [-74.06984158, 40.71055505],
            [-74.07874121, 40.7123191],
            [-74.08018204, 40.70814249],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX33",
      },
    },
    {
      type: "Feature",
      id: "sm3b28bc6d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.09198708, 40.71281249],
            [-74.09943946, 40.71445259],
            [-74.1013185, 40.70966962],
            [-74.09467884, 40.70841619],
            [-74.09343429, 40.70903428],
            [-74.09198708, 40.71281249],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX20",
      },
    },
    {
      type: "Feature",
      id: "sm5a4d8d56",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.09943946, 40.71445259],
            [-74.09198708, 40.71281249],
            [-74.09043854, 40.71685502],
            [-74.09061262, 40.71728854],
            [-74.09769704, 40.7188875],
            [-74.09943946, 40.71445259],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX22",
      },
    },
    {
      type: "Feature",
      id: "smed42afc1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.1013185, 40.70966962],
            [-74.09769704, 40.7188875],
            [-74.10127708, 40.71969551],
            [-74.10639473, 40.7114578],
            [-74.10587974, 40.71053068],
            [-74.1013185, 40.70966962],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX21",
      },
    },
    {
      type: "Feature",
      id: "sm9f70cdfe",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.11249289, 40.7014544],
            [-74.10671509, 40.70034628],
            [-74.10419536, 40.70789701],
            [-74.10719938, 40.70847309],
            [-74.10862631, 40.70790379],
            [-74.11230451, 40.70201702],
            [-74.11249289, 40.7014544],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX17",
      },
    },
    {
      type: "Feature",
      id: "sm38c06b12",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.10671509, 40.70034628],
            [-74.11249289, 40.7014544],
            [-74.11504213, 40.69384047],
            [-74.10925361, 40.69273838],
            [-74.10671509, 40.70034628],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX16",
      },
    },
    {
      type: "Feature",
      id: "sm3ff25282",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.09704309, 40.69861019],
            [-74.10183866, 40.69952995],
            [-74.10441232, 40.69181662],
            [-74.10051529, 40.69107463],
            [-74.09942095, 40.69161154],
            [-74.09704309, 40.69861019],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX14",
      },
    },
    {
      type: "Feature",
      id: "sm5e3255f9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.10183866, 40.69952995],
            [-74.09704309, 40.69861019],
            [-74.09478609, 40.7052524],
            [-74.0954942, 40.70622838],
            [-74.09935624, 40.70696901],
            [-74.10183866, 40.69952995],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX15",
      },
    },
    {
      type: "Feature",
      id: "smc25be860",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.10669128, 40.70041764],
            [-74.10185215, 40.69948953],
            [-74.09935624, 40.70696901],
            [-74.10419536, 40.70789701],
            [-74.10669128, 40.70041764],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX18",
      },
    },
    {
      type: "Feature",
      id: "sm3e3de030",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.10185215, 40.69948953],
            [-74.10669128, 40.70041764],
            [-74.10925361, 40.69273838],
            [-74.10441232, 40.69181662],
            [-74.10185215, 40.69948953],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX19",
      },
    },
    {
      type: "Feature",
      id: "smf60b503d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.11334397, 40.68357842],
            [-74.11093763, 40.69078536],
            [-74.11570732, 40.69170101],
            [-74.11819642, 40.6845175],
            [-74.11334397, 40.68357842],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX11",
      },
    },
    {
      type: "Feature",
      id: "sm09578d0b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.10585296, 40.68980923],
            [-74.10826175, 40.68259485],
            [-74.10377685, 40.68172687],
            [-74.10254304, 40.68222317],
            [-74.10065478, 40.68797511],
            [-74.10138433, 40.68895135],
            [-74.10585296, 40.68980923],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX13",
      },
    },
    {
      type: "Feature",
      id: "sm58878dfa",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.10826175, 40.68259485],
            [-74.10585296, 40.68980923],
            [-74.11093763, 40.69078536],
            [-74.11334397, 40.68357842],
            [-74.10826175, 40.68259485],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX12",
      },
    },
    {
      type: "Feature",
      id: "sm095805c9",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.05760941, 40.71832073],
            [-74.06015699, 40.71080236],
            [-74.06078647, 40.70879839],
            [-74.05662365, 40.70805019],
            [-74.05335136, 40.71749184],
            [-74.05760941, 40.71832073],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX31",
      },
    },
    {
      type: "Feature",
      id: "sm9d580927",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.06015699, 40.71080236],
            [-74.05760941, 40.71832073],
            [-74.0624353, 40.71926014],
            [-74.06496434, 40.7117966],
            [-74.0601427, 40.71084786],
            [-74.06015699, 40.71080236],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX30",
      },
    },
    {
      type: "Feature",
      id: "sm09eb428b",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.06496434, 40.7117966],
            [-74.0624353, 40.71926014],
            [-74.06729154, 40.72020545],
            [-74.06981736, 40.71275151],
            [-74.06496434, 40.7117966],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX29",
      },
    },
    {
      type: "Feature",
      id: "sm4acca6b1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.06981736, 40.71275151],
            [-74.06729154, 40.72020545],
            [-74.07205802, 40.72113327],
            [-74.07458068, 40.71368876],
            [-74.06981736, 40.71275151],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX28",
      },
    },
    {
      type: "Feature",
      id: "sm102a9364",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07458068, 40.71368876],
            [-74.07205802, 40.72113327],
            [-74.07710742, 40.72211615],
            [-74.07962674, 40.71468162],
            [-74.07458068, 40.71368876],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX27",
      },
    },
    {
      type: "Feature",
      id: "sm32786112",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.07962674, 40.71468162],
            [-74.07710742, 40.72211615],
            [-74.08187389, 40.72304395],
            [-74.0844153, 40.7156238],
            [-74.07962674, 40.71468162],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX26",
      },
    },
    {
      type: "Feature",
      id: "smef81d08d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.0844153, 40.7156238],
            [-74.08187389, 40.72304395],
            [-74.08643847, 40.72393244],
            [-74.08915879, 40.7169741],
            [-74.08911057, 40.71696327],
            [-74.08923931, 40.71657294],
            [-74.0844153, 40.7156238],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX25",
      },
    },
    {
      type: "Feature",
      id: "smddaa543d",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.08915879, 40.7169741],
            [-74.08643847, 40.72393244],
            [-74.09206309, 40.72502724],
            [-74.09472295, 40.71822366],
            [-74.08915879, 40.7169741],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX24",
      },
    },
    {
      type: "Feature",
      id: "sm51022cd1",
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-74.09472295, 40.71822366],
            [-74.09206309, 40.72502724],
            [-74.09729667, 40.72604591],
            [-74.10127707, 40.71969551],
            [-74.09472295, 40.71822366],
          ],
        ],
      },
      properties: {
        Unit_Number: "TX23",
      },
    },
    {
      type: "Feature",
      id: "sm7a559e06",
      geometry: {
        type: "LineString",
        coordinates: [
          [-74.07068919, 40.66643736],
          [-74.04094881, 40.66057771],
        ],
      },
      properties: {
        Unit_Number: "TX",
      },
    },
    {
      type: "Feature",
      id: "sm239155db",
      geometry: {
        type: "LineString",
        coordinates: [
          [-74.13870998, 40.65947083],
          [-74.12978358, 40.67405405],
          [-74.0546388, 40.65940574],
        ],
      },
      properties: {
        Unit_Number: "TX",
      },
    },
    {
      type: "Feature",
      id: "smc3dcc133",
      geometry: {
        type: "LineString",
        coordinates: [
          [-74.14454646, 40.67691824],
          [-74.13613505, 40.67542107],
          [-74.14385982, 40.66259608],
        ],
      },
      properties: {
        Unit_Number: "TX",
      },
    },
    {
      type: "Feature",
      id: "smec79e6a3",
      geometry: {
        type: "LineString",
        coordinates: [
          [-74.14454646, 40.68095393],
          [-74.1337318, 40.67887102],
          [-74.1037769, 40.7273468],
          [-74.09957119, 40.73411108],
        ],
      },
      properties: {
        Unit_Number: "TX",
      },
    },
    {
      type: "Feature",
      id: "sm83dda4e3",
      geometry: {
        type: "LineString",
        coordinates: [
          [-74.09227559, 40.73391597],
          [-74.09682461, 40.7267614],
        ],
      },
      properties: {
        Unit_Number: "TX",
      },
    },
    {
      type: "Feature",
      id: "sm62b5992d",
      geometry: {
        type: "LineString",
        coordinates: [
          [-74.11249289, 40.7014544],
          [-74.12746615, 40.67750408],
          [-74.12109251, 40.67636989],
        ],
      },
      properties: {
        Unit_Number: "TX",
      },
    },
  ],
};

export default geojsonData;
