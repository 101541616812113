import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import huh from "../../../../assets/app-illustrations/Shrug-pana.png";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "react-feather";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

function formatDate(inputDate) {
  // Create a new Date object from the input string
  const date = new Date(inputDate);

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

const ViewFow = () => {
  const [fowList, setFowList] = useState([]);
  const [selectedFow, setSelectedFow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterMonth, setFilterMonth] = useState("All Months");

   // Use useSelector to fetch the userId from the Redux store
   const userId = useSelector((state) => state.user.user.user_id);

   useEffect(() => {
    const fetchFowData = async () => {
      try {
        const response = await fetch("https://workspace.optiven.co.ke/api/fow");
        if (response.ok) {
          const fowData = await response.json();
    
          const fowWithFullNames = await Promise.all(
            fowData.map(async (fow) => {
              // Fetch user details based on staff_id
              const userResponse = await fetch(`https://workspace.optiven.co.ke/api/users/${fow.staff_id}`);
              if (userResponse.ok) {
                const userData = await userResponse.json();
                // Assuming the user data object has a 'fullnames' field
                const assignedUserName = userData.fullnames; 
                return { ...fow, assignedUserName }; // Merge FOW data with the assigned user's full name
              }
              return fow; // Return the FOW entry as is if there's an issue fetching user data
            })
          );
    
          setFowList(fowWithFullNames);
        } else {
          console.error("Failed to fetch Focus of the Week data");
        }
      } catch (error) {
        console.error("Error fetching Focus of the Week data:", error);
      }
    };
    
     fetchFowData();
   }, [userId]);

  // Filter fowList based on selected month
  const filteredFowList =
    filterMonth === "All Months"
      ? fowList
      : fowList.filter((fow) => {
          const endDate = new Date(fow.end_date);
          return endDate.getMonth() === parseInt(filterMonth) - 1; // Adjust for 0-based month index
        });

  // Month filter dropdown
  const MonthFilter = () => (
    <div className="flex justify-end mb-4">
      <select
        className="select select-bordered"
        value={filterMonth}
        onChange={(e) => setFilterMonth(e.target.value)}
      >
        <option value="All Months">All Months</option>
        {[...Array(12).keys()].map((month) => (
          <option key={month} value={month + 1}>
            {new Date(0, month).toLocaleString("default", { month: "long" })}
          </option>
        ))}
      </select>
    </div>
  );

  const Modal = ({ fow, closeModal: closeModalProp }) => {
    const [actual_mib, setActualMib] = useState(fow.actual_mib || "");
    const [actual_sales, setActualSales] = useState(fow.actual_sales || "");
    const [variance, setVariance] = useState(fow.variance || "");
    const [comments, setComments] = useState(fow.comments || "");
    const [recommendations, setRecommendations] = useState(
      fow.recommendations || ""
    );
    // Define a function to clear modal states
    const clearModalStates = () => {
      setActualMib("");
      setActualSales("");
      setVariance("");
      setComments("");
      setRecommendations("");
    };

    // Call the closeModalProp and clear states
    const closeModal = () => {
      closeModalProp(); // Call the function passed as prop to close the modal
      clearModalStates(); // Clear the states within the Modal component
    };
    const handleActualMibChange = (e) => {
      setActualMib(e.target.value);
    };
    const handleActualSalesChange = (e) => {
      setActualSales(e.target.value);
    };
    const handleVarianceChange = (e) => {
      setVariance(e.target.value);
    };

    const handleCommentsChange = (e) => {
      setComments(e.target.value);
    };

    const handleRecommendationsChange = (e) => {
      setRecommendations(e.target.value);
    };

    const handleSubmit = async () => {

      if (!actual_mib.trim()) {
        toast.error("Actual MIB is required.", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    
      if (!actual_sales.trim()) {
        toast.error("Actual Sales is required.", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    
      if (!variance.trim()) {
        toast.error("Variance is required.", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    
      if (!comments.trim()) {
        toast.error("Comments are required.", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    
      if (!recommendations.trim()) {
        toast.error("Recommendations are required.", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      const updatedFow = {
        actual_mib,
        actual_sales,
        variance,
        comments,
        recommendations,
      };

      try {
        const response = await fetch(
          `https://workspace.optiven.co.ke/api/fow/${fow.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedFow),
          }
        );

        if (response.ok) {
          closeModal();
          toast.success("Focus of the Week updated successfully!", {
            position: "top-center",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          const updatedFowList = fowList.map((item) => {
            if (item.id === fow.id) {
              return { ...item, status: "completed" }; // Update status to 'completed'
            }
            return item;
          });
          setFowList(updatedFowList);
        } else {
          console.error("Failed to update Focus of the Week");
        }
      } catch (error) {
        console.error("Error updating Focus of the Week:", error);
        toast.error(
          error.message || "An error occurred. Please try again later.",
          {
            position: "top-center",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    };

    const isCompleted = fow.status === "completed";

    return (
      <motion.div
        initial={{ y: "-100vh" }}
        animate={{ y: 0 }}
        exit={{ y: "100vh" }}
        transition={{ type: "spring", stiffness: 260, damping: 20 }}
        className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"
      >
        <div className="relative bg-white p-6 rounded-lg shadow-lg">
          <div
            className="absolute top-0 right-0 m-3 cursor-pointer"
            onClick={closeModal}
          >
            <X size={25} className="mr-2 text-red-500" />
          </div>

          <h1 className="font-bold mb-2">{fow.title}</h1>
          <p>
            <strong>Start Date: </strong>{" "}
          </p>
          <p> {formatDate(fow.start_date)}</p>
          <p>
            <strong>End Date: </strong>{" "}
          </p>
          <p> {formatDate(fow.end_date)}</p>
          <p>
            <strong>Description: </strong>{" "}
          </p>
          <p> {fow.description}</p>
          <p>
            <strong>Expected MIB: </strong>{" "}
          </p>
          <p> {fow.expected_mib}</p>

          <p>
            <strong>Expected Sales: </strong>{" "}
          </p>
          <p> {fow.expected_sales}</p>
          <p>
            <strong>Custom Activities: </strong>{" "}
          </p>
          <p> {fow.custom_activity}</p>

          <p>
            <strong>Status: </strong>{" "}
          </p>
          <p>
            {" "}
            {fow.status === "pending" && (
              <span className="badge badge-warning">Pending</span>
            )}
            {fow.status === "approved" && (
              <span className="badge badge-info">Approved</span>
            )}
            {fow.status === "completed" && (
              <span className="badge badge-success">Completed</span>
            )}
            {fow.status === "rejected" && (
              <span className="badge badge-error">Rejected</span>
            )}
          </p>

          <p>
            <strong>Actual MIB: </strong>
          </p>
          {isCompleted ? (
            <p>{actual_mib}</p>
          ) : (
            <textarea
              value={actual_mib}
              onChange={handleActualMibChange}
              className="textarea textarea-bordered w-full"
            ></textarea>
          )}
          <p>
            <strong>Actual Sales: </strong>
          </p>
          {isCompleted ? (
            <p>{actual_sales}</p>
          ) : (
            <textarea
              value={actual_sales}
              onChange={handleActualSalesChange}
              className="textarea textarea-bordered w-full"
            ></textarea>
          )}
          <p>
            <strong>Variance: </strong>
          </p>
          {isCompleted ? (
            <p>{variance}</p>
          ) : (
            <textarea
              value={variance}
              onChange={handleVarianceChange}
              className="textarea textarea-bordered w-full"
            ></textarea>
          )}

          <p>
            <strong>Comments: </strong>
          </p>
          {isCompleted ? (
            <p>{comments}</p>
          ) : (
            <textarea
              value={comments}
              onChange={handleCommentsChange}
              className="textarea textarea-bordered w-full"
            ></textarea>
          )}

          <p>
            <strong>Recommendations: </strong>
          </p>
          {isCompleted ? (
            <p>{recommendations}</p>
          ) : (
            <textarea
              value={recommendations}
              onChange={handleRecommendationsChange}
              className="textarea textarea-bordered w-full"
            ></textarea>
          )}

          {!isCompleted && (
            <button
              onClick={handleSubmit}
              className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mx-auto block"
            >
              Submit
            </button>
          )}
        </div>
      </motion.div>
    );
  };

  const handleRowClick = (fow) => {
    setSelectedFow(fow);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFow(null);
  };

  return (
    <Sidebar>
      <div className="container mx-auto py-6">
        <div className="block mx-10">
          <div className="text-sm breadcrumbs">
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/create-fow">Create Focus of the Week</Link>
              </li>
              <li>View Focus of the Week</li>
            </ul>
          </div>
        </div>
        <MonthFilter />
        <div className="block">
          {Array.isArray(filteredFowList) && filteredFowList.length > 0 ? (
            <table className="table w-full bg-base-100 shadow-xl mx-10">
              <tbody>
                {fowList.map((fow) => (
                  <tr
                    key={fow.id}
                    onClick={() => handleRowClick(fow)}
                    className="cursor-pointer"
                    style={{
                      color:
                        fow.status === "rejected"
                          ? "rgba(0, 0, 0, 0.4)"
                          : "inherit",
                      textDecoration:
                        fow.status === "rejected" ? "line-through" : "none",
                      pointerEvents:
                        fow.status === "rejected" ? "none" : "auto",
                    }}
                  >
                    <td className="flex items-center justify-between py-6">
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">
                          Start Date
                        </h1>
                        <p>{formatDate(fow.start_date)}</p>
                      </div>
                    </td>
                    <td>
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">
                          End Date
                        </h1>
                        <p>{formatDate(fow.end_date)}</p>
                      </div>
                    </td>
                    <td>
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">Title</h1>
                        <p>{fow.title}</p>
                      </div>
                    </td>
                    <td>
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">
                          Description
                        </h1>
                        <p className="truncate">{fow.description}</p>
                      </div>
                    </td>
                    <td>
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">
                          Expected MIB
                        </h1>
                        <p className="truncate">{fow.expected_mib}</p>
                      </div>
                    </td>
                    <td>
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">
                          Expected Sales
                        </h1>
                        <p className="truncate">{fow.expected_sales}</p>
                      </div>
                    </td>
                    <td>
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">
                          Custom Activity
                        </h1>
                        <p className="truncate">{fow.custom_activity}</p>
                      </div>
                    </td>
                    <td>
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">Status</h1>
                        {fow.status === "pending" && (
                          <span className="badge badge-warning">Pending</span>
                        )}
                        {fow.status === "approved" && (
                          <span className="badge badge-info">Approved</span>
                        )}
                        {fow.status === "completed" && (
                          <span className="badge badge-success">Completed</span>
                        )}
                        {fow.status === "rejected" && (
                          <span className="badge badge-error">Rejected</span>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="w-24">
                        <h1 className="label text-sm text-gray-400">
                          Staff Name
                        </h1>
                        <p className="truncate">{<td>{fow.assignedUserName || 'N/A'}</td> }</p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center">
              <div className="flex flex-col items-center mt-20">
                <img src={huh} alt="huh" className="lg:w-96" />
                <h1 className="font-bold text-center">
                  Nothing to see here. Add a focus of the week to get started.
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && <Modal fow={selectedFow} closeModal={closeModal} />}
      </AnimatePresence>
    </Sidebar>
  );
};

export default ViewFow;
