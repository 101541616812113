import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

export const ProjectStats = ({
  name,
  valueAdditions,
  numberOfValueAdditions,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const customAddValueAddition = () => {
    navigate(`/projects/${id}/add-value-addition`);
  };
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");
  const maintenance = accessRoles.includes("maintenance");
  const valueAddition = accessRole.includes("valueAddition");
  const operations = accessRole.includes("operations");
  const valueAdmin = accessRole.includes("valueAdmin");
  const ict = accessRole.includes(department === "ICT SYSTEMS");

  

  const deleteProject = async () => {
    try {
      await axios.delete(`https://workspace.optiven.co.ke/api/projects/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
      );
      // Redirect to home page after displaying success message
      toast.success("Project deleted", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/projects");
    } catch (error) {
      console.error(error);
      // Display error message
      toast.error("Project couldn't be deleted has associated data", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const editProject = () => {
    navigate(`/projects/${id}/edit-project`);
  };

  return (
    <div className="px-4 pt-0 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pt-0">
      <div className="grid gap-2 row-gap-8 lg:grid-cols-5">
        <div className="grid gap-8 lg:col-span-2">
          <div className="p-5 flex justify-center items-center">
            <p className="mb-2 text-7xl font-extrabold">{name}</p>
          </div>
          <div className="flex justify-start">
            {(!maintenance && !operations) || valueAddition || valueAdmin || ict ? (
              <>
                <button
                  className="btn btn-warning btn-outline lg:ml-6"
                  onClick={editProject}
                >
                  Edit Project
                </button>
                <button
                  className="btn btn-accent ml-2 lg:ml-6"
                  onClick={deleteProject}
                >
                  Delete Project
                </button>
              </>
            ) : null}
          </div>
        </div>
        <div className="grid border divide-y rounded p-4 lg:col-span-3 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
          <div className="p-10">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center">
                <p className="uppercase text-lg font-bold text-gray-800 sm:text-base text-center">
                  Total Value Additions
                </p>
              </div>

              <div className="flex justify-center items-center">
                <h1 className="text-9xl font-bold text-primary text-center">
                  {numberOfValueAdditions}
                </h1>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between p-10">
            <div>
              <p className="ml-1 text-lg font-bold text-gray-800 sm:text-base uppercase text-center">
                Value Additions
              </p>

              <div className="grid lg:col-span-3 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
                {valueAdditions}
                </div>
                <div>
                {(!maintenance && !operations) || valueAddition || valueAdmin || ict ? (
                  <button
                    className="btn btn-circle m-1 btn-primary"
                    onClick={customAddValueAddition}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v12M6 12h12"
                      />
                    </svg>
                  </button>
                  ) : null}
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
