import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ children }) => {
  const accessRole = useSelector((state) => state.user.accessRole).trim();
  const department = useSelector((state) => state.user.user.department);
  const accessRoles = accessRole.split("#");

  const optivenHomes = accessRoles.includes("optivenHomes");

  return (
    <>
      <div className="drawer">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content overflow-visible">{children}</div>
        <div className="drawer-side">
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 bg-base-100 text-base-content">
            {/* Home */}
            <li>
              <Link to="/optiven-home" className="font-bold my-1">
                Home
              </Link>
            </li>

            {/* Clients*/}
            {(optivenHomes || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Clients</div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/optiven-dash"
                  >
                    All Clients
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/design-clients"
                  >
                    Design Clients
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/const-clients"
                  >
                    Construction Clients
                  </Link>
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/terminated-contracts"
                  >
                    Terminated Clients
                  </Link>
                </div>
              </div>
            )}

            {/* Document Management*/}
            {(optivenHomes || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Documents</div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                    <input type="checkbox" className="peer" />
                    <div className="collapse-title font-bold">Upload</div>
                    <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/upload-design"
                      >
                        Design Contracts
                      </Link>
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/upload-const"
                      >
                        Construction Contracts
                      </Link>
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/upload-archi-images"
                      >
                        Architectural Images
                      </Link>
                    </div>
                  </div>

                  <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                    <input type="checkbox" className="peer" />
                    <div className="collapse-title font-bold">View</div>
                    <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/view-design"
                      >
                        Design Contracts
                      </Link>
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/view-const"
                      >
                        Construction Contracts
                      </Link>
                      <Link
                        className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                        to="/view-archi-images"
                      >
                        Architectural Images
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Project Management*/}
            
            {/* Client Notifications*/}
            {(optivenHomes || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">Client Notification</div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/client-notif"
                  >
                    Send Client Notifications
                  </Link>
                  
                </div>
              </div>
            )}


            {/* Reporting and Analytics*/}
            {(optivenHomes || department === "ICT SYSTEMS") && (
              <div className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box my-1">
                <input type="checkbox" className="peer" />
                <div className="collapse-title font-bold">
                  Reports and Analytics
                </div>
                <div className="collapse-content -mt-5 flex flex-col menu bg-base-100">
                  <Link
                    className="font-sans mt-1 hover:bg-base-200 rounded p-2"
                    to="/client-reports"
                  >
                    Client Reports
                  </Link>
                </div>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
