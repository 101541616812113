import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import formatDate from "../../../../utils/formatDate";
import { toast } from "react-toastify";
import axios from "axios";

function Specific() {
  const [studentDetails, setStudentDetails] = useState([]);
  const [allStudentDetails, setAllStudentDetails] = useState([]);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [level, setLevel] = useState("");
  const [county, setCounty] = useState("");
  const [allName, setAllName] = useState("");
  const [allAge, setAllAge] = useState("");
  const [allGender, setAllGender] = useState("");
  const [allPhone, setAllPhone] = useState("");
  const [AllLevel, setAllLevel] = useState("");
  const [allCounty, setAllCounty] = useState("");
  const [allHistory, setAllHistory] = useState("");
  const [allDate, setAllDate] = useState("");
  const [studentId, setStudentId] = useState("");

  const [date, setDate] = useState("");
  const [pay, setPay] = useState([]);

  const [image, setImage] = useState("");
  const [history, setHistory] = useState("");

  const { id } = useParams();
  const token = useSelector((state) => state.user.token);
  const [searchedQuery, setSearchedQuery] = useState("");

  const filteredStudentPaymentDetails = useMemo(() => {
    return studentDetails.filter((item) => {
      const level = item.pay_institution && item.pay_institution.toLowerCase();
      const query = searchedQuery.toLowerCase();

      if (searchedQuery === "") {
        return true; // Include all items when the search query is empty
      } else if (level && level.includes(query)) {
        return true; // Include the item if it matches the search query
      } else {
        return false; // Exclude the item if it doesn't match the search query
      }
    });
  }, [searchedQuery, studentDetails]);

  const handleDownload = async () => {
    try {
      const response = await axios.get(
        "https://workspace.optiven.co.ke/api/payments-education/download-specific-student-payment",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { studentId }, // Ensure schools is included
          responseType: "blob",
        }
      );
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = "Specific Student Payments Report.pdf";
      link.click();
      toast.success("PDF downloaded successfully.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error(
        "An error occurred while downloading the PDF. Please try again.",
        {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await fetch(
          `https://workspace.optiven.co.ke/api/education/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setStudentDetails(data);
        setStudentId(data[0].student_id);
        setName(data[0].educ_name);
        setAge(data[0].educ_age);
        setGender(data[0].educ_gender);
        setPhone(data[0].educ_phone);
        setLevel(data[0].educ_level);
        setImage(data[0].educ_image);
        setHistory(data[0].case_history);
        setDate(data[0].created_at);
        setCounty(data[0].county);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchAllStudentDetails = async () => {
      try {
        const response = await fetch(
          `https://workspace.optiven.co.ke/api/education/payment/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();

        setAllStudentDetails(data);
        setAllName(data[0].educ_name);
        setAllAge(data[0].educ_age);
        setAllGender(data[0].educ_gender);
        setAllPhone(data[0].educ_phone);
        setAllLevel(data[0].educ_level);
        setAllHistory(data[0].case_history);
        setAllDate(data[0].created_at);
        setAllCounty(data[0].county);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAllStudentDetails();

    fetchStudentDetails();
  }, [token]);

  return (
    <Sidebar>
      {studentDetails.length === 0 ? (
        <section className="text-gray-600 body-font">
          <div className=" mx-auto flex flex-col">
            <div className="lg:w-screen mx-auto">
              <div className="h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src="https://images.unsplash.com/photo-1579546929518-9e396f3cc809?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                />
              </div>
              <div className="mx-auto -mt-24 card w-4/6 h-72 bg-base-100 shadow-xl">
                <figure className="px-10 -mt-20 z-2">
                  <img
                    className="mask mask-circle w-36 h-36"
                    src={require("../components/avatar.jpg")}
                    alt={allName}
                  />
                </figure>
                <div className="card-body items-center text-center">
                  <h2 className="text-lg font-semibold mb-1 text-center">
                    {allName}
                  </h2>
                  <p className="text-gray-500 mb-4 text-center">
                    {AllLevel} Student
                  </p>
                  <p className="font-bold text-center my-2">
                    Created on {formatDate(allDate)}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <b>Student's Personal Data</b>
                <div className="w-96 bg-gray-100 rounded-lg shadow-lg mx-4 lg:w-full">
                  <div className="p-4">
                    <h5 className="font-semibold mb-1">Name</h5>
                    <p className="text-gray-500 mb-4">{allName}</p>
                    <h5 className="font-semibold mb-1">Age</h5>
                    <p className="text-gray-500 mb-4">{allAge}</p>
                    <h5 className="font-semibold mb-1">Gender</h5>
                    <p className="text-gray-500 mb-4">{allGender}</p>
                    <h5 className="font-semibold mb-1">Phone Contact</h5>
                    <p className="text-gray-500 mb-4">{allPhone}</p>
                    <h5 className="font-semibold mb-1">Level of Education</h5>
                    <p className="text-gray-500 mb-4">{AllLevel}</p>
                    <h5 className="font-semibold mb-1">Origin County</h5>
                    <p className="text-gray-500 mb-4">{allCounty}</p>
                  </div>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t pt-4 m-4 text-center sm:text-left">
                <div className="flex flex-col sm:flex-row mt-6">
                  <div className="overflow-x-auto mb-4 sm:w-full">
                    <div className="mt-6 md:flex md:items-center md:justify-between">
                      <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg dark:bg-gray-900 rtl:flex-row-reverse dark:border-gray-700 dark:divide-gray-700"></div>
                      <div className="relative flex items-center mt-4 md:mt-0">
                        <span className="absolute">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                          </svg>
                        </span>
                        <input
                          type="text"
                          className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                          placeholder="Search student by name..."
                          value={searchedQuery}
                          onChange={(e) => setSearchedQuery(e.target.value)}
                        />
                      </div>
                    </div>
                    <h2 className="text-center">
                      <b>Student's Payments Data</b>
                    </h2>
                    {studentDetails && studentDetails.length > 0 ? (
                      <table className="table w-full table-zebra">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Institution</th>
                            <th>Level Paid for</th>
                            <th>Amount Disbursed</th>
                            <th>Confirmation of Pay</th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredStudentPaymentDetails.map(
                            (studentDetail, index) => (
                              <tr key={studentDetail.educ_id}>
                                <th>{index + 1}</th>
                                <td>{studentDetail.pay_institution}</td>
                                <td>{studentDetail.student_level}</td>
                                <td>{studentDetail.pay_amount}</td>
                                <td>{studentDetail.pay_confirmation}</td>
                                <td>{studentDetail.pay_comment}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <p>No Payment details available.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="text-gray-600 body-font">
          <div className=" mx-auto flex flex-col">
            <div className="lg:w-screen mx-auto">
              <div className="h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-cover object-center h-full w-full"
                  src="https://images.unsplash.com/photo-1579546929518-9e396f3cc809?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                />
              </div>
              <div className="mx-auto -mt-24 card w-4/6 h-72 bg-base-100 shadow-xl">
                <figure className="px-10 -mt-20 z-2">
                  <img
                    className="mask mask-circle w-36 h-36"
                    src={require("../components/avatar.jpg")}
                    alt={name}
                  />
                </figure>
                <div className="card-body items-center text-center">
                  <h2 className="text-lg font-semibold mb-1 text-center">
                    {name}
                  </h2>
                  <p className="text-gray-500 mb-4 text-center">
                    {level} Student
                  </p>
                  <p className="font-bold text-center my-2">
                    Created on {formatDate(date)}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <b>Student's Personal Data</b>
                <div className="w-96 bg-gray-100 rounded-lg shadow-lg mx-4 lg:w-full">
                  <div className="p-4">
                    <h5 className="font-semibold mb-1">Name</h5>
                    <p className="text-gray-500 mb-4">{name}</p>
                    <h5 className="font-semibold mb-1">Age</h5>
                    <p className="text-gray-500 mb-4">{age}</p>
                    <h5 className="font-semibold mb-1">Gender</h5>
                    <p className="text-gray-500 mb-4">{gender}</p>
                    <h5 className="font-semibold mb-1">Phone Contact</h5>
                    <p className="text-gray-500 mb-4">{phone}</p>
                    <h5 className="font-semibold mb-1">Level of Education</h5>
                    <p className="text-gray-500 mb-4">{level}</p>
                    <h5 className="font-semibold mb-1">Institution </h5>
                    <p className="text-gray-500 mb-4">{history}</p>
                    <h5 className="font-semibold mb-1">Origin County</h5>
                    <p className="text-gray-500 mb-4">{county}</p>
                  </div>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t pt-4 m-4 text-center sm:text-left">
                <div className="flex flex-col sm:flex-row mt-6">
                  <div className="overflow-x-auto mb-4 sm:w-full">
                    <div className="mt-6 md:flex md:items-center md:justify-between">
                      <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg dark:bg-gray-900 rtl:flex-row-reverse dark:border-gray-700 dark:divide-gray-700"></div>
                      <div className="relative flex items-center mt-4 md:mt-0">
                        <span className="absolute">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-5 h-5 mx-3 text-gray-400 dark:text-gray-600"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                          </svg>
                        </span>
                        <input
                          type="text"
                          className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                          placeholder="Search student by name..."
                          value={searchedQuery}
                          onChange={(e) => setSearchedQuery(e.target.value)}
                        />
                      </div>
                    </div>
                    <h2 className="text-center">
                      <b>Student's Payments Data</b>
                    </h2>
                    {studentDetails && studentDetails.length > 0 ? (
                      <table className="table w-full table-zebra">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Institution</th>
                            <th>Level Paid for</th>
                            <th>Amount Disbursed</th>
                            <th>Confirmation of Pay</th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredStudentPaymentDetails.map(
                            (studentDetail, index) => (
                              <tr key={studentDetail.educ_id}>
                                <th>{index + 1}</th>
                                <td>{studentDetail.pay_institution}</td>
                                <td>{studentDetail.student_level}</td>
                                <td>{studentDetail.pay_amount}</td>
                                <td>{studentDetail.pay_confirmation}</td>
                                <td>{studentDetail.pay_comment}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <p>No student details available.</p>
                    )}
                    <div className="flex justify-center mt-4">
                      <button
                        className="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-primary rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-green-600 dark:hover:bg-green-500 dark:bg-green-600"
                        // onClick={handlePrint}
                        onClick={handleDownload}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Sidebar>
  );
}

export default Specific;
