import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AnimatePresence, motion } from "framer-motion";
import Sidebar from "../components/Sidebar";
import Modal from "../components/TasksModal";
import Steps from "../components/Steps";
import VerticalAccordion from "../components/VerticalAccordion";

const ViewConstProgress = () => {
    const { clientId } = useParams();
    const [phases, setPhases] = useState([]);
    const [currentPhase, setCurrentPhase] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [taskCost, setTaskCost] = useState("");

    useEffect(() => {
        const fetchPhases = async () => {
            try {
                const response = await axios.get(
                    `https://workspace.optiven.co.ke/api/homes-clients/clients/${clientId}/phases`
                );
                const phaseData = response.data;
                const currentPhaseIndex = phaseData.findIndex(phase => phase.is_current_phase);
                setCurrentPhase(currentPhaseIndex !== -1 ? currentPhaseIndex : 0);
                setPhases(phaseData);
                setIsLoading(false);
            } catch (error) {
                console.error("Failed to fetch client phases", error);
                setIsLoading(false);
            }
        };

        fetchPhases();
    }, [clientId]);

    const toggleTaskCompletion = (phaseIndex, taskIndex, taskId) => {
        if (phaseIndex !== currentPhase) return;  // Prevent toggling tasks in inactive phases
        setSelectedTask({ phaseIndex, taskIndex, taskId });
        setModalOpen(true);
    };

    const handleConfirmTaskCompletion = async () => {
        const { phaseIndex, taskIndex, taskId } = selectedTask;
        const updatedPhases = phases.map((phase, index) => {
            if (index === phaseIndex) {
                const updatedTasks = phase.tasks.map((task, idx) => {
                    if (idx === taskIndex) {
                        task.completed = !task.completed;
                        // Update task status in the database
                        axios.patch(`https://workspace.optiven.co.ke/api/homes-clients/clients/${clientId}/tasks/${taskId}`, { completed: task.completed });
                        // Update task cost if provided
                        if (taskCost) {
                            axios.patch(`https://workspace.optiven.co.ke/api/homes-clients/clients/${clientId}/tasks/${taskId}/cost`, { cost: taskCost })
                                .then(response => {
                                    console.log(response.data);
                                })
                                .catch(error => {
                                    console.error("Error updating task cost", error);
                                });
                        }
                    }
                    return task;
                });
                return { ...phase, tasks: updatedTasks };
            }
            return phase;
        });
        setPhases(updatedPhases);
        setModalOpen(false);
        setTaskCost("");
    };

    const allTasksCompleted = (tasks) => tasks.every(task => task.completed);

    const handleNextPhase = async () => {
        if (currentPhase < phases.length - 1) {
            const nextPhaseIndex = currentPhase + 1;
            try {
                const response = await axios.patch(`https://workspace.optiven.co.ke/api/homes-clients/clients/${clientId}/current-phase`, { phaseId: phases[nextPhaseIndex].id });
                console.log("Phase update response:", response.data);
                const updatedPhases = phases.map((phase, index) => {
                    if (index === currentPhase) {
                        phase.is_current_phase = false;
                    } else if (index === currentPhase + 1) {
                        phase.is_current_phase = true;
                    }
                    return phase;
                });
                setPhases(updatedPhases);
                setCurrentPhase(nextPhaseIndex);
                toast.success('Phase completed, proceed to next phase.');
            } catch (error) {
                console.error("Error updating current phase:", error);
                toast.error('Failed to update the current phase.');
            }
        } else {
            toast.success('Construction completed.');
        }
    };

    const BarLoader = () => {
        const variants = {
            initial: { scaleY: 0.5, opacity: 0 },
            animate: {
                scaleY: 1, opacity: 1,
                transition: { repeat: Infinity, repeatType: "mirror", duration: 1, ease: "circIn" },
            },
        };

        return (
            <motion.div
                transition={{ staggerChildren: 0.25 }}
                initial="initial"
                animate="animate"
                className="flex gap-1"
            >
                <motion.div variants={variants} className="h-12 w-2 bg-black" />
                <motion.div variants={variants} className="h-12 w-2 bg-black" />
                <motion.div variants={variants} className="h-12 w-2 bg-black" />
                <motion.div variants={variants} className="h-12 w-2 bg-black" />
                <motion.div variants={variants} className="h-12 w-2 bg-black" />
            </motion.div>
        );
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <BarLoader />
                <h1>Loading Progress Details</h1>
            </div>
        );
    }

    return (
        <Sidebar>
            <div className="container mx-auto mt-8 p-4">
                <ToastContainer />
                <div className="bg-white shadow-lg rounded-lg p-6">
                    <div className="flex items-center justify-center">
                        <h1 className="text-3xl font-bold mb-4">
                            Construction Progress for Client
                        </h1>
                    </div>
                    <Steps phases={phases} currentStep={currentPhase} />
                    <VerticalAccordion phases={phases} currentPhase={currentPhase} toggleTaskCompletion={toggleTaskCompletion} allTasksCompleted={allTasksCompleted} handleNextPhase={handleNextPhase} />
                </div>
            </div>
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleConfirmTaskCompletion}
                taskName={selectedTask ? phases[selectedTask.phaseIndex].tasks[selectedTask.taskIndex].name : ""}
                setTaskCost={setTaskCost}
                taskCost={taskCost}
            />
        </Sidebar>
    );
};

export default ViewConstProgress;
