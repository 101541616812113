import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'react-feather';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditClient = ({ isOpen, setIsOpen, clientData, updateClient }) => {
  const [formData, setFormData] = useState({
    client_name: "",
    id_no: "",
    LR_no: "",
    plot_size: "",
    type: "",
    cost: "",
    email: "",
  });

  useEffect(() => {
    if (clientData) {
      setFormData({
        client_name: clientData.client_name,
        id_no: clientData.id_no,
        LR_no: clientData.LR_no,
        plot_size: clientData.plot_size,
        type: clientData.type,
        cost: clientData.cost,
        email: clientData.email,
      });
    }
  }, [clientData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.client_name ||
      !formData.id_no ||
      !formData.email ||
      !formData.LR_no ||
      !formData.plot_size ||
      !formData.cost
    ) {
      toast.error('Please fill all the fields');
      return;
    }
    try {
      await updateClient(clientData.id, formData);
      toast.success('Client updated successfully!');
      setIsOpen(false);
      window.location.reload();
    } catch (error) {
      toast.error('Failed to update client');
      console.error("Failed to update client", error);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/30 backdrop-blur-sm flex justify-center items-center z-50"
          onClick={() => setIsOpen(false)}
        >
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.5 }}
            className="bg-white p-6 rounded-lg w-full max-w-md sm:max-w-lg lg:max-w-xl xl:max-w-2xl overflow-y-auto max-h-full"
            onClick={e => e.stopPropagation()}
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Edit Client</h2>
              <button onClick={() => setIsOpen(false)}><X /></button>
            </div>
            <form className="space-y-4 mt-4" onSubmit={handleSubmit}>
              <label className="block text-sm font-medium text-gray-700">Client Name</label>
              <input
                type="text"
                name="client_name"
                placeholder="Client Name"
                className="w-full p-2 border rounded"
                value={formData.client_name}
                onChange={handleChange}
              />
              <label className="block text-sm font-medium text-gray-700">Id Number</label>
              <input
                type="text"
                name="id_no"
                placeholder="ID Number"
                className="w-full p-2 border rounded"
                value={formData.id_no}
                onChange={handleChange}
              />
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="w-full p-2 border rounded"
                value={formData.email}
                onChange={handleChange}
              />
              <label className="block text-sm font-medium text-gray-700">Land Reference Number</label>
              <input
                type="text"
                name="LR_no"
                placeholder="Land Reference No"
                className="w-full p-2 border rounded"
                value={formData.LR_no}
                onChange={handleChange}
              />
              <label className="block text-sm font-medium text-gray-700">Plot Size</label>
              <input
                type="text"
                name="plot_size"
                placeholder="Plot Size"
                className="w-full p-2 border rounded"
                value={formData.plot_size}
                onChange={handleChange}
              />
              <label className="block text-sm font-medium text-gray-700">Type</label>
              <input
                type="text"
                name="type"
                placeholder="Type"
                className="w-full p-2 border rounded"
                value={formData.type}
                onChange={handleChange}
              />
              <label className="block text-sm font-medium text-gray-700">Cost</label>
              <input
                type="text"
                name="cost"
                placeholder="Cost"
                className="w-full p-2 border rounded"
                value={formData.cost}
                onChange={handleChange}
              />
              <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
                Update Client
              </button>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default EditClient;
