import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import Sidebar from "../components/Sidebar";
import { AlertOctagon, CloudLightning } from "react-feather";

const ConstructionPhase = () => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showContinueConstructionModal, setShowContinueConstructionModal] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [constructionCost, setConstructionCost] = useState("");
  const [propStartDate, setPropStartDate] = useState("");
  const [propEndDate, setPropEndDate] = useState("");

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await axios.get(
          `https://workspace.optiven.co.ke/api/homes-clients/${clientId}`
        );
        setClient(response.data);
        setTimeout(() => setIsLoading(false), 3000);
      } catch (error) {
        console.error("Failed to fetch client details", error);
        setIsLoading(false);
      }
    };

    fetchClientDetails();
  }, [clientId]);

  const downloadContract = async () => {
    try {
      const response = await axios.get(
        `https://workspace.optiven.co.ke/api/homes-clients/${clientId}/const-contract`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Contract_${clientId}.pdf`);
      document.body.appendChild(link);
      link.click();
      setDownloaded(true);
    } catch (error) {
      console.error("Failed to download contract", error);
    }
  };

  const handleBeginConstruction = () => {
    setShowContinueConstructionModal(true);
  };

  const confirmBeginConstruction = async () => {
    // Check if any field is empty
    if (!constructionCost || !propStartDate || !propEndDate) {
      toast.error("Construction cost and dates must not be empty.");
      return;
    }

    // Date validation
    if (new Date(propStartDate) > new Date(propEndDate)) {
      toast.error("Start date cannot be after end date.");
      return;
    }

    if (new Date(propEndDate) < new Date(propStartDate)) {
      toast.error("End date cannot be before start date.");
      return;
    }

    try {
      await axios.patch(
        `https://workspace.optiven.co.ke/api/homes-clients/${clientId}/construction_details`,
        {
          const_cost: constructionCost,
          prop_start_date: propStartDate,
          prop_end_date: propEndDate,
        }
      );
      toast.success("Construction phase initiated and cost updated!");
      setClient((prevClient) =>
        prevClient.map((c) => 
          c.id === clientId ? { ...c, const_cost: constructionCost, prop_start_date: propStartDate, prop_end_date: propEndDate } : c
        )
      ); 
      window.setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Failed to initiate construction phase", error);
      toast.error("Failed to initiate construction phase.");
    } finally {
      setShowContinueConstructionModal(false);
    }
  };

  const handleTerminateContract = () => {
    setShowConfirmationModal(true);
  };

  const confirmTerminateContract = () => {
    axios
      .patch(
        `https://workspace.optiven.co.ke/api/homes-clients/${clientId}/change-status/terminated`
      )
      .then(() => {
        toast.success("Contract terminated successfully!");
        navigate(`/client/${clientId}`, { replace: true });
        window.setTimeout(() => {
          window.location.reload();
        }, 500); // waits 500ms before reloading the page
      })
      .catch((error) => {
        console.error("Failed to terminate contract", error);
        toast.error("Failed to terminate contract.");
      })
      .finally(() => {
        setShowConfirmationModal(false);
      });
  };

  const BarLoader = () => {
    const variants = {
      initial: { scaleY: 0.5, opacity: 0 },
      animate: {
        scaleY: 1,
        opacity: 1,
        transition: {
          repeat: Infinity,
          repeatType: "mirror",
          duration: 1,
          ease: "circIn",
        },
      },
    };

    return (
      <motion.div
        transition={{ staggerChildren: 0.25 }}
        initial="initial"
        animate="animate"
        className="flex gap-1"
      >
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
        <motion.div variants={variants} className="h-12 w-2 bg-black" />
      </motion.div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <BarLoader />
        <h1>Loading Client Details</h1>
      </div>
    );
  }

  const isTerminated = client.length > 0 && client[0].status === "terminated";
  const isConstructionStarted = client.length > 0 && client[0].const_cost;

  return (
    <Sidebar>
      <div className="flex justify-center items-center min-h-screen">
        <ToastContainer />
        <div className="bg-white shadow-lg rounded-lg p-6 w-full sm:w-3/4 lg:w-1/2">
          <div className="flex items-center justify-center">
            <CloudLightning className="text-lg" />
          </div>
          <div className="flex items-center justify-center">
            <h1 className="text-3xl font-bold mb-4">
              Construction Phases for Client {client[0]?.client_name}
            </h1>
          </div>
          {client.map((client, index) => (
            <div className="text-black mb-6" key={index}>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <p>
                  <strong>Name:</strong> {client.client_name}
                </p>
                <p>
                  <strong>ID Number:</strong> {client.id_no}
                </p>
                <p>
                  <strong>Email:</strong> {client.email}
                </p>
                <p>
                  <strong>Land Registration No:</strong> {client.LR_no}
                </p>
                <p>
                  <strong>Plot Size:</strong> {client.plot_size} HA
                </p>
                <p>
                  <strong>Type:</strong> {client.type}
                </p>
                <p>
                  <strong>Cost:</strong> Ksh.{client.cost}
                </p>
              </div>
            </div>
          ))}
          <div className="flex justify-center mt-3 mb-6">
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded w-full sm:w-1/3 lg:w-1/4"
              onClick={downloadContract}
            >
              Download Contract
            </button>
          </div>
          {!isTerminated && !isConstructionStarted && (
            <div className="flex justify-center space-x-4">
              <button
                className="bg-green-500 text-white py-2 px-4 rounded w-full sm:w-1/3 lg:w-1/4"
                onClick={handleBeginConstruction}
              >
                Begin Construction
              </button>
              <button
                className="bg-red-500 text-white py-2 px-4 rounded w-full sm:w-1/3 lg:w-1/4"
                onClick={handleTerminateContract}
              >
                Terminate Contract
              </button>
            </div>
          )}
          {isConstructionStarted && (
            <div className="flex justify-center mt-3 mb-6">
              <button 
                className="btn btn-outline btn-info text-white py-2 px-4 rounded w-full sm:w-1/3 lg:w-1/4"
                onClick={() => navigate(`/view-progress/${clientId}`)}
              >
                View Progress
              </button>
            </div>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <div className="flex items-center justify-center text-red-500">
              <AlertOctagon className="text-lg" />
            </div>
            <h2 className="text-lg font-semibold mb-4 text-center text-red-500">
              Terminate Contract
            </h2>
            <p className="text-sm mb-4">
              Are you sure you want to terminate the contract?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white py-2 px-4 rounded"
                onClick={confirmTerminateContract}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {showContinueConstructionModal && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md">
            <div className="flex items-center justify-center text-green-500">
              <AlertOctagon className="text-lg" />
            </div>
            <h2 className="text-lg font-semibold mb-4 text-center text-green-500">
              Begin Construction
            </h2>
            <p className="text-sm mb-4">
              Are you sure you want to begin the construction phase?
            </p>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Construction Cost
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="input input-bordered input-primary w-full max-w-xs"
                value={constructionCost}
                onChange={(e) => setConstructionCost(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Proposed Start Date
              </label>
              <input
                type="date"
                className="input input-bordered input-primary w-full max-w-xs"
                value={propStartDate}
                onChange={(e) => setPropStartDate(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Proposed End Date
              </label>
              <input
                type="date"
                className="input input-bordered input-primary w-full max-w-xs"
                value={propEndDate}
                onChange={(e) => setPropEndDate(e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded mr-2"
                onClick={() => setShowContinueConstructionModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white py-2 px-4 rounded"
                onClick={confirmBeginConstruction}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default ConstructionPhase;
