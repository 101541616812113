import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { Buffer } from "buffer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar";
import { useParams, useNavigate, Link } from "react-router-dom";
import huh from "../../../assets/app-illustrations/Shrug-bro.png";

function formatTimeToAMPM(time) {
  // Split the time into hours, minutes, and seconds
  const [hour, minute] = time.split(":").map(Number);

  // Determine whether it's AM or PM
  const period = hour >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  const formattedHour = hour % 12 || 12; // Use 12 for 0 and 12, else use the remainder

  // Create the formatted time string
  const formattedTime = `${formattedHour}:${
    (minute < 10 ? "0" : "") + minute
  }${period}`;

  return formattedTime;
}

const formatDate = (dateString) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date
    .toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-");
};

function formatDateMMDDYYY(inputDate) {
  // Create a new Date object from the input string
  const date = new Date(inputDate);

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

const WorkplanDetails = () => {
  const [workplan, setWorkplan] = useState({});
  // const accessRole = useSelector((state) => state.user.user.Accessrole);
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedEditActivity, setSelectedEditActivity] = useState(null);
  const [editableStatus, setEditableStatus] = useState({});
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [eO, setEO] = useState(null);
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch workplan details from the server based on the id parameter
    const fetchWorkplanDetails = async () => {
      try {
        const response = await fetch(
          `https://workspace.optiven.co.ke/api/workplans/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        setWorkplan(data);

        // Calculate editable status for each workplan
        const now = new Date();
        const editableStatusUpdate = {};
        if (
          data.status === "pending_rejection" &&
          data.approval_rejection_time
        ) {
          const rejectionTime = new Date(data.approval_rejection_time);
          const timeSinceRejection = now - rejectionTime;
          // Set the editable status to true if within 12 hours of rejection, otherwise false
          editableStatusUpdate[data.id] =
            timeSinceRejection <= 12 * 60 * 60 * 1000;
        } else {
          // For other statuses, set it to false
          editableStatusUpdate[data.id] = false;
        }

        setEditableStatus(editableStatusUpdate);

        // Fetch workplan activities for the workplan
        const activitiesResponse = await fetch(
          `https://workspace.optiven.co.ke/api/workplans/${id}/details`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const activitiesData = await activitiesResponse.json();
        setActivities(activitiesData.activities);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch workplan details. Please try again.", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Redirect back to the workplans list if there's an error
        navigate("/approve-workplans");
      }
    };

    fetchWorkplanDetails();
  }, [token, id, navigate]);

  // Sort activities by date in ascending order
  activities.sort((a, b) => new Date(a.date) - new Date(b.date));

  const handleView = (activity) => {
    console.log("activity", activity);
    setSelectedActivity(activity);
  };

  const openModal = (activity) => {
    setSelectedEditActivity(activity);
    setDate(activity.date);
    setTime(activity.time);
    setEO(activity.expected_output);
  };

  const handleCloseModal = () => {
    setSelectedActivity(null);
  };

  const handleCloseEditModal = () => {
    setSelectedEditActivity(null);
  };

  const handleEdit = async () => {
    const requestBody = {
      workplan_id: id,
      date: formatDate(date),
      time: time,
      expected_output: eO,
      title: selectedEditActivity.title,
      measurable_achievement: selectedEditActivity.measurable_achievement,
      variance: selectedEditActivity.variance,
      sm_remarks: selectedEditActivity.sm_remarks,
      rm_remarks: selectedEditActivity.rm_remarks,
      comments: selectedEditActivity.comments,
    };
    try {
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/workplan-activities/${selectedEditActivity.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      toast.success("Activity updated successfully.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error(
        error.message || "Failed to update activity. Please try again.",
        {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const handleDelete = async (selectedActivity) => {
    try {
      if (!selectedActivity) {
        throw new Error("No activity selected for deletion.");
      }

      console.log(selectedActivity);

      const response = await fetch(
        `https://workspace.optiven.co.ke/api/workplan-activities/${selectedActivity.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Failed to delete activity. Server responded with status ${response.status}. Error message: ${errorData.message}`
        );
      }

      // Workplan successfully deleted, update the state
      setActivities((prevActivities) =>
        prevActivities.filter((workplan) => workplan.id !== selectedActivity.id)
      );

      handleCloseModal();
      toast.success("Activity deleted successfully", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error(
        error.message || "Failed to delete activity. Please try again.",
        {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  // const handleDownload = (selectedActivity) => {
  //   const nodeJSBuffer = selectedActivity.supporting_document;

  //   const buffer = Buffer.from(nodeJSBuffer);
  //   console.log("buffer", buffer);
  //   const blob = new Blob([buffer]);
  //   console.log("blob", blob);

  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   document.body.appendChild(a);
  //   a.style = "display: none";
  //   a.href = url;
  //   a.download = "filename.pdf";
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // };

  return (
    <Sidebar>
      <div className="container px-4 py-6 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to="/workplan-home">Home</Link>
            </li>
            <li>Work Plan Details</li>
          </ul>
        </div>
        <div>
          <div className="flex flex-col md:flex-row md:justify-between lg:flex-row lg:justify-between">
            <div className="flex items-center">
              <label className="label font-bold text-sm">Start Date: </label>
              <p className="">{formatDateMMDDYYY(workplan.start_date)}</p>
              <label className="label font-bold text-sm">End Date: </label>
              <p className="">{formatDateMMDDYYY(workplan.end_date)}</p>
            </div>
            <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row">
              {(workplan.status === "pending" ||
                (workplan.status === "pending_rejection" &&
                  editableStatus[workplan.id])) && (
                <>
                  <button
                    onClick={() => navigate("/view-workplans/" + workplan.id)}
                    className="btn btn-outline md:btn-md md:mr-1 lg:btn-md lg:mt-0 lg:mr-1"
                  >
                    Add Activity
                  </button>
                </>
              )}
            </div>
          </div>
          <h3 className="my-4 text-xl">Activities</h3>
          <div>
            {Array.isArray(activities) &&
            activities.length > 0 &&
            activities[0].id !== null ? (
              <table className="table w-full bg-base-100 shadow-xl">
                <tbody>
                  {activities.map((activity) => (
                    <tr key={activity.id}>
                      <td>
                        <div>
                          <h1 className="label text-gray-400 text-sm">
                            Activity
                          </h1>
                          <p>{activity.title}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h1 className="label text-gray-400 text-sm">Date</h1>
                          <p>{formatDateMMDDYYY(activity.date)}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h1 className="label text-gray-400 text-sm">Time</h1>
                          <p>{formatTimeToAMPM(activity.time)}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h1 className="label text-gray-400 text-sm">
                            Expected Output
                          </h1>
                          <p>{activity.expected_output}</p>
                        </div>
                      </td>
                      {(workplan.status === "pending" ||
                        (workplan.status === "pending_rejection" &&
                          editableStatus[workplan.id])) && (
                        <td>
                          <button
                            onClick={() => handleDelete(activity)}
                            className="btn btn-sm mt-1 btn-error text-white md:mt-0 lg:mt-0"
                          >
                            Delete
                          </button>
                          <button
                            onClick={() => openModal(activity)}
                            className="btn btn-sm mt-1 ml-4 text-white md:mt-0 lg:mt-0"
                          >
                            Edit
                          </button>
                        </td>
                      )}
                      {workplan.status === "approved" && (
                        <td>
                          <button
                            onClick={() => handleView(activity)}
                            className="btn btn-outline sm mt-1 md:mt-0 lg:mt-0"
                          >
                            View More
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center">
                <div className="flex flex-col items-center mt-20">
                  <img src={huh} alt="huh" className="lg:w-96" />
                  <h1 className="font-bold text-center">
                    No activities in this workplan.
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Modal for displaying deletion warning */}
      {selectedActivity && (
        <div className="fixed inset-0 flex justify-center items-center z-10 bg-black bg-opacity-50">
          <div className="modal-box container mx-auto">
            <button
              onClick={handleCloseModal}
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <h2 className="text-lg font-semibold mb-4">WARNING</h2>
            <label className="label">
              Are you sure you want to delete this activity?
            </label>

            <div className="flex flex-col">
              <button
                onClick={handleDelete}
                className="btn btn-error text-white mt-2"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modal for displaying activity details */}
      {selectedActivity && (
        <div className="fixed inset-0 flex justify-center items-center z-10 bg-black bg-opacity-50">
          <div className="modal-box container mx-auto">
            <button
              onClick={handleCloseModal}
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <label className="label font-bold">Activity</label>
            <p className="ml-1">{selectedActivity.title}</p>
            <label className="label font-bold">Expected Output</label>
            <p className="ml-1">{selectedActivity.expected_output}</p>
            <label className="label font-bold">Measurable Achievement</label>
            <p className="ml-1">
              {selectedActivity.measurable_achievement === null
                ? "No updates"
                : selectedActivity.measurable_achievement}
            </p>
            {/* <label className="label font-bold">Supporting Document</label>
            <p className="ml-1">
              {selectedActivity.supporting_document === null ? (
                "Not uploaded"
              ) : (
                <p
                  onClick={() => handleDownload(selectedActivity)}
                  className="underline text-secondary font-bold hover:cursor-pointer"
                >
                  Click to Download
                </p>
              )}
            </p> */}
            <label className="label font-bold">Variance</label>
            <p className="ml-1">
              {selectedActivity.variance === null
                ? "No updates"
                : selectedActivity.variance}
            </p>
            <label className="label font-bold">Comments</label>
            <p className="ml-1">
              {selectedActivity.comments === null
                ? "No updates"
                : selectedActivity.comments}
            </p>
            <label className="label font-bold">Sales Manager Remarks</label>
            <p className="ml-1">
              {selectedActivity.sm_remarks === null
                ? "No updates"
                : selectedActivity.sm_remarks}
            </p>
            <label className="label font-bold">Regional Manager Remarks</label>
            <p className="ml-1">
              {selectedActivity.rm_remarks === null
                ? "No updates"
                : selectedActivity.rm_remarks}
            </p>
          </div>
        </div>
      )}
      {/* Modal for editing activity details*/}
      {selectedEditActivity && (
        <div className="fixed inset-0 flex justify-center items-center z-10 bg-black bg-opacity-50">
          <div className="modal-box container mx-auto">
            <button
              onClick={handleCloseEditModal}
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <form className="form" onSubmit={handleEdit}>
              <label className="label font-bold">Date</label>
              <input
                className="input input-bordered w-full"
                type="date"
                value={formatDate(date)}
                onChange={(e) => setDate(e.target.value)}
              />
              <label className="label font-bold">Time</label>
              <input
                className="input input-bordered w-full"
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
              <label className="label font-bold">Expected Output</label>
              <textarea
                className="textarea textarea-bordered w-full h-24"
                value={eO}
                onChange={(e) => setEO(e.target.value)}
              />

              <div className="flex flex-col">
                <button type="submit" className="btn text-white mt-2">
                  Edit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default WorkplanDetails;
