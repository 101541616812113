import axios from "axios";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Sidebar from "../components/Sidebar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CreateSupplier = () => {
  const [projects, setProjects] = useState([]);
  const token = useSelector((state) => state.user.token);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the project data from the backend API
    axios
      .get(`https://workspace.optiven.co.ke/api/projects/all`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },

      }
      )
      .then((res) => {
        setProjects(res.data);
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const projectOptions = [];

  // Map through the projects and attach the values to projectOptions array
  projects.map((project) =>
    projectOptions.push({
      value: project.id,
      label: project.project_name,
    })
  );

  const initialValues = {
    name: "",
    location: "",
    phoneNumber: "",
    id_number:"",
    email: "",
    supplies: "",
    project: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Supplier name must be at least 3 characters")
      .max(255, "Exceeded the characters limit")
      .required("Enter the supplier name"),
    location: Yup.string()
      .min(3, "Location must be at least 3 characters")
      .max(255, "Exceeded the characters limit")
      .required("Enter the supplier location"),
    phoneNumber: Yup.string()
      .min(10, "Enter a valid phone number in the format 07XXXXXX")
      .max(10, "Enter a valid phone number in the format 07XXXXXX"),
    email: Yup.string().email("Invalid email address"),
    supplies: Yup.string()
      .min(3, "Supplies must be at least 3 characters")
      .max(255, "Exceeded the characters limit")
      .required("Enter the goods or services supplied"),
    project: Yup.number()
      .oneOf(projectOptions.map((option) => option.value))
      .required("Select a project"),
    id_number: Yup.string().required("Id number required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch("https://workspace.optiven.co.ke/api/suppliers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: values.name,
          location: values.location,
          phoneNumber: values.phoneNumber,
          id_number: values.id_number,
          email: values.email,
          supplies: values.supplies,
          projectId: values.project,
        }),
      });
      console.log(response.data);
      toast.success("Supplier added successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // redirect to home page
      navigate("/suppliers");
    } catch (error) {
      console.error(error.response.data);
      toast.error(error.response.data.error, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setSubmitting(false);
  };

  return (
    <Sidebar>
      <div className="hero min-h-screen">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form className="form-control w-full max-w-xs">
              <label htmlFor="name" className="label">
                <span className="label-text font-bold">Name</span>
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder="Plumbing Solutions"
                className="input input-bordered w-full max-w-xs"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500 font-bold text-sm mt-2"
              />

              <label htmlFor="project" className="label">
                <span className="label-text font-bold">Project</span>
              </label>
              <Field
                as="select"
                id="project"
                name="project"
                className="select select-bordered"
                // disable if there's no projects so that we don't get null data
                disabled={projectOptions.length === 0}
             
              >
                <option value="" disabled>
                  Select a Project
                </option>
                {projectOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="project"
                component="div"
                className="text-red-500 font-bold text-sm mt-2"
              />
              {/* display if there's no projects to add the supplier to */}
              {projectOptions.length === 0 && (
                <div className="text-red-500 font-bold text-sm mt-2">
                  No projects available. Add a project in order to proceed
                  adding suppliers to it
                </div>
              )}

              <label htmlFor="location" className="label">
                <span className="label-text font-bold">Location</span>
              </label>
              <Field
                type="text"
                id="location"
                name="location"
                placeholder="Kisumu"
                className="input input-bordered w-full max-w-xs"
              />
              <ErrorMessage
                name="location"
                component="div"
                className="text-red-500 font-bold text-sm mt-2"
              />

              <label htmlFor="phoneNumber" className="label">
                <span className="label-text font-bold">Phone Number</span>
              </label>
              <Field
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="07XXXXXXXX"
                className="input input-bordered w-full max-w-xs"
              />
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="text-red-500 font-bold text-sm mt-2"
              />
              <label htmlFor="id_number" className="label">
                  <span className="label-text font-bold">ID Number</span>
                </label>
                <Field
                  type="id_number"
                  id="id_number"
                  name="id_number"
                  placeholder="23456789"
                  className="input input-bordered w-full max-w-sm"
                />
                <ErrorMessage
                  name="id_number"
                  component="div"
                  className="text-red-500 font-bold text-sm mt-2"
                />

              <label htmlFor="email" className="label">
                <span className="label-text font-bold">Email</span>
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder="plumbing@solutions.com"
                className="input input-bordered w-full max-w-xs"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 font-bold text-sm mt-2"
              />

              <label htmlFor="supplies" className="label">
                <span className="label-text font-bold">Goods/Service Supplied</span>
              </label>
              <Field
                as="textarea"
                id="supplies"
                name="supplies"
                placeholder="PVC pipes, PEX pipes, Copper pipes, ABS pipes, Cast Iron and galvanized steel pipes, etc"
                className="textarea textarea-bordered w-full max-w-xs h-24"
              />
              <ErrorMessage
                name="supplies"
                component="div"
                className="text-red-500 font-bold text-sm mt-2"
              />

              <button
                type="submit"
                id="submit"
                className="btn btn-primary w-full max-w-xs mt-4"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Submitting..." : "Add Supplier"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </Sidebar>
  );
};

export default CreateSupplier;
