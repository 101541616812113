import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import _404cat from "../assets/svgs/404-cat.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// format date
function formatDate(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const ViewCasualLabourers = () => {
const [casual_labourer, setCasualLabourer] = useState([]);
const [labour, setLabour] = useState([]);
const [projects, setProjects] = useState([]);
// SEARCHBAR: Initialize the search query to an empty string
const [query, setQuery] = useState("");
const navigate = useNavigate(); 

  useEffect(() => {
    const fetchCasualLabourers = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/casual-labourers",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setCasualLabourer(response.data);

        // Fetch the job group documents
        const labourIds = response.data.map((member) => member.labour_id);
        console.log(labourIds);
        const labourResponse = await axios.get(
          "https://workspace.optiven.co.ke/api/labour",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              ids: labourIds,
            },
          }
        );
        setLabour(labourResponse.data);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://workspace.optiven.co.ke/api/projects/all",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setProjects(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCasualLabourers();
    fetchProjects();
  }, []);

  // SEARCHBAR: First filter the array of items(casual_labourer) to return an array that does not include the value of the query
  // SEARCHBAR: Memoize the filtered items, to only re-render when the value of the input or casual_labourer changes
  const filteredCasualLabourer = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      casual_labourer.filter((member) => {
        if (query === "") {
          return member;
        } else if (member.name.toLowerCase().includes(query.toLowerCase())) {
          return member;
        }
      }),
    [query, casual_labourer]
  );

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://workspace.optiven.co.ke/api/casual-labourers/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCasualLabourer(casual_labourer.filter((member) => member.id !== id));
      toast.success("Casual Labourer deleted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      toast.error("Casual Labourer has not been deleted",error.response.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const getProjectName = (projectData) => {
    const project = projects.find(
      (proj) => proj.id === parseInt(projectData, 10)
    );
    return project ? project.project_name : "Unknown Project";
  };

  return (
    <>
      <Sidebar>
        <div className="flex justify-center items-center m-4 flex-col">
          <input
            placeholder="Search  Casual Labourer by Name"
            className="input input-bordered input-success w-full max-w-xs"
            type="text"
            // SEARCHBAR: Create an onChange event which sets the query to whatever the value of the input is when it changes
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="overflow-x-auto px-10">
          {filteredCasualLabourer.length === 0 ? (
            <div className="flex flex-col justify-center items-center w-full">
              <img
                src={_404cat}
                alt="not-found"
                className="max-w-lg lg:w-4/5"
              />
              <h1 className="text-black font-bold text-center">
                Wow, such empty! Add some casual_labourer members to get started
              </h1>
            </div>
          ) : (
            <table className="table table-zebra w-full">
              {/* head */}
              <thead>
                <tr className="text-xl font-serif">
                  <th></th>
                  <th>Project </th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>ID Number</th>
                  <th>Labour</th>
                  <th>Start Date</th>
                  <th>End Date </th>
                  <th>Work Description</th>
                  <th>Rate Per Day</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredCasualLabourer.map((member, i) => {
                  // Find the job group document for this member
                  const groupLabour = labour.find(
                    (group) => group.id === member.labour_id
                  );

                  return (
                    <tr key={member.id} className="font-[Poppin]">
                      <th>{i + 1}</th>
                      <td>{getProjectName(member.project_id)}</td>
                      <td>{member.name}</td>
                      <td>{member.phone}</td>
                      <td>{member.id_number}</td>
                      
                     
                      <td>
                        {groupLabour
                         ? (
                          groupLabour.labour_type
                        ) : (
                          <span className="text-accent font-bold">
                            Labour Type does not exist. It may have been deleted
                          </span>
                        )}
                      </td>
                      <td>{formatDate(member.start_date)}</td>
                      <td>{formatDate(member.end_date)}</td>
                      <td>{member.work_description}</td>
                      <td>{member.rate_per_day}</td>
                      <td>
                      <button
                        className="btn btn-warning btn-outline btn-sm  mr-2"
                        onClick={() =>
                          navigate(`/view-casual_labourer/${member.id}/edit-casual_labourer`)
                        }
                      >
                        Edit
                      </button>
                        <button
                          className="btn btn-accent btn-sm"
                          onClick={() => handleDelete(member.id)}
                        >
                          Delete
                        </button>{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default ViewCasualLabourers;
