import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../components/Sidebar";
import { useParams, useNavigate, Link } from "react-router-dom";
import huh from "../../../assets/app-illustrations/Shrug-bro.png";

function formatDate(inputDate) {
  // Create a new Date object from the input string
  const date = new Date(inputDate);

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

function formatTimeToAMPM(time) {
  // Split the time into hours, minutes, and seconds
  const [hour, minute] = time.split(":").map(Number);

  // Determine whether it's AM or PM
  const period = hour >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  const formattedHour = hour % 12 || 12; // Use 12 for 0 and 12, else use the remainder

  // Create the formatted time string
  const formattedTime = `${formattedHour}:${
    (minute < 10 ? "0" : "") + minute
  }${period}`;

  return formattedTime;
}

const PendingWorkplanDetails = () => {
  const [workplan, setWorkplan] = useState({});
  const accessRole = useSelector((state) => state.user.user.Accessrole);
  const [activities, setActivities] = useState([]);
  const [editableStatus, setEditableStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rejectClicked, setRejectClicked] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [formData, setFormData] = useState({
    approval_rejection_comments: "",
  });
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch workplan details from the server based on the id parameter
    const fetchWorkplanDetails = async () => {
      try {
        const response = await fetch(
          `https://workspace.optiven.co.ke/api/workplans/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        setWorkplan(data);

        // Calculate editable status for each workplan
        const now = new Date();
        const editableStatusUpdate = {};
        if (
          data.status === "pending_rejection" &&
          data.approval_rejection_time
        ) {
          const rejectionTime = new Date(data.approval_rejection_time);
          const timeSinceRejection = now - rejectionTime;
          // Set the editable status to true if within 12 hours of rejection, otherwise false
          editableStatusUpdate[data.id] =
            timeSinceRejection <= 12 * 60 * 60 * 1000;
        } else {
          // For other statuses, set it to false
          editableStatusUpdate[data.id] = false;
        }

        setEditableStatus(editableStatusUpdate);

        // Fetch workplan activities for the workplan
        const activitiesResponse = await fetch(
          `https://workspace.optiven.co.ke/api/workplans/${id}/details`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const activitiesData = await activitiesResponse.json();
        setActivities(activitiesData.activities);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch workplan details. Please try again.", {
          position: "top-center",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Redirect back to the workplans list if there's an error
        navigate("/approve-workplans");
      }
    };

    fetchWorkplanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, id, navigate]);

  const handleRejectModal = (e) => {
    setRejectClicked(true);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setRejectClicked(false);
    setIsModalOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const approvalCommentsArray = [
    "Great job! Keep it up!",
    "You're doing awesome!",
    "Fantastic work!",
    "Impressive effort!",
    "You're on the right track!",
  ];

  const handleApprove = async () => {
    try {
      setIsApproving(true);

      // Select a random approval comment
      const randomIndex = Math.floor(
        Math.random() * approvalCommentsArray.length
      );
      const approvalComments = approvalCommentsArray[randomIndex];

      if (approvalComments === null) {
        return;
      }

      // Make a POST request to approve the workplan with the selected approval comment
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/workplans/${id}/approve`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ approvalComments }), // Send the selected approval comment in the request body
        }
      );

      const data = await response.json();
      console.log("workplan approved:", data);
      toast.success("Workplan approved successfully!", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsApproving(false); // Clear the loading state

      // Redirect back to the workplans list
      navigate("/approve-workplans");
    } catch (error) {
      setIsApproving(false); // Clear the loading state in case of an error
      console.error(error);
      toast.error("Failed to approve the workplan. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleReject = async (e) => {
    e.preventDefault();
    try {
      setIsRejecting(true);
      const rejectionComments = formData.approval_rejection_comments;
      if (rejectionComments === null) {
        return;
      }

      // Make a POST request to reject the workplan with rejectionComments
      const response = await fetch(
        `https://workspace.optiven.co.ke/api/workplans/${id}/reject`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ rejectionComments }),
        }
      );

      const data = await response.json();
      console.log("workplan rejected:", data);
      toast.success("Comments sent to the team member", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsRejecting(false); // Clear the loading state

      // Redirect back to the workplans list
      navigate("/approve-workplans");
    } catch (error) {
      setIsRejecting(false); // Clear the loading state in case of an error
      console.error(error);
      toast.error("Failed to update the workplan. Please try again.", {
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Sort activities by date in ascending order
  activities.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <Sidebar>
      <div className="container px-4 py-6 mx-auto">
        <div className="text-sm breadcrumbs">
          <ul>
            <li>
              <Link to="/workplan-home">Home</Link>
            </li>
            <li>Work Plan Details</li>
          </ul>
        </div>
        <div>
          <div className="flex flex-col md:flex-row md:justify-between lg:flex-row lg:justify-between">
            <div className="flex items-center">
              <label className="label font-bold text-sm">Start Date: </label>
              <p>{formatDate(workplan.start_date)}</p>
              <label className="label font-bold text-sm">End Date: </label>
              <p>{formatDate(workplan.end_date)}</p>
            </div>
            <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row">
              {(workplan.status === "pending" ||
                workplan.status === "pending_rejection") && (
                <>
                  <button
                    onClick={() => handleRejectModal()}
                    className="btn mt-1 btn-outline md:btn-md md:mr-1 md:mt-0 lg:btn-md lg:mt-0 lg:mr-1"
                  >
                    Comment
                  </button>
                  <button
                    onClick={handleApprove}
                    className="btn mt-1 btn-primary text-white md:btn-md md:mt-0 lg:btn-md lg:mt-0"
                    disabled={
                      isApproving ||
                      activities.length === 0 ||
                      activities[0].id === null
                    }
                  >
                    {isApproving ? "Approving..." : "Approve"}
                  </button>
                </>
              )}
            </div>
          </div>
          <h3 className="my-4 text-xl">Activities</h3>
          <div>
            {Array.isArray(activities) &&
            activities.length > 0 &&
            activities[0].id !== null ? (
              <table className="table w-full bg-base-100 shadow-xl">
                <tbody>
                  {activities.map((activity) => (
                    <tr key={activity.id}>
                      <td>
                        <div>
                          <h1 className="label text-sm text-gray-400">
                            Activity
                          </h1>
                          <p>{activity.title}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h1 className="label text-sm text-gray-400">Date</h1>
                          <p>{formatDate(activity.date)}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h1 className="label text-sm text-gray-400">Time</h1>
                          <p>{formatTimeToAMPM(activity.time)}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <h1 className="label text-sm text-gray-400">
                            Expected Output
                          </h1>
                          <div
                            className="tooltip"
                            data-tip={activity.expected_output}
                          >
                            <p className="w-3/4">{activity.expected_output}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center">
                <div className="flex flex-col items-center mt-20">
                  <img src={huh} alt="huh" className="lg:w-96" />
                  <h1 className="font-bold text-center">
                    No activities in this workplan, thus you cannot approve it.
                  </h1>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Modal for updating activities */}
      {rejectClicked && (
        <div
          className="fixed inset-0 flex justify-center items-center z-10 bg-black bg-opacity-50"
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        >
          <div className="modal-box container mx-auto">
            <button
              onClick={handleCloseModal}
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            >
              ✕
            </button>
            <form onSubmit={handleReject}>
              <div>
                <label className="label font-bold">Comments</label>
                <textarea
                  name="approval_rejection_comments"
                  value={formData.approval_rejection_comments}
                  onChange={handleFormChange}
                  className="textarea textarea-bordered w-full h-36"
                  required
                  placeholder="Please change X to Y, A has been moved to Z..."
                />
              </div>
              <div className="flex flex-col">
                <button
                  type="submit"
                  className="btn btn-primary text-white mt-2"
                  disabled={isRejecting}
                >
                  {isRejecting ? "Submitting..." : "Submit Comments"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Sidebar>
  );
};

export default PendingWorkplanDetails;
